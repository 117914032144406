import APICaller, { APIMsgBase } from "../APICaller";
import ErrorMessage from "./ErrorMessages";
import { UpsellRule } from "../../model/UpsellRule";

export type GetUpsellRulesType = APIMsgBase & { upsellRules: UpsellRule[] };
export type SaveUpsellRuleType = APIMsgBase & { upsellRule: UpsellRule };

function getUpsellRules(cb: (res: GetUpsellRulesType)=>{}) {
  APICaller.ownFetch(true, "POST", "/upsellRules", {}, (res) => {
    let result: GetUpsellRulesType = JSON.parse(res);

    if (result.error === ErrorMessage.OK)
      fixDates(result.upsellRules);

    if (cb)
      cb(result);
  });
}

function fixDates(upsellRules: UpsellRule[]) {
  upsellRules.forEach(c => {
    c.date = c.date ? new Date(c.date) : undefined;
  });
}

function save(upsellRule: UpsellRule, cb: (res: SaveUpsellRuleType)=>{}) {
  let upsellRule2: UpsellRule = { ...upsellRule };

  APICaller.ownFetch(false, "PUT", "/upsellRule", { ...upsellRule2 }, (res) => {
    let result: SaveUpsellRuleType = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

function remove(upsellRuleID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/upsellRule", { id: upsellRuleID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

export const UpsellRulesAPI = {
  getUpsellRules,
  save,
  remove,
  fixDates,
};
