import React, { Component } from "react";
import APICaller from "../../utils/APICaller";
import EventSystem from "../../utils/EventSystem";
import Modal from "./Modal";
import { Form } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import type { ImageType } from "../../model/Image";
import { Image, ImageTypes } from "../../model/Image";
import { Button } from "../FormComponents";

const ImageCropper = styled(ReactCrop)`
  width: fit-content;
`;

const Body = styled(Modal.Body)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.label`
  width: 100%;
  text-align: left;
`;

export default class ImageUploader extends Component {
  state: {
    showModal: boolean,
    editFileName: string,
    crop: {
      unit: "%" | "px" | "vw" | "wh",
      width: number,
      aspect: number,
    },
    fileSrc: any,
    croppedImageFile: any,
    language: number,
    type: ImageType,
    cb: (image: Image)=>{},
    showCount: number,
  } = {
    showModal: false,
    editFileName: Language.getName(Names.ProductImage),
    crop: {
      unit: "%",
      width: 100,
      aspect: 16 / 9,
    },
    fileSrc: undefined,
    croppedImageFile: undefined,
    language: ContextSystem.language,
    type: ImageTypes.PRODUCT,
    cb: undefined,
    showCount: 1,
  };

  save() {
    if (!this.state.fileSrc || !this.state.croppedImageFile) {
      toast(Language.getName(Names.NoImageUploadedErrorText));
      return;
    }

    APICaller.uploadFile(this.state.croppedImageFile, this.state.type, (res) => {
      if (res.error !== 0)
        return;

      EventSystem.publish(EventSystem.events.image_uploaded, res.image);
      this.setState({ showModal: false });
      if (this.state.cb)
        this.state.cb(res.image);
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.upload_image, (data: { uploadType: string, cb: (image: Image)=>{} }) => {
      if (!data)
        data = {};
      this.setState({ cb: data.cb });
      if (data.uploadType === ImageTypes.PRODUCT) {
        let crop = { unit: "%", width: 100, aspect: 16 / 9 };
        let editFileName = Language.getName(Names.ProductImage);
        this.setState({
          showModal: true,
          editFileName,
          crop,
          fileSrc: undefined,
          croppedImageFile: undefined,
          type: data.uploadType,
          showCount: this.state.showCount + 1,
        });
      } else if (data.uploadType === ImageTypes.SHOP_BILL_LOGO) {
        let crop = { unit: "%", width: 100, aspect: 16 / 9 };
        let editFileName = Language.getName(Names.ChangeBillLogo);
        this.setState({
          showModal: true,
          editFileName,
          crop,
          fileSrc: undefined,
          croppedImageFile: undefined,
          type: data.uploadType,
          showCount: this.state.showCount + 1,
        });
      } else if (data.uploadType === ImageTypes.SHOP_COVER) {
        let crop = { unit: "%", width: 100, aspect: 16 / 9 };
        let editFileName = Language.getName(Names.ChangeCoverImages) + " (" + Language.getName(
          Names.CoverImagePC) + ")";
        this.setState({
          showModal: true,
          editFileName,
          crop,
          fileSrc: undefined,
          croppedImageFile: undefined,
          type: data.uploadType,
          showCount: this.state.showCount + 1,
        });
      } else if (data.uploadType === ImageTypes.SHOP_COVER_MOBILE) {
        let crop = { unit: "%", width: 100, aspect: 1 };
        let editFileName = Language.getName(Names.ChangeCoverImages) + " (" + Language.getName(
          Names.CoverImageMobile) + ")";
        this.setState({
          showModal: true,
          editFileName,
          crop,
          fileSrc: undefined,
          croppedImageFile: undefined,
          type: data.uploadType,
          showCount: this.state.showCount + 1,
        });
      }
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    // noinspection JSIgnoredPromiseFromCall
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageFile = await this.getCroppedImg(this.imageRef, crop, this.state.editFileName);
      this.setState({ croppedImageFile });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/jpeg");
    });
  }

  getAspectRatioText() {
    if ([ImageTypes.SHOP_COVER, ImageTypes.PRODUCT, ImageTypes.SHOP_BILL_LOGO].includes(this.state.type))
      return "16/9";
    else if (this.state.type === ImageTypes.SHOP_COVER_MOBILE)
      return "1/1";
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.UploadImage)}</Modal.Title>
        </Modal.Header>
        <Body>
          <Label>{Language.getName(Names.SelectFile)}</Label>
          <Form.File
            key={this.state.showCount}
            style={{ marginTop: "5px", marginBottom: "5px" }}
            id="custom-file2"
            accept={"image/*"}
            label={this.state.editFileName ? this.state.editFileName : ""}
            custom
            data-browse={Language.getName(Names.SelectFile)}
            onChange={(e: Event) => {
              if (e.target.files.length > 0) {
                //this.uploadFile(e.target.files[0], 2);
                const reader = new FileReader();
                reader.addEventListener("load", () => this.setState({ fileSrc: reader.result }));
                reader.readAsDataURL(e.target.files[0]);
              }
              this.setState({
                editFileName: e.target.files.length > 0 ? e.target.files[0].name : "ProductImage",
              });
            }}
          />
          <Label>{Language.getName(Names.CropImageText).replace("?", this.getAspectRatioText())}</Label>
          <ImageCropper
            key={this.state.showCount * 2}
            src={this.state.fileSrc}
            crop={this.state.crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Upload)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
