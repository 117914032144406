import { toast } from "react-toastify";
import EventSystem from "./EventSystem";
import Config from "./Config";
import ErrorMessage from "./api/ErrorMessages";
import ContextSystem from "./ContextSystem";
import Language, { Names } from "./Language";
import type { ImageType } from "../model/Image";
import { Image } from "../model/Image";
import type { MessageBase } from "./ws/MessageHandler";
import { ShopProfile } from "../model/ShopProfile";

export type UserTokenLocalStoreType = {
  usertoken: string, pin: string, shopProfile: ShopProfile
}

function uploadFile(file: File, type: ImageType, cb: (response: MessageBase & { image: Image })=>{}) {
  let formData: FormData = new FormData();
  formData.append("file", file, file.name);
  let url = Config.APIBaseURL + "/image";
  url += "?shopID=" + ContextSystem.selectedShop.id;
  url += "&type=" + type;
  if (window.localStorage.getItem("usertoken"))
    url += "&usertoken=" + window.localStorage.getItem("usertoken");

  let xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.withCredentials = true;
  xhr.onload = () => {
    if (xhr.status === 200) {
      if (cb)
        cb(JSON.parse(xhr.responseText));
    } else {
      if (cb)
        cb({ error: 1, type: 0 });
    }
  };
  xhr.send(formData);
}

function ownFetch(quite: boolean, method: string, url: string, params: {} = {}, cb: (data: string)=>{}) {
  if (Config.DEBUG)
    console.log(Config.APIBaseURL + url + "::" + method + " called");

  if (!params)
    params = {};

  if (ContextSystem.selectedShop)
    params.shopID = ContextSystem.selectedShop.id;

  let init = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    credentials: "include",
    withCredentials: true,
  };

  if (window.localStorage.getItem("usertoken") != null) {
    params.usertoken = window.localStorage.getItem("usertoken");
  }

  if (method !== "GET") {
    init.body = JSON.stringify(params);
  }

  fetch(Config.APIBaseURL + url, init)
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      try {
        let result = JSON.parse(res);
        if (result.error === ErrorMessage.TOKEN_NOT_VALID) {
          EventSystem.publish(EventSystem.events.authentication_changed, false);
        }
        if (!quite) {
          if (result.error === ErrorMessage.WRONG_EMAIL_OR_PASS) {
            toast(Language.getName(Names.EmailOrPasswordIsWrong));
          } else if (result.error === ErrorMessage.EMAIL_NOT_VALID) {
            toast(Language.getName(Names.EmailAddressNotValid));
          } else if (result.error === ErrorMessage.TOKEN_NOT_VALID) {
            toast(Language.getName(Names.PleaseLogIn));
          } else if (result.error === ErrorMessage.DATABASE_ERROR) {
            toast(Language.getName(Names.DatabaseError));
          } else if (result.error === ErrorMessage.RESTAURANT_CLOSED) {
            toast(Language.getName(Names.RestaurantClosed));
          } else if (result.error === ErrorMessage.PERMISSION_DENIED) {
            toast(Language.getName(Names.PermissionDenied));
          } else if (result.error === ErrorMessage.DATA_NOT_FOUND) {
            toast(Language.getName(Names.DataNotFound));
          } else if (result.error === ErrorMessage.MENU_NOT_REMOVABLE) {
            toast(Language.getName(Names.MenuNotRemovable));
          } else if (result.error === ErrorMessage.PENDING_OR_VALID_CONTRACT_IS_AVAILABLE) {
            toast("Már van folyamatban lévő vagy hatályos szerződés!");
          } else if (result.error === ErrorMessage.TABLE_QR_USED) {
            toast(Language.getName(Names.QR_IDAlreadyInUse));
          } else if (result.error === ErrorMessage.PREORDER_MAX_7_DAYS) {
            toast(Language.getName(Names.PreparedOrderMaxDaysAtMost7Error));
          } else if (result.error === ErrorMessage.PROFILE_NOT_FOUND) {
            toast(Language.getName(Names.ProfileNotFound));
          } else if (result.error === ErrorMessage.FIRSTNAME_NOT_VALID) {
            toast(Language.getName(Names.FirstNameNotValid));
          } else if (result.error === ErrorMessage.LASTNAME_NOT_VALID) {
            toast(Language.getName(Names.LastNameNotValid));
          } else if (result.error === ErrorMessage.PIN_NOT_VALID) {
            toast(Language.getName(Names.PinNotValid));
          } else if (result.error === ErrorMessage.NEW_PASSWORD_TOO_SHORT) {
            toast(Language.getName(Names.PasswordTooShort));
          } else if (result.error === ErrorMessage.ORDER_NOT_FOUND) {
            toast(Language.getName(Names.OrderNotFound));
          } else if (result.error === ErrorMessage.TEL_NOT_VALID) {
            toast(Language.getName(Names.PhoneNumberNotValid));
          } else if (result.error === ErrorMessage.CANCELLATION_ORDER_MIXED) {
            toast(Language.getName(Names.CANCELLATION_ORDER_MIXED));
          } else if (result.error === ErrorMessage.NO_TABLE_AVAILABLE) {
            toast(Language.getName(Names.NO_TABLE_AVAILABLE));
          } else if (result.error === ErrorMessage.COUPON_CODE_ALREADY_IN_USE) {
            toast(Language.getName(Names.COUPON_CODE_ALREADY_IN_USE));
          } else if (result.error === ErrorMessage.RESERVATION_CANNOT_BE_CANCELLED) {
            toast(Language.getName(Names.RESERVATION_CANNOT_BE_CANCELLED));
          } else if (result.error === ErrorMessage.RESERVATION_NOT_FOUND) {
            toast(Language.getName(Names.RESERVATION_NOT_FOUND));
          } else if (result.error === ErrorMessage.PAYMENT_METHOD_NOT_VALID) {
            toast(Language.getName(Names.PAYMENT_METHOD_NOT_VALID));
          } else if (result.error === ErrorMessage.NOT_ENOUGH_QTY_TO_MOVE) {
            toast(Language.getName(Names.NOT_ENOUGH_QTY_TO_MOVE));
          } else if (result.error === ErrorMessage.CANT_DELETE_EXTRA_PRODUCT_USE_IT) {
            toast(Language.getName(Names.CANT_DELETE_EXTRA_PRODUCT_USE_IT));
          } else if (result.error !== ErrorMessage.OK) {
            toast(Language.getName(Names.UnknownError) + result.error);
          }
        }
        if (cb)
          cb(res);
      } catch (e) {
        console.log(e);
      }
    })
    .catch((reason) => {
      console.log(reason);
      toast(Language.getName(Names.ServerNotAvailable));
    });
}

export default {
  ownFetch,
  uploadFile,
};

export type APIMsgBase = { error: number, type: number };
