import EventSystem from "./EventSystem";
import React from "react";
import Config from "./Config";
import CategoriesAPI from "./api/CategoriesAPI";
import type { LoginResult } from "./api/AuthAPI";
import { AuthAPI } from "./api/AuthAPI";
import { OrdersAPI } from "./api/OrdersAPI";
import { ShippingsAPI } from "./api/ShippingsAPI";
import { Contract } from "../model/Contract";
import { CommissionRecord } from "../model/CommissionRecord";
import type { Stackable } from "../model/Product";
import { Category, Extra, GlobalCategory, Product, ProductView, Raw, Recipe, VATValue } from "../model/Product";
import { ShopProfile } from "../model/ShopProfile";
import { City, ZipCode } from "../model/Address";
import { Order, OrderShopProfileContact, TableReservation, TableReservationStatuses } from "../model/Order";
import { ShippingPrice } from "../model/ShippingPrice";
import WSConnection from "./ws/WSConnection";
import Orders from "../components/home/Orders";
import { Shop } from "../model/Shop";
import { Hour } from "../model/Hour";
import { toast } from "react-toastify";
import Language, { Names } from "./Language";
import { ProductsAPI } from "./api/ProductsAPI";
import { ExtrasAPI } from "./api/ExtrasAPI";
import { MenusAPI } from "./api/MenusAPI";
import { Image, ImageTypes } from "../model/Image";
import { AdminProfile } from "../model/AdminProfile";
import { PaymentMethodSetting } from "../model/PaymentMethodSetting";
import { BluePrintAPI } from "./api/BluePrintAPI";
import type { UserTokenLocalStoreType } from "./APICaller";
import { Qty, QtyTypes, SavedStorageMovement, Storage, StorageTypes } from "../model/Stock";
import { RawAPI } from "./api/RawAPI";
import { RecipeAPI } from "./api/RecipeAPI";
import { TableReservationAPI } from "./api/TableReservationAPI";
import { Profile } from "../model/Profile";
import { CustomerAPI } from "./api/CustomerAPI";
import { Coupon } from "../model/Coupon";
import { CouponsAPI } from "./api/CouponsAPI";
import ErrorMessage from "./api/ErrorMessages";
import { Zone } from "../model/BluePrint";
import { Printer } from "../model/Printer";
import { PrintersAPI } from "./api/PrintersAPI";
import { StorageAPI } from "./api/StorageAPI";
import type { RestaurantSetting } from "./api/ProfileAPI";
import { RestaurantSettings } from "./api/ProfileAPI";
import { SavedStorageMovementAPI } from "./api/SavedStorageMovementAPI";
import { ShiftControlsAPI } from "./api/ShiftControlsAPI";
import { ShiftControl, ShiftControlExtraParameter } from "../model/ShiftControl";
import { UpsellRule } from "../model/UpsellRule";
import { UpsellRulesAPI } from "./api/UpsellRulesAPI";
import { ShopNTAKIntegration } from "../model/NTAK";
import { IntegrationsAPI } from "./api/IntegrationsAPI";
import type { TableReservationStatus } from "../model/Order";

export const DataTypes = {
  ORDERS: 0,
  SHIPPING_PRICES: 1,
  RAWS: 2,
  PRODUCTS: 3,
  RECIPES: 4,
  EXTRAS: 5,
  MENUS: 6,
  QTY: 7,
  SIMPLE_TABLES: 8,
  ELEMENTS: 9, //implement later with complete fancy table layout
  ZONES: 10,
  STORAGES: 11,
  COUPONS: 12,
  PRINTERS: 13,
  DEFAULT_RESERVATIONS: 14,
  DEFAULT_CRM: 15,
  CATEGORIES: 16,
  COMPLETE_STORAGE_HISTORY: 17,
  SAVED_STORAGE_MOVEMENTS: 18,
  SHIFT_CONTROLS: 19,
  UPSELL_RULES: 20,
  VIEWS: 21,
  NTAK: 22,
  COP_PARAMETERS: 23,
};

export type DataType = $Values<typeof DataTypes>;

export const DataStates = {
  NOT_LOADED: 0,
  PENDING: 1,
  ERROR: 2,
  LOADED: 3,
};

export type DataState = $Values<typeof DataStates>;

export class DataLoadingInfo {
  type: DataType;
  state: DataState;
}

export default class ContextSystem {
  static websocket: WSConnection = null;

  static data;

  static online: boolean = false;

  static googleAPILoaded: boolean = false;
  static shops: Shop[] = [];
  static selectedShop: Shop | null = null;
  static globalCategories: GlobalCategory[] = [];
  static categories: Category[] = [];
  static productViews: ProductView[] = [];
  static qtyList: Qty[] = [];
  static cities: City[] = [];
  static zipcodes: ZipCode[] = [];
  static mobileDevice: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  static mobileLayout: boolean = window.innerWidth <= Config.mobileMaxWidth;
  static profile: ShopProfile = undefined;
  static adminProfile: AdminProfile = undefined;
  static loggedIn: boolean = false;
  static orders: Order[] = [];
  static newOrdersLoading: boolean = false;
  static ordersLoadingFinished: boolean = false; // meaning finished orders are loading / not loading
  static shippingPrices: ShippingPrice[] = [];
  static shippingCities: City[] = [];
  // static shippingCitiesOptions = [];
  static products: Product[] = [];
  static recipes: Recipe[] = [];
  static raws: Raw[] = [];
  // static localMachines: LocalMachine[] = [];
  static printers: Printer[] = [];
  static profiles: Profile[] = [];
  static coupons: Coupon[] = [];
  static upsellRules: UpsellRule[] = [];
  static tableReservations: TableReservation[] = [];
  static elements: Element[] = [];
  static orderShopProfileContacts: OrderShopProfileContact[] = [];
  static shiftControls: ShiftControl[] = [];
  static shiftControlParameters: ShiftControlExtraParameter[] = [];
  static zones: Zone[] = [];
  static storages: Storage[] = [];
  static savedStorageMovements: SavedStorageMovement[] = [];
  static extras: Extra[] = [];
  static contracts: Contract[] = [];
  static ntakIntegrations: ShopNTAKIntegration[] = [];
  static commissionRecords: CommissionRecord[] = [];
  static showCommentsInOrdering: number = parseInt(window.localStorage.getItem("showCommentsInOrdering") ?? "0");
  static language: number = parseInt(window.localStorage.getItem("language") ?? "0");
  static layout: number = parseInt(window.localStorage.getItem("layout") ?? "0");
  static selectedMenu: number = parseInt(window.localStorage.getItem("selectedMenu") ?? "0");
  static statisticsLoading = false;
  static loadedData: DataLoadingInfo[] = [];
  static selectedCounter: number = -1;

  // Unique ID for anything needed
  static uniqueID = 0;

  static getNextUniqueID() {
    ContextSystem.uniqueID++;
    return ContextSystem.uniqueID;
  }

  static init() {
    ContextSystem.checkToken();
    ContextSystem.loadFromLocalStorage();
    console.log("Init ContextSystem");
    ContextSystem.data = {
      online: ContextSystem.online,
      googleAPILoaded: ContextSystem.googleAPILoaded,
      shops: ContextSystem.shops,
      selectedShop: ContextSystem.selectedShop,
      cities: ContextSystem.cities,
      zipcodes: ContextSystem.zipcodes,
      mobileDevice: ContextSystem.mobileDevice,
      mobileLayout: ContextSystem.mobileLayout,
      globalCategories: ContextSystem.globalCategories,
      profile: ContextSystem.profile,
      contracts: ContextSystem.contracts,
      ntakIntegrations: ContextSystem.ntakIntegrations,
      commissionRecords: ContextSystem.commissionRecords,
      tableReservations: ContextSystem.tableReservations,
      elements: ContextSystem.elements,
      shiftControls: ContextSystem.shiftControls,
      shiftControlParameters: ContextSystem.shiftControlParameters,
      zones: ContextSystem.zones,
      storages: ContextSystem.storages,
      savedStorageMovements: ContextSystem.savedStorageMovements,
      loggedIn: ContextSystem.loggedIn,
      orders: ContextSystem.orders,
      ordersLoading: ContextSystem.newOrdersLoading,
      ordersLoadingFinished: ContextSystem.ordersLoadingFinished,
      shippingPrices: ContextSystem.shippingPrices,
      shippingCities: ContextSystem.shippingCities,
      // shippingCitiesOptions: ContextSystem.shippingCitiesOptions,
      products: ContextSystem.products,
      recipes: ContextSystem.recipes,
      raws: ContextSystem.raws,
      // localMachines: ContextSystem.localMachines,
      printers: ContextSystem.printers,
      profiles: ContextSystem.profiles,
      categories: ContextSystem.categories,
      productViews: ContextSystem.productViews,
      qtyList: ContextSystem.qtyList,
      extras: ContextSystem.extras,
      language: ContextSystem.language,
      layout: ContextSystem.layout,
      selectedMenu: ContextSystem.selectedMenu,
      coupons: ContextSystem.coupons,
      upsellRules: ContextSystem.upsellRules,
      statisticsLoading: ContextSystem.statisticsLoading,
      loadedData: ContextSystem.loadedData,
      selectedCounter: ContextSystem.selectedCounter,
    };
    ContextSystem.subscribe();
    ContextSystem.reload();
  }

  static mergeCategories(incomingCategories: Category[]) {
    let mergedCategories: Category[] = [];
    ContextSystem.categories.forEach(c => mergedCategories.push(c));

    for (let i = 0; i < incomingCategories.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < mergedCategories.length; j++) {
        if (mergedCategories[j].id === incomingCategories[i].id) {
          mergedCategories[j] = incomingCategories[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        mergedCategories.push(incomingCategories[i]);
    }
    ContextSystem.categories = mergedCategories;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { categories: mergedCategories });
  }

  static mergeProductViews(incoming: ProductView[]) {
    let merged: ProductView[] = [];
    ContextSystem.productViews.forEach(c => merged.push(c));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.productViews = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { productViews: merged });
  }

  static mergeQtyList(incomingQtyList: Qty[]) {
    let merged: Qty[] = [];
    ContextSystem.qtyList.forEach(c => merged.push(c));

    for (let i = 0; i < incomingQtyList.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incomingQtyList[i].id) {
          merged[j] = incomingQtyList[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incomingQtyList[i]);
    }
    ContextSystem.qtyList = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { qtyList: merged });
  }

  static removeProduct(removeID: number) {
    ContextSystem.products = ContextSystem.products.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { products: ContextSystem.products });
  }

  static removeCategory(removeID: number) {
    ContextSystem.categories = ContextSystem.categories.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { categories: ContextSystem.categories });
  }

  static removeExtras(removeID: number) {
    ContextSystem.extras = ContextSystem.extras.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { extras: ContextSystem.extras });
  }

  static removeElement(removeID: number) {
    ContextSystem.elements = ContextSystem.elements.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { elements: ContextSystem.elements });
  }

  static removeZone(removeID: number) {
    ContextSystem.zones = ContextSystem.zones.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { zones: ContextSystem.zones });
  }

  static removeStorage(removeID: number) {
    ContextSystem.storages = ContextSystem.storages.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { storages: ContextSystem.storages });
  }

  static removeSavedStorageMovements(removeID: number) {
    ContextSystem.savedStorageMovements = ContextSystem.savedStorageMovements.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged,
      { savedStorageMovements: ContextSystem.savedStorageMovements },
    );
  }

  static removeRecipe(removeID: number) {
    ContextSystem.recipes = ContextSystem.recipes.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { recipes: ContextSystem.recipes });
  }

  static removeRaw(removeID: number) {
    ContextSystem.raws = ContextSystem.raws.filter(p => p.id !== removeID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { raws: ContextSystem.raws });
  }

  static rawChanged(newRaw: Raw, oldID: number) {
    ContextSystem.qtyList.forEach(q => {
      if (q.type === QtyTypes.RAW && q.modelID === oldID) {
        q.modelID = newRaw.id;
        q.reduction.forEach(r => r.modelID = newRaw.id);
      }
    });

    ContextSystem.savedStorageMovements.forEach(sm => {
      sm.moveQtyList.forEach(mq => {
        if (mq.type === QtyTypes.RAW && mq.modelID === oldID)
          mq.modelID = newRaw.id;
      });
    });

    ContextSystem.recipes.forEach(recipe => {
      for (let raw: Raw in recipe.raws) {
        if (raw.id === oldID) {
          recipe.raws[newRaw] = recipe.raws[raw];
          recipe.raws[raw] = undefined;
          break;
        }
      }
    });

    ContextSystem.setSavedStorageMovements(ContextSystem.savedStorageMovements);

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      qtyList: ContextSystem.qtyList,
      recipes: ContextSystem.recipes,
    });
  }

  static productChanged(newProduct: Product, oldID: number) {
    ContextSystem.qtyList.forEach(q => {
      if (q.type === QtyTypes.PRODUCT && q.modelID === oldID) {
        q.modelID = newProduct.id;
        q.reduction.forEach(r => r.modelID = newProduct.id);
      }
    });

    ContextSystem.savedStorageMovements.forEach(sm => {
      sm.moveQtyList.forEach(mq => {
        if (mq.type === QtyTypes.PRODUCT && mq.modelID === oldID)
          mq.modelID = newProduct.id;
      });
    });

    ContextSystem.setSavedStorageMovements(ContextSystem.savedStorageMovements);

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      qtyList: ContextSystem.qtyList,
    });
  }

  static recipeChanged(newRecipe: Recipe, oldID: number) {
    ContextSystem.raws.forEach(raw => {
      raw.recipes.forEach(recipeQty => {
        if (recipeQty.recipe.id === oldID)
          recipeQty.recipe = newRecipe;
      });
    });

    ContextSystem.products.forEach(pr => {
      pr.recipes.forEach(recipeQty => {
        if (recipeQty.recipe.id === oldID)
          recipeQty.recipe = newRecipe;
      });
    });

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      products: ContextSystem.products,
      raws: ContextSystem.raws,
    });
  }

  static elementChanged(newElement: Element, oldID: number) {
    ContextSystem.tableReservations.forEach(q => {
      if (q.tableID === oldID) {
        q.tableID = newElement.id;
      }
    });

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      tableReservations: ContextSystem.tableReservations,
    });
  }

  static zoneChanged(newZone: Zone, oldID: number) {
    ContextSystem.elements.forEach(q => {
      if (q.zoneID === oldID) {
        q.zoneID = newZone.id;
      }
    });

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      zones: ContextSystem.zones,
      elements: ContextSystem.elements,
    });
  }

  static storageChanged(newStorage: Storage, oldID: number) {
    ContextSystem.qtyList.forEach(q => {
      if (q.storageID === oldID) {
        q.storageID = newStorage.id;
      }
    });

    ContextSystem.savedStorageMovements.forEach(sm => {
      if (sm.fromID === oldID)
        sm.fromID = newStorage.id;
      if (sm.toID === oldID)
        sm.toID = newStorage.id;
    });

    ContextSystem.recipes.forEach(r => {
      r.raws.forEach(rr => {
        if (rr.defaultStorageID === oldID)
          rr.defaultStorageID = newStorage.id;
      });
    });

    ContextSystem.setSavedStorageMovements(ContextSystem.savedStorageMovements);

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      zones: ContextSystem.zones,
    });
  }

  static mergeRecipes(incomingRecipes: Recipe[]) {
    let merged: Recipe[] = [];
    ContextSystem.recipes.forEach(p => merged.push(p));

    for (let i = 0; i < incomingRecipes.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incomingRecipes[i].id) {
          merged[j] = incomingRecipes[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incomingRecipes[i]);
    }
    ContextSystem.recipes = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { recipes: merged });
  }

  static mergeRaws(incoming: Raw[]) {
    let merged: Raw[] = [];
    ContextSystem.raws.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.raws = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { raws: merged });
  }

  static mergeProfiles(incoming: Profile[]) {
    let merged: Profile[] = [];
    ContextSystem.profiles.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.profiles = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { profiles: merged });
  }

  static mergeCoupons(incoming: Coupon[]) {
    let merged: Coupon[] = [];
    ContextSystem.coupons.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.coupons = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { coupons: merged });
  }

  static mergeUpsellRules(incoming: UpsellRule[]) {
    let merged: UpsellRule[] = [];
    ContextSystem.upsellRules.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.upsellRules = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { upsellRules: merged });
  }

  static replacePrinters(incoming: Printer[]) {
    if (!incoming || incoming.length <= 0)
      return;

    let merged: Printer[] = [];
    ContextSystem.printers.forEach(p => merged.push(p));

    // mergePrinters should be called only when one shop's all printers are sent
    // this way we remove all the printers from that shop's existing printers
    merged = merged.filter(p => p.partnerID !== incoming[0].partnerID);

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.printers = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { printers: merged });
  }

  static mergeProducts(incomingProducts: Product[]) {
    let mergedProducts: Product[] = [];
    ContextSystem.products.forEach(p => mergedProducts.push(p));

    for (let i = 0; i < incomingProducts.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < mergedProducts.length; j++) {
        if (mergedProducts[j].id === incomingProducts[i].id) {
          mergedProducts[j] = incomingProducts[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        mergedProducts.push(incomingProducts[i]);
    }
    ContextSystem.products = mergedProducts.sort((p1, p2) => p1.orderPlace - p2.orderPlace);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { products: mergedProducts });
  }

  static mergeExtras(incoming: Extra[]) {
    let merged: Extra[] = [];
    ContextSystem.extras.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.extras = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { extras: merged });
  }

  static mergeTableReservations(incomingTableReservations: TableReservation[]) {
    let merged: TableReservation[] = [];
    ContextSystem.tableReservations.forEach(e => merged.push(e));

    for (let i = 0; i < incomingTableReservations.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incomingTableReservations[i].id) {
          merged[j] = incomingTableReservations[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incomingTableReservations[i]);
    }
    ContextSystem.tableReservations = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { tableReservations: merged });
  }

  static mergeBlueprintElements(incoming: Element[]) {
    let merged: Element[] = [];
    ContextSystem.elements.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setElements(merged);
  }

  static mergeOrderShopProfileContacts(incoming: OrderShopProfileContact[]) {
    let merged: TableReservation[] = [];
    ContextSystem.orderShopProfileContacts.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setOrderShopProfileContacts(merged);
  }

  static mergeShiftControls(incoming: ShiftControl[]) {
    let merged: ShiftControl[] = [];
    ContextSystem.shiftControls.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setShiftControls(merged);
  }

  static mergeShiftControlParameters(incoming: ShiftControlExtraParameter[]) {
    let merged: ShiftControlExtraParameter[] = [];
    ContextSystem.shiftControlParameters.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setShiftControlParameters(merged);
  }

  static mergeZones(incoming: Zone[]) {
    let merged: Zone[] = [];
    ContextSystem.zones.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setZones(merged);
  }

  static mergeNTAKIntegrations(incoming: ShopNTAKIntegration[]) {
    let merged: ShopNTAKIntegration[] = [];
    ContextSystem.ntakIntegrations.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setNTAKIntegrations(merged);
  }

  static mergeStorage(incoming: Storage[]) {
    let merged: Storage[] = [];
    ContextSystem.storages.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setStorages(merged);
  }

  static mergeSavedStorageMovements(incoming: SavedStorageMovement[]) {
    let merged: SavedStorageMovement[] = [];
    ContextSystem.savedStorageMovements.forEach(e => merged.push(e));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.setSavedStorageMovements(merged);
  }

  static mergeOrders(incoming: Order[], incomingProducts: Product[], cities: City[], zipCodes: ZipCode[]) {

    incoming = incoming.filter(io => io.originalOrderID <= 0);

    if (incomingProducts)
      ContextSystem.mergeProducts(incomingProducts);
    if (cities && zipCodes)
      ContextSystem.mergeCities(cities, zipCodes);

    let mergedOrders: Order[] = [];
    ContextSystem.orders.forEach(o => mergedOrders.push(o));

    for (let io of incoming) {
      let updated: boolean = false;
      for (let i = 0; i < mergedOrders.length; i++) {
        let mo = mergedOrders[i];
        if (mo.number === io.number) {
          mergedOrders[i] = io;
          updated = true;
          break;
        }
      }
      if (!updated)
        mergedOrders.push(io);
    }

    if (mergedOrders && mergedOrders.length > 0) {
      ContextSystem.orders = mergedOrders.sort((o1, o2) => Orders.sortByDate(o1.date, o2.date, true));
      EventSystem.publish(EventSystem.events.contextSystemChanged, { orders: ContextSystem.orders });
    }
  }

  static newOrdersLoadingCounter: number = 0;

  static startOrdersLoading() {
    ContextSystem.newOrdersLoadingCounter++;
    ContextSystem.newOrdersLoading = true;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { ordersLoading: ContextSystem.newOrdersLoading });
  }

  static stopOrdersLoading() {
    if (ContextSystem.newOrdersLoadingCounter <= 0)
      return;

    ContextSystem.newOrdersLoadingCounter--;
    ContextSystem.newOrdersLoading = false;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { ordersLoading: ContextSystem.newOrdersLoading });
  }

  static ordersLoadingFinishedCounter: number = 0;

  static startOrdersLoadingFinished() {
    ContextSystem.ordersLoadingFinishedCounter++;
    ContextSystem.ordersLoadingFinished = true;
    EventSystem.publish(EventSystem.events.contextSystemChanged,
      { ordersLoadingFinished: ContextSystem.ordersLoadingFinished },
    );
  }

  static stopOrdersLoadingFinished() {
    if (ContextSystem.ordersLoadingFinishedCounter <= 0)
      return;

    ContextSystem.ordersLoadingFinishedCounter--;
    ContextSystem.ordersLoadingFinished = false;
    EventSystem.publish(EventSystem.events.contextSystemChanged,
      { ordersLoadingFinished: ContextSystem.ordersLoadingFinished },
    );
  }

  static downloadFinishedOrders(editMinDate: Date, editMaxDate: Date, cb: ()=>{} = undefined) {
    if (ContextSystem.ordersLoadingFinished || ContextSystem.ordersLoadingFinishedCounter > 0)
      return;
    ContextSystem.startOrdersLoadingFinished();
    OrdersAPI.getOrders(true, editMinDate, editMaxDate, (res) => {
      ContextSystem.stopOrdersLoadingFinished();
      if (res.error !== 0) {
        if (cb)
          cb();
        return;
      }

      ContextSystem.mergeOrders(res.orders, res.products, res.cities, res.zipCodes);

      if (res.tableReservations) {
        res.tableReservations.forEach(r => TableReservationAPI.fixDates(r));
        ContextSystem.mergeTableReservations(res.tableReservations);
      }
      if (res.tables)
        ContextSystem.mergeBlueprintElements(res.tables);

      if (cb)
        cb();
    });
  }

  static downloadElements(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.SIMPLE_TABLES);
    BluePrintAPI.getSimpleTables(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeBlueprintElements(res.elements);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.SIMPLE_TABLES);
    });
  }

  static downloadZones(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.ZONES);
    BluePrintAPI.getZones(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeZones(res.zones);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.ZONES);
    });
  }

  static downloadViews(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.VIEWS);
    CategoriesAPI.getViews(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeCategories(res.views);
        ContextSystem.mergeProductViews(res.productViews);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.VIEWS);
    });
  }

  static downloadStorages(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.STORAGES);
    StorageAPI.getStorages(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeStorage(res.storages);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.STORAGES);
    });
  }

  static downloadSavedStorageMovements(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.SAVED_STORAGE_MOVEMENTS);
    SavedStorageMovementAPI.getSavedStorageMovements(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeSavedStorageMovements(res.savedStorageMovements);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.SAVED_STORAGE_MOVEMENTS);
    });
  }

  static downloadCoupons(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.COUPONS);
    CouponsAPI.getCoupons(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeCoupons(res.coupons);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.COUPONS);
    });
  }

  static downloadNTAK(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.NTAK);
    IntegrationsAPI.getNTAKIntegrations(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeNTAKIntegrations(res.ntakIntegrations);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.NTAK);
    });
  }

  static downloadUpsellRules(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.UPSELL_RULES);
    UpsellRulesAPI.getUpsellRules(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeUpsellRules(res.upsellRules);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.UPSELL_RULES);
    });
  }

  static downloadShiftControlParameters(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.COP_PARAMETERS);
    ShiftControlsAPI.getParameters(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeShiftControlParameters(res.sceps);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.COP_PARAMETERS);
    });
  }

  static downloadPrinters(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.PRINTERS);
    PrintersAPI.getPrinters(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.replacePrinters(res.printers);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.PRINTERS);
    });
  }

  static setElements(elements: Element[]) {
    ContextSystem.elements = elements;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { elements: elements });
  }

  static setOrderShopProfileContacts(orderShopProfileContacts: OrderShopProfileContact[]) {
    ContextSystem.orderShopProfileContacts = orderShopProfileContacts;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { orderShopProfileContacts });
  }

  static setShiftControls(shiftControls: ShiftControl[]) {
    ContextSystem.shiftControls = shiftControls;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { shiftControls });
  }

  static setShiftControlParameters(shiftControlParameters: ShiftControlExtraParameter[]) {
    ContextSystem.shiftControlParameters = shiftControlParameters;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { shiftControlParameters });
  }

  static selectCounter(counterStorageID: number) {
    ContextSystem.selectedCounter = counterStorageID;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedCounter: ContextSystem.selectedCounter });
  }

  static setZones(zones: Zone[]) {
    ContextSystem.zones = zones;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { zones: zones });
  }

  static setNTAKIntegrations(ntakIntegrations: ShopNTAKIntegration[]) {
    ContextSystem.ntakIntegrations = ntakIntegrations;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { ntakIntegrations });
  }

  static setStorages(storages: Storage[]) {
    ContextSystem.storages = storages;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { storages });

    if (storages.length > 0 && storages.filter(st => st.enabled && st.shopIDs.includes(
      ContextSystem.selectedShop?.id) && st.type === StorageTypes.COUNTER).length > 0)
      ContextSystem.selectCounter(storages.find(st => st.enabled && st.shopIDs.includes(
        ContextSystem.selectedShop?.id) && st.type === StorageTypes.COUNTER).id);
  }

  static setSavedStorageMovements(savedStorageMovements: SavedStorageMovement[]) {
    ContextSystem.savedStorageMovements = savedStorageMovements;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { savedStorageMovements });
  }

  static downloadProducts(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    start(DataTypes.RAWS);
    start(DataTypes.PRODUCTS);
    start(DataTypes.QTY);
    start(DataTypes.CATEGORIES);
    start(DataTypes.RECIPES);
    start(DataTypes.EXTRAS);
    start(DataTypes.MENUS);
    RawAPI.getRaws(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeRaws(res.raws);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.RAWS);
    });
    ProductsAPI.getProducts((res) => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeProducts(res.products);
        ContextSystem.mergeCategories(res.categories);
        ContextSystem.mergeQtyList(res.qtyList);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.PRODUCTS);
      cb(res.error === ErrorMessage.OK, DataTypes.QTY);
      cb(res.error === ErrorMessage.OK, DataTypes.CATEGORIES);
    });
    RecipeAPI.getRecipes(res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeRecipes(res.recipes);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.RECIPES);
    });
    ExtrasAPI.getExtras((res) => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeExtras(res.extras);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.EXTRAS);
    });
    MenusAPI.getMenus((res) => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeProducts(res.menus);
      }
      cb(res.error === ErrorMessage.MENUS, DataTypes.EXTRAS);
    });
  }

  static getDataLoadingInfo(type: DataType): DataLoadingInfo {
    if (ContextSystem.loadedData.find(t => t.type === type) === undefined) {
      ContextSystem.loadedData.push({ type, state: DataStates.NOT_LOADED });
      EventSystem.publish(EventSystem.events.contextSystemChanged, { loadedData: ContextSystem.loadedData });
    }

    return ContextSystem.loadedData.find(t => t.type === type);
  }

  static waitLoadingData(
    types: DataType[], timeOut: number,
    run: (cb: (success: boolean, type: DataType)=>{}, started: (type: DataType)=>{})=>{}, params: any = undefined,
  ): boolean {
    let load: boolean = false;
    let wait: boolean = false;

    for (let type of types) {
      let info: DataLoadingInfo = ContextSystem.getDataLoadingInfo(type);
      if (info.state === DataStates.PENDING) {
        wait = true;
        break;
      } else if (info.state === DataStates.ERROR || info.state === DataStates.NOT_LOADED) {
        load = true;
        break;
      }
    }

    if (wait) {
      if (timeOut > 0)
        setTimeout(() => ContextSystem.waitLoadingData(types, timeOut - 500, run), 500);
    } else if (load) {
      run(
        (success, type) => ContextSystem.setLoadedDataState(type, success ? DataStates.LOADED : DataStates.ERROR)
        , (type) => ContextSystem.setLoadedDataState(type, DataStates.PENDING),
        params,
      );
    }
  }

  static setLoadedDataState(type: DataType, state: DataState) {
    let info: DataLoadingInfo = ContextSystem.getDataLoadingInfo(type);

    if (info.state === state)
      return;

    info.state = state;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { loadedData: ContextSystem.loadedData });
  }

  static downloadNewOrders(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    if (ContextSystem.newOrdersLoading || ContextSystem.newOrdersLoadingCounter > 0)
      return;
    ContextSystem.startOrdersLoading();
    start(DataTypes.ORDERS);
    OrdersAPI.getOrders(false, "", "", (res) => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.stopOrdersLoading();
        ContextSystem.mergeOrders(res.orders, res.products, res.cities, res.zipCodes);

        if (res.tableReservations) {
          res.tableReservations.forEach(r => TableReservationAPI.fixDates(r));
          ContextSystem.mergeTableReservations(res.tableReservations);
        }
        if (res.tables)
          ContextSystem.mergeBlueprintElements(res.tables);
        if (res.orderShopProfileContacts)
          ContextSystem.mergeOrderShopProfileContacts(res.orderShopProfileContacts);
      }
      cb(res.error === ErrorMessage.OK, DataTypes.ORDERS);
    });
  }

  static loadFromLocalStorage() {
    //Nothing to load from localstorage
    // let sa = window.localStorage.getItem("selectedAddress");
    // if (sa && sa !== "undefined")
    //   ContextSystem.selectedAddress = JSON.parse(sa);

    ContextSystem.reloadSelectedShop();
  }

  static mergeCities(cities, zipcodes) {
    if (!cities || !zipcodes) {
      console.error("cities or zipcodes parameter is undefined");
      return;
    }

    if (cities.length <= 0 || zipcodes.length <= 0) return;

    if (!ContextSystem.cities)
      ContextSystem.cities = [];
    if (!ContextSystem.zipcodes)
      ContextSystem.zipcodes = [];

    let changed = false;
    A: for (let city of cities) {
      for (let c of ContextSystem.cities) {
        if (c.id === city.id)
          continue A;
      }
      changed = true;
      ContextSystem.cities.push(city);
    }
    A: for (let zipcode of zipcodes) {
      for (let z of ContextSystem.zipcodes) {
        if (z.id === zipcode.id)
          continue A;
      }
      changed = true;
      ContextSystem.zipcodes.push(zipcode);
    }

    if (changed) {
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        cities,
        zipcodes,
      });
    }
  }

  static lastLoggedInValue = null;

  static reload(force: boolean = false) {
    if (force || ContextSystem.lastLoggedInValue !== ContextSystem.loggedIn) {
      ContextSystem.lastLoggedInValue = ContextSystem.loggedIn;

      if (force === false)
        ContextSystem.startWebSocket();

      ContextSystem.loadProfile();
      ContextSystem.loadGlobalCategories();
      if (!ContextSystem.loggedIn) {   //just logged out
        ContextSystem.products = [];
        ContextSystem.orders = [];
        ContextSystem.endWebSocket();
        EventSystem.publish(EventSystem.events.contextSystemChanged, { products: ContextSystem.products });
      } else {                //just logged in
        ContextSystem.reloadNewShop();
      }
    }
  }

  static loadProfile() {
    AuthAPI.checkLogin(true, (res: LoginResult) => {
      ContextSystem.loggedIn = res.authenticated;
      ContextSystem.profile = res.profile;
      ContextSystem.adminProfile = res.adminProfile;
      ContextSystem.contracts = res.contracts;
      ContextSystem.commissionRecords = res.commissionRecords;

      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        loggedIn: res.authenticated,
        profile: res.profile,
        adminProfile: res.adminProfile,
        contracts: res.contracts,
        commissionRecords: res.commissionRecords,
      });
      ContextSystem.setShops(true, res.shops);
    });
  }

  static subscribe() {
    window.addEventListener("resize", () => {
      ContextSystem.setMobileLayout();
    });

    EventSystem.subscribe(EventSystem.events.googlePlacesScriptLoaded, () => {
      this.googleAPILoaded = true;
      EventSystem.publish(EventSystem.events.contextSystemChanged, { googleAPILoaded: true });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, (data) => {
      let changed = false;
      for (let key in data) {
        // noinspection JSUnfilteredForInLoop
        if (ContextSystem.data[key] !== data[key]) {
          changed = true;
        }
        // noinspection JSUnfilteredForInLoop
        ContextSystem.data[key] = data[key];
      }
      if (changed)
        ContextSystem.reload();
    });

    // noinspection JSUnusedLocalSymbols
    EventSystem.subscribe(EventSystem.events.authentication_changed, ({ loggedIn, profile, adminProfile }) => {
      // ContextSystem.profile = profile;
      ContextSystem.loggedIn = loggedIn;
      // EventSystem.publish(EventSystem.events.contextSystemChanged, {profile, loggedIn});

      ContextSystem.reload();
    });

    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      // ContextSystem.reload();
    });

    EventSystem.subscribe(EventSystem.events.partnerOpened, (data: { opened: boolean, shop: Shop }) => {
      ContextSystem.setShopClosedForToday(!data.opened);
    });

    // EventSystem.subscribe(EventSystem.events.raws_changed, () => {
    //   ContextSystem.reloadRaws();
    // });
  }

  static reloadDefaultReservations(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    let dateMin: Date = new Date();
    dateMin.setHours(0, 0, 0, 0);
    let dateMax: Date = new Date(dateMin);
    dateMax.addWeeks(1);
    ContextSystem.loadReservations(dateMin, dateMax, true, cb, start);
  }

  static loadedReservations: {
    dateMin: Date,
    dateMax: Date
  } = {
    dateMin: undefined,
    dateMax: undefined,
  };

  static loadReservations(
    dateMin: Date, dateMax: Date, allStatuses: boolean, cb: (success: boolean, type: DataType)=>{} = undefined,
    start: (type: DataType)=>{} = undefined,
  ) {
    if (this.loadedReservations?.dateMin && this.loadedReservations?.dateMax
      && this.loadedReservations.dateMin < dateMin && this.loadedReservations.dateMax > dateMax
    ) {
      console.log("Reservation load skipped. Loaded already. Current loaded:", this.loadedReservations, ". Requested: ", dateMin, dateMax);
      return;
    }

    if (start)
      start(DataTypes.DEFAULT_RESERVATIONS);
    TableReservationAPI.getReservations(dateMin, dateMax, allStatuses,res => {
      if (res.error === ErrorMessage.OK) {
        if (!this.loadedReservations.dateMin || dateMin < this.loadedReservations.dateMin)
          this.loadedReservations.dateMin = dateMin;
        if (!this.loadedReservations.dateMax || dateMax > this.loadedReservations.dateMax)
          this.loadedReservations.dateMax = dateMax;

        res.tableReservations.forEach(r => TableReservationAPI.fixDates(r));
        ContextSystem.mergeTableReservations(res.tableReservations);
        ContextSystem.mergeBlueprintElements(res.tables);
        if (res.orders)
          ContextSystem.mergeOrders(res.orders, res.products, [], []);
        if (res.profiles)
          ContextSystem.mergeProfiles(res.profiles);
      }
      if (cb)
        cb(res.error === ErrorMessage.OK, DataTypes.DEFAULT_RESERVATIONS);
    });
  }

  static maxCRMPages: number = -1;
  static gotCRMPages: number = 0;
  static maxShiftControlPages: number = -1;
  static gotShiftControlPages: number = 0;

  static shiftControlPageSize: number = 2;

  static getNextCustomersPage(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    if (start)
      start(DataTypes.DEFAULT_CRM);
    if (ContextSystem.maxCRMPages > 0 && ContextSystem.gotCRMPages >= ContextSystem.maxCRMPages)
      return;
    ContextSystem.setLoadedDataState(DataTypes.DEFAULT_CRM, DataStates.NOT_LOADED);

    CustomerAPI.getCustomers(ContextSystem.gotCRMPages + 1, res => {
      if (res.error === ErrorMessage.OK) {
        if (ContextSystem.gotCRMPages === 0)
          ContextSystem.setLoadedDataState(DataTypes.DEFAULT_CRM, DataStates.LOADED);

        ContextSystem.gotCRMPages++;
        ContextSystem.maxCRMPages = res.maxCount;
        ContextSystem.mergeProfiles(res.profiles);
        ContextSystem.mergeCities(res.cities, res.zipCodes);
      }
      if (cb)
        cb(res.error === ErrorMessage.OK, DataTypes.DEFAULT_CRM);
    });
  }

  static getNextShiftControls() {
    ContextSystem.getNextShiftControlPage();
  }

  static getNextCustomers() {
    ContextSystem.getNextShiftControlPage();
  }

  static getNextShiftControlPage(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    if (start)
      start(DataTypes.SHIFT_CONTROLS);
    if (ContextSystem.maxShiftControlPages > 0 && ContextSystem.gotShiftControlPages >= ContextSystem.maxShiftControlPages)
      return;
    ContextSystem.setLoadedDataState(DataTypes.SHIFT_CONTROLS, DataStates.NOT_LOADED);

    let offset: number = ContextSystem.gotShiftControlPages * ContextSystem.shiftControlPageSize;
    let limit: number = ContextSystem.shiftControlPageSize;
    ShiftControlsAPI.getShiftControls(offset, limit, res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.gotShiftControlPages++;
        ContextSystem.maxShiftControlPages = Math.ceil(res.maxCount / ContextSystem.shiftControlPageSize);
        ContextSystem.mergeOrders(res.orders);
        ContextSystem.mergeBlueprintElements(res.tables);
        ContextSystem.mergeTableReservations(res.tableReservations);
        ContextSystem.mergeCities(res.cities, res.zipCodes);
        ContextSystem.mergeShiftControls(res.shiftControls);
        ContextSystem.mergeProducts(res.products);
      }
      if (cb)
        cb(res.error === ErrorMessage.OK, DataTypes.SHIFT_CONTROLS);
    });
  }

  static setMobileLayout() {
    let newValue = window.innerWidth <= Config.mobileMaxWidth;
    if (newValue !== ContextSystem.mobileLayout) {
      ContextSystem.mobileLayout = newValue;
      EventSystem.publish(EventSystem.events.context, {
        mobileLayout: newValue,
      });
    }
  }

  static loadGlobalCategories() {
    if (ContextSystem.globalCategories !== undefined && ContextSystem.globalCategories.length > 0)
      return;

    CategoriesAPI.getAll(false, (res) => {
      ContextSystem.globalCategories = res.globalCategories;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        globalCategories: res.globalCategories,
      });
    });
  }

  static getZipCode(zipCodeID: number) {
    for (let zipcode of ContextSystem.zipcodes) {
      if (zipcode.id === zipCodeID)
        return zipcode;
    }
    return null;
  }

  static getCityByID(id: number): City | null {
    for (let city of ContextSystem.cities) {
      if (city.id === id)
        return city;
    }
    return null;
  }

  static removeShippingPrice(shippingPriceID: number) {
    let shippingPrices = [];
    for (let shippingPrice of ContextSystem.shippingPrices) {
      if (shippingPrice.id !== shippingPriceID)
        shippingPrices.push(shippingPrice);
    }
    ContextSystem.shippingPrices = shippingPrices;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { shippingPrices });
  }

  static removeCoupon(couponID: number) {
    let coupons = [];
    for (let coupon of ContextSystem.coupons) {
      if (coupon.id !== couponID)
        coupons.push(coupon);
    }
    ContextSystem.coupons = coupons;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { coupons });
  }

  static removeShiftControlExtraParameter(scepID: number) {
    let shiftControlParameters = [];
    for (let scep of ContextSystem.shiftControlParameters) {
      if (scep.id !== scepID)
        shiftControlParameters.push(scep);
    }
    ContextSystem.setShiftControlParameters(shiftControlParameters);
  }

  static removeUpsellRule(upsellRuleID: number) {
    let upsellRules: UpsellRule[] = [];
    for (let upsellRule of ContextSystem.upsellRules) {
      if (upsellRule.id !== upsellRuleID)
        upsellRules.push(upsellRule);
    }
    ContextSystem.upsellRules = upsellRules;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { upsellRules });
  }

  static loadShippingPrices(cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}) {
    if (start)
      start(DataTypes.SHIPPING_PRICES);
    ShippingsAPI.getAll((res) => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.setLoadedDataState(DataTypes.SHIPPING_PRICES, DataStates.LOADED);

        // let options = [];
        // for (let key in res.cities) {
        //   let city = res.cities[key];
        //   options.push({
        //     value: city.id,
        //     label: city.city + " (" + city.zip + ")",
        //   });
        // }
        let shippingPrices = res.prices;
        let shippingCities = res.cities;
        // let shippingCitiesOptions = options;
        ContextSystem.shippingPrices = shippingPrices;
        ContextSystem.shippingCities = shippingCities;
        // ContextSystem.shippingCitiesOptions = shippingCitiesOptions;
        EventSystem.publish(EventSystem.events.contextSystemChanged, {
          shippingPrices,
          shippingCities,
          // shippingCitiesOptions
        });
      }
      if (cb)
        cb(res.error === ErrorMessage.OK, DataTypes.SHIPPING_PRICES);
    });
  }

  static modifyHour(
    hourID: number, day: number, openHour: number, openMinute: number, closeHour: number, closeMinute: number) {
    if (!ContextSystem.selectedShop)
      return;
    let h: Hour;
    for (let hourType of Object.keys(ContextSystem.selectedShop.hours)) {
      let hoursInOneType: Hour[] = ContextSystem.selectedShop.hours[hourType];
      for (let hour: Hour of hoursInOneType) {
        if (hour.id === hourID) {
          h = hour;
          break;
        }
      }
    }
    if (!h)
      return;

    h.openHour.hour = openHour;
    h.openHour.minute = openMinute;
    h.closeHour.hour = closeHour;
    h.closeHour.minute = closeMinute;
    h.day = day;

    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static removeHour(hourID: number) {
    if (!ContextSystem.selectedShop)
      return;

    for (let hourType of Object.keys(ContextSystem.selectedShop.hours)) {
      let hoursInOneType = ContextSystem.selectedShop.hours[hourType];
      for (let hour of hoursInOneType) {
        if (hour.id === hourID) {
          hoursInOneType.remove(hour);
          break;
        }
      }
    }

    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static addHour(hour: Hour) {
    if (!ContextSystem.selectedShop)
      return;

    if (!ContextSystem.selectedShop.hours[hour.hourType])
      ContextSystem.selectedShop.hours[hour.hourType] = [];

    ContextSystem.selectedShop.hours[hour.hourType].push(hour);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static addContract(contract: Contract) {
    ContextSystem.contracts.push(contract);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { contracts: ContextSystem.contracts });
  }

  static checkToken() {
    let token = new URLSearchParams(window.location.search).get("token");
    if (token == null || token.length < 1)
      return;

    localStorage.setItem("usertoken", token);
    window.location.search = "";
  }

  static startWebSocket() {
    ContextSystem.websocket = WSConnection.getInstance();
    ContextSystem.websocket.open(() => {
      if (Config.DEBUG) {
        console.log("WebSocket connection opened!");
      }
    });
  }

  static firstGoOnline: boolean = true;

  static setOnline(online: boolean) {
    ContextSystem.online = online;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { online: ContextSystem.online });
    if (online === true && ContextSystem.firstGoOnline === false)
      ContextSystem.reload(true);
    if (online === true && ContextSystem.firstGoOnline === true)
      ContextSystem.firstGoOnline = false;
  }

  static endWebSocket() {
    if (!ContextSystem.websocket)
      return;
    ContextSystem.websocket.close();
  }

  static selectMenu(selectedMenu: number) {
    window.localStorage.setItem("selectedMenu", selectedMenu);
    ContextSystem.selectedMenu = selectedMenu;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedMenu });
  }

  static setLanguage(language: number) {
    window.localStorage.setItem("language", language);
    ContextSystem.reloadLanguage();
  }

  static setLayout(layout: number) {
    window.localStorage.setItem("layout", layout);
    ContextSystem.reloadLayout();
  }

  static setShowCommentsInOrdering(showCommentsInOrdering: number) {
    window.localStorage.setItem("showCommentsInOrdering", showCommentsInOrdering);
    ContextSystem.reloadShowCommentsInOrdering();
  }

  static setShops(quite: boolean, shops: Shop[]) {
    if (!shops)
      shops = [];

    ContextSystem.shops = shops;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { shops });
    if (shops.length <= 0) {
      if (quite === false)
        toast(Language.getName(Names.NoShopsConnectedToYourProfile));
    } else {
      ContextSystem.reloadSelectedShop();
    }
  }

  static setSelectedShop(selectedShop: Shop, reload: boolean = true) {
    if (!selectedShop) {
      return; //silently
    }

    if (!!ContextSystem.selectedShop && selectedShop === ContextSystem.selectedShop)
      return;

    ContextSystem.selectedShop = selectedShop;

    if (reload) {
      //changed selected shop
      ContextSystem.reload(true);
    }
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop });
  }

  static reloadNewShop() {
    ContextSystem.profiles = [];
    ContextSystem.loadedData = [];
    ContextSystem.maxCRMPages = -1;
    ContextSystem.gotCRMPages = 0;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { profiles: ContextSystem.profiles });

    ContextSystem.waitLoadingData([DataTypes.ORDERS], 10000, ContextSystem.downloadNewOrders);
    ContextSystem.waitLoadingData([DataTypes.SHIPPING_PRICES], 10000, ContextSystem.loadShippingPrices);
    ContextSystem.waitLoadingData([
      DataTypes.RAWS, DataTypes.PRODUCTS, DataTypes.QTY, DataTypes.CATEGORIES, DataTypes.RECIPES, DataTypes.EXTRAS,
      DataTypes.MENUS,
    ], 10000, ContextSystem.downloadProducts);
    ContextSystem.waitLoadingData([DataTypes.SIMPLE_TABLES], 10000, ContextSystem.downloadElements);
    ContextSystem.waitLoadingData([DataTypes.ZONES], 10000, ContextSystem.downloadZones);
    ContextSystem.waitLoadingData([DataTypes.VIEWS], 10000, ContextSystem.downloadViews);
    ContextSystem.waitLoadingData([DataTypes.STORAGES], 10000, ContextSystem.downloadStorages);
    ContextSystem.waitLoadingData([DataTypes.SAVED_STORAGE_MOVEMENTS], 10000,
      ContextSystem.downloadSavedStorageMovements,
    );
    ContextSystem.waitLoadingData([DataTypes.COUPONS], 10000, ContextSystem.downloadCoupons);
    ContextSystem.waitLoadingData([DataTypes.UPSELL_RULES], 10000, ContextSystem.downloadUpsellRules);
    ContextSystem.waitLoadingData([DataTypes.COP_PARAMETERS], 10000, ContextSystem.downloadShiftControlParameters);
    ContextSystem.waitLoadingData([DataTypes.PRINTERS], 10000, ContextSystem.downloadPrinters);
    ContextSystem.waitLoadingData([DataTypes.DEFAULT_RESERVATIONS], 10000, ContextSystem.reloadDefaultReservations);
    ContextSystem.waitLoadingData([DataTypes.DEFAULT_CRM], 10000, ContextSystem.getNextCustomersPage);
    ContextSystem.waitLoadingData([DataTypes.SHIFT_CONTROLS], 10000, ContextSystem.getNextShiftControlPage);
    ContextSystem.waitLoadingData([DataTypes.NTAK], 10000, ContextSystem.downloadNTAK);
    // ContextSystem.downloadLocalMachines();
  }

  static setSelectedShopByID(selectedShopID: number) {
    let selectedShop: Shop = ContextSystem.shops.find((s: Shop) => s.id === selectedShopID);
    if (!selectedShop)
      return;
    localStorage.setItem("shopID", selectedShop.id);
    ContextSystem.setSelectedShop(selectedShop);
  }

  static reloadLanguage() {
    ContextSystem.language = parseInt(window.localStorage.getItem("language") ?? "0");
    EventSystem.publish(EventSystem.events.contextSystemChanged, { language: ContextSystem.language });
  }

  static reloadSelectedShop() {
    let selectedShopID = parseInt(window.localStorage.getItem("shopID") ?? "-1");
    if (selectedShopID <= 0) {
      if (ContextSystem.shops.length > 0)
        ContextSystem.setSelectedShop(ContextSystem.shops[0]);
      return;
    }

    ContextSystem.setSelectedShopByID(selectedShopID);
  }

  static reloadLayout() {
    ContextSystem.layout = parseInt(window.localStorage.getItem("layout") ?? "0");
    EventSystem.publish(EventSystem.events.contextSystemChanged, { layout: ContextSystem.layout });
  }

  static reloadShowCommentsInOrdering() {
    ContextSystem.showCommentsInOrdering = parseInt(window.localStorage.getItem("showCommentsInOrdering") ?? "0");
    EventSystem.publish(EventSystem.events.contextSystemChanged, { showCommentsInOrdering: ContextSystem.showCommentsInOrdering });
  }

  static setShopClosedForToday(closedForToday: boolean) {
    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop of shops) {
      if (shop.id === ContextSystem.selectedShop.id) {
        s = shop;
        s.closedForToday = closedForToday;
      }
    }
    ContextSystem.setSelectedShop(s, false);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static updateShopCover(image: Image) {
    if (!image || !ContextSystem.selectedShop)
      return;

    let s: Shop = { ...ContextSystem.selectedShop };
    if (image.type === ImageTypes.SHOP_COVER_MOBILE)
      s.coverImageMobile = image;
    else if (image.type === ImageTypes.SHOP_COVER)
      s.coverImagePC = image;

    ContextSystem.setSelectedShop(s, false);
  }

  static updateShopBill(image: Image) {
    if (!image || !ContextSystem.selectedShop)
      return;
    let s: Shop = { ...ContextSystem.selectedShop };
    s.images.push(image);

    ContextSystem.setSelectedShop(s, false);
  }

  static changePms(newPms: PaymentMethodSetting) {
    if (!newPms || !ContextSystem.shops)
      return;

    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop of shops) {
      if (shop.id === ContextSystem.selectedShop.id) {
        s = shop;
        let pms = shop.paymentMethods.find(pm => pm.paymentID === newPms.paymentID);
        if (pms)
          shop.paymentMethods.remove(pms);
        shop.paymentMethods.push(newPms);
        break;
      }
    }
    ContextSystem.setSelectedShop(s, false);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static setShopPreparedOrderInfo(
    preparedOrderMaxDays: number, showPreparedOrdersBeforeMinutes: number, preparedOrderOnlyOnlinePayment: boolean) {
    if (!preparedOrderMaxDays || !showPreparedOrdersBeforeMinutes || preparedOrderOnlyOnlinePayment === undefined || !ContextSystem.shops)
      return;

    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop of shops) {
      if (shop.id === ContextSystem.selectedShop.id) {
        s = shop;
        s.preparedOrderMaxDays = preparedOrderMaxDays;
        s.showPreparedOrdersBeforeMinutes = showPreparedOrdersBeforeMinutes;
        s.preparedOrderOnlyOnlinePayment = preparedOrderOnlyOnlinePayment;
      }
    }
    ContextSystem.setSelectedShop(s, false);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static setShopPublicInfo(publicEmail: string, publicTel: string) {
    if (!publicEmail || !publicTel || !ContextSystem.shops)
      return;

    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop of shops) {
      if (shop.id === ContextSystem.selectedShop.id) {
        s = shop;
        s.publicEmail = publicEmail;
        s.publicTel = publicTel;
      }
    }
    ContextSystem.setSelectedShop(s, false);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static updateShopProfile(old: ShopProfile, newSp: ShopProfile) {
    if (!old || !newSp)
      return;

    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop of shops) {
      if (shop?.primaryContact?.id === old.id)
        shop.primaryContact = newSp;

      for (let employee of shop.employees) {
        if (shop === ContextSystem.selectedShop)
          s = shop;

        if (employee.id === old.id) {
          shop.employees.remove(employee);
          shop.employees.push(newSp);
          break;
        }
      }
    }
    ContextSystem.setSelectedShop(s, false);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });

    if (old.id === ContextSystem.profile.id) {
      ContextSystem.profile = newSp;
      EventSystem.publish(EventSystem.events.contextSystemChanged, { profile: ContextSystem.profile });
    }

    if (window.localStorage.getItem("usertokens") != null) {
      try {
        let userTokens: UserTokenLocalStoreType[] = JSON.parse(window.localStorage.getItem("usertokens"));
        userTokens.forEach(u => {
          if (u.shopProfile.id === old.id)
            u.shopProfile = newSp;
        });
        window.localStorage.setItem("usertokens", JSON.stringify(userTokens));
      } catch (e) {
      }
    }
  }

  static removeShopProfile(sp: ShopProfile) {
    if (!sp)
      return;

    if (sp.id === ContextSystem.profile.id) {
      AuthAPI.logout();
      return;
    }

    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop of shops) {
      if (shop === ContextSystem.selectedShop)
        s = shop;
      shop.employees = shop.employees.filter(e => e.id !== sp.id);
    }
    ContextSystem.setSelectedShop(s, false);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static addShopProfileInvite(newlyCreated: boolean, shopProfile: ShopProfile) {
    if (!shopProfile)
      return;

    let shops: Shop[] = ContextSystem.shops;
    let s: Shop;
    for (let shop: Shop of shops) {
      if (shopProfile.permissions[shop.id]) {
        shop.employees.push(shopProfile);
        if (shop.id === ContextSystem.selectedShop.id)
          s = shop;
      }
    }
    ContextSystem.setShops(true, shops);
    EventSystem.publish(EventSystem.events.contextSystemChanged, { selectedShop: ContextSystem.selectedShop });
  }

  static getTableReservation(tableID: number, allTableRes: TableReservation[], filterStatuses: TableReservationStatus[] = [TableReservationStatuses.SEATED]): TableReservation | undefined {
    let tableReservations: TableReservation[] = allTableRes?.filter(
      tr => tr.tableID === tableID
        && filterStatuses.includes(tr.status)
        && TableReservation.getStart(tr),
    ) ?? undefined;

    let tableReservation: TableReservation = undefined;

    if (tableReservations.length > 0) {
      tableReservations.sort((tr1, tr2) => Orders.sortByDate(TableReservation.getStart(tr1), TableReservation.getStart(tr2)));
      let tableReservationsSeated = tableReservations.filter(tr => filterStatuses.includes(tr.status));
      if (tableReservationsSeated.length === 1)
        //if available, select seated
        tableReservation = tableReservationsSeated[0];
      else
        //if no one is seated, select next in time
        tableReservation = tableReservations[0];
    }

    return tableReservation;
  }

  static setStatisticsLoading(statisticsLoading: boolean) {
    ContextSystem.statisticsLoading = statisticsLoading;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { statisticsLoading });
  }

  static loadStatisticsByDate(dateMin: Date, dateMax: Date, preDefined: boolean, cb: ()=>{}) {
    if (!preDefined)
      ContextSystem.setStatisticsLoading(true);

    ContextSystem.downloadFinishedOrders(dateMin, dateMax, () => {
      if (!preDefined)
        ContextSystem.setStatisticsLoading(false);

      if (cb)
        cb();
    });
  }

  static loadQtyHistory(st: Stackable) {
    let dataType = DataTypes.COMPLETE_STORAGE_HISTORY + "_" + st.id + "_" + st.qtyType;
    ContextSystem.waitLoadingData([dataType], 30000, ContextSystem.loadCompleteHistory, { st });
  }

  static loadCompleteHistory(
    cb: (success: boolean, type: DataType)=>{}, start: (type: DataType)=>{}, params: { st: Stackable }) {
    let st = params.st;
    let dataType = DataTypes.COMPLETE_STORAGE_HISTORY + "_" + st.id + "_" + st.qtyType;
    start(dataType);
    RawAPI.getCompleteHistory(st.id, st.qtyType, res => {
      if (res.error === ErrorMessage.OK) {
        ContextSystem.mergeQtyList(res.qtyList);
      }
      cb(res.error === ErrorMessage.OK, dataType);
    });
  }

  static changeRestaurantSetting(type: RestaurantSetting, enabled: boolean) {
    let shop: Shop = { ...ContextSystem.selectedShop };

    if (type === RestaurantSettings.PayLaterAvailable)
      shop.payLaterAvailable = enabled;
    else if (type === RestaurantSettings.PosStandShowLocalOrders)
      shop.posStandShowLocalOrders = enabled;
    else if (type === RestaurantSettings.PosWaiterShowNonLocalOrders)
      shop.posWaiterShowNonLocalOrders = enabled;
    else if (type === RestaurantSettings.AutoPrintOrders)
      shop.autoPrintOrders = enabled;
    else if (type === RestaurantSettings.KitchenReportAvailable)
      shop.kitchenReportAvailable = enabled;
    else if (type === RestaurantSettings.ServerReportAvailable)
      shop.serverReportAvailable = enabled;
    else if (type === RestaurantSettings.CashflowReportAvailable)
      shop.cashflowReportAvailable = enabled;
    else if (type === RestaurantSettings.ShowCashflowReportAtOrder)
      shop.showCashflowReportAtOrder = enabled;
    else if (type === RestaurantSettings.HideNotAvailableProducts)
      shop.hideNotAvailableProducts = enabled;
    else if (type === RestaurantSettings.ShowOrderTimerForGuests)
      shop.showOrderTimerForGuests = enabled;
    else if (type === RestaurantSettings.AutomaticallyAcceptOnlineOrders)
      shop.automaticallyAcceptOnlineOrders = enabled;

    ContextSystem.setSelectedShop(shop, false);
  }

  static vatValuesChanged(vatValues: VATValue[], shopID: number): void {
    ContextSystem.shops.forEach(s => {
      if (s.id !== shopID)
        return;

      s.vatValues = vatValues;
    });
    this.setSelectedShopByID(shopID);
  }

  static async sleep(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms)).then();
  }
}
