import React, { Component } from "react";
// import logo from "../../assets/szimpla-logo_h100.png";
import logo from "../../assets/blundee_logos/blundee.png";
import styled from "styled-components";

const NavigationLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img:nth-child(1) {
    padding: 0;
    height: 40px;
  }

  @media screen and (max-width: 800px) {
    img:nth-child(1) {
      padding: 0;
      height: 25px;
    }
  }
`;

class HeaderLogo extends Component {
  render() {
    return (
      <NavigationLogo>
        {/*<img alt={"logo"} src={logo} />*/}
        <img alt={"logotext"} src={logo} />
      </NavigationLogo>
    );
  }
}

export default HeaderLogo;
