import styled, { css } from "styled-components";
import React, { Component } from "react";
import { PaymentMethods, PaymentMethodTypes } from "../../../model/PaymentMethodSetting";
import OrderPaymentEditor from "./OrderPaymentEditor";
import Language, { Names } from "../../../utils/Language";
import { Button, Input } from "../../FormComponents";
import ProductEditor from "../ProductEditor";

const NumPad = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const NumPadNumber = styled(Input)`
  width: 100%;
`;

const NumPadSaveButton = styled(Button)`
  width: 100%;
  height: 35px;
  background-color: #3b6499;
  color: white;
  padding: 0;

  &:hover, &:active {
    background-color: #5390de;
  }
`;

export const NumPadButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;
`;

const NumPadButtonsColumn = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: ${({ margin }) => margin ?? "3px"};
`;

const NumPadButton = styled.button`
  width: 55px;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px;
  overflow-wrap: break-word;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #444440;
  box-shadow: 0 0 2px 0 black;
  cursor: pointer;
  font-size: 10pt;

  outline: none;

  transition: box-shadow 100ms ease-in-out, background-color 200ms ease-in-out;

  ${({ background_color }) => background_color && css`
    background-color: ${background_color};
  `};

  ${({ disabled }) => disabled && css`
    color: #666666;
  `};

  ${({ selected }) => selected === true && css`
    background-color: #3b6499;
    font-weight: bold;
    color: white;

    &:hover, &:active {
      background-color: #5390de;
    }
  `};

  & > svg {
    font-size: ${({ svg_font_size }) => svg_font_size ? svg_font_size : "22pt"};
  }

  &:hover {
    box-shadow: 0 0 5px 0 black;
  }

  &:hover, &:active {
    outline: none;
  }

  &:active {
    box-shadow: 0 0 1px 0 black;
    background-color: #efefef;
  }
`;

export type NumberPadState = {
  availablePaymentMethods: { type: number, countries: number[], availableShippingMethods: number[] }[][],
  selectedPayingMethod: number,
  numberTextValue: string
};

export type NumberPadProps = {
  availablePaymentMethods: { type: number, countries: number[], availableShippingMethods: number[] }[][],
  onPayingMethodDoubleClicked: (type: number)=>{},
  onPaymentMethodChanged: (type: number)=>{},
  selectedPayingMethod: number,
  onAddButtonClicked: (value: number, type: number)=>{},
};

export default class NumberPad extends Component<NumberPadProps> {
  state: NumberPadState = {
    availablePaymentMethods: undefined,
    selectedPayingMethod: PaymentMethodTypes.CASH,
    numberTextValue: "",
  };

  loadProps() {
    if (this.state.availablePaymentMethods !== this.props.availablePaymentMethods)
      this.setState({ availablePaymentMethods: this.props.availablePaymentMethods });
    if (this.state.selectedPayingMethod !== this.props.selectedPayingMethod)
      this.setState({ selectedPayingMethod: this.props.selectedPayingMethod });
  }

  componentDidUpdate(prevProps: Readonly<NumberPadProps>, prevState: Readonly<NumberPadState>, snapshot) {
    this.loadProps();
  }

  componentDidMount() {
    this.loadProps();
  }

  buttons: string[][] = [
    ["1", "4", "7", "."], ["2", "5", "8", "0"], ["3", "6", "9", OrderPaymentEditor.removeBtnText],
  ];

  paymentClicked(e: MouseEvent<HTMLButtonElement>, pType: number) {
    if (e.detail === 2) {
      if (this.props.onPayingMethodDoubleClicked)
        this.props.onPayingMethodDoubleClicked(pType);
    } else {
      this.setState({ selectedPayingMethod: pType });
      if (this.props.onPaymentMethodChanged)
        this.props.onPaymentMethodChanged(pType);
    }
  }

  buttonHit(s: string) {
    let org: string = this.state.numberTextValue;
    if (s === OrderPaymentEditor.removeBtnText) {
      org = org.substring(0, Math.max(0, org.length - 1));
      s = "";
    }
    s = org + s;

    this.numberTextValueChanged(s);
  }

  numberTextValueChanged(newValue: string) {
    if (newValue.endsWith(".."))
      newValue = newValue.substring(0, newValue.length - 1);

    this.setState({ numberTextValue: newValue });
  }

  addButtonClicked() {
    if (this.props.onAddButtonClicked) {
      let amount: number = parseFloat(ProductEditor.parsePrice(this.state.numberTextValue));
      this.props.onAddButtonClicked(amount, this.state.selectedPayingMethod);
      this.setState({ numberTextValue: "" });
    }
  }

  render() {
    return (
      <NumPad>
        <NumPadNumber
          value={this.state.numberTextValue}
          onChange={e => this.numberTextValueChanged(e.target.value)}
          type={"text"}
          placeholder={Language.getName(Names.Payment) + "..."}
        />
        <NumPadButtonsWrapper>
          <NumPadSaveButton onClick={() => this.addButtonClicked()}>
            {Language.getName(Names.Add)}
          </NumPadSaveButton>
        </NumPadButtonsWrapper>

        <NumPadButtonsWrapper>
          {this.buttons.map((b, i) => {
            return (
              <NumPadButtonsColumn key={i}>
                {b.map((s, j) => {
                  return (
                    <NumPadButton key={j} onClick={() => this.buttonHit(s)}>{s}</NumPadButton>
                  );
                })}
              </NumPadButtonsColumn>
            );
          })}
          <NumPadButtonsColumn margin={"0 6px"} />

          {this.state.availablePaymentMethods?.map((list, i) => {
            return (
              <NumPadButtonsColumn key={i}>
                {list.map((p, j) => {
                  return (
                    <NumPadButton
                      onClick={(e: MouseEvent<HTMLButtonElement>) => this.paymentClicked(e, p.type)}
                      selected={this.state.selectedPayingMethod === p.type}
                      key={j}
                    >
                      {PaymentMethods.getIcon(p.type)}
                    </NumPadButton>
                  );
                })}
              </NumPadButtonsColumn>
            );
          })}
        </NumPadButtonsWrapper>
      </NumPad>
    );
  }
}
