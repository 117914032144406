import APICaller, { APIMsgBase } from "../APICaller";
import { Element, ElementTypes, Zone } from "../../model/BluePrint";

export type GetSimpleTablesType = APIMsgBase & { elements: Element[] };
export type GetZonesType = APIMsgBase & { zones: Zone[] };

function getSimpleTables(cb: (res: GetSimpleTablesType)=>{}) {
  APICaller.ownFetch(true, "POST", "/simple/tables", {}, (res) => {
    let result: GetSimpleTablesType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function getZones(cb: (res: GetZonesType)=>{}) {
  APICaller.ownFetch(true, "POST", "/zones", {}, (res) => {
    let result: GetZonesType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function saveSimpleTable(element: Element, cb: (res: APIMsgBase)=>{}) {
  if (element.type !== ElementTypes.TABLE)
    return;

  APICaller.ownFetch(false, "POST", "/simple/table", { ...element }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function saveZone(zone: Zone, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/zone", { ...zone }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function removeSimpleTable(elementID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/simple/table", { id: elementID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function removeZone(zoneID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/zone", { id: zoneID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const BluePrintAPI = {
  getZones,
  getSimpleTables,
  saveSimpleTable,
  saveZone,
  removeZone,
  removeSimpleTable,
};
