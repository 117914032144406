import React, { ChangeEvent, Component } from "react";
import styled from "styled-components";
import { Button } from "../Settings";
import ToggleButton from "react-toggle-button";
import { FaCheck, FaTimes } from "react-icons/all";
import EventSystem from "../../../utils/EventSystem";
import { NotificationType, PartnerNotification } from "../../../model/PartnerNotification";
import Select2 from "react-select";
import { toast } from "react-toastify";
import EmailValidator from "../../../utils/EmailValidator";
import { IntegrationsAPI } from "../../../utils/api/IntegrationsAPI";
import Config from "../../../utils/Config";
import Language, { Names } from "../../../utils/Language";

const Select = styled(Select2)`
  width: fit-content;
  min-width: 250px;
  margin-left: 5px;
  display: inline-block;
  font-weight: normal;
  font-size: 12pt;
`;

const NotificationWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  flex-wrap: wrap;

  & > h3 {
    font-size: 13pt;
    margin: 0;
    padding: 0;
  }

  & > p {
    width: fit-content;
    padding: 5px 10px;
    margin: 0;
  }

  & > input {
    width: 300px;
  }
`;

const Input = styled.input`
  width: ${({ width }) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h3 {
    font-size: 14pt;
    font-weight: bold;
  }
`;

const MessengerApproveText = styled.span`
  font-family: Calibri, sans-serif;
  font-size: 16px;
  background-color: rgb(219, 219, 219);
  padding: 2px 4px;
  border-radius: 4px;
`;

const typeOptions: [{ label: string, value: number }] = [
  { label: NotificationType.getName(NotificationType.EMAIL), value: NotificationType.EMAIL },
  { label: NotificationType.getName(NotificationType.MESSENGER), value: NotificationType.MESSENGER },
];

export default class PartnerNotificationDiv extends Component<{ notification: PartnerNotification, reload: ()=>{} }> {
  state: {
    wantsToEnable: boolean,
    notification: PartnerNotification | null,
  } = {
    wantsToEnable: false,
    notification: new PartnerNotification(),
  };

  handleToggle(active) {
    if (this.state.notification && this.state.notification.id > 0 && !active) {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: Language.getName(Names.RemoveNotificationConfirmTitle),
        text: (
          <>
            {Language.getName(Names.RemoveNotificationConfirmDetails)}
          </>
        ),
        yesText: Language.getName(Names.Yes),
        noText: Language.getName(Names.CancelButtonText),
        cancelCb: () => {
        },
        proceedCb: () => {
          IntegrationsAPI.deletePartnerNotification(this.state.notification.id, res => {
            if (res.error === 0) {
              if (this.props.reload)
                this.props.reload();
              toast(Language.getName(Names.NotificationSettingDeletedDetails));
              this.setState({
                notification: new PartnerNotification(),
                wantsToEnable: false,
              });
            }
          });
        },
      });
    } else {
      this.setState({ wantsToEnable: active });
    }
  }

  save() {
    if (this.state.notification.id > 0)
      return;

    if (this.state.notification.lastName.length < 3) {
      toast(Language.getName(Names.LastNameShortDetails));
      return;
    }
    if (this.state.notification.firstName.length < 3) {
      toast(Language.getName(Names.FirstNameShortDetails));
      return;
    }

    if (this.state.notification.notificationType === NotificationType.EMAIL) {
      if (!EmailValidator(this.state.notification.email)) {
        toast(Language.getName(Names.EmailAddressNotValid));
        return;
      }
    } else if (this.state.notification.notificationType === NotificationType.MESSENGER) {
      //nothing to do here
    }//else if...

    IntegrationsAPI.addPartnerNotification(this.state.notification,
      (res: { error: number, type: number, notification: PartnerNotification | null }) => {
        if (res.error !== 0)
          return;

        if (this.props.reload)
          this.props.reload();

        toast(Language.getName(Names.NotificationSettingAdded));
      },
    );
  }

  componentDidUpdate(prevProps: Readonly, prevState: Readonly, snapshot) {
    if (this.props.notification !== prevState.notification && prevState.notification.id !== -1) {
      this.updateProps();
    }
  }

  updateProps() {
    if (this.props.notification)
      this.setState({ notification: this.props.notification });
    else
      this.setState({ notification: new PartnerNotification() });
  }

  componentDidMount() {
    this.updateProps();
  }

  changeNotificationType(e: { value: number, label: string }) {
    this.setState({
      notification: {
        ...this.state.notification,
        notificationType: e.value,
      },
    });
  }

  render() {
    return (
      <Wrapper>
        <h3>
          {Language.getName(Names.Notification)}
          {!this.state.notification || this.state.notification.id === -1 &&
            <>
              {" - "} {Language.getName(Names.SetUpNew)}
            </>
          }
        </h3>
        <ToggleButton
          inactiveLabel={<FaTimes />}
          activeLabel={<FaCheck />}
          value={this.state.notification && this.state.notification.id > 0 || this.state.wantsToEnable}
          onToggle={value => this.handleToggle(!value)}
        />
        <newline />
        {(this.state.notification && this.state.notification.id > 0 || this.state.wantsToEnable) &&
          <NotificationWrapper>
            <h3>
              {this.state.notification.id > 0 &&
                <>
                  {"#" + this.state.notification.id + ": "}
                </>
              }
              {this.state.notification.id <= 0 &&
                <>
                  {Language.getName(Names.New)}{" "}
                </>
              }
              {Language.getName(Names.Notification)}
              {this.state.notification.id > 0 &&
                <>
                  {"- " + NotificationType.getName(this.state.notification.notificationType)}
                </>
              }
              {this.state.notification.id <= 0 &&
                <>
                  <Select
                    placeholder={Language.getName(Names.SelectType)}
                    onChange={(e) => this.changeNotificationType(e)}
                    options={typeOptions}
                    value={typeOptions.find(o => o.value === this.state.notification.notificationType)}
                  />
                </>
              }
            </h3>
            <newline />
            <p>{Language.getName(Names.LastName)}</p>
            <Input
              autoComplete="off"
              autocomplete="off"
              value={this.state.notification.lastName}
              type="text"
              placeholder={Language.getName(Names.LastName)}
              onChange={(e: ChangeEvent) => this.setState({
                notification: {
                  ...this.state.notification,
                  lastName: e.target.value,
                },
              })}
            />
            <p>{Language.getName(Names.FirstName)}</p>
            <Input
              autoComplete="off"
              autocomplete="off"
              value={this.state.notification.firstName}
              type="text"
              placeholder={Language.getName(Names.FirstName)}
              onChange={(e: ChangeEvent) => this.setState({
                notification: {
                  ...this.state.notification,
                  firstName: e.target.value,
                },
              })}
            />
            <newline />
            {this.state.notification.notificationType === NotificationType.EMAIL &&
              <>
                <p>{Language.getName(Names.MiscEmailText)}</p>
                <Input
                  autoComplete="off"
                  autocomplete="off"
                  value={this.state.notification.email}
                  type="email"
                  placeholder={Language.getName(Names.MiscEmailText)}
                  onChange={(e: ChangeEvent) => this.setState({
                    notification: {
                      ...this.state.notification,
                      email: e.target.value,
                    },
                  })}
                />
              </>
            }
            {this.state.notification.notificationType === NotificationType.MESSENGER &&
              <>
                {this.state.notification.id > 0 && !this.state.notification.messengerApproved &&
                  <>
                    <hr />
                    <p>
                      {Language.getName(Names.MessengerNotificationSetUpText1) + " "}
                      <MessengerApproveText>/ertesites-{this.state.notification.messengerApproveText}</MessengerApproveText>
                      {" " + Language.getName(Names.MessengerNotificationSetUpText2) + " "}
                      <a target={"_blank"} href={"https://m.me/blundeecom" + (Config.TEST ? "Teszt" : "")}>
                        {" " + Language.getName(Names.MessengerNotificationSetUpText3Link)}
                      </a>.<br />
                      {Language.getName(Names.MessengerNotificationSetUpText4)}
                      Blundee{Config.TEST ? "Teszt" : ""}"
                      {Language.getName(Names.MessengerNotificationSetUpText5) + " "}
                      <MessengerApproveText>/ertesites-{this.state.notification.messengerApproveText}</MessengerApproveText>
                    </p>
                  </>
                }
                {this.state.notification.id > 0 && this.state.notification.messengerApproved &&
                  <>
                    <hr />
                    <p>
                      {Language.getName(Names.TheNotificationChannelIsActive)}
                    </p>
                  </>
                }
                {this.state.notification.id <= 0 &&
                  <>
                    <hr />
                    <p>
                      {Language.getName(Names.MessengerNotificationNeedSetupDetails)}
                    </p>
                  </>
                }
              </>
            }
            <newline />
            <hr />
            {this.state.notification.id < 0 &&
              <Button onClick={() => this.save()}>{Language.getName(Names.AddButtonText)}</Button>
            }
          </NotificationWrapper>
        }
      </Wrapper>
    );
  }

}
