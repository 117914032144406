import React, { Component } from "react";
import styled, { css } from "styled-components";
import ToggleButton from "react-toggle-button";
import { FaPencilAlt, FaPlus, FaTimes, FaTrashAlt } from "react-icons/all";
import { DaysName, HourType } from "./Orders";
import { toast } from "react-toastify";
import { ProfileAPI } from "../../utils/api/ProfileAPI";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import Select2 from "react-select/async";
import { ShippingsAPI } from "../../utils/api/ShippingsAPI";
import { Input } from "../../pages/LoginPage";
import { ButtonWrapper } from "./Settings";
import { HoursAPI } from "../../utils/api/HoursAPI";
import Language, { Names } from "../../utils/Language";
import { ShippingPrice } from "../../model/ShippingPrice";
import { Shop } from "../../model/Shop";
import { City } from "../../model/Address";
import HourEditor, { OptionType } from "./HourEditor";
import { Button } from "../FormComponents";
import { TranslatableString } from "../../model/Product";

export const Select = styled(Select2)`
  width: 100%;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow-y: auto;
  padding: 10px 3% 5px 3%;
`;

export const Section = styled.div`
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ebebeb;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    font-size: 16pt;
    font-family: Arial, sans-serif;
  }

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > h2 {
      font-size: 16pt;
      font-family: Arial, sans-serif;
    }
  }
`;

export const SubSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ? justify : "space-between"};
  align-items: ${({ align }) => align ? align : "unset"};
  flex-wrap: wrap;

  label {
    margin: 0 3px;
  }

  newline {
    width: 100%;
    height: 1px;
    background-color: transparent;
  }

  & > h2 {
    ${({ justify }) => justify && css`
      width: 15%;
      min-width: 240px;
    `};
    font-size: 13pt;
    font-family: Arial, sans-serif;
    font-weight: normal;
    margin: 3px 0;
  }
`;

export const RightSection = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ? justify : "flex-end"};
  flex-wrap: wrap;
  align-items: center;
`;

export const LeftSection = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ? justify : "flex-start"};
  flex-wrap: wrap;
  align-items: center;
`;

export const SectionBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const SimpleTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifycontent }) => justifycontent ? justifycontent : "space-evenly"};
  align-items: center;

  width: 100%;
  align-self: flex-start;
  margin-top: 10px;

  & > span {
    & > svg, & > i {
      &:hover {
        cursor: pointer;
      }
    }

    ${({ justifycontent }) => (justifycontent === "flex-start" || justifycontent === "flex-end") && css`
      margin: 0 5px;
    `};
    width: ${({ spanwidth }) => spanwidth ? spanwidth : "25%"};
  }

  & > svg, & > i {
    ${({ justifycontent }) => (justifycontent === "flex-start" || justifycontent === "flex-end") && css`
      margin: 0 5px;
    `};

    &:hover {
      cursor: pointer;
    }
  }
`;

export const CitySelector = styled.div`
  width: ${({ width }) => width ?? "100%"};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px auto 10px auto;
  flex-wrap: nowrap;

  & > svg, & > i {
    margin: 0 0 10px 10px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const AddNewShippingPrice = styled.div`
  margin-top: 15px;
  width: 40%;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;

  & > p {
    font-size: 13px;
  }

  & > svg {
    display: block;
    margin: 0 0 0 15px;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
`;

export type EditHourType = {
  modifyingHourID: number,
  editTimeRange: string[],
  editDay: OptionType,
}

export default class DetailedSettings extends Component {
  state: {
    profile: Shop,
    shippingPrices: ShippingPrice[],
    cities: City[],
    newShipping: boolean,

    citiesOptions: OptionType[],
    selectedCity: OptionType,
    editMinimumOrder: number,
    editShippingCost: number,
    editShippingCost2: number,
    editShippingCost2Min: number,
    editHour: EditHourType,
    editAddHours: EditHourType[],
  } = {
    // shippingEnabled: true,
    profile: ContextSystem.selectedShop,
    shippingPrices: ContextSystem.shippingPrices,
    cities: ContextSystem.shippingCities,
    newShipping: false,

    citiesOptions: [],
    selectedCity: null,
    editMinimumOrder: 0,
    editShippingCost: "",
    editShippingCost2: "",
    editShippingCost2Min: "",
    editHour: null,
    editAddHours: [],
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let id = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ loggedIn, profile, shippingPrices, shippingCities, shippingCitiesOptions }) => {
        if (loggedIn !== undefined) {
          this.setState({ profile, loggedIn });
        }
        if (shippingPrices)
          this.setState({ shippingPrices });
        if (shippingCities)
          this.setState({ cities: shippingCities });
        if (shippingCitiesOptions)
          this.setState({ citiesOptions: shippingCitiesOptions });
      },
    );
    this.eventIDs.push(id);
  }

  promiseOptions = inputValue =>
    new Promise(resolve => {
      ShippingsAPI.searchCity(inputValue, res => {
        let options = [];
        if (res.error === 0) {
          res.cities.forEach(c => options.push({
            value: c.id,
            label: TranslatableString.get(c.name),
          }));
        }
        resolve(options);
      });
    });

  render() {
    return (
      <Wrapper>
        {/*<Section>*/}
        {/*  <div>*/}
        {/*    <h2>Minimum rendelési összeg</h2>*/}
        {/*  </div>*/}
        {/*  <SectionBody>*/}
        {/*    <Input*/}
        {/*      width={"30%"}*/}
        {/*      value={this.state.profile ? this.state.profile.minimumOrder : "0"}*/}
        {/*      onChange={(e) =>*/}
        {/*        this.setState({*/}
        {/*          profile: {*/}
        {/*            ...this.state.profile,*/}
        {/*            minimumOrder: e.target.value,*/}
        {/*          },*/}
        {/*        })*/}
        {/*      }*/}
        {/*      placeholder={"Minimum rendelési összeg"}*/}
        {/*    />*/}
        {/*    <Button onClick={() => this.saveMinimumOrder()}>Mentés</Button>*/}
        {/*  </SectionBody>*/}
        {/*</Section>*/}
        {/*<span style={{ color: "red", width: "100%" }}>Az alábbi funkciók még nem működnek!</span>*/}
        <Section>
          <div>
            <h2>{Language.getName(Names.ShippingPlaces)}</h2>
            {/*<ToggleButton*/}
            {/*  disabled*/}
            {/*  inactiveLabel={<i className="fas fa-times"/>}*/}
            {/*  activeLabel={<i className="fas fa-check"/>}*/}
            {/*  value={this.state.shippingEnabled || false}*/}
            {/*  // onToggle={() => this.setState({shippingEnabled: !this.state.shippingEnabled})}*/}
            {/*/>*/}
          </div>
          {this.state.shippingPrices && this.state.shippingPrices.map((price, i) => {
            return (
              <SimpleTableRow justifycontent={"flex-start"} spanwidth={"15%"} key={i}>
                <span>{price.name} (#{price.id})</span>
                <span>{price.price} Ft</span>
                {price.price2Min > 0 && (
                  <span>
                    {price.price2Min + " Ft " + Language.getName(
                      Names.ShippingPriceOverText) + ": " + price.price2 + " Ft"}
                  </span>
                )}
                <span />
                <span>
                 {Language.getName(Names.MinCartValue) + ": " + price.minimumOrder + "Ft"}
                </span>
                <span onClick={() => this.handleRemoveShippingClicked(price.id)}>
                  <FaTrashAlt />
                </span>
              </SimpleTableRow>
            );
          })}
          {!this.state.newShipping &&
            <SimpleTableRow onClick={() => this.setState({ newShipping: true })}>
            <span>
              <FaPlus />
            </span>
            </SimpleTableRow>
          }
          {this.state.newShipping &&
            <AddNewShippingPrice>
              <p>{Language.getName(Names.CustomShippingPriceText)}</p>
              <CitySelector>
                <Select
                  placeholder={Language.getName(Names.NameOfTheCity)}
                  noOptionsMessage={() => {
                    Language.getName(Names.NoCitiesToShow);
                  }}
                  value={this.state.selectedCity}
                  onChange={(e) => {
                    this.setState({ selectedCity: e });
                  }}
                  loadOptions={this.promiseOptions}
                />
                <FaTimes onClick={() => this.closeNewShippingPrice()} />
              </CitySelector>
              {this.state.selectedCity &&
                <>
                  <span>{Language.getName(Names.MinShippingCartValueText)}</span>
                  <Input
                    autoComplete={"off"}
                    value={this.state.editMinimumOrder}
                    type="number"
                    placeholder={Language.getName(Names.MinShippingCartValueText2)}
                    onChange={(e) => this.setState({ editMinimumOrder: e.target.value })}
                  />
                  <span>{Language.getName(Names.ShippingPrice)}</span>
                  <Input
                    autoComplete={"off"}
                    value={this.state.editShippingCost}
                    type="number"
                    placeholder={Language.getName(Names.ShippingPrice)}
                    onChange={(e) => this.setState({ editShippingCost: e.target.value })}
                  />
                  <span>{Language.getName(Names.MinShippingCartValueText3)}</span>
                  <Input
                    autoComplete={"off"}
                    value={this.state.editShippingCost2Min}
                    type="number"
                    placeholder={Language.getName(Names.MinShippingCartValueText3)}
                    onChange={(e) => this.setState({ editShippingCost2Min: e.target.value })}
                  />
                  {this.state.editShippingCost2Min !== "" && this.state.editShippingCost2Min > 0 &&
                    <>
                      <span>{Language.getName(
                        Names.MinShippingCartValueText4Part1) + " " + this.state.editShippingCost2Min + " " + Language.getName(
                        Names.MinShippingCartValueText4Part2)}</span>
                      <Input
                        autoComplete={"off"}
                        value={this.state.editShippingCost2}
                        type="number"
                        placeholder={Language.getName(Names.MinShippingCartValueText5)}
                        onChange={(e) => this.setState({ editShippingCost2: e.target.value })}
                      />
                    </>
                  }
                  <ButtonWrapper>
                    <Button onClick={() => this.addNewShippingCost()}>{Language.getName(Names.AddButtonText)}</Button>
                  </ButtonWrapper>
                </>
              }
            </AddNewShippingPrice>
          }
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.AcceptOrders)}</h2>
          </div>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.TakeAwayOption)}</h2>
            <ToggleButton
              inactiveLabel={<i className="fas fa-times" />}
              activeLabel={<i className="fas fa-check" />}
              value={(this.state.profile && this.state.profile.enablePersonalOrder) || false}
              onToggle={value => this.handlePersonalPickupSetting(!value)}
            />
          </SubSection>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.DeliveryOption)}</h2>
            <ToggleButton
              inactiveLabel={<i className="fas fa-times" />}
              activeLabel={<i className="fas fa-check" />}
              value={(this.state.profile && this.state.profile.enableDeliveryOrder) || false}
              onToggle={value => this.handleDeliverySetting(!value)}
            />
          </SubSection>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.AtPlaceOption)}</h2>
            <ToggleButton
              inactiveLabel={<i className="fas fa-times" />}
              activeLabel={<i className="fas fa-check" />}
              value={(this.state.profile && this.state.profile?.enableTableOrder) || false}
              onToggle={value => this.handleTableOrderSetting(!value)}
            />
          </SubSection>
        </Section>
        {this.state.profile && this.state.profile.hours && Object.keys(this.state.profile.hours).map((hourType, i) => {
          if (hourType > HourType.PICK_UP_ORDER_ACCEPT)
            return <React.Fragment key={i} />;

          let hours2 = this.state.profile.hours[hourType];
          let hours = hours2.sort((h1, h2) => h1.day === h2.day ? h1.openHour - h2.openHour : h1.day - h2.day);
          // console.log(this.state.profile.hours);
          // noinspection EqualityComparisonWithCoercionJS
          return (
            <Section key={i}>
              <div>
                {/* eslint-disable-next-line eqeqeq */}
                {hourType == HourType.OPENING && <h2>{Language.getName(Names.ShopOpenHours)}</h2>}
                {/* eslint-disable-next-line eqeqeq */}
                {hourType == HourType.ORDER_ACCEPT &&
                  <h2>{Language.getName(Names.AcceptOrderDeliveryOpenHoursText)}</h2>}
                {/* eslint-disable-next-line eqeqeq */}
                {hourType == HourType.DELIVERY && <h2>{Language.getName(Names.DeliveryOpenHoursText)}</h2>}
                {/* eslint-disable-next-line eqeqeq */}
                {hourType == HourType.PICK_UP_ORDER_ACCEPT &&
                  <h2>{Language.getName(Names.AcceptOrderTakeAwayOpenHoursText)}</h2>}
                {/*<ToggleButton*/}
                {/*  inactiveLabel={""}*/}
                {/*  activeLabel={""}*/}
                {/*  value={this.state.profile.hours[hourType][0].enabled}*/}
                {/*  onToggle={() => {*/}
                {/*    let hours = this.state.profile.hours;*/}
                {/*    for (let h of hours[hourType]) h.enabled = !h.enabled;*/}

                {/*    this.setState({*/}
                {/*      profile: {*/}
                {/*        ...this.state.profile,*/}
                {/*        hours,*/}
                {/*      },*/}
                {/*    });*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
              <SectionBody>
                {hours.map((h, i) => {
                  let openH = h.openHour.hour > 9 ? h.openHour.hour : "0" + h.openHour.hour;
                  let openM = h.openHour.minute > 9 ? h.openHour.minute : "0" + h.openHour.minute;
                  let closeH = h.closeHour.hour > 9 ? h.closeHour.hour : "0" + h.closeHour.hour;
                  let closeM = h.closeHour.minute > 9 ? h.closeHour.minute : "0" + h.closeHour.minute;
                  return (
                    <React.Fragment key={i}>
                      <SimpleTableRow>
                        <span>{DaysName(h.day)}</span>
                        <span>{openH}:{openM}</span>
                        <span>{closeH}:{closeM}</span>
                        <span>
                        <FaPencilAlt onClick={() => this.handleModifyHour(h.id)} />
                        <FaTrashAlt style={{ marginLeft: "10px" }} onClick={() => this.handleRemoveHour(h.id)} />
                      </span>
                      </SimpleTableRow>
                      {this.state.editHour && this.state.editHour.modifyingHourID === h.id &&
                        <HourEditor
                          value={this.state.editHour}
                          onChange={value => this.setState({ editHour: value })}
                          onCancel={() => this.setState({ editHour: null })}
                          onSave={() => this.handleModifyHourSave()}
                        />
                      }
                    </React.Fragment>
                  );
                })}
              </SectionBody>
              {this.state.editAddHours[hourType] == null &&
                <SimpleTableRow onClick={() => this.handleAddHourClicked(hourType)}>
              <span>
                <FaPlus />
              </span>
                </SimpleTableRow>
              }
              {this.state.editAddHours[hourType] != null &&
                <AddNewShippingPrice>
                  <HourEditor
                    value={this.state.editAddHours[parseInt(hourType)]}
                    onChange={value => this.handleEditAddHourChanged(parseInt(hourType), value)}
                    onCancel={() => this.handleCloseAddNewHour(parseInt(hourType))}
                    onSave={() => this.handleSaveNewHour(parseInt(hourType))}
                  />
                </AddNewShippingPrice>
              }
            </Section>
          );
        })}
      </Wrapper>
    );
  }

  handlePersonalPickupSetting(enabled: boolean) {
    ProfileAPI.changePickupEnabled(enabled, res => {
      if (res.error === 0) {
        let profile = this.state.profile; //direct pointer to the ContextSystem.profile!!
        profile.enablePersonalOrder = enabled;
        this.setState({ profile });
        toast(Language.getName(Names.Modified));
      }
    });
  }

  handleTableOrderSetting(enabled: boolean) {
    ProfileAPI.changeTableOrderEnabled(enabled, res => {
      if (res.error === 0) {
        let profile = this.state.profile; //direct pointer to the ContextSystem.profile!!
        profile.enableTableOrder = enabled;
        this.setState({ profile });
        toast(Language.getName(Names.Modified));
      }
    });
  }

  handleDeliverySetting(enabled: boolean) {
    ProfileAPI.changeDeliveryEnabled(enabled, res => {
      if (res.error === 0) {
        let profile = this.state.profile; //direct pointer to the ContextSystem.profile!!
        profile.enableDeliveryOrder = enabled;
        this.setState({ profile });
        toast(Language.getName(Names.Modified));
      }
    });
  }

  handleRemoveShippingClicked(shippingPriceID) {
    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: Language.getName(Names.DeleteShippingPriceConfirmTitle),
      text: (
        <>
          {Language.getName(Names.DeleteShippingPriceConfirmDetails)}
        </>
      ),
      yesText: Language.getName(Names.Yes),
      noText: Language.getName(Names.CancelButtonText),
      cancelCb: () => {
      },
      proceedCb: () => {
        ShippingsAPI.remove(shippingPriceID, res => {
          if (res.error === 0) {
            toast(Language.getName(Names.Removed));
            ContextSystem.removeShippingPrice(shippingPriceID);
          }
        });
      },
    });
  }

  addNewShippingCost() {
    if (this.state.selectedCity == null) {
      toast(Language.getName(Names.SelectACity));
      return;
    }

    if (this.state.editMinimumOrder < 0) {
      toast(Language.getName(Names.MinCartValueIsNegative));
      return;
    }

    if (!this.state.editShippingCost || this.state.editShippingCost === "") {
      toast(Language.getName(Names.NoShippingPriceIsGiven));
      return;
    }

    if (this.state.editShippingCost2Min && this.state.editShippingCost2Min !== "") {
      if (!this.state.editShippingCost2 || this.state.editShippingCost2 === "") {
        toast(Language.getName(Names.NoDiscountShippingPriceGiven));
        return;
      }
    }

    let price2 = this.state.editShippingCost2 === "" ? 0 : parseInt(this.state.editShippingCost2);
    let price2Min = this.state.editShippingCost2Min === "" ? 0 : parseInt(this.state.editShippingCost2Min);

    ShippingsAPI.addNewShippingPrice(this.state.selectedCity.value,
      parseInt(this.state.editShippingCost), price2, price2Min, parseInt(this.state.editMinimumOrder),
      res => {
        if (res.error === 0) {
          this.setState({});
          this.closeNewShippingPrice();
          ContextSystem.loadShippingPrices();
          toast(Language.getName(Names.Added));
        }
      },
    );
  }

  closeNewShippingPrice() {
    this.setState({
      selectedCity: null,
      newShipping: false,
      editMinimumOrder: 0,
      editShippingCost: "",
      editShippingCost2: "",
      editShippingCost2Min: "",
    });
  }

  handleModifyHour(hourID) {
    let h;
    for (let hourType of Object.keys(this.state.profile.hours)) {
      let hoursInOneType = this.state.profile.hours[hourType];
      for (let hour of hoursInOneType) {
        if (hour.id === hourID) {
          h = hour;
          break;
        }
      }
    }
    if (!h)
      return;

    let openH = h.openHour.hour > 9 ? h.openHour.hour : "0" + h.openHour.hour;
    let openM = h.openHour.minute > 9 ? h.openHour.minute : "0" + h.openHour.minute;
    let closeH = h.closeHour.hour > 9 ? h.closeHour.hour : "0" + h.closeHour.hour;
    let closeM = h.closeHour.minute > 9 ? h.closeHour.minute : "0" + h.closeHour.minute;
    let editTimeRange = [openH + ":" + openM, closeH + ":" + closeM];

    let editDay;
    for (let dayOption: OptionType of HourEditor.buildOptions()) {
      if (dayOption.value === h.day) {
        editDay = dayOption;
        break;
      }
    }

    let editHour: EditHourType = {
      modifyingHourID: hourID,
      editTimeRange,
      editDay,
    };

    this.setState({ editHour });
  }

  handleModifyHourSave() {
    if (!this.state.editHour)
      return;

    let validated = DetailedSettings.validateHourEdit(this.state.editHour, false);
    if (!validated)
      return;

    HoursAPI.modify(validated.hourID, validated.day, validated.openHour, validated.openMinute, validated.closeHour,
      validated.closeMinute, res => {
        if (res.error === 0) {
          ContextSystem.modifyHour(validated.hourID, validated.day, validated.openHour, validated.openMinute,
            validated.closeHour, validated.closeMinute,
          );
          toast(Language.getName(Names.Modified));
          this.setState({ editHour: null });
        }
      },
    );
  }

  static validateHourEdit(editHour: EditHourType, newHour: boolean):
    { hourID: number, openHour: number, openMinute: number, closeHour: number, closeMinute: number, day: number } {
    let hourID = editHour.modifyingHourID;
    let openHour = parseInt(editHour.editTimeRange[0]?.split(":")[0]);
    let openMinute = parseInt(editHour.editTimeRange[0]?.split(":")[1]);
    let closeHour = parseInt(editHour.editTimeRange[1]?.split(":")[0]);
    let closeMinute = parseInt(editHour.editTimeRange[1]?.split(":")[1]);
    let day = editHour.editDay.value;

    if (!newHour && hourID < 0)
      return null;

    if (isNaN(openHour) || isNaN(openMinute) || isNaN(closeHour) || isNaN(closeMinute)) {
      toast(Language.getName(Names.OpenHourOrCloseHourIsEmpty));
      return null;
    }

    if (openHour < 0 || openHour > 23 || closeHour < 0 || closeHour > 23 || openMinute < 0 || openMinute > 59 || closeMinute < 0 || closeMinute > 59) {
      toast(Language.getName(Names.InvalidDataEntered));
      return null;
    }

    if (openHour > closeHour || (openHour === closeHour && openMinute > closeMinute)) {
      toast(Language.getName(Names.StartingTimeIsAfterEndingPoint));
      return null;
    }
    if (openHour === closeHour && openMinute === closeMinute) {
      toast(Language.getName(Names.StartingTimeIsAfterEndingPoint));
      return null;
    }
    if (day < 0 || day > 7) {
      toast(Language.getName(Names.SelectADay));
      return null;
    }

    return {
      hourID,
      openHour,
      openMinute,
      closeHour,
      closeMinute,
      day,
    };
  }

  handleRemoveHour(hourID) {
    HoursAPI.remove(hourID, res => {
      if (res.error === 0) {
        ContextSystem.removeHour(hourID);
        toast(Language.getName(Names.Removed));
      }
    });
  }

  handleAddHourClicked(hourType) {
    let editAddHours = this.state.editAddHours;
    editAddHours[hourType] = {
      modifyingHourID: -1,
      editTimeRange: ["10:00", "11:00"],
      editDay: { value: 1, label: DaysName(1) },
    };
    this.setState({ editAddHours });
  }

  handleEditAddHourChanged(hourType, value) {
    let editAddHours = this.state.editAddHours;
    editAddHours[hourType] = value;
    this.setState({ editAddHours });
  }

  handleCloseAddNewHour(hourType) {
    let editAddHours = [];
    for (let hourType2 in this.state.editAddHours) {
      if (parseInt(hourType2) !== hourType)
        editAddHours.push(this.state.editAddHours[hourType2]);
    }
    this.setState({ editAddHours });
  }

  handleSaveNewHour(hourType) {
    let validated = DetailedSettings.validateHourEdit(this.state.editAddHours[hourType], true);
    if (!validated)
      return;

    HoursAPI.add(hourType, validated.day, validated.openHour, validated.openMinute, validated.closeHour,
      validated.closeMinute, res => {
        if (res.error === 0) {
          let h = res.hour;
          this.handleCloseAddNewHour(hourType);
          ContextSystem.addHour(h);
          toast(Language.getName(Names.Modified));
        }
      },
    );
  }
}
