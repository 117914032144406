import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import { Raw, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input, InputArea } from "../FormComponents";
import { RawAPI } from "../../utils/api/RawAPI";
import styled, { css } from "styled-components";
import { languages, ReactFlagsSelect } from "./CouponEditor";
import { CheckBox } from "../home/Orders";

export const Label = styled.label`
  width: 100%;
  margin: 6px 0 0 0;
  font-size: ${({ font_size }) => font_size ?? "11pt"};
`;

export const SmallButton = styled.div`
  font-size: 9pt;
  margin: 3px 6px;
  padding: 3px;
  background-color: #444440;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 3px 0 #212121;
  transition: box-shadow 150ms ease-in-out;

  &:hover {
    box-shadow: 0 0 5px 0 #212121;
  }

  &:active {
    box-shadow: 0 0 1px 0 #212121;
  }
`;

export const SectionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ flex_wrap }) => flex_wrap === true && css`
    flex-wrap: wrap;
  `};
`;

export const SectionColumn = styled.div`
  width: ${({ width }) => width ?? "100%"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default class RawEditor extends Component {
  state: {
    showModal: boolean,
    edit: null | Raw,
    title: string,
    language: number,
    selectedLanguage: number,
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewRaw),
    language: ContextSystem.language,
    selectedLanguage: languages[0].id,
  };

  save() {
    let raw: Raw = { ...this.state.edit };
    if (!raw.name || !raw.name.translation?.find(t => t.value.length > 1)) {
      toast(Language.getName(Names.GivenNameTooShort));
      return;
    }
    if (!raw.details || !raw.details.translation?.find(t => t.value.length > 1)) {
      toast(Language.getName(Names.GivenDetailsTooShort));
      return;
    }

    RawAPI.save(raw, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  editRaw(raw: Raw) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyRaw),
      edit: {
        ...raw,
        name: {
          ...raw.name,
          _editing: "HU-HU",
        },
        details: {
          ...raw.details,
          _editing: "HU-HU",
        },
        unit: {
          ...raw.unit,
          _editing: "HU-HU",
        },
      },
    });
  }

  addNewRaw() {
    this.setState({
      showModal: true,
      title: Language.getName(Names.AddNewRaw),
      edit: {
        id: -1,
        enabled: true,
        name: TranslatableString.create(),
        details: TranslatableString.create(),
        unit: TranslatableString.create(),
        partnerID: ContextSystem.selectedShop.id,
        minQty: 0,
        maxQty: 0,
        expirationMinutes: 0, //disabled
        semiProduct: false,
        recipes: [],
      },
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.edit_raw_material, (raw) => {
      if (!raw)
        this.addNewRaw();
      else
        this.editRaw(raw);
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  changeEx(min: number) {
    this.setState({
      edit: {
        ...this.state.edit,
        expirationMinutes: min,
      },
    });
  }

  setName(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let name: TranslatableString = this.state.edit.name;
    if (!name)
      name = TranslatableString.create("");
    name.translation = name.translation.filter(t => t.languageCode !== languageCode);
    name.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        name: name,
      },
    });
  }

  setDetails(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let details: TranslatableString = this.state.edit.details;
    if (!details)
      details = TranslatableString.create("");
    details.translation = details.translation.filter(t => t.languageCode !== languageCode);
    details.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        details: details,
      },
    });
  }

  setUnit(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let unit: TranslatableString = this.state.edit.unit;
    if (!unit)
      unit = TranslatableString.create("");
    unit.translation = unit.translation.filter(t => t.languageCode !== languageCode);
    unit.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        unit,
      },
    });
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id })}
          />
        </Modal.Header>
        <Modal.Body>
          {this.state.edit &&
            <>
              <Label>{Language.getName(Names.Name)}</Label>
              <Input
                width={"100%"}
                autoComplete="off"
                autocomplete="off"
                value={this.state.edit?.name?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
                type="text"
                placeholder={Language.getName(Names.Name)}
                onChange={e => this.setName(e.target.value)}
              />
              <Label>{Language.getName(Names.Details)}</Label>
              <InputArea
                width={"100%"}
                autoComplete="off"
                autocomplete="off"
                value={this.state.edit?.details?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
                type="text"
                placeholder={Language.getName(Names.Details)}
                onChange={e => this.setDetails(e.target.value)}
              />
              <Label>{Language.getName(Names.Unit)}</Label>
              <Input
                width={"100%"}
                autoComplete="off"
                autocomplete="off"
                value={this.state.edit?.unit?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
                type="text"
                placeholder={Language.getName(Names.Unit)}
                onChange={e => this.setUnit(e.target.value)}
              />
              <CheckBox>
                <input
                  type={"checkbox"}
                  checked={this.state.edit?.semiProduct}
                  onChange={() => this.setState({
                    edit: {
                      ...this.state.edit,
                      semiProduct: !this.state.edit.semiProduct,
                    },
                  })}
                />
                <Label onClick={() => this.setState({
                  edit: {
                    ...this.state.edit,
                    semiProduct: !this.state.edit.semiProduct,
                  },
                })}>
                  {Language.getName(Names.ProcessedProduct)}
                </Label>
              </CheckBox>
              {this.state.edit.semiProduct &&
                <Button
                  margin={"0 0 0 6px"}
                  variant="secondary"
                  onClick={() =>
                    EventSystem.publish(EventSystem.events.edit_recipe_qty, {
                      recipes: this.state.edit.recipes, cb: (recipes) => {
                        this.setState({
                          edit: {
                            ...this.state.edit,
                            recipes: recipes,
                          },
                        });
                      },
                    })
                  }
                >
                  {Language.getName(Names.EditProductRecipes)}
                </Button>
              }
              <SectionRow>
                <SectionColumn width={"49%"}>
                  <Label>{Language.getName(Names.MinQty)}</Label>
                  <Input
                    value={this.state.edit ? this.state.edit.minQty : ""}
                    type="text"
                    placeholder={Language.getName(Names.MinQty)}
                    onChange={(e) =>
                      this.setState({
                        edit: {
                          ...this.state.edit,
                          minQty: isNaN(parseInt(e.target.value)) ? "" : Math.abs(parseInt(e.target.value)),
                        },
                      })
                    }
                    width={"100%"}
                  />
                </SectionColumn>
                <SectionColumn width={"49%"}>
                  <Label>{Language.getName(Names.MaxQty)}</Label>
                  <Input
                    value={this.state.edit ? this.state.edit.maxQty : ""}
                    type="text"
                    placeholder={Language.getName(Names.MaxQty)}
                    onChange={(e) =>
                      this.setState({
                        edit: {
                          ...this.state.edit,
                          maxQty: isNaN(parseInt(e.target.value)) ? "" : Math.abs(parseInt(e.target.value)),
                        },
                      })
                    }
                    width={"100%"}
                  />
                </SectionColumn>
              </SectionRow>
              <SectionColumn>
                <Label>{Language.getName(Names.ShelfLifeDesc)}</Label>
                <Input
                  value={this.state.edit ? this.state.edit.expirationMinutes : ""}
                  type="text"
                  placeholder={Language.getName(Names.ShelfLifeInMinutes)}
                  onChange={(e) =>
                    this.setState({
                      edit: {
                        ...this.state.edit,
                        expirationMinutes: isNaN(parseInt(e.target.value)) ? "" : Math.abs(parseInt(e.target.value)),
                      },
                    })
                  }
                  width={"100%"}
                />
                <SectionRow flex_wrap={true}>
                  <SmallButton onClick={() => this.changeEx(12 * 60)}>{Language.getName(Names._12hours)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(1440)}>{Language.getName(Names._1day)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(3 * 1440)}>{Language.getName(Names._3days)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(5 * 1440)}>{Language.getName(Names._5days)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(7 * 1440)}>{Language.getName(Names._1week)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(14 * 1440)}>{Language.getName(Names._2weeks)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(21 * 1440)}>{Language.getName(Names._3weeks)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(43830)}>{Language.getName(Names._1month)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(87660)}>{Language.getName(Names._2months)}</SmallButton>
                  <SmallButton onClick={() => this.changeEx(131487)}>{Language.getName(Names._3months)}</SmallButton>
                </SectionRow>
              </SectionColumn>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
