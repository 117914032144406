import { MessageSender } from "./MessageSender";
import MessageType from "./MessageType";
import type { CrossEventDataBase } from "./CrossEventHandler";
import CrossEventHandler from "./CrossEventHandler";
import { toast } from "react-toastify";
import Language, { Names } from "../Language";

type HandlerType = (data: any) => void;

const HandleOk: HandlerType = () => {
};

const HandleError: HandlerType = (data: { error: any }) => {
  toast("Unexpected error - Code: #" + data.error);
  // EventSystem.publish(EventSystem.events.SHOW_TOAST, );
  //MessageSender.sendError(Object.keys(ErrorMessage).includes(data.error) ? data.error : ErrorMessage.UNEXPECTED_ERROR);
};

const HandlePing: HandlerType = () => {
  MessageSender.sendPong();
};

const HandlePrintDocumentError: HandlerType = () => {
  toast(Language.getName(Names.PrintDocumentErrorText));
};

export type MessageBase = { error: number, type: number };
type CrossEventMessage = MessageBase & { data: CrossEventDataBase };

const HandleCrossEventPublish: HandlerType = (msg: CrossEventMessage) => {
  let eventType: number = msg.data.type;
  if (CrossEventHandler[eventType])
    CrossEventHandler[eventType](msg.data, eventType);
  else
    console.log("eventType handler is not found - " + eventType);
};

// noinspection JSUnusedLocalSymbols
const HandleCrossEventRegistered: HandlerType = (msg: MessageBase) => {
//todo
};

// noinspection JSUnusedLocalSymbols
const HandleCrossEventUnregistered: HandlerType = (msg: MessageBase) => {
//todo
};

let handlerTable: {
  [type: number]: HandlerType;
} = {};
handlerTable[MessageType.OK] = HandleOk;
handlerTable[MessageType.ERROR] = HandleError;
handlerTable[MessageType.PING] = HandlePing;
handlerTable[MessageType.CROSS_EVENT_PUBLISH] = HandleCrossEventPublish;
handlerTable[MessageType.CROSS_EVENT_REGISTERED] = HandleCrossEventRegistered;
handlerTable[MessageType.CROSS_EVENT_UNREGISTERED] = HandleCrossEventUnregistered;
handlerTable[MessageType.PRINT_DOCUMENT_ERROR] = HandlePrintDocumentError;

export default handlerTable;
