import React, { Component } from "react";
import Modal from "./Modal";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { ProductsAPI } from "../../utils/api/ProductsAPI";
import { toast } from "react-toastify";
import Select2 from "react-select";
import Orders, { CheckBox, DaysName, ProductType } from "../home/Orders";
import Config from "../../utils/Config";
import ExtrasSelector, { SmallInput } from "./ExtrasSelector";
import ContextSystem from "../../utils/ContextSystem";
import { ExtrasAPI } from "../../utils/api/ExtrasAPI";
import type { NTAKSubCategory } from "../../model/Product";
import { Category, Extra, MennyisegEgysegs, NTAKAvailableSubCategories, NTAKMainCategories, NTAKSubCategories, Product, TranslatableString, VATValue, VATValueModelTypes } from "../../model/Product";
import { Image, ImageTypes } from "../../model/Image";
import Language, { Names } from "../../utils/Language";
import { FaPlus, FaTrashAlt } from "react-icons/all";
import { Hour, HourTypes } from "../../model/Hour";
import { Button, Input } from "../FormComponents";
import type { EditHourType } from "../home/DetailedSettings";
import DetailedSettings, { SubSection } from "../home/DetailedSettings";
import { HeaderButton, HeaderButtonWrapper } from "../home/Products";
import HourEditor from "../home/HourEditor";
import type { OptionValue } from "./InwardHandlingModal";
import { Label } from "./ContractsDetailsModal";
import { languages, ReactFlagsSelect } from "./CouponEditor";
import { ShopNTAKIntegration } from "../../model/NTAK";
import type { ShippingMethod } from "../../model/ShippingPrice";
import { ShippingMethods } from "../../model/ShippingPrice";
import { PaymentMethodTypes } from "../../model/PaymentMethodSetting";
import { InputWrapper } from "./AddContractModal";

export const SelectedProduct = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding: 0 5px;
  background-color: white;

  & > div:nth-child(1) {
    width: 10%;
  }

  & > div:nth-child(2) {
    width: 55%;
  }

  & > div:nth-child(3) {
    width: 15%;
  }

  & > div:nth-child(4) {
    text-align: right;
    width: 20%;

    & > svg {
      padding: 4px;
      border-radius: 4px;
      font-size: 12pt;

      &:hover {
        background-color: #ebebeb;
        cursor: pointer;
      }
    }
  }

  ${({ header }) => header && css`
    border: none;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 5px;

    & > div:nth-child(3) {
      width: 40%;
    }
  `}
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;

  & > span {
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    & > img {
      width: 100%;
    }

    & > svg {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 5px;
      border-radius: 4px;
      background-color: white;
      font-size: 20pt;

      &:hover {
        cursor: pointer;
        background-color: #ebebeb;
      }
    }
  }

  & > div {
    width: 32%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #ebebeb;

    & > svg {
      font-size: 14pt;
    }

    & > span {
      font-size: 10pt;
    }

    &:hover {
      box-shadow: 0 0 4px 1px #121212;
      cursor: pointer;
    }
  }
`;

export const ProductsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 0 0 12px 0;
`;

export const Select = styled(Select2)`
  width: ${({ width }) => width ?? "49%"};
  margin: ${({ margin }) => margin ?? "0 0 5px 0"};
`;

export const SubSectionLabel = styled.div`
  width: ${({ width }) => width ?? "auto"};
  display: inline-block;
  margin: 0 3px;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const WeekScheduleEditor = styled.div`
  width: 100%;
  margin-top: 0;
  padding: 5px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const WeekTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 6px;
  flex-wrap: wrap;
`;

export const WeekTableColumn = styled.div`
  width: ${({ width }) => width ? width : "14.27%"};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align ? align : "flex-start"};
  justify-content: flex-start;
  ${({ margin }) => margin && css`
    margin: ${margin};
  `}
`;

export const WeekTableCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > span {
    font-size: 10pt;
  }

  ${({ header }) => header === true && css`
    font-weight: bold;
    background-color: #e3e3e3;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
`;

export default class ProductEditor extends Component {
  state: {
    extras: Extra[],
    categories: Category[],
    showModal: boolean,
    edit: Product,
    title: string | null,
    productsOptions: OptionValue[],
    categoryOptions: OptionValue[],
    globalCategoryOptions: OptionValue[],
    ntakMainCategoryOptions: OptionValue[],
    ntakSubCategoryOptions: OptionValue[],
    ntakMennyisegEgysegOptions: OptionValue[],
    selectedCategory: OptionValue,
    selectedGlobalCategory: OptionValue,
    selectedNtakMainCategory: OptionValue,
    selectedNtakSubCategory: OptionValue,
    selectedNtakMennyisegEgyseg: OptionValue,
    language: number,
    editHour: EditHourType,
    selectedLanguage: number,
    ntakIntegrations: ShopNTAKIntegration[],
  } = {
    categories: [],
    extras: [],
    showModal: false,
    edit: null,
    title: Language.getName(Names.Product),
    productsOptions: [],
    categoryOptions: [],
    globalCategoryOptions: [],
    ntakMainCategoryOptions: [],
    ntakSubCategoryOptions: [],
    ntakMennyisegEgysegOptions: [],
    selectedCategory: undefined,
    selectedGlobalCategory: undefined,
    selectedNtakMainCategory: undefined,
    selectedNtakSubCategory: undefined,
    selectedNtakMennyisegEgyseg: undefined,
    language: ContextSystem.language,
    editHour: undefined,
    selectedLanguage: languages[0].id,
    ntakIntegrations: [],
  };

  save() {
    let product = { ...this.state.edit };
    if (product.globalCategoryID <= 0) {
      toast(Language.getName(Names.ChooseCategory));
      return;
    }
    if (!TranslatableString.get(product.name) || TranslatableString.get(product.name).length < 2) {
      toast(Language.getName(Names.NameTooShort));
      return;
    }
    if (!TranslatableString.get(product.details) || TranslatableString.get(product.details).length < 2) {
      toast(Language.getName(Names.GivenDetailsTooShort));
      return;
    }
    TranslatableString.fillEmptyWithCopy(product.details);
    TranslatableString.fillEmptyWithCopy(product.name);
    if (!product.price)
      product.price = 0;
    if (!product.originalPrice)
      product.originalPrice = 0;
    if (!product.showPrice)
      product.showPrice = 0;
    if (!product.ntakQty)
      product.ntakQty = 0;

    product.price = parseFloat(product.price);
    product.originalPrice = parseFloat(product.originalPrice);
    product.showPrice = parseFloat(product.showPrice);
    product.ntakQty = parseFloat(product.ntakQty);

    if (product.price < 0) {
      toast(Language.getName(Names.PriceShouldBeNonNegative));
      return;
    }
    product.products = [];
    if (!product.extras)
      product.extras = [];
    if (!product.groupExtras)
      product.groupExtras = [];
    if (!product.extraGroups)
      product.extraGroups = [];
    if (!product.versions)
      product.versions = [{ ...ExtrasSelector.defaultVersion }];

    for (let extraGroup of product.extraGroups) {
      extraGroup.minQty = parseInt(extraGroup.minQty);
      extraGroup.maxQty = parseInt(extraGroup.maxQty);
      extraGroup.freeQty = parseInt(extraGroup.freeQty);
      extraGroup.saleID = parseInt(extraGroup.saleID);
      extraGroup.productID = product.id;
      extraGroup.partnerID = product.partnerID;
    }
    for (let version of product.versions) {
      version.price = parseFloat(version.price);
      version.productID = product.id;
      version.partnerID = product.partnerID;
    }

    product.vatValues.forEach(e => {
      if (![ShippingMethods.PICKUP, ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY].includes(e.shippingMode))
        return;
      e.value = parseFloat(e.value);
    });

    if (product.type === ProductType.MENU) {
      for (let subPr of product.products) {
        if (subPr.qty <= 0) {
          toast(Language.getName(Names.MenuSubProductsMin1) + TranslatableString.get(subPr.name) + ")");
          return;
        }
      }
      if (!product.image || product.image === "") {
        toast(Language.getName(Names.YouNeedAtLeastOneImage));
        return;
      }
    }

    ProductsAPI.save(product, (res) => {
      if (res.error !== 0) return;
      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
      EventSystem.publish(EventSystem.events.product_edited, this.state.edit);
    });
  }

  static remove(productID) {
    ProductsAPI.remove(productID, () => {
      toast(Language.getName(Names.Removed));
    });
  }

  static getCategoryById(categoryID: number, categories: Category[]): Category | null {
    if (categoryID === 0)
      return null;

    for (let category of categories) {
      if (category.id === categoryID)
        return category;
    }
  }

  copyProduct(product: Product) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.CopyProduct),
      edit: { ...product, id: -1 },
    });
  }

  editProduct(product: Product) {
    this.setState({
      editHour: undefined,
      showModal: true,
      title: Language.getName(Names.EditProduct),
      edit: product,
    });
  }

  addNewProduct(categoryID: number, type: number) {
    let edit: Product = {
      id: -1,
      categoryID: categoryID,
      globalCategoryID: this.state.selectedGlobalCategory ? this.state.selectedGlobalCategory.value : -1,
      type,
      price: 0,
      name: TranslatableString.create(""),
      details: TranslatableString.create(""),
      extraGroups: [],
      products: [],
      versions: [],
      image: "",
      image1: "",
      image2: "",
      image3: "",
      weekSchedule: [],
      prepared: false,
      countStock: false,
      availablePOS: true,
      availableOnline: true,
      ntakMainCategory: NTAKMainCategories.EGYEB,
      ntakSubCategory: NTAKSubCategories.EGYEB,
      ntakMennyisegEgyseg: MennyisegEgysegs.DARAB,
      ntakQty: 1,
      vatValues: [],
    };
    this.setState({
      editHour: undefined,
      showModal: true,
      title: Language.getName(Names.NewProduct),
      edit,
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, ntakIntegrations }) => {
      if (language !== undefined)
        this.setState({ language });
      if (ntakIntegrations !== undefined)
        this.loadContext();
    });

    EventSystem.subscribe(EventSystem.events.extras_changed, () => {
      if (!this.state.showModal)
        return;
      ExtrasAPI.getExtras(res => {
        if (res.error !== 0)
          return;
        this.setState({ extras: res.extras });
      });
    });

    EventSystem.subscribe(EventSystem.events.edit_product,
      ({ product, categoryID, categories, extras, copy, type }) => {

        if (type === ProductType.PRODUCT && !categories)
          return;

        if (product && !product.globalCategoryID)
          product.globalCategoryID = -1;

        let categoryOptions = [];
        let globalCategoryOptions = [];
        let ntakMainCategoryOptions = [];
        let ntakSubCategoryOptions = [];
        let ntakMennyisegEgysegOptions = [];
        let productsOptions: OptionValue[] = [];

        let selectedCategory: OptionValue = undefined;
        let selectedGlobalCategory: OptionValue = this.state.selectedGlobalCategory;
        let selectedNtakMainCategory: OptionValue = this.state.selectedNtakMainCategory;
        let selectedNtakSubCategory: OptionValue = this.state.selectedNtakSubCategory;
        let selectedNtakMennyisegEgyseg: OptionValue = this.state.selectedNtakMennyisegEgyseg;

        for (let p: Product of ContextSystem.products.filter(
          p => p.enabled && p.partnerID === ContextSystem.selectedShop?.id)) {
          if (p.type === ProductType.PRODUCT)
            productsOptions.push({
              value: p.id,
              label: TranslatableString.get(p.name) + " (" + p.price + " Ft)",
            });
        }

        if (type === ProductType.PRODUCT) {
          for (let c: Category of categories) {
            let label = TranslatableString.get(c.name);
            if (c.parent !== 0)
              label = TranslatableString.get(
                ProductEditor.getCategoryById(c.parent, categories).name) + " > " + TranslatableString.get(c.name);

            let cOption = { value: c.id, label };
            if (c.id === categoryID)
              selectedCategory = cOption;
            categoryOptions.push(cOption);
          }
        }

        for (let gc of ContextSystem.globalCategories) {
          let option = { value: gc.id, label: gc.icon2 + " " + TranslatableString.get(gc.name) };

          if (product && gc.id === product.globalCategoryID) {
            selectedGlobalCategory = option;
          }
          globalCategoryOptions.push(option);
        }

        for (let c of Object.keys(NTAKMainCategories)) {
          let option = { value: c, label: c };

          if (product && c === product.ntakMainCategory) {
            selectedNtakMainCategory = option;
          }
          ntakMainCategoryOptions.push(option);
        }

        for (let c of Object.keys(NTAKSubCategories)) {
          let option = { value: c, label: c };

          if (product && c === product.ntakSubCategory) {
            selectedNtakSubCategory = option;
          }
          ntakSubCategoryOptions.push(option);
        }

        for (let c of Object.keys(MennyisegEgysegs)) {
          let option = { value: c, label: c };

          if (product && c === product.ntakMennyisegEgyseg) {
            selectedNtakMennyisegEgyseg = option;
          }
          ntakMennyisegEgysegOptions.push(option);
        }

        this.setState({
          productsOptions,
          extras,
          categories,
          categoryOptions,
          globalCategoryOptions,
          ntakMainCategoryOptions,
          ntakSubCategoryOptions,
          ntakMennyisegEgysegOptions,
          selectedCategory,
          selectedGlobalCategory,
          selectedNtakMainCategory,
          selectedNtakSubCategory,
          selectedNtakMennyisegEgyseg,
        });

        if (copy) {
          this.copyProduct(product);
        } else {
          if (!product)
            this.addNewProduct(categoryID, type);
          else
            this.editProduct(product);
        }
      },
    );

    EventSystem.subscribe(EventSystem.events.extras_selected, ({ extraGroups, versions }) => {
      if (!this.state.showModal) return;
      let edit = { ...this.state.edit };
      edit.extraGroups = extraGroups;
      edit.versions = versions;
      this.setState({ edit });
    });

    EventSystem.subscribe(EventSystem.events.image_uploaded, (image: Image) => {
      if (!this.state.showModal)
        return;
      this.addImage(image);
    });

    this.loadContext();
  }

  loadNTAKSubCategories() {
    let ntakSubCategoryOptions = [];
    let selectedNtakSubCategory: OptionValue = undefined;

    let subs = NTAKAvailableSubCategories.find(
      nn => nn.mainCategory === this.state?.selectedNtakMainCategory?.value)?.subs;

    if (subs) {
      for (let c: NTAKSubCategory of subs) {
        let option = { value: c, label: c };
        if (this.state.edit?.ntakSubCategory === c) {
          selectedNtakSubCategory = option;
        }
        ntakSubCategoryOptions.push(option);
      }
    }

    this.setState({
      ntakSubCategoryOptions,
      selectedNtakSubCategory,
    });
  }

  loadContext() {
    this.setState({
      ntakIntegrations: ContextSystem.ntakIntegrations.filter(n => n.partnerID === ContextSystem.selectedShop?.id),
    });
  }

  static parsePrice(v: string | number): string {
    if (!isNaN(v))
      return v + "";

    let fv: number = parseFloat(v.replaceAll(",", "."));
    if (isNaN(fv))
      return "";
    else
      return fv.toLocaleString() + (v.endsWith(",") || v.endsWith(".") ? v.substring(v.length - 1) : "");
  }

  changeQty(product, qty) {
    if (isNaN(qty)) qty = "";
    else {
      if (qty <= 0) {
        toast(Language.getName(Names.MinQuantity));
        return;
      }
    }
    let edit = { ...this.state.edit };
    let products = [];
    for (let p of edit.products) {
      let pr = { ...p };
      if (p.id === product.id)
        pr.qty = qty;
      products.push(pr);
    }
    edit.products = products;
    this.setState({ edit });
  }

  removeSelectedProduct(productID: number) {
    let edit = { ...this.state.edit };
    let products: Product[] = [];
    for (let p of edit.products) {
      if (p.id !== productID)
        products.push({ ...p });
    }
    edit.products = products;
    this.setState({ edit });
  }

  addProduct({ value: productID }) {
    let edit = { ...this.state.edit };
    let products = [];
    if (edit.products)
      for (let p of edit.products) {
        if (p.id === productID) {
          toast(Language.getName(Names.ProductIsAlreadyInMenu));
          return;
        }
        products.push({ ...p });
      }
    for (let p of ContextSystem.products) {
      if (p.id === productID && p.type === ProductType.PRODUCT) {
        let pr = { ...p };
        pr.qty = 1;
        products.push(pr);
        if (pr.image) {
          if (!edit.image || edit.image === "")
            edit.image = pr.image;
          else if (!edit.image2 || edit.image2 === "")
            edit.image2 = pr.image;
          else if (!edit.image3 || edit.image3 === "")
            edit.image3 = pr.image;
        }
        break;
      }
    }
    edit.products = products;
    this.setState({ edit });
  }

  selectCategory({ value: categoryID, label }) {
    let edit = { ...this.state.edit };
    edit.categoryID = categoryID;
    this.setState({
      selectedCategory: arguments[0],
      edit,
    });
  }

  selectGlobalCategory({ value: globalCategoryID, label }) {
    let edit = { ...this.state.edit };
    edit.globalCategoryID = globalCategoryID;
    this.setState({
      selectedGlobalCategory: arguments[0],
      edit,
    });
  }

  selectNTAKMainCategory({ value: category, label }) {
    let edit = { ...this.state.edit };
    edit.ntakMainCategory = category;
    this.setState({
      selectedNtakMainCategory: arguments[0],
      edit,
    }, () => {
      this.loadNTAKSubCategories();
    });
  }

  selectNTAKSubCategory({ value: category, label }) {
    let edit = { ...this.state.edit };
    edit.ntakSubCategory = category;
    this.setState({
      selectedNtakSubCategory: arguments[0],
      edit,
    });
  }

  selectNTAKMennyisegEgyseg({ value: me, label }) {
    let edit = { ...this.state.edit };
    edit.ntakMennyisegEgyseg = me;
    this.setState({
      selectedNtakMennyisegEgyseg: arguments[0],
      edit,
    });
  }

  addImage(image: Image) {
    let edit = { ...this.state.edit };
    if (!edit.image || edit.image === "") {
      edit.image = image.fileName;
    } else if (!edit.image2 || edit.image2 === "") {
      edit.image2 = image.fileName;
    } else if (!edit.image3 || edit.image3 === "") {
      edit.image3 = image.fileName;
    }
    this.setState({ edit });
  }

  openImageUploader() {
    EventSystem.publish(EventSystem.events.upload_image, { uploadType: ImageTypes.PRODUCT });
  }

  removeImage(index) {
    let edit = { ...this.state.edit };
    let key = "image" + index;
    if (index === 1) key = "image";
    edit[key] = "";
    this.setState({ edit });
  }

  handleAddHour() {
    let editHour: EditHourType = {
      editDay: HourEditor.buildOptions()[0],
      editTimeRange: ["06:00", "10:00"],
      modifyingHourID: -1,
    };
    this.setState({ editHour });
  }

  deleteHour(h: Hour) {
    let weekSchedule: Hour[] = this.state.edit.weekSchedule.filter(w => w !== h);
    let edit: Product = { ...this.state.edit };
    edit.weekSchedule = weekSchedule;
    this.setState({ edit });
  }

  addHour(h: EditHourType) {
    let validated = DetailedSettings.validateHourEdit(h, true);

    if (!validated)
      return;

    let edit: Product = { ...this.state.edit };
    edit.weekSchedule.push({
      id: -1,
      openHour: { hour: validated.openHour, minute: validated.openMinute },
      closeHour: { hour: validated.closeHour, minute: validated.closeMinute },
      day: validated.day,
      enabled: true,
      hourType: HourTypes.PRODUCT_AVAILABILITY,
      partnerID: ContextSystem.selectedShop.id,
    });
    this.setState({ edit, editHour: undefined });
  }

  toggleTaxStampedProduct() {
    let edit: Product = { ...this.state.edit };
    let vatValues: VATValue[] = [];

    let toggled = edit?.vatValues?.find(vv => vv.value === VATValue.D_AJT) !== undefined;

    if (!toggled) {
      this.setVatValue(VATValue.D_AJT, ShippingMethods.AT_PLACE);
      this.setVatValue(VATValue.D_AJT, ShippingMethods.DELIVERY);
      this.setVatValue(VATValue.D_AJT, ShippingMethods.PICKUP);
      return;
    }

    edit.vatValues = vatValues;
    this.setState({ edit });
  }

  setVatValue(value: number, shippingMode: ShippingMethod) {
    let edit: Product = { ...this.state.edit };
    let vatValues: VATValue[] = [];

    edit.vatValues.forEach(vv => {
      if (vv.shippingMode !== shippingMode)
        vatValues.push({ ...vv });
    });
    vatValues.push({
      id: -1,
      shippingMode: shippingMode,
      type: VATValueModelTypes.PRODUCT,
      value: value,
      date: new Date(),
      enabled: true,
      modelID: -1,
      paymentMode: PaymentMethodTypes.ANY,
      shopID: ContextSystem.selectedShop?.id ?? -1,
    });
    edit.vatValues = vatValues;

    this.setState({ edit });
  }

  setName(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let name: TranslatableString = this.state.edit.name;
    if (!name)
      name = TranslatableString.create("");
    name.translation = name.translation.filter(t => t.languageCode !== languageCode);
    name.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        name: name,
      },
    });
  }

  setDetails(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let details: TranslatableString = this.state.edit.details;
    if (!details)
      details = TranslatableString.create("");
    details.translation = details.translation.filter(t => t.languageCode !== languageCode);
    details.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        details: details,
      },
    });
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    let isWeekSchedule: boolean = (this.state.edit?.weekSchedule?.length ?? 0) > 0;
    let isWeekSchedulePerDay: boolean[] = [false, false, false, false, false, false, false];

    if (isWeekSchedule) {
      for (let i = 1; i <= 7; i++) {
        for (let hour of this.state.edit.weekSchedule) {
          if (hour.day === i) {
            isWeekSchedulePerDay[i - 1] = true;
            break;
          }
        }
      }
    }

    let hasNtakIntegration: boolean = this.state.ntakIntegrations.length > 0;
    let taxStampedProduct: boolean = this.state.edit?.vatValues.find(vv => vv.value === VATValue.D_AJT) !== undefined;

    return (
      <Modal size={"full"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id })}
          />
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && this.state.edit && (
            <>
              <SubSection>
                <SubSectionLabel width={"calc(20% - 6px)"}>
                  {Language.getName(Names.Categories)}:
                </SubSectionLabel>
                <Select
                  width={this.state.edit?.type === ProductType.PRODUCT ? "39%" : "89%"}
                  placeholder={Language.getName(Names.ChooseCategory)}
                  value={this.state.selectedGlobalCategory}
                  onChange={(e) => this.selectGlobalCategory(e)}
                  options={this.state.globalCategoryOptions}
                  noOptionsMessage={() => Language.getName(Names.NoCategoriesAvailable)}
                />
                {this.state.edit?.type === ProductType.PRODUCT &&
                  <Select
                    width={"39%"}
                    placeholder={Language.getName(Names.ChooseRestaurantCategory)}
                    value={this.state.selectedCategory}
                    onChange={(e) => this.selectCategory(e)}
                    options={this.state.categoryOptions}
                    noOptionsMessage={() => Language.getName(Names.NoCategoriesAvailable)}
                  />
                }
              </SubSection>
              {hasNtakIntegration &&
                <SubSection>
                  <SubSectionLabel width={"calc(20% - 6px)"}>
                    {Language.getName(Names.NTAKCategories)}:
                  </SubSectionLabel>
                  <Select
                    width={"39%"}
                    placeholder={Language.getName(Names.ChooseNTAKMainCategory)}
                    value={this.state.selectedNtakMainCategory}
                    onChange={(e) => this.selectNTAKMainCategory(e)}
                    options={this.state.ntakMainCategoryOptions}
                    noOptionsMessage={() => Language.getName(Names.NoCategoriesAvailable)}
                  />
                  <Select
                    width={"39%"}
                    placeholder={Language.getName(Names.ChooseNTAKSubCategory)}
                    value={this.state.selectedNtakSubCategory}
                    onChange={(e) => this.selectNTAKSubCategory(e)}
                    options={this.state.ntakSubCategoryOptions}
                    noOptionsMessage={() => Language.getName(Names.NoCategoriesAvailable)}
                  />
                </SubSection>
              }
              <SubSection>
                <SubSectionLabel width={"calc(20% - 6px)"}>{Language.getName(Names.Description)}: </SubSectionLabel>
                <Input
                  width={"80%"}
                  value={this.state.edit?.name?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
                  type="text"
                  margin={"5px 0"}
                  placeholder={Language.getName(Names.ShortName)}
                  onChange={e => this.setName(e.target.value)}
                />
              </SubSection>
              <SubSection>
                <SubSectionLabel width={"calc(20% - 6px)"}>{Language.getName(Names.Description)}: </SubSectionLabel>
                <Input
                  value={this.state.edit?.details?.translation?.find(
                    tr => tr.languageCode === languageCode)?.value ?? ""}
                  type="textarea"
                  as={"textarea"}
                  margin={"5px 0"}
                  placeholder={Language.getName(Names.Description)}
                  onChange={e => this.setDetails(e.target.value)}
                  width={"80%"}
                />
              </SubSection>
              <SubSection>
                <SubSectionLabel width={"calc(20% - 6px)"}>{Language.getName(Names.Price)}: </SubSectionLabel>
                <Input
                  value={this.state.edit ? this.state.edit.price : ""}
                  margin={"5px 0"}
                  type="text"
                  placeholder={Language.getName(Names.Price)}
                  onChange={(e) =>
                    this.setState({
                      edit: {
                        ...this.state.edit,
                        price: ProductEditor.parsePrice(e.target.value),
                      },
                    })
                  }
                  width={"80%"}
                />
              </SubSection>
              <SubSection>
                <SubSectionLabel width={"calc(20% - 6px)"}>{Language.getName(Names.OriginalPrice)}: </SubSectionLabel>
                <Input
                  value={this.state.edit ? this.state.edit.originalPrice : ""}
                  type="text"
                  placeholder={Language.getName(Names.OriginalPrice)}
                  onChange={(e) =>
                    this.setState({
                      edit: {
                        ...this.state.edit,
                        originalPrice: ProductEditor.parsePrice(e.target.value),
                      },
                    })
                  }
                  width={"80%"}
                />
              </SubSection>
              <SubSection>
                <SubSectionLabel width={"calc(20% - 6px)"}>{Language.getName(Names.ShowPrice)}: </SubSectionLabel>
                <Input
                  value={this.state.edit ? this.state.edit.showPrice : ""}
                  type="text"
                  placeholder={Language.getName(Names.ShowPrice)}
                  onChange={(e) =>
                    this.setState({
                      edit: {
                        ...this.state.edit,
                        showPrice: ProductEditor.parsePrice(e.target.value),
                      },
                    })
                  }
                  width={"80%"}
                />
              </SubSection>
              {hasNtakIntegration &&
                <SubSection align={"center"} justify={"flex-start"}>
                  <SubSectionLabel width={"30%"}>{Language.getName(Names.NTAKMennyiseg)}: </SubSectionLabel>
                  <Input
                    width={"15%"}
                    margin={"0 6px"}
                    padding={"3px 5px"}
                    value={this.state.edit ? this.state.edit.ntakQty : ""}
                    type="text"
                    placeholder={Language.getName(Names.NTAKMennyiseg)}
                    onChange={(e) =>
                      this.setState({
                        edit: {
                          ...this.state.edit,
                          ntakQty: ProductEditor.parsePrice(e.target.value),
                        },
                      })
                    }
                  />
                  <Select
                    margin={"5px 0"}
                    width={"29%"}
                    placeholder={Language.getName(Names.ChooseNTAKMennyisegEgyseg)}
                    value={this.state.selectedNtakMennyisegEgyseg}
                    onChange={(e) => this.selectNTAKMennyisegEgyseg(e)}
                    options={this.state.ntakMennyisegEgysegOptions}
                    noOptionsMessage={() => Language.getName(Names.NoItemsAvailable)}
                  />
                </SubSection>
              }
              <Flex>
                <CheckBox>
                  <input
                    type={"checkbox"}
                    checked={this.state.edit?.countStock}
                    onChange={() => this.setState({
                      edit: {
                        ...this.state.edit,
                        countStock: !this.state.edit.countStock,
                      },
                    })}
                  />
                  <Label onClick={() => this.setState({
                    edit: {
                      ...this.state.edit,
                      countStock: !this.state.edit.countStock,
                    },
                  })}>
                    {Language.getName(Names.CountedStock)}
                  </Label>
                </CheckBox>
                <CheckBox>
                  <input
                    type={"checkbox"}
                    checked={this.state.edit?.availablePOS}
                    onChange={() => this.setState({
                      edit: {
                        ...this.state.edit,
                        availablePOS: !this.state.edit.availablePOS,
                      },
                    })}
                  />
                  <Label onClick={() => this.setState({
                    edit: {
                      ...this.state.edit,
                      availablePOS: !this.state.edit.availablePOS,
                    },
                  })}>
                    {Language.getName(Names.AvailablePOS)}
                  </Label>
                </CheckBox>
                <CheckBox>
                  <input
                    type={"checkbox"}
                    checked={this.state.edit?.availableOnline}
                    onChange={() => this.setState({
                      edit: {
                        ...this.state.edit,
                        availableOnline: !this.state.edit.availableOnline,
                      },
                    })}
                  />
                  <Label onClick={() => this.setState({
                    edit: {
                      ...this.state.edit,
                      availableOnline: !this.state.edit.availableOnline,
                    },
                  })}>
                    {Language.getName(Names.AvailableOnline)}
                  </Label>
                </CheckBox>
              </Flex>
              <SubSection justify={"flex-start"}>
                <SubSectionLabel>{Language.getName(Names.VATValues)}</SubSectionLabel>
                <newline />
                <CheckBox>
                  <input
                    type={"checkbox"}
                    checked={taxStampedProduct}
                    onChange={() => this.toggleTaxStampedProduct()}
                  />
                  <Label onClick={() => this.toggleTaxStampedProduct()}>
                    {Language.getName(Names.D_AJT)}
                  </Label>
                </CheckBox>
                <newline />
                {!taxStampedProduct && Object.keys(ShippingMethods).map((shName, i) => {
                  let sh: number = parseInt(ShippingMethods[shName]);
                  if ([ShippingMethods.VENDOR_DELIVERY, ShippingMethods.ANY].includes(sh))
                    return <React.Fragment key={i} />;
                  let label: string = Orders.getShippingMethodVATString(sh);
                  return (
                    <InputWrapper margin={"0 6px"} key={i} width={"calc(33% - 12px)"}>
                      <Label>{label}</Label>
                      <Input
                        value={this.state?.edit?.vatValues?.find(v => v.shippingMode === sh)?.value}
                        type="number"
                        autoComplete={"off"}
                        placeholder={label}
                        onChange={(e) => this.setVatValue(ProductEditor.parsePrice(e.target.value), sh)}
                        width={"100%"}
                      />
                    </InputWrapper>
                  );
                })}
              </SubSection>
              <ButtonsWrapper>
                <Button
                  margin={"0 6px 0 0"}
                  variant="secondary"
                  onClick={() =>
                    EventSystem.publish(EventSystem.events.select_extras, {
                      extras: this.state.extras,
                      extraGroups: this.state.edit.extraGroups,
                      versions: this.state.edit.versions,
                      product: this.state.edit,
                    })
                  }
                >
                  {Language.getName(Names.EditExtrasButtonText)}
                  {" ("}{(this.state.edit?.extraGroups?.length) ?? 0}
                  {" " + Language.getName(Names.EditExtrasButtonTextPostfix) + ")"}
                </Button>
                <Button
                  margin={"0 0 0 6px"}
                  variant="secondary"
                  onClick={() =>
                    EventSystem.publish(EventSystem.events.edit_recipe_qty, {
                      recipes: this.state.edit.recipes, cb: (recipes) => {
                        this.setState({
                          edit: {
                            ...this.state.edit,
                            recipes: recipes,
                          },
                        });
                      },
                    })
                  }
                >
                  {Language.getName(Names.EditProductRecipes)}
                </Button>
              </ButtonsWrapper>
              <ImagesWrapper>
                {this.state.edit && this.state.edit.image && (
                  <span>
                <FaTrashAlt onClick={() => this.removeImage(1)} />
                <img src={Config.productImageUrlBase + this.state.edit.image} alt={"image"} />
              </span>
                )}
                {!(this.state.edit && this.state.edit.image) && (
                  <div onClick={() => this.openImageUploader()}>
                    <FaPlus />
                    <span>{Language.getName(Names.UploadImage)}</span>
                  </div>
                )}
                {this.state.edit && this.state.edit.image2 && (
                  <span>
                <FaTrashAlt onClick={() => this.removeImage(2)} />
                <img src={Config.productImageUrlBase + this.state.edit.image2} alt={"image2"} />
              </span>
                )}
                {!(this.state.edit && this.state.edit.image2) && (
                  <div onClick={() => this.openImageUploader()}>
                    <FaPlus />
                    <span>{Language.getName(Names.UploadImage)}</span>
                  </div>
                )}
                {this.state.edit && this.state.edit.image3 && (
                  <span>
                <FaTrashAlt onClick={() => this.removeImage(3)} />
                <img src={Config.productImageUrlBase + this.state.edit.image3} alt={"image2"} />
              </span>
                )}
                {!(this.state.edit && this.state.edit.image3) && (
                  <div onClick={() => this.openImageUploader()}>
                    <FaPlus />
                    <span>{Language.getName(Names.UploadImage)}</span>
                  </div>
                )}
              </ImagesWrapper>
              <p />
              <p>{Language.getName(Names.WeekScheduleText)}</p>
              <WeekScheduleEditor>
                <WeekTable>
                  {[1, 2, 3, 4, 5, 6, 7].map((day, i) => {
                    let iDayRecords: Hour[] = this.state.edit?.weekSchedule?.filter(h => h.day === day) ?? [];
                    return (
                      <WeekTableColumn key={i}>
                        <WeekTableCell header={true}>
                          {DaysName(day)}
                        </WeekTableCell>
                        {isWeekSchedulePerDay[day - 1] === false &&
                          <WeekTableCell>
                            <span>{Language.getName(Names.AllDay)}</span>
                          </WeekTableCell>
                        }
                        {isWeekSchedulePerDay[day - 1] === true && iDayRecords.map((dayH, j) => {
                          let openH = dayH.openHour.hour > 9 ? dayH.openHour.hour : "0" + dayH.openHour.hour;
                          let openM = dayH.openHour.minute > 9 ? dayH.openHour.minute : "0" + dayH.openHour.minute;
                          let closeH = dayH.closeHour.hour > 9 ? dayH.closeHour.hour : "0" + dayH.closeHour.hour;
                          let closeM = dayH.closeHour.minute > 9 ? dayH.closeHour.minute : "0" + dayH.closeHour.minute;

                          return (
                            <WeekTableCell key={j}>
                              <span>{openH}:{openM} - {closeH}:{closeM}</span>
                              <HeaderButtonWrapper button_padding={"4px"} margin_top={"0"} width={"fit-content"}>
                                <HeaderButton svg_font_size={"11pt"}>
                                  <FaTrashAlt onClick={() => this.deleteHour(dayH)} />
                                </HeaderButton>
                              </HeaderButtonWrapper>
                            </WeekTableCell>
                          );
                        })}
                      </WeekTableColumn>
                    );
                  })}
                  <WeekTableColumn width={"100%"} margin={"10px 0 0 0"} align={"center"}>
                    {!this.state.editHour &&
                      <HeaderButtonWrapper button_padding={"6px"} margin_top={"0"} width={"fit-content"}>
                        <HeaderButton svg_font_size={"15pt"}>
                          <FaPlus onClick={() => this.handleAddHour()} />
                        </HeaderButton>
                      </HeaderButtonWrapper>
                    }
                    {this.state.editHour &&
                      <HourEditor
                        value={this.state.editHour}
                        onChange={(editHour) => this.setState({ editHour })}
                        onCancel={() => this.setState({ editHour: undefined })}
                        onSave={() => this.addHour(this.state.editHour)}
                      />
                    }
                  </WeekTableColumn>
                </WeekTable>
              </WeekScheduleEditor>
              {this.state.edit?.type === ProductType.MENU &&
                <>
                  <p>{Language.getName(Names.LinkedSubProducts)}</p>
                  <ProductsList>
                    <SelectedProduct header={true}>
                      <div>
                        <b>#</b>
                      </div>
                      <div>
                        <b>{Language.getName(Names.ProductName)}</b>
                      </div>
                      <div>
                        <b>{Language.getName(Names.Quantity)}</b>
                      </div>
                    </SelectedProduct>
                    {this.state.edit && this.state.edit.products && this.state.edit.products.map((p, i) => {
                      return (
                        <SelectedProduct key={i}>
                          <div>{p.id}</div>
                          <div>
                            {TranslatableString.get(p.name)} ({p.price} Ft)
                          </div>
                          <SmallInput
                            value={p.qty}
                            type="text"
                            placeholder={Language.getName(Names.Quantity)}
                            onChange={(ev) => this.changeQty(p, parseInt(ev.target.value))}
                            width={"20%"}
                          />
                          <div>
                            <FaTrashAlt onClick={() => this.removeSelectedProduct(p.id)} />
                          </div>
                        </SelectedProduct>
                      );
                    })}
                  </ProductsList>
                  <Select
                    placeholder={Language.getName(Names.Search) + "..."}
                    onChange={(e) => this.addProduct(e)}
                    options={this.state.productsOptions}
                    noOptionsMessage={() => Language.getName(Names.NoProductToShow)}
                  />
                </>
              }
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
