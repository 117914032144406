import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import type { Element } from "../../model/BluePrint";
import { ElementTypes, Zone } from "../../model/BluePrint";
import { BluePrintAPI } from "../../utils/api/BluePrintAPI";
import { Button, Input } from "../FormComponents";
import SelectOrg from "react-select";
import { SubSection } from "../home/DetailedSettings";
import { Label } from "./ContractsDetailsModal";
import { CheckBox } from "../home/Orders";
import type { OptionValue } from "./InwardHandlingModal";
import styled from "styled-components";

const Select = styled(SelectOrg)`
  width: 100%;
  height: 25px;
  margin-bottom: 12px;
`;

export default class SimpleTableEditor extends Component {
  eventIDs: number[] = [];

  state: {
    showModal: boolean,
    edit: null | Element,
    title: string,
    zones: Zone[],
    language: number,
    selectedZone: OptionValue,
    zoneOptions: OptionValue[],
  } = {
    showModal: false,
    edit: null,
    zones: [],
    title: Language.getName(Names.NewTable),
    language: ContextSystem.language,
    selectedZone: undefined,
    zoneOptions: [],
  };

  loadContext() {
    let zoneOptions: OptionValue[] = [];
    let zones: Zone[] = ContextSystem.zones.filter(z => z.enabled && z.partnerID === ContextSystem.selectedShop?.id);

    let defaultValue: OptionValue = { value: -1, label: Language.getName(Names.WithoutZone) };
    zoneOptions.push(defaultValue);
    zones.forEach(z => zoneOptions.push({ value: z.id, label: z.name }));

    this.setState({
      zones,
      zoneOptions,
      language: ContextSystem.language,
    });

    if (!this.state.selectedZone)
      this.selectZone(defaultValue);
  }

  save() {
    let element: Element = { ...this.state.edit };
    if (!element.name || element.name.length < 2) {
      toast(Language.getName(Names.NameTooShort));
      return;
    }

    BluePrintAPI.saveSimpleTable(element, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  edit(element: Element) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyTable),
      edit: element,
      selectedZone: {
        value: element.zoneID,
        label: element.zoneID === -1 ? Language.getName(Names.WithoutZone) : ContextSystem.zones.find(
          (z: Zone) => z.id === element.zoneID)?.name ?? "--",
      },
    });
  }

  addNew() {
    this.setState({
      showModal: true,
      title: Language.getName(Names.NewTable),
      edit: {
        id: -1,
        enabled: true,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        zoneID: -1,
        partnerID: -1,
        pairedQRID: 1_000_000_000,
        rotate: 0,
        image: "",
        name: "",
        type: ElementTypes.TABLE,
        minCover: 1,
        maxCover: 2,
        acceptOnlineBooking: true,
      },
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs)
      EventSystem.unsubscribe(eid);
  }

  componentDidMount() {
    this.loadContext();
    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.edit_simpleTable, (element) => {
      if (!element)
        this.addNew();
      else
        this.edit(element);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, zones }) => {
      if (language !== undefined || zones !== undefined)
        this.loadContext();
    });
    this.eventIDs.push(eid);
  }

  selectZone(v: OptionValue) {
    this.setState({
      selectedZone: v,
      edit: {
        ...this.state.edit,
        zoneID: v.value,
      },
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        {this.state.showModal &&
          <Modal.Body>
            <label>{Language.getName(Names.Name)}</label>
            <Input
              value={this.state.edit?.name ?? ""}
              type="text"
              placeholder={Language.getName(Names.Name)}
              onChange={(e) =>
                this.setState({
                  edit: { ...this.state.edit, name: e.target.value },
                })
              }
              width={"100%"}
            />
            <label>{Language.getName(Names.QR_ID)}</label><br />
            <label>{Language.getName(Names.QR_WillBeGenerated)}</label><br />
            <br />
            <label>{Language.getName(Names.CoverRange)}, {Language.getName(Names.Min)
              .toLowerCase()} - {Language.getName(Names.Max).toLowerCase()} {Language.getName(Names.person)}</label>
            <SubSection>
              <Input
                value={this.state.edit ? this.state.edit.minCover : ""}
                min={1}
                max={50}
                type="number"
                placeholder={Language.getName(Names.MinCover)}
                onChange={(e) =>
                  this.setState({
                    edit: {
                      ...this.state.edit,
                      minCover: isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value),
                      maxCover: parseInt(e.target.value) > this.state.edit.maxCover
                                ? parseInt(e.target.value)
                                : this.state.edit.maxCover,
                    },
                  })
                }
                width={"49%"}
              />
              <Input
                value={this.state.edit ? this.state.edit.maxCover : ""}
                min={1}
                max={50}
                type="number"
                placeholder={Language.getName(Names.MaxCover)}
                onChange={(e) =>
                  this.setState({
                    edit: {
                      ...this.state.edit,
                      maxCover: isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value),
                      minCover: parseInt(e.target.value) < this.state.edit.minCover
                                ? parseInt(e.target.value)
                                : this.state.edit.minCover,
                    },
                  })
                }
                width={"49%"}
              />
            </SubSection>
            <SubSection>
              <Select
                width={"49%"}
                placeholder={Language.getName(Names.ChooseZone)}
                value={this.state.selectedZone}
                onChange={(e: OptionValue) => this.selectZone(e)}
                options={this.state.zoneOptions}
              />
              <CheckBox>
                <input
                  type={"checkbox"}
                  checked={this.state.edit?.acceptOnlineBooking ?? false}
                  onChange={e => this.setState({
                    edit: {
                      ...this.state.edit,
                      acceptOnlineBooking: e.target.checked,
                    },
                  })}
                />
                <Label onClick={() => this.setState({
                  edit: {
                    ...this.state.edit,
                    acceptOnlineBooking: !this.state.edit.acceptOnlineBooking,
                  },
                })}>
                  {Language.getName(Names.AcceptOnlineBookings)}
                </Label>
              </CheckBox>
            </SubSection>
          </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
