import React, { Component } from "react";
import styled from "styled-components";
import { Menu, MenuBody, MenuHead, MenuProduct, OrderExtraDiv, OrderExtraWrapper, OrderProductNoteEditor, OrderProductNoteEditorWrapper, ProductDiv, ProductDiv as OrderProductDiv } from "./Cart";
import { ShippingMethods, ShippingPrice } from "../model/ShippingPrice";
import { Product, ProductTypes, TranslatableString } from "../model/Product";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import CartCalc from "../utils/CartCalc";
import Language, { Names } from "../utils/Language";
import { Order, OrderProduct } from "../model/Order";
import { getOpeningState } from "../utils/HoursCalc";
import { HourStatuses } from "../model/Hour";
import { AiFillCaretDown, AiFillCaretRight, BsPencilFill, FaTrashAlt, FiMinus, GoPlus } from "react-icons/all";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const OrderSumUpBody = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 5px 5px 5px;
  overflow: hidden;
  position: relative;
`;

const DisabledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  font-size: 10pt;
  width: 100%;
  color: #ff8442;
  font-weight: bold;
`;

type S = {
  order: Order,
  products: Product[],
  availableShippingPrices: ShippingPrice[],
  loadingAvailableShippingPrices: boolean,
  shippingMethod: number,
  menuOpened: { [keys: number]: boolean },
  showShippingPrices: boolean,
  showModificationButtons: boolean,
  fontSize: string,
  showTotal: boolean,
  language: number,
  scheduleDate: Date,
  increaseItem: (p: Product, qty: number)=>{},
  removeItem: (p: Product)=>{},
  onProductClicked: (p: Product)=>{},
  showPrices: boolean,
  showOriginalPrice: boolean,
  showSplitPrice: boolean,
  setNote: (p: Product)=>{},
  showNote: boolean
}

export type P = {
  showModificationButtons: boolean,
  products: Product[],
  showShippingPrices: boolean,
  showTotal: boolean,
  increaseItem: (p: Product, qty: number)=>{},
  removeItem: (p: Product)=>{},
  fontSize: string,
  onProductClicked: (p: Product)=>{},
  showPrices: boolean,
  showOriginalPrice: boolean,
  showSplitPrice: boolean,
  setNote: (p: Product)=>{},
  showNote: boolean
}

export default class CartContent extends Component<P> {
  eventIDs: number[] = [];

  state: S = {
    products: [],
    availableShippingPrices: [],
    loadingAvailableShippingPrices: false,
    shippingMethod: ShippingMethods.PICKUP,
    menuOpened: {},
    showShippingPrices: true,
    showTotal: true,
    language: ContextSystem.language,
    scheduleDate: null,
    increaseItem: undefined,
    removeItem: undefined,
    onProductClicked: undefined,
    showModificationButtons: true,
    showPrices: true,
    setNote: undefined,
    showNote: true
  };

  loadProps() {
    this.setState({
      products: this.props.products,
      fontSize: this.props.fontSize,
      showModificationButtons: this.props.showModificationButtons,
      setNote: this.props.setNote,
      showShippingPrices: this.props.showShippingPrices,
      showTotal: this.props.showTotal,
      increaseItem: this.props.increaseItem,
      removeItem: this.props.removeItem,
      onProductClicked: this.props.onProductClicked,
      showPrices: this.props.showPrices,
      showOriginalPrice: this.props.showOriginalPrice,
      showSplitPrice: this.props.showSplitPrice,
      showNote: this.props.showNote
    });
  }

  loadContext() {
    this.setState({
      availableShippingPrices: ContextSystem.shippingPrices.filter(
        s => s.partnerID === ContextSystem.selectedShop.id && s.active === true && s.enabled === true),
    });
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot) {
    if (
      this.props.products !== this.state.products ||
      this.props.showShippingPrices !== this.state.showShippingPrices ||
      this.props.showTotal !== this.state.showTotal ||
      this.props.increaseItem !== this.state.increaseItem ||
      this.props.removeItem !== this.state.removeItem ||
      this.props.onProductClicked !== this.state.onProductClicked ||
      this.props.showPrices !== this.state.showPrices ||
      this.props.showSplitPrice !== this.state.showSplitPrice ||
      this.props.showOriginalPrice !== this.state.showOriginalPrice ||
      this.props.showNote !== this.state.showNote
    ) {
      this.loadProps();
    }
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];

    this.loadProps();
    this.loadContext();

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined) {
        this.setState({ language });
      }
    });
    this.eventIDs.push(eid);
  }

  toggleMenuOpen(index) {
    let menuOpened = { ...this.state.menuOpened };
    menuOpened[index] = menuOpened[index] !== true;
    this.setState({ menuOpened });
  }

  isMenuOpened(index) {
    return this.state.menuOpened[index];
  }

  static handleMinusClick(e: MouseEvent, p: Product, increaseItem: (p: Product)=>{}) {
    e.stopPropagation();
    increaseItem && increaseItem(p, -1);
  }

  static handlePlusClick(e: MouseEvent, p: Product, increaseItem: (p: Product)=>{}) {
    e.stopPropagation();
    increaseItem && increaseItem(p, +1);
  }

  static onProductClickedFunc(e, p: Product, onProductClicked: (p: Product)=>{}) {
    e.stopPropagation();
    if (onProductClicked)
      onProductClicked(p);
  }

  static renderOrderProductCart(
    p: Product,
    i: number,
    isMenuOpened: (i: number)=>{},
    toggleMenuOpen: (i: number)=>{},
    modificationButtons: boolean = true,
    orderProduct: OrderProduct | null = null,
    scheduleDate: Date | null,
    increaseItem: (p: Product, qty: number)=>{},
    removeItem: (p: Product)=>{},
    onProductClicked: (p: Product)=>{},
    showPrices: boolean,
    showOriginalPrice: boolean,
    showSplitPrice: boolean,
    setNote: (p: Product, value: string)=>{},
    showNote: boolean
  ) {
    if (!p)
      return <React.Fragment key={i} />;

    let leadOP = CartCalc.getLeadOrderProduct(p.orderProduct);
    if (orderProduct !== null)
      leadOP = orderProduct;

    let versionName = "";
    let v = CartCalc.getVersionByID(p.versions, leadOP.versionID);
    if (p.versions.length > 1 && v) versionName = "(" + TranslatableString.get(v.name) + ")";

    let isDisabled: boolean = false;

    if (p?.weekSchedule && p.weekSchedule.length > 0) {
      let openStatus = getOpeningState(p.weekSchedule, scheduleDate);
      isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
    }

    let prices = (
      <>{showPrices &&
        <>
          {showOriginalPrice &&
            <>
              <div>{Math.round((leadOP.originalPrice + leadOP.originalExtrasPrice) / (showSplitPrice ? p._splitBy : 1)).toLocaleString() + " Ft"}</div>
              <div>{Math.round(((leadOP.originalPrice + leadOP.originalExtrasPrice) * leadOP.qty) / (showSplitPrice ? p._splitBy : 1))
                .toLocaleString() + " Ft"}</div>
            </>
          }
          {showOriginalPrice !== true &&
            <>
              <div>{Math.round((leadOP.price + leadOP.extrasPrice) / (showSplitPrice ? p._splitBy : 1)).toLocaleString() + " Ft"}</div>
              <div>{Math.round(((leadOP.price + leadOP.extrasPrice) * leadOP.qty) / (showSplitPrice ? p._splitBy : 1))
                .toLocaleString() + " Ft"}</div>
            </>
          }
        </>
      }
      </>
    );

    if (p.type && p.type === ProductTypes.PRODUCT) {
      return (
        <ProductDiv key={i} onClick={e => CartContent.onProductClickedFunc(e, p, onProductClicked)}>
          <div>
            {modificationButtons && onProductClicked &&
              <BsPencilFill style={{ marginRight: "10px", fontSize: "20px", width: "auto" }} />
            }
            {TranslatableString.get(leadOP.name)} {versionName}
            {leadOP.extras.length > 0 &&
              CartContent.renderOrderExtrasInCart(leadOP)
            }
          </div>
          <div onClick={(e: MouseEvent) => e.stopPropagation()} style={{ userSelect: "none" }}>
            {modificationButtons &&
              <>
                <FiMinus style={{ cursor: "pointer", margin: "0 8px", fontSize: "20px", width: "auto" }}
                         onClick={e => this.handleMinusClick(e, p, increaseItem)} />{" "}
              </>
            }
            {leadOP.qty < 10 && <span style={{ visibility: "hidden" }}>0</span>}
            {leadOP.qty} {Language.getName(Names.pcs)}
            {modificationButtons &&
              <>
                <GoPlus style={{ cursor: "pointer", margin: "0 8px", width: "auto", fontSize: "20px" }}
                        onClick={e => this.handlePlusClick(e, p, increaseItem)}
                />
              </>
            }
          </div>
          <div style={{ width: "100%", height: "5px" }} />
          {modificationButtons &&
            <span onClick={e => {
              e.stopPropagation();
              removeItem && removeItem(p);
            }}
            >
            <FaTrashAlt style={{ cursor: "pointer", margin: "0 8px", fontSize: "20px", width: "auto" }} />
          </span>
          }
          {prices}
          <div style={{ width: "100%", height: "3px" }} />
          {isDisabled &&
            <DisabledDiv>
              {Language.getName(Names.ProductNotAvailableNow)}
            </DisabledDiv>
          }
          {showNote && modificationButtons &&
            <OrderProductNoteEditorWrapper>
              <OrderProductNoteEditor
                onClick={e => e.stopPropagation()}
                value={leadOP.note}
                type={"text"}
                width={"100%"}
                onChange={e => setNote(p, e.target.value)}
                placeholder={Language.getName(Names.Note)}
              />
            </OrderProductNoteEditorWrapper>
          }
          {showNote && (!modificationButtons && leadOP.note.length > 0) &&
            <OrderProductNoteEditorWrapper>
              {leadOP.note}
            </OrderProductNoteEditorWrapper>
          }
        </ProductDiv>
      );
    } else if (p.type && p.type === ProductTypes.MENU) {
      return (
        <Menu onClick={e => e.stopPropagation()} key={i}>
          <MenuHead onClick={() => toggleMenuOpen(i)}>
            {!isMenuOpened(i) && <AiFillCaretRight onClick={() => toggleMenuOpen(i)} />}
            {isMenuOpened(i) && <AiFillCaretDown onClick={() => toggleMenuOpen(i)} />}
            {onProductClicked &&
              <BsPencilFill />
            }
            {p.id < 0 && <div>{Language.getName(Names.CombinedMenu)}</div>}
            {p.id > 0 && (
              <div>
                {TranslatableString.get(p.name) + " " + versionName}
                {CartContent.renderOrderExtrasInCart(leadOP)}
              </div>
            )}
            <div onClick={(e) => e.stopPropagation()} style={{ userSelect: "none" }}>
              {modificationButtons &&
                <>
                  <FiMinus style={{ cursor: "pointer" }}
                           onClick={e => this.handleMinusClick(e, p, increaseItem)} />{" "}
                </>
              }
              {leadOP.qty < 10 && <span style={{ visibility: "hidden" }}>0</span>}
              {leadOP.qty} db
              {modificationButtons &&
                <>
                  <GoPlus style={{ cursor: "pointer" }} onClick={e => this.handlePlusClick(e, p, increaseItem)} />
                </>
              }
            </div>
            {prices}
            {modificationButtons &&
              <span onClick={e => {
                e.stopPropagation();
                removeItem(p);
              }
              }>
              <FaTrashAlt />
            </span>
            }
            {showNote && modificationButtons &&
              <OrderProductNoteEditorWrapper>
                <OrderProductNoteEditor
                  onClick={e => e.stopPropagation()}
                  value={leadOP.note}
                  type={"text"}
                  width={"100%"}
                  onChange={e => setNote(p, e.target.value)}
                  placeholder={Language.getName(Names.Note)}
                />
              </OrderProductNoteEditorWrapper>
            }
            {showNote && (!modificationButtons && leadOP.note.length > 0) &&
              <OrderProductNoteEditorWrapper>
                {leadOP.note}
              </OrderProductNoteEditorWrapper>
            }
          </MenuHead>
          <MenuBody show={isMenuOpened(i)}>
            {p && p.products.map((subProduct, j) => {
              let subLeadOP = CartCalc.getLeadOrderProduct(subProduct.orderProduct);
              return (
                <MenuProduct key={j}>
                  <span>
                    {TranslatableString.get(subProduct.name)}
                    {" (" +
                      subProduct.qty +
                      Language.getName(Names.pcs) +
                      (p.id < -1 && subLeadOP.extras.length > 0
                       ? ", " + subLeadOP.extras.length + " " + Language.getName(Names.KindTopping)
                       : "") +
                      ")"}
                  </span>
                  {p.id < -1 && (
                    <span onClick={() => removeItem(subProduct)}>
                      <FaTrashAlt />
                    </span>
                  )}
                </MenuProduct>
              );
            })}
          </MenuBody>
          {isDisabled &&
            <DisabledDiv>
              {Language.getName(Names.ProductNotAvailableNow)}
            </DisabledDiv>
          }
        </Menu>
      );
    } else {
      return <React.Fragment key={i} />;
    }
  }

  static renderOrderExtrasInCart(leadOP: OrderProduct) {
    return (
      <>
        {leadOP.extras.length > 0 &&
          <OrderExtraWrapper>
            {leadOP.extras.map((extra, k) => {
              return (
                <OrderExtraDiv first={k === 0}>
                  <div>{extra.qty + " " + Language.getName(Names.pcs)}</div>
                  <div>{TranslatableString.get(extra.name)}</div>
                </OrderExtraDiv>
              );
            })}
          </OrderExtraWrapper>
        }
      </>
    );
  }

  render() {
    let products = this.state.products;

    let total = 0;
    let shippingPrice = 0;
    if (this.state.showTotal) {
      total = CartCalc.calcOrderProductsTotalPrice(products, this.state.showSplitPrice);
      if (this.state.showShippingPrices) {
        shippingPrice = CartCalc.calcOrderShippingPrice(this.state.availableShippingPrices, total);
        if (this.state.shippingMethod === ShippingMethods.DELIVERY)
          total += shippingPrice;
      }
    }

    return (
      <Wrapper>
        <OrderProductDiv header={true}>
          <div><b>{Language.getName(Names.ProductName)}</b></div>
          <div><b>{Language.getName(Names.Quantity)}</b></div>
          {this.state.showPrices &&
            <>
              <div><b>{Language.getName(Names.Price)}</b></div>
              <div />
            </>
          }
          {/*<div>< b>{Language.getName(Names.Total)}</b></div>*/}
        </OrderProductDiv>
        <OrderSumUpBody>
          {products && products.length > 0 && products.map((p, i) => {
            let k: number = i;
            return (
              <React.Fragment key={i}>
                {CartContent.renderOrderProductCart(p, k, (k) => this.isMenuOpened(k), (k) => this.toggleMenuOpen(k),
                  this.state.showModificationButtons,
                  null,
                  this.state.scheduleDate,
                  this.state.increaseItem,
                  this.state.removeItem,
                  this.state.onProductClicked,
                  this.state.showPrices,
                  this.state.showOriginalPrice,
                  this.state.showSplitPrice,
                  this.state.setNote,
                  this.state.showNote
                )}
              </React.Fragment>
            );
          })}
          {this.state.showShippingPrices && this.state.shippingMethod === ShippingMethods.DELIVERY && (
            <OrderProductDiv>
              <div>{Language.getName(Names.ShippingPrice)}</div>
              <div />
              <div />
              <div>{shippingPrice} Ft</div>
            </OrderProductDiv>
          )}
          {this.state.showTotal &&
            <OrderProductDiv>
              <div />
              <div />
              {this.state.showPrices &&
                <>
                  <div>
                    <b>{Language.getName(Names.Total)}:</b>
                  </div>
                  <div>
                    <b>{total}Ft</b>
                  </div>
                </>
              }
            </OrderProductDiv>
          }
        </OrderSumUpBody>
      </Wrapper>
    );
  }
}
