import React, { Component, useState } from "react";
import styled, { css } from "styled-components";
import { Element, ElementTypes } from "../../../model/BluePrint";
import ContextSystem from "../../../utils/ContextSystem";
import { Shop } from "../../../model/Shop";
import { Order, OrderState, TableReservation, TableReservationStatuses } from "../../../model/Order";
import EventSystem from "../../../utils/EventSystem";
import Language, { Names } from "../../../utils/Language";
import { Button as ButtonOrg, Input, Textarea } from "../../FormComponents";
import {
  BiMessageRoundedEdit,
  BsCheck2All,
  BsFillPeopleFill,
  FaUserEdit,
  GiKnifeFork,
  ImCancelCircle,
  IoCloseSharp,
  RiTableFill,
} from "react-icons/all";
import { CategoryDiv } from "../StandingComponent";
import { Profile } from "../../../model/Profile";
import TablesLayout from "../../modals/TablesLayout";
import { addLeadingZero } from "../../../utils/HoursCalc";
import { toast } from "react-toastify";
import { TableReservationAPI } from "../../../utils/api/TableReservationAPI";
import ErrorMessage from "../../../utils/api/ErrorMessages";
import { Product } from "../../../model/Product";
import Orders from "../Orders";
import OrderComponent from "../OrderComponent";
import CartCalc from "../../../utils/CartCalc";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { print } from "../../../utils/OrderPrinter";

const Button = styled(ButtonOrg)`
  color: white;
  border-color: rgb(86, 112, 164);
  width: fit-content;
  margin: 4px;
  padding: 12px 12px;
`;

const TableActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 12px 0 0 0;
  flex-wrap: wrap;
`;

const BookingNoteWrapper = styled.div`
  padding: 2px 4px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  font-size: 16pt;
  color: #333333;
`;

const MoveWeekButton = styled(CategoryDiv)`
  width: fit-content;
  min-width: 0;
  border-radius: 6px;
  padding: 6px 6px;
  margin: 3px 6px;
`;

export const CenteredRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ margin }) => margin ?? "3px 0"};

  svg {
    margin: 0 2px;
    font-size: 12pt;
  }
`;

const SmallToggleButton = styled(CategoryDiv)`
  ${({ min_width }) => min_width && css`
    min-width: ${min_width};
  `};
  ${({ width }) => width && css`
    width: ${width};
  `};
  ${({ round }) => round && css`
    border-radius: ${round};
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
`;

const LeftPanel = styled.div`
  min-width: 55%;
  height: 100%;
  flex-grow: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  overflow-y: auto;

  & > h1 {
    font-family: Arial, sans-serif;
    font-size: 16pt;
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    padding: 6px;
  }
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 390px;
  flex-shrink: 0;
  height: 100%;

  padding: 12px;

  box-shadow: 0 0 8px 1px #323232;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
    min-width: 150px;
  }
`;

const OneFilter = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;

  ${({ justify }) => justify !== undefined && css`
    justify-content: ${justify};
  `};

  ${({ clickable }) => clickable !== undefined && css`
    cursor: pointer;
    margin: 12px 0;

    &:hover, &:active {
      color: #4b4b4b;
    }
  `};

  & > p {
    font-size: 10pt;
    min-width: 70px;
  }

  & > p:nth-of-type(1) {
    text-align: left;
    margin: 0 6px 0 0;
  }

  & > p:nth-of-type(2) {
    text-align: right;
    margin: 0 0 0 6px;
  }
`;

const RightPanelHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const RowEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const DeselectTableDiv = styled.div`
  cursor: pointer;
  width: fit-content;
  flex-shrink: 0;
  padding: 3px 6px 3px 12px;
  margin: 0 0 0 auto;
`;

const RightPanelHeaderText = styled.h3`
  width: 100%;
  margin: 0 0 6px 0;
  text-align: center;
  font-size: 14pt;
  font-family: Arial, sans-serif;
  color: #333333;

  ${({ margin }) => margin !== undefined && css`
    margin: ${margin};
  `};
`;

const BookingsWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ all_scroll }) => all_scroll === false && css`
    max-height: 37vh;
  `};

  ${({ all_scroll }) => all_scroll === true && css`
    flex-grow: 2;
  `};

  flex-grow: 4;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 10px;
  }
`;

const OrdersOnTable = styled.div`
  width: 100%;
  max-height: 42vh;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;

  flex-grow: 4;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 10px;
  }
`;

const Bookings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #e8e8e8;
`;

const NoBookings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding: 10px;

  text-align: center;
  font-family: Arial, sans-serif;
  color: #787878;
  font-size: 10pt;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
`;

const Booking = styled.div`
  cursor: pointer;

  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 6px;

  transition: background-color 300ms ease-in-out;

  & > p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: "Segoe UI", sans-serif;
    color: #343434;
  }

  ${({ selected }) => selected === true && css`
    background-color: #f1f1f1;
  `};
`;

export const BookingTime = styled.div`
  height: 100%;
  min-height: 55px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BookingTimeClock = styled.div`
  font-size: 12pt;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #454545;
  margin-bottom: 3px;
`;

export const BookingTimeDay = styled.div`
  width: 100%;
  text-align: center;
  font-size: 8pt;
  font-family: Roboto, sans-serif;
  color: #797979;
`;

const BookingDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align ?? "flex-start"};
  justify-content: flex-start;
  margin: ${({ margin }) => margin ?? "0 6px"};
  width: ${({ width }) => width ?? "auto"};
`;

const BookingActions = styled(BookingDetails)`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

const BookingName = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 12pt;
  margin-bottom: 3px;
`;

const BookingDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justify }) => justify ?? "flex-start"};
  font-family: Roboto, sans-serif;
  margin: 2px 0;
  text-align: ${({ text_align }) => text_align ?? "auto"};

  font-size: 9pt;
  color: #333333;
  font-weight: bold;

  & > svg {
    margin: 0 3px;
    font-size: 11pt;
    font-weight: normal;
  }

  & > space {
    display: block;
    width: 6px;
    height: 100%;
  }
`;

const SeatButton = styled(CategoryDiv)`
  margin: 3px 0;
  padding: 3px 6px;
`;

const BookingAcceptButton = styled(CategoryDiv)`
  margin: 3px 0;
  padding: 3px 6px;
`;

const Divider = styled.div`
  width: 90%;
  height: 2px;
  background-color: #e7e7e7;
  border-radius: 100px;
  margin: 6px auto;
`;

export const ReservationCover = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 10pt;

  & > svg {
    font-size: 12pt;
    margin-left: 3px;
  }
`;

export default class WaiterLayout extends Component {
  eventIDs: number[] = [];

  state: {
    shop: Shop,
    tables: Element[],
    orders: Order[],
    profiles: Profile[],
    language: number,
    tableReservations: TableReservation[],
    filterBooked: number,
    filterPaid: number,
    filterText: string,
    selectedTable: Element,
    seated: boolean,
    upcoming: boolean,
    all: boolean,
    allScroll: boolean,
    loadedReservations: { dateMin: Date, dateMax: Date },
    selectedZoneID: number,
    selectedWeek: Date
  } = {
    shop: undefined,
    tables: [],
    orders: [],
    language: ContextSystem.language,
    profiles: [],
    tableReservations: [],
    filterBooked: 0,
    filterPaid: 0,
    filterText: "",
    selectedTable: undefined,
    seated: true,
    upcoming: false,
    all: false,
    loadedReservations: ContextSystem.loadedReservations,
    allScroll: false,
    selectedZoneID: -2,
    selectedWeek: new Date(),
  };

  loadFromContext() {
    this.setState({
      shop: ContextSystem.selectedShop,
      tables: ContextSystem.elements.filter(
        e => e.type === ElementTypes.TABLE && e.partnerID === ContextSystem.selectedShop?.id && e.enabled),
      orders: ContextSystem.orders.filter(
        o => o.partnerID === ContextSystem.selectedShop?.id && o.tableReservationID > 0),
      language: ContextSystem.language,
      tableReservations: ContextSystem.tableReservations.filter(
        t => t.enabled && t.partnerID === ContextSystem.selectedShop?.id),
      profiles: ContextSystem.profiles,
      loadedReservations: ContextSystem.loadedReservations,
    });
  }

  componentWillUnmount() {
    this.eventIDs.forEach(e => EventSystem.unsubscribe(e));
  }

  componentDidMount() {
    this.eventIDs = [];

    this.loadFromContext();

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ selectedShop, language, elements, orders, tableReservations }) => {
        if (
          orders !== undefined || selectedShop !== undefined
          || language !== undefined || elements !== undefined
          || tableReservations !== undefined
        )
          this.loadFromContext();
      },
    );

    this.eventIDs.push(eid);
  }

  filterTable(table: Element): boolean {
    if (!table)
      return false;

    let show: boolean = true;
    let now: Date = new Date();

    // bookings which are either seated or reserved to "now"
    let tableReservation: TableReservation = this.state.tableReservations.find(tr =>
      tr.tableID === table.id &&
      (
        (
          TableReservation.getStart(tr) <= now && now <= TableReservation.getEnd(tr)
          && [TableReservationStatuses.ACCEPTED, TableReservationStatuses.SEATED].includes(tr.status)
        )
        || tr.status === TableReservationStatuses.SEATED
      ),
    );

    let profile: Profile = tableReservation?.profileID > 0 ? this.state.profiles.find(p => p.id === tableReservation?.profileID) : undefined;
    let orders: Order[] = this.state.orders.filter(o => o.tableReservationID > 0 && tableReservation);

    if (this.state.filterText && this.state.filterText.length > 0) {
      //don't filter if the selected filter is all in a list / scroll view
      //this.state.filterText will apply to the list only
      if (!(this.state.all && this.state.allScroll)) {
        let s: string = this.state.filterText.toLowerCase();

        show = show && (
          table.name.toLowerCase().includes(s)
          || (
            (tableReservation?.profileID <= 0 && Language.getName(Names.WalkIn).toLowerCase().includes(s))
            || profile && (profile.firstName.toLowerCase().includes(s)
              || profile.lastName.toLowerCase().includes(s)
              || profile.tel.toLowerCase().includes(s)
              || (profile.firstName + " " + profile.lastName).toLowerCase().includes(s)
              || (profile.firstName + "" + profile.lastName).toLowerCase().includes(s)
              || (profile.lastName + " " + profile.firstName).toLowerCase().includes(s)
              || (profile.lastName + "" + profile.firstName).toLowerCase().includes(s)
            )
          )
          || (
            orders && orders.length > 0 && orders.find(
              o => (o.number + "").includes(s)
                || (o.dailyNumber + "").includes(s)
                || o.profile?.firstName.toLowerCase().includes(s)
                || o.profile?.lastName.toLowerCase().includes(s)
                || (o.profile?.firstName + " " + o.profile?.lastName).toLowerCase().includes(s)
                || (o.profile?.firstName + "" + o.profile?.lastName).toLowerCase().includes(s)
                || (o.profile?.lastName + " " + o.profile?.firstName).toLowerCase().includes(s)
                || (o.profile?.lastName + "" + o.profile?.firstName).toLowerCase().includes(s)
                || o.profile?.tel.includes(s)
                || o.comment?.includes(s),
            ) !== undefined
          )
          || (tableReservation !== undefined && tableReservation.comment.toLowerCase().includes(s))
        );
      }
    }

    if (this.state.filterPaid === 1) {
      show = show && orders && orders.length > 0 && orders.filter(o => !Order.isPaidFully(o)).length <= 0;
    } else if (this.state.filterPaid === -1) {
      show = show && orders && orders.length > 0 && orders.filter(o => Order.isPaidFully(o)).length <= 0;
    }

    if (this.state.filterBooked === 1) {
      show = show && tableReservation !== undefined;
    } else if (this.state.filterBooked === -1) {
      show = show && tableReservation === undefined;
    }

    return show;
  }

  toggleSeated() {
    this.setState({
      seated: true,
      upcoming: false,
      all: false,
      allScroll: false,
    });
  }

  toggleAll() {
    this.setState({
      seated: false,
      upcoming: false,
      all: true,
      allScroll: false,
    });
  }

  toggleAllScrolled() {
    if (!this.state.all)
      return;

    this.setState({
      allScroll: !this.state.allScroll,
    });
  }

  toggleUpcoming() {
    this.setState({
      upcoming: true,
      seated: false,
      all: false,
    });
  }

  selectTable(t: Element) {
    this.setState({
      selectedTable: t,
      allScroll: false,
      all: false,
      upcoming: false,
      seated: true,
    });
  }

  selectZone(zoneID: number) {
    this.setState({ selectedZoneID: zoneID });
  }

  handleCloseTableClick(t: Element, r: TableReservation) {
    if (!r)
      return;

    let orders: Order[] = ContextSystem.orders.filter(o => o.tableReservationID === r.id);

    let allPaid: boolean = true;
    orders.forEach(o => allPaid = allPaid && Order.isPaidFully(o));

    if (!allPaid && ContextSystem.selectedShop?.cashflowReportAvailable) {
      this.payTable(r, () => {
        this.closeTable(t, r);
      });
    } else {
      this.closeTable(t, r);
    }
  }

  payTable(r: TableReservation, cb: ()=>{}) {
    if (!r)
      return;

    let orders: Order[] = ContextSystem.orders.filter(o =>
      o.tableReservationID === r.id
      && ![OrderState.DELETED, OrderState.DECLINED].includes(o.lastState.status),
    );

    if (!orders || orders.length <= 0)
      return;

    let cart: Product[] = [];
    orders.forEach(o => CartCalc.createCartFromOrder(o).forEach(p => cart.push(p)));

    //existingPayments, newPayments, cart, cb, force
    EventSystem.publish(EventSystem.events.open_order_payment, {
      existingPayments: [],
      newPayments: [],
      cart,
      showBackButton: false,
      cb: ({ newPayments, cancelled, deletePayments, back }) => {
        if (cancelled === true || back === true)
          return;
        Orders.saveNewPaymentsForOrders(orders, newPayments, deletePayments);
        if (cb)
          cb();
      },
      force: true,
    });
  }

  closeTable(t: Element, r: TableReservation) {
    TableReservationAPI.closeTable(t, r, res => {
      if (res.error !== ErrorMessage.OK)
        return;
      toast(Language.getName(Names.TableClosed));
    });
  }

  // handleSplitTableClick(t: Element, r: TableReservation) {
  //   toast(Language.getName(Names.NotImplementedYet))
  // }

  addOrder(t: Element) {
    EventSystem.publish(EventSystem.events.modify_order, { table: t });
  }

  addNewReservation(table: Element = undefined) {
    EventSystem.publish(EventSystem.events.open_new_reservation_editor, { tableSelected: table });
  }

  printReservation(table: Element = undefined, reservation: TableReservation = undefined) {
    if (!table || !reservation)
      return;

    let orders: Order[] = this.state.orders.filter(o => o.tableReservationID === reservation.id);

    print(orders, reservation, table);
  }

  handleBookedFilterClicked() {
    let filterBooked = this.state.filterBooked;
    filterBooked++;
    if (filterBooked === 2)
      filterBooked = -1;

    this.setState({ filterBooked });
  }

  handlePaidFilterClicked() {
    let filterPaid = this.state.filterPaid;
    filterPaid++;
    if (filterPaid === 2)
      filterPaid = -1;

    this.setState({ filterPaid });
  }

  handleAcceptBooking(e, res: TableReservation, accept: boolean) {
    e.stopPropagation();
    if (!res)
      return;

    TableReservationAPI.changeStatus(res,
      accept ? TableReservationStatuses.ACCEPTED : TableReservationStatuses.DECLINED, (res) => {
        if (res.error !== ErrorMessage.OK)
          return;

        toast(Language.getName(Names.Saved));
      },
    );
  }

  handleSeatedButton(e, res: TableReservation) {
    e.stopPropagation();
    if (!res)
      return;

    TableReservationAPI.changeStatus(res, TableReservationStatuses.SEATED, (res) => {
      if (res.error !== ErrorMessage.OK)
        return;

      toast(Language.getName(Names.Saved));
    });
  }

  handleCancelButton(e, res: TableReservation) {
    e.stopPropagation();
    if (!res)
      return;

    TableReservationAPI.cancel(res.id, "Cancelled manually by " + ContextSystem.profile?.firstName, () => {
      if (res.error !== ErrorMessage.OK)
        return;

      toast(Language.getName(Names.Cancelled));
    });
  }

  handleBookingClicked(res: TableReservation) {
    if (!res)
      return;

    if (res.tableID <= 0)
      return;

    let table: Element = ContextSystem.elements.find(e => e.id === res.tableID);

    if (!table)
      return;

    let done: boolean = [TableReservationStatuses.CANCELLED, TableReservationStatuses.DONE, TableReservationStatuses.FORCE_DONE, TableReservationStatuses.DECLINED].includes(res.status);
    if (done)
      return;

    this.selectTable(table);
  }

  handleAssignTableClicked(e, res: TableReservation) {
    e.stopPropagation();
    if (!res)
      return;

    EventSystem.publish(EventSystem.events.open_table_selector, {
      tableSelected: undefined,
      cart: [],
      modifyingOrder: undefined,
      origin: "assignTableButton",
      cb: ({ tableSelected, cancelled }) => {
        if (cancelled)
          return;
        TableReservationAPI.changeTable(tableSelected?.id, res?.id, res => {
          if (res.error !== ErrorMessage.OK)
            return;

          toast(Language.getName(Names.Saved));
        });
      },
    });
  }

  handleSetCustomerButton(e: Event, reservation: TableReservation) {
    e.stopPropagation();

    if (reservation.profileID > 0)
      return;

    EventSystem.publish(EventSystem.events.add_new_customer, {
      closeOnSelected: true,
      cb: (profile: Profile) => {
        TableReservationAPI.chooseCustomer(profile.id, reservation.id, (res) => {
          if (res.error !== 0)
            return;

          toast(Language.getName(Names.Saved));
        });
      },
    });
  }

  openNote(r: TableReservation) {
    if (!r)
      return;

    let orgValue: string = r.note;

    let Node = ({ oV, onChange }) => {
      const [value, setValue] = useState(oV);
      return (
        <Textarea
          value={value}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      );
    };

    ConfirmationModal.showModal(
      Language.getName(Names.Note),
      <Node oV={orgValue} onChange={v => orgValue = v} />,
      Language.getName(Names.Save),
      Language.getName(Names.CancelButtonText),
      undefined,
      () => {
        TableReservationAPI.changeNote(orgValue, r.id, (res) => {
          if (res.error !== ErrorMessage.OK)
            return;

          toast(Language.getName(Names.Saved));
        });
      },
    );
  }

  addWeek(w: number = 0) {
    let newSelectedWeek: Date = this.state.selectedWeek.addWeeks(w);
    this.setState({ selectedWeek: newSelectedWeek });
    ContextSystem.loadReservations(newSelectedWeek.startOfWeek(), newSelectedWeek.endOfWeek(), true);
  }

  renderBookings() {
    let tableReservations: TableReservation[] = this.state.tableReservations.filter(r => {
      if (this.state.all && !this.state.allScroll && !TableReservation.getStart(r).isSameWeek(this.state.selectedWeek))
        return false;

      //don't show if it's done and not "all" is selected
      if (!this.state.all && [
        TableReservationStatuses.DONE, TableReservationStatuses.CANCELLED, TableReservationStatuses.FORCE_DONE,
        TableReservationStatuses.DECLINED,
      ].includes(r.status))
        return false;

      if (this.state.seated && r.status !== TableReservationStatuses.SEATED)
        return false;

      // noinspection RedundantIfStatementJS
      if (this.state.upcoming && ![TableReservationStatuses.NEW, TableReservationStatuses.ACCEPTED].includes(r.status))
        return false;

      //in "all scroll" view its good to use the filter properly
      if (this.state.all && this.state.allScroll && this.state.filterText && this.state.filterText.trim().length > 0) {
        let s: string = this.state.filterText.toLowerCase();

        let orders: Order[] = this.state.orders.filter(o => o.tableReservationID > 0 && o.tableReservationID === r.id);
        let table: Element = this.state.tables.find(t => t.id === r.tableID);
        let pr: Profile = r.profileID > 0 ? this.state.profiles.find(p => p.id === r.profileID) : undefined;

        let passTableFilter = table?.name.toLowerCase().includes(s);
        let passCommentFilter = r.comment && r.comment.trim().toLowerCase().includes(s);
        let passProfileFilter = (r.profileID <= 0 && Language.getName(Names.WalkIn).toLowerCase().includes(s))
          || pr && (pr.firstName.toLowerCase().includes(s)
            || pr.lastName.toLowerCase().includes(s)
            || pr.tel.toLowerCase().includes(s)
            || (pr.firstName + " " + pr.lastName).toLowerCase().includes(s)
            || (pr.firstName + "" + pr.lastName).toLowerCase().includes(s)
            || (pr.lastName + " " + pr.firstName).toLowerCase().includes(s)
            || (pr.lastName + "" + pr.firstName).toLowerCase().includes(s)
          );
        let passOrdersFilter = orders && orders.find(
          o => (o.number + "").includes(s)
            || (o.dailyNumber + "").includes(s)
            || o.comment?.toLowerCase().includes(s),
        ) !== undefined;

        if (!passTableFilter && !passOrdersFilter && !passCommentFilter && !passProfileFilter)
          return false;
      }

      return true;
    });

    tableReservations.sort((tr1, tr2) => Orders.sortByDate(TableReservation.getStart(tr1), TableReservation.getStart(tr2)));

    let now = new Date();
    return (
      <>
        {(!tableReservations || tableReservations.length <= 0) &&
          <NoBookings>
            <p> {Language.getName(Names.NoReservations)}</p>
          </NoBookings>
        }
        {tableReservations.map((reservation: TableReservation, i: number) => {
          let table: Element = this.state.tables.find(t => t.id === reservation.tableID);

          if (table !== undefined && this.state.selectedZoneID !== -2 && this.state.selectedZoneID !== table.zoneID)
            return <React.Fragment key={i} />;

          let bookingDate: Date = TableReservation.getStart(reservation);
          let sameDay: boolean = now.isSameDay(bookingDate);
          let sameMonth: boolean = now.isSameMonth(bookingDate);
          let sameYear: boolean = now.isSameYear(bookingDate);
          let profile: Profile = ContextSystem.profiles.find(p => p.id === reservation.profileID);

          let orders: Order[] = this.state.orders.filter(o => o.tableReservationID === reservation.id);
          let ordersSum: number = 0;
          let paid: boolean = true;
          orders.forEach(o => {
            if ([OrderState.DELETED, OrderState.DECLINED].includes(o.lastState.status))
              return;

            ordersSum += Order.totalOrderPrice(o);
            paid = paid && Order.isPaidFully(o);
          });

          let selected: boolean = this.state.selectedTable && this.state.selectedTable.id === reservation.tableID;
          let deletable: boolean = [TableReservationStatuses.NEW, TableReservationStatuses.ACCEPTED].includes(reservation.status)
            && reservation.acceptedByRestaurant === true;
          let done: boolean = [TableReservationStatuses.CANCELLED, TableReservationStatuses.DONE, TableReservationStatuses.FORCE_DONE, TableReservationStatuses.DECLINED].includes(reservation.status);

          return (
            <Booking key={i} selected={!(this.state.all && this.state.allScroll) && selected} onClick={() => this.handleBookingClicked(reservation)}>
              <BookingTime>
                <BookingTimeClock>{addLeadingZero(bookingDate.getHours())}:{addLeadingZero(
                  bookingDate.getMinutes())}</BookingTimeClock>
                <BookingTimeDay>
                  <>
                    {sameYear ? <></> : <>{bookingDate.getUTCFullYear()}<br /></>}
                    {sameMonth ? <></> : <>{Language.getName(Names.MonthNames, bookingDate.getMonth())} {bookingDate.getDate()}<br /></>}
                    {sameDay ? <>{Language.getName(Names.Today)}</> : <>{Language.getName(Names.DayNames, bookingDate.getDay() === 0 ? 6 : bookingDate.getDay() - 1)}<br /></>}
                  </>
                </BookingTimeDay>
                {!done &&
                  <BookingNoteWrapper>
                    <BiMessageRoundedEdit onClick={() => this.openNote(reservation)} />
                  </BookingNoteWrapper>
                }
              </BookingTime>
              <BookingDetails width={"120px"}>
                <BookingName>
                  {!profile &&
                    <>
                      {Language.getName(Names.WalkIn)}
                      {!done &&
                        <SmallToggleButton width={"fit-content"} min_width={"0"}
                                           onClick={(e) => this.handleSetCustomerButton(e, reservation)}
                                           round={"100px"}
                        >
                          <FaUserEdit />
                        </SmallToggleButton>
                      }
                    </>
                  }
                  {profile && <>{profile.firstName + " " + profile.lastName}</>}
                </BookingName>
                <BookingDetailsRow>
                  {table &&
                    <>
                      <RiTableFill />
                      <span>{table && table.name}</span>
                      <space />
                    </>
                  }
                  <BsFillPeopleFill />
                  <span>{reservation.numberOfPeople}</span>
                  <space />
                  <span style={{ width: "70px" }}>{ordersSum.toLocaleString()} Ft</span>
                </BookingDetailsRow>
                <BookingDetailsRow>
                  {!done &&
                    <>
                      {!table &&
                        <SeatButton onClick={(e) => this.handleAssignTableClicked(e, reservation)}>
                          {Language.getName(Names.AssignTable)}
                        </SeatButton>
                      }
                      {table && reservation.status > TableReservationStatuses.ACCEPTED &&
                        <SeatButton onClick={(e) => this.handleAssignTableClicked(e, reservation)}>
                          {Language.getName(Names.TableChange)}
                        </SeatButton>
                      }
                      {table && reservation.status <= TableReservationStatuses.ACCEPTED &&
                        <SeatButton onClick={(e) => this.handleAssignTableClicked(e, reservation)}>
                          {Language.getName(Names.AssignTable)}
                        </SeatButton>
                      }
                    </>
                  }
                </BookingDetailsRow>
              </BookingDetails>
              <BookingDetails align={"flex-end"} margin={"0 6px 0 auto"} width={"110px"}>
                <BookingDetailsRow justify={"flex-end"} text_align={"right"}>
                  {TableReservation.getStatusString(reservation.status)}
                  <space />
                  #{reservation.id}
                </BookingDetailsRow>
                {ordersSum > 0 &&
                  <>
                    <BookingDetailsRow>
                      <GiKnifeFork />
                      <span>{orders.length} {Language.getName(Names.order)}</span>
                    </BookingDetailsRow>
                    <BookingDetailsRow>
                      {paid &&
                        <>
                          <BsCheck2All style={{ color: "green" }} />
                          <span>{Language.getName(Names.Paid)}</span>
                        </>
                      }
                      {!paid &&
                        <>
                          <ImCancelCircle style={{ color: "red" }} />
                          <span>{Language.getName(Names.Unpaid)}</span>
                        </>
                      }
                    </BookingDetailsRow>
                  </>
                }
              </BookingDetails>
              {(reservation.status === TableReservationStatuses.NEW || reservation.status === TableReservationStatuses.ACCEPTED) &&
                <BookingActions>
                  {(!reservation.acceptedByRestaurant || reservation.status === TableReservationStatuses.NEW) &&
                    <>
                      <BookingAcceptButton onClick={(e) => this.handleAcceptBooking(e, reservation, true)}>
                        {Language.getName(Names.Confirm)}
                      </BookingAcceptButton>
                      <BookingAcceptButton onClick={(e) => this.handleAcceptBooking(e, reservation, false)}>
                        {Language.getName(Names.Decline)}
                      </BookingAcceptButton>
                    </>
                  }
                  {deletable &&
                    <BookingAcceptButton onClick={(e) => this.handleCancelButton(e, reservation, false)}>
                      {Language.getName(Names.CancelButtonText)}
                    </BookingAcceptButton>
                  }
                  {reservation.acceptedByRestaurant && reservation.status === TableReservationStatuses.ACCEPTED && reservation.tableID > 0 &&
                    <>
                      <BookingAcceptButton onClick={(e) => this.handleSeatedButton(e, reservation, false)}>
                        {Language.getName(Names.Seat)}
                      </BookingAcceptButton>
                    </>
                  }
                </BookingActions>
              }
            </Booking>
          );
        })}
      </>
    );
  }

  render() {
    let selected: Element = this.state.selectedTable;

    // noinspection JSUnusedAssignment
    let selectedProfile: Profile;
    let selectedTableReservation: TableReservation;
    let selectedOrders: Order[];
    let pulseSeatingTimeLate_selected: boolean;
    let selectedTotalPrice: number;
    let selectedPaid: boolean;
    let selectedStartDate: Date;
    let selectedEndDate: Date;
    let endReservationSignThreshold_selected: Date;
    let pulseAlmostEndTime_selected: boolean;
    let pulseUnpaid: boolean;
    let ordersForTable: Order[] = [];

    if (selected) {
      selectedTableReservation = ContextSystem.getTableReservation(selected.id, this.state.tableReservations);
      // noinspection JSUnusedAssignment
      selectedProfile = selectedTableReservation ? this.state.profiles.find(
        p => p.id === selectedTableReservation.profileID) : undefined;
      selectedOrders = selectedTableReservation ? this.state.orders.filter(
        o => o.tableReservationID === selectedTableReservation.id) : [];
      if (selectedTableReservation)
        ordersForTable = this.state.orders.filter(o => o.tableReservationID === selectedTableReservation.id);

      selectedTotalPrice = 0;
      selectedPaid = true;

      for (let order of selectedOrders) {
        selectedTotalPrice += order.orderTotalPrice;
        if (!Order.isPaidFully(order))
          selectedPaid = false;
      }

      selectedStartDate = TableReservation.getStart(selectedTableReservation);
      selectedEndDate = TableReservation.getEnd(selectedTableReservation);

      endReservationSignThreshold_selected = new Date().addMinutes(15);
      pulseAlmostEndTime_selected = selectedEndDate
        && endReservationSignThreshold_selected > selectedEndDate;

      // noinspection JSUnusedAssignment
      pulseUnpaid = selectedTableReservation && (
          selectedTableReservation.status === TableReservationStatuses.DONE
          || selectedTableReservation.status === TableReservationStatuses.FORCE_DONE
          || selectedTableReservation.status === TableReservationStatuses.CANCELLED
        )
        && selectedPaid === false
        && pulseAlmostEndTime_selected === true;

      // noinspection JSUnusedAssignment
      pulseSeatingTimeLate_selected = selectedStartDate && selectedTableReservation &&
        selectedStartDate > new Date().addMinutes(-15)
        && (
          selectedTableReservation.status === TableReservationStatuses.NEW
          || selectedTableReservation.status === TableReservationStatuses.ACCEPTED
        );
    }

    return (
      <Wrapper>
        <LeftPanel>
          <TablesLayout
            onZoneSelected={(zoneID: number) => this.selectZone(zoneID)}
            onTableSelected={(table: Element) => this.selectTable(table)}
            filterTable={table => this.filterTable(table)}
            selectedTable={this.state.selectedTable}
            emptyNote={<p>{Language.getName(Names.NoTablesToShow)}</p>}
            useFixDate={undefined}
            parent={"waiterLayout"}
            filterTableReservation={undefined}
          />
        </LeftPanel>
        <RightPanel>
          <RightPanelHeader>
            <SmallToggleButton selected={this.state.filterPaid !== 0} onClick={() => this.handlePaidFilterClicked()}
            >
              <div>
                {this.state.filterPaid === -1 && Language.getName(Names.Paid)}
                {this.state.filterPaid === 0 && " -- "}
                {this.state.filterPaid === 1 && Language.getName(Names.Unpaid)}
              </div>
            </SmallToggleButton>
            <SmallToggleButton selected={this.state.filterBooked !== 0} onClick={() => this.handleBookedFilterClicked()}
            >
              <div>
                {this.state.filterBooked === -1 && Language.getName(Names.Free)}
                {this.state.filterBooked === 0 && " -- "}
                {this.state.filterBooked === 1 && Language.getName(Names.Booked)}
              </div>
            </SmallToggleButton>
            <RowEnd>
              <ButtonOrg bg_color={"#2e5482"} color={"white"} onClick={() => this.addNewReservation()}>
                {Language.getName(Names.New)}
              </ButtonOrg>
            </RowEnd>
          </RightPanelHeader>
          <Divider />
          <OneFilter>
            <Input
              autoComplete="off"
              value={this.state.filterText}
              type="text"
              placeholder={(this.state.all && this.state.allScroll ? Language.getName(Names.FilterBookings) : Language.getName(Names.FilterTables)) + "..."}
              width={"40%"}
              margin={"3px 0"}
              onChange={(e) => this.setState({ filterText: e.target.value })}
            />
            <SmallToggleButton selected={this.state.seated === true} onClick={() => this.toggleSeated()}
            >
              <div>{Language.getName(Names.Seated)}</div>
            </SmallToggleButton>
            <SmallToggleButton selected={this.state.upcoming === true} onClick={() => this.toggleUpcoming()}
            >
              <div>{Language.getName(Names.Upcoming)}</div>
            </SmallToggleButton>
            <SmallToggleButton selected={this.state.all === true} onClick={() => this.toggleAll()}
            >
              <div>{Language.getName(Names.All)}</div>
            </SmallToggleButton>
          </OneFilter>
          {this.state.all && !this.state.allScroll &&
            <>
              <OneFilter>
                <MoveWeekButton onClick={() => this.addWeek(-1)}>{"<"}</MoveWeekButton>
                <div>{this.state.selectedWeek.toWeekFormatForWaiterLayout()}</div>
                <MoveWeekButton onClick={() => this.addWeek(+1)}>{">"}</MoveWeekButton>
              </OneFilter>
              <OneFilter justify={"flex-end"} clickable={true}>
                <div onClick={() => this.toggleAllScrolled()}>{Language.getName(Names.All)}</div>
              </OneFilter>
            </>
          }
          {this.state.all && this.state.allScroll &&
            <OneFilter clickable={true} justify={"center"} onClick={() => this.addWeek(-1)}>
              {Language.getName(Names.LoadedFrom1) + " "}
              {this.state.loadedReservations.dateMin.toLocaleDateString()}
              {" " + Language.getName(Names.LoadedFrom2)}
            </OneFilter>
          }
          <BookingsWrapper all_scroll={this.state.all && this.state.allScroll}>
            <Bookings>
              {this.renderBookings()}
            </Bookings>
          </BookingsWrapper>
          {this.state.all && this.state.allScroll &&
            <OneFilter clickable={true} justify={"center"}
                       onClick={() => this.addWeek(+1)}>
              {Language.getName(Names.LoadedUntil1) + " "}
              {this.state.loadedReservations.dateMax.toLocaleDateString()}
              {" " + Language.getName(Names.LoadedUntil2)}
            </OneFilter>
          }
          {this.state.seated &&
            <>
              {!selected &&
                <OneFilter justify={"center"}>
                  <p>{Language.getName(Names.SelectTable)}</p>
                </OneFilter>
              }
              {selected &&
                <>
                  <RightPanelHeader>
                    <RightPanelHeaderText margin={"12px 0 6px 0"}>
                      {selected.name}
                    </RightPanelHeaderText>
                    <DeselectTableDiv onClick={() => this.selectTable(undefined)}>
                      <IoCloseSharp />
                    </DeselectTableDiv>
                  </RightPanelHeader>
                  <OrdersOnTable>
                    {ordersForTable.map((o, i) => {
                      return (
                        <OrderComponent
                          key={i}
                          order={o}
                          showTotal={true}
                          showActions={true}
                          onTableClick={() => {
                          }}
                        />
                      );
                    })}
                  </OrdersOnTable>
                  <TableActions>
                    <Button onClick={() => this.addOrder(selected, selectedTableReservation)}
                            bg_color={"rgb(86,112,164)"}
                    >
                      {Language.getName(Names.Order)}
                    </Button>
                    <Button onClick={() => this.payTable(selectedTableReservation)}
                            bg_color={"rgb(86,112,164)"}
                    >
                      {Language.getName(Names.Payment)}
                    </Button>
                    {/*<Button onClick={() => this.handleSplitTableClick(selected, selectedTableReservation)}*/}
                    {/*        bg_color={"rgb(86,112,164)"}*/}
                    {/*>*/}
                    {/*  {Language.getName(Names.Split)}*/}
                    {/*</Button>*/}
                    <Button onClick={() => this.handleCloseTableClick(selected, selectedTableReservation)}
                            bg_color={"rgb(86,112,164)"}
                            margin={"0 6px"}
                    >
                      {Language.getName(Names.CloseTable)}
                    </Button>
                    <Button onClick={() => this.addNewReservation(selected)}
                            bg_color={"rgb(86,112,164)"}
                            margin={"0 6px"}
                    >
                      {Language.getName(Names.Book)}
                    </Button>
                    <Button onClick={() => this.printReservation(selected, selectedTableReservation)}
                            bg_color={"rgb(86,112,164)"}
                            margin={"0 6px"}
                    >
                      {Language.getName(Names.Print)}
                    </Button>
                  </TableActions>
                </>
              }
            </>
          }
        </RightPanel>
      </Wrapper>
    );
  }
}
