import React, { Component } from "react";
import styled, { css } from "styled-components";
import Config from "../../utils/Config";
import { ChangedMenusType, MenusAPI } from "../../utils/api/MenusAPI";
import EventSystem from "../../utils/EventSystem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { ProductsAPI } from "../../utils/api/ProductsAPI";
import { Controls, Div, DivImg, HeaderButton, HeaderButtonWrapper, HiddenRectangle, ListWrapper, ProductDiv } from "./Products";
import { Extra, Product, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import Language, { Names } from "../../utils/Language";
import { FaCopy, FaEye, FaEyeSlash, FaPen, FaPlus, FaTrashAlt } from "react-icons/all";
import { ProductType } from "./Orders";
import ConfirmationModal from "../modals/ConfirmationModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SelectedMenusWrapper = styled.div`
  margin: 15px 0;
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & > h2 {
    width: 100%;
    font-family: "Segoe UI Light", sans-serif;
    font-size: 19pt;
    color: #841a1e;
    margin-bottom: 15px;
  }
`;

const SelectedMenu = styled.div`
  width: 10%;
  height: 100px;
  padding: 5px 3px;
  border-radius: 5px;
  border: 1px solid #841a1e;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 20pt;
  }

  & > div {
    width: 100%;
    text-align: right;
    padding: 4px;
    position: absolute;
    top: 0;
    left: 0;

    & > svg {
      border-radius: 4px;
      padding: 4px;
      font-size: 16pt;

      &:hover {
        background-color: #ebebeb;
        cursor: pointer;
      }
    }
  }

  ${({ empty }) => empty === true && css`
    &:hover, &:active {
      box-shadow: 0 0 3px 1px #474747;
      cursor: pointer;
    }
  `}
`;

export default class Menus extends Component {
  eventIDs: number[] = [];

  state: {
    menus: Product[],
    extras: Extra[],
    products: Product[],
    language: number,
  } = {
    menus: [],
    extras: [],
    products: [],
    language: ContextSystem.language,
  };

  load() {
    this.setState({
      menus: (ContextSystem.products?.filter(p =>
          p.enabled &&
          p.type === ProductType.MENU &&
          p.partnerID === ContextSystem.selectedShop.id,
        ) ?? []
      ).sort((a, b) => a.orderPlace - b.orderPlace),
      extras: ContextSystem.extras.filter(e => e.enabled && e.partnerID === ContextSystem.selectedShop.id),
      products: ContextSystem.products?.filter(
        p => p.enabled && p.type === ProductType.PRODUCT && p.partnerID === ContextSystem.selectedShop.id) ?? [],
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  componentDidMount() {
    this.eventIDs = [];

    this.load();

    // noinspection DuplicatedCode
    let eid = EventSystem.subscribe(EventSystem.events.product_edited, () => {
      this.load();
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, products, extras }) => {
      if (language !== undefined)
        this.setState({ language });
      if (products !== undefined || extras !== undefined)
        this.load();
    });
    this.eventIDs.push(eid);
  }

  onDragEnd(res) {
    const { destination, source } = res;
    if (!destination)
      return;

    if (destination.droppableId === "1") {
      let menu = this.state.menus[source.index];
      if (menu.menuSelected) {
        toast(Language.getName(Names.ProductIsAlreadyInMenu));
        return;
      }
      MenusAPI.setMenuSelected(menu.id, (res) => {
        if (res.error !== 0) return;
        toast(Language.getName(Names.ProductAddedToTheMenu));
        this.load();
      });
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    let menus = [];
    for (let menu of this.state.menus) {
      menus.push({ ...menu });
    }

    let menuSource = menus[source.index];

    menus.splice(source.index, 1);
    menus.splice(destination.index, 0, menuSource);

    let k = 0;
    let changedMenus: ChangedMenusType[] = [];
    for (let i = 0; i < menus.length; i++) {
      let menu = menus[i];
      menu.orderPlace = k++;
      changedMenus.push({
        id: menu.id,
        orderPlace: menu.orderPlace,
      });
    }

    MenusAPI.changeOrderPlace(changedMenus, (res) => {
      if (res.error === 0) {
        toast(Language.getName(Names.OrderingSaved));
      }
    });

    this.setState({ menus });
  }

  toggleActive(menu: Product) {
    ProductsAPI.toggleProductActive(menu.id, (res) => {
      if (res.error !== 0)
        return;
      if (menu.active)
        toast(Language.getName(Names.ProductHiddenText));
      else
        toast(Language.getName(Names.ProductUnhiddenText));

      this.load();
    });
  }

  handleRemoveMenu(menu: Product) {
    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {
      },
      () => {
        ProductsAPI.remove(menu.id, (res) => {
          if (res.error === 0) {
            toast(Language.getName(Names.Removed));
            this.load();
          }
        });
      },
    );
  }

  render() {
    let maxSelectedMenus = 8;
    let k = this.state.menus.filter(m => m.menuSelected).length;

    return (
      <Wrapper>
        <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
          <Droppable droppableId={"1"}>
            {(provided) => (
              <SelectedMenusWrapper ref={provided.innerRef} {...provided.droppableProps}>
                <h2>{Language.getName(Names.SelectedVisibleMenus)}</h2>
                {this.state.menus.map((menu, i) => {
                  if (menu.menuSelected === true)
                    return (
                      <SelectedMenu key={i}>
                        <div>
                          <FaTrashAlt
                            onClick={() =>
                              MenusAPI.setMenuUnSelected(menu.id, (res) => {
                                if (res.error !== 0)
                                  return;
                                toast(Language.getName(Names.RemovedFromVisibleMenus));
                                this.load();
                              })
                            }
                          />
                        </div>
                        <span>
                          {TranslatableString.get(menu.name)}
                          <br />
                          {menu.price}Ft
                        </span>
                      </SelectedMenu>
                    );
                  else
                    return null;
                })}
                {(() => {
                  let empties = [];
                  for (let i = 0; i < maxSelectedMenus - k; i++) {
                    empties.push(
                      <SelectedMenu empty={true} key={i} onClick={() =>
                        EventSystem.publish(EventSystem.events.edit_product, {
                          product: undefined,
                          extras: this.state.extras,
                          type: ProductType.MENU,
                        })
                      }>
                        <FaPlus />
                      </SelectedMenu>,
                    );
                  }
                  return empties;
                })()}
              </SelectedMenusWrapper>
            )}
          </Droppable>
          <HeaderButtonWrapper>
            <HeaderButton
              onClick={() =>
                EventSystem.publish(EventSystem.events.edit_product, {
                  product: undefined,
                  extras: this.state.extras,
                  type: ProductType.MENU,
                })
              }
            >
              <FaPlus />
              <span>{Language.getName(Names.NewMenuBtnText)}</span>
            </HeaderButton>
          </HeaderButtonWrapper>
          <Droppable droppableId={"0"}>
            {(provided) => (
              <ListWrapper ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.menus.map((menu, i) => {
                  return (
                    <Draggable draggableId={i + ""} index={i} key={i}>
                      {(provided) => (
                        <ProductDiv {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <HiddenRectangle show={menu.active === false} />
                          <DivImg onError={(e: Event) => (e.target.style.display = "none")}
                                  src={Config.productImageUrlBase + menu.image} alt={"image"} height={"60px"} />
                          <Div width={"20%"}>
                            <b>{Language.getName(Names.Name)}:</b>(#{menu.id}) {TranslatableString.get(menu.name)}
                          </Div>
                          <Div width={"20%"}>
                            <b>{Language.getName(Names.Price)}:</b>
                            <br />
                            {menu.price + " Ft"}
                          </Div>
                          <Controls>
                            {menu.active === true && (
                              <div onClick={() => this.toggleActive(menu)}>
                                <FaEyeSlash />
                                <span>{Language.getName(Names.Hide)}</span>
                              </div>
                            )}
                            {menu.active === false && (
                              <div onClick={() => this.toggleActive(menu)}>
                                <FaEye />
                                <span>{Language.getName(Names.Unhide)}</span>
                              </div>
                            )}
                            <div
                              onClick={() => {
                                EventSystem.publish(EventSystem.events.edit_product, {
                                  product: menu,
                                  extras: this.state.extras,
                                  copy: true,
                                  type: ProductType.MENU,
                                });
                              }}
                            >
                              <FaCopy />
                              <span>{Language.getName(Names.Copy)}</span>
                            </div>
                            {" "}
                            <div
                              onClick={() =>
                                EventSystem.publish(EventSystem.events.edit_product, {
                                  product: menu,
                                  extras: this.state.extras,
                                  type: ProductType.MENU,
                                })
                              }
                            >
                              <FaPen />
                              <span>{Language.getName(Names.Modify)}</span>
                            </div>
                            <div
                              onClick={() => this.handleRemoveMenu(menu)}
                            >
                              <FaTrashAlt />
                              <span>{Language.getName(Names.Remove)}</span>
                            </div>
                          </Controls>
                        </ProductDiv>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ListWrapper>
            )}
          </Droppable>
        </DragDropContext>
      </Wrapper>
    );
  }
}
