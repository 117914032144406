import AbstractModel from "./AbstractModel";

export class Image extends AbstractModel {
  name: string;
  fileName: string;
  alt: string;
  type: ImageType;
}

export const ImageTypes = {
  SHOP_COVER: 0,
  PRODUCT: 1,
  MENU: 2,
  BLOGPOST_COVER: 3,
  BLOGPOST_CONTENT: 4,
  SHOP_COVER_MOBILE: 5,
  SHOP_BILL_LOGO: 6,
};

export type ImageType = $Values<typeof ImageTypes>;
