import React, { Component } from "react";
import Orders, { ProductType, pulseBgAnimation } from "../Orders";
import Settings from "../Settings";
import Products from "../Products";
import DetailedSettings from "../DetailedSettings";
import Menus from "../Menus";
import Integrations from "../Integrations";
import FloorPlanner from "../FloorPlanner";
import ContextSystem from "../../../utils/ContextSystem";
import EventSystem from "../../../utils/EventSystem";
import { AiFillDashboard, BsLayersFill, FaChartPie, FaCogs, FaConciergeBell, FaDoorClosed, FaDoorOpen, FaMix, FaPuzzlePiece, FaSlidersH, FaUserFriends, GiWheat, ImProfile } from "react-icons/all";
import { HoursAPI } from "../../../utils/api/HoursAPI";
import styled, { css } from "styled-components";
import { Order, OrderState } from "../../../model/Order";
import Language, { Names } from "../../../utils/Language";
import { Shop } from "../../../model/Shop";
import SimpleTables from "../SimpleTables";
import Users from "../Users";
import RawMaterials from "../RawMaterials";
import Customers from "../Customers";
import Dashboard from "../Dashboard";
import { ShopProfile, ShopProfileTypes } from "../../../model/ShopProfile";

const SideMenuScrollWrapper = styled.div`
  display: block;
  width: 20vw;
  height: 100%;
  max-width: 250px;
  z-index: 2;
  background-color: white;
  box-shadow: 0 0 10px 1px gray;

  @media screen and (max-width: 800px) {
    max-width: unset;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 50px;
    overflow-x: auto;
    overflow-y: visible;
    box-shadow: 0 -2px 2px -2px gray;
  }
`;

const SideMenu = styled.div`
  width: 100%;
  height: 100%;

  background-color: white;
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > hr {
    display: block;
    width: 90%;
    height: 1px;
    color: #841a1e;
    padding: 0;
    margin: 10px 0;
  }

  @media screen and (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-shadow: none;

    & > hr {
      width: 1px;
      height: 90%;
      color: #841a1e;
      margin: 0 10px;
    }
  }
`;

const NewOrders = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //padding: 5px 10px;
  border-radius: 100px;
  background-color: #841a1e;;
  color: white;
  margin-left: 8px;
  font-weight: bold;

  animation: ${pulseBgAnimation} 0.4s ease-in-out infinite alternate;
`;

const MenuItem = styled.div`
  width: 100%;
  min-height: 22px;
  padding: 4px 0;

  margin-top: 6px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10pt;

  //background-color: #e9e9e9;
  border-radius: 10px;

  ${({ bottom }) => bottom && css`
    margin-top: auto;
    margin-bottom: 0;
  `};

  & > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    & > svg {
      margin-left: 5px;
      width: 20%;
      font-size: 18pt;
      color: #444440;
    }
  }

  & > svg {
    margin-left: 5px;
    width: 20%;
    font-size: 18pt;
    color: #444440;
  }

  &:hover, &:active {
    background-color: #edf0f6;
    cursor: pointer;
  }

  ${({ selected }) => selected === true && css`
    background-color: #edf0f6;
    cursor: pointer;
  `};

  @media screen and (max-width: 800px) {
    margin: 0 6px;
  }
`;

export const DashBoardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
  background-color: white;

  @media screen and (max-width: 800px) {
    height: calc(100% - 50px);
    margin: 0 0 50px 0;
  }
`;

export default class ManagerLayout extends Component {
  state: {
    selectedMenu: number,
    shop: Shop,
    hiddenProduct: number,
    hiddenMenu: number,
    newOrdersNumber: number,
    language: number,
    shopProfile: ShopProfile,
  } = {
    selectedMenu: ContextSystem.selectedMenu,
    shop: ContextSystem.selectedShop,
    hiddenProduct: 0,
    hiddenMenu: 0,
    newOrdersNumber: 0,
    language: ContextSystem.language,
    shopProfile: ContextSystem.profile,
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  reloadHidden() {
    this.setState({
      hiddenMenu: ContextSystem.products.filter((x) =>
        !x.active &&
        x.partnerID === ContextSystem.selectedShop.id &&
        x.type === ProductType.MENU &&
        x.enabled,
      ).length,
      hiddenProduct: ContextSystem.products.filter((x) =>
        !x.active &&
        x.partnerID === ContextSystem.selectedShop.id &&
        x.type === ProductType.PRODUCT &&
        x.enabled,
      ).length,
    });
  }

  componentDidMount() {
    this.eventIDs = [];

    this.reloadHidden();
    this.reloadNewOrdersNumber(ContextSystem.orders);

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ products, orders, selectedMenu, language, selectedShop, profile }) => {
        if (selectedShop !== undefined)
          this.setState({ shop: selectedShop });
        if (products !== undefined)
          this.reloadHidden();
        if (orders !== undefined)
          this.reloadNewOrdersNumber(orders);
        if (selectedMenu !== undefined)
          this.setState({ selectedMenu });
        if (language !== undefined)
          this.setState({ language });
        if (profile !== undefined)
          this.setState({ shopProfile: profile });
      },
    );
    this.eventIDs.push(eid);
  }

  reloadNewOrdersNumber(orders: Order[]) {
    let newOrdersNumber = 0;
    orders.forEach((order) => newOrdersNumber += order.partnerID === this.state.shop?.id && order.lastState?.status === OrderState.NEW ? 1 : 0);
    this.setState({ newOrdersNumber });
  }

  selectMenu(menu) {
    ContextSystem.selectMenu(menu);
  }

  toggleClosedForToday() {
    HoursAPI.toggleCloseForToday((res) => {
      if (res.error !== 0)
        return;
      ContextSystem.setShopClosedForToday(res.closedForToday);
    });
  }

  render() {
    let anyoneUsersPermission: boolean = this.state.shop.employees.find(
      e => e.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_USERS)) !== undefined;
    let thisUserHasUserModificationPermission: boolean = this.state.shopProfile.permissions[this.state.shop?.id]?.includes(
      ShopProfileTypes.MODIFY_USERS);
    let showUsers: boolean = thisUserHasUserModificationPermission || !anyoneUsersPermission;
    return (
      <>
        {/*{this.state.mobile === false && (*/}
        {/*  <>*/}
        <SideMenuScrollWrapper>
          <SideMenu>
            <MenuItem selected={this.state.selectedMenu === 13} onClick={() => this.selectMenu(13)}>
              <AiFillDashboard />
              {Language.getName(Names.Dashboard)}
            </MenuItem>
            <MenuItem selected={this.state.selectedMenu === 0} onClick={() => this.selectMenu(0)}>
              <FaConciergeBell />
              {Language.getName(Names.Orders)}
              {this.state.newOrdersNumber > 0 &&
                <NewOrders>{this.state.newOrdersNumber}</NewOrders>
              }
            </MenuItem>
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_MENU) &&
              <>
                <MenuItem selected={this.state.selectedMenu === 2} onClick={() => this.selectMenu(2)}>
                  <FaChartPie />
                  {Language.getName(Names.Products)}
                  {this.state.hiddenProduct > 0 &&
                    <>
                      <br />{" (" + this.state.hiddenProduct + " " + Language.getName(Names.Hidden) + ")"}
                    </>
                  }
                </MenuItem>
                <MenuItem selected={this.state.selectedMenu === 4} onClick={() => this.selectMenu(4)}>
                  <FaMix />
                  {Language.getName(Names.Menus)}
                  {this.state.hiddenMenu > 0 &&
                    <>
                      <br />{" (" + this.state.hiddenMenu + " " + Language.getName(Names.Hidden) + ")"}
                    </>
                  }
                </MenuItem>
              </>
            }
            {(this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_STORAGES)
                || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MOVE_STORAGE)
                || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                  ShopProfileTypes.REQUEST_STORAGE_MOVEMENT)
                || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_MENU)
                || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                  ShopProfileTypes.STORAGE_INWARD_HANDLE)
                || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.STOCK_TAKING)
              ) &&
              <MenuItem selected={this.state.selectedMenu === 11} onClick={() => this.selectMenu(11)}>
                <GiWheat />
                {Language.getName(Names.Raws)}
              </MenuItem>
            }
            {/*
             <MenuItem selected={this.state.selectedMenu === 8} onClick={() => this.selectMenu(8)}>
             <BsLayersFill/>
             {Language.getName(Names.FloorMap)}
             </MenuItem>
             */}

            {/* ------------------------- */}
            <hr />
            {/* ------------------------- */}
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_OPENING_HOURS) &&
              <MenuItem selected={this.state.selectedMenu === 3} onClick={() => this.selectMenu(3)}>
                <FaSlidersH />
                {Language.getName(Names.OpeningsAndShipping)}
              </MenuItem>
            }
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_SETTINGS) &&
              <MenuItem selected={this.state.selectedMenu === 1} onClick={() => this.selectMenu(1)}>
                <FaCogs />
                {Language.getName(Names.Settings)}
              </MenuItem>
            }
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_TABLES) &&
              <MenuItem selected={this.state.selectedMenu === 9} onClick={() => this.selectMenu(9)}>
                <BsLayersFill />
                {Language.getName(Names.Tables)}
              </MenuItem>
            }
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_SETTINGS) &&
              <MenuItem selected={this.state.selectedMenu === 6} onClick={() => this.selectMenu(6)}>
                <FaPuzzlePiece />
                {Language.getName(Names.Integrations)}
              </MenuItem>
            }
            {showUsers &&
              <MenuItem selected={this.state.selectedMenu === 10} onClick={() => this.selectMenu(10)}>
                <FaUserFriends />
                {Language.getName(Names.Users)}
              </MenuItem>
            }
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_CRM) &&
              <MenuItem selected={this.state.selectedMenu === 12} onClick={() => this.selectMenu(12)}>
                <ImProfile />
                {Language.getName(Names.CRM_and_Coupons)}
              </MenuItem>
            }
            {/* ------------------------- */}
            <hr />
            {/* ------------------------- */}
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_OPENING_HOURS) &&
              <MenuItem onClick={() => this.toggleClosedForToday()}>
                {this.state.shop && this.state.shop.closedForToday === false && (
                  <span>
                <FaDoorClosed />
                    {Language.getName(Names.CloseForToday)}
              </span>
                )}
                {this.state.shop && this.state.shop.closedForToday === true && (
                  <span>
                <FaDoorOpen />
                    {Language.getName(Names.OpenShop)}
              </span>
                )}
              </MenuItem>
            }
          </SideMenu>
        </SideMenuScrollWrapper>
        {this.state.shop?.closedForToday && (
          <DashBoardWrapper>
            <br />
            <h2>{Language.getName(Names.OpenBeforeWork)}</h2>
          </DashBoardWrapper>
        )}

        {this.state.shop?.closedForToday === false && (
          <DashBoardWrapper>
            {this.state.selectedMenu === 0 && <Orders />}
            {this.state.selectedMenu === 1 && <Settings />}
            {this.state.selectedMenu === 2 && <Products />}
            {this.state.selectedMenu === 3 && <DetailedSettings />}
            {this.state.selectedMenu === 4 && <Menus />}
            {this.state.selectedMenu === 6 && <Integrations />}
            {this.state.selectedMenu === 8 && <FloorPlanner />}
            {this.state.selectedMenu === 9 && <SimpleTables />}
            {this.state.selectedMenu === 10 && <Users />}
            {this.state.selectedMenu === 11 && <RawMaterials />}
            {this.state.selectedMenu === 12 && <Customers />}
            {this.state.selectedMenu === 13 && <Dashboard />}
          </DashBoardWrapper>
        )}
      </>
    );
  }
}
