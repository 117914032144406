import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import styled, { css } from "styled-components";
import ContextSystem from "../../utils/ContextSystem";
import { Extra, Product, TranslatableString } from "../../model/Product";
import Language, { Names } from "../../utils/Language";
import { Button } from "../FormComponents";
import type { PrinterSettingType } from "../../model/Printer";
import { Printer, PrinterSetting, PrinterSettingTypes } from "../../model/Printer";
import { AiFillCheckCircle } from "react-icons/all";
import { PrintersAPI } from "../../utils/api/PrintersAPI";
import ErrorMessage from "../../utils/api/ErrorMessages";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 12px;
`;

const DetailsText = styled.p`
  font-size: 9pt;
`;

const SettingsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
`;

const Cell = styled.div`
  padding: 1px 3px;
  font-size: 9pt;
  width: ${({ width }) => width ?? "fit-content"};
  transition: background-color 300ms ease-in-out;

  text-align: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  flex-grow: 2;

  & > svg {
    font-size: 16pt;
  }

  ${({ header }) => header === true && css`
    border-left: 1px solid #777777;
    border-top: 1px solid transparent;
    font-weight: bold;
    font-size: 9pt;
  `};

  ${({ header }) => header !== true && css`
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #efefef;
    }

    &:active {
      background-color: #e0e0e0;
    }
  `};

  ${({ version }) => version === true && css`
    padding-left: 24px;
  `};
`;

const TableRow = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;

  ${({ header }) => header === true && css`
    border-top: 1px solid #777777;
    border-bottom: 1px solid #777777;
    border-right: 1px solid #777777;
    background-color: #efefef;
  `};

  & > ${Cell}:nth-of-type(1) {
    user-select: none;
    text-align: left;
    justify-content: flex-start;
    flex-grow: 10;
    max-width: 500px;
  }
`;

export default class PrinterSettingsEditor extends Component {
  state: {
    showModal: boolean,
    products: Product[],
    extras: Extra[],
    printers: Printer[],
    language: number,
  } = {
    showModal: false,
    printers: [],
    products: [],
    extras: [],
    language: ContextSystem.language,
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.edit_product_printer_settings, () => {
      this.setState({ showModal: true });
      this.loadContext();
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, products, printers, extras }) => {
        if (!this.state.showModal)
          return;

        if (language !== undefined || products !== undefined || printers !== undefined || extras !== undefined)
          this.loadContext();
      },
    );
  }

  loadContext() {
    this.setState({
      printers: ContextSystem.printers.filter(p => p.enabled && p.partnerID === ContextSystem.selectedShop?.id),
      products: ContextSystem.products.filter(p => p.enabled && p.partnerID === ContextSystem.selectedShop?.id),
      extras: ContextSystem.extras.filter(p => p.enabled && p.partnerID === ContextSystem.selectedShop?.id),
      language: ContextSystem.language,
    });
  }

  changePrinter(modelID: number, modelType: PrinterSettingType, printer: Printer, ps: PrinterSetting) {
    if (!printer && !ps)
      return;

    if (ps && !printer) {
      //delete printer setting --> set it back to default
      PrintersAPI.deletePrinterSetting(modelID, parseInt(modelType), (res) => {
        if (res.error === ErrorMessage.OK)
          toast(Language.getName(Names.Saved));
      });
      return;
    }

    let newPs: PrinterSetting = ps;
    if (ps && printer) {
      //change printer on existing setting
      ps.printerID = printer.id;
    } else if (!ps && printer) {
      //add new printer setting
      newPs = {
        id: -1,
        enabled: true,
        printerID: printer.id,
        modelID,
        modelType,
        shopID: ContextSystem.selectedShop?.id,
      };
    }

    PrintersAPI.updatePrinterSetting(newPs, (res) => {
      if (res.error === ErrorMessage.OK)
        toast(Language.getName(Names.Saved));
    });
  }

  renderTableHeader(name: string) {
    return (
      <TableRow header={true}>
        <Cell width={"300px"} header={true}>{name}</Cell>
        <Cell width={"100px"} header={true}>{Language.getName(Names.Default)}</Cell>
        {this.state.printers.map((p, i) => {
          return (
            <Cell width={"100px"} header={true} key={i}>
              {p.name}
            </Cell>
          );
        })}
      </TableRow>
    );
  }

  render() {
    return (
      <Modal size={"xl"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.Printing)}</Modal.Title>
        </Modal.Header>
        <Modal.Header>
          <DetailsText>{Language.getName(Names.PrintingDetails)}</DetailsText>
        </Modal.Header>
        <Modal.Body>
          <Wrapper>
            <SettingsTable>
              {this.renderTableHeader(Language.getName(Names.ProductName))}
              {this.state.products.map((product, i) => {
                let printerSetting: PrinterSetting = product.printerSettings.filter(
                  ps => ps.enabled && ps.modelType === PrinterSettingTypes.PRODUCT && ps.modelID === product.id)[0];
                let printer = this.state.printers.find(printer => printer.id === printerSetting?.printerID);
                if (!printer && printerSetting)
                  printerSetting = undefined;

                return (
                  <React.Fragment key={i}>
                    <TableRow>
                      <Cell width={"300px"}>{TranslatableString.get(product.name)}</Cell>
                      <Cell width={"100px"}
                            onClick={() => this.changePrinter(product.id, PrinterSettingTypes.PRODUCT, null,
                              printerSetting,
                            )}
                      >
                        {!printerSetting &&
                          <AiFillCheckCircle fill={"green"} />
                        }
                      </Cell>
                      {this.state.printers.map((printer, j) => {
                        return (
                          <Cell key={j}
                                width={"100px"}
                                onClick={() => this.changePrinter(product.id, PrinterSettingTypes.PRODUCT, printer,
                                  printerSetting,
                                )}
                          >
                            {printerSetting && printerSetting?.printerID === printer.id &&
                              <AiFillCheckCircle fill={"green"} />
                            }
                          </Cell>
                        );
                      })}
                    </TableRow>
                    {product.versions.length > 1 && product.versions.map((version, j) => {
                      let printerSetting: PrinterSetting = version.printerSettings.filter(
                        ps => ps.enabled && ps.modelType === PrinterSettingTypes.VERSION && ps.modelID === version.id)[0];
                      let printer = this.state.printers.find(printer => printer.id === printerSetting?.printerID);
                      if (!printer && printerSetting)
                        printerSetting = undefined;

                      return (
                        <TableRow key={j}>
                          <Cell width={"300px"} version={true}>{TranslatableString.get(version.name)}</Cell>
                          <Cell width={"100px"}
                                onClick={() => this.changePrinter(version.id, PrinterSettingTypes.VERSION, null,
                                  printerSetting,
                                )}
                          >
                            {!printerSetting &&
                              <AiFillCheckCircle fill={"green"} />
                            }
                          </Cell>
                          {this.state.printers.map((printer, k) => {
                            return (
                              <Cell key={k}
                                    width={"100px"}
                                    onClick={() => this.changePrinter(version.id, PrinterSettingTypes.VERSION, printer,
                                      printerSetting,
                                    )}>
                                {printerSetting && printerSetting?.printerID === printer.id &&
                                  <AiFillCheckCircle fill={"green"} />}
                              </Cell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
              {this.renderTableHeader(Language.getName(Names.ExtraName))}
              {this.state.extras.map((extra, i) => {
                let printerSetting: PrinterSetting = extra.printerSettings.filter(
                  ps => ps.enabled && ps.modelType === PrinterSettingTypes.EXTRA && ps.modelID === extra.id)[0];
                let printer = this.state.printers.find(printer => printer.id === printerSetting?.printerID);
                if (!printer && printerSetting)
                  printerSetting = undefined;

                return (
                  <TableRow key={i}>
                    <Cell width={"300px"}>{TranslatableString.get(extra.name)}</Cell>
                    <Cell width={"100px"}
                          onClick={() => this.changePrinter(extra.id, PrinterSettingTypes.EXTRA, null, printerSetting)}
                    >
                      {!printerSetting &&
                        <AiFillCheckCircle fill={"green"} />
                      }
                    </Cell>
                    {this.state.printers.map((printer, j) => {
                      return (
                        <Cell width={"100px"} key={j}
                              onClick={() => this.changePrinter(extra.id, PrinterSettingTypes.EXTRA, printer,
                                printerSetting,
                              )}>
                          {printerSetting && printerSetting?.printerID === printer.id &&
                            <AiFillCheckCircle fill={"green"} />
                          }
                        </Cell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </SettingsTable>
          </Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
