import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input, InputArea } from "../FormComponents";
import { LeftSection, RightSection, Section, SubSection } from "../home/DetailedSettings";
import { Coupon, CouponTypes } from "../../model/Coupon";
import { CouponsAPI } from "../../utils/api/CouponsAPI";
import { TranslatableString } from "../../model/Product";
import styled from "styled-components";
import ReactFlagsSelect2 from "react-flags-select";
import hu from "react-phone-input-2/lang/hu.json";
import en from "../../assets/en.json";
import { HeaderButton } from "../home/Products";
import DateTimePicker from "react-datetime-picker";
import { FaCheck, FaTimes } from "react-icons/all";
import ToggleButton from "react-toggle-button";
import { endOfMonth } from "date-fns";
import ProductEditor from "./ProductEditor";

const RadioButton = styled(HeaderButton)`
  padding: 16px 16px;
`;

export const languages = [
  { id: 0, code: "HU", label: "Magyar", countryNamesForPhoneComponent: hu, languageCode: "HU-HU" },
  { id: 1, code: "GB", label: "English", countryNamesForPhoneComponent: en, languageCode: "EN-GB" },
];

export const ReactFlagsSelect = styled(ReactFlagsSelect2)`
  width: 230px;
  background-color: white;

  & * {
    background-color: white;
  }

  button {
    background-color: var(--blundee_input_bg_1) !important;
    border: none;
    color: var(--blundee_input_color_1) !important;

    &:after {
      border-top-color: var(--blundee_input_color_1) !important;
      border-bottom-color: var(--blundee_input_color_1) !important;
    }
  }

  ul {
    background-color: var(--blundee_input_bg_1) !important;
    border: none;

    li {
      transition: background-color 150ms ease-in-out;
      padding: 10px;

      svg {
        font-size: 20pt;
      }
    }

    li:focus, li:hover {
      background-color: var(--blundee_input_bg_3) !important;
    }
  }
`;

const ValidUntilWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export default class CouponEditor extends Component {
  eventIDs: number[] = [];

  state: {
    showModal: boolean,
    edit: null | Coupon,
    title: string,
    language: number,
    selectedLanguage: number,
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewCoupon),
    language: ContextSystem.language,
    selectedLanguage: languages[0].id,
  };

  save() {
    let element: Coupon = { ...this.state.edit };
    if (!element.name || !TranslatableString.check(element.name, n => n.value.length >= 2, false)) {
      toast(Language.getName(Names.NameTooShort));
      return;
    }

    TranslatableString.fillEmptyWithCopy(element.name);

    if (!element.couponCode || element.couponCode.length < 2) {
      toast(Language.getName(Names.CouponCodeTooShort));
      return;
    }

    element.allPartners = false;
    element.partnerIDs = [ContextSystem.selectedShop.id];
    element.couponValue = parseFloat(element.couponValue);

    if ([CouponTypes.SERVICE].includes(element.type))
      element.couponValue = -element.couponValue;

    CouponsAPI.save(element, res => {
      if (res.error !== 0)
        return;

      // if (element.id > 0)
      //   ContextSystem.removeCoupon(element.id);

      // ContextSystem.mergeCoupons([res.coupon]);

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  edit(coupon: Coupon) {
    coupon = { ...coupon };
    if ([CouponTypes.SERVICE].includes(coupon.type))
      coupon.couponValue = -coupon.couponValue;
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyCoupon),
      edit: coupon,
    });
  }

  addNew() {
    let edit: Coupon = {
      id: -1,
      enabled: true,

      type: CouponTypes.FIX,
      couponCode: "",
      couponValue: 0,
      name: TranslatableString.create(""),
      details: TranslatableString.create(""),

      validFrom: new Date(),
      validUntil: new Date(new Date().addYears(1000)),

      allCategories: true,
      allGlobalCategories: true,
      allProducts: true,
      allPartners: false,
      allShopProfiles: true,
      allProfiles: true,

      categoryIDs: [],
      globalCategoryIDs: [],
      productIDs: [],
      partnerIDs: [ContextSystem.selectedShop.id],
      shopProfileIDs: [],
      profileIDs: [],

      freeShipping: false,
      loginNeeded: false,
      validForProductsOnSale: false,
      qty: Coupon.UNLIMITED,

      maxCartValue: -1,
      minCartValue: -1,

      maxQtyPerPerson: -1,
      maxQtyPerPurchase: -1,
    };

    this.setState({
      showModal: true,
      title: Language.getName(Names.NewCoupon),
      edit: edit,
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs)
      EventSystem.unsubscribe(eid);
  }

  componentDidMount() {
    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.add_new_coupon, (coupon) => {
      if (!coupon)
        this.addNew();
      else
        this.edit(coupon);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
    this.eventIDs.push(eid);
  }

  setName(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let name: TranslatableString = this.state.edit.name;
    if (!name)
      name = TranslatableString.create("");
    name.translation = name.translation.filter(t => t.languageCode !== languageCode);
    name.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        name: name,
      },
    });
  }

  setDetails(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let details: TranslatableString = this.state.edit.details;
    if (!details)
      details = TranslatableString.create("");
    details.translation = details.translation.filter(t => t.languageCode !== languageCode);
    details.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        details: details,
      },
    });
  }

  handleValidUntilToggle(value: boolean) {
    if (value) {
      this.setState({
        edit: {
          ...this.state.edit,
          validUntil: endOfMonth(new Date()),
        },
      });
    } else {
      this.setState({
        edit: {
          ...this.state.edit,
          validUntil: new Date(3000, 0),
        },
      });
    }
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id })}
          />
        </Modal.Header>
        <Modal.Body>
          {this.state.edit &&
            <>
              <Section>
                <SubSection>
                  <label>{Language.getName(Names.Type)}:</label>
                  <RightSection>
                    <RadioButton margin_right={"0"}
                                 svg_font_size={"18pt"}
                                 background_color={this.state.edit.type === CouponTypes.FIX ? "#E6E6E6" : undefined}
                                 onClick={() => this.setState({
                                   edit: {
                                     ...this.state.edit,
                                     type: CouponTypes.FIX,
                                   },
                                 })}
                    >
                      <span>{Language.getName(Names.Fix_Amount)}</span>
                    </RadioButton>
                    <RadioButton margin_left={"0"}
                                 margin_right={"0"}
                                 svg_font_size={"18pt"}
                                 background_color={this.state.edit.type === CouponTypes.PERCENTAGE
                                                   ? "#E6E6E6"
                                                   : undefined}
                                 onClick={() => this.setState({
                                   edit: {
                                     ...this.state.edit,
                                     type: CouponTypes.PERCENTAGE,
                                   },
                                 })}
                    >
                      <span>{Language.getName(Names.Percentage)}</span>
                    </RadioButton>
                    <RadioButton margin_left={"0"}
                                 svg_font_size={"18pt"}
                                 background_color={this.state.edit.type === CouponTypes.SERVICE ? "#E6E6E6" : undefined}
                                 onClick={() => this.setState({
                                   edit: {
                                     ...this.state.edit,
                                     type: CouponTypes.SERVICE,
                                     validUntil: new Date(new Date().addYears(1000)),
                                     allCategories: true,
                                     allGlobalCategories: true,
                                     allProducts: true,
                                     allPartners: false,
                                     allShopProfiles: true,
                                     allProfiles: true,

                                     categoryIDs: [],
                                     globalCategoryIDs: [],
                                     productIDs: [],
                                     partnerIDs: [ContextSystem.selectedShop.id],
                                     shopProfileIDs: [],
                                     profileIDs: [],

                                     freeShipping: false,
                                     loginNeeded: false,
                                     validForProductsOnSale: false,
                                     qty: Coupon.UNLIMITED,

                                     maxCartValue: -1,
                                     minCartValue: -1,

                                     maxQtyPerPerson: -1,
                                     maxQtyPerPurchase: -1,
                                   },
                                 })}
                    >
                      <span>{Language.getName(Names.ServiceCharge)}</span>
                    </RadioButton>
                  </RightSection>
                  <newline />
                  {[CouponTypes.FIX, CouponTypes.PERCENTAGE].includes(this.state.edit.type) &&
                    <label>{Language.getName(Names.DiscountValue)}:</label>
                  }
                  {[CouponTypes.SERVICE].includes(this.state.edit.type) &&
                    <label>{Language.getName(Names.ServiceChargeValue)}:</label>
                  }
                  <newline />
                  <LeftSection>
                    <Input
                      value={this.state.edit ? this.state.edit.couponValue : ""}
                      min={0}
                      type="number"
                      placeholder={Language.getName(Names.DiscountValue)}
                      onChange={(e) =>
                        this.setState({
                          edit: {
                            ...this.state.edit,
                            couponValue: ProductEditor.parsePrice(e.target.value),
                          },
                        })
                      }
                      width={"120px"}
                    />
                    {[CouponTypes.PERCENTAGE, CouponTypes.SERVICE].includes(this.state.edit.type) &&
                      <label>%</label>
                    }
                    {[CouponTypes.FIX].includes(this.state.edit.type) &&
                      <label>Ft</label>
                    }
                  </LeftSection>
                </SubSection>
              </Section>
              <Section>
                <SubSection>
                  {[CouponTypes.FIX, CouponTypes.PERCENTAGE].includes(this.state.edit.type) &&
                    <label>{Language.getName(Names.Code)}</label>
                  }
                  {[CouponTypes.SERVICE].includes(this.state.edit.type) &&
                    <label>{Language.getName(Names.ID)}:</label>
                  }
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.edit.couponCode ?? ""}
                    type="text"
                    placeholder={Language.getName(Names.Code)}
                    onChange={(e) => this.setState({
                      edit: {
                        ...this.state.edit,
                        couponCode: e.target.value.toUpperCase(),
                      },
                    })}
                  />
                </SubSection>
                <SubSection>
                  <label>{Language.getName(Names.Name_WillBeDisplayed)}</label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.edit?.name?.translation?.find(
                      tr => tr.languageCode === languageCode)?.value ?? ""}
                    type="text"
                    placeholder={Language.getName(Names.Name)}
                    onChange={e => this.setName(e.target.value)}
                  />
                </SubSection>
                <SubSection>
                  <label>{Language.getName(Names.Details)}</label>
                  <InputArea
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.edit?.details?.translation?.find(
                      tr => tr.languageCode === languageCode)?.value ?? ""}
                    type="text"
                    placeholder={Language.getName(Names.Details)}
                    onChange={e => this.setDetails(e.target.value)}
                  />
                </SubSection>
              </Section>
              {[CouponTypes.FIX, CouponTypes.PERCENTAGE].includes(this.state.edit.type) &&
                <>
                  <Section>
                    <SubSection>
                      <label>{Language.getName(Names.Validity)}</label>
                      <newline />
                      <DateTimePicker
                        onChange={(date) => {
                          this.setState({
                            edit: {
                              ...this.state.edit,
                              validFrom: date,
                            },
                          });
                        }}
                        value={this.state.edit.validFrom}
                        locale={"HU"}
                        disableClock
                        format={"yyyy.MM.dd."}
                      />
                      <ValidUntilWrapper>
                        <ToggleButton
                          inactiveLabel={<FaTimes />}
                          activeLabel={<FaCheck />}
                          value={this.state.edit.validUntil < new Date(3000, 0)}
                          onToggle={value => this.handleValidUntilToggle(!value)}
                        />
                        {this.state.edit.validUntil < new Date(3000, 0) &&
                          <DateTimePicker
                            onChange={(date) => {
                              this.setState({
                                edit: {
                                  ...this.state.edit,
                                  validUntil: date,
                                },
                              });
                            }}
                            value={this.state.edit.validUntil}
                            locale={"HU"}
                            disableClock
                            format={"yyyy.MM.dd."}
                          />
                        }
                      </ValidUntilWrapper>
                    </SubSection>
                  </Section>
                  <Section>
                    <SubSection>
                      <label>{Language.getName(Names.AvailableQty)}</label>
                      <newline />
                      <label>{Language.getName(Names.Unlimited)}</label>
                      <ToggleButton
                        inactiveLabel={<FaTimes />}
                        activeLabel={<FaCheck />}
                        value={this.state.edit.qty === Coupon.UNLIMITED}
                        onToggle={value => this.setState({
                          edit: {
                            ...this.state.edit,
                            qty: !value ? Coupon.UNLIMITED : 3,
                          },
                        })}
                      />
                      {this.state.edit.qty !== Coupon.UNLIMITED &&
                        <>
                          <newline />
                          <Input
                            value={this.state.edit ? this.state.edit.qty : ""}
                            min={0}
                            type="number"
                            placeholder={Language.getName(Names.AvailableQty)}
                            onChange={(e) =>
                              this.setState({
                                edit: {
                                  ...this.state.edit,
                                  qty: isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value),
                                },
                              })
                            }
                            width={"300px"}
                          />
                        </>
                      }
                    </SubSection>
                  </Section>
                </>
              }
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}
