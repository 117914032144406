export class ShippingPrice {
  id: number;
  enabled: boolean;
  price: number;
  price2: number;
  price2Min: number;
  partnerID: number;
  polyID: number;
  minimumOrder: number;
  name: string;
  active: boolean;
}

export const ShippingMethods = {
  PICKUP: 1,
  DELIVERY: 2,
  AT_PLACE: 3,
  VENDOR_DELIVERY: 4,
  ANY: 100,
};

export type ShippingMethod = $Keys<typeof ShippingMethods>;
