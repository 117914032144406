import AbstractModel from "./AbstractModel";
import { TranslatableString } from "./Product";

export const CouponTypes = {
  PERCENTAGE: 0,
  FIX: 1,
  SERVICE: 2,
};

export type CouponType = $Values<typeof CouponTypes>;

export const CouponModelTypes = {
  PARTNER: 1,
  PRODUCT: 2,
  CATEGORY: 3,
  GLOBAL_CATEGORY: 4,
  PROFILE: 5,
  SHOP_PROFILE: 6,
  COUPON_TRIGGER: 7,
  PRODUCT_TRIGGER: 8,
};

export type CouponModelType = $Values<typeof CouponModelTypes>;

export class Coupon extends AbstractModel {
  static UNLIMITED: number = -2;

  name: TranslatableString;
  couponCode: string;
  couponValue: number;
  details: TranslatableString;
  validFrom: Date;
  validUntil: Date;
  type: CouponType;
  qty: number;
  maxQtyPerPerson: number;
  maxQtyPerPurchase: number;
  minCartValue: number;
  maxCartValue: number;
  loginNeeded: boolean;
  freeShipping: boolean;
  validForProductsOnSale: boolean;
  allProducts: boolean;
  allPartners: boolean;
  allCategories: boolean;
  allGlobalCategories: boolean;
  allShopProfiles: boolean;
  allProfiles: boolean;
  partnerIDs: number[];
  productIDs: number[];
  categoryIDs: number[];
  globalCategoryIDs: number[];
  shopProfileIDs: number[];
  profileIDs: number[];
}
