import React, { Component } from "react";
import styled from "styled-components";
import blundeeLogo from "../assets/blundee_logos/blundee.png";
import awsLogo from "../assets/aws.png";
import hetznerLogo from "../assets/hetzner-logo.svg";
import arubaLogo from "../assets/aruba_logo_h141.png";
import Config, { ConfigKeys } from "../utils/Config";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  margin: 120px 10%;
  h2 {
    font-family: "Segoe UI", sans-serif;
    margin: 50px 0 20pt;
  }
  h3 {
    font-family: "Raleway Light", sans-serif;
    font-size: 18pt;
  }
  h4 {
    font-family: "Raleway Light", sans-serif;
    font-size: 16pt;
  }
`;

const Logo = styled.img`
  height: 150px;
  align-self: center;
`;

const SmallLogo = styled.img`
  height: ${({ height }) => height ? height : "60px"};
  margin-bottom: 15px;
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-family: "Raleway Black", sans-serif;
`;

export const Subtitle = styled.p`
  width: 100%;
  text-align: center;
  font-family: "Courier New", sans-serif;
`;

export default class AszfPage extends Component {
  render() {
    return (
      <Wrapper>
        <Title>Általános Szerződési Feltételek (ÁSZF) és Adatvédelmi Szabályzat</Title>
        <Subtitle>Hatályos: 2021.02.11.</Subtitle>
        <Logo alt={"logo"} src={blundeeLogo} />
        <hr />
        <h2>Üdvözöljük!</h2>
        <p>
          Az aktuális ÁSZF és Adatkezelés dokumentumot <a
          href="https://partner.blundee.com/data/docs/ASZF.pdf">ezen</a> a
          linken keresztül
          letöltheti.
        </p>
        <p>
          Az Általános Szerződési Feltételek (továbbiakban: ÁSZF) az {Config.getString(ConfigKeys.partner_contract_company)}
          (továbbiakban: Szolgáltató) és partnere (továbbiakban: Partner) (továbbiakban
          együttesen: Felek, külön-külön: Fél) között létrejött szerződés (továbbiakban:
          Szerződés) elválaszthatatlan részét képezi.
        </p>
        <h2>Adatkezelő szervezetek</h2>
        <h3>Szolgáltató adatai</h3>
        <SmallLogo src={blundeeLogo} />
        <p>
          Név: {Config.getString(ConfigKeys.partner_contract_company)}<br />
          Székhely: {Config.getString(ConfigKeys.partner_contract_address)}<br />
          Levelezési cím: {Config.getString(ConfigKeys.partner_contract_address)}<br />
          Adószám: {Config.getString(ConfigKeys.partner_contract_vat)}<br />
          Cégjegyzékszám: {Config.getString(ConfigKeys.partner_contract_official_id)}<br />
          Kapcsolat: {Config.getString(ConfigKeys.partner_contract_contact)}<br />
          Honlap: www.blundee.com<br />
        </p>
        <h3>Tárhely szolgáltatók és adatkezelők adatai</h3>
        <p>Minden szerver és infrastruktúra szolgáltatónk az Európai Unió területein belül szolgáltatja az adattárolásra
          szükgéses erőforrásokat.</p>
        <SmallLogo height={"25px"} src={hetznerLogo} />
        <p className="font-size-16">
          1. Hetzner<br />
          Cégnév: Hetzner Online GmbH.<br />
          Székhely: Industriestr. 25 91710 Gunzenhausen Germany<br />
          Ügyfélszolgálati cím: Industriestr. 25 91710 Gunzenhausen Germany (Ansbach Registration Office, HRB
          6089)<br />
          Levelezési cím: Industriestr. 25 91710 Gunzenhausen Germany (Ansbach Registration Office, HRB 6089)<br />
          Cégjegyzék száma: DE812871812<br />
          Adatkezelő weboldala: https://www.hetzner.com/<br />
          E-mail címe: info@hetzner.com<br />
          Adatkezelés: https://www.hetzner.com/rechtliches/agb
        </p>
        <SmallLogo height={"50px"} src={awsLogo} />
        <p className="font-size-16">
          2. Amazon Web Services<br />
          Cégnév: Amazon Web Services, Inc.<br />
          Székhely: 410 Terry Avenue North Seattle, WA 98109, United States<br />
          Ügyfélszolgálati cím: P.O. Box 81226, Seattle, WA 98108, USA<br />
          Levelezési cím: P.O. Box 81226, Seattle, WA 98108, USA<br />
          Cégjegyzék száma: 000-22513, DELAWARE<br />
          Adatkezelő weboldala: https://aws.amazon.com/<br />
          E-mail címe: abuse@amazonaws.com<br />
          Adatkezelés: https://aws.amazon.com/legal/
        </p>
        <SmallLogo src={arubaLogo} />
        <p className="font-size-16">
          3. Aruba Cloud Forpsi<br />
          Cégnév: BlazeArts Kft.<br />
          Székhely: 6090 Kunszentmiklós, Damjanich utca 36. 1. em. 8.<br />
          Ügyfélszolgálati cím: 6090 Kunszentmiklós, Damjanich utca 36. 1. em. 8.<br />
          Levelezési cím: 6090 Kunszentmiklós, Damjanich utca 36. 1. em. 8.<br />
          Cégjegyzék száma: Kecskeméti Törvényszék, 03-09-109150<br />
          Adatkezelő weboldala: http://forpsi.hu/, www.arubacloud.hu<br />
          E-mail címe: info@forpsi.hu, admin@forpsi.hu<br />
          Kapcsolatfelvételi űrlap: https://support.forpsi.hu/Main/Default.aspx
        </p>
        <h2>1. Általános Adatok és Elérhetőség</h2>
        <ol>
          <li>Szolgáltató:<br />
            Név: {Config.getString(ConfigKeys.partner_contract_company)}<br />
            Székhely: {Config.getString(ConfigKeys.partner_contract_address)}<br />
            Adószám: {Config.getString(ConfigKeys.partner_contract_vat)}<br />
            Cégjegyzékszám: {Config.getString(ConfigKeys.partner_contract_official_id)}<br />
            Kapcsolat: {Config.getString(ConfigKeys.partner_contract_contact)}<br />
          </li>
          <li>Az általános szerződési feltételek elérhetősége a Szolgáltató honlapján:
            https://partner.blundee.com/aszf
          </li>
        </ol>
        <h2>2. Általános Szerződési Feltételek tárgyi hatálya</h2>
        <ol>
          <li>Jelen ÁSZF az Blundee ételrendelés-közvetítő online szolgáltatására terjed ki, illetve az Offline
            megjelenésére a Partnereknél.
          </li>
          <li>A jelen ÁSZF a fenti szolgáltatásokkal kapcsolatban a Szolgáltató és a Partner(ek) jogait és
            kötelezettségeit szabályozza.
          </li>
        </ol>
        <h2>3. Felek tevékenysége és jogállásuk</h2>
        <ol>
          <li>A Szolgáltató a tulajdonosa és üzemeltetője az Blundee online ételrendelés-közvetítő weboldalnak, mely
            a www.blundee.com, az Blundee mobil applikációból, a Messenger-es rendelő felületből és a partnereknek
            szánt partner.blundee.com adminisztrációs felületből (továbbiakban együtt: Platform) épül fel. A Platform
            a partner éttermek ételrendelési szolgáltatásait gyűjti össze és kezeli. Szolgáltató a felhasználók
            (továbbiakban: Felhasználók vagy Felhasználó) által a Platformon leadott étel, ital és egyéb vendéglátói
            termékek rendelések felvételével, Partner részére történő továbbításával, illetve – amennyiben a Felhasználó
            online fizet – a rendelés ellenértékének fogadásával és továbbításával kapcsolatos szolgáltatásokat
            (továbbiakban: Blundee Szolgáltatás) nyújt a Partner részére.
          </li>
          <li>A Partner éttermi szolgáltatásokat nyújtó személy, vagy gazdasági társaság, aki az üzletszerű gazdasági
            tevékenységéhez igénybe veszi az Blundee Szolgáltatást.
          </li>
          <li>A Szolgáltató a Partnertől független, önálló szolgáltatási tevékenységet folytat. A tevékenység során a
            Szolgáltató nem a Felhasználók nevében jár el.
          </li>
          <li>A Felek a Szerződés aláírásával a Polgári törvénykönyvről szóló 2013. évi V. törvény (a továbbiakban:
            Ptk.) 6:293 – 6:301 §-ai szerinti tartós közvetítői jogviszonyt hoznak létre, amely alapján a Szolgáltató
            tartós jogviszony keretében, önállóan eljárva a Partnernek harmadik személyekkel (Felhasználók) kötendő
            szerződések közvetítésére, illetve a Partner nevében történő megkötésére és a szerződés szerinti díjak
            Felhasználóktól történő átvételére és továbbítására, a Partner pedig a Szerződésben meghatározott
            szolgáltatási díjak fizetésére köteles.
          </li>
          <li>A Szerződés nem minősül fogyasztói szerződésnek, mivel egyik fél sem fogyasztó.</li>
          <li>A Szolgáltató tevékenysége a hitelintézetekről és a pénzügyi vállalkozásokról szóló 2013. évi CCXXXVII.
            törvény 6. § (4) a-b) pontjára tekintettel nem minősül pénzforgalmi szolgáltatásnak, mivel a Szolgáltató
            kizárólag a Partner megbízásából eljáró önálló kereskedelmi ügynök, amely a Partner és a Felhasználó közötti
            szerződés megkötésére is jogosult. A Szolgáltató a Felhasználó megbízásából pénzügyi műveletet nem hajt
            végre, csupán a Partner közreműködőjeként vesz át pénzt a Felhasználótól (Ptk. 6:129. § (1) bekezdés).
          </li>
          <li>Amennyiben a Szerződés a Partner több éttermére is kiterjed, a Szerződésben foglalt jogok és
            kötelezettségek az éttermekre külön-külön vonatkoznak.
          </li>
        </ol>
        <h2>4. Blundee Szolgáltatás létrejötte, megkezdés</h2>
        <ol>
          <li> Felhasználó és a Partner között szerződés jön létre a Platformon leadott rendelés Partner általi
            befogadásáról szóló elektronikus igazolásnak a Szolgáltatóhoz történő megérkezésével, feltéve, hogy addig a
            Felhasználó nem mondta le a rendelését. A Szolgáltató, mint közvetítő jogosult a Partner nevében az általa
            közvetített szerződés e pont szerint történő megkötésére.
          </li>
          <li> Szolgáltató jogosult és köteles a Partner és a Felhasználók közötti, a Szolgáltató közvetítésével
            létrejött szerződés szerinti Felhasználók részéről történő teljesítés (díjfizetés) elfogadására, amennyiben
            a teljesítés nem készpénzes formában történik, hanem a Szolgáltatóval szerződött pénzforgalmi szolgáltatón
            keresztül (bankkártyás és SZÉP kártyás online fizetés, továbbiakban együtt: Online fizetési szolgáltatás).
            Online fizetési szolgáltatás választása esetén Partner a közvetítési szolgáltatás nyújtása mellett
            kifejezetten megrendeli, Szolgáltató pedig elvállalja online fizetési szolgáltatás nyújtását, melyet
            Szolgáltató közvetített szolgáltatásként a vele szerződött pénzforgalmi szolgáltatón keresztül teljesít a
            Partner részére. A Szolgáltató jogosult a Szerződésben feltüntetett díját a Felhasználók online fizetett
            rendeléseinek összértékéből az ÁSZF 10. pontja szerint levonni.
          </li>
          <li> Szolgáltató a Partnerrel kötött egyedi Partnerségi megállapodás alapján kezdi el az Blundee
            Szolgáltatás nyújtását, a Szerződésben rögzített adatok megadása után.
          </li>
          <li> Partner köteles Szolgáltató részére megadni az éttermenkénti (ha egy Partner több éttermet is üzemeltet)
            a Szolgáltatás elinduláshoz szükséges az alábbi linken megtalálható adatokat:
            https://partner.blundee.com/adatbekeres.pdf
          </li>
          <li> 4.4 pontban lévő mellékletben “Étterem megjelenési részletei”-nél megadott kapcsolattartási adatok
            egyikén a Partnernek biztosítania kell a Szolgáltató és a Felhasználók részére is a nyitvatartási időben
            történő elérhetőséget.
          </li>
          <li> Partner a Szolgáltató részére köteles átadni a teljes étel és ital lapot, melyet a Szolgáltató kapcsolódó
            árakkal és egyéb információkkal együtt a Partnerrel kötött megállapodás szerinti mértékben a Platformon
            feltünteti. A későbbi étel vagy ital lap módosításáról, árak megjelöléséről a Partner rendelkezik. A
            Szolgáltató önálló döntési jogkörében jogosult a Partner Platformon feltüntetett étlapjának elemeit
            eltávolítani és módosítani, ha úgy értesül, hogy azok nem valósak vagy helyesek. Ezekről a módosításokról a
            Szolgáltatónak kötelessége a Partnert tájékoztatni. A Platformra megadott termékek és szolgáltatások árai
            minden esetben bruttó értékek (tartalmazzák a rájuk vonatkozó mindenkori adókat).
          </li>
          <li> Partner szabadon megválaszthatja a kiszállítási területét, viszont 5 kiszállítási terület megjelölésén
            túl már Szolgáltató beleegyezésére van szüksége. A továbbiakban a Szolgáltató fenntartja azon jogát, hogy
            korlátozhassa a Parner kiszállítási területét.
          </li>
        </ol>
        <h2>5. A rendelés menete</h2>
        <ol>
          <li>A Partnerhez a Szolgáltatón keresztül beérkező megrendelésekre köteles 5 percen belül visszajelezni.
            Amennyiben nem történik meg a visszajelzés a megadott időn belül a Szolgáltató a 4.4 pont mellékletében
            megjelölt kapcsolattartási adatok egyikén (telefonhívás/e-mail) felszólítja Partnert a rendelés
            visszaigazolására. Amennyiben a felszólítás után, a megrendelés beérkezésétől számított 10 percen belül sem
            jelez vissza, a megrendelés automatikusan törlésre kerül. Amennyiben a Partner elérhetetlensége miatt
            legalább 10 db rendelést kell törölnie a Szolgáltatónak egy adott tárgyhónapban, úgy automatikusan átkerül a
            “Standard” csomag szerinti elszámolásra az egész tárgyhónapot tekintve, illetve köteles a Szolgáltató
            részére megtéríteni a felszólítási költségeket is, mely telefonhívásonként 100 Forint.
          </li>
          <li>A Partner nem köteles a rendelést elfogadnia, adott esetben visszautasíthatja a rendelést, viszont arról
            köteles a Szolgáltatót, valamint a Felhasználót is azonnal tájékoztatni az elutasítás okát.
          </li>
          <li>A Partner köteles az előre meghatározott szállítási feltételeket teljesíteni, és azonnal tájékoztatni a
            Szolgáltatót és a Felhasználót, ha a szállítási feltételeket nem képes teljesíteni (pl. az előre
            meghatározott szállítási időt túllépi).
          </li>
          <li>A Partnernek lehetősége van a Partner oldalon tájékoztatni a Szolgáltatót a “Nem vette át” gomb
            megnyomásával, ha a rendelés befogadását követően a Felhasználó érdekkörében fellépő hiba miatt (lemondott
            rendelés, kiszállíthatatlan vagy megközelíthetetlen cím) hiúsult meg a Partner és Felhasználó között
            létrejött szerződésteljesítése.
          </li>
          <li>Amennyiben a felhasználó a megrendelés leadása utáni 5 percen belül eláll a megrendelésétől a Partnernek
            lehetősége van a Partner oldalon, - amennyiben már visszaigazolta a rendelést - a “Visszaigazolt rendelések”
            között törölni.
          </li>
          <li>Partnernek kötelessége a Partner oldalon a Szolgáltatónak valós időben a rendelés nyomonkövethetőségét
            visszajelezni a megfelelő gombok megnyomásával. (lásd: a Partnerfelületen történő installáció és bemutató
            rendelés bemutatásakor).
          </li>
          <li>A Partner esetében felmerülő teljesítési nehézség lépne fel (áramszünet, baleset... stb.) a Partner
            oldalon az “Étterem ideiglenes bezárása” gombbal köteles jelezni a Szolgáltató és a Felhasználóknak
            irányába, hogy átmenetileg nem képes a rendelések fogadására.
          </li>
        </ol>
        <h2>6. Fizetési módok a Felhasználók számára</h2>
        <ol>
          <li>A Felhasználónak lehetősége van készpénzes, bankkártyás és online fizetésre is, függetlenül attól, hogy
            személyesen veszi át a Partnernél vagy kiszállítással kérte a megrendelést.
          </li>
          <li>A Felhasználóknak a Rendelési felületen lehetőségük van a fizetési módozatok közül választani.
            Megadhatják, hogy készpénzzel, bankkártyával vagy Széchenyi Pihenő Kártyával történik a kifizetés, valamint
            a készpénzes fizetés esetén megadhatja, ha nagy címlettel szeretne fizetni.
          </li>
          <li>A kiszállításkor vagy a Partnernél történő személyes fizetés esetén a Felhasználó a Partner által lehetővé
            tett fizetési módok egyikén fizeti meg a rendelés végösszegét, közvetlenül a Partner részére.
          </li>
          <li>Online történő fizetés esetén a Felhasználó a rendelés összegét bankkártyával vagy Széchenyi Pihenő
            Kártyával fizetheti meg a következők szerint. A Felhasználó a rendelési folyamat során kiválasztja valamely
            online fizetési opciót. A Platform ezt követően átirányítja a Felhasználót egy, a Szolgáltatóval szerződéses
            kapcsolatban álló, engedéllyel rendelkező pénzügyi szolgáltatóhoz, ahol a Felhasználó kezdeményezheti a
            megrendelés kifizetését a Szolgáltató részére. A sikeres fizetést követően a Szolgáltató továbbítja a
            megrendelést a Partnerhez. A Szolgáltató és Partner közötti elszámolás menetét a 10. pont tartalmazza.
          </li>
        </ol>
        <h2>7. A Partner kötelezettségvállalásai</h2>
        <ol>
          <li>A Partner köteles a működése során minden vonatkozó jogszabályi kötelezettségnek megfelelni, a szükséges
            engedélyeket beszerezni és a rá vonatkozó összes élelmiszerbiztonsággal kapcsolatos előírást maradéktalanul
            teljesíteni. A Partner köteles fokozott figyelmet fordítani az ételek beszerzése, tárolása, elkészítése,
            csomagolása és kiszállítása során, illetve az ezekkel kapcsolatos előírásokat és ajánlásokat betartani. A
            Partner a Felhasználók által elvárható állapotban és módon köteles kiszállítani a megrendelést.
          </li>
          <li>A Partnernek Szolgáltató részére szavatolja, hogy a szerződés megkötésének időpontjában nem áll
            felszámolási eljárás, végelszámolási eljárás vagy csődeljárás alatt.
          </li>
          <li>A Partner hozzájárul ahhoz, hogy a Szolgáltató ellenőrzést tarthasson a kötelezettségek teljesítésének
            tekintetében. Szolgáltatónak nem kötelessége az ellenőrzés megtétele, viszont a Partner helyiségeibe
            előzetes figyelmeztetés nélkül, helyszíni egyeztetést követően beléphet. A Szolgáltató – az ellenőrzés
            eredményére tekintet nélkül – semmilyen módon nem felelős a Partner működéséért.
          </li>
          <li>A Partner kizárólagosan felelős a Szolgáltatónak megküldött és a Parter oldalon megjelent minden
            információ – különösen a termékek ÁFA-kulcsa – helyességéért és valódiságáért, és felelős azért, hogy a
            jogszabályokban megjelölt minden szükséges információ feltüntetésre kerüljön, külön kiemelve az ÁSZF 8.
            pontjában írt információkat.
          </li>
          <li>A Partnernek kötelessége a Szolgáltatót legfeljebb 2 órán belül értesíteni, ha a 4.4 pontban megadott
            mellékletben feltüntetett adatok valamelyike megváltozik - elsősorban a kapcsolattartási adatokra -, mivel
            elengedhetetlen az 5.1 pontban leírtak szerint a Szolgáltatónak a Partner figyelmeztetéséhez.
          </li>
          <li>A Partnernek kötelessége a Partner felületen valós időben megváltoztatni az étel- vagy itallapon teljesen
            vagy átmenetileg elérhetetlen ételek és italok listáját a Felhasználók tájékoztatása végett.
          </li>
          <li>A Partner köteles megfelelő kapacitást és megfelelő szakértelemmel rendelkező személyzetet biztosítani a
            Partner oldalon leadott rendelések határidőben történő és a lehető legjobb minőségű teljesítése érdekében.
            Amennyiben a Szolgáltató a rendelések teljesítésének módjával kapcsolatban általános jellegű utasítást ad át
            a Partner részére, a Partner köteles a Szerződés szerinti rendeléseket az utasításnak megfelelően
            teljesíteni.
          </li>
          <li>A Partner teljes felelősséggel tartozik a munkavállalóinak, illetve a Szerződés teljesítése érdekében
            igénybe vett szerződéses partnereinek a tevékenységéért.
          </li>
          <li>A Partner amennyiben olyan magatartást vagy kijelentéseket tesz, amely a Szolgáltató jó hírnevének
            megsértésére alkalmas, súlyos szerződésszegésnek minősül és a szerződés azonnali felmondását eredményezi és
            bizonyos esetekben polgári peres eljárást von maga után.
          </li>
          <li>
            A Partner a Szerződés alapján leszállított megrendelések mellett nem adhat át a Szolgáltató szolgáltatásának
            megkerülésére irányuló direkt felhívást tartalmazó marketing anyagot a Felhasználónak (tiltott direkt
            marketing tevékenység)
            <ol>
              <li>
                A jelen pont szerinti kötelezettség megszegése tisztességtelen piaci magatartásnak és súlyos
                szerződésszegésnek minősül, a Partner a kötelezettség minden egyes megszegéskor – függetlenül annak
                számától – 100.000,- Ft kötbér fizetésére köteles a Szolgáltató részére.
              </li>
            </ol>
          </li>
          <li>Amennyiben a Partner a Szolgáltatón keresztül felvett rendelést - a Szolgáltató direkt megkerülése
            céljából - törli, majd a felvett rendelést egyéb úton a Felhasználó részére teljesíti, úgy a 7.10.1 pontban
            leírt szankciók lépnek a Partnerrel szemben életbe.
          </li>
          <li>Abban az esetben, ha a Partner az éttermet, amelyre a Szerződés vonatkozik, belátható időn belül
            értékesíteni kívánja, vagy üzemeltetését másra kívánja átruházni, vagy attól egyéb módon megválik vagy
            megválni köteles, a Partner köteles azonnal értesíteni a Szolgáltatót. A Partner és Szolgáltató közötti
            szerződés megszűnik, amennyiben a jogutód továbbiakban kívánja a Szolgáltatást igényelni, úgy új szerződést
            köt vele a Szolgáltató.
          </li>
        </ol>
        <h2>8. Élelmiszerek jelölése, tájékoztatás az összetevőkről</h2>
        <ol>
          <li>
            Az 1169/2011/EU rendelet, a 36/2014 (XII.17.) FM rendelet és egyéb vonatkozó jogszabályok a Felhasználók
            számára készített és értékesített élelmiszerekre vonatkozó részletes tájékoztatási kötelezettséget írnak elő
            az élelmiszert értékesítő élelmiszer-vállalkozás számára. A tájékoztatási kötelezettség kiterjed többek
            között
            az allergiát és intoleranciát okozó anyagokra, egyes színezékekre, továbbá az édesítőszereket tartalmazó
            élelmiszerekre. Online rendelés esetében elsősorban úgy kell megfelelni a kötelezettségnek, hogy az egyes
            élelmiszerekre vonatkozó, a rendelést megelőzően is elérhető tájékoztatókat helyez el az
            élelmiszer-vállalkozás a honlapon. A kötelezettség a nem előre csomagolt élelmiszert előállító és értékesítő
            élelmiszer-vállalkozásokat is terheli, így a Partner köteles annak megfelelni.
          </li>
          <li>A Szolgáltató élelmiszerre irányuló rendeléseket közvetít, így az élelmiszerekhez felhasznált alapanyagok
            beszerzésében és az élelmiszerek előállításában nem működik közre, arra sem hatása, sem rálátása nincs, így
            az
            e pont szerinti tájékoztatási kötelezettségek nem terhelik. A Szolgáltató azonban a honlap megfelelő
            kialakításával megteremti a szükséges feltételeit annak, hogy a Partner teljes körűen megfelelhessen a
            számára
            előírt fenti jogszabályi rendelkezéseknek, viszont az ott közölt adatokért kizárólag a Partner vállal
            felelősséget.
          </li>
          <li>Felhasználó kifejezett kérésére a Partnernek kötelezően tájékoztatnia kell a Felhasználót az adott
            élelmiszer összetevőiről, tömegéről, fogyaszthatósági/minőség megőrzési idejéről és tárolásának
            feltételeiről.
            Ezt a kötelezettséget a Partner közvetlenül teljesíti, a Szolgáltató ezeket az adatokat nem gyűjti, tárolja
            és
            adja ki. A Szolgáltató vállalja, hogy a Felhasználók ilyen irányú kéréseit továbbítja a Partner felé.
          </li>
          <li>Amennyiben a Partner a jelen pont szerinti tájékoztatási kötelezettségének nem tesz eleget vagy az általa
            közölt adat hiányos, hibás vagy nem valószerű, és ebből eredően a Szolgáltatót joghátrány éri (pl. hatósági
            bírság), úgy a Partner teljes mértékben és feltétel nélkül helytállni köteles a Szolgáltató felé.
          </li>
        </ol>
        <h2>9. A Partner saját online rendelési csatornáira vonatkozó feltételek</h2>
        <ol>
          <li>A Partnernek kötelessége a Szolgáltató által közvetített rendeléseket a Partner saját online rendelési
            csatornáin történő rendelésekkel egyenlő feltételekkel kezelni és kiszállítani.
          </li>
          <li>A rendelések teljesítésére vonatkozó feltételek körébe tartozik különösen a termék ára, a kiszállítás
            díja, a minimum rendelési összeg, a termék minősége, a kínálat, a kiszállítási feltételek és az esetleges
            akciók.
          </li>
          <li>A feltételek változása esetén a Partnernek kötelessége nem csak a saját online értékesítési felületén, de
            a Szolgáltató által nyújtott Partner oldalon is mindig a megfelelő tájékoztatást nyújtania a Felhasználók
            számára.
          </li>
          <li>A jelen Szerződésben a Partner saját online rendelési csatornájának minősül a Partner tulajdonában álló,
            illetőleg a Partner által közvetlenül vagy közvetve üzemeltetett minden olyan internetes felület, amelyen
            keresztül rendelést adhatnak le a Felhasználók közvetlenül a Partner részére. A más rendelés közvetítők
            által nyújtott szolgáltatás nem minősül saját rendelési csatornának.
          </li>
          <li>Az időszakosan bevezetett akciók a Partnernek saját értékesítési csatornáján történt meghirdetése nem
            számít a jelen szerződés megszegésének, ha nem haladja meg a 15 napot és arról előzetesen, legalább az
            időszakos akció megkezdésének napján tájékoztatta a Szolgáltatót.
          </li>
        </ol>
        <h2>10. Elszámolás és számlázás</h2>
        <ol>
          <li>A Szolgáltató és a Partner között kötött Partnerségi Megállapodás 1.1-1.5 pontjaiban rögzített elszámolási
            megállapodás és “Díjazási Csomag” adja a két Fél közötti elszámolás alapját, abban az esetben ha a Partner a
            Megállapodásban rögzített kiválasztott “Csomag” feltételeit teljesítette. Amennyiben a vállalt feltételek
            nem teljesülnek úgy az elszámolás a Felek között a “Standard Csomag” szerint kerülnek kiszámításra.
          </li>
          <li>
            A Felek megállapodnak, hogy a Szolgáltatás számlája minden esetben utólag, elektronikus formában, minden
            Szolgáltatási időszakban kétszeri vagy egyszeri számlázási ciklusban készül. A Szolgáltatási időszak,
            minden tárgyhónap első napjával kezdődik és utolsó napjával ér véget.
            <ol>
              <li>Amennyiben az egyszeri számlázás szerint állapodtak meg a Felek a Partnerségi megállapodásban, úgy
                legkésőbb a tárgyhónapot követő hónap 5. munkanapjáig kiállításra kerül a Szolgáltató általi számla.
              </li>
              <li>Ha a felek az egy Szolgáltatási időszakon belüli kétszeri elszámolásban állapodtak meg, úgy egy
                Szolgáltatási időszakban 2 esedékes időszakban kerül elszámolásra a Szolgáltatási díj, melyet minden
                hónapban legkésőbb az esedékes időszak utáni 5. munkanapon kerülnek kiszámlázásra. Az első esedékes
                időszak a tárgyhónap 1. napjától a 15. napjáig, míg a második esedékes időszak a tárgyhónap 16. napjától
                a tárgyhónap utolsó napjáig tart
              </li>
              <li>Ha a felek az egy Szolgáltatási időszakon belüli négyszeri elszámolásban állapodtak meg, úgy egy
                Szolgáltatási időszakban 4 esedékes időszakban kerül elszámolásra a Szolgáltatási díj, melyet minden
                hónapban legkésőbb az esedékes időszak utáni 5. munkanapon kerülnek kiszámlázásra. Az esedékes időszakok
                első és utolsó napja rendre: 1., 7., 15., 16., 24., illetve az utolsó esedékes időszak utolsó napja a
                tárgyhónap utolsó napja.
              </li>
            </ol>
          </li>
          <li>A Szolgáltatás az Áfa tv. 58. § (1) bekezdésében foglaltak alapján időszakos elszámolású ügyletnek
            minősül.
          </li>
          <li>A számla kifizetésének határidejére a számla kiállításától számított 8 naptári nap.</li>
          <li>A Számla kiegyenlítése minden esetben átutalással történik. Ha a Felek más pénznemben nem állapodnak meg,
            akkor a Számla értéke minden esetben Magyar Forintban (HUF) értendő és fizetendő.
          </li>
          <li>A Szolgáltató a Számla mellékletében részletezi a megrendelések számát és összegét.</li>
          <li>A Partner a 10.3-10.4 pontokban leírtak szerint köteles határidőre kiegyenlíteni a Szolgáltatási számla
            összegét . A Számlával kapcsolatos kifogást a fizetési határidő lejártáig támaszthat a Szolgáltatóval
            szemben, kizárólag írásos formában. A Partnernek világosan meg kell jelölnie a számlareklamáció okait,
            valamint köteles csatolni minden a reklamációját alátámasztó dokumentumot. A Szolgáltató az írásos
            reklamációtól számított 5 munkanapon belül megvizsgálja és dönt annak jogosságáról. Amennyiben a reklamáció
            jogosnak bizonyul a Szolgáltató helyesbíti az előzőekben kiállított kifogásolt számlát és kompenzálja a
            Partnert az előzőekben hibás és a helyesbített számla különbözetének értékében. Amennyiben a Szolgáltató nem
            ad helyt a számlareklamációnak, a Partner a jelen ÁSZF szerint bírósághoz fordulhat.
          </li>
          <li>A Felek a Partnerségi Megállapodás megkötésekor, illetve a Megállapodás fennállta alatt jogosultak
            megállapodni arról, hogy a Szolgáltató és a Partner között egy Állandó kompenzációs megállapodás jöjjön
            létre, hogy a Szolgáltató a Partner részére fizetendő online vásárlások utáni kifizetések összegébe
            beszámítja a Partner Szolgáltató irányába fizetendő aktuális Szolgáltatási díjat. Ezen megállapodás
            értelmében a Szolgáltató a Partner előzetes értesítése nélkül jogosult ezen beszámításra azzal, hogy a
            kompenzáció tényéről, a kompenzált összegről (szolgáltatási díj,) a kompenzációt követően a Partnert írásban
            tájékoztatja.
          </li>
          <li>Amennyiben a Felek nem kötöttek Állandó kompenzációs megállapodást, úgy a Partner 16 napot meghaladó
            fizetési késedelme esetén a Szolgáltató jogosult a lejárt követeléseit a Partner igényeivel szemben
            beszámítani, mivel a Partner ebben az esetben a megszegi a Felek között kötött Partnerségi megállapodás 1.4
            pontját.
          </li>
          <li>Amennyiben a Partner a 10.8 pontban leírt kesedelembe esik egy naptári éven belül két alkalommal, úgy a
            Szolgáltatót a Partnerségi megállapodás fennálltáig, illetve ameddig a Felek másként nem rendelkeznek
            megilleti a Partner igényeivel szembeni Szolgáltatási díj automatikus beszámítása.
          </li>
          <li>Amennyiben a Partner a Számla fizetési határidejével késedelembe esik és a 10.7-10.9 pontokban leírtak
            alkalmazása eredménytelen, illetve nem alkalmazható (Partner tartozása magasabb, mint az online fizetésekből
            befolyt összeg), abban az esetben a Szolgáltató a hatályos jogszabályok szerint visszatartási jogot
            érvényesíthet, továbbá a Szolgáltatás működését azonnali hatállyal felfüggesztheti.

            <ol>
              <li>Amennyiben a Partner a fennálló tartozását a lejárt számla teljesítési határidejétől számított
                következő Szolgáltatási időszak végéig sem teljesíti, úgy a Szolgáltatás automatikusan megszűnik.
              </li>
            </ol>
          </li>
          <li>Amennyiben a Partner késedelmesen teljesít a Szolgáltató részére, úgy a késedelem első napjától a
            Szolgáltató késedelmi kamatot számol a Ptk 6:48 §, illetve a Ptk 6:155 §-a alapján, mely a következő
            elszámolási időszakban kiállított számlára lesz terhelve.
          </li>
          <li>
            A Felek megállapodnak abban, hogy a fizetési határidő lejártát követően, amennyiben a 10.7-10.10 pontokban
            leírtak siketelenek a Szolgáltató kielégítésére, vagy valószínűsíthető, hogy a Partner teljesítése nem
            várható, úgy a Szolgáltató a Partnerrel szemben fennálló követelésének kezelésére harmadik felet -
            követeléskezelő társaságot - megbízhat.
            <ol>
              <li>Ebben az esetbe a Szolgáltató a Partnerrel szembeni követelésének kezelése céljából kizárólag a cél
                eléréséhez szükséges adatokat jogosult a harmadik fél követeléskezelő cég részére átadni. Továbbiakban
                az adatkezelésről a 15. pont rendelkezik.
              </li>
            </ol>
          </li>
          <li>
            Amennyiben a Szolgáltatónak a 7.10-7.11. pontokban leírtak szerinti követelése van a Partnerrel szemben, azt
            a következő elszámolási időszakban esedékes Szolgáltatási számla összegéből kompenzálhatja.
          </li>
          <li>
            A Szolgáltató a Partner részére online fizetés lehetőséget biztosít, az alábbi feltételek szerint.
            <ol>
              <li>A Partnernek rendelkeznie kell automata online számlázási lehetőséggel, melyet a Szolgáltató
                előzetesen jóváhagyott (Pl: Billingo.hu).
              </li>
              <li>Az automatikus online fizetési szolgáltatás költségei és egyéb kötelezettségeinek a felelőssége a
                Partnert terheli.
              </li>
              <li>Az Online fizetés eléréséhez a 10.13.1 pontban leírtak alapján a Partner a Szolgáltató részére egy
                meghatalmazást biztosít, hogy az fizetési szolgáltatást közvetítő szolgáltató felületén automatikus
                számlát állítson ki a Partner adataival, melyet online fizetés esetén azonnal elküld a Felhasználó
                részére, egyidejűleg megbízza Szolgáltatót a Felhasználóktól történő beszedésére és a beszedett összeg
                kezelésére.
              </li>
            </ol>
          </li>
          <li>A Felhasználók által az elszámolási időszakban online fizetett rendelések összértékét a Szolgáltató a
            Partner részére a - 10.1 pontban részletezett feltételekkel - a megadott bankszámlára az adott elszámolási
            időszakhoz tartozó Szolgáltatási számla kiállításától számított 8 napon belül átutalja.
          </li>
          <li>Amennyiben a Felhasználó a bankkártyával kifizetett rendelését jelzi a Partner részére, hogy le szeretné
            mondani, - és a megrendelés már “visszaigazolt” státuszban van - a Szolgáltató visszautalja a Felhasználó
            részére a levont összeget, amennyiben a megrendelés lemondása az online megrendeléstől számított 5 percen
            belül történik, és a Partner a Partnerfelületen “meghíusúlt” státuszba helyezi a megrendelést és
            megindoloklja annak okát és tényét.
          </li>
          <li>SZÉP-kártyával történő rendelés ezen pont szerinti lemondása esetén a 10.15 pontban leírtak szerinti
            rendelkezések az irányadóak.
          </li>
          <li>Ha a rendelésről szóló visszaigazolás Szolgáltatóhoz történő megérkezéséig, de legkésőbb a rendelés
            leadásától eltelt öt perces időtartam lejártáig nem mondja le a Felhasználó a rendelést, akkor a Szolgáltató
            átutalja a pénzt a Partnernek a jelen ÁSZF-nek megfelelően.
          </li>
          <li>Amennyiben a Partner alanyi adómentességgel rendelkezik a szerződés megkötésekor, de későbbiekben a
            bevételi határt átlépve ÁFA körbe kerül, azonnali értesítési kötelezettséggel tartozik a Szolgáltató felé.
            Az értesítés elmulasztásából eredő anyagi hátrány miatt a Szolgáltató nem vállalja a felelősséget.
          </li>
        </ol>
        <h2>11. Panaszkezelés</h2>
        <ol>
          <li>
            A Felek rögzítik, hogy a jelen pont vonatkozik minden a Felhasználótól érkező panaszra, észrevételre,
            kérdésre és visszajelzésre, - továbbiakban együtt: Panasz, - mely a Partner termékeit, a kiszállítás
            szolgáltatását, illetve a Szolgáltató rendelési felületét érinti.
          </li>
          <li>
            A Felek rögzítik, hogy Panasznak minősül különösen, de nem kizárólag:
            <ol>
              <li>
                A Partner által nyújtott termékek (termék minősége, összetevők, ára)
              </li>
              <li>
                A Partner által nyújtott kiszállítási szolgáltatás minőségével (gyorsaság, elérhetőség, teljesítési idő)
              </li>
              <li>
                A házhoz szállított termékekkel kapcsolatos észrevételek (minőség, mennyiség, csomagolás)
              </li>
              <li>
                A házhozszállítást végző személyre - futár - vonatkozó észrevételek (viselkedés, öltözet, kommunikáció)
              </li>
              <li>
                A Szolgáltató által közvetített rendelési felületen történt hiba (időszakos elérhetetlenség, rendelési
                felület meghibásodása)
                <ol>
                  <li>
                    A Felek megállapodnak, hogy a Szolgáltató jogosult és köteles – a körülmények által lehetővé tett
                    legrövidebb időn belül és a hatályos jogszabályi határidők betartásával - a Panaszokat megválaszolni
                    és orvosolni minden olyan esetben, amikor a Panasz kizárólag a Szolgáltató tevékenységével
                    kapcsolatos. A jelen pontban foglalt esetekben a felelősség a Szolgáltatót terheli.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>A Felek megállapodnak abban, hogy a Felhasználók által tett valamennyi panaszt a Partner kezeli első
            körben. Mindazonáltal, a Felek tudomásul veszik, hogy a Felhasználók ezirányú döntése esetén Panaszaikat a
            Szolgáltató nyilvánosság számára elérhető Ügyfélszolgálatán, infóvonalain, egyéb fórumain, felületein is
            bejelenthetik.
          </li>
          <li>
            A Felek megállapodnak, hogy a Szolgáltató továbbíthatja a Partnernek a panaszt a körülmények által indokolt
            legkorábbi időpontban az alábbi esetekben:
            <ol>
              <li>a termékekkel kapcsolatos minőségi hiba,</li>
              <li>a rendelés átvételéről szóló visszaigazolás Szolgáltatóhoz történő megérkezését követően érkezett
                felhasználói lemondással kapcsolatos megkeresés,
              </li>
              <li>idegen anyag a futárnak átadott, lezárt csomagolásban átvett termékben, ahol felmerül a
                gyártói/éttermi felelősség (függetlenül a bizonyítottságtól),
              </li>
              <li>egészségügyi panasz, vagy ennek veszélye, ahol a termék minősége kapcsán felmerül a gyártói/éttermi
                felelősség (függetlenül a bizonyítottságtól),
              </li>
              <li>házhozszállítással kapcsolatos Panaszok,</li>
              <li>egyéb Panaszok, amelyek esetében a Szolgáltató nem érintett.</li>
            </ol>
          </li>
          <li>Az ÁSZF 11.4 pontban foglalt esetekben a Panasz megválaszolását a Szolgáltató is elvégezheti.</li>
          <li>Amennyiben a fent felsorolt esetekben - vagy egyéb panaszok esetében - a Partner felelőssége a Szolgáltató
            által vagy egyéb más módon bizonyításra került, úgy a Partner teljes egészében köteles az esetleges
            jogkövetkezmények és felelősség felvállalására és helytállásra.
          </li>
          <li>Amennyiben a Szolgáltató vagy más módon a Partnerrel szembeni Panasz megalapozottnak bizonyul, a
            Szolgáltató a Partnerre történt egyeztetés után kötelezheti a Partnert, hogy kompenzálja a Felhasználót.
          </li>
          <li>A Felek a rendelési, házhozszállítási szolgáltatások, folyamatok javítása, valamint a Panaszok
            kivizsgálása, megválaszolása, orvoslása tekintetében fokozott együttműködésre kötelesek, és kötelesek e
            tárgyban indokolt esetben egyeztetni, valamint a szükséges intézkedéseket foganatosítani a szolgáltatások,
            folyamatok javítása érdekében.
          </li>
        </ol>
        <h2>12. Felelősség harmadik személyek követeléseiért</h2>
        <ol>
          <li>
            Harmadik személytől származó követeléssel szemben a Partner tartozik minden felelősséggel, és mentesíti
            Szolgáltatót ezen követelésekkel szemben.
            <ol>
              <li>Amennyiben a követelés alapja igaznak bizonyulna, a Szerződés Partner általi megszegését jelentené;
              </li>
              <li>a Partner Szerződés körében elkövetett gondatlan vagy szándékos károkozásából származik.</li>
            </ol>
          </li>
          <li>Továbbiakban a Partner felelőssége a 12.1 pontban leírtak értelmében kiterjed minden összegre, kárra vagy
            veszteségre, közvetlen és közvetett formában is, beleértve az ügyvédi díjakat is.
          </li>
          <li>A Szolgáltató kizárólag a saját hibájából származó a közvetített rendelések után vállal felelősséget,
            melyről a Felhasználót tájékoztatja és kárpótolja. A Szolgáltató hibája miatti a Partner részéről történt
            bevételkiesés miatt felelősséget nem vállal és a Partner kártérítésre nem jogosult.
          </li>
        </ol>
        <h2>13. Szellemi alkotásokhoz fűződő jogok</h2>
        <ol>
          <li>A Partner hozzájárul ahhoz, hogy a Szolgáltató a Platformon közzé tehesse a Partner nevét, kereskedelmi
            nevét, logóját, valamint, hogy marketing célú anyagain feltüntethesse. A Partner szavatolja, hogy a nevének,
            kereskedelmi nevének, logójának vagy védjegyének jelen pont szerinti használata nem sérti harmadik személy
            szellemi alkotáshoz fűződő jogát.
          </li>
          <li>A Partner jogosult a Szolgáltató kereskedelmi nevét, logóját saját webes felületén feltüntetni olyan
            formában, hogy azzal Szolgáltató jogos érdekét ne sértse.
          </li>
          <li>A Partner a Szolgáltató írásos beleegyezése nélkül nem jogosul annak nevét, kereskedelmi nevét, logóját
            vagy védjegyét a saját anygaiban megjeleníteni, vagy azokat a jelen ÁSZF-től eltérően alkalmazni.
          </li>
          <li>Amennyiben a Partner olyan felület jelenítené meg a Szolgáltató nevét, kereskedelmi nevét vagy logóját,
            ami a Szolgáltató részéről sérelmesnek bizonyul, úgy Partnernek haladéktalanul gondoskodnia kell
            eltávolításáról. Súlyosabb sérelem esetén kártérítéssel tartozik a Szolgáltató részére.
          </li>
          <li>A Szolgáltató Partner részére engedélyezi a Szolgáltató által díjmentesen készített összes digitális
            tartalom, fényképek, grafikai anyagok, szöveges tartalom, videó anyagok, marketing tartalmak és minden egyéb
            tartalom marketing célú használatát, viszont ezen tartalmak a Szolgáltató tulajdonát képezik, és fenntartja
            a teljes szerzői jogot ezen tartalmak felett.
          </li>
          <li>Amennyiben a Felek között megszűnne a Partnerségi megállapodás, a Partnernek kötelessége a Szolgáltató
            részére visszajuttatni minden a 13.5 pontban részletezett online és offline tartalmat, mely a Szolgáltató
            szellemi tulajdonát képezik, valamint köteles törölni és eltávolítani saját online felületeiről, mivel
            további marketing tevékenységet ezen tartalmakkal nem végezhet.
          </li>
          <li>Amennyiben ha a Partner a Szolgáltató felszólítása után sem tesz eleget a 13.6. pontban leírt
            kötelezettségeinek, úgy megsérti a Szolgáltató szerzői jogait és 100.000 forint sérelemdíj fizetésére
            kötelezhető.
          </li>
        </ol>
        <h2>14. Marketing anyagok, Promóciók</h2>
        <ol>
          <li>A Szolgáltató a Partner részére biztosíthat online és offline marketing anyagokat, (lásd 13.5 pont),
            továbbá ilyenek lehetnek ablakmatrica, molinó, szórólap, asztali kínáló stb.
          </li>
          <li>A Partner vállalja, hogy a Szolgáltató utasításai alapján azokat megfelelően elhelyezi és használja.</li>
          <li>A Partnernek kötelessége a létesítménye bejáratánál jól látható helyen kihelyezni legalább egy a
            Szolgáltató által meghatározott, annak logójával ellátott ablakmatricát. A jelen pontban említett marketing
            anyagok és eszközök a Szolgáltató kizárólagos tulajdonát képezik.
          </li>
          <li>A Partner a Partnerségi megállapodás 1.5 pontban rögzített “Csomagok” közül a Partnerségi megállapodás
            létrejöttekor vállalja az azokban rögzített többlet promóciós anyagok költségének viselését.
          </li>
          <li>A Partner a promóciós anyagok kihelyezésénél, illetve a vállalt közösségi médiában történő Szolgáltató
            promócióját a Szolgáltató által megnevezett közösségi platformon és az alább megnevezett formában a megadott
            hivatkozáson (partner.blundee.com/marketing) megtalálható hirdetési minták alapján a szerződésben vállalt
            Promóciós kötelezettséget minden tárgyhónapban bizonyíthatóan elhelyezi.
          </li>
          <li>A Partnernek Promóciós kötelezettségben vállalt hirdetésében az étterem Blundee Szolgáltatás
            hivatkozásán kívül tilos elhelyezni bármilyen más hivatkozást, (Pl: más szolgáltatás, étterem online saját
            rendelési felülete) illetve telefonszámot és bármilyen más eszközt, amely a Szolgáltató megkerülésére
            irányul.
          </li>
          <li>A Partnernek Promóciós kötelezettségben vállalt hirdetésében kötelezően követnie kell a hirdetési
            mintákat, tartalomban, formalitásban nem térhet el a megadott példáktól. Amennyiben a Partner el szeretne
            térni a Szolgáltató által megadott hirdetési mintáktól, fel kell vennie a kapcsolatot a Szolgáltatóval
            írásos formában és elküldenie a tervezetet jóváhagyásra. A Szolgáltatónak 14 napja van jóváhagyni a Partner
            tervezetét, amennyiben 14 napon belül a Szolgáltató nem válaszol vagy nem sikerült a Partnerrel egyezségre
            jutni, a Partner kérvénye automatikusan elutasításra kerül. Amennyiben jóváhagyásra kerül, a Szolgáltató
            esetlegesen megszabhatja a promóció maximális idejét a Partner a tervezete alapján. A hirdetési minták az
            alábbi linken érhetők el: https://partner.blundee.com/marketing
          </li>
          <li>A Szolgáltatónak jogában áll utasítani a Partnert, melyben a Szolgáltató úgy véli érdekei sérülnek, hogy
            módosítsa vagy törölje a Promóciós kötelezettségben vállalt hirdetést a Szolgáltató feltételei szerint, a
            Partnernak kötelessége 48 órán belül eleget tenni a Szolgáltató kérésének, különben súlyos szerződésszegést
            követ el a Partner.
          </li>
          <li>A Partner Promóciós kötelezettségben vállalt hirdetés tartalmán túl a hirdetési paraméterekre (pl:
            hirdetési terület, hirdetési idősávok, hirdetés célközönsége) is vonatkoznak a Partnerre és Szolgáltatóra a
            14.7 és 14.8 pontban megszabott feltételek, minden Szolgáltató által jóváhagyott paraméter a hirdetéséhez
            megtalálható az alábbi hivatkozásban: https://partner.blundee.com/marketing
          </li>
          <li>Amennyiben Partner nem tesz eleget a 14.5 pontban részletezett kötelezettségeinek, vagy azokat a
            Szolgáltató kérésére nem tudja hitelt érdemlően igazolni, úgy a Szolgáltató felszólítás után automatikusan
            átkerül a “Standard” csomag szerinti elszámolásba.
          </li>
          <li>A Felek közötti külön megállapodás esetén a Szolgáltató egyedi akciókba is bevonhatja a Partnert.</li>
          <li>A Szolgáltató külön megállapodás esetén vállalja a forgalom bővítését célzó, széleskörű, minden résztvevő
            számára azonos feltételekkel meghirdetett üzletpolitikai kampányok, promóciók szervezését, lebonyolítását.
          </li>
        </ol>
        <h2>15. Adatvédelem</h2>
        <ol>
          <li>Az általános adatvédelmi rendelet (2016/679/EU rendelet; „GDPR”), továbbá az információs önrendelkezési
            jogról és az információszabadságról szóló 2011. évi CXII. törvény („Infotv.”) alapján a Felek önálló
            adatkezelőknek számítanak.
          </li>
          <li>A Felhasználó személyes adatait a Szolgáltató a Felhasználó hozzájárulása alapján továbbítja kizárólag a
            rendelés teljesítésének céljából a Partner részére, a Partner ezen adatokat harmadik személynek nem adhatja
            tovább és egyéb célokra (pl. reklámozásra, Felhasználók közvetlen megkeresésére) nem használhatja fel.
          </li>
          <li>A Felek megegyeznek, hogy a Felhasználóktól származó adatok a Partnernek való átadás után a Szolgáltató a
            Partner általi kezelésének jogszerűségével kapcsolatosan semmilyen felelősséget nem vállal.
          </li>
          <li>A Felhasználók megfelelő adatvédelmi tájékoztatással történő ellátása a Szolgáltató és a Partner önálló
            felelőssége és kötelezettsége.
          </li>
          <li>A szakmai kapcsolattartás és a szolgáltatás eredő kötelezettségek teljesítésének érdekében a Szolgáltató
            tájékoztatja Partnert, mint adatkezelőt, hogy kapcsolattartási adatokat és személyes adatokat kezel.
          </li>
          <li>A kapcsolattartási adatokat Szolgáltató papír alapon a székhelyén, vagy elektronikusan a szerverén
            tárolja.
          </li>
          <li>Az adatkezelés jellemzői:</li>
          <li>
            Az adatkezelés jellemzői:
            <ol>
              <li>Adatkezelés jogalapja: A szolgáltató jogos érdeke, valamint a Szolgáltatás tényének teljesítése</li>
              <li>Kezelt adatok köre: Cégnév, Székhely, Étterem neve, Étterem címe Postázási cím, Adószám, Számlavezető
                bank neve és bankszámlaszám, Telefonszám, E-mail cím, Nyitvatartási adatok, Szállítási terület,
                Képviselő/Kapcsolattartó elérhetőségei, személyes adatai, feltöltött képek, közvetített termékek és
                szolgáltatásokkal kapcsolatos adatok.
              </li>
              <li>Kezelt adatok forrása: A Partner által kiállított Partnerségi megállapodás, valamit az Adatbekérő
                adatlap.
              </li>
              <li>Az adatkezelés időtartama: A Szolgáltató az esetleges későbbi igények rendezése érdekében a Partner
                adatait a szerződéses kapcsolat megszűnését követő 5 évig tárolja az általános elévülési határidő
                tartalmának megfelelően.
              </li>
              <li>Adattovábbítás: A Szolgáltató a kapcsolatot a Partnerrel közvetlenül vagy a Partnerfelületen keresztül
                tartja.
              </li>
            </ol>
          </li>
        </ol>
        <h2>16. Felfüggesztés, szünetelés</h2>
        <ol>
          <li>A Szolgáltató saját döntési körében határozhat úgy, hogy a Partner részére felfüggeszti vagy szünetelteti
            a Szolgáltatást, melyről köteles a Partnert megfelelő időben értesíteni és a felfüggesztés vagy szünetelés
            tényét megindokolni.
          </li>
          <li>Felfüggesztés vagy szünetelés esetében érvényben marad a megkötött Partnerségi megbízás, viszont a
            Szolgáltató nem köteles a Szolgáltatás további nyújtására és a Partner részére elérhetetlenné teheti a
            Partnerfelületet.
          </li>
          <li>
            A Szolgáltató felfüggesztheti a Partnert, amennyiben:
            <ol>
              <li>A Partner határidőben nem tett eleget bármely fizetési kötelezettségének.</li>
              <li>A saját szolgáltatásának nyújtásához szükséges jogszabályi követelményeket vagy a Szolgáltatóval
                kötött Partnerségi megállapodás és jelen ÁSZF-ből eredő kötelezettségeit bizonyítható módon nem képes
                vagy nem lesz képes teljesíteni.
              </li>
              <li>A Partner tiltott direkt marketing tevékenységet folytatott.</li>
              <li>A Partner ellen felszámolási eljárás, végelszámolási eljárás indul, vagy csődeljárás alá kerül.</li>
              <li>A Partner tevékenységére a Szolgáltatóhoz a Felhasználók vagy más úton az átlaghoz képest kiugróan sok
                panasz érkezik.
              </li>
              <li>A Partner működése a Szolgáltató üzleti hírnevét rombolja vagy veszélyezteti, ideértve, ha
                médianyilvánosságot kapó szerződésszegést vagy törvénysértést követ el.
              </li>
            </ol>
          </li>
          <li>A Szolgáltató akkor is felfüggesztheti a Partner szolgáltatásait, ha bármely rendelés átadása során a
            Szolgáltató ügyfélszolgálata három egymást követő alkalommal sem éri el a Partnert a megadott
            elérhetőségeken.
          </li>
          <li>A Partner is dönthet a Szolgáltatás időszakos szüneteléséről, melyről tájékoztatnia kell a Szolgáltatót,
            valamint a szünetelés kérését meg kell indokolnia Szolgáltató részére.
          </li>
          <li>Amennyiben a Partner kérésére történik a Szolgáltatás szünetelése, úgy annak írásos indoklásától kezdődően
            számított maximum három hónapig szünetelhet Partnerségi megállapodás, annak megszűnése nélkül.
          </li>
          <li>A Partner részére továbbra is elérhetővé válik a Partnerfelület, viszont maga a Szolgáltatás a Rendelési
            felületen a szünetelés időtartamára nem lesz elérhető. A Szolgáltató felületén abban az időszakban a Partner
            étterme nem fog megjelenni.
          </li>
          <li>Szünetelés esetén három hónapot követően a Szerződés megszűnik, ha a Partner a határidő lejártát
            megelőzően nem kezdeményezi a szünetelés végét, vagy nem jelölt meg előzetesen olyan időpontot, amikor ismét
            aktívvá válik a szolgáltatása a Platformon.
          </li>
          <li>Amennyiben a felfüggesztés vagy a szünetelés feltételei megszűnnek, úgy a Szolgáltatónak kötelessége
            jelezni a Partner irányába a Szolgáltatás ismételt elérhetőségét.
          </li>
          <li>Felek a Partnerségi megállapodás szerinti jogairól és kötelezettségeiről szóló, a felfüggesztés vagy
            szünetelés alatt nem érvényesülő rendelkezések a felfüggesztés vagy szünetelés megszüntetésétől újra
            hatályba lépnek.
          </li>
          <li>Felfüggesztés vagy szünetelés esetén a Partner kártérítésre semmilyen formában nem jogosult, ideértve a
            forgalom elmaradását is.
          </li>
        </ol>
        <h2>17. A Szerződés módosítása, megszűnése</h2>
        <ol>
          <li>A Felek között kötött Partnerségi megállapodás határozatlan időre szól</li>
          <li>Mindkét Fél jogosult a Partnerségi megállapodásban vagy az Adatbekérő adatlapon feltüntetett értesítési
            címeket, a kapcsolattartó személyét vagy a meglévő adataiban beállt változás tényét megváltoztatni,
            amennyiben erről a másik Felet értesítette. A változtatásra vonatkozó értesítést az értesítés küldésére a
            változás előtt jogosult személy vagy a Szerződés aláírására jogosult személy teheti meg
          </li>
          <li>Ha a Szerződés aláírására jogosult személy megváltozik, a másik Fél jogosult a változással érintett féltől
            a változás jogszerűségét alátámasztó irat (cégbírósági végzés vagy meghatalmazást tartalmazó irat)
            másolatát-, indokolt esetben az eredeti irat bemutatását kérni
          </li>
          <li>Felek megállapodnak abban, hogy a Szerződés bármilyen egyéb módosítása csak közös megegyezés alapján, és
            kizárólag írásban érvényes, kivéve az ÁSZF 10. pontban foglaltakat, illetve az ÁSZF 22. pontban foglalt
            szerződésátruházást, melyhez Partner, mint a szerződésben maradó fél jelen Szerződés aláírásával
            hozzájárulását adja
          </li>
          <li>
            A Szerződést bármelyik fél, bármelyik napon indoklás nélkül felmondhatja a felmondási idő lejárta szerinti
            naptári hónap utolsó napjára (rendes felmondás). A felmondási idő a felmondás napján kezdődik és minden
            esetben 30 napos időtartamú.
            <ol>
              <li>
                A Felek a Partnerségi megállapodás megkötésének idejétől számított 30 napon belül azonnali hatállyal
                felmondhatják a megkötött megállapodást és a Szolgáltató 5 munkanapon belül törli Partnert a
                Platformról.
              </li>
            </ol>
          </li>
          <li>A Partnerségi megállapodást a Szolgáltató azonnali hatállyal jogosult felmondani, amennyiben a Partner a
            megállapodáson alapuló bármely kötelezettségét súlyos mértékben megszegi. A Partnerségi megállapodás súlyos
            mértékű megszegését jelenti különösen, ha a Partner fizetési késedelembe esik, Szolgáltató platformján
            feltüntetendő információkkal kapcsolatban valótlan tényt közöl, a Szolgáltató hírnevét csorbítja,
            fizetésképtelenségi eljárás hatálya alá kerül vagy az Általános Szerződési Feltételekben meghatározott egyéb
            súlyos szerződésszegési okot követi el.
          </li>
          <li>Rendes felmondás esetén a Partnerségi megállapodás a megküldött felmondási nyilatkozatban megjelölt napon
            szűnik meg. Azonnali hatályú felmondás esetén a felmondási nyilatkozat megküldésének napján szűnik meg a
            Megállapodás.
          </li>
          <li>A Partnerségi megállapodás megszűnésekor a Szolgáltató a Partnernek nyújtott Szolgáltatást a felmondási
            idő utolsó napjával vagy a Felek által közösen megállapított napon leállítja, a Partnert a Platformról
            eltávolítja.
          </li>
          <li>A Partnerségi megállapodás megszűnése esetén Partner köteles minden, a Szolgáltatótól kapott és
            rendelkezésre álló marketing anyagot és eszközt a Szolgáltató utasítása alapján visszajuttatni vagy
            megsemmisíteni, a Szolgáltató logóját és a Szolgáltatóra vonatkozó minden utalást a honlapjáról, étterméből
            és marketing anyagaiból eltávolítani.
          </li>
        </ol>
        <h2>18. Értesítések és változtatások</h2>
        <ol>
          <li>
            Felek megállapodnak abban, hogy a Partnerségi megállapodás hatálya alá tartozó értesítések és
            tájékoztatások egymás irányába akkor hatályosak, ha azokat írásban, személyesen vagy könyvelt
            levélpostai küldemény, illetve elektronikus levél útján, a másik Fél által meghatározott elérhetőségére
            juttatják el.
          </li>
          <li>
            Az e-mail, vagy sürgős esetben sms útján küldött értesítés – ha a kézbesítés ettől eltérő időpontját más
            nem igazolja – az elküldés napján és időpontjában tekintendő kézbesítettnek.
          </li>
          <li>
            A Partnernek lehetősége van a Partner felületen az alábbi módosításokat a Szolgáltatótól függetlenül
            elvégezni:
            <ol>
              <li>
                Nyitvatartás
              </li>
              <li>
                Ételek nevét, leírását, feltéteket, árakat
              </li>
              <li>
                Minimális rendelési összeget
              </li>
            </ol>
          </li>
          <li>
            A 18.3 pontban leírt lehetőségeken felüli változtatásokra már a Szolgáltatóval történt egyeztetés után
            van lehetősége a Partnernek. A Parner jelzésétől számított legkésőbb 5 munkanapon a Szolgáltató elvégzi
            a kért módosításokat.
          </li>
        </ol>
        <h2>19. Üzleti titok</h2>
        <ol>
          <li>
            A Felek üzleti titoknak minősítenek bármely olyan tényt, információt, megoldást vagy egyéb adatot (beleértve
            az adatból levonható következtetéseket is), függetlenül annak megjelenési formájától, amelyet a Felek a
            Partnerségi megállapodás teljesítésével összefüggésben egymásnak átadnak, vagy amely a Megállapodás
            teljesítése során egyéb módon a másik Fél tudomására jut.
          </li>
          <li>
            Üzleti titoknak minősül különösen a szellemi termék, egyéb know-how, a szabályzatok, az informatikai és
            fizikai hozzáférés ellenőrzését biztosító adatok (felhasználónevek és jelszavak), üzleti vagy üzemi
            folyamatok és módszerek, tervek és specifikációk, egyéb szoftverek és adatbázisok, valamint más szellemi
            alkotások, ötletek és előkészített projektek.
            A Partnerségi megállapodás szövege vagy annak bármely része és mellékletei üzleti titoknak tekintendő.
          </li>
          <li>
            Az üzleti titokra az üzleti titokról szóló 2018. évi LIV. törvény vonatkozik, a Felek kötelesek az üzleti
            titkot ennek megfelelően védeni és titokban tartani.
          </li>
          <li>
            A jelen pont szerinti titoktartási kötelezettség a Feleket a Szerződés megszűnésétől számított 5 évig
            terheli.
          </li>
        </ol>
        <h2>20. Vis maior</h2>
        <ol>
          <li>
            E pont értelmezésében vis maior eseménynek minősül az érintett Fél érdekkörén kívül eső okból eredő,
            elháríthatatlan esemény, így különösen (i) minősített időszaki helyzet (rendkívüli állapot, szükségállapot,
            veszélyhelyzet) vagy egyéb katasztrófahelyzetnek minősíthető helyzet, függetlenül a minősített időszaki
            helyzet tényleges kihirdetésétől vagy a katasztrófa kinyilvánításától; (ii) sztrájk vagy hasonló
            munkabeszüntetés, a Fél munkavállalói által végrehajtott sztrájk vagy munkabeszüntetés kivételével.
          </li>
          <li>
            Egyik Fél sem felelős a Szerződés szerinti kötelezettségeinek nem-, hibás- vagy késedelmes teljesítésért, ha
            azt az előző pontban meghatározott vis maior esemény okozta. Vis maior esemény bekövetkezte esetén az
            érintett Fél köteles a másik Felet írásban haladéktalanul értesíteni.
          </li>
          <li>
            Vis maior esemény időtartama alatt a Szerződés hatálya szünetel, abban a mértékben, amennyiben a Szerződés
            teljesítése a vis maior esemény miatt nem lehetséges.
          </li>
          <li>
            A Szolgáltató nem köteles a beszedett pénzt időben utalni, sem a Partnernek nem kötelessége a számlákat
            időben fizetni.
          </li>
        </ol>
        <h2>21. Az ÁSZF hatálya és módosítása</h2>
        <ol>
          <li>
            A jelen ÁSZF az ÁSZF elején megjelölt naptól kezdődően visszavonásig, illetve módosításig hatályos. Az ÁSZF
            aktuális és korábbi változatai mindenkor elérhetőek a partner.blundee.com/aszf weboldalon.
          </li>
          <li>
            A Szolgáltató jogosult a jelen ÁSZF-et bármikor, egyoldalúan módosítani a partner.blundee.com/aszf
            weboldalon történő közzététellel.
          </li>
          <li>
            Amennyiben a Szolgáltató úgy ítéli meg, hogy az ÁSZF módosítása a Felek jogait és kötelezettségeit érinti
            (érdemi változás), úgy a módosítás hatálybalépését legalább 5 munkanappal megelőzően a módosított ÁSZF-et a
            a partner.blundee.com/aszf weboldalon közzéteszi, továbbá arról a Partnert a 18. pontnak megfelelően
            értesíti.
          </li>
          <li>
            Amennyiben a Szolgáltató úgy ítéli meg, hogy az ÁSZF módosítása a Felek jogait és kötelezettségeit nem
            érinti (formai változás), a Szolgáltató nem köteles a módosításról (pl. elírás kijavítása) a Partnert
            tájékoztatni.
          </li>
          <li>
            Amennyiben a Partner a változásokkal nem ért egyet, a Partnerségi megállapodást a módosított ÁSZF hatályba
            lépése előtt felmondhatja, amely esetben a Megállapodás a módosított ÁSZF hatálybalépésének napjával
            megszűnik.
          </li>
          <li>
            A Partnerségi megállapodás a Felek közötti megszűnésével vagy megszüntetésével a Felek között az ÁSZF is
            hatályát veszti a 19.4. pont és minden olyan rendelkezés kivételével, amelyek természetüknél fogva tovább
            élnek.
          </li>
        </ol>
        <h2>21. Vegyes rendelkezések</h2>
        <ol>
          <li>
            A Felek a Partnerségi megállapodás teljesítése során együttműködnek, a Szerződéssel kapcsolatos minden
            lényeges körülményről tájékoztatják egymást.
          </li>
          <li>
            Szolgáltató jogosult a Partnerségi megállapodásban rögzített jogait és kötelezettségeit harmadik személyre
            átruházni a Partner külön hozzájárulása nélkül azzal a feltétellel, hogy a Szolgáltató a Megállapodásba
            belépő jogutódja a Megállapodás szerinti kötelezettségek teljesítését előzetesen írásban vállalja. A
            jogutóddal létrejövő jogviszony tartalma megegyezik a jelen Megállapodásban foglaltakkal. Szolgáltató
            köteles a személyében bekövetkezett változásról Partnert a változástól számított 15 napon belül írásban
            tájékoztatni.
          </li>
          <li>
            Amennyiben a jelen Szerződés bármely rendelkezése érvénytelen, vagy végrehajthatatlan volna, úgy az a
            többi rendelkezését nem érinti.
          </li>
          <li>
            Amennyiben a jelen Megállapodás bármely részének érvénytelenségét a bíróság megállapítja, a jelen
            Megállapodás valamennyi többi feltétele és rendelkezése ettől függetlenül hatályban marad, feltéve, hogy a
            Felek jelen Megállapodást az érvénytelen rész nélkül is megkötötték volna.
          </li>
          <li>
            A Felek minden elvárható intézkedést megtesznek azért, hogy az érvénytelen vagy végrehajthatatlan
            rendelkezést egy olyan érvényes és végrehajtható rendelkezéssel helyettesítsék, amely célját és hatását
            tekintve leginkább megfelel az eredeti rendelkezésnek.
          </li>
          <li>
            Bármely vitát, amely a jelen Szerződésből vagy azzal összefüggésben, annak megszegésével, megszűnésével,
            érvényességével vagy értelmezésével kapcsolatban keletkezik, a Felek elsődlegesen tárgyalásos úton kísérlik
            meg rendezni. Amennyiben a tárgyalások 30 napon belül nem vezetnek eredményre, Felek a hatáskörrel és
            illetékességgel rendelkező bírósághoz fordulnak, járásbíróság hatáskörébe tartozó ügyben a felek kikötik a
            Pesti Központi Kerületi Bíróság kizárólagos illetékességét.
          </li>
          <li>
            A Szolgáltató által nyújtott szoftverek biztonság szempontjából megfelelőek, viszont a Szolgáltató nem
            felelős
            bármilyen kárért, ami a csatlakozás kiber-biztonságához köthető, mindkét fél saját maga felelős a adatai
            biztonságáért.
          </li>
          <li>Minden itt nem megfogalmazott korlátozás és szabályozásról a Ptk. rendelkezik.</li>
          <li>A Partnerségi megállapodásra a magyar jog rendelkezései az irányadók, amiről jelen ÁSZF és a Partnerségi
            megállapodás nem rendelkezik a hatályos jogszabályok az irányadók.
          </li>
        </ol>
      </Wrapper>
    );
  }
}
