import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import type { Element } from "../../model/BluePrint";
import { Zone, ZoneTypes } from "../../model/BluePrint";
import { BluePrintAPI } from "../../utils/api/BluePrintAPI";
import { Button, Input } from "../FormComponents";
import { SubSection } from "../home/DetailedSettings";

export default class ZoneEditor extends Component {
  eventIDs: number[] = [];

  state: {
    showModal: boolean,
    edit: null | Zone,
    title: string,
    language: number,
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewZone),
    language: ContextSystem.language,
  };

  save() {
    let element: Zone = { ...this.state.edit };
    if (!element.name || element.name.length < 2) {
      toast(Language.getName(Names.NameTooShort));
      return;
    }

    BluePrintAPI.saveZone(element, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  edit(element: Element) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyZone),
      edit: element,
    });
  }

  addNew() {
    let edit: Zone = {
      id: -1,
      enabled: true,
      elements: [],
      customOpenHours: false,
      floorID: -1,
      name: "",
      maxPeople: 10,
      openHours: [],
      type: ZoneTypes.DINING_AREA,
      partnerID: -1,
    };

    this.setState({
      showModal: true,
      title: Language.getName(Names.NewZone),
      edit,
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs)
      EventSystem.unsubscribe(eid);
  }

  componentDidMount() {
    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.edit_zone, (element) => {
      if (!element)
        this.addNew();
      else
        this.edit(element);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{Language.getName(Names.Name)}</label>
          <Input
            value={this.state.edit ? this.state.edit.name : ""}
            type="text"
            placeholder={Language.getName(Names.Name)}
            onChange={(e) =>
              this.setState({
                edit: { ...this.state.edit, name: e.target.value },
              })
            }
            width={"100%"}
          />
          <br />
          <label>{Language.getName(Names.CoverRange)}</label>
          <SubSection>
            <Input
              value={this.state.edit ? this.state.edit.maxPeople : ""}
              min={0}
              type="number"
              placeholder={Language.getName(Names.MaxCover)}
              onChange={(e) =>
                this.setState({
                  edit: {
                    ...this.state.edit,
                    maxPeople: isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value),
                  },
                })
              }
              width={"49%"}
            />
            {/*<CheckBox>*/}
            {/*  <input*/}
            {/*    type={"checkbox"}*/}
            {/*    checked={this.state.edit?.customOpenHours ?? false}*/}
            {/*    onChange={e => this.setState({*/}
            {/*      edit: {*/}
            {/*        ...this.state.edit,*/}
            {/*        customOpenHours: e.target.checked,*/}
            {/*      }*/}
            {/*    })}*/}
            {/*  />*/}
            {/*  <Label onClick={() => this.setState({*/}
            {/*    edit: {*/}
            {/*      ...this.state.edit,*/}
            {/*      customOpenHours: !this.state.edit.customOpenHours*/}
            {/*    }*/}
            {/*  })}>*/}
            {/*    {Language.getName(Names.CustomOpenHours)}*/}
            {/*  </Label>*/}
            {/*</CheckBox>*/}
          </SubSection>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
