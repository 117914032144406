import React, { Component } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { AuthAPI } from "../../utils/api/AuthAPI";
import Orders from "./Orders";
import ToggleButton from "react-toggle-button";
import { Section, SubSection } from "./DetailedSettings";
import type { PMSChangeType } from "../../utils/api/ProfileAPI";
import { ProfileAPI, RestaurantSettings } from "../../utils/api/ProfileAPI";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Contract } from "../../model/Contract";
import Language, { Names } from "../../utils/Language";
import { FaCheck, FaTimes } from "react-icons/all";
import { Shop } from "../../model/Shop";
import Config from "../../utils/Config";
import { Image, ImageTypes } from "../../model/Image";
import { AllPaymentMethod, PaymentMethods, PaymentMethodSetting } from "../../model/PaymentMethodSetting";
import PhoneNumber from "libphonenumber-js";
import { InputWrapper } from "../modals/AddContractModal";
import { Label } from "../modals/ContractsDetailsModal";
import EmailValidator from "../../utils/EmailValidator";
import { ActivateAPI, SaveDetailsResponse } from "../../utils/api/ActivateAPI";
import ErrorMessage from "../../utils/api/ErrorMessages";
import type { ShippingMethod } from "../../model/ShippingPrice";
import { ShippingMethods } from "../../model/ShippingPrice";
import { TranslatableString, VATValue, VATValueModelTypes } from "../../model/Product";
import ProductEditor from "../modals/ProductEditor";
import type { ShiftControlExtraParameter } from "../../model/ShiftControl";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5%;
`;

export const Input = styled.input`
  width: ${({ width }) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

export const LinkButton = styled.div`
  color: #007bff;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #005ac2;
    text-decoration: underline;
  }
`;

export const Button = styled.div`
  border: 1px solid #931c20;
  border-radius: 5px;
  padding: 5px 15px;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  text-align: center;
  transition: height 0.2s ease-in-out;
  color: #a62024;

  &:hover,
  &:active {
    background-color: #a62024;
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CoverImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  max-height: 300px;
  box-shadow: 0 0 5px 1px black;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
`;

const CoverImage = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CoverImageButton = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
`;

export default class Settings extends Component {
  state: {
    editPreparedOrderMaxDays: string,
    editShowPreparedOrdersBeforeMinutes: string,
    editPreparedOrderOnlyOnlinePayment: string,
    editPublicEmail: string,
    editPublicPhone: string,
    editOldPass: string,
    editNewPass: string,
    profile: Shop,
    contracts: Contract[],
    language: number,

    editFirstName: string,
    editLastName: string,
    editPhoneNumber: string,
    editPin: string,
    editVATValues: VATValue[],
    shiftControlParameters: ShiftControlExtraParameter[],
  } = {
    editPreparedOrderMaxDays: ContextSystem.selectedShop?.preparedOrderMaxDays + "",
    editShowPreparedOrdersBeforeMinutes: ContextSystem.selectedShop?.showPreparedOrdersBeforeMinutes + "",
    editPreparedOrderOnlyOnlinePayment: ContextSystem.selectedShop?.preparedOrderOnlyOnlinePayment,
    editOldPass: "",
    editNewPass: "",
    editPublicEmail: ContextSystem.selectedShop?.publicEmail,
    editPublicPhone: ContextSystem.selectedShop?.publicTel,
    profile: ContextSystem.selectedShop,
    contracts: ContextSystem.contracts,
    language: ContextSystem.language,
    editFirstName: ContextSystem.profile.firstName,
    editLastName: ContextSystem.profile.lastName,
    editPhoneNumber: ContextSystem.profile.phoneNumber,
    editPin: ContextSystem.profile.pin,
    editVATValues: ContextSystem.selectedShop?.vatValues,
    shiftControlParameters: ContextSystem.shiftControlParameters.filter(scep => scep.shopID === ContextSystem.selectedShop?.id),
  };

  toggleRestaurantSetting(type: number, value: boolean) {
    ProfileAPI.toggleRestaurantSetting(type, value, res => {
      if (res.error !== ErrorMessage.OK)
        return;

      toast(Language.getName(Names.Saved));
    });
  }

  savePreorderInfo() {
    let maxDays: number = parseInt(this.state.editPreparedOrderMaxDays);
    let showMin: number = parseInt(this.state.editShowPreparedOrdersBeforeMinutes);

    if (maxDays < 0) {
      maxDays = 0;
      this.setState({ editPreparedOrderMaxDays: 0 });
    }
    if (showMin < 5) {
      this.setState({ editShowPreparedOrdersBeforeMinutes: 5 });
      maxDays = 5;
    }

    if (maxDays > 7) {
      toast(Language.getName(Names.PreparedOrderMaxDaysAtMost7Error));
      this.setState({ editPreparedOrderMaxDays: 7 });
      return;
    }

    if (showMin > 120) {
      toast(Language.getName(Names.PreparedOrderShowMinAtMost2HoursError));
      this.setState({ editShowPreparedOrdersBeforeMinutes: 120 });
      return;
    }

    AuthAPI.savePreparedOrderInfo(maxDays, showMin, this.state.editPreparedOrderOnlyOnlinePayment, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      ContextSystem.setShopPreparedOrderInfo(maxDays, showMin, this.state.editPreparedOrderOnlyOnlinePayment);
    });
  }

  savePublicInfo() {
    if (!EmailValidator(this.state.editPublicEmail)) {
      toast(Language.getName(Names.EmailAddressNotValid));
      return;
    }
    if (!(PhoneNumber(this.state.editPublicPhone, "HU")?.isValid() ?? false)) {
      toast(Language.getName(Names.PhoneNumberNotValid));
      return;
    }

    AuthAPI.savePublicInfo(this.state.editPublicEmail, this.state.editPublicPhone, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      ContextSystem.setShopPublicInfo(this.state.editPublicEmail, this.state.editPublicPhone);
    });
  }

  isNumeric(str: string) {
    if (typeof str != "string")
      return false; // we only process strings!

    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
  }

  saveProfileDetails() {
    if (this.state.editFirstName.length < 3) {
      toast(Language.getName(Names.FirstNameShortDetails));
      return;
    }
    if (this.state.editLastName.length < 3) {
      toast(Language.getName(Names.LastNameShortDetails));
      return;
    }
    if (!(PhoneNumber(this.state.editPhoneNumber, "HU")?.isValid() ?? false)) {
      toast(Language.getName(Names.PhoneNumberNotValid));
      return;
    }
    if (!this.isNumeric(this.state.editPin)) {
      toast(Language.getName(Names.PinOnlyNumbers));
      return;
    }
    if (this.state.editPin.length < 4) {
      toast(Language.getName(Names.PinTooShort));
      return;
    }
    if (this.state.editPin.length > 8) {
      toast(Language.getName(Names.PinTooLong));
      return;
    }

    ActivateAPI.saveProfileDetails(
      this.state.editFirstName, this.state.editLastName, this.state.editPin, this.state.editPhoneNumber,
      (res: SaveDetailsResponse) => {
        if (res.error !== 0)
          return;

        toast(Language.getName(Names.Saved));
        ContextSystem.updateShopProfile(ContextSystem.profile, res.shopProfile);
      },
    );
  }

  savePass() {
    if (!ContextSystem.profile || ContextSystem.adminProfile) {
      toast(Language.getName(Names.LoggedInAsAdminNoPassChange));
      return;
    }
    if (this.state.editOldPass.length < 5 || this.state.editNewPass.length < 5) {
      this.setState({ editOldPass: "", editNewPass: "" });
      toast(Language.getName(Names.GivenPasswordsTooShort));
      return;
    }
    AuthAPI.changePassword(this.state.editOldPass, this.state.editNewPass, (res) => {
      this.setState({ editOldPass: "", editNewPass: "" });
      if (res.error === 0)
        toast(Language.getName(Names.Saved));
    });
  }

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, selectedShop, profile, shiftControlParameters }) => {
        if (language !== undefined)
          this.setState({ language });
        if (selectedShop !== undefined)
          this.setState({ profile: selectedShop });
        if (profile !== undefined)
          this.setState({ shopProfile: profile });
        if (shiftControlParameters !== undefined)
          this.setState({ shiftControlParameters: ContextSystem.shiftControlParameters.filter(scep => scep.shopID === ContextSystem.selectedShop?.id) })
      },
    );
    this.eventIDs.push(eid);

    this.setState({
      contracts: ContextSystem.contracts,
      profile: ContextSystem.selectedShop,
      shopProfile: ContextSystem.profile,
    });
  }

  handlePaymentToggle(paymentID, active) {
    ProfileAPI.changePaymentMethodActiveness(paymentID, active, (res: PMSChangeType) => {
      if (res.error !== 0)
        return;

      let newPms: PaymentMethodSetting = res.paymentMethodSetting;
      ContextSystem.changePms(newPms);
      toast(Language.getName(Names.Modified));
    });
  }

  changeCoverImage(PC: boolean) {
    EventSystem.publish(EventSystem.events.upload_image, {
      uploadType: PC ? ImageTypes.SHOP_COVER : ImageTypes.SHOP_COVER_MOBILE,
      cb: (image: Image) => {
        ContextSystem.updateShopCover(image);
      },
    });
  }

  changeBillLogoImage() {
    EventSystem.publish(EventSystem.events.upload_image, {
      uploadType: ImageTypes.SHOP_BILL_LOGO,
      cb: (image: Image) => {
        ContextSystem.updateShopBill(image);
      },
    });
  }

  changeVATValue(sm: ShippingMethod, value: string): void {
    let editVATValues: VATValue[] = [];

    this.state.editVATValues.forEach(ev => {
      if (ev.shippingMode !== sm || ![
        ShippingMethods.PICKUP, ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY,
      ].includes(sm))
        editVATValues.push({ ...ev });
    });
    editVATValues.push({
      id: -1,
      value: value,
      shippingMode: sm,
      date: new Date(),
      type: VATValueModelTypes.SHOP,
      enabled: true,
      modelID: ContextSystem.selectedShop?.id,
      shopID: ContextSystem.selectedShop?.id,
      paymentMode: PaymentMethods.ANY,
    });
    this.setState({ editVATValues });
  }

  saveVATValues(): void {
    let evv: VATValue[] = [];
    this.state.editVATValues.forEach(e => {
      if (![ShippingMethods.PICKUP, ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY].includes(e.shippingMode))
        return;
      let e2 = { ...e };
      e2.value = parseFloat(e2.value);
      evv.push(e2);
    });

    ProfileAPI.changeShopVATValues(evv, res => {
      if (res.error !== ErrorMessage.OK)
        return;
      toast(Language.getName(Names.Saved));
    });
  }

  addCOPParameters(): void {

  }

  render() {
    return (
      <Wrapper>
        <Section>
          <div>
            <h2>{Language.getName(Names.PaymentMethods)}</h2>
          </div>
          {AllPaymentMethod.map((pm, i) => {
            if (!pm.countries.includes(ContextSystem.selectedShop?.countryID))
              return <React.Fragment key={i} />;

            if (!this.state.profile || !this.state.profile.paymentMethods || this.state.profile.paymentMethods.length <= 0)
              return <React.Fragment key={i} />;

            let p: PaymentMethodSetting | undefined = this.state.profile.paymentMethods.find(
              pms => pms.paymentID === pm.type);

            return (
              <SubSection key={i} justify={"flex-start"}>
                <h2>{PaymentMethods.getName(pm.type)}</h2>
                <ToggleButton
                  inactiveLabel={<FaTimes />}
                  activeLabel={<FaCheck />}
                  value={p && p.active}
                  onToggle={value => this.handlePaymentToggle(pm.type, !value)}
                />
              </SubSection>
            );
          })}
          {!(this.state.profile && this.state.profile.paymentMethods) &&
            <SubSection>
              <h2>{Language.getName(Names.PaymentMethodIsNotSet)}</h2>
            </SubSection>
          }
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.CompanyDetailsText)}</h2>
          </div>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.Contracts)}</h2>
            <h2>
              <LinkButton
                onClick={e => this.handleContractsClicked(e)}>{Language.getName(Names.CheckMyContracts)}</LinkButton>
            </h2>
          </SubSection>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.CompanyNameAndVATNumber)}</h2>
            <h2>
              {this.state.contracts?.find(
                c => c.id === parseInt(this.state.profile?.contractID ?? 0))?.companyName ?? "Nincs megadva"}
              {"; "}
              {this.state.contracts?.find(
                c => c.id === parseInt(this.state.profile?.contractID ?? 0))?.companyVAT ?? "Adószám nincs megadva"}
            </h2>
          </SubSection>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.PayingCycle)}</h2>
            <h2>
              {
                this.state.profile && this.state.profile.invoiceCycle && this.state.profile.invoiceCycle > 0 ?
                Language.getName(
                  Names.PayingCycleValuePart1) + this.state.profile.invoiceCycle + " " + Language.getName(
                    Names.PayingCycleValuePart2)
                                                                                                             : Language.getName(
                                                                                                               Names.NotGiven)
              }
            </h2>
          </SubSection>
          <SubSection justify={"flex-start"}>
            <h2>{Language.getName(Names.PayoutCycle)}</h2>
            <h2>
              {
                this.state.profile && this.state.profile.payoutCycle && this.state.profile.payoutCycle > 0 ?
                Language.getName(Names.PayingCycleValuePart1) + this.state.profile.payoutCycle + " " + Language.getName(
                    Names.PayingCycleValuePart2)
                                                                                                           : Language.getName(
                                                                                                             Names.NotGiven)
              }
            </h2>
          </SubSection>
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.ChangePassword)}</h2>
          </div>
          <SubSection>
            <Input
              value={this.state.editOldPass}
              type="password"
              autoComplete={"old-password"}
              placeholder={Language.getName(Names.CurrentPassword)}
              onChange={(e) => this.setState({ editOldPass: e.target.value })}
              width={"49%"}
            />
            <Input
              value={this.state.editNewPass}
              type="password"
              autoComplete={"new-password"}
              placeholder={Language.getName(Names.ProfileNewPasswordPlaceholderText)}
              onChange={(e) => this.setState({ editNewPass: e.target.value })}
              width={"49%"}
            />
          </SubSection>
          <ButtonWrapper>
            <Button onClick={() => this.savePass()}>{Language.getName(Names.Save)}</Button>
          </ButtonWrapper>
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.ChangeProfileDetails)}</h2>
          </div>
          <SubSection>
            <Input
              autoComplete="off"
              value={this.state.editLastName}
              type="text"
              placeholder={Language.getName(Names.LastName) + "..."}
              onChange={(e) => this.setState({ editLastName: e.target.value })}
              width={"49%"}
            />
            <Input
              autoComplete="off"
              value={this.state.editFirstName}
              type="text"
              placeholder={Language.getName(Names.FirstName) + "..."}
              onChange={(e) => this.setState({ editFirstName: e.target.value })}
              width={"49%"}
            />
            <Input
              autoComplete="off"
              value={this.state.editPhoneNumber}
              type="text"
              placeholder={Language.getName(Names.PhoneNumber) + "..."}
              onChange={(e) => this.setState(
                { editPhoneNumber: PhoneNumber(e.target.value, "HU")?.formatInternational() ?? e.target.value })}
              width={"49%"}
            />
            <Input
              autoComplete="off"
              value={this.state.editPin}
              type="text"
              placeholder={Language.getName(Names.PinNumber)}
              onChange={(e) => this.setState({ editPin: e.target.value })}
              width={"49%"}
            />
          </SubSection>
          <ButtonWrapper>
            <Button onClick={() => this.saveProfileDetails()}>{Language.getName(Names.Save)}</Button>
          </ButtonWrapper>
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.PublicContactInfo)}</h2>
          </div>
          <p>{Language.getName(Names.PublicContactInfoDetails)}</p>
          <SubSection>
            <InputWrapper width={"48%"}>
              <Label
                color={EmailValidator(this.state.editPublicEmail ?? "") ? "green" : "initial"}
              >
                {Language.getName(Names.PublicEmail)}
              </Label>
              <Input
                value={this.state.editPublicEmail}
                type="email"
                autoComplete={"off"}
                placeholder={Language.getName(Names.PublicEmail)}
                onChange={(e) => this.setState({ editPublicEmail: e.target.value })}
                width={"100%"}
              />
            </InputWrapper>
            <InputWrapper width={"48%"}>
              <Label
                color={PhoneNumber(this.state.editPublicPhone ?? "", "HU")?.isValid() ? "green" : "initial"}
              >
                {Language.getName(Names.PublicPhone)}
              </Label>
              <Input
                value={this.state.editPublicPhone}
                type="email"
                autoComplete={"off"}
                placeholder={Language.getName(Names.PublicPhone)}
                onChange={(e) => this.setState(
                  { editPublicPhone: PhoneNumber(e.target.value, "HU")?.formatInternational() ?? e.target.value })}
                width={"100%"}
              />
            </InputWrapper>
          </SubSection>
          <ButtonWrapper>
            <Button onClick={() => this.savePublicInfo()}>{Language.getName(Names.Save)}</Button>
          </ButtonWrapper>
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.PrepareOrderSettings)}</h2>
          </div>
          <SubSection>
            <InputWrapper width={"48%"}>
              <Label
                color={isNaN(parseInt(this.state.editPreparedOrderMaxDays)) ? "initial" : ((parseInt(
                  this.state.editPreparedOrderMaxDays) >= 0 && parseInt(this.state.editPreparedOrderMaxDays) <= 7)
                                                                                           ? "green"
                                                                                           : "red")}
              >
                {Language.getName(Names.PreparedOrderMaxDaysLabel)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={isNaN(parseInt(this.state.editPreparedOrderMaxDays)) ? true : parseInt(
                  this.state.editPreparedOrderMaxDays) > 0}
                onToggle={value => this.setState({ editPreparedOrderMaxDays: !value ? 7 : 0 })}
              />
              {(isNaN(parseInt(this.state.editPreparedOrderMaxDays)) || parseInt(
                  this.state.editPreparedOrderMaxDays) > 0) &&
                <Input
                  value={this.state.editPreparedOrderMaxDays}
                  type="text"
                  autoComplete={"off"}
                  placeholder={Language.getName(Names.PreparedOrderMaxDaysExample)}
                  onChange={(e) => this.setState({ editPreparedOrderMaxDays: e.target.value })}
                  width={"80%"}
                />
              }
            </InputWrapper>
            <InputWrapper width={"48%"}>
              <Label
                color={isNaN(parseInt(this.state.editShowPreparedOrdersBeforeMinutes)) ? "initial" : ((parseInt(
                  this.state.editShowPreparedOrdersBeforeMinutes) >= 5 && parseInt(
                  this.state.editShowPreparedOrdersBeforeMinutes) <= 120) ? "green" : "red")}
              >
                {Language.getName(Names.ShowPreparedOrdersBeforeMinutesLabel)}
              </Label>
              <Label color={"initial"}>
                {Language.getName(Names.ShowPreparedOrdersBeforeMinutesLabel2)}
              </Label>
              {(isNaN(parseInt(this.state.editShowPreparedOrdersBeforeMinutes)) || parseInt(
                  this.state.editShowPreparedOrdersBeforeMinutes) > 0) &&
                <Input
                  value={this.state.editShowPreparedOrdersBeforeMinutes}
                  type="text"
                  autoComplete={"off"}
                  placeholder={Language.getName(Names.ShowPreparedOrdersBeforeMinutesExample)}
                  onChange={(e) => this.setState({ editShowPreparedOrdersBeforeMinutes: e.target.value })}
                  width={"80%"}
                />
              }
            </InputWrapper>
            <InputWrapper width={"98%"}>
              <Label>
                {Language.getName(Names.PreparedOrderOnlyOnlinePaymentLabel)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.editPreparedOrderOnlyOnlinePayment}
                onToggle={value => this.setState({ editPreparedOrderOnlyOnlinePayment: !value })}
              />
            </InputWrapper>
          </SubSection>
          <ButtonWrapper>
            <Button onClick={() => this.savePreorderInfo()}>{Language.getName(Names.Save)}</Button>
          </ButtonWrapper>
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.OrderProcessingSettings)}</h2>
          </div>
          <SubSection>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.PayLaterAvailableText)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.payLaterAvailable}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.PayLaterAvailable, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.posStandShowLocalOrders)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.posStandShowLocalOrders}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.PosStandShowLocalOrders, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.PosWaiterShowNonLocalOrdersText)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.posWaiterShowNonLocalOrders}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.PosWaiterShowNonLocalOrders, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.autoPrintOrders)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.autoPrintOrders}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.AutoPrintOrders, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.kitchenReportAvailable)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.kitchenReportAvailable}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.KitchenReportAvailable, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.serverReportAvailable)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.serverReportAvailable}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.ServerReportAvailable, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.cashflowReportAvailable)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.cashflowReportAvailable}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.CashflowReportAvailable, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.showCashflowReportAtOrder)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.showCashflowReportAtOrder}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.ShowCashflowReportAtOrder, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.hideNotAvailableProducts)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.hideNotAvailableProducts}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.HideNotAvailableProducts, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"49%"}>
              <Label>
                {Language.getName(Names.showOrderTimerForGuests)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.showOrderTimerForGuests}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.ShowOrderTimerForGuests, !value)}
              />
            </InputWrapper>
            <InputWrapper width={"99%"}>
              <Label>
                {Language.getName(Names.automaticallyAcceptOnlineOrders)}
              </Label>
              <ToggleButton
                inactiveLabel={<FaTimes />}
                activeLabel={<FaCheck />}
                value={this.state.profile.automaticallyAcceptOnlineOrders}
                onToggle={value => this.toggleRestaurantSetting(RestaurantSettings.AutomaticallyAcceptOnlineOrders, !value)}
              />
            </InputWrapper>
          </SubSection>
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.ChangeCoverImages)}</h2>
          </div>
          <SubSection>
            <h2>{Language.getName(Names.CoverImagePC)}</h2>
            <CoverImage>
              <CoverImageWrapper>
                {this.state.profile.coverImagePC &&
                  <img src={Config.imageUrlBase + "partner/" + this.state.profile.coverImagePC.fileName}
                       alt={this.state.profile?.coverImagePC?.alt ?? ""} />
                }
                {!this.state.profile.coverImagePC &&
                  <p>
                    {Language.getName(Names.NoImageUploadedErrorText)}
                  </p>
                }
              </CoverImageWrapper>
              <CoverImageButton>
                <Button onClick={() => this.changeCoverImage(true)}>{Language.getName(Names.Modify)}</Button>
              </CoverImageButton>
            </CoverImage>
          </SubSection>
          <SubSection>
            <h2>{Language.getName(Names.CoverImageMobile)}</h2>
            <CoverImage>
              <CoverImageWrapper>
                {this.state.profile.coverImageMobile &&
                  <img src={Config.imageUrlBase + "partner/" + this.state.profile.coverImageMobile.fileName}
                       alt={this.state.profile?.coverImageMobile?.alt ?? ""} />
                }
                {!this.state.profile.coverImageMobile &&
                  <p>
                    {Language.getName(Names.NoImageUploadedErrorText)}
                  </p>
                }
              </CoverImageWrapper>
              <CoverImageButton>
                <Button onClick={() => this.changeCoverImage(false)}>{Language.getName(Names.Modify)}</Button>
              </CoverImageButton>
            </CoverImage>
          </SubSection>
          <SubSection>
            <h2>{Language.getName(Names.BillLogo)}</h2>
            {(() => {
              let images: Image[] = this.state.profile.images.filter(i => i.type === ImageTypes.SHOP_BILL_LOGO)
                .sort((i1, i2) => i2.id - i1.id);
              let im = images.length > 0 ? images[0] : undefined;
              return (
                <CoverImage>
                  <CoverImageWrapper>
                    {im &&
                      <img src={Config.imageUrlBase + "partner/" + im?.fileName}
                           alt={im?.alt ?? ""} />
                    }
                    {!im &&
                      <p>
                        {Language.getName(Names.NoImageUploadedErrorText)}
                      </p>
                    }
                  </CoverImageWrapper>
                  <CoverImageButton>
                    <Button onClick={() => this.changeBillLogoImage()}>{Language.getName(Names.Modify)}</Button>
                  </CoverImageButton>
                </CoverImage>
              );
            })()}
          </SubSection>
        </Section>
        <Section>
          <h2>{Language.getName(Names.VATSettings)}</h2>
          <SubSection justify={"flex-start"}>
            {Object.keys(ShippingMethods).map((shName, i) => {
              let sh: number = parseInt(ShippingMethods[shName]);
              if ([ShippingMethods.VENDOR_DELIVERY, ShippingMethods.ANY].includes(sh))
                return <React.Fragment key={i} />;
              let label: string = Orders.getShippingMethodVATString(sh);
              return (
                <InputWrapper margin={"0 6px"} key={i} width={"48%"}>
                  <Label>{label}</Label>
                  <Input
                    value={this.state.editVATValues.find(v => v.shippingMode === sh)?.value}
                    type="number"
                    autoComplete={"off"}
                    placeholder={label}
                    onChange={(e) => this.changeVATValue(sh, ProductEditor.parsePrice(e.target.value))}
                    width={"100%"}
                  />
                </InputWrapper>
              );
            })}
          </SubSection>
          <ButtonWrapper>
            <Button onClick={() => this.saveVATValues()}>{Language.getName(Names.Save)}</Button>
          </ButtonWrapper>
        </Section>
        <Section>
          <h2>{Language.getName(Names.COPs)}</h2>
          <SubSection justify={"flex-start"}>
            {this.state.shiftControlParameters.map((scep, i)=>{
              return (
                <div key={i}>
                  {TranslatableString.get(scep.name)}<br/>
                </div>
              );
            })}
          </SubSection>
        </Section>
      </Wrapper>
    );
  }

  handleContractsClicked(e: MouseEvent) {
    e.preventDefault();
    EventSystem.publish(EventSystem.events.open_contracts_details);
  }
}
