import AbstractModel from "./AbstractModel";
import type { Stackable } from "./Product";
import Language, { Names } from "../utils/Language";
import type { MoveQty } from "../components/modals/MoveStorageModal";

export class Qty extends AbstractModel {
  modelID: number;
  qty: number;
  profileID: number;
  //supplyPrice: number;
  price: number;
  type: QtyType;
  addedDate: Date;
  reduction: QtyChange[];
  storageID: number;
  finished: boolean;

  static getSum(qtyList: Qty[]): number {
    let qtySum: number = 0;
    for (let qty of qtyList) {
      qtySum += qty.qty;
      if (qty.reduction)
        for (let qtyChange of qty.reduction) {
          qtySum += qtyChange.changeQty;
        }
    }
    return qtySum;
  }

  static countSum(qtyList: Qty[], modelType: QtyType, modelID: number, storageID: number = 0) {
    let qtySum: number = 0;
    for (let qty: Qty of qtyList.filter(q => q.modelID === modelID && q.type === modelType)) {
      if (storageID !== 0 && qty.storageID !== storageID)
        continue;
      qtySum += qty.qty;
      if (qty.reduction)
        for (let qtyChange of qty.reduction) {
          qtySum += qtyChange.changeQty;
        }
    }

    return qtySum;
  }
}

export class Storage extends AbstractModel {
  name: string;
  shopIDs: number[];
  type: StorageType;
  addedDate: Date;

  static getTypeName(t: StorageType): string {
    if (t === StorageTypes.COUNTER)
      return Language.getName(Names.Counter);
    else if (t === StorageTypes.WAREHOUSE)
      return Language.getName(Names.Warehouse);
    return "";
  }
}

export class SavedStorageMovement extends AbstractModel {
  name: string;
  note: string;
  shopID: number;
  fromID: number;
  toID: number;
  assignedShopProfileID: number;
  addedDate: Date;
  moveQtyList: MoveQty[];
}

export class SavedProductionList extends AbstractModel {
  name: string;
  note: string;
  shopID: number;
  assignedShopProfileID: number;
  addedDate: Date;
  qtyList: Qty[];
}

export class QtyChange extends AbstractModel {
  profileID: number;
  modelID: number;
  qtyID: number;
  changeGroupID: number;
  changeQty: number;
  date: Date;
  type: string;
  changeType: QtyChangeType;

  /**
   * not persistent
   * Only used to indicate that the change is needed to be done from which storage
   */
  _storageID: number;
}

export const QtyTypes = {
  RAW: 0,
  PRODUCT: 1,
};

export const QtyChangeTypes = {
  SELL: 0,
  WASTE: 1,
  LOSS: 2,
  INVENTORY_DIFFERENCE: 3,
  REVERSE_TRANSACTION: 4,
  MOVE_BETWEEN_STORAGE: 5,
  MANUFACTURE: 6,
};

export function GetQtyChangeTypeName(t: QtyChangeType): string {
  if (t === QtyChangeTypes.SELL)
    return Language.getName(Names.SELL);
  else if (t === QtyChangeTypes.WASTE)
    return Language.getName(Names.WASTE);
  else if (t === QtyChangeTypes.LOSS)
    return Language.getName(Names.LOSS);
  else if (t === QtyChangeTypes.INVENTORY_DIFFERENCE)
    return Language.getName(Names.INVENTORY_DIFFERENCE);
  else if (t === QtyChangeTypes.REVERSE_TRANSACTION)
    return Language.getName(Names.REVERSE_TRANSACTION);
  else if (t === QtyChangeTypes.MOVE_BETWEEN_STORAGE)
    return Language.getName(Names.MOVE_BETWEEN_STORAGE);
  else if (t === QtyChangeTypes.MANUFACTURE)
    return Language.getName(Names.MANUFACTURE);
}

export function GetQtyTypeName(stackable: Stackable): string {
  let t = stackable.qtyType;
  if (t === QtyTypes.RAW && stackable.semiProduct === true)
    return Language.getName(Names.Ingredient);
  else if (t === QtyTypes.RAW && stackable.semiProduct === false)
    return Language.getName(Names.ProcessedProduct);
  else if (t === QtyTypes.PRODUCT)
    return Language.getName(Names.Product);
}

export const StorageTypes = {
  WAREHOUSE: 0,
  COUNTER: 1,
};

export type QtyType = $Values<typeof QtyTypes>;

export type QtyChangeType = $Values<typeof QtyChangeTypes>;

export type StorageType = $Values<typeof StorageTypes>;
