import APICaller, { APIMsgBase } from "../APICaller";
import { Printer, PrinterSetting } from "../../model/Printer";

export type GetPrintersType = APIMsgBase & { printers: Printer[] };

function getPrinters(cb: (res: GetPrintersType)=>{}) {
  APICaller.ownFetch(true, "POST", "/printers", {}, (res) => {
    let result: GetPrintersType = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

function updatePrinterSetting(printerSetting: PrinterSetting, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "PUT", "/printerSetting", { printerSetting }, (res) => {
    let result: APIMsgBase = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

function deletePrinterSetting(modelID: number, modelType: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/printerSetting", { modelID, modelType }, (res) => {
    let result: APIMsgBase = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

export const PrintersAPI = {
  getPrinters,
  updatePrinterSetting,
  deletePrinterSetting,
};
