import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5%;
`;

export default class FloorPlanner extends Component {
  state: {
    observed: any
  } = {
    observed: null,
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
  }

  diagramRef;

  initDiagram() {
    // const $ = go.GraphObject.make;
    //
    // const diagram = $(go.Diagram,
    //   {
    //     'undoManager.isEnabled': true,  // must be set to allow for model change listening
    //     // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
    //     model: $(go.GraphLinksModel, {
    //       linkKeyProperty: 'key'  // this should always be set when using a GraphLinksModel
    //     })
    //   });
    //
    // diagram.nodeTemplate =
    //   $(go.Node, 'Auto',  // the Shape will go around the TextBlock
    //     $(go.Shape, 'RoundedRectangle', { strokeWidth: 0, fill: 'white' },
    //       // Shape.fill is bound to Node.data.color
    //       new go.Binding('fill', 'color')),
    //     $(go.TextBlock,
    //       { margin: 8 },  // some room around the text
    //       // TextBlock.text is bound to Node.data.key
    //       new go.Binding('text', 'key'))
    //   );
    //
    // return diagram;
  }

  render() {
    return (
      <Wrapper>
        <p>Floor Map</p>
        {/*<ReactDiagram*/}
        {/*  ref={this.diagramRef}*/}
        {/*  divClassName='diagram-component'*/}
        {/*  // initDiagram={this.initDiagram}*/}
        {/*  // nodeDataArray={this.props.nodeDataArray}*/}
        {/*  // linkDataArray={this.props.linkDataArray}*/}
        {/*  // modelData={this.props.modelData}*/}
        {/*  // onModelChange={this.props.onModelChange}*/}
        {/*  // skipsDiagramUpdate={this.props.skipsDiagramUpdate}*/}
        {/*/>*/}

        {/*<ReactPalette*/}
        {/*  initPalette={this.initPalette}*/}
        {/*  divClassName='palette-component'*/}
        {/*  nodeDataArray={[{ key: 0, text: 'Alpha' }]}*/}
        {/*/>*/}

        {/*<ReactOverview*/}
        {/*  initOverview={this.initOverview}*/}
        {/*  divClassName='overview-component'*/}
        {/*  observedDiagram={this.state.observed}*/}
        {/*/>*/}
      </Wrapper>
    );
  }
}
