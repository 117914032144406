import React, { Component } from "react";
import Language, { Names } from "../../../utils/Language";
import { PaymentMethods } from "../../../model/PaymentMethodSetting";
import { FaTrash } from "react-icons/all";
import styled, { css } from "styled-components";
import { Order, OrderPayment, OrderProduct } from "../../../model/Order";
import ContextSystem from "../../../utils/ContextSystem";
import { ShopProfile } from "../../../model/ShopProfile";
import { TranslatableString } from "../../../model/Product";

const PayAllSummary = styled.div`
  width: 30%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SummaryItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-items: stretch;
  margin: 3px;
  padding: 0 3px;
`;

const OrderProductName = styled.div`
  font-size: 8pt;
`;

const SummaryItemText = styled.div`
  width: calc(100% - 35px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 6px;
  font-weight: bold;
  font-size: 10pt;

  & > p {
    margin: 2px 0;
  }

  ${({ header }) => header === true && css`
    font-size: 13pt;
    margin-bottom: 12px;
    border-bottom: 1px solid #efefef;
  `}

  ${({ with_border }) => with_border === true && css`
    border-bottom: 1px solid #efefef;
  `}
`;

const SummaryItemRemove = styled.div`
  width: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type PaymentSummaryProps = {
  toPay: number,
  total: number,
  availablePaymentMethods: { type: number, countries: number[], availableShippingMethods: number[] }[][],
  existingPayments: OrderPayment[],
  newPayments: OrderPayment[],
  deletePayments: OrderPayment[],
  onNewPaymentsChanged: (newPayments: OrderPayment[])=>{},
  onDeletePaymentsChanged: (deletePayments: OrderPayment[])=>{},
  orders: Order[],
  inSplit: boolean
};

type PaymentSummaryState = {
  total: number,
  toPay: number,
  originalPaid: number,
  availablePaymentMethods: { type: number, countries: number[], availableShippingMethods: number[] }[][],
  existingPayments: OrderPayment[],
  deletePayments: OrderPayment[],
  newPayments: OrderPayment[],
  orders: Order[],
  inSplit: boolean
};

export default class PaymentSummaryComponent extends Component<PaymentSummaryProps> {

  state: PaymentSummaryState = {
    total: 0,
    toPay: 0,
    originalPaid: 0,
    availablePaymentMethods: [],
    existingPayments: [],
    newPayments: [],
    inSplit: false
  };

  loadProps() {
    if (this.state.total !== this.props.total)
      this.setState({ total: this.props.total });
    if (this.state.toPay !== this.props.toPay)
      this.setState({ toPay: this.props.toPay });
    if (this.state.availablePaymentMethods !== this.props.availablePaymentMethods)
      this.setState({ availablePaymentMethods: this.props.availablePaymentMethods });
    if (this.state.newPayments !== this.props.newPayments)
      this.setState({ newPayments: this.props.newPayments });
    if (this.state.existingPayments !== this.props.existingPayments)
      this.setState({ existingPayments: this.props.existingPayments });
    if (this.state.deletePayments !== this.props.deletePayments)
      this.setState({ deletePayments: this.props.deletePayments });
    if (this.state.orders !== this.props.orders)
      this.setState({ orders: this.props.orders });
    if (this.state.inSplit !== this.props.inSplit)
      this.setState({ inSplit: this.props.inSplit });
  }

  componentDidMount() {
    this.loadProps();
  }

  componentDidUpdate(prevProps: Readonly<PaymentSummaryProps>, prevState: Readonly<PaymentSummaryState>, snapshot) {
    this.loadProps();
  }

  static changeNewPayment(newPayments: OrderPayment[], paymentType: number, value: number): OrderPayment[] {
    let ops: OrderPayment[] = [];

    let found: boolean = false;
    newPayments.forEach(op => {
      let opCopy: OrderPayment = { ...op };
      if (opCopy.paymentType === paymentType) {
        found = true;
        opCopy.amount = value;
      }
      if (opCopy.amount - opCopy.creditedAmount !== 0)
        ops.push(opCopy);
    });
    if (!found)
      ops.push({
          id: -1,
          enabled: true,
          paymentType,
          amount: value,
          creditedAmount: 0,
          dateTime: new Date(),
          orderContactID: -1,
          orderID: -1,
          payed: true,
          productPayments: [],
          shopID: ContextSystem.selectedShop?.id ?? -1,
          txid: -1,
        },
      );

    return ops;
  }

  removeExistingPayment(op: OrderPayment) {
    if (op.id <= 0)
      return;

    let deletePayments: OrderPayment[] = this.state.deletePayments;
    deletePayments.push(op);

    this.setState({ deletePayments });
    if (this.props.onDeletePaymentsChanged)
      this.props.onDeletePaymentsChanged(this.state.deletePayments);
  }

  removePayment(paymentType: number, value: string) {
    let newPayments: OrderPayment[] = PaymentSummaryComponent.changeNewPayment(this.state.newPayments, paymentType, value);
    this.setState({ newPayments });
    if (this.props.onNewPaymentsChanged)
      this.props.onNewPaymentsChanged(newPayments);
  }

  render() {
    let paidSum: number = OrderPayment.getPaidSum(this.state.newPayments);

    if(!this.state.inSplit){
      paidSum -= OrderPayment.getPaidSum(this.state.deletePayments);
      paidSum += OrderPayment.getPaidSum(this.state.existingPayments);
    }

    let remaining: number = this.state.toPay - paidSum;

    return (
      <PayAllSummary>
        <SummaryItem>
          <SummaryItemText header={true}>
            <p>{Language.getName(Names.Total)}:</p>
            <p>{Math.round(this.state.total).toLocaleString()} Ft</p>
          </SummaryItemText>
          <SummaryItemRemove />
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText>
            <p>{Language.getName(Names.Amount)}:</p>
            <p>{Math.round(this.state.toPay).toLocaleString()} Ft</p>
          </SummaryItemText>
          <SummaryItemRemove />
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText>
            <p>{Language.getName(Names.Remaining)}:</p>
            <p>{Math.max(0, Math.round(remaining)).toLocaleString()} Ft</p>
          </SummaryItemText>
          <SummaryItemRemove />
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText with_border={true}>
            <p>{Language.getName(Names.Tip)}:</p>
            <p>{Math.max(0, -Math.round(remaining)).toLocaleString()} Ft</p>
          </SummaryItemText>
          <SummaryItemRemove />
        </SummaryItem>
        {this.state.originalPaid > 0 &&
          <SummaryItem>
            <SummaryItemText>
              <p>{Language.getName(Names.Paid)}:</p>
              <p>{Math.round(this.state.total).toLocaleString()} Ft</p>
            </SummaryItemText>
            <SummaryItemRemove />
          </SummaryItem>
        }
        {this.state.availablePaymentMethods?.map((l, i) => l.map((p, j) => {
          let sum: number = OrderPayment.getPaidSum(this.state.newPayments, p.type);
          if (sum === 0)
            return <React.Fragment key={i * j + j} />;

          return (
            <SummaryItem key={i * j + j}>
              <SummaryItemText>
                <p>{PaymentMethods.getName(p.type)}</p>
                <p>{Math.round(sum)} Ft</p>
              </SummaryItemText>
              <SummaryItemRemove onClick={() => this.removePayment(p.type, 0)}>
                <FaTrash />
              </SummaryItemRemove>
            </SummaryItem>
          );
        }))}

        {this.state.existingPayments.map((op, i) => {
          if (this.state.deletePayments.find(dp => dp.id === op.id) !== undefined)
            return <React.Fragment key={i} />;
          if (!op.payed || (op.amount - op.creditedAmount) === 0)
            return <React.Fragment key={i} />;

          let sp: ShopProfile = ContextSystem.selectedShop.employees.find(e => e.id === op.orderContactID);

          return (
            <SummaryItem key={i}>
              <SummaryItemText>
                <p>
                  {PaymentMethods.getName(op.paymentType)}{!!sp ? (" (" + sp.firstName + " " + sp.lastName + ")") : ""}
                  {op.productPayments &&
                    <>
                      <br />
                      {op.productPayments.map((opp, index) => {
                        let orderProduct: OrderProduct;
                        this.state.orders.forEach(o => {
                          if (o && o.productList)
                            o.productList.forEach(op => {
                              if (op.id === opp.orderProductID)
                                orderProduct = op;
                            });
                        });
                        if (!orderProduct)
                          return <React.Fragment key={index} />;
                        return (
                          <OrderProductName key={index}>{TranslatableString.get(orderProduct.name)}</OrderProductName>
                        );
                      })}
                    </>
                  }
                </p>
                <p>{Math.round(op.amount - op.creditedAmount)} Ft</p>
              </SummaryItemText>
              <SummaryItemRemove onClick={() => this.removeExistingPayment(op)}>
                <FaTrash />
              </SummaryItemRemove>
            </SummaryItem>
          );
        })}
      </PayAllSummary>
    );
  }
}
