import React, { Component, ReactNode } from "react";
import styled, { css, keyframes } from "styled-components";
import Language, { Names } from "../../utils/Language";
import { Order, OrderState, TableReservation, TableReservationStatuses } from "../../model/Order";
import { BiTimer, IoMdPeople } from "react-icons/all";
import { addLeadingZero } from "../../utils/HoursCalc";
import { CenteredRow, ReservationCover } from "../home/layout/WaiterLayout";
import { Element, ElementTypes, Zone } from "../../model/BluePrint";
import { pulseBgAnimation, TabBar, TabItem } from "../home/Orders";
import { Profile } from "../../model/Profile";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import { ButtonTag } from "../home/StandingComponent";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TablesWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 2;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 12px;
  align-content: flex-start;
`;

const TableTotalPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 9.5pt;
`;

const TableDiv = styled.div`
  user-select: none;
  position: relative;

  width: 100px;
  height: fit-content;
  min-height: 80px;
  margin: 12px 6px;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 black;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  cursor: pointer;

  transition: box-shadow 150ms ease-in-out;

  color: #444440;
  font-size: 10pt;
  font-family: Arial, sans-serif;

  &:hover {
    box-shadow: 0 0 7px 0 black;
  }

  &:active {
    box-shadow: 0 0 2px 0 black;
  }

  & > .hideOverlay {
    position: absolute;
    top: -6px; //padding
    left: -6px; //padding
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-radius: 4px;
    transition: opacity 200ms ease-in-out, background-color 200ms ease-in-out;
    background-color: transparent;
    pointer-events: none;
  }

  ${({ show }) => show === false && css`
    & > .hideOverlay {
      pointer-events: initial;
      background-color: rgba(158, 158, 158, 0.64);
    }
  `};

  ${({ selected }) => selected === true && css`
    border: 1px solid purple;
  `};

  @media screen and (max-width: 800px) {
    width: 120px;
    height: fit-content;
    min-height: 80px;
    margin: 3px;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-family: monospace;
  font-size: 11pt;

  top: 0;
  left: 0;
`;

const TableName = styled.div`
  width: fit-content;
  padding: 2px 9px;
  border-radius: 3px;
  font-size: 9pt;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #2b2b2b;
  color: #efefef;
`;

const EmptyTable = styled.div`
  width: 100%;
  text-align: center;
`;

const TableContent = styled.div`
  width: 100%;
  flex-grow: 2;
  padding-top: 10px;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > p {
    padding: 0;
    margin: 0;
    font-size: 10pt;
    color: #717171;
  }
`;

const BigLabelWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > p {
    padding: 0;
    margin: 0;
    font-size: 14pt;
  }
`;

const pulseColorAnimation = (color1, color2) => keyframes`
  from {
    color: ${color1 ?? "black"};
  }
  to {
    color: ${color2 ?? "white"};
  }
`;

const Pulse = styled.div`
  margin: 0;
  animation: ${({ color }) => pulseColorAnimation(color, "#fff")} 1s ease-in-out infinite alternate;
`;

const UnpaidPulse = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 3px 0 0;

  border-radius: 300px;
  background-color: #e30808;

  animation: ${pulseBgAnimation} 1s ease-in-out infinite alternate;
`;

type P = {
  onZoneSelected: (zoneID: number)=>{},
  onTableSelected: (table: Element)=>{},
  filterTable: (table: Element)=>boolean,
  filterTableReservation: (tableReservation: TableReservation)=>boolean | undefined,
  selectedTable: Element,
  emptyNote: ReactNode,
  useFixDate: Date,
  parent: string
}

type S = {
  tables: Element[],
  orders: Order[],
  profiles: Profile[],
  tableReservations: TableReservation[],
  onZoneSelected: (zoneID: number)=>{},
  onTableSelected: (table: Element)=>{},
  filterTable: (table: Element)=>boolean,
  filterTableReservation: (tableReservation: TableReservation)=>boolean | undefined,
  selectedTable: Element,
  dateTime: Date,
  useFixDate: Date,
  emptyNote: ReactNode,
  zones: Zone[],
  selectedZoneID: number,
  parent: string
}

export default class TablesLayout extends Component<P, S> {
  state: S = {
    onTableSelected: undefined,
    onZoneSelected: undefined,
    filterTable: undefined,
    filterTableReservation: undefined,
    tables: [],
    zones: [],
    orders: [],
    profiles: [],
    tableReservations: [],
    selectedTable: undefined,
    dateTime: new Date(),
    emptyNote: undefined,
    useFixDate: undefined,
    selectedZoneID: -2,
    parent: "",
  };

  timeout;

  reloadDateTime() {
    this.timeout = setTimeout(() => {
      this.setState({ dateTime: new Date() });
      this.reloadDateTime();
    }, 1000);
  }

  componentWillUnmount() {
    this.eventIDs.forEach(e => EventSystem.unsubscribe(e));
    if (this.timeout)
      clearTimeout(this.timeout);
  }

  loadFromContext() {
    this.setState({
      tables: ContextSystem.elements.filter(
        e => e.type === ElementTypes.TABLE && e.partnerID === ContextSystem.selectedShop?.id && e.enabled)
        .sort((e1, e2) => e1.name.localeCompare(e2.name)),
      orders: ContextSystem.orders.filter(
        o => o.partnerID === ContextSystem.selectedShop?.id && o.tableReservationID > 0),
      language: ContextSystem.language,
      tableReservations: ContextSystem.tableReservations.filter(
        t => t.partnerID === ContextSystem.selectedShop?.id && t.enabled),
      zones: ContextSystem.zones.filter(z => z.partnerID === ContextSystem.selectedShop?.id && z.enabled),
      profiles: ContextSystem.profiles,
    });
  }

  componentDidMount() {
    this.reloadDateTime();
    this.eventIDs = [];
    this.loadFromContext();
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, elements, orders, tableReservations, zones }) => {
        if (
          orders !== undefined
          || language !== undefined || elements !== undefined
          || tableReservations !== undefined
          || zones !== undefined
        )
          this.loadFromContext();
      },
    );

    this.eventIDs.push(eid);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>) {
    if (this.state.filterTable !== this.props.filterTable)
      this.setState({ filterTable: this.props.filterTable });
    if (this.state.filterTableReservation !== this.props.filterTableReservation)
      this.setState({ filterTableReservation: this.props.filterTableReservation });
    if (this.state.onTableSelected !== this.props.onTableSelected)
      this.setState({ onTableSelected: this.props.onTableSelected });
    if (this.state.onZoneSelected !== this.props.onZoneSelected)
      this.setState({ onZoneSelected: this.props.onZoneSelected });
    if (this.state.selectedTable !== this.props.selectedTable)
      this.setState({ selectedTable: this.props.selectedTable });
    if (this.state.emptyNote !== this.props.emptyNote)
      this.setState({ emptyNote: this.props.emptyNote });
    if (this.state.useFixDate !== this.props.useFixDate)
      this.setState({ useFixDate: this.props.useFixDate });
    if (this.state.parent !== this.props.parent)
      this.setState({ parent: this.props.parent });
  }

  changeSelectedZoneID(zoneID: number) {
    this.setState({ selectedZoneID: zoneID });
    if (this.state.onZoneSelected)
      this.state.onZoneSelected(zoneID);
  }

  render() {
    let date: Date = this.state.dateTime;
    if (this.state.useFixDate)
      date = this.state.useFixDate;

    let hasEmptyZoneTable: boolean = this.state.tables.find(t => t.zoneID <= 0) !== undefined;

    return (
      <Wrapper>
        <TabBar>
          <TabItem
            key={-1}
            selected={this.state.selectedZoneID === -2}
            onClick={() => this.changeSelectedZoneID(-2)}
          >
            {Language.getName(Names.AllZones)}
          </TabItem>
          {this.state.zones && this.state.zones.map((c, i) => {
            return (
              <TabItem
                key={i}
                selected={this.state.selectedZoneID === c.id}
                onClick={() => this.changeSelectedZoneID(c.id)}
              >
                {c.name}
              </TabItem>
            );
          })}
          {hasEmptyZoneTable &&
            <TabItem
              key={-2}
              selected={this.state.selectedZoneID === -1}
              onClick={() => this.changeSelectedZoneID(-1)}
            >
              {Language.getName(Names.WithoutZone)}
            </TabItem>
          }
        </TabBar>
        <TablesWrapper onClick={() => this.state.onTableSelected && this.state.onTableSelected(undefined)}>
          {(!this.state.tables || this.state.tables <= 0) &&
            <BigLabelWrapper>
              {this.state.emptyNote && this.state.emptyNote}
            </BigLabelWrapper>
          }
          {this.state.tables?.map((t, i) => {
            let show = !this.state.filterTable || this.state.filterTable(t);

            let tableReservationsFiltered = this.state.tableReservations;
            if (this.state.filterTableReservation)
              tableReservationsFiltered = tableReservationsFiltered.filter(tr => this.state.filterTableReservation(tr));

            let statuses = undefined;
            if (this.state.parent === "reservationEditor")
              statuses = [
                TableReservationStatuses.NEW,
                TableReservationStatuses.ACCEPTED,
                TableReservationStatuses.SEATED,
                TableReservationStatuses.LEAVING,
              ];

            let tableReservation: TableReservation = ContextSystem.getTableReservation(t.id, tableReservationsFiltered, statuses);
            let orders: Order[] = tableReservation ? this.state.orders.filter(o => o.tableReservationID === tableReservation.id) : [];

            let totalPrice: number = 0;
            let paid: boolean = true;

            for (let order of orders) {
              if ([OrderState.DELETED, OrderState.DECLINED].includes(order.lastState.status))
                continue;

              totalPrice += Order.totalOrderPrice(order);
              if (!Order.isPaidFully(order))
                paid = false;
            }

            let startDate: Date = TableReservation.getStart(tableReservation);
            let endDate: Date = TableReservation.getEnd(tableReservation);

            let endReservationSignThreshold: Date = new Date(date).addMinutes(15);
            let pulseAlmostEndTime: boolean = endDate
              && endReservationSignThreshold > endDate;

            let pulseUnpaid: boolean = tableReservation && (
                tableReservation.status === TableReservationStatuses.DONE
                || tableReservation.status === TableReservationStatuses.FORCE_DONE
                || tableReservation.status === TableReservationStatuses.CANCELLED
              )
              && paid === false
              && pulseAlmostEndTime === true;

            let pulseSeatingTimeLate: boolean = startDate && tableReservation &&
              startDate > new Date(date).addMinutes(-15)
              && (
                tableReservation.status === TableReservationStatuses.NEW
                || tableReservation.status === TableReservationStatuses.ACCEPTED
              );

            let notConfirmedOrders: number =
              !tableReservation ? 0 : this.state.orders.filter(
                o => o.enabled
                  && o.tableReservationID === tableReservation?.id
                  && o.lastState.status === OrderState.NEW,
              ).length;
            let readyOrders: number =
              !tableReservation ? 0 : this.state.orders.filter(
                o => o.enabled
                  && o.tableReservationID === tableReservation?.id
                  && o.lastState.status === OrderState.READY,
              ).length;
            let bookingsToTable: number = this.state.tableReservations.filter(tr =>
              tr.tableID === t.id
              && tr.enabled === true
              && tr.status === TableReservationStatuses.ACCEPTED
              && new Date() < TableReservation.getStart(tr)
              // same day
              && new Date().toLocaleDateString() === TableReservation.getStart(tr).toLocaleDateString(),
            ).length;

            if (this.state.selectedZoneID > -2 && t.zoneID !== this.state.selectedZoneID)
              return <React.Fragment key={i} />;

            let profile: Profile = undefined;
            if (tableReservation && tableReservation.profileID > 0)
              profile = ContextSystem.profiles.find(p => p.id === tableReservation.profileID);

            return (
              <TableDiv selected={this.state.selectedTable?.id === t.id}
                        show={show}
                        key={i}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.state.onTableSelected && this.state.onTableSelected(t);
                        }}
              >
                {this.state.parent !== "reservationEditor" &&
                  <>
                    {notConfirmedOrders > 0 &&
                      <ButtonTag
                        width={"18px"}
                        height={"18px"}
                        top={"-7px"}
                        left={"-7px"}
                      >
                        {notConfirmedOrders}
                      </ButtonTag>
                    }
                    {readyOrders > 0 &&
                      <ButtonTag
                        width={"18px"}
                        height={"18px"}
                        top={"-7px"}
                        right={"-7px"}
                      >
                        {readyOrders}
                      </ButtonTag>
                    }
                    {bookingsToTable > 0 &&
                      <ButtonTag
                        width={"18px"}
                        height={"18px"}
                        bottom={"-7px"}
                        right={"-7px"}
                      >
                        {bookingsToTable}
                      </ButtonTag>
                    }
                  </>
                }
                <div className={"hideOverlay"} />
                <TableHeader>
                  <TableName>
                    {t.name}
                  </TableName>
                  {tableReservation &&
                    <ReservationCover>
                      {tableReservation.numberOfPeople}
                      <IoMdPeople />
                    </ReservationCover>
                  }
                </TableHeader>
                {tableReservation &&
                  <>
                    {this.state.parent === "reservationEditor" &&
                      <>
                        <TableContent>
                          <EmptyTable>
                            {Language.getName(Names.Booked)}<br />
                            {TableReservation.getStart(tableReservation).toShortTimeFormat()} - {TableReservation.getEnd(tableReservation).toShortTimeFormat()}
                          </EmptyTable>
                        </TableContent>
                      </>
                    }
                    {this.state.parent !== "reservationEditor" &&
                      <>
                        <TableContent>
                          {profile &&
                            <EmptyTable>
                              {profile.firstName} {profile.lastName}
                            </EmptyTable>
                          }

                          {orders.length > 0 &&
                            <>
                              <TableTotalPriceWrapper>
                                {pulseUnpaid && <UnpaidPulse />}
                                <div>{totalPrice.toLocaleString()} Ft</div>
                              </TableTotalPriceWrapper>
                              <CenteredRow margin={"3px"}>
                                {pulseAlmostEndTime &&
                                  <>
                                    <Pulse color={"#ec4d04"}>
                                      <BiTimer style={{ position: "relative", top: "1px" }} />
                                    </Pulse>
                                    {endDate &&
                                      <Pulse color={"#ec4d04"}>
                                        {addLeadingZero(endDate.getHours()) + ":" + addLeadingZero(endDate.getMinutes())}
                                      </Pulse>
                                    }
                                  </>
                                }
                                {pulseSeatingTimeLate &&
                                  <>
                                    <Pulse color={"#ec4d04"}>
                                      <BiTimer style={{ position: "relative", top: "1px" }} />
                                    </Pulse>
                                    {startDate &&
                                      <Pulse color={"#ec4d04"}>
                                        {addLeadingZero(startDate.getHours()) + ":" + addLeadingZero(startDate.getMinutes())}
                                      </Pulse>
                                    }
                                  </>
                                }
                              </CenteredRow>
                            </>
                          }
                          {orders.length <= 0 &&
                            <EmptyTable>
                              {Language.getName(Names.NoOrders2)}
                            </EmptyTable>
                          }
                        </TableContent>
                      </>
                    }
                  </>
                }
              </TableDiv>
            );
          })}
        </TablesWrapper>
      </Wrapper>
    );
  }
}
