import APICaller, { APIMsgBase } from "../APICaller";
import { ShiftControl, ShiftControlCountedPayments, ShiftControlExtraParameter, ShiftControlExtraParameterSaved } from "../../model/ShiftControl";
import { OrderPayment } from "../../model/Order";
import { GetOrdersType, OrdersAPI } from "./OrdersAPI";
import ErrorMessage from "./ErrorMessages";
import { TableReservationAPI } from "./TableReservationAPI";
import { ProductsAPI } from "./ProductsAPI";

export type LastShiftControlsType =
  GetOrdersType
  & {
  orderPayments: OrderPayment[],
  date: Date
};

export type ShiftControlsType =
  GetOrdersType
  & {
  orderPayments: OrderPayment[],
  shiftControls: ShiftControl[],
  maxCount: number,
};

export type ShiftControlParameters =
  APIMsgBase
  & {
  sceps: ShiftControlExtraParameter[]
};

function loadLastShiftControl(cb: (res: LastShiftControlsType)=>{}) {
  APICaller.ownFetch(false, "POST", "/cop", {}, res => {
    let result = JSON.parse(res);
    if (result.error === ErrorMessage.OK) {
      result.orders.forEach(o => OrdersAPI.fixDates(o));
      result.orderPayments.forEach(o => OrdersAPI.fixDatesOP(o));
      result.date = result.date ? new Date(result.date) : undefined;
      result.tableReservations.forEach(tr => TableReservationAPI.fixDates(tr));

      if (cb)
        cb(result);
    } else {
      if (cb)
        cb(result);
    }
  });
}

function getShiftControls(offset: number, limit: number, cb: (res: ShiftControlsType)=>{}) {
  APICaller.ownFetch(false, "POST", "/cops", { offset, limit }, res => {
    let result: ShiftControlsType = JSON.parse(res);
    if (result.error === ErrorMessage.OK) {
      result.orders.forEach(o => OrdersAPI.fixDates(o));
      result.orderPayments.forEach(o => OrdersAPI.fixDatesOP(o));
      result.tableReservations.forEach(tr => TableReservationAPI.fixDates(tr));
      result.shiftControls.forEach(s => s.dateTime = new Date(s.dateTime));
      result.products.forEach(p => ProductsAPI.fixDates(p));

      if (cb)
        cb(result);
    } else {
      if (cb)
        cb(result);
    }
  });
}

function saveShiftControl(sccps: ShiftControlCountedPayments, sceps: ShiftControlExtraParameterSaved[], cb: (res: ShiftControlsType)=>{}) {
  APICaller.ownFetch(false, "PUT", "/cop", { sccps, sceps }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function getParameters(cb: (res: ShiftControlParameters)=>{}) {
  APICaller.ownFetch(false, "POST", "/cop/parameters", {}, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ShiftControlsAPI = {
  getShiftControls,
  getParameters,
  loadLastShiftControl,
  saveShiftControl,
};
