class EventSystem {
  static queue = {};
  static idCounter = 0;

  static events = {
    open_contracts_details: 1,
    open_add_contract_modal: 2,
    open_show_contract_modal: 3,
    partnerOpened: 4,
    open_extras: 6,
    edit_simpleTable: 7,
    upload_image: 8,
    image_uploaded: 9,
    extras_changed: 10,
    authentication_changed: 11,
    edit_extra: 12,
    edit_menu: 13,
    menu_edited: 14,
    product_edited: 15,
    urlChanged: 16,
    sideMenu: 17,
    edit_product: 18,
    select_extras: 19,
    extras_selected: 20,
    open_order_comment: 21,
    contextSystemChanged: 22,
    show_confirmation_modal: 23,
    edit_product_categories: 24,
    open_extras_selector: 25,
    edit_shop_profile_permissions: 27,
    edit_raw_material: 28,
    start_stock_taking: 29,
    start_inward_handling: 31,
    edit_recipe: 32,
    edit_recipe_qty: 33,
    open_order_payment: 34,
    open_coupons_modal: 35,
    modify_order: 36,
    open_table_selector: 37,
    open_new_reservation_editor: 38,
    add_new_customer: 39,
    open_orders_modal: 40,
    edit_zone: 41,
    add_new_coupon: 42,
    edit_product_printer_settings: 43,
    edit_storage: 44,
    start_manufacturing: 45,
    open_qty_history: 46,
    start_move_storage: 47,
    googlePlacesScriptLoaded: 48,
    open_close_of_pay_modal: 49,
    edit_upsell_rule_settings: 50,
    edit_upsell_rule: 51,
    edit_view: 52,
    order_payment_save_clicked: 53,
  };

  static publish(event, data) {
    if (typeof EventSystem.queue === "undefined") {
      return false;
    }
    if (Object.keys(EventSystem.queue).length > 0) {
      if (typeof EventSystem.queue[event] !== "undefined" && EventSystem.queue[event].length > 0)
        for (let a of EventSystem.queue[event]) a.callback(data);
    }
  }

  static unsubscribe(id) {
    let eventName;
    let index = -1;
    A: for (let event in EventSystem.queue) {
      let eventSubscribers = EventSystem.queue[event];
      for (let i in eventSubscribers) {
        if (eventSubscribers[i].id === id) {
          index = i;
          eventName = event;
          break A;
        }
      }
    }
    if (index >= 0) {
      EventSystem.queue[eventName].splice(index, 1);
    }
  }

  static subscribe(event, callback) {
    if (typeof EventSystem.queue[event] === "undefined") {
      EventSystem.queue[event] = [];
    }

    let id = EventSystem.idCounter;
    EventSystem.queue[event].push({ callback, id });
    EventSystem.idCounter++;
    return id;
  }
}

export default EventSystem;
