import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import UpsellRules from "../home/UpsellRules";

export default class UpsellRulesModal extends Component {
  state: {
    showModal: boolean,
    language: number,
  } = {
    showModal: false,
    language: ContextSystem.language,
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.edit_upsell_rule_settings, () => {
      this.setState({ showModal: true });
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"xl"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.UpSellCrossSellRules)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpsellRules />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
