import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import type { ShopProfileType } from "../../model/ShopProfile";
import { ShopProfile, ShopProfilePermissions, ShopProfileTypes } from "../../model/ShopProfile";
import { Shop } from "../../model/Shop";
import styled from "styled-components";
import { ShopProfileAPI } from "../../utils/api/ShopProfileAPI";

const PermissionDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10px;
  margin: 3px 0;

  & > span {
    margin-left: 6px;
    width: 300px;
    font-size: 11pt;
    cursor: pointer;
  }
`;

const CheckBox = styled(Input)`
  margin: 0;
  padding: 0;
  min-height: unset;
`;

export default class ShopProfileEditor extends Component {
  eventIDs: number[] = [];

  state: {
    showModal: boolean,
    edit: null | ShopProfile,
    title: string,
    language: number,
    selectedShop: Shop,
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.AddProfile),
    language: ContextSystem.language,
    selectedShop: ContextSystem.selectedShop,
  };

  save() {
    let sp: ShopProfile = { ...this.state.edit };

    ShopProfileAPI.save(sp.email, sp.permissions[this.state.selectedShop.id], (res) => {
      if (res.error !== 0)
        return;

      ContextSystem.updateShopProfile(sp, res.shopProfile);
      this.setState({ showModal: false });
    });
  }

  edit(shopProfile: ShopProfile) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyProfile),
      edit: shopProfile,
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs)
      EventSystem.unsubscribe(eid);
  }

  componentDidMount() {
    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.edit_shop_profile_permissions, (shopProfile) => {
      if (!shopProfile)
        return;

      this.edit(shopProfile);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, selectedShop }) => {
      if (language !== undefined)
        this.setState({ language });
      if (selectedShop !== undefined)
        this.setState({ selectedShop });
    });
    this.eventIDs.push(eid);
  }

  togglePermit(type: ShopProfileType) {
    let edit: ShopProfile = JSON.parse(JSON.stringify(this.state.edit));
    if (edit.permissions[this.state.selectedShop.id].includes(type)) {
      edit.permissions[this.state.selectedShop.id].remove(type);
    } else {
      edit.permissions[this.state.selectedShop.id].push(type);
    }
    this.setState({ edit });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label><b>{Language.getName(Names.Permissions)}</b></label>
          {Object.keys(ShopProfileTypes).map((k, i) => {
            let type: ShopProfileType = ShopProfileTypes[k];
            let permitted: boolean = ShopProfile.isPermitted(this.state.edit, this.state.selectedShop?.id, type);
            return (
              <PermissionDiv key={i}>
                <CheckBox
                  checked={permitted}
                  type="checkbox"
                  onChange={() => this.togglePermit(type)}
                  width={"20px"}
                  height={"20px"}
                />
                <span onClick={() => this.togglePermit(type)}>{ShopProfilePermissions.getName(type)}</span>
              </PermissionDiv>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
