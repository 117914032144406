import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import Orders from "../home/Orders";
import { Order } from "../../model/Order";
import { Button } from "../FormComponents";
import Language, { Names } from "../../utils/Language";

export default class OrdersModal extends Component {
  state: {
    showModal: boolean,
    language: number,
    orders: Order[],
  } = {
    showModal: false,
    language: ContextSystem.language,
    orders: [],
  };

  loadContext() {
    this.setState({
      language: ContextSystem.language,
      orders: ContextSystem.orders.filter(o => o.enabled && o.partnerID === ContextSystem.selectedShop?.id),
    });
  }

  componentDidMount() {
    this.loadContext();
    EventSystem.subscribe(EventSystem.events.open_orders_modal, () => {
      this.setState({ showModal: true });
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, orders }) => {
      if (language !== undefined || orders !== undefined)
        this.loadContext();
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"full_scroll"}
             onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Body scroll={true} padding={0}>
          {this.state.showModal &&
            <Orders />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
