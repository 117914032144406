/* eslint-disable */
import Config from "./Config";

export default function validateEmail(email) {
  // noinspection RegExpRedundantEscape
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isInAdminDomain(email: string): boolean {
  if (!validateEmail(email))
    return false;
  if (Config.TEST)
    return false;
  for (let adminDomain: string of Config.adminDomains) {
    if (email.toLowerCase().endsWith(adminDomain.toLowerCase()))
      return true;
  }
  return false;
}
