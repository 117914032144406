import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import { Section, SubSection } from "../home/DetailedSettings";
import { Category, CategoryViewTypes, Product, ProductView, TranslatableString } from "../../model/Product";
import { endOfMonth } from "date-fns";
import { languages } from "./CouponEditor";
import CategoriesAPI from "../../utils/api/CategoriesAPI";
import { ProductsList, Select, SelectedProduct } from "./ProductEditor";
import { FaTrashAlt } from "react-icons/all";
import ErrorMessage from "../../utils/api/ErrorMessages";

export default class ViewEditor extends Component {
  eventIDs: number[] = [];

  state: {
    showModal: boolean,
    edit: null | Category,
    title: string,
    language: number,
    selectedLanguage: number,
    productIDs: number[],
    products: Product[],
    productsOptions: { value: any, label: string }[],
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewView),
    language: ContextSystem.language,
    selectedLanguage: languages[0].id,
    productIDs: [],
    products: [],
    productsOptions: []
  };

  deleteView() {
    if (!this.state.edit?.id || this.state.edit.id <= 0)
      return;

    CategoriesAPI.removeView(this.state.edit.id, (res) => {
      if (res.error !== ErrorMessage.OK)
        return;

      toast(Language.getName(Names.Removed));
      this.setState({ showModal: false, edit: undefined, productIDs: [] });
    });
  }

  save() {
    let element: Category = { ...this.state.edit };
    if (!element.name || !TranslatableString.check(element.name, n => n.value.length >= 2, false)) {
      toast(Language.getName(Names.NameTooShort));
      return;
    }

    TranslatableString.fillEmptyWithCopy(element.name);

    let productViews: ProductView[] = this.state.productIDs.map(
      id => ({ id: -1, enabled: true, productID: id, categoryID: -1 }));

    CategoriesAPI.saveView(element, productViews, res => {
      if (res.error !== 0)
        return;
      toast(Language.getName(Names.Added));
      this.setState({ showModal: false });
    });
  }

  edit(view: Category, productViews: ProductView[]) {
    view = { ...view };
    let productIDs: number[] = productViews?.map(pv => pv.productID);
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyView),
      edit: view,
      productIDs
    });
  }

  addProduct({ value: productID }) {
    let productIDs: number[] = this.state.productIDs;
    if (!productIDs)
      productIDs = [];

    if (!productIDs?.includes(productID))
      productIDs.push(productID);

    this.setState({ productIDs });
  }

  removeSelectedProduct(productID: number) {
    let productIDs: number[] = this.state.productIDs;

    productIDs = productIDs.filter(pi => pi !== productID);

    this.setState({ productIDs });
  }

  addNew() {
    let edit: Category = {
      id: -1,
      enabled: true,
      parent: 0,
      orderNumber: 1,
      img: "",
      partnerID: ContextSystem.selectedShop.id,
      name: TranslatableString.create(Language.getName(Names.View)),
      view: CategoryViewTypes.PUBLIC_VIEW
    };

    this.setState({
      showModal: true,
      title: Language.getName(Names.NewView),
      edit: edit,
      productIDs: []
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs)
      EventSystem.unsubscribe(eid);
  }

  componentDidMount() {
    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.edit_view, (a) => {
      let { view, productIDs } = a ? a : { view: undefined, productIDs: undefined };
      this.loadProductOptions();
      if (!view)
        this.addNew();
      else
        this.edit(view, productIDs);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, products }) => {
      if (language !== undefined || products !== undefined)
        this.setState({
          language,
          products: ContextSystem.products.filter(p => p.enabled && p.partnerID === ContextSystem?.selectedShop?.id)
        }, () => {
          this.loadProductOptions();
        });
    });
    this.eventIDs.push(eid);
    this.loadProductOptions();
  }

  loadProductOptions() {
    let productsOptions = [];
    for (let p: Product of this.state.products) {
      productsOptions.push({
        value: p.id,
        label: TranslatableString.get(p.name)
      });
    }
    this.setState({ productsOptions });
  }

  setName(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let name: TranslatableString = this.state.edit.name;
    if (!name)
      name = TranslatableString.create("");
    name.translation = name.translation.filter(t => t.languageCode !== languageCode);
    name.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        name: name
      }
    });
  }

  handleValidUntilToggle(value: boolean) {
    if (value) {
      this.setState({
        edit: {
          ...this.state.edit,
          validUntil: endOfMonth(new Date())
        }
      });
    } else {
      this.setState({
        edit: {
          ...this.state.edit,
          validUntil: new Date(3000, 0)
        }
      });
    }
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.edit &&
            <>
              <Section>
                <SubSection>
                  <label>{Language.getName(Names.Name)}</label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.edit?.name?.translation?.find(
                      tr => tr.languageCode === languageCode)?.value ?? ""}
                    type="text"
                    placeholder={Language.getName(Names.Name)}
                    onChange={e => this.setName(e.target.value)}
                  />
                </SubSection>
              </Section>
              <ProductsList>
                <SelectedProduct header={true}>
                  <div>
                    <b>#</b>
                  </div>
                  <div>
                    <b>{Language.getName(Names.ProductName)}</b>
                  </div>
                </SelectedProduct>
                {this.state.edit && this.state.productIDs && this.state.productIDs.map((pid, i) => {
                  let p: Product = this.state.products.find(product => product.id === pid);
                  if (!p)
                    return <React.Fragment key={i} />;
                  return (
                    <SelectedProduct key={i}>
                      <div>#{p.id}</div>
                      <div>
                        {TranslatableString.get(p.name)}
                      </div>
                      <div>
                        <FaTrashAlt onClick={() => this.removeSelectedProduct(p.id)} />
                      </div>
                    </SelectedProduct>
                  );
                })}
              </ProductsList>
              <Select
                placeholder={Language.getName(Names.Search) + "..."}
                onChange={(e) => this.addProduct(e)}
                options={this.state.productsOptions}
                noOptionsMessage={() => Language.getName(Names.NoProductToShow)}
              />
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          {this.state.edit?.id > 0 &&
            <Button variant="secondary" onClick={() => this.deleteView()}>
              {Language.getName(Names.Remove)}
            </Button>
          }
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}
