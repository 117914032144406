import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import type { Stackable } from "../../model/Product";
import { Product, Raw, RawQty, Recipe, RecipeQty, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import type { QtyType } from "../../model/Stock";
import { Qty, QtyTypes, Storage } from "../../model/Stock";
import { Label } from "./ContractsDetailsModal";
import { RawAPI } from "../../utils/api/RawAPI";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/all";
import { CellInput, IngredientsDiv, RawDiv, RawUnit, Select, Table, TableCell, TableRow } from "./InwardHandlingModal";
import type { Option } from "./OrderStateCommentEditor";
import { QtyWarning } from "../home/RawMaterials";

export default class ManufacturingModal extends Component {
  state: {
    showModal: boolean,
    title: string,
    language: number,
    raws: Raw[],
    products: Product[],
    qtyList: Qty[],
    storages: Storage[],
    recipes: Recipe[],
    storageQty: Qty[],
  } = {
    showModal: false,
    title: Language.getName(Names.Production),
    language: ContextSystem.language,
    raws: [],
    products: [],
    qtyList: [],
    storages: [],
    recipes: [],
    storageQty: [],
  };

  save() {
    RawAPI.handleProductionManufactured(this.state.qtyList, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.start_manufacturing, () => {
      this.setState({
        qtyList: [],
        showModal: true,
      });
    });

    this.loadContext();

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, raws, storages, products, recipes, qtyList }) => {
        if (language !== undefined)
          this.setState({ language });
        if (raws !== undefined || storages !== undefined || products !== undefined || recipes !== undefined || qtyList !== undefined)
          this.loadContext();
      },
    );
  }

  loadContext() {
    this.setState({
      raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      storages: ContextSystem.storages.filter(r => r.enabled && r.shopIDs.includes(ContextSystem.selectedShop?.id)),
      products: ContextSystem.products.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      recipes: ContextSystem.recipes.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      storageQty: ContextSystem.qtyList.filter(
        r => r.enabled && (r.storageID === -1 || ContextSystem.storages.find(s => s.id === r.storageID)?.shopIDs
          .includes(ContextSystem.selectedShop?.id))),
    });
  }

  addNewQty(id: string) {
    if (!id || id === "-1")
      return;

    let modelID = parseInt(id.substring(1));
    let modelType = id.startsWith("r") ? QtyTypes.RAW : QtyTypes.PRODUCT;

    if (!modelID || modelID <= 0)
      return;

    let model: Raw | Product;
    if (modelType === QtyTypes.RAW)
      model = this.state.raws.find(r => r.id === modelID);
    else if (modelType === QtyTypes.PRODUCT)
      model = this.state.products.find(r => r.id === modelID);

    if (!model)
      return;

    if (this.state.qtyList.find(q => q.modelID === modelID && q.type === modelType))
      return;

    let qtyList: Qty[] = this.state.qtyList.map(q => ({ ...q }));
    qtyList.push({
      id: -1,
      enabled: true,
      qty: 0,
      modelID,
      price: 0,
      type: modelType,
      reduction: [],
      profileID: model.partnerID,
      addedDate: new Date(),
      supplyPrice: 0,
      storageID: -1,
    });
    this.setState({ qtyList });
  }

  changeQty(modelID: number, type: QtyType, qty: string, storageID: number = -1) {
    if (qty < 0)
      qty = 0;
    let qtyList: Qty[] = this.state.qtyList.map(qt => qt.modelID === modelID && qt.storageID === storageID ? {
      ...qt,
      qty: parseFloat(qty),
    } : { ...qt });

    if (qtyList.find(qt => qt.modelID === modelID && qt.type === type && qt.storageID === storageID) === undefined)
      qtyList.push({
        id: -1,
        enabled: true,
        qty: parseFloat(qty),
        modelID: modelID,
        price: 0,
        type: type,
        reduction: [],
        profileID: ContextSystem.selectedShop?.id,
        addedDate: new Date(),
        supplyPrice: 0,
        storageID: storageID,
      });

    this.setState({ qtyList });
  }

  removeQty(q: Qty) {
    let qtyList: Qty[] = this.state.qtyList.filter(qt => qt.modelID !== q.modelID && qt.type !== q.type);
    this.setState({ qtyList });
  }

  render() {
    let items: Stackable[] = [];
    for (let qty of this.state.qtyList) {
      if (items.find(r => r.id === qty.modelID && r.qtyType === qty.type) === undefined) {
        let model = (qty.type === QtyTypes.RAW ? this.state.raws : this.state.products).find(r => r.id === qty.modelID);
        items.push({ qtyType: parseInt(qty.type), ...model });
      }
    }

    let options: Option[] = [];

    options.push({ value: "-1", label: "-----" + Language.getName(Names.Products) + "-----" });
    this.state.products.forEach(pr => {
      //"p" means product, used in addNewQty
      if (pr.countStock === true && items.find(
        row => row.id === pr.id && row.qtyType === QtyTypes.PRODUCT) === undefined)
        options.push({ value: "p" + pr.id, label: TranslatableString.get(pr.name) });
    });
    options.push({ value: "-1", label: "-----" + Language.getName(Names.ProcessedProducts) + "-----" });
    this.state.raws.forEach(r => {
      //"r" means product, used in addNewQty
      if (r.semiProduct === true && items.find(row => row.id === r.id && row.qtyType === QtyTypes.RAW) === undefined)
        options.push({ value: "r" + r.id, label: TranslatableString.get(r.name) });
    });

    return (
      <Modal size={"xl"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Label font_size={"10pt"}>{Language.getName(Names.PleaseEnterYourProduction)}</Label>
          <Table>
            <TableRow header={true}>
              <TableCell width={"40px"}>#</TableCell>
              <TableCell grow={2} width={"20%"}>{Language.getName(Names.Name)}</TableCell>
              {this.state.storages.length > 0 &&
                <>
                  {this.state.storages.map((s, i) => (
                    <TableCell key={i} width={"100px"}>{Language.getName(Names.Quantity)} ({s.name})</TableCell>
                  ))}
                </>
              }
              {this.state.storages.length <= 0 &&
                <TableCell width={"100px"}>{Language.getName(Names.Quantity)}</TableCell>
              }
              <TableCell width={"250px"}>{Language.getName(Names.UsedIngredients)}</TableCell>
              <TableCell c_cursor={true} width={"30px"} />
            </TableRow>
            {items.map((item: Stackable, i: number) => {
                let qtys: Qty[] = this.state.qtyList.filter(q => q.modelID === item.id && q.type === item.qtyType);
                let q: Qty = qtys[0];

                if (!item)
                  return <React.Fragment key={i} />;

                return (
                  <TableRow key={i}>
                    <TableCell grow={2} width={"20%"}>
                      {TranslatableString.get(item.name)}
                    </TableCell>
                    {this.state.storages.length > 0 &&
                      <>
                        {this.state.storages.map(s => {
                          let q: Qty = qtys.find(q => q.storageID === s.id);

                          return (
                            <TableCell width={"100px"}>
                              <CellInput
                                min={0}
                                pattern={"[0-9]+([\\.,][0-9]+)?"}
                                value={q?.qty ?? ""}
                                type="number"
                                step={item.qtyType === QtyTypes.PRODUCT ? 1 : 0.01}
                                placeholder={Language.getName(Names.Quantity)}
                                onChange={(e) => this.changeQty(item.id, item.qtyType, e.target.value, s.id)}
                                width={"100%"}
                              />
                              <RawUnit>
                                {item.qtyType === QtyTypes.RAW ? TranslatableString.get(item.unit) : Language.getName(
                                  Names.servings)}
                              </RawUnit>
                            </TableCell>
                          );
                        })}
                      </>
                    }
                    {this.state.storages.length <= 0 &&
                      (() => {
                        let q: Qty = qtys.find(q => q.storageID === -1);

                        return (
                          <TableCell width={"100px"}>
                            <CellInput
                              min={0}
                              pattern={"[0-9]+([\\.,][0-9]+)?"}
                              value={q.qty ?? ""}
                              type="number"
                              step={item.qtyType === QtyTypes.PRODUCT ? 1 : 0.01}
                              placeholder={Language.getName(Names.Quantity)}
                              onChange={(e) => this.changeQty(item.id, item.qtyType, e.target.value, -1)}
                              width={"100%"}
                            />
                            <RawUnit>
                              {item.qtyType === QtyTypes.RAW ? TranslatableString.get(item.unit) : Language.getName(
                                Names.servings)}
                            </RawUnit>
                          </TableCell>
                        );
                      })()
                    }
                    <TableCell width={"250px"}>
                      <IngredientsDiv>
                        {item?.recipes?.map((recipeQty: RecipeQty, i) => {
                          return (
                            <React.Fragment key={i}>
                              {recipeQty.recipe.raws.map((rawQty, k) => {
                                let sumTyped: number = 0;
                                qtys.forEach(qq => sumTyped += qq.qty);
                                return (
                                  <RawDiv key={k}>
                                    {Math.round(
                                      (sumTyped * recipeQty.qty * rawQty.qty / 10) * 100) / 100} {TranslatableString.get(
                                    rawQty.raw.unit)} {TranslatableString.get(rawQty.raw.name)}
                                  </RawDiv>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </IngredientsDiv>
                    </TableCell>
                    <TableCell width={"30px"}>
                      <FaTrash onClick={() => this.removeQty(q)} />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
            <TableRow>
              <TableCell width={"100%"}>
                <Select
                  options={options}
                  placeholder={Language.getName(Names.Choose)}
                  noOptionsMessage={() => {
                    Language.getName(Names.NoProductToShow);
                  }}
                  onChange={(e) => this.addNewQty(e.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={"100%"} justify={"flex-end"}>
                {(() => {
                  let ingredients: { raw: RawQty, qty: number }[] = [];
                  items.forEach((item: Stackable) => item.recipes?.forEach(
                    (recipe: RecipeQty) => recipe.recipe.raws?.forEach((raw: RawQty) => {
                      let ingredient = ingredients.find(ing => ing.raw.id === raw.raw.id);

                      let typedQty: number = 0;
                      this.state.qtyList.filter(q => q.modelID === item.id && q.type === item.qtyType)
                        .forEach(qq => typedQty += qq.qty);

                      let q: number = typedQty * recipe.qty * raw.qty / 10;
                      if (ingredient)
                        ingredient.qty += q;
                      else
                        ingredients.push({ raw: { ...raw }, qty: q });
                    })));
                  return (
                    <IngredientsDiv>
                      <b style={{ "marginRight": "3px" }}>
                        {Language.getName(Names.AllUsedIngredients)}:
                      </b>
                      {ingredients.map((ing, i) => {
                        let sum = Qty.countSum(this.state.storageQty, QtyTypes.RAW, ing.raw.raw.id);
                        return (
                          <RawDiv key={i}>
                            {sum < ing.qty &&
                              <QtyWarning />
                            }
                            {Math.round(ing.qty * 100) / 100} {TranslatableString.get(
                            ing.raw.raw.unit)} {TranslatableString.get(ing.raw.raw.name)}
                          </RawDiv>
                        );
                      })}
                    </IngredientsDiv>
                  );
                })()}
              </TableCell>
            </TableRow>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
