import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import Modal from "./Modal";
import "react-image-crop/dist/ReactCrop.css";
import styled, { css } from "styled-components";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import type { OrderStateComment } from "../../model/Order";
import { Order, OrderPayment, OrderState } from "../../model/Order";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import StandingComponent, {
  PaymentInput as PaymentInput2,
  PaymentName as PaymentName2,
  PaymentOption as PaymentOption2,
  PaymentsWrapper as PaymentsWrapper2
} from "../home/StandingComponent";
import { AllPaymentMethod, PaymentMethods } from "../../model/PaymentMethodSetting";
import { ShippingMethods } from "../../model/ShippingPrice";
import { BsCheck2All } from "react-icons/all";
import { Shop } from "../../model/Shop";
import type { PutPaymentType } from "../../utils/api/OrdersAPI";
import { OrdersAPI } from "../../utils/api/OrdersAPI";

const PaymentsWrapper = styled(PaymentsWrapper2)`
  justify-content: flex-start;
  align-items: flex-start;
`;

const PaymentOption = styled(PaymentOption2)`
  margin: 3px;
`;

const PaymentInput = styled(PaymentInput2)`
  width: 100px;
`;

const PaymentName = styled(PaymentName2)`
  width: 300px;
  margin-left: 6px;
`;

const Body = styled(Modal.Body)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.label`
  width: 100%;
  text-align: left;
  margin: 10px 0 2px 0;
  padding: 0;
  font-size: ${({ font_size }) => font_size ?? "13pt"};
  font-family: "Helvetica Neue", sans-serif;
`;

const DurationButtonList = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const DurationButton = styled.div`
  padding: 15px 5px;
  border-radius: 5px;
  margin: 10px 0;

  width: 24%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbdbdb;
  transition: box-shadow 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 1px #121212;
  }

  ${({ selected }) => selected === true && css`
    box-shadow: 0 0 5px 1px #121212;
  `}
`;

const OtherDurationWrapper = styled.div`
  padding: 0 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: box-shadow 200ms ease-in-out;
  border-radius: 8px;
  border: 1px solid #dbdbdb;

  & > h3 {
    max-width: 19%;
    font-size: 11pt;
    font-weight: normal;
    padding: 0;
    margin: 0 5px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 1px #121212;
  }

  ${({ selected }) => selected === true && css`
    box-shadow: 0 0 5px 1px #121212;
  `}
`;

export type Option = { value: any, label: string };
type DurationOption = Option;

export default class OrderStateCommentEditor extends Component {
  state: {
    showModal: boolean,
    title: string,
    comment: string,
    durationOptions: DurationOption[],
    selectedOption: number,
    editDuration: string,
    incomingParameters: { orderNumber: string, newState: number, order: Order },
    language: number,
    selectedShop: Shop,
    payment: { [keys: number]: string },
    cb: ()=>{}
  } = {
    showModal: false,
    title: Language.getName(Names.AddComment),
    comment: "",
    durationOptions: [
      { value: 11, label: "1" },
      { value: 10, label: "3" },
      { value: 9, label: "5" },
      { value: 8, label: "10" },
      { value: 1, label: "15" },
      { value: 2, label: "30" },
      { value: 3, label: "45" },
      { value: 4, label: "60" },
      { value: 5, label: "80" },
      { value: 6, label: "100" },
      { value: 7, label: "120" }
    ],
    selectedOption: -1,
    editDuration: "",
    incomingParameters: undefined,
    language: ContextSystem.language,
    selectedShop: ContextSystem.selectedShop,
    payment: {},
    cb: undefined
  };

  save() {
    if (!Order.isPaidFully(
      this.state.incomingParameters.order) && this.state.incomingParameters.newState === OrderState.DONE) {
      let orderPayments: OrderPayment[] = StandingComponent.createOrderPayments(this.state.payment);
      let orderPaymentsSend: PutPaymentType[] = orderPayments.map(op => {
        return { orderNumber: this.state.incomingParameters.order.number, orderPayment: op };
      });
      OrdersAPI.putPayments(orderPaymentsSend, [], (res) => {
        if (res.error !== 0)
          return;

        if (this.state.cb)
          this.state.cb({
            comment: "",
            ...this.state.incomingParameters
          });
        this.setState({ showModal: false });
      });
    } else {
      if (this.state.selectedOption === -1) {
        toast(Language.getName(Names.PleaseChooseAnOptionFirst));
        return;
      }
      if (this.state.selectedOption === 0 && this.state.editDuration.length < 1) {
        toast(Language.getName(Names.PleaseChooseAnOptionFirst));
        return;
      }

      let comment: OrderStateComment = {
        message: this.state.comment && this.state.comment.length > 1 ? this.state.comment : "",
        minutes: this.state.selectedOption === 0 ? parseInt(this.state.editDuration) : parseInt(
          this.getDuration(this.state.durationOptions, this.state.selectedOption).label)
      };

      if (this.state.cb)
        this.state.cb({
          comment,
          ...this.state.incomingParameters
        });
      this.setState({ showModal: false });
    }
  }

  getDuration(durationOptions: DurationOption[], selected: number): null | DurationOption {
    for (let durationOption: DurationOption of durationOptions) {
      if (durationOption.value === selected) {
        return durationOption;
      }
    }
    return null;
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_order_comment,
      (incomingParameters: { orderNumber: string, newState: number, order: Order, cb: ()=>{} }) => {
        this.setState({
          showModal: true,
          comment: "",
          selectedOption: -1,
          editDuration: "",
          incomingParameters,
          cb: incomingParameters.cb
        });
        if (!Order.isPaidFully(incomingParameters.order) && incomingParameters.newState === OrderState.DONE) {
          let payment: { [keys: number]: number } = {};
          this.setState({
            title: Language.getName(Names.PaymentMethods),
            payment
          });
        }
      }
    );

    type CSC = { language: number, orders: Order[], selectedShop: Shop }
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, (p: CSC) => {
      let { language, orders, selectedShop }: CSC = p;
      if (language !== undefined) {
        this.setState({
          language,
          title: Language.getName(Names.AddComment)
        });
      }
      if (this.state.showModal && orders !== undefined) {
        let editingOrder: Order = orders.find(o => o.id === this.state.incomingParameters?.order?.id);
        if (!editingOrder) {
          let ip = this.state.incomingParameters;
          ip.order = editingOrder;
          this.setState({ incomingParameters: ip });
        }
      }
      if (selectedShop !== undefined) {
        this.setState({ selectedShop });
      }
    });
  }

  selectDuration(durationValue) {
    this.setState({ selectedOption: durationValue });
  }

  clearPayment() {
    let payment: { [keys: number]: string } = {};
    this.setState({ payment });
  }

  changePayment(paymentType: number, value: string) {
    let payment: { [keys: number]: string } = { ...this.state.payment };
    payment[paymentType] = value;
    this.setState({ payment });
  }

  allOnOnePaymentOption(paymentType: number, value: string) {
    let payment: { [keys: number]: string } = {};
    payment[paymentType] = value;
    this.setState({ payment });
  }

  render() {
    let paid: boolean = Order.isPaidFully(this.state.incomingParameters?.order);
    let newState: number = this.state.incomingParameters?.newState;
    let paidUntilNow: number = Order.totalPayment(this.state.incomingParameters?.order);
    let orderTotalPrice: number = this.state.incomingParameters?.order?.orderTotalPrice;
    let orderPayments: OrderPayment[] = StandingComponent.createOrderPayments(this.state.payment);
    let totalPayment: number = 0;
    orderPayments.forEach(p => totalPayment += p.amount);

    let left: number = orderTotalPrice - paidUntilNow - totalPayment;

    return (
      <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Body>
          {!paid && newState === OrderState.DONE &&
            <>
              <Label>{Language.getName(Names.PleaseSetPayments)}</Label>
              {paidUntilNow > 0 &&
                <>
                  <Label font_size={"10pt"}>{Language.getName(Names.Total)} {orderTotalPrice.toLocaleString()}Ft</Label>
                  <Label font_size={"10pt"}>{Language.getName(
                    Names.PaidUntilNow)} {paidUntilNow.toLocaleString()}Ft</Label>
                  <Label font_size={"10pt"}>
                    {Language.getName(Names.LeftToPay)}
                    <>
                      {left > 0 &&
                        <>{left.toString() + " Ft"}</>
                      }
                      {left < 0 &&
                        <>
                          {Language.getName(Names.Paid)}
                          {" +"}
                          <span color={"green"}>{-left.toLocaleString()} Ft</span>
                        </>
                      }
                    </>
                  </Label>
                </>
              }
              <PaymentsWrapper>
                {AllPaymentMethod.map((p, i) => {
                  if (PaymentMethods.isOnline(p.type))
                    return <React.Fragment key={i} />;

                  if (!p.availableShippingMethods.includes(ShippingMethods.PICKUP))
                    return <React.Fragment key={i} />;

                  let pms = this.state.selectedShop?.paymentMethods?.find(
                    pay => pay.paymentID === p.type && pay.active);

                  if (!pms)
                    return <React.Fragment key={i} />;

                  if (!p.countries.includes(this.state.selectedShop?.countryID))
                    return <React.Fragment key={i} />;

                  let value: string = this.state.payment[p.type] ?? "0";

                  // noinspection RequiredAttributes
                  return (
                    <PaymentOption>
                      <PaymentInput
                        value={value}
                        type="text"
                        autoComplete={"off"}
                        placeholder={"500"}
                        onChange={(e) => this.changePayment(p.type, e.target.value)}
                      />
                      <span>Ft</span>
                      <PaymentName onClick={() => this.allOnOnePaymentOption(p.type, orderTotalPrice - paidUntilNow)}>
                        {PaymentMethods.getName(p.type)}
                        {Object.keys(this.state.payment).length > 0 && paidUntilNow > 0 &&
                          <BsCheck2All />
                        }
                      </PaymentName>
                    </PaymentOption>
                  );
                })}
              </PaymentsWrapper>
            </>
          }
          {(newState === OrderState.CONFIRMED || newState === OrderState.PREPARING) &&
            <>
              {this.state.incomingParameters?.order?.shippingMethod === ShippingMethods.DELIVERY &&
                <Label>{Language.getName(Names.ChooseDeliveryTime)}</Label>
              }
              {this.state.incomingParameters?.order?.shippingMethod === ShippingMethods.PICKUP &&
                <Label>{Language.getName(Names.ChoosePreparationTime)}</Label>
              }
              <DurationButtonList>
                {this.state.durationOptions.map((duration, i) => {
                  return (
                    <DurationButton key={i} selected={this.state.selectedOption === duration.value}
                                    onClick={() => this.selectDuration(duration.value)}>
                      {Language.getName(Names.Approx) + ". " + duration.label + " " + Language.getName(Names.minutes)}
                    </DurationButton>
                  );
                })}
              </DurationButtonList>
              <OtherDurationWrapper selected={this.state.selectedOption === 0} onClick={() => this.selectDuration(0)}>
                <h3>{Language.getName(Names.Other) + ": " + Language.getName(Names.Approx) + ". "}</h3>
                <Input
                  value={this.state.editDuration}
                  type={"text"}
                  width={"20%"}
                  onChange={(e) =>
                    this.setState({
                      selectedOption: 0,
                      editDuration: e.target.value
                    })
                  }
                  placeholder={Language.getName(Names.eg) + " 15-20"}
                />
                <h3>{Language.getName(Names.minutes)}.</h3>
              </OtherDurationWrapper>
              <Input
                value={this.state.comment}
                type={"textarea"}
                width={"100%"}
                as={"textarea"}
                onChange={(e) => this.setState({ comment: e.target.value })}
                placeholder={Language.getName(Names.OtherCommentForGuest)}
              />
            </>
          }
        </Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}
