import React, { Component } from "react";
import { Subtitle, Title, Wrapper as WrapperOrg } from "../../pages/AszfPage";
import styled from "styled-components";
import shippingPrices from "../../assets/help/shippingPrices.png";
import extras from "../../assets/help/extras.png";
import openinHours from "../../assets/help/openinHours.png";
import settings from "../../assets/help/settings.png";
import categoires from "../../assets/help/categoires.png";
import products from "../../assets/help/products.png";
import changeCategories from "../../assets/help/changeCategories.png";
import menu from "../../assets/help/menu.png";
import new_product from "../../assets/help/new_product.png";
import { FaParagraph, FaPlus } from "react-icons/all";

const Wrapper = styled(WrapperOrg)`
  & > img{
    max-width: calc(100% - 35px);
    border-radius: 15px;
    box-shadow: 0 0 5px 1px #767676;
    margin: 5px 0 20px 35px;
    max-height: 65vh;
    object-fit: scale-down;
  }
  
  & > h3{
    padding-left: 18px;
  }
  
  & > imagetitle{
    margin-top: 20px;
    font-weight: bold;
  }
  
  & > p, & > imagetitle{
    padding-left: 35px;
  }
`;

export default class HelpPage extends Component {
  render() {
    return (
      <Wrapper>
        <Title>Blundee.com Partner Oldal - Hogyan működünk?</Title>
        <p><b>Tartalom:</b></p>
        <ul>
          <li>
            A: Első lépések: Étterem Adatainak megadása<br />
            <ol>
              <li>Szállítási területek megadása</li>
              <li>Személyes átvétel És Nyitvatartás megadása</li>
            </ol>
          </li>
          <li>
            B: Étlap feltöltése<br />
            <ol>
              <li>Bevezetés</li>
              <li>Lépések Étlap feltöltéséhez</li>
              <li>Beállítások És Étterem bezárása</li>
            </ol>
          </li>
        </ul>
        <h2><FaParagraph />A: Első lépések: Étterem Adatainak megadása</h2>
        <h3>A/1. Szállítási területek megadása</h3>
        <p>
          Fenti módon új kiszállítási területeket adhat meg, illetve törölheti a meglévő kiszállítási területeit (Kuka
          ikonra kattintva). A fentebbi módon teljes kerületek, városok… adhatóak meg, ha kisebb, egyedi szállítási
          területeket szeretne étterme számára, kerrese fel ügyfélszolgálatunkat.
        </p>
        <imagetitle>Szállítás és Nyitvatartás fül alatt a következőképpen</imagetitle>
        <img src={shippingPrices} alt={"Szállítási területek"} />

        <h3>A/2. Személyes átvétel És Nyitvatartás megadása</h3>
        <p>
          Személyes átvétel kapcsolóra kattintva engedélyezheti, hogy nyitvatartási időben a vásárlók személyesen is
          átvehessék rendelésüket.<br />
          4 fajta nyitvatartási idővel dolgozunk:
        </p>
        <ol>
          <li>
            Étterem nyitvatartása<br />
            <p>
              Amikor az étterem fizikailag kinyit, illetve bezárja kapuit: személyes átvétel illetve rendelés
              feldolgozásának előkészítése miatt fontos!
            </p>
          </li>
          <li>
            Kiszállítás ideje<br />
            <p>
              Étterem például 10 órakor kinyit, de csak 11-kor van futárszolgálat, illetve étterem zárása után,
              bentragadt ételeket még 1 órán keresztül kiszállítják.
            </p>
          </li>
          <li>
            Rendelés felvétel kiszállításra<br />
            <p>
              Két megadott időpont között a vendégnek az étteremhez van lehetősége előrendelést leadni, nyitási idő
              előtt. Kezdete az előrendelés leghamarabbi időpontja aznap, vége az utolsó időpont amikor az étterem
              rendelés leadást fogad.
            </p>
          </li>
          <li>
            Rendelés felvétel személyes átvételre<br />
            <p>
              Éppúgy mint a 3., "rendelés felvétel kiszállításra" beállítás, ez is megadja azt a két időpontot, ami
              között a vendég rendelést tud feladni személyes átvételre. Ezt is lehet előrendelés beállítására
              használni.
            </p>
          </li>
        </ol>
        <imagetitle>Szállítás és Nyitvatartás fül alatt a következőképpen</imagetitle>
        <img alt={""} src={openinHours} />
        <h2><FaParagraph />B: Étlap feltöltése</h2>
        <Subtitle>
          Az Blundee innovatív rendszerében az étlapon történt változtatások azonnal megjelennek a
          vásárlók számára, nem kell várni.
        </Subtitle>
        <h3>B/1. Bevezetés</h3>
        <imagetitle>Étlapjának feltöltése a “Termékeim” fülön érhető el.</imagetitle>
        <img alt={""} src={products} />
        <p>Jelmagyarázat</p>
        <ol>
          <li>Termék “Elrejtése” <br />
            A Vásárlónak nem jelenik meg a termék, viszont nem is kerül törlésre.
            pl: Elfogyott a termék, holnap lesz csak újra rendelhető.
          </li>
          <li>“Másolás” <br />
            Nagyon hasonló terméket szeretnék feltölteni, ezért gyorsabb másolni egy adott terméket és csak a szükséges
            paramétereket átírni, mint egy teljesen újat létrehozni. Két külön termékem lesz, csak gyorsabb így új
            terméket feltölteni.
          </li>
          <li>“Módosítás” <br />
            Rákattintva a termék paramétereit tudja megváltoztatni
          </li>
          <li>“Törlés” <br />
            Véglegesen törlöm az adott terméket.
          </li>
        </ol>
        <h3>B/2. Lépések Étlap feltöltéséhez</h3>
        <p>
          <b>1. Étlap kategóriáinak felépítése (Jobb felső sarok -> “Kategória” gomb)</b><br />
          Hogy étlapja a vásárlói is könnyen tudják értelmezni, illetve keresni benne az ételeit létrehozhat 6 db Fő
          kategóriát és minden Főkategória alatt 6 db alkategóriát. Jobb felső sarok “Kategóriák” gombra kattintva
          hozhatja létre az Önnek legjobban megfelelő elrendezést.
        </p>
        <imagetitle>Példa</imagetitle>
        <img alt={""} src={categoires} />
        <imagetitle>Kategóriák módosítása</imagetitle>
        <img alt={""} src={changeCategories} />
        <p>
          <b>2. Létrehozott kategóriákhoz Ételek hozzáadása</b><br />
          “<FaPlus />” Hozzáadás gombbal tud Kategóriához ételt feltölteni a következőképpen:
        </p>
        <imagetitle>Példa</imagetitle>
        <img alt={""} src={new_product} />
        <p>
          <b>3. Feltétek szerkesztése</b><br />
          - Feltéteket a “Feltétek” menüpontban tud hozzáadni, módosítani.<br />
          - Ételekhez a “Feltétek szerkesztése” gombal tudja őket hozzáadni
        </p>
        <p>
          Ha két verziót szeretné ugyanabból a termékből, (például két különböző méretű pizza) akkor a jobb felső
          sarokba lévő plusz gombbal hozzáadhat egy Új termék verziót, melynek az árát megváltoztathatja. (Jelenleg a 32
          CM-es Pizza termék alapára 2500 Ft, a 45 CM-es Pizza alapára 5000 Ft) A két termék verzióban a feltétek
          egymástól függetlenül léteznek, tehát a példánál maradva, ha a nagyobb pizzára drágább az extra feltét, akkor
          oda magasabb árú feltéteket adhat hozzá, mint az alap verzióhoz.
        </p>
        <imagetitle>Példa</imagetitle>
        <img alt={""} src={extras} />
        <p>
          <b>4. Menük létrehozás, szerepük</b><br />
        </p>
        <p>
          Menüket kiemelt ajánlatként kezeljük, ezeket a Vásárlói oldalon legfelül mutatjuk. Már feltöltött termékeket
          kapcsolhat összes egy csomagba és adhat az egész csomagra egy ajánlatot.
        </p>
        <imagetitle>FONTOS: Képekkel ellátott ételeit az Blundee főoldalán kiemeljük.</imagetitle>
        <img alt={""} src={menu} />
        <h3>B/3. Beállítások És Étterem bezárása</h3>
        <p>
          Fizetési módjait az oldalon kapcsolók segítségével állíthatja be.
        </p>
        <p>
          Helyszíni bankkártya = Futárnál, étteremben van bank terminál<br />
          Helyszíni szép kártyák = Futár, étterem elfogadja a kiválasztott szépkártyákat<br />
          Online fizetés = Bankkártyás illetve szép kártyás fizetés Online az Blundee-n keresztül<br />
        </p>
        <p>
          <b>Mára Bezárok gomb:</b><br />
          Váratlan esemény miatt az Éttermet bezárva mutatja a vásárlóknak, visszakapcsolásig a rendelés leadása nem
          lehetséges. Újra kattintva a gombra megnyitja az éttermet.
        </p>
        <imagetitle>Jelmagyarázat</imagetitle>
        <img alt={""} src={settings} />
      </Wrapper>
    );
  }
}
