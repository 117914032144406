export default class ErrorMessage {
  static OK: number = 0;

  static WRONG_EMAIL_OR_PASS: number = 1001;
  static PERMISSION_DENIED: number = 1002;
  static PARAMETER_NOT_VALID: number = 1003;
  static DATA_NOT_FOUND: number = 1004;
  static AMBIGUOUS: number = 1005;
  static UNEXPECTED_ERROR: number = 1006;
  static EMAIL_NOT_VALID: number = 1007;
  static BODY_NOT_PARSABLE: number = 1008;
  static TOKEN_NOT_VALID: number = 1009;
  static DATABASE_ERROR: number = 1010;
  static ILLEGAL_STATE: number = 1011;
  static EMAIL_REGISTERED: number = 1012;
  static SHIPPING_PRICE_NOT_FOUND: number = 1013;
  static RESTAURANT_NOT_FOUND: number = 1014;
  static RESTAURANT_CLOSED_FOR_TODAY: number = 1015;
  static RESTAURANT_CLOSED: number = 1016;
  static DOESNT_HIT_MINIMUM_ORDER: number = 1017;
  static PRODUCT_NOT_FOUND: number = 1018;
  static EXTRA_QTY_OVER_MAX: number = 1019;
  static CITY_NOT_FOUND: number = 1020;
  static MIXED_PRODUCTS: number = 1021;
  static ADDRESS_PERMISSION_DENIED: number = 1022;
  static ADDRESS_NOT_FOUND: number = 1023;
  static ZIPCODE_NOT_FOUND: number = 1024;
  static CURRENT_PASSWORD_INVALID: number = 1025;
  static NEW_PASSWORD_TOO_SHORT: number = 1026;
  static NO_SHIPPING_TO_THIS_ADDRESS: number = 1027;
  static ORDER_NOT_FOUND: number = 1028;
  static TOO_FREQUENT_REQUESTS: number = 1029;
  static FIRSTNAME_NOT_VALID: number = 1030;
  static LASTNAME_NOT_VALID: number = 1031;
  static TEL_NOT_VALID: number = 1032;
  static CANT_DELETE_DATA_FOUND: number = 1034;
  static MENU_NOT_REMOVABLE: number = 1035;
  static PENDING_OR_VALID_CONTRACT_IS_AVAILABLE: number = 1036;
  static PROFILE_NOT_FOUND: number = 1037;
  static CANT_DELETE_EXTRA_PRODUCT_USE_IT: number = 1042;
  static TABLE_QR_USED: number = 1043;
  static PREORDER_MAX_7_DAYS: number = 1047;
  static PAYMENT_METHOD_NOT_VALID: number = 1048;
  static PIN_NOT_VALID: number = 1049;
  static CANCELLATION_ORDER_MIXED = 1050;
  static NO_TABLE_AVAILABLE = 1052;
  static CANT_DELETE_RAW_PRODUCT_USE_IT = 1053;
  static CANT_DELETE_RAW_RECIPE_USE_IT = 1054;
  static CANT_DELETE_RECIPE_PRODUCT_USE_IT = 1055;
  static RESERVATION_NOT_FOUND = 1056;
  static RESERVATION_CANNOT_BE_CANCELLED = 1057;
  static COUPON_CODE_ALREADY_IN_USE = 1058;
  static RESTAURANT_DOESNT_TAKE_ORDERS_YET = 1059;
  static PRINTER_NOT_FOUND = 1060;
  static NOT_ENOUGH_QTY_TO_MOVE = 1061;
}
