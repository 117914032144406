import React, { Component } from "react";
import styled from "styled-components";
import { PageContainer } from "../components/PageContainer";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import EventSystem from "../utils/EventSystem";
import { ActivateAPI } from "../utils/api/ActivateAPI";
import { toast } from "react-toastify";
import ContextSystem from "../utils/ContextSystem";
import Language, { Names } from "../utils/Language";

export const Wrapper = styled.div`
  width: 100%;
  min-height: ${({ minheight }) => (minheight ? minheight : "70vh")};

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

export const ActivationPanel = styled.div`
  max-width: 600px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;
  //border-radius: 8px;
  //box-shadow: 0 0 10px 1px dimgray;

  & > h1 {
    margin-bottom: 30px;
    font-family: "Segoe UI Light", sans-serif;
    font-size: 28pt;
    width: 100%;
  }

  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    & > p {
      margin: 0;
      padding: 0 0 0 5px;
      width: 70%;
      text-align: left;
    }
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Input = styled.input`
  width: ${({ width }) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

export const Button = styled.div`
  border: 1px solid #931c20;
  border-radius: 5px;
  padding: 8px 20px;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  margin: 5px 20% 15px 20%;
  text-align: center;
  transition: height 0.2s ease-in-out;
  color: #a62024;

  &:hover,
  &:active {
    background-color: #a62024;
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

class ActivatePage extends Component {
  eventIDs = [];

  state: {
    editPass: string,
    activationPassphrase: string,
    language: number
  } = {
    editPass: "",
    activationPassphrase: "",
    // passphraseValid: true,
    language: ContextSystem.language,
  };

  activateOnEnter(e) {
    if (e.keyCode === 13)
      this.activate();
  }

  activate() {
    if (this.state.editPass.length < 5) {
      toast(Language.getName(Names.PasswordTooShort));
      return;
    }
    ActivateAPI.activate(this.state.activationPassphrase, this.state.editPass, (authenticated) => {
      this.redirect(authenticated);
    });
  }

  redirect(authenticated) {
    if (authenticated)
      this.props.history.push("/");
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    this.eventIDs = [];
  }

  componentDidMount() {
    let passphrase = this.props.match.params.passphrase;
    this.setState({ activationPassphrase: passphrase });

    // noinspection DuplicatedCode
    this.eventIDs = [];
    this.redirect(ContextSystem.loggedIn);

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ loggedIn, language }) => {
      if (loggedIn !== undefined) {
        this.redirect(loggedIn);
      }

      if (language !== undefined) {
        this.setState({ language });
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>{Language.getName(Names.EnniAkarokActivatePageTitle)}</title>
          <meta name="description" content={Language.getName(Names.EnniAkarokActivatePageDesc)} />
        </Helmet>
        <Wrapper>
          <ActivationPanel>
            <h1>{Language.getName(Names.ActivateTitle)}</h1>
            <form id={"activate"} method={"POST"} onSubmit={event => event.preventDefault()}>
              <p>{Language.getName(Names.ActivateDesc)}</p>
              <Input
                autoComplete="new-password"
                value={this.state.editPass}
                onKeyUp={(e) => this.activateOnEnter(e)}
                type="password"
                placeholder={Language.getName(Names.ProfileNewPasswordPlaceholderText)}
                onChange={(e) => this.setState({ editPass: e.target.value })}
              />
              <Button onClick={() => this.activate()}>{Language.getName(Names.ActivateBtnText)}</Button>
            </form>
          </ActivationPanel>
        </Wrapper>
      </PageContainer>
    );
  }
}

export default withRouter(ActivatePage);
