import APICaller, { APIMsgBase } from "../APICaller";
import { SavedStorageMovement, Storage } from "../../model/Stock";

function save(storageMovement: SavedStorageMovement, cb: (res: APIMsgBase)=>{}) {
  let storageMovement2: Storage = { ...storageMovement };
  storageMovement2.addedDate = storageMovement2.addedDate.getTime();

  let method = storageMovement2.id > 0 ? "POST" : "PUT";

  APICaller.ownFetch(false, method, "/storage/move", { storageMovement: storageMovement2 }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(savedMovementID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/storage/move", { id: savedMovementID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type GetSavedStorageMovementsResponseType = APIMsgBase & { savedStorageMovements: SavedStorageMovement[] };

function getSavedStorageMovements(cb: (res: GetSavedStorageMovementsResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/storage/moves", {}, (res) => {
    let result: GetSavedStorageMovementsResponseType = JSON.parse(res);
    if (result.error === 0)
      result.savedStorageMovements.forEach(r => r.addedDate = new Date(r.addedDate));
    if (cb)
      cb(result);
  });
}

export const SavedStorageMovementAPI = {
  getSavedStorageMovements,
  save,
  remove,
};
