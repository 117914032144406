import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import { Product } from "../../model/Product";
import { FaPen, FaPlus, FaTrashAlt } from "react-icons/all";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { UpsellRulesAPI } from "../../utils/api/UpsellRulesAPI";
import { UpsellRule } from "../../model/UpsellRule";
import Orders from "./Orders";
import { AddButtonWrapper, Controls, Div, ListWrapper, ProductDiv, Wrapper } from "./Extras";
import { PaymentMethods } from "../../model/PaymentMethodSetting";
import ConfirmationModal from "../modals/ConfirmationModal";

export default class UpsellRules extends Component {
  eventIDs: number[] = [];

  state: {
    upsellRules: UpsellRule[],
    products: Product[],
    language: number,
  } = {
    upsellRules: ContextSystem.upsellRules,
    products: [],
    language: [],
  };

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  loadContext() {
    this.setState({
      upsellRules: ContextSystem.upsellRules.filter(
        ur => ur.enabled && ur.shopIDs.find(s => s.modelID === ContextSystem.selectedShop?.id)),
      products: ContextSystem.products,
    });
  }

  componentDidMount() {
    this.loadContext();

    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, upsellRules, products }) => {
      if (language !== undefined)
        this.setState({ language });
      if (upsellRules !== undefined || products !== undefined)
        this.loadContext();
    });
    this.eventIDs.push(eid);
  }

  handleRemoveButton(upsellRule: UpsellRule) {
    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {
      },
      () => {
        UpsellRulesAPI.remove(upsellRule.id, (res) => {
          if (res.error !== 0)
            return;
          toast(Language.getName(Names.Removed));
          this.loadContext();
        });
      },
    );
  }

  render() {
    return (
      <Wrapper>
        <AddButtonWrapper>
          <div onClick={() => EventSystem.publish(EventSystem.events.edit_upsell_rule)}>
            <FaPlus />
            <span>{Language.getName(Names.AddButtonText)}</span>
          </div>
        </AddButtonWrapper>
        <ListWrapper>
          {this.state.upsellRules.map((upsellRule, i) => {
            return (
              <ProductDiv key={i}>
                <Div width={"10%"}>
                  <b>{Language.getName(Names.DateCreated)}:</b>
                  <br />
                  {upsellRule?.date?.toLocaleDateTime()}
                </Div>
                <Div width={"20%"}>
                  <b>{Language.getName(Names.TimeOfOfferAppearance)}:</b>
                  <br />
                  {Orders.getUpsellRuleTypeString(upsellRule.type)}
                </Div>
                <Div width={"20%"}>
                  <b>{Language.getName(Names.PaymentMethod)}:</b>
                  <br />
                  {PaymentMethods.getName(upsellRule.paymentMode)}
                </Div>
                <Div width={"20%"}>
                  <b>{Language.getName(Names.ShippingMode)}:</b>
                  <br />
                  {Orders.getShippingMethodString(upsellRule.shippingMode)}
                </Div>
                <Div width={"20%"}>
                  <b>{Language.getName(Names.Products)}:</b>
                  <br />
                  {upsellRule.productIDs.map((pid, i) => {
                    let product: Product = ContextSystem.products.find(pr => pr.id === pid);
                    if (!product)
                      return <React.Fragment key={i} />;
                    return (
                      <React.Fragment key={i}>
                        <span>{product.name}</span>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </Div>
                <Controls>
                  <div onClick={() => EventSystem.publish(EventSystem.events.edit_upsell_rule, upsellRule)}>
                    <FaPen />
                    <span>{Language.getName(Names.Modify)}</span>
                  </div>
                  <div
                    onClick={() => this.handleRemoveButton(upsellRule)}
                  >
                    <FaTrashAlt />
                    <span>{Language.getName(Names.Remove)}</span>
                  </div>
                </Controls>
              </ProductDiv>
            );
          })}
        </ListWrapper>
      </Wrapper>
    );
  }
}
