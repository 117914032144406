import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import { Product, Raw, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import type { QtyType } from "../../model/Stock";
import { Qty, QtyTypes, SavedStorageMovement, Storage } from "../../model/Stock";
import { Select, Table, TableCell, TableRow } from "./InwardHandlingModal";
import { FaCheck, FaTimes, FaTrash, TbArrowRightBar, TbArrowRightCircle, TbBuildingWarehouse } from "react-icons/all";
import type { Option } from "./OrderStateCommentEditor";
import ToggleButton from "react-toggle-button";
import styled from "styled-components";
import { RawAPI } from "../../utils/api/RawAPI";
import { toast } from "react-toastify";
import { Shop } from "../../model/Shop";
import Config from "../../utils/Config";
import { SavedStorageMovementAPI } from "../../utils/api/SavedStorageMovementAPI";
import ErrorMessage from "../../utils/api/ErrorMessages";
import { ShopProfile, ShopProfileTypes } from "../../model/ShopProfile";

export type MoveQty = {
  qtyID: number,
  modelID: number,
  type: QtyType,
  moveQty: number,
}

export const AssignedSelect = styled(Select)`
  margin: 0 3px;
  width: 150px;
  font-size: 10pt;
  padding: 3px 6px;
`;

export const SaveSection = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid #efefef;
  justify-self: flex-start;
`;

export const CommandSection = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
  margin-left: auto !important;
`;

const SavedNameInput = styled(Input)`
  min-height: unset;
  padding: 8px 10px;
  font-size: 10pt;
  width: 200px;
`;

const StorageSelector = styled.div`
  width: 100%;
  margin: 24px;
  font-size: 18pt;
  color: #444440;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 48pt;
    margin: 0 72px;
  }
`;

const StorageDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 48px;

  & > span {
    font-size: 28pt;
  }

  & > p {
    font-size: 20pt;
    margin: 6px 0;
  }
`;

const ManualSelector = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > p {
    margin-right: 6px;
  }
`;

const LoadSavedSelector = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > p {
    margin-right: 6px;
  }
`;

const TargetCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 14pt;
    margin: 0 6px;
  }
`;

const OriginCell = styled.div`
  padding: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    font-weight: bold;
    font-size: 12pt;
    margin: 0;
  }
`;

const OriginCellModificationRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 3px 0;

  & > p {
    margin: 0 6px 0 0;

    & > svg {
      margin: 0 6px;
      font-size: 14pt;
    }
  }
`;

const OriginalModificationTableRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 3px 0;
`;

const ModificationAutoModeSelect = styled(Select)`
  margin: 3px 3px 3px 12px;
  font-size: 9pt;
  width: 150px;
  min-height: unset;
  padding: 2px 4px;
`;

const StorageSelect = styled(Select)`
  border: none;
  font-size: 18pt;
`;

const SavedSelect = styled(Select)`
  font-size: 10pt;
  width: 200px;
  margin: 3px 6px;
`;

const ModificationTable = styled(Table)`
  width: 60%;
  font-size: 9pt;
  margin-right: 12px;
`;

const MoveQtySumInputCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    margin: 0;
  }
`;

const MoveQtySumInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ModificationInput = styled(Input)`
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 10pt;
  width: 60px;
  max-width: 60px;
  margin: 0 3px;
  padding: 3px 4px;
  min-height: unset;
`;

const MoveQtyCellInput = styled(Input)`
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 10pt;
  width: 60px;
  max-width: 80px;
  margin: 0 3px;
  padding: 3px 4px;
  min-height: unset;
`;

export default class MoveStorageModal extends Component {
  state: {
    savedStorageMovement: SavedStorageMovement,
    showModal: boolean,
    title: string,
    language: number,
    manualSelection: boolean,
    shop: Shop,
    showCount: number,

    raws: ({ qtyType: QtyType } & Raw)[],
    products: ({ qtyType: QtyType } & Product)[],
    qtyList: Qty[],
    storages: Storage[],
    savedStorageMovements: SavedStorageMovement[],

    moveQty: MoveQty[],
    selectedFrom: Option,
    selectedTo: Option,

    autoModificationOptions: Option[],
    selectedAutoModificationType: { modelID: number, type: QtyType, selected: Option }[],
    shopProfile: ShopProfile,
  } = {
    savedStorageMovement: undefined,
    showModal: false,
    title: Language.getName(Names.MoveStorage),
    language: ContextSystem.language,
    manualSelection: false,
    shop: ContextSystem.selectedShop,
    showCount: 0,

    raws: [],
    products: [],
    qtyList: [],
    storages: [],
    savedStorageMovements: [],

    moveQty: [],
    selectedFrom: { value: -1, label: Language.getName(Names.DefaultStorage) },
    selectedTo: { value: -1, label: Language.getName(Names.DefaultStorage) },

    autoModificationOptions: [],
    selectedAutoModificationType: [],
    shopProfile: ContextSystem.profile,
  };

  removeQty(type: QtyType, modelID: number) {
    let moveQty: MoveQty[] = this.state.moveQty.filter(mq => !(mq.modelID === modelID && mq.type === mq.type));
    this.setState({ moveQty });
  }

  addNewQty(id: string) {
    if (!id || id === "-1")
      return;

    let modelID = parseInt(id.substring(1));
    let modelType = id.startsWith("r") ? QtyTypes.RAW : QtyTypes.PRODUCT;

    if (!modelID || modelID <= 0)
      return;

    let model: Raw | Product;
    if (modelType === QtyTypes.RAW)
      model = this.state.raws.find(r => r.id === modelID);
    else if (modelType === QtyTypes.PRODUCT)
      model = this.state.products.find(r => r.id === modelID);

    if (!model)
      return;

    if (this.state.moveQty.find(mq => mq.modelID === modelID && mq.type === modelType))
      return;

    let selectedAutoModificationType: { modelID: number, type: QtyType, selected: Option }[]
      = this.state.selectedAutoModificationType.map(e => ({ ...e }));
    selectedAutoModificationType.push({
      modelID: modelID,
      type: modelType,
      selected: { value: 1, label: Language.getName(Names.Oldest) },
    });

    let moveQty: MoveQty[] = this.state.moveQty.map(q => ({ ...q }));
    moveQty.push({
      type: modelType,
      moveQty: 0,
      modelID: modelID,
      qtyID: -1, //not needed right now, will be updated on moveQty change
    });

    this.setState({ moveQty, selectedAutoModificationType });
  }

  deleteSaved() {
    if (!this.state.savedStorageMovement || this.state.savedStorageMovement.id <= 0) {
      toast(Language.getName(Names.PleaseSelectASavedMovement));
      return;
    }

    SavedStorageMovementAPI.remove(this.state.savedStorageMovement.id, (res) => {
      if (res.error !== ErrorMessage.OK)
        return;

      this.setState({ savedStorageMovement: undefined });
    });
  }

  save() {
    if (!this.state.selectedFrom || !this.state.selectedTo || !this.state.moveQty || this.state.moveQty.length <= 0)
      return;

    if (this.state.selectedTo.value === this.state.selectedFrom.value)
      return;

    let saveStorageMovement: SavedStorageMovement = this.state.savedStorageMovement;
    if (!saveStorageMovement) {
      toast(Language.getName(Names.PleaseEnterASavingName));
      return;
    }

    saveStorageMovement.addedDate = new Date();
    saveStorageMovement.fromID = this.state.selectedFrom.value;
    saveStorageMovement.toID = this.state.selectedTo.value;
    saveStorageMovement.shopID = ContextSystem.selectedShop.id;
    saveStorageMovement.moveQtyList = this.state.moveQty;
    saveStorageMovement.enabled = true;

    SavedStorageMovementAPI.save(saveStorageMovement, res => {
      if (res.error !== ErrorMessage.OK)
        return;

      this.setState({
        showModal: false,
        saveStorageMovement: undefined,
        moveQty: [],
      });
    });
  }

  move() {
    if (!this.state.selectedFrom || !this.state.selectedTo || !this.state.moveQty || this.state.moveQty.length <= 0)
      return;

    if (this.state.selectedTo.value === this.state.selectedFrom.value)
      return;

    let moveQty: MoveQty[] = this.state.moveQty.filter(mq => mq.qtyID > 0);

    RawAPI.moveStorage(this.state.selectedFrom.value, this.state.selectedTo.value, moveQty, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false, savedStorageMovement: undefined });

      if (this.state.savedStorageMovement && this.state.savedStorageMovement.id > 0) {
        this.deleteSaved();
      }
    });
  }

  loadContext() {
    this.setState({
      raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      products: ContextSystem.products.filter(q => q.enabled && q.partnerID === ContextSystem.selectedShop?.id),
      qtyList: ContextSystem.qtyList.filter(
        q => q.enabled && q.profileID === ContextSystem.selectedShop?.id && !q.finished),
      storages: ContextSystem.storages.filter(r => r.enabled && r.shopIDs.includes(ContextSystem.selectedShop?.id)),
      savedStorageMovements: ContextSystem.savedStorageMovements.filter(
        e => e.enabled && e.shopID === ContextSystem.selectedShop.id),
      shop: ContextSystem.selectedShop,
      shopProfile: ContextSystem.profile,
    });
  }

  componentDidMount() {
    this.loadContext();

    EventSystem.subscribe(EventSystem.events.start_move_storage, () => {
      this.loadContext();

      let autoModificationOptions: Option[] = [];
      autoModificationOptions.push({ value: 1, label: Language.getName(Names.Oldest) });
      autoModificationOptions.push({ value: 2, label: Language.getName(Names.Newest) });

      let selectedTo: Option = {
        value: ContextSystem.storages[0].id,
        label: ContextSystem.storages[0].name,
      };

      this.setState({
          showCount: this.state.showCount++,
          savedStorageMovement: undefined,
          showModal: true,
          manualSelection: false,
          moveQty: [],
          autoModificationOptions,
          selectedAutoModificationType: [],
          selectedTo,
        }, () => this.changeRequestName(
          new Date().toLocaleDateString() + " - " + ContextSystem.profile?.firstName + (ContextSystem.profile?.lastName?.length > 0
                                                                                        ? " " + ContextSystem.profile?.lastName?.substring(
            0, 1) + "."
                                                                                        : "")),
      );
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, raws, qtyList, products, selectedShop, storages, savedStorageMovements, profile }) => {
        if (language !== undefined)
          this.setState({ language });
        if (this.state.showModal) {
          if (profile !== undefined || raws !== undefined || products !== undefined || qtyList !== undefined || selectedShop !== undefined || storages !== undefined || savedStorageMovements !== undefined)
            this.loadContext();
        }
      },
    );
  }

  changeStorage(from: boolean, e: Option) {
    if (from)
      this.setState({ selectedFrom: e });
    else
      this.setState({ selectedTo: e });

    if (from) {
      let moveQty: MoveQty[] = this.state.moveQty.filter(mq => {
        let sum: number = Qty.countSum(this.state.qtyList, mq.type, mq.modelID, e.value);
        return sum > 0;
      });
      this.setState({ moveQty });
    }
  }

  changeAutoModificationType(modelID: number, type: QtyType, o: Option) {
    let selectedAutoModificationType: { modelID: number, type: QtyType, selected: Option }[] = [];

    this.state.selectedAutoModificationType.forEach(amt => {
      let e: { modelID: number, type: QtyType, selected: Option } = { ...amt };
      if (e.modelID === modelID && e.type === type)
        e.selected = o;

      selectedAutoModificationType.push(e);
    });

    this.setState({ selectedAutoModificationType });
    this.changeMoveQtySum(modelID, type, -1);
  }

  changeMoveQty(qtyID: number, newValue: string) {
    let value: number = 0;

    if (newValue.length > 0 && !isNaN(parseFloat(newValue)))
      value = parseFloat(newValue);

    let qty: Qty = this.state.qtyList.find(q => q.id === qtyID);
    if (!qty)
      return;

    let moveQty: MoveQty[] = this.state.moveQty.filter(mq => mq.qtyID !== qty.id).map(mq => ({ ...mq }));
    moveQty.push({
      type: qty.type,
      qtyID: qty.id,
      modelID: qty.modelID,
      moveQty: value,
    });
    this.setState({ moveQty });
  }

  changeMoveQtySum(modelID: number, type: QtyType, newValue: string = -1) {
    let value: number = 0;

    if (newValue === -1) {
      let mqs: MoveQty[] = this.state.moveQty.filter(mq => mq.qtyID > 0 && mq.type === type && mq.modelID === modelID);
      let moveSum: number = 0;
      mqs.forEach(mq => moveSum += mq.moveQty);
      value = moveSum;
    } else if (newValue.length > 0 && !isNaN(parseFloat(newValue)))
      value = parseFloat(newValue);

    let moveQty: MoveQty[] = this.state.moveQty.filter(
      mq => mq.qtyID === -1 || !(mq.modelID === modelID && mq.type === type)).map(mq => ({ ...mq }));

    let qtyListFrom: Qty[] = this.state.qtyList.filter(
      q => q.modelID === modelID && q.type === type && q.storageID === this.state.selectedFrom.value);

    qtyListFrom = qtyListFrom.sort((a, b) => a.addedDate.getTime() - b.addedDate.getTime());
    let selectedAutoType: Option = this.state.selectedAutoModificationType.find(
      a => a.modelID === modelID && a.type === type)?.selected;
    if (selectedAutoType?.value === 2)
      qtyListFrom = qtyListFrom.reverse();

    for (let qty: Qty of qtyListFrom) {
      let remainingQty: number = Qty.countSum([qty], type, modelID, this.state.selectedFrom.value);
      if (remainingQty <= 0)
        continue;

      let reducePlan: number = 0;
      if (value >= remainingQty)
        reducePlan = remainingQty;
      else if (value < remainingQty)
        reducePlan = value;

      moveQty.push({
        type: type,
        qtyID: qty.id,
        modelID: modelID,
        moveQty: reducePlan,
      });
      value -= reducePlan;

      if (value <= 0)
        break;
    }

    this.setState({ moveQty });
  }

  changeRequestName(value: string) {
    let savedStorageMovement: SavedStorageMovement = this.state?.savedStorageMovement;
    if (!savedStorageMovement) {
      savedStorageMovement = {
        name: value,
        id: -1,
        enabled: true,
        addedDate: new Date(),
        moveQtyList: [],
        toID: -1,
        fromID: -1,
        shopID: ContextSystem.selectedShop.id,
        note: "",
        assignedShopProfileID: -1,
      };
    }
    savedStorageMovement.name = value;
    this.setState({ savedStorageMovement });
  }

  selectSavedAssign(id: number) {
    let savedStorageMovement: SavedStorageMovement = this.state.savedStorageMovement;
    if (!savedStorageMovement) {
      savedStorageMovement = {
        name: "",
        id: -1,
        enabled: true,
        addedDate: new Date(),
        moveQtyList: [],
        toID: -1,
        fromID: -1,
        shopID: ContextSystem.selectedShop.id,
        note: "",
        assignedShopProfileID: id,
      };
    }
    savedStorageMovement.assignedShopProfileID = id;
    this.setState({ savedStorageMovement });
  }

  loadSaved(e: Option) {
    if (e.value === -1) {
      this.setState({ savedStorageMovement: undefined });
      return;
    }

    let savedStorageMovement: SavedStorageMovement = this.state.savedStorageMovements.find(s => s.id === e.value);

    if (!savedStorageMovement)
      return;

    let selectedFrom: Option = {
      value: savedStorageMovement.fromID,
      label: savedStorageMovement.fromID === -1 ? Language.getName(Names.DefaultStorage) : this.state.storages.find(
        (st: Storage) => st.id === savedStorageMovement.fromID)?.name,
    };

    let selectedTo: Option = {
      value: savedStorageMovement.toID,
      label: savedStorageMovement.toID === -1 ? Language.getName(Names.DefaultStorage) : this.state.storages.find(
        (st: Storage) => st.id === savedStorageMovement.toID)?.name,
    };

    this.setState({
      savedStorageMovement,
      selectedFrom,
      selectedTo,
      moveQty: savedStorageMovement.moveQtyList,
    });
  }

  render() {
    let options: Option[] = [];
    let assignedOptions: Option[] = [];
    let selectedAssigned: Option = undefined;

    if (this.state.shop?.employees) {
      this.state.shop.employees.filter(e => {
        let ret: boolean = e.enabled && !(e.firstName.trim().length <= 0 && e.lastName.trim().length <= 0);
        if (!Config.TEST)
          ret = ret && (!e.email.toLowerCase().trim().endsWith("enniakarok.hu") && !e.email.toLowerCase().trim()
            .endsWith("blundee.com"));
        return ret;
      }).forEach(e => assignedOptions.push({
        value: e.id,
        label: e.firstName + " " + e.lastName,
      }));
      if (this.state.savedStorageMovement?.assignedShopProfileID) {
        for (let employee of this.state.shop.employees) {
          if (employee.id === this.state.savedStorageMovement.assignedShopProfileID) {
            selectedAssigned = {
              value: employee.id,
              label: employee.firstName + " " + employee.lastName,
            };
            break;
          }
        }
      }
    }

    options.push({ value: "-1", label: "-----" + Language.getName(Names.Products) + "-----" });
    this.state.products.forEach(pr => {
      //"p" means product, used in addNewQty
      let sum: number = Qty.countSum(this.state.qtyList, QtyTypes.PRODUCT, pr.id, this.state.selectedFrom.value);
      if (
        sum <= 0
        || !pr.enabled
        || !pr.countStock
        || this.state.moveQty.find(mq => mq.modelID === pr.id && mq.type === QtyTypes.PRODUCT) !== undefined
      )
        return;

      options.push({ value: "p" + pr.id, label: TranslatableString.get(pr.name) });
    });
    options.push({ value: "-1", label: "-----" + Language.getName(Names.ProcessedProducts) + "-----" });
    this.state.raws.forEach(r => {
      //"r" means raw, used in addNewQty
      let sum: number = Qty.countSum(this.state.qtyList, QtyTypes.RAW, r.id, this.state.selectedFrom.value);
      if (
        sum <= 0
        || !r.enabled
        || !r.semiProduct
        || this.state.moveQty.find(mq => mq.modelID === r.id && mq.type === QtyTypes.RAW) !== undefined
      )
        return;

      options.push({ value: "r" + r.id, label: TranslatableString.get(r.name) });
    });
    options.push({ value: "-1", label: "-----" + Language.getName(Names.Raws) + "-----" });
    this.state.raws.forEach(r => {
      //"r" means raw, used in addNewQty
      let sum: number = Qty.countSum(this.state.qtyList, QtyTypes.RAW, r.id, this.state.selectedFrom.value);
      if (
        sum <= 0
        || !r.enabled
        || r.semiProduct
        || this.state.moveQty.find(mq => mq.modelID === r.id && mq.type === QtyTypes.RAW) !== undefined
      )
        return;

      options.push({ value: "r" + r.id, label: TranslatableString.get(r.name) });
    });

    let storageOptions: Option[] = this.state.storages.map(st => ({ value: st.id, label: st.name }));
    storageOptions.unshift({ value: -1, label: Language.getName(Names.DefaultStorage) });

    let savedOptions: Option[] = this.state.savedStorageMovements.map(st => {
      let label: string = st.name;
      if (st.assignedShopProfileID > 0 && this.state.shop?.employees) {
        let e: ShopProfile = this.state.shop.employees.find(e => e.id === st.assignedShopProfileID);
        if (e)
          label += " | " + e.firstName + " " + e.lastName;
      }
      return { value: st.id, label };
    });
    let savedOptionSelected: Option = undefined;
    if (this.state.savedStorageMovement) {
      savedOptionSelected = {
        value: this.state.savedStorageMovement.id,
        label: this.state.savedStorageMovement.name,
      };
      savedOptions.unshift({ value: -1, label: Language.getName(Names.Unselect) });
    }

    let rows: { modelID: number, type: QtyType }[] = [];
    this.state.moveQty.forEach(mq => {
      if (rows.find(r => r.modelID === mq.modelID && r.type === mq.type) !== undefined)
        return;
      rows.push({ modelID: mq.modelID, type: mq.type });
    });

    return (
      <Modal size={"xl"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          {this.state.savedStorageMovements.length > 0 &&
            <LoadSavedSelector>
              <SavedSelect
                placeholder={Language.getName(Names.LoadSaved)}
                onChange={(e) => this.loadSaved(e)}
                options={savedOptions}
                value={savedOptionSelected}
              />
            </LoadSavedSelector>
          }
          <ManualSelector>
            <p
              onClick={() => this.setState({ manualSelection: !this.state.manualSelection })}>
              {Language.getName(Names.ManualSelection)}
            </p>
            <ToggleButton
              inactiveLabel={<FaTimes />}
              activeLabel={<FaCheck />}
              value={this.state.manualSelection}
              onToggle={() => this.setState({ manualSelection: !this.state.manualSelection })}
            />
          </ManualSelector>
        </Modal.Header>
        {this.state.showModal &&
          <Modal.Body>
            <StorageSelector>
              <StorageDiv>
                <span><TbBuildingWarehouse /></span>
                <StorageSelect
                  placeholder={Language.getName(Names.Storages)}
                  onChange={(e) => this.changeStorage(true, e)}
                  options={storageOptions}
                  value={this.state.selectedFrom}
                />
              </StorageDiv>
              <TbArrowRightCircle />
              <StorageDiv>
                <span><TbBuildingWarehouse /></span>
                <StorageSelect
                  placeholder={Language.getName(Names.Storages)}
                  onChange={(e) => this.setState({ selectedTo: e })}
                  options={storageOptions}
                  value={this.state.selectedTo}
                />
              </StorageDiv>
            </StorageSelector>
            <Table>
              <TableRow header={true}>
                <TableCell width={"40px"}>#</TableCell>
                <TableCell width={"calc(60% - 40px)"}>
                  {Language.getName(Names.Send)}
                </TableCell>
                <TableCell width={"calc(40% - 40px)"}>
                  {Language.getName(Names.Accept2)}
                </TableCell>
                <TableCell width={"40px"} c_cursor={true} />
              </TableRow>
              {rows.map((r, i) => {
                let mqs: MoveQty[] = this.state.moveQty.filter(
                  mq => mq.qtyID > 0 && mq.type === r.type && mq.modelID === r.modelID);
                let model: Raw | Product = this.state.products.find(m => m.id === r.modelID);
                if (r.type === QtyTypes.RAW) {
                  model = this.state.raws.find(m => m.id === r.modelID);
                }

                let moveSum: number = 0;
                mqs.forEach(mq => moveSum += mq.moveQty);

                let fromSumOrg: number = Qty.countSum(this.state.qtyList, r.type, r.modelID,
                  this.state.selectedFrom.value,
                );
                let fromSumTarget: number = fromSumOrg - moveSum;
                let toSumOrg: number = Qty.countSum(this.state.qtyList, r.type, r.modelID, this.state.selectedTo.value);
                let toSumTarget: number = toSumOrg + moveSum;

                let unit: string = model.unit ? TranslatableString.get(model.unit) : Language.getName(Names.pcs);
                let selectedAutoType: Option = this.state.selectedAutoModificationType.find(
                  a => a.modelID === r.modelID && a.type === r.type)?.selected;

                let qtys: Qty[] = this.state.qtyList.filter(
                  q => q.modelID === r.modelID && q.type === r.type && !q.finished && q.enabled && q.storageID === this.state.selectedFrom.value);

                return (
                  <TableRow key={i}>
                    <TableCell width={"40px"}>#{(i + 1)}</TableCell>
                    <TableCell width={"calc(60% - 40px)"}>
                      <OriginCell>
                        <p>{TranslatableString.get(model.name)}</p>
                        <OriginCellModificationRow>
                          <p>
                            {fromSumOrg} {unit} <TbArrowRightBar /> {fromSumTarget} {unit}
                          </p>
                          <ModificationAutoModeSelect
                            isDisabled={this.state.manualSelection}
                            options={this.state.autoModificationOptions}
                            value={selectedAutoType}
                            placeholder={Language.getName(Names.Choose)}
                            onChange={e => this.changeAutoModificationType(r.modelID, r.type, e)}
                          />
                        </OriginCellModificationRow>
                        <OriginalModificationTableRow>
                          <ModificationTable>
                            <TableRow header={true}>
                              <TableCell width={"35px"}>#</TableCell>
                              <TableCell width={"100px"} grow={2}>{Language.getName(Names.Added)}</TableCell>
                              <TableCell width={"100px"}>{Language.getName(Names.Remaining)}</TableCell>
                              <TableCell width={"100px"}>{Language.getName(Names.Move)}</TableCell>
                            </TableRow>
                            {qtys.map((q: Qty, j) => {
                              let remainingQty: number = Qty.countSum([q], q.type, q.modelID, q.storageID);
                              let moveQty: MoveQty = mqs.find(mq => mq.qtyID === q.id);
                              return (
                                <TableRow key={j}>
                                  <TableCell width={"35px"}>#{q.id}</TableCell>
                                  <TableCell width={"100px"} grow={2}>{q.addedDate.toHunFormatMinutes()}</TableCell>
                                  <TableCell width={"100px"}>{remainingQty} {unit}</TableCell>
                                  <TableCell width={"100px"}>
                                    <MoveQtyCellInput
                                      disabled={this.state.manualSelection === false}
                                      value={moveQty?.moveQty ?? 0}
                                      type={"text"}
                                      onChange={e => this.changeMoveQty(q.id, e.target.value)}
                                      placeholder={"0"}
                                    />
                                    {unit}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </ModificationTable>
                          <MoveQtySumInputCell>
                            <p>{Language.getName(Names.TotalMoveQty)}</p>
                            <MoveQtySumInput width={"100%"}>
                              <ModificationInput
                                value={moveSum}
                                disabled={this.state.manualSelection}
                                type={"text"}
                                placeholder={"0"}
                                onChange={(e) => this.changeMoveQtySum(r.modelID, r.type, e.target.value)}
                              />
                              {unit}
                            </MoveQtySumInput>
                          </MoveQtySumInputCell>
                        </OriginalModificationTableRow>
                      </OriginCell>
                    </TableCell>
                    <TableCell width={"calc(40% - 40px)"}>
                      <TargetCell>
                        {toSumOrg} {unit} <TbArrowRightBar /> {toSumTarget} {unit}
                      </TargetCell>
                    </TableCell>
                    <TableCell width={"40px"} c_cursor={true}>
                      <FaTrash onClick={() => this.removeQty(r.type, r.modelID)} />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell width={"100%"}>
                  <Select
                    options={options}
                    placeholder={Language.getName(Names.Choose)}
                    noOptionsMessage={() => {
                      Language.getName(Names.NoProductToShow);
                    }}
                    onChange={(e) => this.addNewQty(e.value)}
                  />
                </TableCell>
              </TableRow>
            </Table>
          </Modal.Body>
        }
        <Modal.Footer>
          {(
              this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                ShopProfileTypes.REQUEST_STORAGE_MOVEMENT)
              || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MOVE_STORAGE)
            )
            &&
            <SaveSection>
              <SavedNameInput
                type="text"
                value={this.state.savedStorageMovement?.name ?? ""}
                placeholder={Language.getName(Names.RequestName) + "..."}
                onChange={e => this.changeRequestName(e.target.value)}
              />
              <AssignedSelect
                key={this.state.showCount}
                value={selectedAssigned}
                options={assignedOptions}
                placeholder={Language.getName(Names.Assigned)}
                onChange={(e) => this.selectSavedAssign(e.value)}
              />
              <Button variant="primary"
                      onClick={() => this.save()}
                      margin={"0 3px"}
                      padding={"11px 10px"}
              >
                {Language.getName(Names.Request)}
              </Button>
              {this.state.savedStorageMovement && this.state.savedStorageMovement.id > 0 &&
                <Button variant="primary"
                        onClick={() => this.deleteSaved()}
                        margin={"0 3px"}
                        padding={"11px 10px"}
                >
                  {Language.getName(Names.Remove)}
                </Button>
              }
            </SaveSection>
          }
          <CommandSection>
            <Button variant="secondary" onClick={() => this.setState({ showModal: false })} margin={"0 6px"}>
              {Language.getName(Names.Close)}
            </Button>
            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MOVE_STORAGE) &&
              <Button variant="primary" onClick={() => this.move()}>
                {Language.getName(Names.Move)}
              </Button>
            }
          </CommandSection>
        </Modal.Footer>
      </Modal>
    );
  }
}
