import React, { Component } from "react";
import styled from "styled-components";
import ContextSystem from "../utils/ContextSystem";
import Config, { ConfigKeys } from "../utils/Config";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";
import { Shop } from "../model/Shop";
import Layout from "../model/Layout";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-self: center;

  width: 100%;
  height: 30px;

  background-color: #ffef42;
  z-index: 3;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const AppVersion = styled.span`
  font-size: 9pt;
  margin-left: 12px;
  margin-right: 3px;
  font-weight: normal;
`;

const Copyright = styled.div`
  color: #444440;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;

  & > span > a {
    text-decoration: underline;
    margin-left: 5px;
    color: #444440;
  }
`;

export default class Footer extends Component {
  eventIDs: number[] = [];

  state: {
    shop: Shop | undefined,
    language: number,
    layout: number
  } = {
    shop: undefined,
    language: ContextSystem.language,
    layout: ContextSystem.layout,
  };

  static getYear() {
    return new Date().getFullYear();
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ selectedShop, layout, language }) => {
      if (selectedShop !== undefined) {
        this.setState({ shop: selectedShop });
      }
      if (language !== undefined) {
        this.setState({ language });
      }
      if (layout !== undefined) {
        this.setState({ layout });
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    if (this.state.layout !== Layout.MANAGER)
      return <></>;

    return (
      <Wrapper>
        <Copyright>
          <span>
            {this.state.shop &&
              <>
                {Language.getName(Names.RestaurantPageText) + ":"}
                <a href={Config.frontendURL + "/etterem/" + this.state.shop.url} target={"_blank"} rel={"noreferrer"}>
                  {this.state.shop.name}
                </a>
              </>
            }
            {" © 2020"}
            {Footer.getYear() > 2020 ? "-" + Footer.getYear() : ""} Blundee -
            <a href={"mailto:"+ Config.getString(ConfigKeys.partner_footer_mail)}>{Config.getString(ConfigKeys.partner_footer_mail)}</a>,
            <a href={"tel:" + Config.getString(ConfigKeys.partner_footer_phone)}>{Config.getString(ConfigKeys.partner_footer_phone)}</a>
            <AppVersion>v{Config.version}</AppVersion>
          </span>
        </Copyright>
      </Wrapper>
    );
  }
}
