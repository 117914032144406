// noinspection JSUnusedGlobalSymbols
export default class CrossEventType {
  static ORDER_CHANGED: number = 0;
  static PRODUCT_CHANGED: number = 1;
  static MESSAGE_RECEIVED: number = 2;
  static JOB_ACCEPTED: number = 3;
  static CATEGORY_CHANGED: number = 4;
  static SHOP_CLOSED: number = 5;
  static SHOP_OPENED: number = 6;
  static LOGGED_OUT: number = 7;
  static RAW_CHANGED: number = 8;
  static RECIPE_CHANGED: number = 9;
  static STOCK_CHANGED: number = 10;
  static RESERVATION_CHANGED: number = 11;
  static ELEMENT_CHANGED: number = 12;
  static ZONE_CHANGED: number = 13;
  static COUPON_CHANGED: number = 14;
  static PRINTERS_CHANGED: number = 15;
  static EXTRA_CHANGED: number = 16;
  static STORAGE_CHANGED: number = 17;
  static SETTING_CHANGED: number = 18;
  static SAVED_STORAGE_MOVEMENT_CHANGED: number = 19;
  static SHIFT_CONTROL_CHANGED: number = 20;
  static VAT_VALUES_CHANGED: number = 21;
  static UPSELL_RUNE_CHANGED: number = 22;
  static SHIFT_CONTROL_EXTRA_PARAMETER_CHANGED: number = 23;
}
