import type { ContractType } from "./Contract";
import { ContractTypes } from "./Contract";

export class CommissionRecord {
  id: number;
  partnerID: number;
  contractID: number;
  commission: number;
  date: Date;
}

export class CommissionOffers {
  static SECOND_TYPE_CUSTOM_OFFER: number = 0;
  static SECOND_TYPE_A_OFFER: number = 1;
  static SECOND_TYPE_STANDARD_OFFER: number = 2;

  static THIRD_TYPE_A_OFFER: number = 0;
  static THIRD_TYPE_STANDARD_OFFER: number = 1;
  static THIRD_TYPE_CUSTOM_OFFER: number = 2;

  static getName(type: ContractType, offer: number): string {
    if (type === ContractTypes.FIRST || type === ContractTypes.SECOND) {
      if (offer === CommissionOffers.SECOND_TYPE_CUSTOM_OFFER)
        return "\"Egyedi\"";
      else if (offer === CommissionOffers.SECOND_TYPE_A_OFFER)
        return "\"A\"";
      else if (offer === CommissionOffers.SECOND_TYPE_STANDARD_OFFER)
        return "\"Standard\"";
    } else if (type === ContractTypes.THIRD) {
      if (offer === CommissionOffers.THIRD_TYPE_CUSTOM_OFFER)
        return "\"Egyedi\"";
      else if (offer === CommissionOffers.THIRD_TYPE_A_OFFER)
        return "\"A\"";
      else if (offer === CommissionOffers.THIRD_TYPE_STANDARD_OFFER)
        return "\"Standard\"";
    }
  }
}
