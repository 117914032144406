import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import styled, { css } from "styled-components";
import { Coupon, CouponTypes } from "../../model/Coupon";
import { TranslatableString } from "../../model/Product";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ScrollWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 500px;
  margin: 3px;
`;

const CouponDiv = styled.div`
  margin: 3px;
  box-shadow: 0 0 3px 0 #212121;
  padding: 12px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: box-shadow 150ms ease-in-out, background-color 300ms ease-in-out;

  cursor: pointer;

  &:hover {
    box-shadow: 0 0 5px 0 #212121;
  }

  &:active {
    box-shadow: 0 0 1px 0 #212121;
  }

  ${({ selected }) => selected === true && css`
    background-color: #e0e0e0;
  `}
}

;
`;

const Label = styled.label`
  font-size: 12pt;
`;

export default class OrderCouponSelector extends Component {
  state: {
    coupons: Coupon[],
    selectedCoupons: Coupon[],
    language: number,
    showModal: boolean,
    cb: ()=>{},
  } = {
    coupons: ContextSystem.coupons,
    selectedCoupons: [],
    language: ContextSystem.language,
    showModal: false,
    cb: undefined,
  };

  save() {
    this.state.cb && this.state.cb({ selectedCoupons: this.state.selectedCoupons });
    this.setState({ showModal: false });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_coupons_modal, ({ selectedCoupons, cb }) => {
      this.setState({
        showModal: true,
        selectedCoupons,
        cb,
      });
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, coupons }) => {
      if (language !== undefined)
        this.setState({ language });
      if (coupons !== undefined)
        this.setState({
            coupons: ContextSystem.coupons.filter(c => c.partnerIDs.includes(ContextSystem?.selectedShop?.id)),
          },
        );
    });
  }

  select(coupon: Coupon) {
    let selectedCoupons: Coupon[] = [];
    let includedCoupon: Coupon = this.state.selectedCoupons.find(c => c.id === coupon.id);
    if (!includedCoupon) {
      this.state.selectedCoupons.forEach(c => selectedCoupons.push({ ...c }));
      selectedCoupons.push({ ...coupon });
    } else {
      this.state.selectedCoupons.forEach(c => {
        if (c.id === coupon.id)
          return;
        selectedCoupons.push({ ...c });
      });
    }
    this.setState({ selectedCoupons });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.PriceAdjustments)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Label>{Language.getName(Names.Coupons)}:</Label>
          <ScrollWrapper>
            <Wrapper>
              {this.state.coupons.filter(c => [CouponTypes.FIX, CouponTypes.PERCENTAGE].includes(c.type))
                .map((c, i) => {
                  return (
                    <CouponDiv key={i} onClick={() => this.select(c)}
                               selected={this.state.selectedCoupons.find(sc => sc.id === c.id) !== undefined}>
                      {TranslatableString.get(c.name)}<br />
                      {c.couponValue.toLocaleString()} {c.type === CouponTypes.FIX ? "Ft" : "%"}
                    </CouponDiv>
                  );
                })}
            </Wrapper>
          </ScrollWrapper>
          <Label>{Language.getName(Names.AdditionalCharges)}:</Label>
          <ScrollWrapper>
            <Wrapper>
              {this.state.coupons.filter(c => [CouponTypes.SERVICE].includes(c.type)).map((c, i) => {
                return (
                  <CouponDiv key={i} onClick={() => this.select(c)}
                             selected={this.state.selectedCoupons.find(sc => sc.id === c.id) !== undefined}>
                    {TranslatableString.get(c.name)}<br />
                    {(-c.couponValue).toLocaleString()} {"%"}
                  </CouponDiv>
                );
              })}
            </Wrapper>
          </ScrollWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
