import APICaller, { APIMsgBase } from "../APICaller";
import type { TableReservationStatus } from "../../model/Order";
import { Order, TableReservation } from "../../model/Order";
import { Element } from "../../model/BluePrint";
import { Product } from "../../model/Product";
import { OrdersAPI } from "./OrdersAPI";
import { Profile } from "../../model/Profile";

export type GetTableReservationsType =
  APIMsgBase
  & {
  tableReservations: TableReservation[],
  tables: Element[],
  orders: Order[],
  products: Product[],
  profiles: Profile[]
};

export type ReservationBookedType = APIMsgBase & { reservation: TableReservation, table: Element };

function getReservations(min: Date, max: Date, allStatuses: boolean, cb: (res: GetTableReservationsType)=>{}) {
  let dateMin: number = min.getTime();
  let dateMax: number = max.getTime();
  APICaller.ownFetch(true, "POST", "/tableReservations", { dateMin, dateMax, allStatuses }, (res) => {
    let result: GetTableReservationsType = JSON.parse(res);

    if (result.error === 0) {
      result.tableReservations.forEach(tr => TableReservationAPI.fixDates(tr));
      result.orders.forEach(o => OrdersAPI.fixDates(o));
    }

    if (cb)
      cb(result);
  });
}

function changeTable(tableID: number, reservationID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/tableReservation/changeTable", { tableID, reservationID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function changeNote(note: string, reservationID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/tableReservation/changeNote", { note, reservationID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function chooseCustomer(profileID: number, reservationID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/tableReservation/changeCustomer", { profileID, reservationID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function cancel(reservationID: number, msg: string, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/tableReservation/cancel", { reservationID: reservationID, msg }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function addReservation(
  arrival: Date, stay: number, cover: number, note: string, tableSelected: number, profileID: number,
  cb: (res: ReservationBookedType)=>{},
) {
  APICaller.ownFetch(false, "PUT", "/table/book",
    { arrival: arrival.getTime(), stay, cover, tableSelected, note, profileID },
    (res) => {
      let result: ReservationBookedType = JSON.parse(res);
      if (cb)
        cb(result);
    },
  );
}

function closeTable(table: Element, reservation: TableReservation, cb: (res: APIMsgBase)=>{}) {
  if (!reservation)
    return;

  APICaller.ownFetch(false, "POST", "/tableReservation",
    { tableReservationID: reservation.id },
    (res) => {
      let result: APIMsgBase = JSON.parse(res);
      if (cb)
        cb(result);
    },
  );
}

function changeStatus(res: TableReservation, newStatus: TableReservationStatus, cb: (res: APIMsgBase)=>{}) {
  if (!res)
    return;

  APICaller.ownFetch(false, "POST", "/tableReservation/status",
    { reservationID: res.id, newStatus },
    (res) => {
      let result: APIMsgBase = JSON.parse(res);
      if (cb)
        cb(result);
    },
  );
}

function fixDates(tr: TableReservation) {
  tr.end = new Date(tr.end);
  tr.start = new Date(tr.start);
  tr.changes && tr.changes.forEach(c => {
    c.newStart = new Date(c.newStart);
    c.newEnd = new Date(c.newEnd);
    c.lastEnd = new Date(c.lastEnd);
    c.lastStart = new Date(c.lastStart);
  });
}

export const TableReservationAPI = {
  getReservations,
  changeTable,
  cancel,
  fixDates,
  addReservation,
  closeTable,
  changeStatus,
  chooseCustomer,
  changeNote,
};
