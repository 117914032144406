import React, { Component } from "react";
import styled from "styled-components";
import ContextSystem from "../../../utils/ContextSystem";
import { Order, OrderState } from "../../../model/Order";
import EventSystem from "../../../utils/EventSystem";
import CartContent from "../../CartContent";
import { Product } from "../../../model/Product";
import { OrdersAPI } from "../../../utils/api/OrdersAPI";
import Language, { Names } from "../../../utils/Language";
import Orders, { CheckBox } from "../Orders";
import { Label } from "../../modals/ContractsDetailsModal";
import { addLeadingZero } from "../../../utils/HoursCalc";
import CartCalc from "../../../utils/CartCalc";

const TopWrapper = styled.div`
  width: 100%;
  padding: 3px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
`;

const TicketsScroll = styled.div`
  width: 100%;
  height: fit-content;
  flex-grow: 2;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 12px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #c9c9c9;
    border-radius: 100px;
  }
`;

const Tickets = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 14pt;
  color: #323232;
`;

const Ticket = styled.div`
  width: 300px;
  min-height: 100px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  margin: 12px;
  padding: 0;
  border-radius: 6px;
  background-color: #f8f8f8;
  box-shadow: 0 0 1px 1px #4e4e4e;
`;

const TicketContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
`;

const TicketHeader = styled.div`
  width: 100%;
  margin: 0;
  background-color: #666666;
  color: white;
  font-size: 10pt;
  padding: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const TicketDailyNumber = styled.div`
  padding: 2px 4px;
  background-color: #333333;
  border-radius: 4px;
`;

const TicketName = styled.div`
  margin: 0 4px;
`;

const TicketNumber = styled.div`
  margin-left: auto;
`;

const TicketTiming = styled.div`
  color: ${({ color }) => color ?? "white"};
  font-weight: bold;
`;

const TicketStatus = styled.div`
  margin-left: auto;
  padding: 2px 4px;
  border-radius: 4px;

  background-color: ${({ background_color }) => background_color ?? "#333333"};
  font-size: 9pt;
`;

export default class KitchenLayout extends Component {
  eventIDs: number[] = [];

  state: {
    language: number,
    orders: Order[],
    dateTime: Date,
    showUnconfirmed: boolean
  } = {
    language: ContextSystem.language,
    orders: [],
    dateTime: new Date(),
    showUnconfirmed: false,
  };

  loadContext() {
    let acceptedStatuses: number[] = [
      OrderState.CONFIRMED,
      OrderState.PREPARING,
      OrderState.NEW,
    ];

    this.setState({
      orders: ContextSystem.orders.filter(o => o.enabled && acceptedStatuses.includes(o.lastState.status))
        .sort((o1, o2) => Orders.sortByDate(o1.date, o2.date, false)),
    });
  }

  timeout;

  reloadDateTime() {
    this.timeout = setTimeout(() => {
      this.setState({ dateTime: new Date() });
      this.reloadDateTime();
    }, 1000);
  }

  componentWillUnmount() {
    this.eventIDs.forEach(e => EventSystem.unsubscribe(e));
    if (this.timeout)
      clearTimeout(this.timeout);
  }

  componentDidMount() {
    this.loadContext();
    this.reloadDateTime();
    this.setShowUnconfirmed(parseInt(localStorage.getItem("kds-uncf")) === 1);

    this.eventIDs = [];

    let eid: number = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, orders }) => {
      if (language !== undefined)
        this.setState({ language });
      if (orders !== undefined)
        this.loadContext();
    });
    this.eventIDs.push(eid);
  }

  handleTicketClicked(o: Order) {
    if (!o)
      return;

    let newState = -1;

    if (o.lastState.status === OrderState.NEW) {
      newState = OrderState.CONFIRMED;
    } else if (o.lastState.status === OrderState.CONFIRMED) {
      newState = OrderState.PREPARING;
    } else if (o.lastState.status === OrderState.PREPARING) {
      newState = OrderState.READY;
    }

    if (newState === -1)
      return;

    OrdersAPI.modifyOrderState(o, newState);
  }

  setShowUnconfirmed(showUnconfirmed: boolean) {
    if (showUnconfirmed === undefined)
      return;

    this.setState({ showUnconfirmed });
    localStorage.setItem("kds-uncf", showUnconfirmed ? 1 : 0);
  }

  render() {
    let hiddenOrdersCount: number = this.state.orders.filter(o => o.lastState.status === OrderState.NEW).length;

    return (
      <Wrapper>
        <TopWrapper>
          <CheckBox>
            <input
              type={"checkbox"}
              checked={this.state.showUnconfirmed}
              onChange={e => this.setShowUnconfirmed(e.target.checked)}
            />
            <Label onClick={() => this.setShowUnconfirmed(!this.state.showUnconfirmed)}>
              {Language.getName(Names.ShowNotConfirmedOrders)}
              {hiddenOrdersCount > 0 ? " (" + hiddenOrdersCount + ")" : ""}
            </Label>
          </CheckBox>
        </TopWrapper>
        <TicketsScroll>
          <Tickets>
            {this.state.orders.map((o, i) => {
              if (!this.state.showUnconfirmed && o.lastState.status === OrderState.NEW)
                return <React.Fragment key={i} />;

              let products: Product[] = CartCalc.createCartFromOrder(o);
              products = products.filter(p => p.prepared === false);

              if (products.length <= 0)
                return <React.Fragment key={i} />;

              let bgColor = "rgb(11,66,123)";

              if (o.lastState.status === OrderState.PREPARING)
                bgColor = "rgb(97,5,128)";

              let timeNow: Date = new Date();
              let due: Date = undefined;
              let lastOS: OrderState = o.orderStates.find(
                os => os.comment !== "" && JSON.parse(os.comment)?.minutes !== undefined);
              if (lastOS)
                due = new Date(o.scheduleDate ?? o.date).addMinutes(JSON.parse(lastOS.comment)?.minutes);

              return (
                <Ticket key={i} onClick={() => this.handleTicketClicked(o)}>
                  <TicketHeader>
                    <TicketDailyNumber>#{o.dailyNumber}</TicketDailyNumber>
                    <TicketName>{o.profile && (o.profile.firstName)}</TicketName>
                    <TicketNumber>{o.number}</TicketNumber>
                  </TicketHeader>
                  <TicketHeader>
                    <TicketTiming
                      color={due ? (due.getTime() - timeNow.getTime() > 0 ? "orange" : "#740303") : "white"}>
                      {due &&
                        (() => {
                          let diffMs: number = Math.abs(due.getTime() - timeNow.getTime());
                          let diffHours: number = Math.floor(diffMs / (1000 * 60 * 60));
                          diffMs -= diffHours * 1000 * 60 * 60;
                          let diffMinutes: number = Math.floor(diffMs / (1000 * 60));

                          return (
                            <>
                              {due.getTime() - timeNow.getTime() <= 0 && Language.getName(Names.Delay) + ": "}
                              {due.getTime() - timeNow.getTime() > 0 && Language.getName(Names.RemainingTime) + ": "}
                              {addLeadingZero(diffHours)}:{addLeadingZero(diffMinutes)}
                            </>
                          );
                        })()
                      }
                    </TicketTiming>
                    <TicketStatus background_color={bgColor}>
                      {Orders.getStatusString(o.lastState.status)}
                    </TicketStatus>
                  </TicketHeader>
                  <TicketContent>
                    <CartContent products={products}
                                 showShippingPrices={false}
                                 showModificationButtons={false}
                                 showTotal={false}
                                 increaseItem={undefined}
                                 removeItem={undefined}
                                 fontSize={"small"}
                                 showPrices={false}
                                 showOriginalPrice={true}
                                 showSplitPrice={false}
                                 onProductClicked={(/*p: Product*/) => {
                                 }}
                                 setNote={undefined}
                    />
                  </TicketContent>
                </Ticket>
              );
            })}
          </Tickets>
        </TicketsScroll>
        {this.state.orders.length <= 0 &&
          <Empty>
            {Language.getName(Names.EmptyKitchenTickets)}
          </Empty>
        }
      </Wrapper>
    );
  }
}
