import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { FaTimes, FaTrashAlt, GoPencil, GoPlus, TiMinus } from "react-icons/all";
import AnimateHeight from "../AnimateHeight";
import ContextSystem from "../../utils/ContextSystem";
import { Extra, ExtraGroup, Product, ProductExtra, TranslatableString } from "../../model/Product";
import Language, { Names } from "../../utils/Language";
import { toast } from "react-toastify";
import { OrderExtra, OrderProduct } from "../../model/Order";
import CartCalc from "../../utils/CartCalc";
import Modal from "./Modal";
import { SmallInput } from "./ExtrasSelector";
import ProductEditor from "./ProductEditor";

const VersionWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  ${({ selected, last }) => last === true && selected === false && css`
    margin-bottom: 8px;
  `}
`;

const WrapperBase = styled.div`
  & > span {
    width: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgb(250, 250, 250);

    & > h1 {
      font-size: 16pt;
      font-family: "Segoe UI", sans-serif;
      margin: 0;
      padding: 0;
    }

    & > h2 {
      font-size: 14pt;
      font-family: "Segoe UI", sans-serif;
      margin: 0;
      padding: 0;
    }

    & > svg {
      font-size: 14pt;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const Wrapper = styled(WrapperBase)`
  width: 100%;
  height: fit-content;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(40, 40, 40);
  border-radius: 5px;
  box-shadow: 0 0 10px 1px black;
  position: relative;
  color: rgb(240, 240, 240);
`;

const InnerWrapper = styled(WrapperBase)`
  width: 100%;
  position: relative;
  padding-bottom: 50px;
`;

const Header = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // font-size: 23pt;
  color: rgb(250, 250, 250);

  & > h1 {
    font-size: 16pt;
    font-family: "Segoe UI", sans-serif;
    margin: 0;
    padding: 0;
  }

  & > svg {
    font-size: 14pt;

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1000px) {
    & > h1 {
      font-size: 14pt;
    }
  }
`;

const ExtrasListWrapper = styled.div`
  width: 100%;
  display: block;
  max-height: 60vh;
  overflow-y: auto;
`;

const ExtrasList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const VersionsList = styled(WrapperBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`;

const BottomPanel = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: auto;
  overflow: hidden;
  background-color: #2c7116;
  color: white;

  & > div {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    user-select: none;
    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

    ${({ valid }) => valid === true && css`
      &:hover {
        cursor: pointer;
        background-color: #3e9e1d;
      }
    `}
  }

  ${({ valid }) => valid === false && css`
    background-color: #51933f;
    color: rgb(184, 184, 184);
  `}
`;

const Version = styled.div`
  width: 95%;
  padding: 15px 20px;
  margin: 5px 0;
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  background-color: rgb(60, 60, 60);

  & > span:nth-child(1) {
    width: 75%;
  }

  & > span:nth-child(2) {
    width: 25%;
  }

  & > span:nth-child(3) {
    opacity: 0;
    width: 0;
    font-size: 16pt;
  }

  transition: color 100ms ease-in-out, background-color 100ms ease-in-out, width 50ms ease-in-out;

  ${({ selected }) => selected && css`
    background-color: rgb(80, 80, 80);
    color: white;
    margin: 0;
    width: 100%;

    & > span:nth-child(2) {
      width: 15%;
    }

    & > span:nth-child(3) {
      opacity: 1;
      width: 10%;
    }
  `}
  &:hover {
    background-color: rgb(90, 90, 90);
  }

  &:hover, &:active {
    cursor: pointer;
  }
`;

const ExtraGroupDiv = styled.div`
  position: relative;
  width: 96%;
  margin: 0 0 8px 0;
  border-radius: 5px;
  border: 1px solid rgb(80, 80, 80);
  padding: 5px 0;
  user-select: none;
  background-color: rgb(80, 80, 80);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraGroupTitle = styled.div`
  position: sticky;
  top: 0;
  padding: 5px;
  width: 100%;
  text-align: center;
  background-color: rgb(80, 80, 80);
  border-bottom: 1px solid rgb(47, 47, 47);
  margin-bottom: 7px;

  transition: background-color 200ms ease-in-out;

  & > span {
    font-weight: bold;
    font-size: 16px;
  }

  & > div {
    font-size: 13px;
  }

  @media screen and (max-width: 1000px) {
    & > span {
      font-size: 15px;
    }

    & > div {
      font-size: 13px;
    }
  }

  ${({ valid }) => valid !== null && css`
    background-color: ${valid === true ? "#4c8f4f" : "#7f2929"}
  `}
`;

const SelectedExtraCount = styled.div`
  width: fit-content;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 21px;

  & > span {
    white-space: nowrap;
  }
`;

const ExtraDiv = styled.div`
  width: 100%;
  padding: 0 15px;
  margin: 5px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  transition: background-color 200ms ease-in-out;

  & > span:nth-child(1) {
    width: 45%;
  }

  & > span:nth-child(2) {
    width: 15%;
  }

  ${({ selected }) => selected === true && css`
    background-color: #4c8f4f;
  `};

  @media screen and (max-width: 1000px) {
    padding: 0 5px;

    & > span:nth-child(1) {
      width: 65%;
    }

    & > span:nth-child(2) {
      width: 20%;
    }
  }
`;

const ExtraButtonsList = styled.div`
  padding: 0 10px;
  width: fit-content;
  ${({ align }) => align === "left" && css`
    margin-right: auto;
  `};
  ${({ align }) => align === "right" && css`
    margin-left: auto;
  `};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraButton = styled.div`
  padding: 10px 7px;
  margin: 0 5px;
  border-radius: 3px;

  &:hover {
    background-color: rgb(70, 70, 70);
    cursor: pointer;
  }
;

  @media screen and (max-width: 1000px) {
    margin: 0;
  }

  & > svg {
    font-size: 25pt;
  }
`;

export default class ProductExtrasSelector extends Component {
  static openedFromClick: boolean = false;

  state: {
    clickedDoneOnce: boolean,
    product: undefined | Product,
    opened: boolean,
    selectedExtras: OrderExtra[],
    showVersions: boolean,
    selectedVersion: number,
    language: number,
    onFinish: (p: Product)=>{} | undefined
  } = {
    clickedDoneOnce: false,
    product: undefined,
    opened: false,
    selectedExtras: [],
    showVersions: true,
    selectedVersion: 0,
    language: ContextSystem.language,
    onFinish: undefined,
  };

  getSelected(extraID: number, extraGroupID: number): OrderExtra {
    for (let selectedExtra: OrderExtra of this.state.selectedExtras) {
      if (selectedExtra.id === extraID && selectedExtra.extraGroupID === extraGroupID)
        return selectedExtra;
    }
    return null;
  }

  setProduct(product) {
    if (!product)
      return;
    this.setState({ product, selectedExtras: [] });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.product !== this.props.product) {
      this.setProduct(this.props.product);
    }
  }

  getExtra(product: Product, extraID: number): Extra | null {
    if (!product)
      return;

    for (let e of product.availableExtras) {
      if (e.id === extraID)
        return e;
    }
    return null;
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });
  }

  componentDidMount() {
    if (this.props.product)
      this.setProduct(this.props.product);

    EventSystem.subscribe(EventSystem.events.open_extras_selector, ({ product, onFinish }) => {
      if (!product)
        return;

      this.setState({ onFinish });

      ProductExtrasSelector.openedFromClick = true;
      setTimeout(() => ProductExtrasSelector.openedFromClick = false, 200);
      this.setProduct(product);

      this.setState(
        { opened: true, showVersions: true, selectedVersion: 0, clickedDoneOnce: false },
        () => {
          if (product.versions.length === 1)
            this.selectVersion(product.versions[0].id);
        },
      );
    });

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined) {
        this.setState({ language });
      }
    });
  }

  removeExtra(extra, extraGroup) {
    let orderExtras = [];

    for (let oe: OrderExtra of this.state.selectedExtras) {
      if (oe.id !== extra.id || oe.extraGroupID !== extraGroup.id) orderExtras.push({ ...oe });
    }
    this.setState({ selectedExtras: orderExtras });
  }

  onQtyInputFocus(e) {
    let current: string = e.target.value;
    if (!current || current === "")
      return;
    if (current === "0")
      e.target.select();
  }

  addExtra(extra: Extra, productExtra: ProductExtra, extraGroup: ExtraGroup, qty: number = 1, replace: boolean = false) {
    if (isNaN(qty))
      qty = 0;

    qty = Math.round(qty * 100) / 100;

    let selectedExtras = [];
    if (!extraGroup || !extra || !productExtra) {
      toast(Language.getName(Names.ErrorInShopMenuText));
      return;
    }

    let found = false;
    let totalQty = 0;
    for (let orderExtra of this.state.selectedExtras) {
      let newOE = { ...orderExtra };
      if (newOE.extraID === extra.id && newOE.extraGroupID === extraGroup.id) {
        found = true;
        let newValue: number = replace ? qty : newOE.qty + qty;
        if (newValue <= 0)
          newValue = 0;
        if (newValue >= productExtra.maxQty && productExtra.maxQty > 0)
          newValue = productExtra.maxQty;
        newOE.qty = newValue;
      }

      if (newOE.qty <= 0)
        continue;

      if (newOE.extraGroupID === extraGroup.id)
        totalQty += newOE.qty;
      selectedExtras.push(newOE);
    }

    if (totalQty >= extraGroup.maxQty && extraGroup.maxQty > 0) {
      let msg: string = Language.getName(Names.ToppingMaxReachedTextPart1) + extraGroup.maxQty + Language.getName(
        Names.ToppingMaxReachedTextPart2);
      toast(msg);
      return;
    }

    if (!found) {
      let newValue: number = qty;
      if (newValue <= 0)
        newValue = 0;
      if (newValue >= productExtra.maxQty && productExtra.maxQty > 0)
        newValue = productExtra.maxQty;

      let orderExtra = { ...extra.orderExtra };
      orderExtra.qty = newValue;
      orderExtra.extraGroupID = extraGroup.id;
      orderExtra.saleID = -1;

      orderExtra.price = extra.price;
      orderExtra.originalPrice = orderExtra.price;

      orderExtra.total = extra.price * orderExtra.qty;
      orderExtra.originalTotal = orderExtra.total;
      selectedExtras.push(orderExtra);
    }
    this.setState({ selectedExtras });
  }

  close() {
    this.setState({ opened: false, product: undefined });
  }

  finishSelection() {
    this.setState({ clickedDoneOnce: true });
    if (!this.isSelectedExtrasValid()) {
      let msg: string = Language.getName(Names.ToppingMinNotReachedText);
      toast(msg);
      return;
    }

    let product = { ...this.state.product };
    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
    leadOP.extras = this.state.selectedExtras;
    leadOP.qty = 1;
    leadOP.versionID = this.state.selectedVersion;
    if (this.state.onFinish)
      this.state.onFinish(product);
    this.close();
  }

  selectVersion(versionID: number) {
    if (this.state.selectedVersion === versionID) {
      this.setState({ selectedVersion: 0 });
      return;
    }
    this.setState({ selectedVersion: versionID, showVersions: false }, () => {
      let versionHasExtraGroups = false;
      for (let eg of this.state.product.extraGroups) {
        if (eg.versionID === versionID && eg.groupExtras.length > 0) {
          versionHasExtraGroups = true;
          break;
        }
      }
      if (!versionHasExtraGroups) {
        this.finishSelection();
      }
    });
  }

  isExtraGroupValid(extraGroupID: number) {
    if (this.state.selectedVersion === 0) return false;

    for (let eg: ExtraGroup of this.state.product.extraGroups) {
      if (eg.id === extraGroupID) {
        let totalQty = 0;
        for (let oe: OrderExtra of this.state.selectedExtras) {
          if (oe.extraGroupID === eg.id) {
            totalQty += oe.qty;
          }
        }
        return totalQty >= eg.minQty && (totalQty <= eg.maxQty || eg.maxQty <= 0);
      }
    }
    return false;
  }

  isSelectedExtrasValid() {
    if (this.state.selectedVersion === 0)
      return false;

    for (let eg of this.state.product.extraGroups) {
      if (eg.versionID === this.state.selectedVersion) {
        let totalQty = 0;
        for (let oe: OrderExtra of this.state.selectedExtras) {
          if (oe.extraGroupID === eg.id) {
            totalQty += oe.qty;
          }
        }
        if (totalQty < eg.minQty || (totalQty > eg.maxQty && eg.maxQty > 0))
          return false;
      }
    }
    return true;
  }

  calcSelectedExtraPrice() {
    if (this.state.selectedVersion === 0) return 0;

    let total = 0;
    this.state.selectedExtras.sort((a, b) => a.price - b.price);

    for (let v of this.state.product.versions) {
      if (v.id === this.state.selectedVersion) {
        total += v.price;
        break;
      }
    }

    for (let eg of this.state.product.extraGroups) {
      if (eg.versionID !== this.state.selectedVersion)
        continue;
      let freeCount = 0;
      for (let oe: OrderExtra of this.state.selectedExtras) {
        if (oe.extraGroupID === eg.id) {
          if (eg.freeQty > freeCount) {
            if (eg.freeQty - freeCount > oe.qty) {
              freeCount += oe.qty;
            } else {
              let addFreeCount = eg.freeQty - freeCount;
              freeCount += addFreeCount;
              total += (oe.qty - addFreeCount) * oe.price;
            }
          } else {
            total += oe.qty * oe.price;
          }
        }
      }
    }
    return total;
  }

  getExtraGroupElements(version) {
    return this.state.product.extraGroups.map((group, j) => {
      if (version.id === group.versionID) {
        let extras = group.groupExtras.map((productExtra: ProductExtra, k) => {
          let extra: Extra = this.getExtra(this.state.product, productExtra.extraID);
          let selectedExtra: OrderExtra = this.getSelected(productExtra.extraID, group.id);
          if (!extra)
            return <React.Fragment key={k} />;

          return (
            <ExtraDiv selected={selectedExtra ? selectedExtra.qty > 0 : false} key={k}>
              <span>{TranslatableString.get(productExtra.shownName)}</span>
              <span>{extra?.price ?? "--"}Ft</span>
              <ExtraButtonsList align={"left"}>
                <ExtraButton onClick={() => this.addExtra(extra, productExtra, group, -1)}>
                  <TiMinus />
                </ExtraButton>
              </ExtraButtonsList>
              <SelectedExtraCount>
                <SmallInput
                  hideSpin={true}
                  value={selectedExtra ? selectedExtra.qty : "0"}
                  type="number"
                  height={"21px"}
                  font_size={"21px"}
                  placeholder={"0"}
                  border={"none"}
                  lang={"hu-hu,en"}
                  onFocus={e => this.onQtyInputFocus(e, extra, productExtra, group)}
                  onChange={e => {
                    let v: string = ProductEditor.parsePrice(e.target.value);
                    v = v.replace(",", ".");
                    e.target.value = v;
                    this.addExtra(extra, productExtra, group, parseFloat(v), true);
                  }}
                  width={"40px"}
                  bgColor={"rgba(0,0,0,0)"}
                  color={"white"}
                />
                <span>/ {productExtra.maxQty}</span>
              </SelectedExtraCount>
              <ExtraButtonsList align={"right"}>
                <ExtraButton onClick={() => this.addExtra(extra, productExtra, group)}>
                  <GoPlus />
                </ExtraButton>
                <ExtraButton onClick={() => this.removeExtra(extra, group)}>
                  <FaTrashAlt />
                </ExtraButton>
              </ExtraButtonsList>
            </ExtraDiv>
          );
        });
        if (extras.length === 0)
          return <React.Fragment key={j} />;

        let optionsText;

        let freeQtyText = group.freeQty > 0 ? Language.getName(
          Names.ToppingFreeQtyTextPart1) + group.freeQty + Language.getName(Names.ToppingFreeQtyTextPart2) : "";
        let minMaxRange;
        if (group.minQty === group.maxQty)
          minMaxRange = group.minQty === 0 ? Language.getName(Names.ToppingMinMaxQtyTextPart1) : group.minQty;
        else if (group.minQty === 0 && group.maxQty > 0)
          minMaxRange = Language.getName(Names.ToppingMinMaxQtyTextPart2) + group.maxQty + Language.getName(
            Names.ToppingMinMaxQtyTextPart3);
        else
          minMaxRange = (group.maxQty > 0 ? group.minQty + " - " + group.maxQty : Language.getName(
            Names.ToppingMinMaxQtyTextPart4) + group.minQty) + Language.getName(Names.ToppingMinMaxQtyTextPart5);

        optionsText = Language.getName(Names.ToppingQtyTextPart1) + minMaxRange + Language.getName(
          Names.ToppingQtyTextPart2) + (freeQtyText !== "" ? "(" + freeQtyText + ")" : "");

        if (group.maxQty <= group.freeQty && group.maxQty > 0 && group.freeQty > 0) {
          optionsText = Language.getName(Names.ToppingQtyTextPart1) + group.maxQty + Language.getName(
            Names.ToppingQtyTextPart3);
        }

        return (
          <ExtraGroupDiv key={j}>
            <ExtraGroupTitle valid={this.state.clickedDoneOnce ? this.isExtraGroupValid(group.id) : null}>
              <span>{TranslatableString.get(group.name)}</span>
              <br />
              <div>{optionsText}</div>
            </ExtraGroupTitle>
            {extras}
          </ExtraGroupDiv>
        );
      }
      return <React.Fragment key={j} />;
    });
  }

  getVersionElement(groups, oneVersion = false, i: number) {
    let isValidGroup = false;
    for (let group of groups) {
      if (group) {
        isValidGroup = true;
        break;
      }
    }
    if (!isValidGroup) return null;
    return (
      <InnerWrapper key={i}>
        <span>
          <h2>{Language.getName(Names.ChooseToppings)}</h2>
          <FaTimes onClick={() => this.close()} />
        </span>
        <ExtrasListWrapper>
          <ExtrasList>
            {groups}
          </ExtrasList>
        </ExtrasListWrapper>
        <BottomPanel valid={this.isSelectedExtrasValid()}>
          <div onClick={() => this.finishSelection()}>
            {Language.getName(Names.AddToCart)}
            {" (" + (Math.round(this.calcSelectedExtraPrice() + this.state.product.price)) + " Ft) "}
          </div>
        </BottomPanel>
      </InnerWrapper>
    );
  }

  render() {
    let oneVersion = false;
    let versions;
    let isVersionThatHasExtra = false;
    if (this.state.product?.versions.length === 1) {
      versions = this.state.product?.versions.map((version, i) => {
        let groups = this.getExtraGroupElements(version);
        return this.getVersionElement(groups, true, i);
      });
      oneVersion = true;
    } else {
      versions = this.state.product?.versions.map((version, i) => {
        let groups = this.getExtraGroupElements(version);
        let versionElement = this.getVersionElement(groups);
        if (versionElement)
          isVersionThatHasExtra = true;

        let opened = this.state.selectedVersion === 0 || this.state.selectedVersion === version.id;
        let last = i === this.state.product.versions.length - 1;
        return (
          <VersionWrapper key={i} selected={this.state.selectedVersion > 0} last={last}
                          className={"VersionWrapper"}>
            <AnimateHeight opened={opened}>
              <VersionsList>
                <Version selected={this.state.selectedVersion === version.id}
                         onClick={() => this.selectVersion(version.id)}>
                  <span>{TranslatableString.get(version.name)}</span>
                  <span>{this.state.product.price + version.price} Ft</span>
                  <span>
                    <GoPencil />
                  </span>
                </Version>
              </VersionsList>
            </AnimateHeight>
            <AnimateHeight opened={versionElement && this.state.selectedVersion === version.id}>
              {versionElement}
            </AnimateHeight>
          </VersionWrapper>
        );
      });
    }
    return (
      <Modal size={"xl"} show={this.state.opened} onEscapeKeyDown={() => this.close(true)}>
        {this.state.opened &&
          <Wrapper onClick={(e) => e.stopPropagation()}>
            <AnimateHeight opened={!oneVersion && (this.state.selectedVersion === 0 || !isVersionThatHasExtra)}>
              <Header>
                <h1>{Language.getName(Names.WhichVersionText)}</h1>
                <FaTimes onClick={() => this.close(true)} />
              </Header>
            </AnimateHeight>
            {versions}
          </Wrapper>
        }
      </Modal>
    );
  }
}
