import APICaller, { APIMsgBase } from "../APICaller";
import { Hour } from "../../model/Hour";

export type ToggleCloseForTodayResponseType = APIMsgBase & { closedForToday: boolean };

function toggleCloseForToday(cb: (res: ToggleCloseForTodayResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/toggleClosedToday", {}, (res) => {
    let result: ToggleCloseForTodayResponseType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function modify(
  hourID: number, day: number, openHour: number, openMinute: number, closeHour: number, closeMinute: number,
  cb: (res: APIMsgBase)=>{},
) {
  APICaller.ownFetch(false, "POST", "/hour", { hourID, day, openHour, openMinute, closeHour, closeMinute }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type AddHourResponseType = APIMsgBase & { hour: Hour };

function add(
  hourType: number, day: number, openHour: number, openMinute: number, closeHour: number, closeMinute: number,
  cb: (res: AddHourResponseType)=>{},
) {
  APICaller.ownFetch(false, "POST", "/hour", { hourType, day, openHour, openMinute, closeHour, closeMinute }, (res) => {
    let result: AddHourResponseType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(hourID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/hour", { hourID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const HoursAPI = {
  toggleCloseForToday,
  modify,
  add,
  remove,
};
