import APICaller, { APIMsgBase } from "../APICaller";
import { Recipe } from "../../model/Product";

function save(recipe: Recipe, cb: (res: APIMsgBase)=>{}) {
  let recipe2: Recipe = { ...recipe };
  recipe2.addedDate = recipe2.addedDate.getTime();
  APICaller.ownFetch(false, "POST", "/recipe", { ...recipe2 }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(recipeID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/recipe", { id: recipeID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type GetRecipesResponseType = APIMsgBase & { recipes: Recipe[] };

function getRecipes(cb: (res: GetRecipesResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/recipes", {}, (res) => {
    let result: GetRecipesResponseType = JSON.parse(res);
    if (result.error === 0)
      result.recipes.forEach(r => r.addedDate = new Date(r.addedDate));
    if (cb)
      cb(result);
  });
}

export const RecipeAPI = {
  getRecipes,
  save,
  remove,
};
