import AbstractModel from "./AbstractModel";
import Language, { Names } from "../utils/Language";

export const ShopProfileTypes: { [keys: string]: number } = {
  MANAGER: 0,
  DISPATCHER: 1,
  WAITER: 2,
  KITCHEN: 3,
  STAND: 4,
  MODIFY_USERS: 5,
  MODIFY_MENU: 6,
  MODIFY_STORAGES: 7,
  MOVE_STORAGE: 8,
  REQUEST_STORAGE_MOVEMENT: 9,
  STORAGE_INWARD_HANDLE: 10,
  STOCK_TAKING: 11,
  MODIFY_TABLES: 12,
  MODIFY_DISCOUNTS: 13,
  MODIFY_CRM: 14,
  MODIFY_SETTINGS: 15,
  MODIFY_OPENING_HOURS: 16,
  STORNO_ORDER: 17,
};

export type ShopProfileType = $Values<typeof ShopProfileTypes>;

export const ShopProfilePermissions = {
  getName(type: ShopProfileType) {
    switch (type) {
      case ShopProfileTypes.MANAGER:
        return Language.getName(Names.Manager);
      case ShopProfileTypes.DISPATCHER:
        return Language.getName(Names.Dispatcher);
      case ShopProfileTypes.WAITER:
        return Language.getName(Names.Waiter);
      case ShopProfileTypes.KITCHEN:
        return Language.getName(Names.Kitchen);
      case ShopProfileTypes.STAND:
        return Language.getName(Names.Standing);
      case ShopProfileTypes.MODIFY_USERS:
        return Language.getName(Names.MODIFY_USERS);
      case ShopProfileTypes.MODIFY_MENU:
        return Language.getName(Names.MODIFY_MENU);
      case ShopProfileTypes.MODIFY_STORAGES:
        return Language.getName(Names.MODIFY_STORAGES);
      case ShopProfileTypes.MOVE_STORAGE:
        return Language.getName(Names.MOVE_STORAGE);
      case ShopProfileTypes.REQUEST_STORAGE_MOVEMENT:
        return Language.getName(Names.REQUEST_STORAGE_MOVEMENT);
      case ShopProfileTypes.STORAGE_INWARD_HANDLE:
        return Language.getName(Names.STORAGE_INWARD_HANDLE);
      case ShopProfileTypes.STOCK_TAKING:
        return Language.getName(Names.STOCK_TAKING);
      case ShopProfileTypes.MODIFY_TABLES:
        return Language.getName(Names.MODIFY_TABLES);
      case ShopProfileTypes.MODIFY_DISCOUNTS:
        return Language.getName(Names.MODIFY_DISCOUNTS);
      case ShopProfileTypes.MODIFY_CRM:
        return Language.getName(Names.MODIFY_CRM);
      case ShopProfileTypes.MODIFY_SETTINGS:
        return Language.getName(Names.MODIFY_SETTINGS);
      case ShopProfileTypes.MODIFY_OPENING_HOURS:
        return Language.getName(Names.MODIFY_OPENING_HOURS);
      case ShopProfileTypes.STORNO_ORDER:
        return Language.getName(Names.STORNO_ORDER);
    }
  },
};

export class ShopProfile extends AbstractModel {
  activated: boolean;
  email: string;

  //password is not needed here

  phoneNumber: string;
  firstName: string;
  lastName: string;
  image: string;
  permissions: { [keys: number]: ShopProfileType[] }; //shopID -> permissions
  pin: string;

  static isPermitted(sp: ShopProfile, shopID: number, permission: ShopProfileType): boolean {
    if (!sp || !sp.permissions[shopID])
      return false;
    return sp.permissions[shopID].includes(permission);
  }
}
