import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import { ExtrasAPI } from "../../utils/api/ExtrasAPI";
import Language, { Names } from "../../utils/Language";
import { Extra, MennyisegEgysegs, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import { languages, ReactFlagsSelect } from "./CouponEditor";
import ProductEditor, { Select, SubSectionLabel } from "./ProductEditor";
import { ShopNTAKIntegration } from "../../model/NTAK";
import type { OptionValue } from "./InwardHandlingModal";
import { SubSection } from "../home/DetailedSettings";

export default class ExtraEditor extends Component {
  state: {
    showModal: boolean,
    edit: null | Extra,
    title: string,
    language: number,
    selectedLanguage: number,
    ntakIntegrations: ShopNTAKIntegration[],
    ntakMennyisegEgysegOptions: OptionValue[],
    selectedNtakMennyisegEgyseg: OptionValue,
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewExtra),
    language: ContextSystem.language,
    selectedLanguage: languages[0].id,
    ntakIntegrations: [],
    ntakMennyisegEgysegOptions: [],
    selectedNtakMennyisegEgyseg: undefined,
  };

  save() {
    let extra = { ...this.state.edit };
    if (!extra.name || TranslatableString.get(extra.name).length < 2) {
      toast(Language.getName(Names.ExtraNameTooShort));
      return;
    }
    if (!extra.price)
      extra.price = 0;
    if (!extra.ntakQty)
      extra.ntakQty = 0;

    extra.price = parseFloat(extra.price);
    extra.ntakQty = parseFloat(extra.ntakQty);

    if (extra.price < 0) {
      toast(Language.getName(Names.PriceShouldBeNonNegative));
      return;
    }

    if (!extra.recipes)
      extra.recipes = [];

    ExtrasAPI.save(extra, () => {
      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
      EventSystem.publish(EventSystem.events.extras_changed);
    });
  }

  static remove(extraID) {
    ExtrasAPI.remove(extraID, () => {
      toast(Language.getName(Names.Removed));
      EventSystem.publish(EventSystem.events.extras_changed);
    });
  }

  editProduct(extra) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyExtra),
      edit: extra,
    });
  }

  setName(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let name: TranslatableString = this.state.edit.name;
    if (!name)
      name = TranslatableString.create("");
    name.translation = name.translation.filter(t => t.languageCode !== languageCode);
    name.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        name: name,
      },
    });
  }

  selectNTAKMennyisegEgyseg({ value: me, label }) {
    let edit = { ...this.state.edit };
    edit.ntakMennyisegEgyseg = me;
    this.setState({
      selectedNtakMennyisegEgyseg: arguments[0],
      edit,
    });
  }

  addNewExtra() {
    this.setState({
      showModal: true,
      title: Language.getName(Names.NewExtra),
      edit: {
        enabled: 1,
        id: -1,
        name: TranslatableString.create(""),
        price: 0,
        qty: 0,
        partnerID: -1,
        ntakMennyisegEgyseg: MennyisegEgysegs.DARAB,
        ntakQty: 1,
      },
    });
  }

  loadContext() {
    this.setState({
      ntakIntegrations: ContextSystem.ntakIntegrations.filter(n => n.partnerID === ContextSystem.selectedShop?.id),
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.edit_extra, (extra) => {
      let selectedNtakMennyisegEgyseg: OptionValue = this.state.selectedNtakMennyisegEgyseg;
      let ntakMennyisegEgysegOptions = [];
      for (let c of Object.keys(MennyisegEgysegs)) {
        let option = { value: c, label: c };

        if (extra && c === extra.ntakMennyisegEgyseg) {
          selectedNtakMennyisegEgyseg = option;
        }
        ntakMennyisegEgysegOptions.push(option);
      }

      this.setState({
        selectedNtakMennyisegEgyseg,
        ntakMennyisegEgysegOptions,
      });

      if (!extra)
        this.addNewExtra();
      else
        this.editProduct(extra);
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, ntakIntegrations }) => {
      if (language !== undefined)
        this.setState({ language });
      if (ntakIntegrations !== undefined)
        this.loadContext();
    });
    this.loadContext();
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;
    let hasNtakIntegration: boolean = this.state.ntakIntegrations.length > 0;

    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id })}
          />
        </Modal.Header>
        <Modal.Body>
          <Input
            value={this.state.edit?.name?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
            type="text"
            placeholder={Language.getName(Names.Name)}
            onChange={e => this.setName(e.target.value)}
            width={"100%"}
          />
          <label>{Language.getName(Names.Price)}</label>
          <Input
            value={this.state.edit ? this.state.edit.price : ""}
            type="text"
            placeholder={Language.getName(Names.Price)}
            onChange={(e) =>
              this.setState({
                edit: {
                  ...this.state.edit,
                  price: ProductEditor.parsePrice(e.target.value),
                },
              })
            }
            width={"100%"}
          />
          {hasNtakIntegration &&
            <SubSection justify={"flex-start"}>
              <SubSectionLabel width={"30%"}>{Language.getName(Names.NTAKMennyiseg)}: </SubSectionLabel>
              <Input
                width={"15%"}
                margin={"0 6px"}
                padding={"3px 5px"}
                value={this.state.edit ? this.state.edit.ntakQty : ""}
                type="text"
                placeholder={Language.getName(Names.NTAKMennyiseg)}
                onChange={(e) =>
                  this.setState({
                    edit: {
                      ...this.state.edit,
                      ntakQty: ProductEditor.parsePrice(e.target.value),
                    },
                  })
                }
              />
              <Select
                margin={"0"}
                width={"29%"}
                placeholder={Language.getName(Names.ChooseNTAKMennyisegEgyseg)}
                value={this.state.selectedNtakMennyisegEgyseg}
                onChange={(e) => this.selectNTAKMennyisegEgyseg(e)}
                options={this.state.ntakMennyisegEgysegOptions}
                noOptionsMessage={() => Language.getName(Names.NoItemsAvailable)}
              />
            </SubSection>
          }
          <Button
            variant="secondary"
            onClick={() =>
              EventSystem.publish(EventSystem.events.edit_recipe_qty, {
                recipes: this.state.edit.recipes, cb: (recipes) => {
                  this.setState({
                    edit: {
                      ...this.state.edit,
                      recipes: recipes,
                    },
                  });
                },
              })
            }
          >
            {Language.getName(Names.EditProductRecipes)}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
