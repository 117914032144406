import APICaller, { APIMsgBase } from "../APICaller";
import { Profile } from "../../model/Profile";
import { City, Municipality, ZipCode } from "../../model/Address";

export type GetCustomersType =
  APIMsgBase
  & { profiles: Profile[], zipCodes: ZipCode[], cities: City[], municipalities: Municipality[], maxCount: number };

function searchCustomer(query: string = "", cb: (res: GetCustomersType)=>{}) {
  APICaller.ownFetch(true, "POST", "/crm/get", { query }, (res) => {
    let result: GetCustomersType = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

function getCustomers(page: number, cb: (res: GetCustomersType)=>{}) {
  APICaller.ownFetch(true, "PUT", "/crm/get", { page }, (res) => {
    let result: GetCustomersType = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

function addCustomer(firstName: string, lastName: string, email: string, tel: string, cb: (res: GetCustomersType)=>{}) {
  APICaller.ownFetch(true, "PUT", "/crm", { firstName, lastName, email, tel }, (res) => {
    let result: GetCustomersType = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

export const CustomerAPI = {
  searchCustomer,
  getCustomers,
  addCustomer,
};
