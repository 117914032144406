import mergeImages from "merge-images";
import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Element, Zone } from "../../model/BluePrint";
import { BluePrintAPI } from "../../utils/api/BluePrintAPI";
import { BsCloudCheckFill, FaPen, FaPlus, FaTrashAlt, GiTable, MdCancel, MdContentCopy, MdDashboard } from "react-icons/all";
import Language, { Names } from "../../utils/Language";
import { toast } from "react-toastify";
import { HeaderButton } from "./Products";
import { TabBar, TabItem } from "./Orders";
import QRCodeStyling from "qr-code-styling";
import Config from "../../utils/Config";
import blundeeQRLogo from "../../assets/blundee_logos/blundee_qr_logo.png";
import JSZip from "jszip";
import ConfirmationModal from "../modals/ConfirmationModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ListWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 24px;
  height: 100%;

  overflow-y: scroll;
`;

const TableDiv = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px gray;

  min-height: 50px;
  background-color: white;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Div = styled.div`
  width: ${({ width }) => (width ? width : "15%")};
`;

const Controls = styled.div`
  width: ${({ width }) => (width ? width : "20%")};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > div {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    text-align: center;

    & > svg {
      font-size: 22pt;
    }

    &:hover, &:active {
      cursor: pointer;
    }
  }
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-right: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10pt;

    & > svg {
      font-size: 22pt;
    }

    &:hover, &:active {
      cursor: pointer;
    }
  }
`;

export default class SimpleTables extends Component {
  state: {
    language: number,
    elements: Element[],
    zones: Zone[],
    selected: number,
    selectedZoneID: number,
    generatingZIP: boolean,
  } = {
    language: ContextSystem.language,
    zones: [],
    elements: [],
    selected: 0,
    selectedZoneID: -1,
    generatingZIP: false,
  };

  eventIDs = [];

  load() {
    let zones: Zone[] = ContextSystem.zones.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled)
      .sort((e1, e2) => e1.name.localeCompare(e2.name));
    this.setState({
      elements: ContextSystem.elements.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled)
        .sort((e1, e2) => e1.name.localeCompare(e2.name)),
      zones,
      selectedZoneID: zones[0]?.id,
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    this.load();
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, zones, elements }) => {
      if (language !== undefined)
        this.setState({ language });
      if (elements !== undefined || zones !== undefined)
        this.load();
    });
    this.eventIDs.push(eid);
  }

  handlePlusClick() {
    if (this.state.selected === 0)
      EventSystem.publish(EventSystem.events.edit_simpleTable);
    else if (this.state.selected === 1)
      EventSystem.publish(EventSystem.events.edit_zone);
  }

  async downloadAllQRs() {
    if (this.state.generatingZIP)
      return;

    if (!this.state.elements || this.state.elements.length <= 0)
      return;

    this.setState({ generatingZIP: true });

    let zip: JSZip = new JSZip();

    for (let e: Element of this.state.elements) {
      let b64 = await this.generateQR(e);

      let blob = await (await fetch(b64)).blob();
      zip.file("QR_" + e.name + ".png", blob, { binary: true });
    }

    let content: Blob = await zip.generateAsync({ type: "blob" });
    let a = document.createElement("a");
    a.href = URL.createObjectURL(content);
    a.download = "QR Codes.zip";
    a.click();
    this.setState({ generatingZIP: false });
  }

  async generateQR(element: Element): string {
    if (!element)
      return;

    let qr = new QRCodeStyling({
      width: 500,
      height: 500,
      data: Config.frontendURL + "/asztal/" + element.pairedQRID,
      dotsOptions: {
        type: "dots",
        color: "#7f2b25",
      },
      cornersSquareOptions: {
        type: "extra-rounded",
        color: "#7f2b25",
      },
      cornersDotOptions: {
        type: "dot",
        color: "#7f2b25",
      },
      image: blundeeQRLogo,
    });
    let blob = await qr.getRawData("png");
    let url = URL.createObjectURL(blob);
    let src2 = Config.qrImageUrlBase + ContextSystem.selectedShop?.url + ".png";

    return await mergeImages([
      { src: src2, x: 0, y: 0 },
      { src: url, x: 577, y: 577 },
    ]);
  }

  async downloadQR(element: Element) {
    let b64 = await this.generateQR(element);

    let a = document.createElement("a");
    a.href = "data:image/png;base64" + b64;
    a.download = "QR_" + element.name + ".png";
    a.click();
  }

  copyQR(element: Element) {
    let url: string = Config.frontendURL + "/asztal/" + element.pairedQRID;
    navigator.clipboard.writeText(url);
    toast(Language.getName(Names.Copied));
  }

  removeButtonClicked(element: Element) {
    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {},
      ()=>{
        BluePrintAPI.removeSimpleTable(element.id, (res) => {
          if (res.error !== 0)
            return;

          toast(Language.getName(Names.Removed));
          this.load();
        })
      }
    );
  }

  render() {
    let hasEmptyZoneTable: boolean = this.state.elements.find(t => t.zoneID <= 0) !== undefined;

    return (
      <Wrapper>
        <AddButtonWrapper>
          <HeaderButton margin_right={"0"}
                        svg_font_size={"18pt"}
                        background_color={this.state.selected === 0 ? "#E6E6E6" : undefined}
                        onClick={() => this.setState({ selected: 0 })}
          >
            <GiTable />
            <span>{Language.getName(Names.Tables)}</span>
          </HeaderButton>
          <HeaderButton margin_left={"0"}
                        svg_font_size={"18pt"}
                        background_color={this.state.selected === 1 ? "#E6E6E6" : undefined}
                        onClick={() => this.setState({ selected: 1 })}
          >
            <MdDashboard />
            <span>{Language.getName(Names.Zones)}</span>
          </HeaderButton>
          <HeaderButton svg_font_size={"18pt"}
                        onClick={() => this.handlePlusClick()}
          >
            <FaPlus />
            <span>{Language.getName(Names.AddButtonText)}</span>
          </HeaderButton>
          {/*<HeaderButton svg_font_size={"18pt"}*/}
          {/*              onClick={() => this.downloadAllQRs()}*/}
          {/*              disabled={this.state.generatingZIP}*/}
          {/*>*/}
          {/*  {this.state.generatingZIP &&*/}
          {/*    <AiOutlineLoading3Quarters/>*/}
          {/*  }*/}
          {/*  {!this.state.generatingZIP &&*/}
          {/*    <AiOutlineCloudDownload/>*/}
          {/*  }*/}
          {/*  <span>{Language.getName(Names.DownloadAllQR)}</span>*/}
          {/*</HeaderButton>*/}
        </AddButtonWrapper>
        <ListWrapper>
          {this.state.selected === 0 &&
            <>
              <TabBar>
                {hasEmptyZoneTable &&
                  <TabItem
                    key={-1}
                    selected={this.state.selectedZoneID === -1}
                    onClick={() => this.setState({ selectedZoneID: -1 })}
                  >
                    {Language.getName(Names.WithoutZone)}
                  </TabItem>
                }
                {this.state.zones && this.state.zones.map((c, i) => {
                  return (
                    <TabItem
                      key={i}
                      selected={this.state.selectedZoneID === c.id}
                      onClick={() => this.setState({ selectedZoneID: c.id })}
                    >
                      {c.name}
                    </TabItem>
                  );
                })}
              </TabBar>
              <>
                {this.state.elements.length <= 0 &&
                  <>
                    {Language.getName(Names.NoTablesToShowAddRightTop)}
                  </>
                }
                {this.state.elements.map((element, i) => {
                  if (element.zoneID !== this.state.selectedZoneID)
                    return <React.Fragment key={i} />;

                  return (
                    <TableDiv key={i}>
                      <Div width={"30px"}>
                        #{element.id}
                      </Div>
                      <Div width={"120px"}>
                        <b>{Language.getName(Names.Name)}:</b>
                        <br />
                        {element.name}
                      </Div>
                      {/*<Div width={"110px"}>*/}
                      {/*  <b>{Language.getName(Names.QR_ID)}</b>*/}
                      {/*  <br/>*/}
                      {/*  {element.pairedQRID}*/}
                      {/*</Div>*/}
                      <Div width={"160px"}>
                        <b>{Language.getName(Names.CoverRange)}</b>
                        <br />
                        {element.minCover} - {element.maxCover} {Language.getName(Names.person)}
                      </Div>
                      <Div width={"100px"}>
                        <b>{Language.getName(Names.Online)}</b>
                        <br />
                        {element.acceptOnlineBooking &&
                          <BsCloudCheckFill style={{ color: "green" }} />
                        }
                        {!element.acceptOnlineBooking &&
                          <MdCancel style={{ color: "red" }} />
                        }
                      </Div>
                      <Controls>
                        {/*<div onClick={() => this.downloadQR(element)}>*/}
                        {/*  <AiOutlineCloudDownload/>*/}
                        {/*  <span>{Language.getName(Names.DownloadQR)}</span>*/}
                        {/*</div>*/}
                        <div onClick={() => this.copyQR(element)}>
                          <MdContentCopy />
                          <span>{Language.getName(Names.DownloadQR)}</span>
                        </div>
                        <div onClick={() => EventSystem.publish(EventSystem.events.edit_simpleTable, element)}>
                          <FaPen />
                          <span>{Language.getName(Names.Modify)}</span>
                        </div>
                        <div
                          onClick={() => this.removeButtonClicked(element)}
                        >
                          <FaTrashAlt />
                          <span>{Language.getName(Names.Remove)}</span>
                        </div>
                      </Controls>
                    </TableDiv>
                  );
                })}
              </>
            </>
          }
          {this.state.selected === 1 &&
            <>

              {this.state.zones.length <= 0 &&
                <>
                  {Language.getName(Names.NoZonesToShowAddRightTop)}
                </>
              }
              {this.state.zones.map((zone, i) => {
                return (
                  <TableDiv key={i}>
                    <Div width={"30px"}>
                      #{zone.id}
                    </Div>
                    <Div width={"100px"}>
                      <b>{Language.getName(Names.Name)}:</b>
                      <br />
                      {zone.name}
                    </Div>
                    <Controls>
                      <div onClick={() => EventSystem.publish(EventSystem.events.edit_zone, zone)}>
                        <FaPen />
                        <span>{Language.getName(Names.Modify)}</span>
                      </div>
                      <div
                        onClick={() =>
                          BluePrintAPI.removeZone(zone.id, (res) => {
                            if (res.error !== 0)
                              return;

                            toast(Language.getName(Names.Removed));
                            this.load();
                          })
                        }
                      >
                        <FaTrashAlt />
                        <span>{Language.getName(Names.Remove)}</span>
                      </div>
                    </Controls>
                  </TableDiv>
                );
              })}
            </>
          }
        </ListWrapper>
      </Wrapper>
    );
  }
}
