import Language, { Names } from "../utils/Language";

export class ContractStatus {
  static NEW: number = 1;
  static ACCEPTED: number = 2;
  static REJECTED: number = 3;
  static TERMINATED: number = 4;

  static getName(v: number): string {
    switch (v) {
      case ContractStatus.NEW:
        return Language.getName(Names.ContractStateNew);
      case ContractStatus.ACCEPTED:
        return Language.getName(Names.ContractStateValid);
      case ContractStatus.REJECTED:
        return Language.getName(Names.ContractStateDeclined);
      case ContractStatus.TERMINATED:
        return Language.getName(Names.ContractStateStopped);
      default:
        return Language.getName(Names.ContractStateError);
    }
  }
}

export class Contract {
  id: number;
  partnerID: number;
  companyName: string;
  companyVAT: string;
  hqCountry: string;
  hqZip: string;
  hqCity: string;
  hqAddress: string;
  payoutIBAN: string;
  bankName: string;
  representativeName: string;
  contactEmail: string;
  contactTel: string;
  date: Date;
  status: number;
  terminateDate: Date;
  type: ContractType;
  offerID: number;

  constructor(
    id: number, partnerID: number, companyName: string, companyVAT: string, hqCountry: string, hqZip: string,
    hqCity: string, hqAddress: string, payoutIBAN: string, bankName: string, representativeName: string,
    contactEmail: string, contactTel: string, date: Date, status: number, terminateDate: Date, type: ContractStatus,
    offerID: number,
  ) {
    this.id = id;
    this.partnerID = partnerID;
    this.companyName = companyName;
    this.companyVAT = companyVAT;
    this.hqCountry = hqCountry;
    this.hqZip = hqZip;
    this.hqCity = hqCity;
    this.hqAddress = hqAddress;
    this.payoutIBAN = payoutIBAN;
    this.bankName = bankName;
    this.representativeName = representativeName;
    this.contactEmail = contactEmail;
    this.contactTel = contactTel;
    this.date = date;
    this.status = status;
    this.terminateDate = terminateDate;
    this.type = type;
    this.offerID = offerID;
  }
}

export const ContractTypes = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
};

export type ContractType = $Values<typeof ContractTypes>;
