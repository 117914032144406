import React, { Component, ReactNode } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import type { Stackable } from "../../model/Product";
import { TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import { Timeline, TimelineEvent } from "react-event-timeline";
import type { QtyType } from "../../model/Stock";
import { GetQtyChangeTypeName, Qty, QtyChangeTypes, QtyTypes, Storage } from "../../model/Stock";
import { BiLineChartDown, BsFillCartCheckFill, BsFillTrashFill, BsListCheck, FaCompressArrowsAlt, FaExchangeAlt, GiCardExchange, ImBoxAdd, ImBoxRemove } from "react-icons/all";
import type { Option } from "./OrderStateCommentEditor";
import { MultiSelect } from "../home/RawMaterials";

type TimeLineEvent = {
  createdAt: Date,
  title: string,
  color: string,
  icon: ReactNode,
  details: string,
};

export default class StackableTimelineModal extends Component {
  state: {
    showModal: boolean,
    qtyList: Qty[],
    storages: Storage[],
    modelID: number,
    qtyType: QtyType,
    st: Stackable,
    language: number,
    timelineEvents: TimeLineEvent[],
    storageOptions: Option[],
    selectedStorageOptions: Option[],
  } = {
    showModal: false,
    qtyList: [],
    storages: [],
    modelID: -1,
    st: undefined,
    qtyType: undefined,
    language: ContextSystem.language,
    timelineEvents: [],
    storageOptions: [],
    selectedStorageOptions: [],
  };

  load(st: Stackable) {
    this.setState({
      showModal: true,
      modelID: st.id,
      qtyType: st.qtyType,
      st: { ...st },
    }, () => {
      this.loadEvents();
    });
    ContextSystem.loadQtyHistory(st);
  }

  loadEvents() {
    let timelineEvents: TimeLineEvent[] = [];
    let qtyList: Qty[] = ContextSystem.qtyList.filter(
      q => q.modelID === this.state.modelID && q.type === this.state.qtyType);

    qtyList.forEach(q => {
      let storageString = " | (" + Language.getName(Names.DefaultStorage) + ")";

      if (this.state.selectedStorageOptions.find(ss => ss.value === q.storageID) === undefined) {
        return;
      }

      if (q.storageID !== -1)
        storageString = " | (" + (ContextSystem.storages.find(s => s.id === q.storageID)?.name ?? "") + ")";

      if (q.qty !== 0) {
        let icon = <ImBoxAdd />;
        if (this.state.st.qtyType === QtyTypes.RAW && this.state.st.semiProduct)
          icon = <FaCompressArrowsAlt />;
        if (q.qty < 0)
          icon = <ImBoxRemove />;

        let title: string = (this.state.st.qtyType === QtyTypes.RAW && !this.state.st.semiProduct ? Language.getName(
          Names.NewArrival) : Language.getName(Names.Production)) + storageString;

        timelineEvents.push({
          icon,
          title,
          color: q.qty >= 0 ? "green" : "red",
          details: (q.qty >= 0 ? "+" : "") + q.qty + " " + (TranslatableString.get(
            this.state.st.unit) ?? Language.getName(Names.pcs)),
          createdAt: q.addedDate,
        });
      }

      q.reduction.forEach(qr => {
        let icon = <ImBoxRemove />;

        if (qr.changeType === QtyChangeTypes.SELL)
          icon = <BsFillCartCheckFill />;
        else if (qr.changeType === QtyChangeTypes.LOSS)
          icon = <BiLineChartDown />;
        else if (qr.changeType === QtyChangeTypes.WASTE)
          icon = <BsFillTrashFill />;
        else if (qr.changeType === QtyChangeTypes.MANUFACTURE)
          icon = <FaCompressArrowsAlt />;
        else if (qr.changeType === QtyChangeTypes.INVENTORY_DIFFERENCE)
          icon = <BsListCheck />;
        else if (qr.changeType === QtyChangeTypes.MOVE_BETWEEN_STORAGE)
          icon = <GiCardExchange />;
        else if (qr.changeType === QtyChangeTypes.REVERSE_TRANSACTION)
          icon = <FaExchangeAlt />;

        let details = (qr.changeQty >= 0 ? "+" : "") + qr.changeQty + " " + (TranslatableString.get(
          this.state.st.unit) ?? Language.getName(Names.pcs));

        if (qr.changeQty !== 0) {
          timelineEvents.push({
            icon: icon,
            title: GetQtyChangeTypeName(qr.changeType) + storageString,
            color: qr.changeQty >= 0 ? "green" : "red",
            details,
            createdAt: qr.date,
          });
        }
      });
    });

    timelineEvents = timelineEvents.sort((a, b) => b.createdAt - a.createdAt);

    this.setState({ qtyList, timelineEvents });
  }

  loadContext() {
    let storageOptions: Option[] = [];
    storageOptions.push({ value: -1, label: Language.getName(Names.DefaultStorage) });
    for (let storage: Storage of ContextSystem.storages) {
      if (storage.enabled)
        storageOptions.push({ value: storage.id, label: storage.name });
    }

    this.setState({
      storageOptions,
      selectedStorageOptions: storageOptions.map(i => ({ ...i })),
      language: ContextSystem.language,
    });
    this.loadEvents();
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_qty_history, (st: Stackable) => {
      if (st)
        this.load(st);
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, qtyList, storages }) => {
      if (this.state.showModal) {
        if (language !== undefined || qtyList !== undefined || storages !== undefined)
          this.loadContext();
      }
    });
  }

  storageOptionsSelected(s: { value: number, label: string }[]) {
    this.setState({ selectedStorageOptions: s }, () => this.loadEvents());
  }

  render() {
    return (
      <Modal size={"lg"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        {this.state.showModal &&
          <>
            <Modal.Header>
              <Modal.Title>{TranslatableString.get(this.state.st?.name)}</Modal.Title>
              <MultiSelect
                width={"150px"}
                options={this.state.storageOptions}
                value={this.state.selectedStorageOptions}
                onChange={(s) => this.storageOptionsSelected(s)}
                labelledBy={"Select"}
                disableSearch
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 }),
                }}
                overrideStrings={{
                  selectSomeItems: Language.getName(Names.ChooseStorages),
                  allItemsAreSelected: Language.getName(Names.AllStorages),
                  selectAll: Language.getName(Names.SelectAll),
                  search: Language.getName(Names.Search),
                  clearSearch: Language.getName(Names.Clear),
                }}
                hasSelectAll={true}
              />
            </Modal.Header>
            <Modal.Body>
              <Timeline>
                {this.state.timelineEvents.map((e, i) => {
                  return (
                    <TimelineEvent iconColor={e.color} createdAt={e.createdAt.toHunFormatMinutes()} icon={e.icon}
                                   title={e.title} key={i}>
                      {e.details}
                    </TimelineEvent>
                  );
                })}
              </Timeline>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
                {Language.getName(Names.Close)}
              </Button>
            </Modal.Footer>
          </>
        }
      </Modal>
    );
  }
}
