import { OrderExtra, OrderProduct } from "./Order";
import AbstractModel from "./AbstractModel";
import { Hour } from "./Hour";
import { PrinterSetting } from "./Printer";
import ContextSystem from "../utils/ContextSystem";
import { languages } from "../components/modals/CouponEditor";
import type { QtyType } from "./Stock";

export type Stackable = ({ qtyType: QtyType } & (Product | Raw));

export class Product extends AbstractModel {
  name: TranslatableString;
  details: TranslatableString;
  image: string;
  image2: string;
  image3: string;
  price: number;
  showPrice: number;
  originalPrice: number;
  qty: number;
  partnerID: number;
  categoryID: number;
  globalCategoryID: number;
  type: ProductType;
  orderPlace: number;
  menuSelected: boolean;
  active: boolean;
  products: Product[];
  productExtras: ProductExtra[];
  extraGroups: ExtraGroup[];
  versions: Version[];
  recipes: RecipeQty[];
  additives: string;
  allergens: string;
  vatPercentage: number;
  weekSchedule: Hour[];
  uid: string;
  // noinspection JSUnusedGlobalSymbols
  availableExtras: Extra[];
  // noinspection JSUnusedGlobalSymbols
  warehouseInfo: string;
  // noinspection JSUnusedGlobalSymbols
  alcoholPercentage: number;
  // noinspection JSUnusedGlobalSymbols
  countryOrigin: string;
  // noinspection JSUnusedGlobalSymbols
  sku: string;
  // noinspection JSUnusedGlobalSymbols
  gtin: string;
  maxOrder: number;
  // noinspection JSUnusedGlobalSymbols
  countStock: boolean;
  // noinspection JSUnusedGlobalSymbols
  expirationMinutes: boolean;
  productSourcePrices: ProductSourcePrice[];
  nutritions: Nutrition;

  /*not used in db%*/
  // noinspection JSUnusedGlobalSymbols
  orderProduct: OrderProduct[];
  prepared: boolean;
  printerSettings: PrinterSetting[];
  availableOnline: boolean;
  availablePOS: boolean;
  vatValues: VATValue[];
  ntakMainCategory: NTAKMainCategory;
  ntakSubCategory: NTAKSubCategory;
  ntakMennyisegEgyseg: MennyisegEgyseg;
  ntakQty: number;

  constructor() {
    super();
  }

  _splitBy: number = 1; //only used in bill split and CartContent (to show reduced / split price), default is 1
}

export const MennyisegEgysegs = {
  DARAB: "DARAB",
  LITER: "LITER",
  KILOGRAMM: "KILOGRAMM",
  EGYSEG: "EGYSEG"
};

export type MennyisegEgyseg = $Values<typeof MennyisegEgysegs>;
export const NTAKMainCategories = {
  ETEL: "ETEL",
  ALKMENTESITAL_HELYBEN: "ALKMENTESITAL_HELYBEN",
  ALKMENTESITAL_NEM_HELYBEN: "ALKMENTESITAL_NEM_HELYBEN",
  ALKOHOLOSITAL: "ALKOHOLOSITAL",
  EGYEB: "EGYEB"
};

export type NTAKMainCategory = $Values<typeof NTAKMainCategories>;

export const NTAKSubCategories = {
  REGGELI: "REGGELI",
  SZENDVICS: "SZENDVICS",
  ELOETEL: "ELOETEL",
  LEVES: "LEVES",
  FOETEL: "FOETEL",
  KORET: "KORET",
  SAVANYUSAG_SALATA: "SAVANYUSAG_SALATA",
  DESSZERT: "DESSZERT",
  SNACK: "SNACK",
  EGYEB: "EGYEB",
  LIMONADE_SZORP_FACSART: "LIMONADE_SZORP_FACSART",
  ALKOHOLMENTES_KOKTEL: "ALKOHOLMENTES_KOKTEL",
  TEA_FORROCSOKOLADE: "TEA_FORROCSOKOLADE",
  KAVE: "KAVE",
  ITALCSOMAG: "ITALCSOMAG",
  VIZ: "VIZ",
  ROSTOS_UDITO: "ROSTOS_UDITO",
  SZENSAVAS_UDITO: "SZENSAVAS_UDITO",
  SZENSAVMENTES_UDITO: "SZENSAVMENTES_UDITO",
  KOKTEL: "KOKTEL",
  LIKOR: "LIKOR",
  PARLAT: "PARLAT",
  SOR: "SOR",
  BOR: "BOR",
  PEZSGO: "PEZSGO",
  SZERVIZDIJ: "SZERVIZDIJ",
  BORRAVALO: "BORRAVALO",
  KISZALLITASI_DIJ: "KISZALLITASI_DIJ",
  NEM_VENDEGLATAS: "NEM_VENDEGLATAS",
  KORNYEZETBARAT_CSOMAGOLAS: "KORNYEZETBARAT_CSOMAGOLAS",
  MUANYAG_CSOMAGOLAS: "MUANYAG_CSOMAGOLAS",
  KEDVEZMENY: "KEDVEZMENY",
  KOSTOLO: "KOSTOLO",
  PEKSUTEMENY: "PEKSUTEMENY",
  FOETEL_KORETTEL: "FOETEL_KORETTEL",
  ETELCSOMAG: "ETELCSOMAG"
};

export type NTAKSubCategory = $Values<typeof NTAKSubCategories>;

export const NTAKAvailableSubCategories: { mainCategory: NTAKMainCategory, subs: NTAKSubCategory[] }[] = [
  {
    mainCategory: NTAKMainCategories.EGYEB, subs: [
      NTAKSubCategories.SZERVIZDIJ,
      NTAKSubCategories.BORRAVALO,
      NTAKSubCategories.KISZALLITASI_DIJ,
      NTAKSubCategories.NEM_VENDEGLATAS,
      NTAKSubCategories.KORNYEZETBARAT_CSOMAGOLAS,
      NTAKSubCategories.MUANYAG_CSOMAGOLAS,
      NTAKSubCategories.KEDVEZMENY
    ]
  },
  {
    mainCategory: NTAKMainCategories.ALKOHOLOSITAL,
    subs: [
      NTAKSubCategories.KOKTEL,
      NTAKSubCategories.LIKOR,
      NTAKSubCategories.PARLAT,
      NTAKSubCategories.SOR,
      NTAKSubCategories.BOR,
      NTAKSubCategories.PEZSGO,
      NTAKSubCategories.ITALCSOMAG
    ]
  },
  {
    mainCategory: NTAKMainCategories.ALKMENTESITAL_NEM_HELYBEN,
    subs: [
      NTAKSubCategories.VIZ,
      NTAKSubCategories.ROSTOS_UDITO,
      NTAKSubCategories.SZENSAVAS_UDITO,
      NTAKSubCategories.SZENSAVMENTES_UDITO,
      NTAKSubCategories.ITALCSOMAG
    ]
  },
  {
    mainCategory: NTAKMainCategories.ALKMENTESITAL_HELYBEN,
    subs: [
      NTAKSubCategories.VIZ,
      NTAKSubCategories.LIMONADE_SZORP_FACSART,
      NTAKSubCategories.ALKOHOLMENTES_KOKTEL,
      NTAKSubCategories.TEA_FORROCSOKOLADE,
      NTAKSubCategories.ITALCSOMAG,
      NTAKSubCategories.KAVE
    ]
  },
  {
    mainCategory: NTAKMainCategories.ETEL,
    subs: [
      NTAKSubCategories.REGGELI,
      NTAKSubCategories.SZENDVICS,
      NTAKSubCategories.ELOETEL,
      NTAKSubCategories.LEVES,
      NTAKSubCategories.FOETEL,
      NTAKSubCategories.KORET,
      NTAKSubCategories.SAVANYUSAG_SALATA,
      NTAKSubCategories.KOSTOLO,
      NTAKSubCategories.PEKSUTEMENY,
      NTAKSubCategories.DESSZERT,
      NTAKSubCategories.SNACK,
      NTAKSubCategories.FOETEL_KORETTEL,
      NTAKSubCategories.ETELCSOMAG,
      NTAKSubCategories.EGYEB
    ]
  }
];

export class Nutrition {
  key: TranslatableString;
  value: TranslatableString;
}

export class TranslatableString {
  // noinspection JSUnusedGlobalSymbols
  translation: { languageCode: string, value: string }[];
  _editing: string;

  static get(t: TranslatableString) {
    if (!t)
      return undefined;

    if (t.translation && t.translation.length > 0)
      return t.translation?.find(
        n => n.languageCode === languages[ContextSystem.language].languageCode)?.value ?? t.translation[0].value;
    else
      return t;
  }

  static getValues(s: TranslatableString | string): [] {
    if (!s)
      return [];
    if (s.translation)
      return s.translation.map(v => v.value);
    else
      return [s];
  }

  static fillEmptyWithCopy(t: TranslatableString) {
    if (!t)
      return;

    let firstValue: string = t.translation.find(n => n.value.trim().length > 0).value;

    for (let n of t.translation) {
      if (n.value.trim().length <= 0)
        n.value = firstValue;
    }
  }

  static check(t: TranslatableString, a: (({
                                             languageCode: string,
                                             value: string
                                           })=>boolean), and: boolean = true): boolean {
    if (!t || !a)
      return false;

    for (const n of t.translation) {
      if (and && !a(n))
        return false;
      if (!and && a(n))
        return true;
    }
  }

  static modify(s: TranslatableString, newValue: string, atLanguageCode: string) {
    let s2: TranslatableString = {
      _editing: s._editing,
      translation: []
    };

    let found: boolean = false;
    for (let t of s?.translation ?? []) {
      if (t.languageCode === atLanguageCode) {
        s2.translation.push({ languageCode: atLanguageCode, value: newValue });
        found = true;
      } else
        s2.translation.push(t);
    }
    if (!found)
      s2.translation.push({ languageCode: atLanguageCode, value: newValue });
    return s2;
  }

  static create(defaultValue: string = ""): TranslatableString {
    return {
      _editing: "HU-HU",
      translation: [
        {
          languageCode: "HU-HU",
          value: defaultValue
        }, {
          languageCode: "EN-GB",
          value: defaultValue
        }
      ]
    };
  }

  static create2(defaultValue: string[]): TranslatableString {
    let obj: TranslatableString = {
      translation: []
    };
    for (let language of languages) {
      let v: string = defaultValue && language.id < defaultValue.length ? defaultValue[language.id] : "";
      obj.translation.push({ languageCode: language.languageCode, value: v });
    }
    return obj;
  }

  static toIndexedObject(s: TranslatableString): { [keys: string]: string } {
    if (!s)
      return { "HU-HU": "", "EN-GB": "" };

    let v: {} = {};
    for (let record of s?.translation ?? []) {
      v[record.languageCode] = record.value;
    }
    return v;
  }

  static toTranslatableString(v: { [keys: string]: string }): TranslatableString {
    let trs: TranslatableString = { translation: [], _editing: "" };
    for (let languageCode in v) {
      trs.translation.push({ value: v[languageCode], languageCode: languageCode });
    }
    return trs;
  }
}

export class ProductSourcePrice extends AbstractModel {
  partnerID: number;
  productID: number;
  sourceID: number;
  value: number;
  type: ProductPriceType;
}

export class ProductExtra extends AbstractModel {
  extraID: number;
  maxQty: number;
  productID: number;
  partnerID: number;
  orderPlace: number;
  extraGroupID: number;
  shownName: TranslatableString;
  UID: string;

  ///not persistent
  qty: number;
}

export class Extra extends AbstractModel {
  partnerID: number;
  price: number;
  name: TranslatableString;
  recipes: RecipeQty[];

  //not used in database
  orderExtra: OrderExtra;
  printerSettings: PrinterSetting[];
  ntakMennyisegEgyseg: MennyisegEgyseg;
  ntakQty: number;
}

export class ExtraGroup extends AbstractModel {
  name: TranslatableString;
  groupExtras: ProductExtra[];
  versionID: number;
  productID: number;
  partnerID: number;
  minQty: number;
  maxQty: number;
  freeQty: number;
  saleID: number;
  minPrice: number;
  orderPlace: number;
}

export class Version extends AbstractModel {
  productID: number;
  partnerID: number;
  saleID: number; //not used yet
  name: TranslatableString;
  price: number;
  printerSettings: PrinterSetting[];
}

export class Raw extends AbstractModel {
  name: TranslatableString;
  details: TranslatableString;
  unit: TranslatableString;
  minQty: number;
  maxQty: number;
  // noinspection JSUnusedGlobalSymbols
  expirationMinutes: number;
  partnerID: number;
  recipes: RecipeQty[];
  semiProduct: boolean;
}

export class RecipeQty {
  recipe: Recipe;
  qty: number;
}

export class RawQty {
  raw: Raw;
  qty: number;
  defaultStorageID: number;
  allowOtherStorage: boolean;
}

export class Recipe extends AbstractModel {
  raws: RawQty[];
  name: TranslatableString;
  addedDate: Date;
  partnerID: number;
}

export const ProductTypes = {
  PRODUCT: 1,
  MENU: 2
};
export type ProductType = $Values<typeof ProductTypes>

export const ProductPriceTypes = {
  COMMISSION: 0,
  FIX_PRICE: 1
};

export type ProductPriceType = $Values<typeof ProductPriceTypes>

export class Category extends AbstractModel {
  name: TranslatableString;
  img: string;
  parent: number;
  partnerID: number;
  orderNumber: number;
  view: CategoryViewType;
}

export class GlobalCategory extends AbstractModel {
  name: TranslatableString;
  icon1: string;
  icon2: string;
  parentID: number;
  orderNumber: number;
  showInOffers: boolean;
}

export const VATValueModelTypes = {
  PRODUCT: 0,
  SHOP: 1
};

export type VATValueModelType = $Values<typeof VATValueModelTypes>

export class VATValue extends AbstractModel {
  static D_AJT = -1;
  value: number;
  modelID: number;
  shopID: number;
  shippingMode: number;
  paymentMode: number;
  type: VATValueModelType;
  date: Date;
}

export class ProductView extends AbstractModel {
  productID: number;
  categoryID: number;
}

export const CategoryViewTypes = {
  NORMAL_CATEGORY: 0,
  PRIVATE_VIEW: 1,
  PUBLIC_VIEW: 2,
  BLUNDEE_OFFER_LIST: 3,
  USER_SPECIFIC_LIST: 4
};

export type CategoryViewType = $Values<typeof CategoryViewTypes>
