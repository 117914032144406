/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Contract, ContractStatus } from "../../model/Contract";
import Language, { Names } from "../../utils/Language";
import Select2 from "react-select";
import Layout from "../../model/Layout";
import { BsQuestionCircle, FaSignOutAlt } from "react-icons/all";
import { AuthAPI } from "../../utils/api/AuthAPI";
import { Shop } from "../../model/Shop";
import { Button } from "../FormComponents";
import { pulseBgAnimation } from "../home/Orders";
import { ButtonTag } from "../home/StandingComponent";
import { Order, OrderState } from "../../model/Order";
import { ShippingMethods } from "../../model/ShippingPrice";
import { Button as ButtonStandComponent } from "../../components/home/StandingComponent";
import type { OptionValue } from "../modals/InwardHandlingModal";
import type { ShopProfileType } from "../../model/ShopProfile";
import { ShopProfile, ShopProfileTypes } from "../../model/ShopProfile";
import { Storage, StorageTypes } from "../../model/Stock";

const ShopSelect = styled(Select2)`
  width: 130px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
`;

const LanguageSelect = styled(Select2)`
  width: 80px;
  margin-right: 5px;
  font-size: 12px;
`;

const CounterSelect = styled(Select2)`
  width: 120px;
  margin-right: 5px;
  font-size: 12px;
`;

const LayoutSelect = styled(Select2)`
  width: 130px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
`;

export const HeaderButton = styled.button`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  height: 36px;
  margin: 0 5px;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #cecece;
  color: #cecece;

  transition: color 100ms ease-in-out, border 100ms ease-in-out;

  &:hover, &:active {
    color: #a1a1a1;
    border-color: #a1a1a1;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  ${({ selected }) => selected === true && css`
    color: #841a1e;
    border-color: #841a1e;

    &:hover, &:active {
      color: #a62327;
      border-color: #a62327;
    }
  `};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 20;
  padding: 5px;
  
  /*choose*/
  background-color: white;
  /*background-image: linear-gradient(45deg, rgba(141, 21, 22, 0.9), rgba(18, 25, 69, 0.9), rgba(255, 68, 70, 0.9));*/
  box-shadow: 0 0 10px -1px #6a0800;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    height: fit-content;
  }
`;

const NavBar = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  top: 0;
  left: 0;
  z-index: 19;

  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  color: #a62024;

  .logo-navlink {
    height: 100%;
    box-sizing: border-box;
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;

  max-height: 100%;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    margin: 5px;
  }
`;

const StartContractButton = styled(Button)`
  margin-left: 25px;
  background-color: #4a7dbf;
  color: white;

  &:hover, &:active {
    background-color: #204772;
  }
`;

const OfflineDivWrapper = styled.div`
  font-size: 9pt;
  color: #e30808;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const OfflineDiv = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 6px;

  border-radius: 300px;
  background-color: #e30808;

  animation: ${pulseBgAnimation} 0.5s ease-in-out infinite alternate;
`;

class Header extends Component {
  lastScroll = 0;
  lastWidth = 0;
  lastHeight = 0;

  getShopOption(selectedShop: Shop): { value: number, label: string } {
    if (!this.state?.shopOptions || !selectedShop)
      return { value: -1, label: "..." };

    for (let shopOption of this.state.shopOptions) {
      if (shopOption.value === selectedShop.id)
        return shopOption;
    }
    return { value: -1, label: "..." };
  }

  getLanguageOption(id: number): { value: number, label: string } {
    if (!this.state?.languageOptions)
      return { value: -1, label: "..." };

    for (let languageOption of this.state.languageOptions) {
      if (languageOption.value === id)
        return languageOption;
    }
    return { value: -1, label: "..." };
  }

  getLayoutOption(id: number): { value: number, label: string } {
    if (!this.state?.layoutOptions)
      return { value: -1, label: "..." };

    for (let layoutOption of this.state.layoutOptions) {
      if (layoutOption.value === id)
        return layoutOption;
    }
    return { value: -1, label: "..." };
  }

  state: {
    shops: Shop[],
    orders: Order[],
    selectedShop: Shop,
    menuOpened: boolean,
    windowWidth: number,
    windowHeight: number,
    scrolled: boolean,
    showing: boolean,
    mouseAtTop: boolean,
    authenticated: boolean,
    editSearch: string,
    contracts: Contract[],
    selectedLanguage: { value: number, label: string },
    selectedLayout: { value: number, label: string },
    selectedMenu: number,
    languageOptions: { value: number, label: string }[],
    layoutOptions: { value: number, label: string }[],
    shopOptions: { value: number, label: string }[],
    online: boolean,
    profile: ShopProfile,
    storages: Storage[],
    selectedCounter: OptionValue,
  } = {
    shops: ContextSystem.shops,
    orders: [],
    selectedShop: ContextSystem.selectedShop,
    menuOpened: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    scrolled: false,
    showing: true,
    mouseAtTop: true,
    authenticated: ContextSystem.loggedIn,
    editSearch: "",
    contracts: ContextSystem.contracts.filter(
      c => c.partnerID === ContextSystem.selectedShop.id || c.id === ContextSystem.selectedShop.contractID),
    selectedLanguage: this.getLanguageOption(ContextSystem.language),
    selectedLayout: this.getLayoutOption(ContextSystem.layout),
    selectedMenu: this.getShopOption(ContextSystem.selectedShop),
    languageOptions: [],
    layoutOptions: [],
    online: ContextSystem.online,
    profile: ContextSystem.profile,
    storages: ContextSystem.storages.filter(e => e.enabled && e.shopIDs.includes(ContextSystem.selectedShop?.id)),
    selectedCounter: { value: -1, label: Language.getName(Names.DefaultStorage) },
  };

  buildLayoutOptions(): void {
    let layoutOptions: OptionValue[] = [];
    if (ContextSystem.selectedShop && ContextSystem.profile?.permissions) {
      let permissions: ShopProfileType[] = ContextSystem.profile.permissions[ContextSystem.selectedShop?.id];
      if (permissions) {
        if (permissions.includes(ShopProfileTypes.MANAGER))
          layoutOptions.push({ value: Layout.MANAGER, label: Language.getName(Names.Manager) });
        if (permissions.includes(ShopProfileTypes.STAND))
          layoutOptions.push({ value: Layout.STANDING, label: Language.getName(Names.Standing) });
        if (permissions.includes(ShopProfileTypes.WAITER))
          layoutOptions.push({ value: Layout.WAITER, label: Language.getName(Names.Waiter) });
        if (permissions.includes(ShopProfileTypes.KITCHEN))
          layoutOptions.push({ value: Layout.KITCHEN, label: Language.getName(Names.Kitchen) });
        // if (permissions.includes(ShopProfileTypes.DISPATCHER))
        // layoutOptions.push({value: Layout.DISPATCHER, label: Language.getName(Names.Dispatcher)});
      }
    }
    this.setState({
      layoutOptions,
    }, () => this.setState({
      selectedLayout: this.getLayoutOption(ContextSystem.layout),
    }));
  }

  buildShopOptions(): void {
    let shopOptions: { value: number, label: string }[] = [];
    if (ContextSystem.shops) {
      ContextSystem.shops.forEach(s => {
        shopOptions.push({
          value: s.id,
          label: s.name,
        });
      });
    }

    this.setState({ shopOptions });
  }

  buildLanguageOptions(): void {
    this.setState({
      languageOptions: [
        { value: 0, label: "HU" },
        { value: 1, label: "EN" },
        //{value: 2, label: "Denmark"},
      ],
    }, () => this.setState({
      selectedLanguage: this.getLanguageOption(ContextSystem.language),
    }));
  }

  menuStateChanged(opened) {
    this.setState({ menuOpened: opened });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  loadContext() {
    let selectedCounter: OptionValue = { label: Language.getName(Names.DefaultStorage), value: -1 };
    if (ContextSystem.selectedCounter > 0)
      selectedCounter = {
        value: ContextSystem.selectedCounter,
        label: ContextSystem.storages.find((st: Storage) => st.id === ContextSystem.selectedCounter)?.name ?? "-",
      };

    this.setState({
      authenticated: ContextSystem.loggedIn,
      contracts: ContextSystem.contracts.filter(c => c.partnerID === ContextSystem.selectedShop?.id),
      online: ContextSystem.online,
      orders: ContextSystem.orders.filter(o => o.enabled && o.partnerID === ContextSystem.selectedShop?.id),
      storages: ContextSystem.storages.filter(e => e.enabled && e.shopIDs.includes(ContextSystem.selectedShop?.id)),
      selectedCounter,
    });
  }

  eventIDs = [];

  componentDidMount() {
    this.buildLanguageOptions();
    this.buildShopOptions();
    this.buildLayoutOptions();

    this.lastHeight = window.innerHeight;
    this.lastWidth = window.innerWidth;
    this.lastSelectedMenu = 0;

    this.loadContext();

    EventSystem.subscribe(EventSystem.events.sideMenu, (opened) => {
      this.menuStateChanged(opened);
    });

    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
      if (this.lastHeight === window.innerHeight) {
        if (this.lastScroll < window.scrollY && window.scrollY > 100) {
          this.setState({ showing: false });
          EventSystem.publish(EventSystem.events.sideMenu, false);
        } else {
          this.setState({ showing: true });
        }
      }

      this.lastHeight = window.innerHeight;
      this.lastWidth = window.innerWidth;
      this.lastScroll = window.scrollY;
    });

    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         selectedCounter,
         storages, loggedIn, contracts, language, profile, layout,
         selectedMenu, shops, selectedShop, online, orders,
       }) => {
        if (selectedCounter !== undefined || storages !== undefined || loggedIn !== undefined || online !== undefined || orders !== undefined || contracts !== undefined) {
          this.loadContext();
        }

        if (shops !== undefined) {
          this.buildShopOptions();
          this.buildLayoutOptions();
          this.setState({
            shops,
            selectedShop: this.getShopOption(ContextSystem.selectedShop),
          });
        }
        if (profile !== undefined) {
          this.buildShopOptions();
          this.buildLayoutOptions();
          this.setState({
            profile,
            selectedShop: this.getShopOption(ContextSystem.selectedShop),
          });
        }
        if (selectedShop !== undefined) {
          this.setState({
            selectedShop: this.getShopOption(selectedShop),
            contracts: (ContextSystem.contracts ?? []).filter(
              c => c.partnerID === ContextSystem.selectedShop.id || c.id === ContextSystem.selectedShop.contractID),
          });
        }
        if (language !== undefined) {
          this.buildLanguageOptions();
          this.buildLayoutOptions();
          this.setState({ selectedLanguage: this.getLanguageOption(language) });
        }
        if (layout !== undefined) {
          this.buildLanguageOptions();
          this.buildLayoutOptions();
          this.setState({ selectedLayout: this.getLayoutOption(layout) });
        }
        if (selectedMenu !== undefined) {
          this.setState({ selectedMenu });
        }
      },
    );
    this.eventIDs.push(eid);
  }

  helpButtonClicked() {
    if (this.state.selectedMenu === 7) {
      ContextSystem.selectMenu(this.lastSelectedMenu);
      this.lastSelectedMenu = 7;
    } else {
      this.lastSelectedMenu = this.state.selectedMenu;
      ContextSystem.selectMenu(7);
    }
  }

  getNotConfirmedOrdersNumber(): number {
    let allowedTypes: number[] = [ShippingMethods.AT_PLACE];
    if (this.state.selectedShop.posWaiterShowNonLocalOrders) {
      allowedTypes.push(ShippingMethods.DELIVERY);
      allowedTypes.push(ShippingMethods.VENDOR_DELIVERY);
      allowedTypes.push(ShippingMethods.PICKUP);
    }

    return this.state.orders.filter(o =>
      o.lastState?.status === OrderState.NEW
      && allowedTypes.includes(o.shippingMethod),
    ).length;
  }

  handleOrdersClicked() {
    EventSystem.publish(EventSystem.events.open_orders_modal);
  }

  handleShiftControlButton() {
    EventSystem.publish(EventSystem.events.open_close_of_pay_modal);
  }

  render() {
    let storages: Storage[] = this.state.storages.filter(st => st.type === StorageTypes.COUNTER);
    // storages.unshift({
    //   name: Language.getName(Names.DefaultStorage),
    //   type: StorageTypes.COUNTER,
    //   enabled: true,
    //   id: -1,
    //   addedDate: new Date(),
    //   shopIDs: [ContextSystem.selectedShop?.id]
    // });

    return (
      <HeaderWrapper>
        <NavBar>
          <NavLink className={"logo-navlink"} to={"/"}>
            <HeaderLogo />
          </NavLink>
          {this.state.authenticated &&
            <>
              {this.state.selectedLayout.value === Layout.MANAGER && this.state.contracts && !this.state.contracts.find(
                  c => c.status === ContractStatus.NEW || c.status === ContractStatus.ACCEPTED) &&
                <StartContractButton onClick={() => this.handleContractClick()}>
                  {Language.getName(Names.StartContract)}
                </StartContractButton>
              }
              {this.state.selectedLayout.value === Layout.WAITER &&
                <ButtonStandComponent onClick={() => this.handleOrdersClicked()}
                                      bg_color={"rgb(175,9,112)"}
                                      bg_color2={"rgb(208,15,138)"}
                                      bg_color3={"rgb(139,8,88)"}
                                      color={"white"}
                                      border_color={"rgb(86,112,164)"}
                                      width={"100px"}
                                      m_width={"80px"}
                >
                  {(() => {
                    let newOrders: number = this.getNotConfirmedOrdersNumber();
                    if (newOrders <= 0)
                      return <></>;
                    else
                      return (
                        <ButtonTag
                          width={"18px"}
                          height={"18px"}
                          top={"-7px"}
                          right={"-7px"}
                        >
                          {this.getNotConfirmedOrdersNumber()}
                        </ButtonTag>
                      );
                  })()}
                  {Language.getName(Names.Orders)}
                </ButtonStandComponent>
              }
            </>
          }
          {this.state.authenticated && !this.state.online &&
            <OfflineDivWrapper>
              <OfflineDiv />
              {Language.getName(Names.NoConnection)}
            </OfflineDivWrapper>
          }
          <RightWrapper>
            {this.state.selectedLayout.value === Layout.STANDING &&
              <ButtonStandComponent onClick={() => this.handleShiftControlButton()}
                                    bg_color={"rgb(175,9,112)"}
                                    bg_color2={"rgb(208,15,138)"}
                                    bg_color3={"rgb(139,8,88)"}
                                    color={"white"}
                                    border_color={"rgb(86,112,164)"}
                                    width={"100px"}
                                    height={"38px"}
                                    m_width={"80px"}
              >
                {Language.getName(Names.COP)}
              </ButtonStandComponent>
            }
            {this.state.authenticated && this.state.storages.length > 0 && this.state.selectedLayout.value === Layout.STANDING &&
              <CounterSelect
                placeholder={Language.getName(Names.UsedStorage)}
                value={this.state.selectedCounter}
                onChange={(e) => ContextSystem.selectCounter(e.value)}
                options={storages.filter(st => st.type === StorageTypes.COUNTER).map(st => ({
                  value: st.id,
                  label: st.name,
                }))}
              />
            }
            <ShopSelect
              placeholder={Language.getName(Names.SelectShopText)}
              noOptionsMessage={() => Language.getName(Names.NoShopsToShow)}
              value={this.state.selectedShop}
              onChange={(e) => ContextSystem.setSelectedShopByID(e.value)}
              options={this.state.shopOptions}
            />
            <LanguageSelect
              placeholder={Language.getName(Names.MoreLanguageText)}
              noOptionsMessage={() => Language.getName(Names.NoLanguagesToShow)}
              value={this.state.selectedLanguage}
              onChange={(e) => ContextSystem.setLanguage(e.value)}
              options={this.state.languageOptions}
            />
            {this.state.authenticated &&
              <>
                <LayoutSelect
                  placeholder={Language.getName(Names.LayoutSelectText)}
                  noOptionsMessage={() => Language.getName(Names.NoLayoutToShow)}
                  value={this.state.selectedLayout}
                  onChange={(e) => ContextSystem.setLayout(e.value)}
                  options={this.state.layoutOptions}
                />
                <HeaderButton selected={this.state.selectedMenu === 7} onClick={() => this.helpButtonClicked()}>
                  <BsQuestionCircle />
                </HeaderButton>
                <HeaderButton onClick={() => AuthAPI.logout()}>
                  <FaSignOutAlt />
                </HeaderButton>
              </>
            }
          </RightWrapper>
        </NavBar>
      </HeaderWrapper>
    );
  }

  handleContractClick() {
    EventSystem.publish(EventSystem.events.open_contracts_details);
  }
}

export default withRouter(Header);
