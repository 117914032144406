import React, { Component } from "react";
import styled from "styled-components";
import StandingComponent from "../StandingComponent";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default class StandingLayout extends Component {
  render() {
    return (
      <Wrapper>
        <StandingComponent
          origin={"counter"}
          selectTable={true}
          onDone={undefined}
          order={undefined}
          padding={undefined}
          showOrdersButton={true}
          tableSelected={undefined}
        />
      </Wrapper>
    );
  }
}
