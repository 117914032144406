import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import styled from "styled-components";
import StandingComponent from "../home/StandingComponent";
import { Order } from "../../model/Order";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  flex-grow: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default class OrderEditor extends Component {
  state: {
    language: number,
    showModal: boolean,
    order: Order,
    table: Element,
    showCount: number
  } = {
    language: ContextSystem.language,
    showModal: false,
    order: undefined,
    table: undefined,
    showCount: 1,
  };

  save() {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.modify_order, ({ order, table }) => {
      let orderCopy: Order = order === undefined ? undefined : { ...order };

      if (orderCopy !== undefined) {
        orderCopy.orderTotalPrice = orderCopy.originalOrderTotalPrice;
        orderCopy.shippingPrice = orderCopy.originalShippingPrice;
      }

      this.setState({
        showModal: true,
        showCount: this.state.showCount + 1,
        order: orderCopy,
        table,
      });
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  render() {
    return (
      <Modal size={"full_scroll"} show={this.state.showModal}
             onEscapeKeyDown={() => this.setState({ order: undefined, showModal: false })}
      >
        <Modal.Body height={"100%"} padding={"6px"} display={"flex"}>
          <Wrapper>
            <StandingComponent
              origin={"orderEditor"}
              key={this.state.showCount}
              onDone={() => this.setState({ order: undefined, showModal: false })}
              selectTable={true}
              tableSelected={this.state.table}
              padding={"0"}
              showOrdersButton={false}
              order={this.state.order}
            />
          </Wrapper>
        </Modal.Body>
      </Modal>
    );
  }
}
