import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import Extras from "../home/Extras";
import { Button } from "../FormComponents";

export default class ExtrasModal extends Component {
  state: {
    showModal: boolean,
    language: number,
  } = {
    showModal: false,
    language: ContextSystem.language,
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_extras, () => {
      this.setState({ showModal: true });
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"xl"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.Toppings)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Extras />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
