import AbstractModel from "./AbstractModel";
import { Hour } from "./Hour";

export class Element extends AbstractModel {
  x: number;
  y: number;
  width: number;
  height: number;
  zoneID: number;
  partnerID: number;
  pairedQRID: number;
  rotate: number;
  image: string;
  name: string;
  type: ElementType;
  minCover: number;
  maxCover: number;
  acceptOnlineBooking: boolean;
}

export const ElementTypes = {
  TABLE: 0,
  CHAIR: 1,
  FLOWERS: 2,
  STAIRS: 3,
  SINK: 4,
  MIRROR: 5,
  PISSOIR: 6,
  TOILET_WC: 7,
  RAMP: 8,
  DOOR: 9,
  BAR: 10,
  COMPUTER: 11,
  DECOR: 12,
  HANDRAIL: 13,
  ELEVATOR: 14,
  WINDOW: 15,
  LAMP: 16,
  TREE: 17,
  OTHER: 100,
};

export type ElementType = $Values<typeof ElementTypes>;

export class Floor extends AbstractModel {
  floor: number;
  partnerID: number;
  name: string;
  zones: Zone[];
}

export class Zone extends AbstractModel {
  floorID: number;
  maxPeople: number;
  partnerID: number;
  name: string;
  type: ZoneType;
  openHours: Hour[];
  elements: Element[];
  customOpenHours: boolean;
}

export const ZoneTypes = {
  DINING_AREA: 0,
  TOILETS: 1,
  RESTROOM_MENS: 2,
  RESTROOM_LADIES: 3,
  HALL: 4,
  WAITING_AREA: 5,
  BAR: 6,
  DANCING_AREA: 7,
  OUTDOOR_SEATING: 8,
  STAIRS_ROOM: 9,
  KITCHEN: 10,
  BILLING_AREA: 11,
  OTHER: 100,
};

export type ZoneType = $Values<typeof ZoneTypes>;
