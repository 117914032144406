import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { Contract } from "../../model/Contract";

function remove(offerID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/contract", { offerID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type AddContractResponseType = APIMsgBase & { contract: Contract };

function add(contract: Contract, offerID: number, cb: (res: AddContractResponseType)=>{}) {
  APICaller.ownFetch(false, "PUT", "/contract", { ...contract, offerID }, (res) => {
    let result: AddContractResponseType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ContractAPI = {
  remove,
  add,
};
