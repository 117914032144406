import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { AuthAPI } from "./AuthAPI";
import { ShopProfile } from "../../model/ShopProfile";

function activate(passphrase: string, password: string, cb: (authenticated: boolean)=>{}) {
  APICaller.ownFetch(false, "POST", "/activate", {
    passphrase,
    password,
  }, (data: string) => AuthAPI.defaultLoginHandler(data, cb));
}

function activateShopProfile(
  token: string, password: string, firstName: string, lastName: string, pin: string, phoneNumber: string,
  cb: (res: APIMsgBase)=>{},
) {
  APICaller.ownFetch(false, "PUT", "/shop/profile/activate", {
      password, token, firstName, lastName, phoneNumber, pin,
    }, (data: string) => {
      let res: APIMsgBase = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

export type SaveDetailsResponse = APIMsgBase & { shopProfile: ShopProfile };

function saveProfileDetails(
  firstName: string, lastName: string, pin: string, phoneNumber: string, cb: (res: SaveDetailsResponse)=>{}) {
  APICaller.ownFetch(false, "POST", "/shop/profile/details", {
      firstName, lastName, phoneNumber, pin,
    }, (data: string) => {
      let res: SaveDetailsResponse = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

export type checkPassphraseResultType = APIMsgBase & { valid: boolean };

function checkPassphrase(passphrase: string, cb: (res: checkPassphraseResultType)=>{}) {
  APICaller.ownFetch(true, "PUT", "/activate", { passphrase }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ActivateAPI = {
  activate,
  checkPassphrase,
  activateShopProfile,
  saveProfileDetails,
};
