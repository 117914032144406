import APICaller, { APIMsgBase } from "../APICaller";
import { Raw } from "../../model/Product";
import type { QtyType } from "../../model/Stock";
import { Qty, QtyChange } from "../../model/Stock";
import ErrorMessage from "./ErrorMessages";
import type { MoveQty } from "../../components/modals/MoveStorageModal";

export type GetRawsType = APIMsgBase & { raws: Raw[] };

function getRaws(cb: (res: GetRawsType)=>{}) {
  APICaller.ownFetch(true, "POST", "/raws", {}, (res) => {
    let result: GetRawsType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function save(raw: Raw, cb: (res: APIMsgBase)=>{}) {
  raw = { ...raw };
  //we got one issue, this is why I wrapped it into a new Date(..)
  raw.recipes.forEach(r => r.recipe.addedDate = new Date(r.recipe.addedDate).getTime());

  APICaller.ownFetch(false, "POST", "/raw", { ...raw }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(rawID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/raw", { id: rawID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function handleInward(qtyList: Qty[], cb: (res: APIMsgBase)=>{}) {
  qtyList = qtyList.map(q => ({ ...q, addedDate: q.addedDate.getTime() }));
  APICaller.ownFetch(false, "PUT", "/qty", { qtyList: qtyList }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function handleProductionManufactured(qtyList: Qty[], cb: (res: APIMsgBase)=>{}) {
  qtyList = qtyList.map(q => ({ ...q, addedDate: q.addedDate.getTime() }));
  APICaller.ownFetch(false, "PUT", "/manufacture", { qtyList: qtyList }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function stockTaking(qtyList: QtyChange[], cb: (res: APIMsgBase)=>{}) {
  qtyList = qtyList.map(q => ({ ...q, date: q.date.getTime() }));
  APICaller.ownFetch(false, "PUT", "/qty/change", { qtyList }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type QtyHistoryResType = { qtyList: Qty[] } & APIMsgBase;

function getCompleteHistory(modelID: number, modelType: QtyType, cb: (res: QtyHistoryResType)=>{}) {
  APICaller.ownFetch(false, "POST", "/qty", { modelID, modelType }, (res) => {
    let result: QtyHistoryResType = JSON.parse(res);

    if (result.error === ErrorMessage.OK) {
      result.qtyList.forEach(q => {
        q.addedDate = new Date(q.addedDate);
        q.reduction.forEach(r => r.date = new Date(r.date));
      });
    }

    if (cb)
      cb(result);
  });
}

function moveStorage(fromID: number, toID: number, moveQty: MoveQty[], cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/qty/move", { fromID, toID, moveQty }, (res) => {
    let result: APIMsgBase = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

export const RawAPI = {
  getRaws,
  moveStorage,
  getCompleteHistory,
  save,
  remove,
  handleInward,
  stockTaking,
  handleProductionManufactured,
};
