import AbstractModel from "./AbstractModel";
import Language, { Names } from "../utils/Language";
import { ShippingMethods } from "./ShippingPrice";
import { BsCreditCard2Front, BsCurrencyDollar, MdCreditScore, SiContactlesspayment } from "react-icons/all";
import React from "react";

export class PaymentMethodSetting extends AbstractModel {
  active: boolean;
  partnerID: number;
  paymentID: number;
}

export const PaymentMethodTypes: { [keys: string]: number } = {
  CASH: 1,
  CASH_BIG_BILLS: 2,
  COD_CREDIT_CARD: 3,
  COD_SZEP_CARD_KH: 4,
  COD_SZEP_CARD_OTP: 5,
  COD_SZEP_CARD_MKB: 6,
  ONLINE_CREDIT_CARD: 7,
  ONLINE_SZEP_KH: 8,
  ONLINE_SZEP_OTP: 9,
  ONLINE_SZEP_MKB: 10,

  LOCAL_SZEP_CARD_KH: 11,
  LOCAL_SZEP_CARD_OTP: 12,
  LOCAL_SZEP_CARD_MKB: 13,
  TAKEAWAY_CREDIT_CARD: 14,

  ONLINE_MOBILEPAY_DK: 20,
  FOODORA: 21,
  WOLT: 22,

  ANY: 100,
};

export const AllPaymentMethod: { type: number, countries: number[], availableShippingMethods: number[] }[] = [
  {
    type: PaymentMethodTypes.CASH,
    countries: [1, 2],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.CASH_BIG_BILLS,
    countries: [1, 2],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },

  {
    type: PaymentMethodTypes.ONLINE_CREDIT_CARD,
    countries: [1, 2],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.ONLINE_SZEP_KH,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.ONLINE_SZEP_OTP,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
  // {
  //   type: PaymentMethodTypes.ONLINE_SZEP_MKB,
  //   countries: [],
  //   availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP]
  // },

  {
    type: PaymentMethodTypes.COD_CREDIT_CARD,
    countries: [1, 2],
    availableShippingMethods: [ShippingMethods.DELIVERY],
  },
  {
    type: PaymentMethodTypes.COD_SZEP_CARD_KH,
    countries: [1],
    availableShippingMethods: [ShippingMethods.DELIVERY],
  },
  {
    type: PaymentMethodTypes.COD_SZEP_CARD_OTP,
    countries: [1],
    availableShippingMethods: [ShippingMethods.DELIVERY],
  },
  {
    type: PaymentMethodTypes.COD_SZEP_CARD_MKB,
    countries: [1],
    availableShippingMethods: [ShippingMethods.DELIVERY],
  },

  {
    type: PaymentMethodTypes.TAKEAWAY_CREDIT_CARD,
    countries: [1, 2],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.LOCAL_SZEP_CARD_KH,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.LOCAL_SZEP_CARD_OTP,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.LOCAL_SZEP_CARD_MKB,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.ONLINE_MOBILEPAY_DK,
    countries: [2],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.FOODORA,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
  {
    type: PaymentMethodTypes.WOLT,
    countries: [1],
    availableShippingMethods: [ShippingMethods.AT_PLACE, ShippingMethods.DELIVERY, ShippingMethods.PICKUP],
  },
];

export type PaymentMethodType = $Values<typeof PaymentMethodTypes>;

export const PaymentMethods = {
  ...PaymentMethodTypes,

  isOnline(paymentID: PaymentMethodType): boolean {
    return [
      PaymentMethodTypes.ONLINE_CREDIT_CARD, PaymentMethodTypes.ONLINE_SZEP_KH, PaymentMethodTypes.ONLINE_SZEP_OTP,
    ].includes(paymentID);
  },
  getName(paymentID: PaymentMethodType) {
    switch (paymentID) {
      case PaymentMethodTypes.CASH:
        return Language.getName(Names.Cash);
      case PaymentMethodTypes.CASH_BIG_BILLS:
        return Language.getName(Names.CashBigBill);

      case PaymentMethodTypes.COD_CREDIT_CARD:
        return Language.getName(Names.CODCredit);
      case PaymentMethodTypes.COD_SZEP_CARD_KH:
        return Language.getName(Names.COD_SZEP_KH);
      case PaymentMethodTypes.COD_SZEP_CARD_OTP:
        return Language.getName(Names.COD_SZEP_OTP);
      case PaymentMethodTypes.COD_SZEP_CARD_MKB:
        return Language.getName(Names.COD_SZEP_MKB);

      case PaymentMethodTypes.ONLINE_CREDIT_CARD:
        return Language.getName(Names.CREDITCARD_ONLINE);
      case PaymentMethodTypes.ONLINE_SZEP_KH:
        return Language.getName(Names.SZEP_ONLINE_KH);
      case PaymentMethodTypes.ONLINE_SZEP_OTP:
        return Language.getName(Names.SZEP_ONLINE_OTP);
      case PaymentMethodTypes.ONLINE_SZEP_MKB:
        return Language.getName(Names.SZEP_ONLINE_MKB);

      case PaymentMethodTypes.LOCAL_SZEP_CARD_KH:
        return Language.getName(Names.LOCAL_SZEP_CARD_KH);
      case PaymentMethodTypes.LOCAL_SZEP_CARD_OTP:
        return Language.getName(Names.LOCAL_SZEP_CARD_OTP);
      case PaymentMethodTypes.LOCAL_SZEP_CARD_MKB:
        return Language.getName(Names.LOCAL_SZEP_CARD_MKB);
      case PaymentMethodTypes.TAKEAWAY_CREDIT_CARD:
        return Language.getName(Names.TAKEAWAY_CREDIT_CARD);

      case PaymentMethodTypes.ONLINE_MOBILEPAY_DK:
        return Language.getName(Names.ONLINE_MOBILEPAY_DK);
      case PaymentMethodTypes.FOODORA:
        return Language.getName(Names.FOODORA);
      case PaymentMethodTypes.WOLT:
        return Language.getName(Names.WOLT);
    }
  },

  getIcon(paymentID: PaymentMethodType) {
    switch (paymentID) {
      case PaymentMethodTypes.CASH:
        return <BsCurrencyDollar />;
      case PaymentMethodTypes.CASH_BIG_BILLS:
        return <BsCurrencyDollar />;

      case PaymentMethodTypes.COD_CREDIT_CARD:
        return <BsCreditCard2Front />;
      case PaymentMethodTypes.COD_SZEP_CARD_KH:
        return "Szép-K&H";
      case PaymentMethodTypes.COD_SZEP_CARD_OTP:
        return "Szép-OTP";
      case PaymentMethodTypes.COD_SZEP_CARD_MKB:
        return "Szép-MKB";

      case PaymentMethodTypes.ONLINE_CREDIT_CARD:
        return <MdCreditScore />;
      case PaymentMethodTypes.ONLINE_SZEP_KH:
        return "Szép-K&H";
      case PaymentMethodTypes.ONLINE_SZEP_OTP:
        return "Szép-OTP";
      case PaymentMethodTypes.ONLINE_SZEP_MKB:
        return "Szép-MKB";

      case PaymentMethodTypes.LOCAL_SZEP_CARD_KH:
        return "Szép-K&H";
      case PaymentMethodTypes.LOCAL_SZEP_CARD_OTP:
        return "Szép-OTP";
      case PaymentMethodTypes.LOCAL_SZEP_CARD_MKB:
        return "Szép-MKB";
      case PaymentMethodTypes.TAKEAWAY_CREDIT_CARD:
        return <SiContactlesspayment />;
      case PaymentMethodTypes.FOODORA:
        return "Foodora";
      case PaymentMethodTypes.WOLT:
        return "Wolt";

      case PaymentMethodTypes.ONLINE_MOBILEPAY_DK:
        return "MobilePay";
    }
  },
};
