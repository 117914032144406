import AbstractModel from "./AbstractModel";
import type { CouponModelType } from "./Coupon";

export class UpsellRule extends AbstractModel {
  shippingMode: number;
  paymentMode: number;
  type: UpsellType;
  date: Date;
  productIDs: UpsellRuleModel[];
  shopIDs: UpsellRuleModel[];
  productsTriggering: UpsellRuleModel[];
  couponsTriggering: UpsellRuleModel[];
  minCartValue: number;
  maxCartValue: number;
}

export const UpsellTypes = {
  POS_AT_END_OF_ORDER: 0,
  POS_WHILE_ORDERING: 1,
  APP_AT_END_OF_ORDER: 2,
  APP_WHILE_ORDERING: 3,
  APP_BEFORE_PAYMENT: 4,
  APP_AFTER_PAYMENT: 5,
  APP_AFTER_PRODUCT_VIEW: 6,
};

export type UpsellType = $Values<typeof UpsellTypes>;

export class UpsellRuleModel {
  modelID: number;
  upsellRuleID: number;
  type: CouponModelType;
}
