import React, { Component } from "react";
import Language, { Names } from "../../utils/Language";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { FaSave, FaTimes } from "react-icons/all";
import { EditHourType, SimpleTableRow } from "./DetailedSettings";
import styled from "styled-components";
import SelectOrg from "react-select";
import type { OptionsType } from "react-select/src/types";
import { DaysName } from "./Orders";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";

const SelectDay = styled(SelectOrg)`
  width: 200px;
  margin-right: 10px;
`;

export type OptionType = { value: number, label: string };

export default class HourEditor extends Component<{
  value: EditHourType,
  onChange: (e: EditHourType)=>{},
  onCancel: ()=>{},
  onSave: ()=>{}
}> {
  eventIDs: number[] = [];

  state: {
    dayOptions: OptionType[],
    language: number,
  } = {
    dayOptions: HourEditor.buildOptions(),
    language: ContextSystem.language,
  };

  static buildOptions(): OptionsType[] {
    let ops: OptionsType[] = [];
    for (let i = 0; i < 7; i++)
      ops.push({ value: i + 1, label: DaysName(i + 1) });
    return ops;
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined) {
        this.setState({
          language,
          dayOptions: HourEditor.buildOptions(),
        });
      }
    });

    this.eventIDs.push(eid);
  }

  render() {
    return (
      <SimpleTableRow justifycontent={"flex-start"}>
        <SelectDay
          placeholder={"Nap"}
          value={this.props.value.editDay}
          onChange={editDay => this.change(null, null, editDay)}
          options={this.state.dayOptions}
          noOptionsMessage={() => Language.getName(Names.NoDaysToShow)}
        />
        <TimeRangePicker
          amPmAriaLabel={Language.getName(Names.AMOrPM)}
          autoFocus={true}
          clearAriaLabel={Language.getName(Names.Remove)}
          locale={"hu-HU"}
          // format={"hh:mm"}
          disableClock
          value={this.props.value.editTimeRange}
          onChange={editTimeRange => this.change(null, editTimeRange, null)}
        />
        <FaSave style={{ marginLeft: "10px" }} onClick={() => this.handleModifyHourSave()} />
        <FaTimes style={{ marginLeft: "10px" }} onClick={() => this.handleCancel()} />
      </SimpleTableRow>
    );
  }

  handleCancel() {
    this.setState({ modifyingHourID: -1 });
    if (this.props.onCancel)
      this.props.onCancel();
  }

  handleModifyHourSave() {
    if (this.props.onSave)
      this.props.onSave();
  }

  change(modifyingHourID, editTimeRange, editDay) {
    if (this.props.onChange) {
      this.props.onChange({
        modifyingHourID: modifyingHourID ? modifyingHourID : this.props.value.modifyingHourID,
        editTimeRange: editTimeRange ? editTimeRange : this.props.value.editTimeRange,
        editDay: editDay ? editDay : this.props.value.editDay,
      });
    }
  }
}
