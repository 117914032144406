import APICaller, { APIMsgBase } from "../APICaller";
import { Coupon } from "../../model/Coupon";
import ErrorMessage from "./ErrorMessages";

export type GetCouponsType = APIMsgBase & { coupons: Coupon[] };
export type SaveCouponType = APIMsgBase & { coupon: Coupon };

function getCoupons(cb: (res: GetCouponsType)=>{}) {
  APICaller.ownFetch(true, "POST", "/coupons", {}, (res) => {
    let result: GetCouponsType = JSON.parse(res);

    if (result.error === ErrorMessage.OK)
      fixDates(result.coupons);

    if (cb)
      cb(result);
  });
}

function fixDates(coupons: Coupon[]) {
  coupons.forEach(c => {
    c.validUntil = !!c.validUntil ? new Date(3000, 1) : new Date(c.validUntil);
    c.validFrom = new Date(c.validFrom);
  });
}

function save(coupon: Coupon, cb: (res: SaveCouponType)=>{}) {
  let coupon2: Coupon = { ...coupon };

  APICaller.ownFetch(false, "PUT", "/coupon", { ...coupon2 }, (res) => {
    let result: SaveCouponType = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

function remove(couponID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/coupon", { id: couponID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);

    if (cb)
      cb(result);
  });
}

export const CouponsAPI = {
  getCoupons,
  save,
  remove,
  fixDates,
};
