import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Qty, QtyTypes } from "../../model/Stock";
import { Category, Product, Raw, Recipe, TranslatableString } from "../../model/Product";
import type { OrderProductSorted } from "../../model/Order";
import { Order, OrderPayment, OrderProduct, OrderUsedCouponID, TableReservation } from "../../model/Order";
import { ShopProfile } from "../../model/ShopProfile";
import { Shop } from "../../model/Shop";
import Chart2 from "react-apexcharts";
import hu from "apexcharts/dist/locales/hu.json";
import en from "apexcharts/dist/locales/en.json";
import dateHu from "date-fns/locale/hu";
import Language, { Names } from "../../utils/Language";
import Config from "../../utils/Config";
import Orders, { getShippingName } from "./Orders";
import { addLeadingZero } from "../../utils/HoursCalc";
import { BookingTime, BookingTimeClock, BookingTimeDay } from "./layout/WaiterLayout";
import { Profile } from "../../model/Profile";
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import { createStaticRanges, defaultInputRanges } from "react-date-range/dist/defaultRanges";
import { addDays, addMonths, differenceInCalendarDays, endOfDay, endOfMonth, endOfWeek, isSameDay, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { Button as Button2 } from "../FormComponents";
import { AllPaymentMethod, PaymentMethods, PaymentMethodTypes } from "../../model/PaymentMethodSetting";
import { ShippingMethods } from "../../model/ShippingPrice";
import CartCalc from "../../utils/CartCalc";
import AnimateHeight from "../AnimateHeight";
import { Coupon } from "../../model/Coupon";
import { isInAdminDomain } from "../../utils/EmailValidator";

const StickyWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 12;
  background-color: white;
  margin-bottom: 3px;
  box-shadow: 0 0 3px -1px black;
`;

const Info = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 10pt;
`;

const InfoName = styled.div`
  width: 70%;
  min-width: 200px;
  margin: 0 6px;
`;

const InfoValue = styled.div`
  margin: 0 0 0 auto;
`;

const AggregatedStatistics = styled.div`
  padding: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Chart = styled(Chart2)`
  width: 49%;
  max-width: 1000px;
  height: ${({ height }) => height ?? "350px"};
  min-height: ${({ min_height }) => min_height ?? "250px"};
  min-width: ${({ min_width }) => min_width ?? "350px"};
`;

const Button = styled(Button2)`
  margin: 0 0 10px 0;
  background-color: #4a7dbf;
  color: white;

  &:hover, &:active {
    background-color: #204772;
  }

  ${({ disabled }) => disabled === true && css`
    background-color: #d7d7d7;
    border: #666666;
    cursor: none;

    &:hover, &:active {
      cursor: none;
      background-color: #d7d7d7;
    }
  `};
`;

export const definedDates = {
  startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const DateSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DateSelectorButton = styled.button`
  outline: none;
  background-color: white;
  border: 1px solid #dedede;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 6px;

  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: #eeeeee;
  }

  &:active {
    background-color: #f3f3f3;
  }
`;

const Wrapper = styled.div`
  background-color: white;
  padding: 12px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const SOSList = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #555555;
`;

const SOSListScroll = styled.div`
  width: 300px;
  height: 100%;
  background-color: white;
  flex-shrink: 0;

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #c9c9c9;
    border-radius: 100px;
  }
`;

const ChartsScroll = styled.div`
  width: 60%;
  min-width: 200px;
  flex-grow: 10;
  height: 100%;
  background-color: white;
  flex-shrink: 0;
  position: relative;

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #c9c9c9;
    border-radius: 100px;
  }

  @media screen and (max-width: 800px) {
    width: 600px;
  }
`;

const ChartsContainer = styled.div`
  width: 100%;
  background-color: white;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
`;

const SOSEventDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 3px;
  margin: 3px 0;
`;

const Div = styled.div`
  width: ${({ width }) => (width ? width : "15%")};
  font-size: 9pt;
  margin: 0 3px;
`;

export default class Dashboard extends Component {
  state: {
    language: number,
    raws: Raw[],
    qtyList: Qty[],
    orders: Order[],
    shopProfiles: ShopProfile[],
    products: Product[],
    recipes: Recipe[],
    tableReservations: TableReservation[],
    selectedShop: Shop,
    elements: Element[],
    statisticsLoading: boolean,
    dateTime: Date,

    dateMin: Date,
    dateMax: Date,
    dateSelectorOpened: boolean,
    loadedDates: {
      dateMin: Date,
      dateMax: Date,
    },

    revenueChartOptions: ApexCharts.ApexOptions,
    revenueChartSeries: ApexAxisChartSeries[],
    tipChartOptions: ApexCharts.ApexOptions,
    tipChartSeries: ApexAxisChartSeries[],
    shippingPaymentModeChartOptions: ApexCharts.ApexOptions,
    shippingPaymentModeChartSeries: ApexAxisChartSeries[],
    shopProfilePaymentModeChartOptions: ApexCharts.ApexOptions,
    shopProfilePaymentModeChartSeries: ApexAxisChartSeries[],
    productChartOptions: ApexCharts.ApexOptions,
    productChartSeries: ApexAxisChartSeries[],
    categoriesChartOptions: ApexCharts.ApexOptions,
    categoriesChartSeries: ApexAxisChartSeries[],
    couponsChartOptions: ApexCharts.ApexOptions,
    couponsChartSeries: ApexAxisChartSeries[],
  } = {
    language: ContextSystem.language,
    raws: [],
    qtyList: [],
    orders: [],
    shopProfiles: [],
    products: [],
    recipes: [],
    tableReservations: [],
    selectedShop: ContextSystem.selectedShop,
    elements: [],
    statisticsLoading: ContextSystem.statisticsLoading,
    dateTime: new Date(),

    dateMin: new Date(),
    dateMax: new Date(),
    dateSelectorOpened: false,
    loadedDates: {
      dateMin: new Date(new Date().setHours(0, 0, 0, 0)),
      dateMax: new Date(),
    },

    revenueChartOptions: {},
    revenueChartSeries: [],
    tipChartOptions: {},
    tipChartSeries: [],
    shippingPaymentModeChartOptions: {},
    shippingPaymentModeChartSeries: [],
    shopProfilePaymentModeChartOptions: {},
    shopProfilePaymentModeChartSeries: [],
    productChartOptions: {},
    productChartSeries: [],
    categoriesChartOptions: {},
    categoriesChartSeries: [],
    couponsChartOptions: {},
    couponsChartSeries: [],
  };

  eventIDs = [];

  timeout;

  reloadDateTime() {
    this.timeout = setTimeout(() => {
      this.setState({ dateTime: new Date() });
      this.reloadDateTime();
    }, 60 * 1000);
  }

  load() {
    this.setState({
      selectedShop: ContextSystem.selectedShop,

      orders: ContextSystem.orders.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled),

      elements: ContextSystem.elements.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled),
      raws: ContextSystem.raws.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled),
      qtyList: ContextSystem.qtyList.filter(e => e.profileID === ContextSystem.selectedShop.id && e.enabled),
      shopProfiles: ContextSystem.selectedShop.employees,
      products: ContextSystem.products.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled),
      recipes: ContextSystem.recipes.filter(e => e.partnerID === ContextSystem.selectedShop.id && e.enabled),
      tableReservations: ContextSystem.tableReservations.filter(
        t => t.partnerID === ContextSystem.selectedShop.id && t.enabled),

      statisticsLoading: ContextSystem.statisticsLoading,
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
    if (this.timeout)
      clearTimeout(this.timeout);
  }

  componentDidMount() {
    this.reloadDateTime();
    this.reloadCharts();

    this.eventIDs = [];
    //ContextSystem.loadPredefinedDateStatistics();
    this.load();
    this.setState({
      loadedDates: {
        dateMin: new Date(new Date().setHours(0, 0, 0, 0)),
        dateMax: new Date(),
      },
    });

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         language, raws, qtyList, orders, products, recipes, selectedShop,
         statisticsLoading,
       }) => {
        if (language !== undefined)
          this.setState({ language });
        if (raws !== undefined || qtyList !== undefined || orders !== undefined || products !== undefined || recipes !== undefined || selectedShop !== undefined)
          this.load();
        if (statisticsLoading !== undefined)
          this.load();
        if (orders !== undefined || language !== undefined)
          this.reloadCharts();
      },
    );
    this.eventIDs.push(eid);
  }

  reloadCharts() {
    let revenueChartOptions: ApexPlotOptions = this.getRevenueChartOptions();
    let revenueChartSeries: ApexAxisChartSeries[] = this.getRevenueChartSeries();

    let tipChartOptions: ApexPlotOptions = this.getTipChartOptions();
    let tipChartSeries: ApexAxisChartSeries[] = this.getTipChartSeries();

    let shippingPaymentModeChartOptions: ApexPlotOptions = this.getShippingPaymentModeChartOptions();
    let shippingPaymentModeChartSeries: ApexAxisChartSeries[] = this.getShippingPaymentModeChartSeries();

    let shopProfilePaymentModeChartOptions: ApexPlotOptions = this.getShopProfilePaymentModeChartOptions();
    let shopProfilePaymentModeChartSeries: ApexAxisChartSeries[] = this.getShopProfilePaymentModeChartSeries();

    let productsInfos: ProductChartInfos = this.getProductChartInfo();

    this.setState({
      revenueChartOptions,
      revenueChartSeries,
      tipChartOptions,
      tipChartSeries,
      shippingPaymentModeChartOptions,
      shippingPaymentModeChartSeries,
      shopProfilePaymentModeChartOptions,
      shopProfilePaymentModeChartSeries,
      productChartOptions: productsInfos.productsOptions,
      productChartSeries: productsInfos.productsSeries,
      categoriesChartOptions: productsInfos.categoriesOptions,
      categoriesChartSeries: productsInfos.categoriesSeries,
      couponsChartOptions: productsInfos.couponsOptions,
      couponsChartSeries: productsInfos.couponsSeries,
    });
  }

  getOrdersSOS(): Order[] {
    return this.state.orders.filter(o => {
      if (!o)
        return false;
      if (o.tableReservationID > 0) //we will list these orders in the "tableReservation's sos list"
        return false;

      return Order.isStuck(o) || Order.isDelayed(o) || Order.isNotConfirmed(o);
    });
  }

  getTableReservationsSOS(): TableReservation[] {
    return this.state.tableReservations.filter(r => {
      if (!r)
        return false;

      let orders: Order[] = this.state.orders.filter(o => o.tableReservationID === r.id);

      let delayedOrder: boolean = false;
      orders.forEach(o => delayedOrder = delayedOrder || Order.isDelayed(o));

      return TableReservation.isNotClosed(r)
        || (TableReservation.isClosed(r) && TableReservation.isUnpaid(r, orders))
        || delayedOrder;
    });
  }

  getIngredientsSOS(): Raw[] {
    let raws: Raw[] = [];
    this.state.raws.forEach(raw => {
      let qtySum: number = Qty.getSum(this.state.qtyList.filter(q => q.modelID === raw.id && q.type === QtyTypes.RAW));

      if (qtySum < raw.minQty)
        raws.push(raw);
    });

    return raws;
  }

  getSOSList(): SOSEvent[] {
    let events: SOSEvent[] = [];

    let rawsSOS: Raw[] = this.getIngredientsSOS();
    let ordersSOS: Order[] = this.getOrdersSOS();
    let tableReservationsSOS: TableReservation[] = this.getTableReservationsSOS();

    rawsSOS.forEach(r => {
      events.push({
        date: this.state.dateTime,
        data: r,
        type: SOSEventTypes.EMPTY_RAW_QTY,
      });
    });

    ordersSOS.forEach(o => {
      let date: Date = o.date;
      let type: SOSEventType = SOSEventTypes.DELAYED_ORDER;

      if (Order.isStuck(o)) {
        date = o.lastState.dateTime;
        type = SOSEventTypes.STUCK_ORDER;
      } else if (Order.isDelayed(o)) {
        if (o.scheduleDate) {
          date = new Date(o.scheduleDate).addMinutes(
            -2 * (this.state.selectedShop.showPreparedOrdersBeforeMinutes / 4));
        } else {
          date = new Date(o.lastState.dateTime).addMinutes(-o.lastState.comment.minutes);
        }
        type = SOSEventTypes.DELAYED_ORDER;
      } else if (Order.isNotConfirmed(o)) {
        if (o.scheduleDate) {
          date = new Date(o.date).addMinutes(Config.notify_order_not_confirmed_minutes_preorder);
        } else {
          date = new Date(o.date).addMinutes(Config.notify_order_not_confirmed_minutes);
        }
        type = SOSEventTypes.NOT_CONFIRMED_ORDER;
      }

      events.push({
        date,
        data: o,
        type,
      });
    });

    tableReservationsSOS.forEach(r => {
      let orders: Order[] = this.state.orders.filter(o => o.tableReservationID === r.id);

      let delayedOrder: boolean = false;
      let delayShouldDate: Date = undefined;
      orders.forEach(o => {
        if (Order.isDelayed(o)) {
          delayedOrder = true;
          let d: Date = o.scheduleDate ?
                        new Date(o.scheduleDate).addMinutes(
                          2 * (this.state.selectedShop.showPreparedOrdersBeforeMinutes / 4))
                                       : new Date(o.lastState.dateTime).addMinutes(-o.lastState.comment.minutes);

          if (!delayShouldDate || d < delayShouldDate)
            delayShouldDate = d;
        }
      });

      if (delayedOrder) {
        events.push({ type: SOSEventTypes.DELAYED_TABLE, date: delayShouldDate, data: r });
      }

      if (TableReservation.isNotClosed(r)) {
        let date: Date = new Date(TableReservation.getEnd(r)).addHours(12);
        events.push({ type: SOSEventTypes.UNCLOSED_TABLE, date, data: r });
      } else if (TableReservation.isClosed(r) && TableReservation.isUnpaid(r, orders)) {
        events.push({ type: SOSEventTypes.UNPAID_TABLE, date: TableReservation.getStart(r), data: r });
      }
    });

    events.sort((e1, e2) => Orders.sortByDate(e1.date, e2.date, true));

    return events;
  }

  getRevenueChartSeries(): ApexAxisChartSeries[] {
    let series: ApexAxisChartSeries[] = [];
    let diffTimeMs = Math.abs(this.state.loadedDates.dateMax - this.state.loadedDates.dateMin);
    let diffHours = diffTimeMs / (1000 * 60 * 60);

    let groupHours: boolean = diffHours <= 48;
    let groupDays: boolean = diffHours > 48 && diffHours <= 60 * 24;
    let groupMonths: boolean = diffHours > 60 * 24 && diffHours <= 10000;
    let groupYears: boolean = diffHours > 10000;

    let revenue: number[] = [];
    let revenue2: { [keys: string]: number } = {};
    let transactions: number[] = [];
    let transactions2: { [keys: string]: number } = {};

    this.state.orders.forEach(o => {
      if (o.date < this.state.loadedDates.dateMin || o.date > this.state.loadedDates.dateMax)
        return;

      if (groupHours) {
        if (!revenue2[o.date.getHours()]) {
          revenue2[o.date.getHours()] = 0;
          transactions2[o.date.getHours()] = 0;
        }
        revenue2[o.date.getHours()] += Order.totalOrderPrice(o);
        transactions2[o.date.getHours()]++;
      } else if (groupDays) {
        if (!revenue2[o.date.toLocaleDateString()]) {
          revenue2[o.date.toLocaleDateString()] = 0;
          transactions2[o.date.toLocaleDateString()] = 0;
        }
        revenue2[o.date.toLocaleDateString()] += Order.totalOrderPrice(o);
        transactions2[o.date.toLocaleDateString()]++;
      } else if (groupMonths) {
        if (!revenue2[o.date.getFullYear() + "-" + o.date.getMonth()]) {
          revenue2[o.date.getFullYear() + "-" + o.date.getMonth()] = 0;
          transactions2[o.date.getFullYear() + "-" + o.date.getMonth()] = 0;
        }
        revenue2[o.date.getFullYear() + "-" + o.date.getMonth()] += Order.totalOrderPrice(o);
        transactions2[o.date.getFullYear() + "-" + o.date.getMonth()]++;
      } else if (groupYears) {
        if (!revenue2[o.date.getFullYear()]) {
          revenue2[o.date.getFullYear()] = 0;
          transactions2[o.date.getFullYear()] = 0;
        }
        revenue2[o.date.getFullYear()] += Order.totalOrderPrice(o);
        transactions2[o.date.getFullYear()]++;
      }
    });

    let k = 0;
    if (groupHours) {
      for (let i = this.state.loadedDates.dateMin.getHours(); i <= this.state.loadedDates.dateMax.getHours(); i++) {
        revenue[k] = revenue2[i] ?? 0;
        transactions[k++] = transactions2[i] ?? 0;
      }
    } else if (groupDays) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      while (opDate <= this.state.loadedDates.dateMax) {
        revenue[k] = revenue2[opDate.toLocaleDateString()] ?? 0;
        transactions[k++] = transactions2[opDate.toLocaleDateString()] ?? 0;
        opDate = new Date(opDate.addDays(1));
      }
    } else if (groupMonths) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      while (opDate <= this.state.loadedDates.dateMax) {
        revenue[k] = revenue2[opDate.getFullYear() + "-" + opDate.getMonth()] ?? 0;
        transactions[k++] = transactions2[opDate.getFullYear() + "-" + opDate.getMonth()] ?? 0;
        opDate = new Date(opDate.addMonths(1));
      }
    } else if (groupYears) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      opDate = new Date(opDate.setMonth(0));
      while (opDate <= this.state.loadedDates.dateMax) {
        revenue[k] = revenue2[opDate.getFullYear()] ?? 0;
        transactions[k++] = transactions2[opDate.getFullYear()] ?? 0;
        opDate = new Date(opDate.addYears(1));
      }
    }

    series.push({ name: Language.getName(Names.Orders), data: transactions });
    series.push({ name: Language.getName(Names.Income), data: revenue });

    return series;
  }

  getRevenueChartOptions(): ApexPlotOptions {
    let diffTimeMs = Math.abs(this.state.loadedDates.dateMax - this.state.loadedDates.dateMin);
    let diffHours = diffTimeMs / (1000 * 60 * 60);

    let groupHours: boolean = diffHours <= 48;
    let groupDays: boolean = diffHours > 48 && diffHours <= 60 * 24;
    let groupMonths: boolean = diffHours > 60 * 24 && diffHours <= 10000;
    let groupYears: boolean = diffHours > 10000;

    let categories: string[] = [];

    let k: number = 0;
    if (groupHours) {
      for (let i = this.state.loadedDates.dateMin.getHours(); i <= this.state.loadedDates.dateMax.getHours(); i++)
        categories[k++] = addLeadingZero(i);
    } else if (groupDays) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      while (opDate <= this.state.loadedDates.dateMax) {
        categories[k++] = opDate.getDate();
        opDate = new Date(opDate.addDays(1));
      }
    } else if (groupMonths) {
      let sameYear: boolean = this.state.loadedDates.dateMin.getFullYear() === this.state.loadedDates.dateMax.getFullYear();

      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      while (opDate <= this.state.loadedDates.dateMax) {
        categories[k++] = (sameYear ? "" : this.state.loadedDates + " ") + Language.getName(Names.MonthNames,
          opDate.getMonth(),
        );
        opDate = new Date(opDate.addMonths(1));
      }
    } else if (groupYears) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      opDate = new Date(opDate.setMonth(0));
      while (opDate <= this.state.loadedDates.dateMax) {
        categories[k++] = opDate.getFullYear();
        opDate = new Date(opDate.addYears(1));
      }
    }

    let defaultLocale: string = "hu";
    if (ContextSystem.language === 1)
      defaultLocale = "en";

    return {
      stroke: { curve: "smooth" },
      chart: {
        type: "area",
        id: "revenue",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        categories,
      },
      yaxis: [
        {
          title: { text: Language.getName(Names.Orders) },
          labels: {
            formatter: value => value.toLocaleString() + " " + Language.getName(Names.pcs),
            forceNiceScale: true,
          },
        },
        {
          opposite: true,
          title: { text: Language.getName(Names.Income) },
          labels: {
            formatter: value => {
              if (value >= 10000)
                return (value / 1000).toLocaleString() + " eFt";
              else
                return value.toLocaleString() + " Ft";
            },
          },
        },
      ],
    };
  }

  getTipChartOptions(): ApexPlotOptions {
    let diffTimeMs = Math.abs(this.state.loadedDates.dateMax - this.state.loadedDates.dateMin);
    let diffHours = diffTimeMs / (1000 * 60 * 60);

    let groupHours: boolean = diffHours <= 48;
    let groupDays: boolean = diffHours > 48 && diffHours <= 60 * 24;
    let groupMonths: boolean = diffHours > 60 * 24 && diffHours <= 10000;
    let groupYears: boolean = diffHours > 10000;

    let categories: string[] = [];

    let k: number = 0;
    if (groupHours) {
      for (let i = this.state.loadedDates.dateMin.getHours(); i <= this.state.loadedDates.dateMax.getHours(); i++)
        categories[k++] = addLeadingZero(i);
    } else if (groupDays) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      while (opDate <= this.state.loadedDates.dateMax) {
        categories[k++] = opDate.getDate();
        opDate = new Date(opDate.addDays(1));
      }
    } else if (groupMonths) {
      let sameYear: boolean = this.state.loadedDates.dateMin.getFullYear() === this.state.loadedDates.dateMax.getFullYear();

      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      while (opDate <= this.state.loadedDates.dateMax) {
        categories[k++] = (sameYear ? "" : this.state.loadedDates + " ") + Language.getName(Names.MonthNames,
          opDate.getMonth(),
        );
        opDate = new Date(opDate.addMonths(1));
      }
    } else if (groupYears) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      opDate = new Date(opDate.setMonth(0));
      while (opDate <= this.state.loadedDates.dateMax) {
        categories[k++] = opDate.getFullYear();
        opDate = new Date(opDate.addYears(1));
      }
    }

    let defaultLocale: string = "hu";
    if (ContextSystem.language === 1)
      defaultLocale = "en";

    return {
      stroke: { curve: "smooth" },
      chart: {
        type: "area",
        id: "tips",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        categories,
      },
      yaxis: [
        {
          title: { text: Language.getName(Names.Tips) },
          labels: {
            formatter: value => {
              if (value >= 10000)
                return (value / 1000).toLocaleString() + " eFt";
              else
                return value.toLocaleString() + " Ft";
            },
          },
        },
        {
          opposite: true,
          title: { text: Language.getName(Names.Guest) },
          labels: {
            formatter: value => value.toLocaleString() + " " + Language.getName(Names.person),
          },
        },
      ],
    };
  }

  getTipChartSeries(): ApexAxisChartSeries[] {
    let series: ApexAxisChartSeries[] = [];
    let diffTimeMs = Math.abs(this.state.loadedDates.dateMax - this.state.loadedDates.dateMin);
    let diffHours = diffTimeMs / (1000 * 60 * 60);

    let groupHours: boolean = diffHours <= 48;
    let groupDays: boolean = diffHours > 48 && diffHours <= 60 * 24;
    let groupMonths: boolean = diffHours > 60 * 24 && diffHours <= 10000;
    let groupYears: boolean = diffHours > 10000;

    let tip: number[] = [];
    let tip2: { [keys: string]: number } = {};
    let customers: number[] = [];
    let customers2: { [keys: string]: number } = {};
    let uniqueCustomers: { [keys: string]: number[] } = {};

    this.state.orders.forEach(o => {
      if (o.date < this.state.loadedDates.dateMin || o.date > this.state.loadedDates.dateMax)
        return;

      let key = "";

      if (groupHours) {
        key = o.date.getHours();
      } else if (groupDays) {
        key = o.date.toLocaleDateString();
      } else if (groupMonths) {
        key = o.date.getFullYear() + "-" + o.date.getMonth();
      } else if (groupYears) {
        key = o.date.getFullYear();
      }

      if (!tip2[key]) {
        tip2[key] = 0;
        customers2[key] = 0;
        uniqueCustomers[key] = [];
      }
      tip2[key] += Order.totalPayment(o) - Order.totalOrderPrice(o);
      if (o.profile && !uniqueCustomers[key].includes(o.profile.id)) {
        uniqueCustomers[key].push(o.profile.id);
        customers2[key]++;
      }
    });

    let k = 0;
    if (groupHours) {
      for (let i = this.state.loadedDates.dateMin.getHours(); i <= this.state.loadedDates.dateMax.getHours(); i++) {
        tip[k] = tip2[i] ?? 0;
        customers[k++] = customers2[i] ?? 0;
      }
    } else if (groupDays) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      while (opDate <= this.state.loadedDates.dateMax) {
        tip[k] = tip2[opDate.toLocaleDateString()] ?? 0;
        customers[k++] = customers2[opDate.toLocaleDateString()] ?? 0;
        opDate = new Date(opDate.addDays(1));
      }
    } else if (groupMonths) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      while (opDate <= this.state.loadedDates.dateMax) {
        tip[k] = tip2[opDate.getFullYear() + "-" + opDate.getMonth()] ?? 0;
        customers[k++] = customers2[opDate.getFullYear() + "-" + opDate.getMonth()] ?? 0;
        opDate = new Date(opDate.addMonths(1));
      }
    } else if (groupYears) {
      let opDate: Date = new Date(new Date(this.state.loadedDates.dateMin).setHours(0, 0, 0, 0));
      opDate = new Date(opDate.setDate(1));
      opDate = new Date(opDate.setMonth(0));
      while (opDate <= this.state.loadedDates.dateMax) {
        tip[k] = tip2[opDate.getFullYear()] ?? 0;
        customers[k++] = customers2[opDate.getFullYear()] ?? 0;
        opDate = new Date(opDate.addYears(1));
      }
    }

    series.push({ name: Language.getName(Names.AggregatedTips), data: tip, type: "area" });
    series.push({ name: Language.getName(Names.Guest), data: customers });

    return series;
  }

  getShippingPaymentModeChartOptions(): ApexPlotOptions {
    let defaultLocale: string = "hu";
    if (ContextSystem.language === 1)
      defaultLocale = "en";

    // noinspection UnnecessaryLocalVariableJS
    let options: ApexPlotOptions = {
      stroke: { curve: "smooth" },
      chart: {
        type: "heatmap",
        id: "modes",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        //categories
      },
      dataLabels: {
        formatter: value => {
          if (value >= 10000)
            return (Math.round(value / 100) / 10).toLocaleString() + " eFt";
          else
            return value.toLocaleString() + " Ft";
        },
      },
      plotOptions: {
        heatmap: {
          radius: 6,
          shadeIntensity: 0.1,
          colorScale: {
            min: 0,
          },
        },
      },
    };
    return options;
  }

  getShippingPaymentModeChartSeries(): ApexAxisChartSeries[] {
    let series: ApexAxisChartSeries[] = [];

    let data2: { [keys: number]: { [keys: number]: number } } = {};

    this.state.orders.forEach(o => {
      if (o.date < this.state.loadedDates.dateMin || o.date > this.state.loadedDates.dateMax)
        return;

      if (!data2[o.shippingMethod])
        data2[o.shippingMethod] = {};
      if (!data2[o.shippingMethod][o.paymentMethod])
        data2[o.shippingMethod][o.paymentMethod] = 0;

      for (let payment: OrderPayment of o.payments) {
        if (!payment.payed)
          continue;

        if (!data2[o.shippingMethod][payment.paymentType])
          data2[o.shippingMethod][payment.paymentType] = 0;
        data2[o.shippingMethod][payment.paymentType] += payment.amount - payment.creditedAmount;
      }
    });

    for (let p: { type: number, countries: number[], availableShippingMethods: number[] } of AllPaymentMethod) {
      if (!p.countries.includes(ContextSystem.selectedShop.countryID))
        continue;

      if (PaymentMethods.isOnline(p.type))
        continue;

      let paymentMethod: number = p.type;

      let data: [] = [];
      for (let key of Object.keys(ShippingMethods)) {
        let sh: number = ShippingMethods[key];
        if (!data2[sh] || !data2[sh][paymentMethod])
          data.push({ x: getShippingName(sh), y: 0 });
        else
          data.push({ x: getShippingName(sh), y: data2[sh][paymentMethod] });
      }

      series.push({
        name: PaymentMethods.getName(paymentMethod),
        data: data,
      });
    }
    return series;
  }

  getShopProfilePaymentModeChartOptions(): ApexPlotOptions {
    let defaultLocale: string = "hu";
    if (ContextSystem.language === 1)
      defaultLocale = "en";

    // noinspection UnnecessaryLocalVariableJS
    let options: ApexPlotOptions = {
      stroke: { curve: "smooth" },
      chart: {
        type: "heatmap",
        id: "modes",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        //categories
      },
      dataLabels: {
        formatter: value => {
          if (value >= 10000)
            return (Math.round(value / 100) / 10).toLocaleString() + " eFt";
          else
            return value.toLocaleString() + " Ft";
        },
      },
      plotOptions: {
        heatmap: {
          radius: 6,
          shadeIntensity: 0.1,
          colorScale: {
            min: 0,
          },
        },
      },
    };
    return options;
  }

  getShopProfilePaymentModeChartSeries(): ApexAxisChartSeries[] {
    let series: ApexAxisChartSeries[] = [];

    //            shopProfileID      //paymentID    sum
    let data2: { [keys: number]: { [keys: number]: number } } = {};

    let adminShopProfileIDs: number[] = [];
    if (!Config.TEST && ContextSystem.selectedShop.id !== 4) {
      ContextSystem.selectedShop.employees.forEach(e => {
        if (isInAdminDomain(e.email))
          adminShopProfileIDs.push(e.id);
      });
    }

    this.state.orders.forEach(o => {
      if (o.date < this.state.loadedDates.dateMin || o.date > this.state.loadedDates.dateMax)
        return;

      let spid = o.shopProfileID;
      if (adminShopProfileIDs.includes(spid))
        spid = -1;

      if (!data2[spid])
        data2[spid] = {};

      for (let payment: OrderPayment of o.payments) {
        if (!payment || !payment.payed)
          continue;

        let pt: number = payment.paymentType === undefined ? PaymentMethodTypes.ANY : payment.paymentType;

        try{
          if (!data2[spid][pt])
            data2[spid][pt] = 0;
        }catch (e) {
        }
        data2[spid][pt] += payment.amount - payment.creditedAmount;
      }
    });

    for (let p: { type: number, countries: number[], availableShippingMethods: number[] } of AllPaymentMethod) {
      if (!p.countries.includes(ContextSystem.selectedShop.countryID))
        continue;

      if (PaymentMethods.isOnline(p.type))
        continue;

      let paymentMethod: number = p.type;
      let data: [] = [];

      for (let employee: ShopProfile of this.state.selectedShop.employees) {
        if (!data2[employee.id] || !data2[employee.id][paymentMethod])
          data.push({ x: employee.firstName + " " + employee.lastName, y: 0 });
        else
          data.push({ x: employee.firstName + " " + employee.lastName, y: data2[employee.id][paymentMethod] });
      }
      if (Object.keys(data2).includes(-1))
        data.push({ x: Language.getName(Names.Unassigned), y: data2["-1"][paymentMethod] });

      series.push({
        name: PaymentMethods.getName(paymentMethod),
        data: data,
      });
    }

    return series;
  }

  // noinspection JSUnresolvedVariable
  getProductChartInfo(): ProductChartInfos {
    let returnData: ProductChartInfos = {
      categoriesOptions: {},
      categoriesSeries: [],
      productsOptions: {},
      productsSeries: [],
      couponsOptions: {},
      couponsSeries: [],
    };

    returnData.productsSeries[0] = { name: Language.getName(Names.Consumption), data: [] };
    returnData.categoriesSeries[0] = { name: Language.getName(Names.Consumption), data: [] };
    returnData.couponsSeries[0] = { name: Language.getName(Names.UsedCoupons), data: [] };
    returnData.couponsSeries[1] = { name: Language.getName(Names.CouponsAmount), data: [] };

    let productDataMapper: { [keys: string]: number } = {};
    let categoryDataMapper: { [keys: string]: number } = {};
    let couponsDataMapper: { [keys: string]: number } = {};

    let productsCategories: string[] = [];
    let categoriesCategories: string[] = [];
    let couponsCategories: string[] = [];

    let k_products = 0;
    let k_categories = 0;
    let k_coupons = 0;
    for (let order of this.state.orders) {
      if (order.date < this.state.loadedDates.dateMin || order.date > this.state.loadedDates.dateMax)
        continue;

      let orderProducts: OrderProductSorted[] = Orders.sortProducts(order.productList);

      if (!orderProducts)
        continue;

      for (let orderProduct of orderProducts) {
        let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(orderProduct);
        if (!leadOP)
          continue;

        let product: Product = ContextSystem.products.find(p => p.id === leadOP.productID);
        let category: Category = ContextSystem.categories.find(c => c.id === leadOP.categoryID);

        if (product) {
          if (!productDataMapper[product.uid]) {
            productsCategories.push(TranslatableString.get(product.name));
            productDataMapper[product.uid] = k_products++;
            // noinspection JSUnresolvedVariable
            returnData.productsSeries[0].data[productDataMapper[product.uid]] = 0;
          }

          let index: number = productDataMapper[product.uid];

          // noinspection JSUnresolvedVariable
          returnData.productsSeries[0].data[index] += leadOP.qty;
        }

        if (category) {
          if (!categoryDataMapper[category.id]) {
            categoriesCategories.push(TranslatableString.get(category.name));
            categoryDataMapper[category.id] = k_categories++;
            // noinspection JSUnresolvedVariable
            returnData.categoriesSeries[0].data[categoryDataMapper[product.categoryID]] = 0;
          }

          let index: number = categoryDataMapper[product.categoryID];

          // noinspection JSUnresolvedVariable
          returnData.categoriesSeries[0].data[index] += leadOP.qty;
        }
      }

      let m: [] = [];
      for (let usedCoupon: OrderUsedCouponID of order.usedCoupons) {
        if (couponsDataMapper[usedCoupon.usedCouponID] === undefined) {
          couponsDataMapper[usedCoupon.usedCouponID] = k_coupons++;
          let c: Coupon = ContextSystem.coupons.find(coupon => coupon.id === usedCoupon.usedCouponID);
          if (!c)
            continue;

          couponsCategories.push(TranslatableString.get(c.name));
          let index: number = couponsDataMapper[usedCoupon.usedCouponID];
          // noinspection JSUnresolvedVariable
          returnData.couponsSeries[0].data[index] = 0;
          // noinspection JSUnresolvedVariable
          returnData.couponsSeries[1].data[index] = 0;
        }

        let index: number = couponsDataMapper[usedCoupon.usedCouponID];
        if (!m.includes(usedCoupon.usedCouponID)) {
          m.push(usedCoupon.usedCouponID);
          // noinspection JSUnresolvedVariable
          returnData.couponsSeries[0].data[index] += 1;
        }
        // noinspection JSUnresolvedVariable
        returnData.couponsSeries[1].data[index] += usedCoupon.amount;
      }
    }

    let defaultLocale: string = "hu";
    if (ContextSystem.language === 1)
      defaultLocale = "en";

    returnData.productsOptions = {
      stroke: { curve: "smooth" },
      chart: {
        type: "bar",
        id: "products",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        categories: productsCategories,
      },
      dataLabels: {},
      plotOptions: {
        bar: {
          horizontal: true,
          radius: 6,
          shadeIntensity: 0.1,
          colorScale: {
            min: 0,
          },
        },
      },
    };

    returnData.categoriesOptions = {
      stroke: { curve: "smooth" },
      chart: {
        type: "bar",
        id: "products",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        categories: categoriesCategories,
      },
      dataLabels: {},
      plotOptions: {
        bar: {
          horizontal: true,
          radius: 6,
          shadeIntensity: 0.1,
          colorScale: {
            min: 0,
          },
        },
      },
    };

    returnData.couponsOptions = {
      stroke: { curve: "smooth" },
      chart: {
        type: "bar",
        id: "coupons",
        locales: [hu, en],
        defaultLocale,
      },
      xaxis: {
        categories: couponsCategories,
      },
      dataLabels: {
        formatter: value => {
          if (value >= 10000)
            return (Math.round(value / 100) / 10).toLocaleString();
          else
            return value.toLocaleString();
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          radius: 6,
          shadeIntensity: 0.1,
          colorScale: {
            min: 0,
          },
        },
      },
    };

    return returnData;
  }

  handleUpdate() {
    if (this.isUpdateDisabled())
      return;

    this.setState({
      loadedDates: {
        dateMin: this.state.dateMin,
        dateMax: this.state.dateMax,
      },
    });

    ContextSystem.loadStatisticsByDate(this.state.dateMin, this.state.dateMax, false, () => {
      this.setState({
        dateSelectorOpened: false,
      });
    });
  }

  isUpdateDisabled() {
    return this.state.dateMin === this.state.loadedDates.dateMin && this.state.dateMax === this.state.loadedDates.dateMax;
  }

  render() {
    let sosEvents: SOSEvent[] = this.getSOSList();

    let customerMapping: { [keys: number]: number } = {};
    let allRevenue: number = 0;
    let allDiscounts: number = 0;
    let allRevenueWithoutDiscounts: number = 0;
    let shippingFees: number = 0;
    let ordersTotal: number = 0;
    let tips: number;
    let customers: number = 0;

    for (let o: Order of this.state.orders) {
      if (o.date < this.state.dateMin || o.date > this.state.dateMax)
        continue;

      allRevenueWithoutDiscounts += Order.originalTotalOrderPrice(o);
      ordersTotal += Order.totalOrderPrice(o);
      allRevenue += Order.totalPayment(o);
      allDiscounts += Order.totalOrderPrice(o) - Order.originalTotalOrderPrice(o);
      shippingFees += o.shippingPrice;

      if (o.profile && !customerMapping[o.profile.id]) {
        customers++;
        customerMapping[o.profile.id]++;
      }
    }

    tips = allRevenue - ordersTotal;

    // noinspection JSUnresolvedVariable
    return (
      <Wrapper>
        <ChartsScroll>
          <ChartsContainer>
            <StickyWrapper>
              <AnimateHeight opened={true}>
                <>
                  <DateSelectorWrapper>
                    <DateSelectorButton
                      onClick={() => this.setState({ dateSelectorOpened: !this.state.dateSelectorOpened })}>
                      {this.state.loadedDates.dateMin.toLocaleDateString()}
                      {this.state.loadedDates.dateMin.toLocaleDateString() !== this.state.loadedDates.dateMax.toLocaleDateString() &&
                        <>
                          {"- " + this.state.loadedDates.dateMax.toLocaleDateString()}
                        </>
                      }
                    </DateSelectorButton>
                  </DateSelectorWrapper>
                  {this.state.dateSelectorOpened &&
                    <>
                      <DateSelectorWrapper>
                        <DateRangePicker
                          onChange={item => this.setState({
                            dateMin: new Date(item.selection.startDate),
                            dateMax: new Date(item.selection.endDate),
                          })}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={1}
                          ranges={[
                            {
                              startDate: this.state.dateMin,
                              endDate: this.state.dateMax,
                              key: "selection",
                            },
                          ]}
                          direction="horizontal"
                          maxDate={new Date()}
                          locale={dateHu}
                          staticRanges={createStaticRanges([
                            {
                              label: Language.getName(Names.Today),
                              range: () => ({
                                startDate: definedDates.startOfToday,
                                endDate: definedDates.endOfToday,
                              }),
                            },
                            {
                              label: Language.getName(Names.Yesterday),
                              range: () => ({
                                startDate: definedDates.startOfYesterday,
                                endDate: definedDates.endOfYesterday,
                              }),
                            },
                            {
                              label: Language.getName(Names.ThisWeek),
                              range: () => ({
                                startDate: definedDates.startOfWeek,
                                endDate: definedDates.endOfWeek,
                              }),
                            },
                            {
                              label: Language.getName(Names.LastWeek),
                              range: () => ({
                                startDate: definedDates.startOfLastWeek,
                                endDate: definedDates.endOfLastWeek,
                              }),
                            },
                            {
                              label: Language.getName(Names.ThisMonth),
                              range: () => ({
                                startDate: definedDates.startOfMonth,
                                endDate: definedDates.endOfMonth,
                              }),
                            },
                            {
                              label: Language.getName(Names.LastMonth),
                              range: () => ({
                                startDate: definedDates.startOfLastMonth,
                                endDate: definedDates.endOfLastMonth,
                              }),
                            },
                          ])}
                          inputRanges={[
                            {
                              ...(defaultInputRanges[0]),
                              label: Language.getName(Names.DaysUpToToday),
                            },
                            {
                              label: Language.getName(Names.DaysUpToYesterday),
                              range(value) {
                                return {
                                  startDate: addDays(definedDates.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                                  endDate: definedDates.endOfYesterday,
                                };
                              },
                              getCurrentValue(range) {
                                if (!isSameDay(range.endDate, definedDates.endOfYesterday))
                                  return "-";
                                if (!range.startDate)
                                  return "∞";
                                return differenceInCalendarDays(definedDates.endOfYesterday, range.startDate) + 1;
                              },
                            },
                          ]}
                        />
                      </DateSelectorWrapper>
                      <DateSelectorWrapper>
                        <Button
                          disabled={this.isUpdateDisabled()}
                          onClick={() => this.handleUpdate()}>
                          {Language.getName(Names.Update)}
                        </Button>
                      </DateSelectorWrapper>
                    </>
                  }
                </>
              </AnimateHeight>
            </StickyWrapper>
            <AggregatedStatistics>
              <Info>
                <InfoName>{Language.getName(Names.RevenueWithoutDiscounts)}</InfoName>
                <InfoValue>{allRevenueWithoutDiscounts.toLocaleString()} Ft</InfoValue>
              </Info>
              <Info>
                <InfoName>{Language.getName(Names.AllRevenue)}</InfoName>
                <InfoValue>{ordersTotal.toLocaleString()} Ft</InfoValue>
              </Info>
              <Info>
                <InfoName>{Language.getName(Names.GivenDiscounts)}</InfoName>
                <InfoValue>{allDiscounts.toLocaleString()} Ft</InfoValue>
              </Info>
              <Info>
                <InfoName>{Language.getName(Names.AllIncome)}</InfoName>
                <InfoValue>{allRevenue.toLocaleString()} Ft</InfoValue>
              </Info>
              <Info>
                <InfoName>{Language.getName(Names.AggregatedTips)}</InfoName>
                <InfoValue>{tips.toLocaleString()} Ft</InfoValue>
              </Info>
              <Info>
                <InfoName>{Language.getName(Names.ShippingFees)}</InfoName>
                <InfoValue>{shippingFees.toLocaleString()} Ft</InfoValue>
              </Info>
              <Info>
                <InfoName>{Language.getName(Names.Guests)}</InfoName>
                <InfoValue>{customers.toLocaleString()} {Language.getName(Names.person)}</InfoValue>
              </Info>
            </AggregatedStatistics>
            <Chart
              options={this.state.revenueChartOptions}
              series={this.state.revenueChartSeries}
            />
            <Chart
              options={this.state.tipChartOptions}
              series={this.state.tipChartSeries}
            />
            <Chart
              options={this.state.shippingPaymentModeChartOptions}
              series={this.state.shippingPaymentModeChartSeries}
              type={"heatmap"}
              min_height={"500px"}
              min_width={"800px"}
              height={"300px"}
            />
            <Chart
              options={this.state.shopProfilePaymentModeChartOptions}
              series={this.state.shopProfilePaymentModeChartSeries}
              type={"heatmap"}
              min_height={"250px"}
              min_width={"100%"}
              height={"300px"}
            />
            <Chart
              options={this.state.productChartOptions}
              series={this.state.productChartSeries}
              type={"bar"}
              height={(100 + (this.state.productChartSeries[0]?.data?.length ?? 2) * 25) + "px"}
              min_width={"49%"}
            />
            <Chart
              options={this.state.categoriesChartOptions}
              series={this.state.categoriesChartSeries}
              type={"bar"}
              height={(100 + (this.state.categoriesChartSeries[0]?.data?.length ?? 2) * 25) + "px"}
              min_width={"49%"}
            />
            <Chart
              options={this.state.couponsChartOptions}
              series={this.state.couponsChartSeries}
              type={"bar"}
              height={(100 + (this.state.couponsChartSeries[0]?.data?.length ?? 2) * 25) + "px"}
              min_width={"49%"}
            />
          </ChartsContainer>
        </ChartsScroll>
        <SOSListScroll>
          <SOSList>
            {sosEvents.length <= 0 &&
              <>
                {Language.getName(Names.NoSOS)}
              </>
            }
            {sosEvents.length > 0 && sosEvents.map((e, i) => {
              let date: Date = e.date;
              let today: boolean = new Date().toDateString() === date.toDateString();

              return (
                <SOSEventDiv key={i}>
                  <BookingTime>
                    <BookingTimeClock>{addLeadingZero(date.getHours())}:{addLeadingZero(
                      date.getMinutes())}</BookingTimeClock>
                    <BookingTimeDay>
                      {today && <>{Language.getName(Names.Today)}</>}
                      {!today &&
                        <>
                          {Language.getName(Names.MonthNames, date.getMonth())} {date.getDate()}<br />
                          {Language.getName(Names.DayNames, date.getDay() - 1 === -1 ? 6 : date.getDay() - 1)}
                        </>
                      }
                    </BookingTimeDay>
                  </BookingTime>
                  <Div width={"100px"}>
                    {SOSEvent.getTypeString(e.type)}
                  </Div>
                  <Div width={"150px"}>
                    <>
                      {[SOSEventTypes.DELAYED_TABLE, SOSEventTypes.UNCLOSED_TABLE, SOSEventTypes.UNPAID_TABLE].includes(
                          e.type) &&
                        (() => {
                          let table: Element = this.state.elements.find(t => t.id === e.data.tableID);
                          let profile: Profile = ContextSystem.profiles.find(p => p.id === e.data.profileID);

                          if (!table)
                            return <></>;

                          return (
                            <>
                              {Language.getName(Names.Table)}: {table.name}
                              {profile &&
                                <>
                                  <br />
                                  {Language.getName(Names.Name)}: {profile.firstName}
                                </>
                              }
                            </>
                          );
                        })()
                      }
                      {[SOSEventTypes.EMPTY_RAW_QTY].includes(e.type) &&
                        (() => {
                          let raw: Raw = e.data;
                          let qtySum: number = Qty.getSum(
                            this.state.qtyList.filter(q => q.modelID === raw.id && q.type === QtyTypes.RAW));

                          return (
                            <>
                              {TranslatableString.get(raw.name)}<br />
                              {qtySum.toLocaleString()} {TranslatableString.get(raw.unit)}, {Language.getName(
                              Names.min)}: {raw.minQty.toLocaleString()} {TranslatableString.get(raw.unit)}
                            </>
                          );
                        })()
                      }
                      {[
                          SOSEventTypes.DELAYED_ORDER, SOSEventTypes.STUCK_ORDER, SOSEventTypes.NOT_CONFIRMED_ORDER,
                        ].includes(e.type) &&
                        (() => {
                          let order: Order = e.data;
                          let profile: Profile = order.profile;

                          return (
                            <>
                              #{order.number}<br />
                              {order.orderTotalPrice.toLocaleString()} Ft
                              {profile &&
                                <>
                                  <br />
                                  {Language.getName(Names.Name)}: {profile.firstName}
                                  {profile.tel &&
                                    <>
                                      <br />
                                      {profile.tel}
                                    </>
                                  }
                                </>
                              }
                            </>
                          );
                        })()
                      }
                    </>
                  </Div>
                </SOSEventDiv>
              );
            })}
          </SOSList>
        </SOSListScroll>
      </Wrapper>
    );
  }
}

type ProductChartInfos = {
  productsSeries: ApexAxisChartSeries[];
  productsOptions: ApexPlotOptions;
  categoriesSeries: ApexAxisChartSeries[];
  categoriesOptions: ApexPlotOptions;
  couponsSeries: ApexAxisChartSeries[];
  couponsOptions: ApexPlotOptions;
};

export class SOSEvent {
  date: Date;
  type: SOSEventType;
  data: Order | TableReservation | Raw;

  static getTypeString(type: SOSEventType) {
    switch (type) {
      case SOSEventTypes.UNPAID_TABLE:
        return Language.getName(Names.UnpaidTable);
      case SOSEventTypes.UNCLOSED_TABLE:
        return Language.getName(Names.OpenTable);
      case SOSEventTypes.NOT_CONFIRMED_ORDER:
        return Language.getName(Names.NotConfirmedOrder);
      case SOSEventTypes.STUCK_ORDER:
        return Language.getName(Names.StuckOrder);
      case SOSEventTypes.DELAYED_ORDER:
        return Language.getName(Names.DelayedOrder);
      case SOSEventTypes.EMPTY_RAW_QTY:
        return Language.getName(Names.EmptyIngredient);
      case SOSEventTypes.DELAYED_TABLE:
        return Language.getName(Names.DelayedTablesOrder);
    }
  }
}

export const SOSEventTypes = {
  NOT_CONFIRMED_ORDER: 0,
  DELAYED_ORDER: 1,
  STUCK_ORDER: 2,
  UNCLOSED_TABLE: 3,
  UNPAID_TABLE: 4,
  EMPTY_RAW_QTY: 5,
  DELAYED_TABLE: 6,
};

export type SOSEventType = $Values<typeof SOSEventTypes>;
