import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { Category, GlobalCategory, ProductView } from "../../model/Product";

export type GlobalCategoriesResponseType = APIMsgBase & { globalCategories: GlobalCategory[] };

function getAll(quite: boolean, cb: (res: GlobalCategoriesResponseType)=>{}) {
  APICaller.ownFetch(quite, "POST", "/categories", {}, (data) => {
    let res: GlobalCategoriesResponseType = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export type ChangeCategoriesType = { orderNumber: string, id: number, parent: number };

function changeOrderNumber(changedCategories: ChangeCategoriesType[], cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/category/orderplace", { changedCategories }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function modify(ID: number, name: string, languageID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/category", { ID, name, languageID }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export type AddCategoryResponseType = APIMsgBase & { category: Category };
export type GetViewsResponseType = APIMsgBase & { views: Category[], productViews: ProductView[] };

function add(parentID: number, cb: (res: AddCategoryResponseType)=>{}) {
  APICaller.ownFetch(false, "PUT", "/category", { parentID }, (data) => {
    let res: AddCategoryResponseType = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function getViews(cb: (res: GetViewsResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/views", {}, (data) => {
    let res: AddCategoryResponseType = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function saveView(view: Category, productViews: ProductView[], cb: (res: AddCategoryResponseType)=>{}) {
  APICaller.ownFetch(false, "PUT", "/view", { view, productViews }, (data) => {
    let res: AddCategoryResponseType = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function removeView(id: number, cb: (APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/view", { id }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function remove(ID: number, cb: (APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/category", { ID }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default {
  getAll,
  changeOrderNumber,
  modify,
  add,
  saveView,
  getViews,
  removeView,
  remove,
};
