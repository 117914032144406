import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import ContextSystem from "../ContextSystem";
import { Product } from "../../model/Product";

export type GetMenusResponseType = APIMsgBase & { menus: Product[] };

function getMenus(cb: (GetMenusResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/menus", {}, (res) => {
    let result: GetMenusResponseType = JSON.parse(res);
    if (cb)
      cb(result);
    ContextSystem.mergeProducts(result.menus);
  });
}

export type ChangedMenusType = { id: number, orderPlace: number };

function changeOrderPlace(changedMenus, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/product/orderplace", { changedProducts: changedMenus }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function setMenuSelected(menuID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/menu/select", { menuID }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function setMenuUnSelected(menuID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/menu/select", { menuID }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export const MenusAPI = {
  getMenus,
  changeOrderPlace,
  setMenuSelected,
  setMenuUnSelected,
};
