import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import type { OrderStateComment } from "../../model/Order";
import { Order, OrderPayment, OrderShopProfileContact, OrderState, TableReservation } from "../../model/Order";
import { Product } from "../../model/Product";
import { City, ZipCode } from "../../model/Address";
import { Element } from "../../model/BluePrint";
import EventSystem from "../EventSystem";
import Orders from "../../components/home/Orders";
import ContextSystem from "../ContextSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../Language";
import CartCalc from "../CartCalc";
import ErrorMessage from "./ErrorMessages";

function getDateFormat(date) {
  //format needed for backend: 2013.03.12. 18:55:02
  if (!date)
    return null;
  let mm = date.getMonth() + 1; // getMonth() is zero-based
  let dd = date.getDate();
  let hh = date.getHours();
  let MM = date.getMinutes();
  let ss = date.getSeconds();

  return (
    date.getFullYear() +
    "." +
    (mm > 9 ? "" : "0") +
    mm +
    "." +
    (dd > 9 ? "" : "0") +
    dd +
    ". " +
    (hh > 9 ? "" : "0") +
    hh +
    ":" +
    (MM > 9 ? "" : "0") +
    MM +
    ":" +
    (ss > 9 ? "" : "0") +
    ss
  );
}

export type GetOrdersType =
  APIMsgBase
  & {
  orders: Order[],
  products: Product[],
  cities: City[],
  zipCodes: ZipCode[],
  tableReservations: TableReservation[],
  tables: Element[],
  orderShopProfileContacts: OrderShopProfileContact[],
};

function getOrders(finished: boolean, minDate: Date, maxDate: Date, cb: (res: GetOrdersType)=>{}) {
  if (minDate)
    minDate.setHours(0, 0, 0, 0);
  if (maxDate)
    maxDate.setHours(23, 59, 59, 999);
  let dateMin = getDateFormat(minDate);
  let dateMax = getDateFormat(maxDate);

  APICaller.ownFetch(false, "POST", "/orders", { finished: finished ? 1 : 0, dateMin, dateMax }, (res) => {
    let result = JSON.parse(res);

    if (result.error === ErrorMessage.OK) {
      result.orders.forEach(o => fixDates(o));

      if (cb)
        cb(result);
    } else {
      if (cb)
        cb(result);
    }
  });
}

function changeOrderStateAPI(orderNumber, newState, comment, cb) {
  APICaller.ownFetch(false, "PUT", "/orderstate", { orderNumber, state: newState, comment }, (res) => {
    let result = JSON.parse(res);
    if (result.error === ErrorMessage.OK)
      if (cb)
        cb(result.error);
  });
}

export type TransactionInfo = { url: string, txid: string };

export type SendOrderMessageType =
  APIMsgBase
  & {
  order: Order,
  products: Product[],
  orderNumber: string,
  profileID: number,
  transactionInfo: TransactionInfo,
  paymentMethod: number,
  tableReservation: TableReservation
};

function sendOrder(
  order: Order, done: boolean, products: Product[], selectedStorageID: number, cb: (res: SendOrderMessageType)=>{}) {
  APICaller.ownFetch(false, "PUT", "/order",
    {
      ...order,
      products,
      paymentMethods: order.payments,
      selectedStorageID,
    },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

export type PutPaymentType = { orderNumber: string, orderPayment: OrderPayment };

function putPayments(paymentMethods: PutPaymentType[], deletePayments: OrderPayment[], cb: (res: APIMsgBase)=>{}) {
  if (!paymentMethods || !deletePayments || (paymentMethods.length <= 0 && deletePayments.length <= 0))
    return;

  APICaller.ownFetch(false, "PUT", "/order/pay", { paymentMethods, deletePayments },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

function fixDates(order: Order) {
  if (!order)
    return;

  order.date = new Date(order.date);
  if (order.scheduleDate)
    order.scheduleDate = new Date(order.scheduleDate);
  if (order.lastState?.dateTime)
    order.lastState.dateTime = new Date(order.lastState?.dateTime);
  order.orderStates.forEach(os => os.dateTime = new Date(os.dateTime));
}

function fixDatesOP(op: OrderPayment) {
  if (!op)
    return;

  op.dateTime = new Date(op.dateTime);
}

//This function is used only when user clicked on pay button manually.
//Should not be used on an automatic pop-up show
function payOrder(order: Order, cb: ({ newPayments: OrderPayment[], deletePayments: OrderPayment[], cancelled: boolean })=>{}, force: boolean = false) {
  let orders: Order[] = [order];
  let cart: Product[] = CartCalc.createCartFromOrder(order);

  //existingPayments, newPayments, cart, cb, force
  EventSystem.publish(EventSystem.events.open_order_payment,
    {
      existingPayments: order.payments,
      newPayments: [],
      cart,
      showBackButton: false,
      cb: ({ newPayments, deletePayments, cancelled, back }) => {
        //back is not used here, since we reach here only from "pay" button
        //back is used when the panel is opened after TableSelector, and we need to open back again the table selector

        if (cancelled === true || back === true)
          return;
        Orders.saveNewPaymentsForOrders(orders, newPayments, deletePayments);
        if (cb)
          cb();
      },
      force,
    },
  );
}

function modifyOrder(order: Order): void {
  EventSystem.publish(EventSystem.events.modify_order, { order });
}

function changeOrderState(order: Order, newState: OrderState, comment: OrderStateComment) {
  changeOrderStateAPI(order.number, newState, JSON.stringify(comment), () => {
    let orderState: OrderState = {};
    orderState.enabled = true;
    orderState.comment = JSON.stringify(comment);
    orderState.dateTime = new Date().getTime();
    orderState.orderID = -1;
    orderState.status = newState;
    orderState.id = -1;
    orderState.finished = newState !== OrderState.NEW && newState !== OrderState.WAITING_FOR_ONLINE_PAYMENT;
    order.orderStates.push(orderState);
    order.lastState = orderState;

    ContextSystem.mergeOrders([order]);

    toast(Language.getName(Names.Modified));
  });
}

function modifyOrderState(order: Order, newState: number) {
  if (ContextSystem.selectedShop.cashflowReportAvailable && newState === OrderState.DONE && !Order.isPaidFully(order)) {
    payOrder(order, () => {
      //not needed, since it will be handled automatically by backend.
      //changeOrderState(order, newState, "");
    });
    return;
  }

  let needsComment =
    (order.scheduleDate && newState === OrderState.PREPARING && !ContextSystem.selectedShop.kitchenReportAvailable)
    || (!order.scheduleDate && newState === OrderState.CONFIRMED && !ContextSystem.selectedShop.kitchenReportAvailable)
    || (newState === OrderState.DONE && !Order.isPaidFully(
      order) && ContextSystem.selectedShop.cashflowReportAvailable);

  if (needsComment) {
    EventSystem.publish(EventSystem.events.open_order_comment, {
      orderNumber: order.number,
      newState,
      order,
      cb: (params: { comment: OrderStateComment, orderNumber: string, newState: number, order: Order }) => {
        changeOrderState(params.order, params.newState, params.comment);
      },
    });
    return;
  }

  changeOrderState(order, newState, "");
}

export const OrdersAPI = {
  getOrders,
  changeOrderState,
  fixDates,
  sendOrder,
  putPayments,
  payOrder,
  modifyOrder,
  modifyOrderState,
  fixDatesOP,
};
