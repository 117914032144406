// noinspection SpellCheckingInspection

import { ExtraGroup, Product, ProductTypes, Version } from "../model/Product";
import type { OrderProductSorted } from "../model/Order";
import { Order, OrderCouponModelTypes, OrderPayment, OrderProduct, OrderSource, OrderState, OrderUsedCouponID, TableReservation, TableReservationStatuses } from "../model/Order";
import { ShippingMethods, ShippingPrice } from "../model/ShippingPrice";
import Orders from "../components/home/Orders";
import ContextSystem from "./ContextSystem";
import { Coupon, CouponTypes } from "../model/Coupon";
import { Profile } from "../model/Profile";

export default class CartCalc {
  static getVersionByID(versions: Version[], versionID: number): Version {
    for (let v of versions) {
      if (v.id === versionID)
        return v;
    }
    return null;
  }

  static applyCoupons(cart: Product[], order: Order) {
    order._appliedCoupons = [];
    if (!order || !cart || !order._useCoupons || order._useCoupons.length <= 0)
      return;

    let usedCoupons: OrderUsedCouponID[] = [];

    for (let c: Coupon of order._useCoupons) {
      if (!c || !c.enabled || (c.qty <= 0 && c.qty !== Coupon.UNLIMITED))
        continue;

      if (
        (!c.allPartners && !c.partnerIDs.includes(order.partnerID))
        || (!c.allProfiles && (!order.profile || !c.profileIDs.includes(order.profile.id)))
        || (!c.allShopProfiles && !c.shopProfileIDs.includes(ContextSystem.profile.id))
      )
        continue;
      if (c.validFrom > new Date() || c.validUntil < new Date())
        continue;
      if ((c.maxCartValue > 0 && order.orderTotalPrice > c.maxCartValue) || (c.minCartValue > 0 && order.orderTotalPrice < c.minCartValue))
        continue;
      if (c.maxQtyPerPurchase > 0 && order.productList.length > c.maxQtyPerPurchase)
        continue;
      if (c.maxQtyPerPerson > 0 && order.productList.length > c.maxQtyPerPerson)
        continue;

      let fixCanBeUsed: number = 0;
      let used: boolean = false;

      let orderProductsList: OrderProductSorted[] = Orders.sortProducts(order.productList);

      for (let o: OrderProduct[] of orderProductsList) {
        let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(o);
        if (!leadOP)
          continue;
        let p: Product = cart.find(pr => pr.id === leadOP.productID);

        if (
          c.allProducts || c.allCategories || c.allGlobalCategories
          || c.productIDs.includes(leadOP.productID)
          || c.categoryIDs.includes(leadOP.categoryID)
          || (p && c.globalCategoryIDs.includes(p.globalCategoryID))
        ) {
          if (c.type === CouponTypes.FIX) {
            fixCanBeUsed += leadOP.itemTotalPrice;
          } else if ([CouponTypes.PERCENTAGE, CouponTypes.SERVICE].includes(c.type)) {
            let discount = OrderProduct.applyCouponPercentage(leadOP, c.couponValue, p);
            if (!used)
              usedCoupons.push({
                id: -1,
                enabled: true,
                usedCouponID: c.id,
                modelID: leadOP.id,
                modelType: OrderCouponModelTypes.ORDER_PRODUCT,
                addedDate: new Date(),
                orderID: order.id,
                amount: discount,
              });
          }
          used = true;
        }
      }

      let decreaseOrderTotalPrice: number = 0;
      if (used === true && c.type === CouponTypes.FIX) {
        decreaseOrderTotalPrice = Math.min(order.originalOrderTotalPrice, c.couponValue);
        decreaseOrderTotalPrice = Math.min(fixCanBeUsed, decreaseOrderTotalPrice);

        order.orderTotalPrice = order.originalOrderTotalPrice - decreaseOrderTotalPrice;
        usedCoupons.push({
          id: -1,
          enabled: true,
          usedCouponID: c.id,
          modelID: order.id,
          modelType: OrderCouponModelTypes.ORDER,
          addedDate: new Date(),
          orderID: order.id,
          amount: decreaseOrderTotalPrice,
        });
      }

      if (c.freeShipping && order.shippingPrice > 0) {
        used = true;
        if (c.type === CouponTypes.FIX) {
          let leftValueInCoupon: number = c.couponValue - decreaseOrderTotalPrice;
          if (leftValueInCoupon > 0) {
            let decrease: number = Math.min(order.shippingPrice, leftValueInCoupon);
            order.shippingPrice = order.shippingPrice - decrease;

            usedCoupons.push({
              id: -1,
              enabled: true,
              usedCouponID: c.id,
              modelID: order.id,
              modelType: OrderCouponModelTypes.ORDER,
              addedDate: new Date(),
              orderID: order.id,
              amount: decrease,
            });
          }
        } else if ([CouponTypes.PERCENTAGE, CouponTypes.SERVICE].includes(c.type)) {
          usedCoupons.push({
            id: -1,
            enabled: true,
            usedCouponID: c.id,
            modelID: order.id,
            modelType: OrderCouponModelTypes.ORDER,
            addedDate: new Date(),
            orderID: order.id,
            amount: order.shippingPrice * c.couponValue / 100,
          });
          order.shippingPrice = order.shippingPrice * (1 - c.couponValue / 100);
        }
      }

      if (used) {
        order._appliedCoupons.push(c);
      }
    }

    order.usedCoupons = usedCoupons;
    order.orderTotalPrice = Order.totalOrderPrice(order);
  }

  static stringHasSameStartingWord(str: string, s: string): boolean {
    if (!str || !s)
      return false;

    let strings: string[] = str.split(" ");
    for (let s2 of strings) {
      if (s2.toLowerCase().startsWith(s.toLowerCase()))
        return true;
    }
    return false;
  }

  static isSameOrderMenu(menu1: Product, menu2: Product): boolean {
    if (!menu1 || !menu2 || !menu1.type || !menu2.type || menu1.type !== ProductTypes.MENU || menu2.type !== ProductTypes.MENU)
      return false;

    let leadOP1 = CartCalc.getLeadOrderProduct(menu1.orderProduct);
    let leadOP2 = CartCalc.getLeadOrderProduct(menu2.orderProduct);

    //menu1.id > 0, mert csak az összerakósnál lehet a terméknek is feltéte, míg a "kész" menünél csak a menünek lehet
    // feltéte (összességben)
    //kombinált menü (menu.id <= 0), nem kell csekkolni a menü extráit (úgyis üres)
    if (!CartCalc.isSameOrderProduct(leadOP1, leadOP2, menu1.id > 0))
      return false;

    //lényeg: lecsekkoljuk, hogy ugyanazok a termékek és mennyiségek vannak a menüben
    //illetve, ha a menü összerakós volt, akkor még azt, hogy az extrák is ugyanazok a terméken belül
    // - összerakós, ha menu-nek az id-ja negatív (<= -1).
    // - ha nem összerakós, akkor nem kell nézni az extrákat termék szinten,
    //    elég menü szinten (következő blokk csekkolja)

    // noinspection RedundantIfStatementJS
    if (!CartCalc.checkSubProducts(menu1, menu2))
      return false;

    return true;
  }

  static checkSubProducts(menu1: Product, menu2: Product): boolean {
    if (!menu1 || !menu2)
      return false;

    if (menu1.products.length !== menu2.products.length)
      return false;

    for (let p1 of menu1.products) {
      let hasMatch = false;
      for (let p2 of menu2.products) {
        let subLeadOP1 = CartCalc.getLeadOrderProduct(p1.orderProduct);
        let subLeadOP2 = CartCalc.getLeadOrderProduct(p2.orderProduct);

        //kombinált menünél, meg kell nézni az extrákat, hogy stimmelnek-e
        if (!CartCalc.isSameOrderProduct(subLeadOP1, subLeadOP2, menu1.id < -1))
          continue;

        hasMatch = true;
        break;
      }
      //nem találtuk meg a menu1 p1 termékét a menu2 termékeiben, szóval eltér a kettő
      if (!hasMatch)
        return false;
    }
    return true;
  }

  static hasSameOrderExtras(leadOP1: OrderProduct, leadOP2: OrderProduct): boolean {
    if (!leadOP1 || !leadOP2)
      return false;

    if ((!leadOP1.extras && !leadOP2.extras) || (leadOP1.extras.length === 0 === leadOP2.extras.length))
      return true;
    if (leadOP1.extras.length !== leadOP2.extras.length)
      return false;

    for (let e1 of leadOP1.extras) {
      let hasMatchExtra = false;
      for (let e2 of leadOP2.extras) {
        if (e1.id === e2.id && e1.qty === e2.qty && e1.extraGroupID === e2.extraGroupID) {
          hasMatchExtra = true;
          break;
        }
      }
      //nem találtuk meg a p1 termék e1 extráját a p2 extráiban, szóval eltér a kettő
      if (!hasMatchExtra)
        return false;
    }
    return true;
  }

  static isSameOrderElement(leadOP1: OrderProduct, leadOP2: OrderProduct) {
    if (leadOP1.type !== leadOP2.type)
      return false;
    if (leadOP1.type === ProductTypes.PRODUCT)
      return this.isSameOrderProduct(leadOP1, leadOP2, true);
    else if (leadOP1.type === ProductTypes.MENU)
      return this.isSameOrderMenu(leadOP1, leadOP2);
    return false;
  }

  static isSameOrderProduct(leadOP1: OrderProduct, leadOP2: OrderProduct, checkExtras: boolean = true): boolean {
    if (!leadOP1 || !leadOP2)
      return false;

    if (leadOP1.versionID !== leadOP2.versionID || leadOP1.productID !== leadOP2.productID || leadOP1.menuID !== leadOP2.menuID)
      return false;

    return !checkExtras || CartCalc.hasSameOrderExtras(leadOP1, leadOP2);
  }

  static getOrderProductExtrasPrice(orderProduct: OrderProduct, product: Product): number {
    if (!product || !orderProduct)
      return 0;

    let totalExtrasPrice: number = 0;
    for (let v: Version of product.versions) {
      if (v.id === orderProduct.versionID) {
        totalExtrasPrice += v.price;
        break;
      }
    }

    for (let eg: ExtraGroup of product.extraGroups) {
      if (eg.versionID !== orderProduct.versionID)
        continue;

      let freeCount = 0;
      for (let oe of orderProduct.extras) {
        if (oe.extraGroupID !== eg.id)
          continue;

        let freeLeft = eg.freeQty - freeCount;
        if (freeLeft > oe.qty) {
          freeCount += oe.qty;
        } else {
          freeCount += freeLeft;
          totalExtrasPrice += (oe.qty - freeLeft) * oe.price;
        }
      }
    }

    return totalExtrasPrice;
  }

  static getLeadOrderProduct(orderProduct: OrderProduct[]): OrderProduct {
    if (!orderProduct)
      return null;
    if (!CartCalc.isIterable(orderProduct)) {
      return orderProduct;
    } else if (orderProduct.length <= 0) {
      return null;
    }

    if (orderProduct.length === 1)
      return orderProduct[0];
    for (let op of orderProduct)
      if (op.type === ProductTypes.MENU)
        return op;
  }

  static isIterable(obj: any) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  // noinspection JSUnusedGlobalSymbols
  static getAtPlaceOrders(): Order[] {
    //   if (ContextSystem.atPlaceQRID < 0 || !ContextSystem.atPlaceTableReservation)
    //     return [];
    //
    //   return ContextSystem.orders.filter(o => o.tableReservationID === ContextSystem.atPlaceTableReservation.id);
  }

  static calcOrderShippingPrice(availableShippingPrices: ShippingPrice[], orderProductsTotal: number) {
    let shippingPrice = 0;
    if (availableShippingPrices && availableShippingPrices.length > 0) {
      availableShippingPrices.sort((a, b) => a.price - b.price);

      shippingPrice = availableShippingPrices[0].price;
      if (availableShippingPrices[0].price2Min > 0 && orderProductsTotal >= availableShippingPrices[0].price2Min)
        shippingPrice = availableShippingPrices[0].price2;
    }
    return shippingPrice;
  }

  static calcOrderProductsTotalPrice(products: Product[], calcSplitPrice: boolean = false) {
    let total = 0;
    if (!products || products.length <= 0)
      return 0;

    for (let p of products) {
      let leadOP = CartCalc.getLeadOrderProduct(p.orderProduct);
      let price: number = leadOP.qty * (leadOP.price + leadOP.extrasPrice);
      if (calcSplitPrice)
        price = price / p._splitBy;
      total += price;
    }
    return total;
  }

  static addProduct(product: Product, cart: Product[]): Product[] {
    if (!product || !cart)
      return cart;

    let leadOP = CartCalc.getLeadOrderProduct(product.orderProduct);
    let found = false;
    for (let opCart: Product of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = CartCalc.getLeadOrderProduct(opCart.orderProduct);
        if (CartCalc.isSameOrderProduct(leadOP, cartLeadOP)) {
          found = true;
          cartLeadOP.qty += leadOP.qty;
          break;
        }
      }
    }
    if (!found)
      cart.push(product);
    return cart;
  }

  static addMenu(menu: Product, cart: Product[]): Product[] {
    if (!menu || !cart)
      return cart;

    let found = false;
    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(menu.orderProduct);
    for (let cartElement of cart) {
      let cartElementLeadOP = CartCalc.getLeadOrderProduct(cartElement.orderProduct);
      if (cartElement.type && cartElement.type === ProductTypes.MENU && CartCalc.isSameOrderMenu(cartElement, menu)) {
        found = true;
        cartElementLeadOP.qty += leadOP.qty;
        break;
      }
    }
    if (!found)
      cart.push(menu);
    return cart;
  }

  /**
   * Remove product from the cart. Either a simple product or from a combined menu.
   * With this function you can not remove menus.
   */
  static removeProduct(product: Product, cart: Product[]): Product[] {
    if (!product)
      return cart;

    let cartNew: Product[] = [];

    let leadOP = CartCalc.getLeadOrderProduct(product.orderProduct);

    let addProduct: Product = null;

    for (let cartElement: Product of cart) {
      let filtered = false;
      let cartLeadOP = CartCalc.getLeadOrderProduct(cartElement.orderProduct);

      if (cartElement.type === ProductTypes.PRODUCT) {
        //cartElement is a simple product
        if (CartCalc.isSameOrderProduct(cartLeadOP, leadOP))
          filtered = true;
      } else if (cartElement.type === ProductTypes.MENU && cartElement.id < -1) {
        //cartElement is a combined menu
        let menuProducts = [];
        for (let cartSubProduct of cartElement.products) {
          let cartSubLeadOP = CartCalc.getLeadOrderProduct(cartSubProduct.orderProduct);

          let filteredInMenu = false;
          if (CartCalc.isSameOrderProduct(cartSubLeadOP, leadOP))
            filteredInMenu = true;
          if (!filteredInMenu)
            menuProducts.push(cartSubProduct);
        }

        if (menuProducts.length <= 0)
          filtered = true;
        else if (menuProducts.length === 1) {
          filtered = true;
          cartElement = menuProducts[0];
          addProduct = cartElement;
          let addProductLeadOP: OrderProduct = CartCalc.getLeadOrderProduct(addProduct.orderProduct);
          addProductLeadOP.menuID = -1;
        } else {
          cartElement.products = menuProducts;
          let menuTotal = 0;
          for (let cartSubProduct of cartElement.products) {
            let cartSubLeadOP = CartCalc.getLeadOrderProduct(cartSubProduct.orderProduct);
            menuTotal += cartSubLeadOP.qty * (cartSubLeadOP.price + CartCalc.getOrderProductExtrasPrice(cartSubLeadOP,
              cartSubProduct,
            ));
          }
          cartLeadOP.price = menuTotal;
        }
      }

      if (!filtered)
        cartNew.push(cartElement);
    }

    if (addProduct)
      cartNew = CartCalc.addProduct(addProduct, cart);

    return cartNew;
  }

  /**
   * Remove combined or simple menu from the cart.
   * With this function you can not remove products.
   */
  static removeMenu(menu: Product, cart: Product[]): Product[] {
    if (!menu || !cart)
      return cart;

    let cartNew: Product[] = [];

    for (let cartElement: Product of cart) {
      if (!CartCalc.isSameOrderMenu(cartElement, menu))
        cartNew.push(cartElement);
    }

    return cartNew;
  }

  static getProductQuantity(product: Product, cart: Product[]): number {
    if (!product || !product.orderProduct)
      return 0;
    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product?.orderProduct);
    if (!leadOP)
      return 0;
    for (let opCart of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = CartCalc.getLeadOrderProduct(opCart.orderProduct);
        if (CartCalc.isSameOrderProduct(leadOP, cartLeadOP)) {
          return cartLeadOP.qty;
        }
      }
    }
    return 0;
  }

  static addProductQuantity(product: Product, cart: Product[], qty: number, orderProductNew: OrderProductSorted = undefined): Product[] {
    let found: boolean = false;

    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
    for (let opCart of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = CartCalc.getLeadOrderProduct(opCart.orderProduct);
        if (CartCalc.isSameOrderProduct(leadOP, cartLeadOP)) {
          cartLeadOP.qty += qty;
          if (orderProductNew) {
            cartLeadOP.price = orderProductNew.price;
            cartLeadOP.orderID = orderProductNew.orderID;
            cartLeadOP.itemTotalPrice = orderProductNew.itemTotalPrice;
            cartLeadOP.extrasPrice = orderProductNew.extrasPrice;
          }
          found = true;
          break;
        }
      }
    }
    if (!found) {
      let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
      leadOP.qty = qty;
      cart = this.addProduct(product, cart);
    }

    return cart;
  }

  static addMenuQuantity(menu: Product, cart: Product[], qty: number): Product[] {
    let found: boolean = false;
    for (let cartElement of cart) {
      let cartElementLeadOP = CartCalc.getLeadOrderProduct(cartElement.orderProduct);
      if (cartElement.type && cartElement.type === ProductTypes.MENU && CartCalc.isSameOrderMenu(cartElement, menu)) {
        cartElementLeadOP.qty += qty;
        found = true;
        break;
      }
    }
    if (!found) {
      let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(menu.orderProduct);
      leadOP.qty = qty;
      cart = this.addMenu(menu, cart);
    }
    return cart;
  }

  static decreaseMenuQuantity(menu: Product, cart: Product[], qty: number, allowNegative: boolean = false): Product[] {
    let removeItem: boolean = false;
    let found: boolean = false;

    for (let cartElement: Product of cart) {
      let cartElementLeadOP: OrderProduct = CartCalc.getLeadOrderProduct(cartElement.orderProduct);
      if (cartElement.type && cartElement.type === ProductTypes.MENU && CartCalc.isSameOrderMenu(cartElement, menu)) {
        found = true;
        if (allowNegative || cartElementLeadOP.qty > qty) {
          cartElementLeadOP.qty -= qty;
        } else {
          removeItem = true;
        }
        break;
      }
    }

    if (!found && allowNegative) {
      let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(menu.orderProduct);
      leadOP.qty = -qty;
      cart.push(menu);
    }

    if (removeItem)
      cart = CartCalc.removeMenu(menu, cart);

    return cart;
  }

  static decreaseProductQuantity(product: Product, cart: Product[], qty: number, allowNegative: boolean = false): Product[] {
    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
    let removeItem: boolean = false;
    let found: boolean = false;
    for (let opCart of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = CartCalc.getLeadOrderProduct(opCart.orderProduct);
        if (CartCalc.isSameOrderProduct(leadOP, cartLeadOP)) {
          found = true;

          if (allowNegative || cartLeadOP.qty > qty) {
            cartLeadOP.qty -= qty;
          } else {
            removeItem = true;
          }
          break;
        }
      }
    }

    if (!found && allowNegative) {
      let leadOP = CartCalc.getLeadOrderProduct(product.orderProduct);
      leadOP.qty = -qty;
      cart.push(product);
    }

    if (removeItem)
      cart = CartCalc.removeProduct(product, cart);
    return cart;
  }

  static decreaseQuantity(p: Product, cart: Product[], qty: number, allowNegative: boolean = false): Product[] {
    if (p.type === ProductTypes.PRODUCT) {
      return CartCalc.decreaseProductQuantity(p, cart, qty, allowNegative);
    } else if (p.type === ProductTypes.MENU) {
      return CartCalc.decreaseMenuQuantity(p, cart, qty, allowNegative);
    }
  }

  static increaseQuantity(p: Product, cart: Product[], qty: number): Product[] {
    if (p.type === ProductTypes.PRODUCT) {
      return CartCalc.addProductQuantity(p, cart, qty);
    } else if (p.type === ProductTypes.MENU) {
      return CartCalc.addMenuQuantity(p, cart, qty);
    }
  }

  static createCartFromOrder(order: Order, useOriginalPrices: boolean = false): Product[] {
    let cart: Product[] = [];
    if (!order)
      return {};
    let orderCopy: Order = JSON.parse(JSON.stringify(order));

    let orderProductsList: OrderProductSorted[] = Orders.sortProducts(orderCopy.productList);

    for (let orderProduct: OrderProductSorted of orderProductsList) {
      let psd: Product = ContextSystem.products.find(pp => pp && pp.id === CartCalc.getLeadOrderProduct(orderProduct)?.productID);
      if (!psd)
        continue;
      let p: Product = JSON.parse(JSON.stringify(psd));
      if (!p)
        continue;

      let leadOP = CartCalc.getLeadOrderProduct(p.orderProduct);
      let newOPList: OrderProduct[] = [];
      if (orderProduct.type === ProductTypes.MENU && orderProduct.products) {
        p.orderProduct.forEach(op => {
          if (leadOP === op) {
            this.addOrderProductToOrderProductList(orderProduct, newOPList, useOriginalPrices);
          } else {
            this.addOrderProductToOrderProductList(op, newOPList, useOriginalPrices);
          }
        });
      } else { //product
        this.addOrderProductToOrderProductList(orderProduct, newOPList, useOriginalPrices);
      }
      p.orderProduct = newOPList;

      cart.push(p);
    }

    for (let o of orderCopy.subOrders) {
      let orderProductsListSub: OrderProductSorted[] = Orders.sortProducts(o.productList);
      if (!orderProductsListSub)
        return;
      for (let orderProduct: OrderProductSorted of orderProductsListSub) {
        let prs = ContextSystem.products.find(pp => pp.id === CartCalc.getLeadOrderProduct(orderProduct).productID);
        if (!prs)
          return;
        let p: Product = JSON.parse(JSON.stringify(prs));
        if (!p)
          return;

        if (!useOriginalPrices)
          p.orderProduct[0] = orderProduct;
        if (orderProduct.products) { //menu
          cart = CartCalc.addMenuQuantity(p, cart, orderProduct.qty);
        } else { //product
          //orderID update is needed bcs of the usedCouponIDs, this way we can match later the usedCouponID to the product
          cart = CartCalc.addProductQuantity(p, cart, orderProduct.qty, orderProduct);
        }
      }
    }

    cart = cart.filter(product => CartCalc.getLeadOrderProduct(product.orderProduct).qty !== 0);

    return cart;
  }

  static addOrderProductToOrderProductList(orderProduct: OrderProduct, orderProducts: OrderProduct[], useOriginalPrices: boolean) {
    if (useOriginalPrices) {
      orderProduct.price = orderProduct.originalPrice;
      orderProduct.extrasPrice = orderProduct.originalExtrasPrice;
      orderProduct.itemTotalPrice = orderProduct.originalItemTotalPrice;
    }
    orderProducts.push(orderProduct);
  }

  static addProductToCart(product: Product, cart: Product[]) {
    cart.push(product);
  }

  static createOrderFromState(
    cart: Product[], payment: OrderPayment[], tableSelected: Element = null, originalOrderNumber: string = undefined,
    selectedCoupons: Coupon[] = [], profile: Profile = null, comment: string = "", shippingMethod: number = ShippingMethods.AT_PLACE,
  ): Order {
    if (!cart || cart.length <= 0)
      return null;

    let k: number = 0;
    let productList: OrderProduct[] = [];

    for (let p: Product of cart) {
      for (let op: OrderProduct of p.orderProduct) {
        op.productIndex = k;
        productList.push(op);
      }
      k++;
    }

    let paymentMethod: number = 1;
    let orderTotalPrice: number = CartCalc.calcOrderProductsTotalPrice(cart);

    let tableID: number = tableSelected ? tableSelected.id : undefined;
    let usedCoupons: Coupon[] = selectedCoupons;
    if (tableID > 0)
      shippingMethod = ShippingMethods.AT_PLACE;

    if (tableID > 0) {
      let tres: TableReservation = ContextSystem.tableReservations.find(tr =>
        tr.enabled
        && tr.tableID === tableID
        && [TableReservationStatuses.SEATED, TableReservationStatuses.LEAVING].includes(tr.status),
      );
      if (tres) {
        profile = ContextSystem.profiles.find(p => p.id === tres.profileID);
        if (!profile)
          profile = { id: tres.profileID };
      }
    }

    let order: Order = {
      id: -1,
      enabled: true,
      number: -1,
      dailyNumber: -1,
      comment,
      profile,
      productList,
      orderTotalPrice,
      date: new Date(),
      orderStates: [],
      lastState: {
        id: -1,
        enabled: true,
        comment: "",
        dateTime: new Date(),
        orderID: -1,
        status: OrderState.DONE,
        finished: true,
      },
      partnerID: ContextSystem.selectedShop.id,
      shippingMethod,
      shippingPrice: 0,
      paymentMethod,
      address: null,
      shippingPriceID: -1,
      sourceID: OrderSource.ENNIAKAROK_POS,
      countryID: ContextSystem.selectedShop.countryID,
      tableReservationID: -1,
      scheduleDate: null,
      payments: payment,
      originalOrderID: -1,
      subOrders: [],
      usedCoupons: [],
      _useCoupons: usedCoupons,
      originalOrderTotalPrice: orderTotalPrice,
      shopProfileID: ContextSystem.profile.id,
      originalShippingPrice: 0,

      tableID,
      //not part of "Order"
      originalOrderNumber,
    };

    CartCalc.applyCoupons(cart, order);
    return order;
  }
}

