import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import Modal from "./Modal";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { Contract } from "../../model/Contract";
import ContractContent from "../contract/ContractContent";
import { CommissionRecord } from "../../model/CommissionRecord";
import ContextSystem from "../../utils/ContextSystem";
import Language, { Names } from "../../utils/Language";
import { Button } from "../FormComponents";
// import validateVAT from 'validate-vat';

const Body = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  & > newline {
    display: block;
    height: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  & > h3 {
    width: 100%;
    text-align: left;
  }
`;

const ContractContentWrapper = styled.div`
  width: 95%;
  margin: 15px auto;
`;

export default class ShowContractModal extends Component {
  state: {
    showModal: boolean,
    title: string,
    commissionRecord: CommissionRecord,
    contract: Contract,
    language: number,
  } = {
    showModal: false,
    commissionRecord: null,
    contract: null,
    language: ContextSystem.language,
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_show_contract_modal, ({ contract, commissionRecord }) => {
      this.setState({
        showModal: true,
        contract,
        commissionRecord,
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"xl"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.CheckContract)}</Modal.Title>
        </Modal.Header>
        <Body>
          {this.state.showModal &&
            <>
              <ContractContentWrapper>
                <ContractContent contract={this.state.contract} />
              </ContractContentWrapper>
            </>
          }
        </Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
