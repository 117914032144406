import React, { Component } from "react";
import styled, { css } from "styled-components";
import ReactResizeDetector from "react-resize-detector";

const AnimateHeightWrapper = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: max-height ${({ duration }) => duration ?? "200ms"} ease-in-out;
    // margin ${({ duration }) => duration ?? "200ms"} ease-in-out;

  ${({ opened }) => opened === true && css`
    max-height: ${({ max, init_max }) => (max === 0 ? init_max ?? 500 : max) + "px"};
    //margin-bottom: 5px;
  `};
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export default class AnimateHeight extends Component<{ opened: boolean }> {
  ref;

  state: {
    max: number,
    initMax: number,
  } = {
    max: 0,
    initMax: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      max: 0,
      initMax: props.initMax,
    };
    AnimateHeight.i++;
  }

  componentDidMount() {
    if (this.props.opened) {
      this.setState({ initMax: this.ref.clientHeight });
    }
  }

  innerDivResized(width, height) {
    this.setState({ max: height });
  }

  render() {
    // noinspection JSUnresolvedVariable
    return (
      <AnimateHeightWrapper
        opened={this.props.opened}
        duration={this.props.duration}
        max={this.state.max}
        init_max={this.state.initMax}
      >
        <ReactResizeDetector handleHeight onResize={(w, h) => this.innerDivResized(w, h)}>
          <InnerWrapper ref={r => this.ref = r}>
            {this.props.children}
          </InnerWrapper>
        </ReactResizeDetector>
      </AnimateHeightWrapper>
    );
  }
}
