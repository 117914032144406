import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import Modal from "./Modal";
import "react-image-crop/dist/ReactCrop.css";
import styled, { css } from "styled-components";
import ContextSystem from "../../utils/ContextSystem";
import { FaPlus } from "react-icons/all";
import { Contract, ContractStatus } from "../../model/Contract";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import { Shop } from "../../model/Shop";
import { Button } from "../FormComponents";

const Body = styled(Modal.Body)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Label = styled.label`
  width: ${({ width }) => width ?? "fit-content"};
  text-align: left;
  color: ${({ color }) => color ?? "initial"};

  ${({ bold }) => bold === true && css`
    font-weight: bold;
  `};
  ${({ font_size }) => font_size === true && css`
    font-size: ${font_size};
  `};
  padding: 2px 0;
`;

const IconButton = styled.div`
  font-size: 16pt;

  & > svg {
    cursor: pointer;
  }
`;

const ContractsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ContractDiv = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #b4b4b4;

  & > div {
    margin-left: auto;
  }

  & > p > b {
    margin-right: 5px;
  }
`;

export default class ContractsDetailsModal extends Component {
  state: {
    showModal: boolean,
    contracts: Contract[],
    language: number,
    selectedShop: Shop
  } = {
    showModal: false,
    contracts: ContextSystem.contracts?.filter(
      c => c.partnerID === ContextSystem.selectedShop?.id || c.id === ContextSystem.selectedShop?.contractID),
    language: ContextSystem.language,
    selectedShop: ContextSystem.selectedShop,
  };

  eventIDs: number[] = [];

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    EventSystem.subscribe(EventSystem.events.open_contracts_details, () => {
      this.setState({
        showModal: true,
        contracts: ContextSystem.contracts.filter(
          c => c.partnerID === ContextSystem.selectedShop.id || c.id === ContextSystem.selectedShop.contractID),
      });
    });

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ profile, contracts, language, selectedShop }) => {
        if (contracts !== undefined) {
          this.setState({ contracts });
        }
        if (profile !== undefined) {
          this.setState({ profile });
        }
        if (language !== undefined) {
          this.setState({ language });
        }
        if (selectedShop !== undefined) {
          this.setState({
            selectedShop,
            contracts: ContextSystem.contracts.filter(
              c => c.partnerID === ContextSystem.selectedShop.id || c.id === ContextSystem.selectedShop.contractID),
          });
        }
      },
    );
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.Contracts)}</Modal.Title>
          <IconButton>
            <FaPlus onClick={() => this.handlePlusClicked()} />
          </IconButton>
        </Modal.Header>
        {this.state.showModal &&
          <Body>
            {(!this.state.contracts || this.state.contracts.length <= 0) &&
              <>
                <Label>{Language.getName(Names.NoContractsYetText)}</Label>
                <Button onClick={() => this.handlePlusClicked()}>
                  {Language.getName(Names.StartContract)}
                </Button>
              </>
            }
            {this.state.contracts && this.state.contracts.length > 0 &&
              <ContractsWrapper>
                {this.state.contracts.map((c, i) =>
                  <ContractDiv key={i}>
                    <b>{Language.getName(Names.State)}:</b> {ContractStatus.getName(c.status)}<br />
                    <br />
                    <p>
                      <b>{Language.getName(Names.CompanyName)}:</b>{c.companyName}<br />
                      <b>{Language.getName(Names.CompanyVAT)}:</b>{c.companyVAT}<br />
                      <b>{Language.getName(
                        Names.CompanyHQ)}</b>{c.hqCountry + ", "}{c.hqZip + " "}{c.hqCity + ", "}{c.hqAddress}<br />
                      <b>{Language.getName(Names.BankName)}:</b>{c.bankName}<br />
                      <b>{Language.getName(Names.BankAccountNumber)}:</b>{c.payoutIBAN}<br />
                      <b>{Language.getName(
                        Names.SignedBy)}:</b>{c.representativeName + " ("}{c.contactEmail + ", "}{c.contactTel + ")"}<br />
                      <b>{Language.getName(Names.Date)}:</b>{c.date.toHunFormatMinutes()}<br />
                    </p>
                    <Button onClick={() => this.handleSeeContractClicked(c)}>
                      {Language.getName(Names.CheckContract)}
                    </Button>
                  </ContractDiv>,
                )}
              </ContractsWrapper>
            }
          </Body>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handlePlusClicked() {
    if (this.state.contracts.find(c => c.status === ContractStatus.NEW || c.status === ContractStatus.ACCEPTED)) {
      toast(Language.getName(Names.ContractUnderProcessText));
      return;
    }
    EventSystem.publish(EventSystem.events.open_add_contract_modal);
    this.setState({ showModal: false });
  }

  handleSeeContractClicked(contract: Contract) {
    let maxDateCR = ContextSystem.commissionRecords[0];
    if (!maxDateCR)
      return;
    for (let cr of ContextSystem.commissionRecords) {
      if (cr.date > maxDateCR && parseInt(cr.contractID) === contract.id)
        maxDateCR = cr;
    }

    EventSystem.publish(EventSystem.events.open_show_contract_modal, { contract, commissionRecord: maxDateCR });
  }
}
