import { Profile } from "./Profile";
import { Address } from "./Address";
import AbstractModel from "./AbstractModel";
import Config from "../utils/Config";
import ContextSystem from "../utils/ContextSystem";
import { Product, TranslatableString } from "./Product";
import CartCalc from "../utils/CartCalc";
import { Coupon } from "./Coupon";
import { PaymentMethodTypes } from "./PaymentMethodSetting";
import Language, { Names } from "../utils/Language";

export type OrderStateComment = { message: string, minutes: number };

export class OrderSource {
  static ENNIAKAROK_ONLINE: number = 1;
  static ENNIAKAROK_BLUNDEE: number = 2;
  static ENNIAKAROK_POS: number = 3;
  static WOLT: number = 4;
  static FOODPANDA: number = 5;
  static FALATOZZ: number = 6;
}

export class OrderState {
  static WAITING_FOR_ONLINE_PAYMENT: number = 2;

  static NEW: number = 1;
  static CONFIRMED: number = 3;
  static PREPARING: number = 9;
  static READY: number = 6;
  static SERVED: number = 10;
  static DONE: number = 8;

  static DECLINED: number = 4;
  static DELETED: number = 5;
  static FAILED_TRANS: number = 7;

  id: number;
  enabled: boolean;
  comment: OrderStateComment;
  dateTime: Date;
  orderID: number;
  status: number;
  finished: boolean;
}

export class OrderExtra extends AbstractModel {
  name: TranslatableString;
  partnerID: number;
  orderID: number;
  qty: number;
  price: number;
  originalPrice: number;
  orderProductID: number;
  extraID: number;
  extraGroupID: number;
  saleID: number;
  total: number;
  originalTotal: number;
}

export type OrderProductSorted = OrderProduct & { products: OrderProduct[] | null };

export class OrderProduct extends AbstractModel {
  name: TranslatableString;
  details: TranslatableString;
  image1: string;
  image2: string;
  image3: string;
  categoryID: number;
  qty: number;
  partnerID: number;
  offerID: number;
  menuID: number;
  orderID: number;
  price: number;
  extrasPrice: number;
  itemTotalPrice: number;
  originalPrice: number;
  originalExtrasPrice: number;
  originalItemTotalPrice: number;
  extras: OrderExtra[];
  type: number;
  productIndex: number;
  productID: number;
  versionID: number;
  note: string;

  /**
   * @param op OrderProduct which it's applied on
   * @param percentage like 15% -> 15.0, 50% -> 50.0
   */
  static applyCouponPercentage(op: OrderProduct, percentage: number): number {
    if (!op)
      return;

    let totalPriceNow: number = op.itemTotalPrice;
    let multiplier: number = percentage / 100.0;
    op.price = op.price - op.originalPrice * multiplier;
    op.extrasPrice = op.extrasPrice - op.originalExtrasPrice * multiplier;
    op.itemTotalPrice = op.itemTotalPrice - op.originalItemTotalPrice * multiplier;
    return totalPriceNow - op.itemTotalPrice;
  }
}

export class Order extends AbstractModel {
  number: string;
  dailyNumber: string;
  comment: string;
  profile: Profile;
  productList: OrderProduct[];
  orderTotalPrice: number;
  originalOrderTotalPrice: number;
  originalShippingPrice: number;
  date: Date;
  orderStates: OrderState[];
  lastState: OrderState; //calculated value, not represented in database!
  partnerID: number;
  shippingMethod: number;
  shippingPrice: number;
  paymentMethod: number;
  address: Address;
  shippingPriceID: number;
  sourceID: number;
  countryID: number;
  tableReservationID: number;
  scheduleDate: Date;
  payments: OrderPayment[];
  shopProfileID: number;
  originalOrderID: number;
  subOrders: Order[];
  usedCoupons: OrderUsedCouponID[];
  _useCoupons: Coupon[];
  _appliedCoupons: Coupon[];

  static isPaidFully(o: Order): boolean {
    if (!o)
      return false;

    if ([OrderState.DELETED, OrderState.DECLINED].includes(o.lastState.status))
      return true;

    return Order.totalPayment(o) >= Order.totalOrderPrice(o);
  }

  static totalOrderPrice(o: Order): number {
    if (!o)
      return 0;

    let cart: Product[] = CartCalc.createCartFromOrder(o);
    let total = CartCalc.calcOrderProductsTotalPrice(cart);
    total += o.shippingPrice;

    o.usedCoupons.forEach(uc => {
      if (uc.modelType === OrderCouponModelTypes.ORDER)
        total -= uc.amount;
    });

    return total;
  }

  static originalTotalOrderPrice(o: Order): number {
    if (!o)
      return 0;

    let total = o.originalOrderTotalPrice;
    for (let subOrder: Order of o.subOrders) {
      total += subOrder.orderTotalPrice;
    }

    return total;
  }

  static totalPayment(o: Order): number {
    if (!o)
      return 0;

    let total: number = 0;
    o.payments.forEach(p => p.enabled && p.payed && (total += p.amount - p.creditedAmount));
    o.subOrders.forEach(subOrder => total += Order.totalPayment(subOrder));
    return total;
  }

  static isNotConfirmed(o: Order): boolean {
    if (o.lastState?.status === OrderState.NEW) {
      if (o.scheduleDate) { //scheduled order --> date is the scheduled date
        if (new Date().addMinutes(-Config.notify_order_not_confirmed_minutes_preorder) > o.date) {
          return true;
        }
      } else { // "instant" order --> date is the creation date
        if (new Date().addMinutes(-Config.notify_order_not_confirmed_minutes) > o.date) {
          return true;
        }
      }
    }
    return false;
  }

  static isDelayed(o: Order): boolean {
    if (o.scheduleDate) { //confirmed within 30 minutes, but now we have to set the "timer" --> showPreparedOrdersBeforeMinutes timing's 50%.
      // for example: order is scheduled for 14.00, shopPrepOrdersBeforeMin. = 60 --> after 13.30, it will show as "SOS"
      if (new Date().addMinutes(
        -2 * (ContextSystem.selectedShop.showPreparedOrdersBeforeMinutes / 4)) > o.scheduleDate) {
        return true;
      }
    } else {
      let due: Date = new Date(o.lastState?.dateTime).addMinutes(-o.lastState?.comment?.minutes);

      if (new Date() > due) {
        return true;
      }
    }

    return false;
  }

  static isStuck(o: Order): boolean {
    if (o.lastState?.status === OrderState.READY || o.lastState?.status === OrderState.SERVED) {
      //filter for "stuck" orders
      if (new Date().addHours(-24) > o.lastState?.dateTime) {
        return true;
      }
    }
    return false;
  }

  static isHidden(o: Order): boolean {
    if (!o)
      return true;

    if (!o.scheduleDate)
      return false;

    if (o.lastState.status === OrderState.NEW)
      return false;

    let min: number = ContextSystem.selectedShop.showPreparedOrdersBeforeMinutes;

    let maxShowWindow: Date = new Date().addMinutes(min);

    return o.scheduleDate > maxShowWindow;
  }
}

export class OrderPayment extends AbstractModel {
  dateTime: Date;
  orderID: number;
  txid: number;
  creditedAmount: number;

  shopID: number;
  paymentType: number;
  payed: boolean;
  amount: number;
  orderContactID: number;
  productPayments: OrderProductPayment[];
  _split: number;

  static getPaidSum(ors: OrderPayment[], type: number = PaymentMethodTypes.ANY): number {
    if (!ors || ors?.length <= 0)
      return 0;

    let sum = 0;
    ors.forEach(or => {
      if (type === PaymentMethodTypes.ANY || type === or.paymentType)
        sum += or.amount - or.creditedAmount;
    });
    return sum;
  }
}

export class OrderProductPayment extends AbstractModel {
  orderID: number;
  orderPaymentID: number;
  orderProductID: number;
}

export class OrderShopProfileContact extends AbstractModel {
  orderID: number;
  shopProfileID: number;
  contactType: OrderContactType;
  date: Date;
}

export const OrderCouponModelTypes = {
  ORDER: 1,
  ORDER_PRODUCT: 2,
  ORDER_EXTRA: 3,
};

export type OrderCouponModelType = $Values<typeof OrderCouponModelTypes>;

export class OrderUsedCouponID extends AbstractModel {
  usedCouponID: number;
  modelID: number;
  modelType: number;
  orderID: number;
  addedDate: Date;
  amount: number;
}

export const OrderContactTypes = {
  CREATION: 0,
  CONFIRMATION: 1,
  PREPARATION: 2,
  READINESS: 3,
  SERVE: 4,
  MONEY_COLLECTOR: 5,
  CANCELLATION: 6,
  FAIL_TRANSFER: 7,
  MODIFICATION: 8,
};

export type OrderContactType = $Values<typeof OrderContactTypes>;

export class TableReservation extends AbstractModel {
  tableID: number;
  partnerID: number;
  profileID: number;
  numberOfPeople: number;
  addedDate: Date;
  start: Date;
  end: Date;
  changes: TableReservationChange[];
  comment: string;
  note: string;
  acceptedByRestaurant: boolean;
  status: TableReservationStatus;

  static getStart(tableReservation: TableReservation): Date | undefined {
    if (!tableReservation || !tableReservation.start)
      return undefined;

    let startDate: Date = tableReservation.start;
    if (tableReservation.changes?.length > 0)
      startDate = tableReservation.changes[tableReservation.changes.length - 1].newStart;
    return startDate;
  }

  static getEnd(tableReservation: TableReservation): Date | undefined {
    if (!tableReservation || !tableReservation.start)
      return undefined;

    let endDate: Date = tableReservation.end;
    if (tableReservation.changes?.length > 0)
      endDate = tableReservation.changes[tableReservation.changes.length - 1].newEnd;
    return endDate;
  }

  static isClosed(r: TableReservation): boolean {
    if (!r)
      return false;

    return [
      TableReservationStatuses.DONE, TableReservationStatuses.FORCE_DONE,
      TableReservationStatuses.DECLINED, TableReservationStatuses.CANCELLED,
    ].includes(r.status);
  }

  static isDelayed(r: TableReservation, orders: Order[]): boolean {
    if (!r || !orders)
      return false;

    let delayed: boolean = false;
    for (let o of orders) {
      if (Order.isDelayed(o)) {
        delayed = true;
        break;
      }
    }

    return delayed;
  }

  static isUnpaid(r: TableReservation, orders: Order[]): boolean {
    if (!r || !orders)
      return false;

    let paid: boolean = true;
    orders.forEach(o => paid = paid && Order.isPaidFully(o));

    return !paid;
  }

  static isNotClosed(r: TableReservation): boolean {
    if (!r)
      return false;

    let end: Date = TableReservation.getEnd(r);

    return r.status === TableReservationStatuses.SEATED && new Date().addHours(-12) > end;
  }

  static getStatusString(status: number) {
    switch (status) {
      case TableReservationStatuses.NEW:
        return Language.getName(Names.TableReservationNEW);
      case TableReservationStatuses.ACCEPTED:
        return Language.getName(Names.TableReservationACCEPTED);
      case TableReservationStatuses.SEATED:
        return Language.getName(Names.TableReservationSEATED);
      case TableReservationStatuses.LEAVING:
        return Language.getName(Names.TableReservationLEAVING);
      case TableReservationStatuses.DONE:
        return Language.getName(Names.TableReservationDONE);
      case TableReservationStatuses.CANCELLED:
        return Language.getName(Names.TableReservationCANCELLED);
      case TableReservationStatuses.FORCE_DONE:
        return Language.getName(Names.TableReservationFORCE_DONE);
      case TableReservationStatuses.DECLINED:
        return Language.getName(Names.TableReservationDECLINED);
      default:
        return Language.getName(Names.OrderState3Dot);
    }
  }
}

export class TableReservationChange extends AbstractModel {
  reservationID: number;
  partnerProfileID: number;
  lastStart: Date;
  lastEnd: Date;
  newStart: Date;
  newEnd: Date;
  addedDate: Date;
  cancelled: boolean;
}

export const TableReservationStatuses = {
  NEW: 0, //Reservation which was added by the customer through app
  ACCEPTED: 1, //Reservation which was accepted by restaurant OR added by restaurant
  SEATED: 5,
  LEAVING: 7,

  CANCELLED: 2, //Cancelled reservation from customer OR restaurant
  DONE: 3, //finished booking
  FORCE_DONE: 4, //force finished booking (not paid probably)
  DECLINED: 6, //Reservation was not accepted by the restaurant
};

export type TableReservationStatus = $Values<typeof TableReservationStatuses>;
