import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import { ChangeItemsOrderType, ProductsAPI } from "../../utils/api/ProductsAPI";
import { ProductType, TabBar, TabItem } from "./Orders";
import EventSystem from "../../utils/EventSystem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import {
  BsFilePlus,
  BsReceiptCutoff,
  CgListTree,
  FaCopy,
  FaEye,
  FaEyeSlash,
  FaPen,
  FaPlus,
  FaTrashAlt,
  GiTakeMyMoney,
} from "react-icons/all";
import Config from "../../utils/Config";
import ContextSystem from "../../utils/ContextSystem";
import { Category, CategoryViewTypes, Extra, Product, TranslatableString } from "../../model/Product";
import Language, { Names } from "../../utils/Language";
import { Input } from "../FormComponents";
import ConfirmationModal from "../modals/ConfirmationModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ListWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 24px;
  height: 100%;

  overflow-y: scroll;
`;

export const DivImg = styled.img`
  display: block;
  width: ${({ width }) => (width ? width : "10%")};
  max-height: 60px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 15px;
`;

export const ProductDiv = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px gray;

  min-height: 50px;
  background-color: white;
  padding: 8px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HiddenRectangle = styled.div`
  width: 0.5%;
  background-color: ${({ show }) => (show ? "#841a1e" : "transparent")};
  margin-right: 1%;

  &:before {
    content: "";
    display: block;
    padding-top: 600%;
  }
`;

export const Div = styled.div`
  width: ${({ width }) => (width ? width : "15%")};
  overflow: hidden;
  font-size: 10pt;
  word-wrap: anywhere;
  margin: 0 3px;

  ${({ maxlines }) => maxlines !== undefined && css`
    display: -webkit-box;
    //noinspection CssUnknownProperty
    -webkit-line-clamp: ${maxlines};
    -webkit-box-orient: vertical;
  `}
`;

export const Controls = styled.div`
  width: ${({ width }) => (width ? width : "20%")};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-grow: 2;

  & > div {
    width: 60px;
    margin: 5px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10pt;

    & > svg {
      font-size: 15pt;
      color: #444440;
    }

    &:hover, &:active {
      cursor: pointer;
    }
  }
`;

export const HeaderLeftWrapper = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const HeaderButtonWrapper = styled.div`
  width: calc(${({ width }) => width ? width : "100%"} - 48px);
  margin: ${({ margin_top }) => margin_top ? margin_top : "12px"} 24px 0 24px;

  padding-bottom: ${({ button_padding }) => button_padding ? button_padding : "7px"};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const loadingRotateAnimation = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const HeaderButtonTopRightCircle = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 9pt;
  font-weight: bold;

  border-radius: 100px;
  box-shadow: 0 0 3px 0 black;
  background-color: white;
  color: black;
`;

export const HeaderButton = styled.div`
  margin: 0 6px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
  border-radius: 7px;
  cursor: pointer;
  padding: 3px 7px;
  position: relative;

  transition: background-color 200ms ease-in-out;

  ${({ background_color }) => background_color && css`
    background-color: ${background_color};
  `};

  ${({ disabled }) => disabled && css`
    color: #666666;

    & > svg {
      animation: ${loadingRotateAnimation} 0.7s linear infinite;
    }
  `};

  ${({ margin_right, margin_left }) => {
    if (margin_right && margin_left)
      return css`
        margin-left: ${margin_left};
        margin-right: ${margin_right};
        border-radius: 0;
      `;
    else if (margin_right)
      return css`
        margin-right: ${margin_right};
        border-radius: 7px 0 0 7px;
      `;
    else if (margin_left)
      return css`
        margin-left: ${margin_left};
        border-radius: 0 7px 7px 0;
      `;
  }};

  & > svg {
    font-size: ${({ svg_font_size }) => svg_font_size ? svg_font_size : "22pt"};
  }

  & > span {
    text-align: center;
    font-size: ${({ font_size }) => font_size ? font_size : "inherit"};
    ${({ max_width }) => max_width && css`
      max-width: ${max_width};
    `};
  }

  &:hover, &:active {
    background-color: rgb(230, 230, 230);
  }
`;

export default class Products extends Component {
  eventIDs = [];
  state: {
    products: Product[],
    categories: Category[],
    selectedCategory: null | Category,
    extras: Extra[],
    language: number,
    search: string,
  } = {
    products: ContextSystem.products?.filter(p => p.partnerID === ContextSystem.selectedShop?.id) ?? [],
    categories: ContextSystem.categories?.filter(c => c.partnerID === ContextSystem.selectedShop?.id) ?? [],
    selectedCategory: null,
    extras: [],
    language: ContextSystem.language,
    search: "",
  };

  getCategoryById(catID: number): Category | undefined {
    if (!this.state.categories || this.state.categories.length <= 0)
      return undefined;

    for (let category of this.state.categories) {
      if (category.id === catID)
        return category;
    }
    return undefined;
  }

  hasChildren(category, categories) {
    if (!category || !categories)
      return false;

    for (let c of categories) {
      if (c.parent === category.id)
        return true;
    }
    return false;
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  loadProducts() {
    this.setState({
        products: ContextSystem.products?.filter(p =>
          p.partnerID === ContextSystem.selectedShop?.id &&
          p.type === ProductType.PRODUCT &&
          p.enabled,
        ).sort((p1, p2) => p1.orderPlace - p2.orderPlace) ?? [],
        extras: ContextSystem.extras,
        categories: ContextSystem.categories?.filter(c => c.partnerID === ContextSystem.selectedShop?.id) ?? [],
      },
    );
    this.reloadSelectedCategory();
  }

  componentDidMount() {
    this.eventIDs = [];

    this.loadProducts();

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ categories, language, products, extras, selectedShop }) => {
        if (categories !== undefined) {
          categories = categories.sort(
            (c1, c2) => c1.parent - c2.parent === 0 ? c1.orderNumber - c2.orderNumber : c1.parent - c2.parent);
          this.reloadSelectedCategory();
          this.setState({ categories });
        }

        if (language !== undefined)
          this.setState({ language });
        if (extras !== undefined || products !== undefined)
          this.loadProducts();
        if (selectedShop !== undefined)
          this.loadProducts();
      },
    );

    this.eventIDs.push(eid);
  }

  changeTab(category) {
    this.setState({ selectedCategory: category });
  }

  onDragEnd(res) {
    const { destination, source } = res;
    if (!destination)
      return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    if (this.state.search.length > 0) {
      toast(Language.getName(Names.CannotChangeOrderWhileSearching));
      return;
    }

    let copyAllProducts = [];
    for (let product of this.state.products) {
      copyAllProducts.push({ ...product });
    }

    let prodSource = copyAllProducts[source.index];

    copyAllProducts.splice(source.index, 1);
    copyAllProducts.splice(destination.index, 0, prodSource);

    let k = 0;
    let changedProducts: ChangeItemsOrderType[] = [];
    for (let i = 0; i < copyAllProducts.length; i++) {
      let product = copyAllProducts[i];
      if (product.categoryID === this.state.selectedCategory.id) {
        product.orderPlace = k++;
        changedProducts.push({
          id: product.id,
          orderPlace: product.orderPlace,
        });
      }
    }

    ProductsAPI.changeOrderPlace(changedProducts, (res) => {
      if (res.error === 0) {
        toast(Language.getName(Names.OrderingSaved));
      }
    });

    this.setState({ products: copyAllProducts });
  }

  toggleActive(product) {
    ProductsAPI.toggleProductActive(product.id, (res) => {
      if (res.error !== 0)
        return;
      if (product.active)
        toast(Language.getName(Names.ProductHiddenText));
      else
        toast(Language.getName(Names.ProductUnhiddenText));
      this.loadProducts();
    });
  }

  getInactiveProductsCountTextInCategory(category) {
    let inactiveCount = 0;
    let affectedCategoryIDs = this.state.categories.filter((x) => x.id === category.id || x.parent === category.id)
      .map((x) => x.id);
    for (let id of affectedCategoryIDs) {
      inactiveCount += this.state.products.filter((x) => !x.active && x.categoryID === id).length;
    }

    return inactiveCount > 0 ? " (" + inactiveCount + " " + Language.getName(Names.Hidden) + ")" : "";
  }

  render() {
    return (
      <Wrapper>
        <TabBar>
          {this.state.categories && this.state.categories.map((c, i) => {
            if (c.view === CategoryViewTypes.PRIVATE_VIEW) {
              return <React.Fragment key={i} />;
            }
            if (c.parent === 0 || (this.state.selectedCategory && c.id === this.state.selectedCategory.parent)) {
              return (
                <TabItem
                  key={i}
                  selected={this.state.selectedCategory === c || (this.state.selectedCategory && this.state.selectedCategory.parent === c.id)}
                  onClick={() => this.changeTab(c)}
                >
                  {TranslatableString.get(c.name)}
                  {this.getInactiveProductsCountTextInCategory(c)}
                </TabItem>
              );
            } else {
              return <React.Fragment key={i} />;
            }
          })}
        </TabBar>
        {this.state.selectedCategory &&
          (this.hasChildren(this.state.selectedCategory, this.state.categories) || this.state.selectedCategory.parent !== 0)
          && (
            <TabBar>
              {this.state.categories && this.state.categories.map((c, i) => {
                if (c.view === CategoryViewTypes.PRIVATE_VIEW)
                  return <React.Fragment key={i} />;
                if (c.parent === this.state.selectedCategory.id || (this.state.selectedCategory.parent === c.parent && c.parent !== 0)) {
                  return (
                    <TabItem key={i} selected={this.state.selectedCategory === c} onClick={() => this.changeTab(c)}>
                      {TranslatableString.get(c.name)}
                      {this.getInactiveProductsCountTextInCategory(c)}
                    </TabItem>
                  );
                } else {
                  return <React.Fragment key={i} />;
                }
              })}
            </TabBar>
          )
        }
        <HeaderButtonWrapper margin_top={"6px"}>
          <HeaderLeftWrapper>
            <Input
              autoComplete="off"
              value={this.state.search}
              type="text"
              placeholder={Language.getName(Names.Search) + "..."}
              width={"90%"}
              margin={"3px 0"}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </HeaderLeftWrapper>
          <HeaderButton
            onClick={() => EventSystem.publish(EventSystem.events.edit_upsell_rule_settings)}
            max_width={"80px"}
          >
            <GiTakeMyMoney />
            <span>{Language.getName(Names.UpSellCrossSell)}</span>
          </HeaderButton>
          <HeaderButton
            onClick={() => EventSystem.publish(EventSystem.events.edit_product_printer_settings)}
          >
            <BsReceiptCutoff />
            <span>{Language.getName(Names.Printing)}</span>
          </HeaderButton>
          <HeaderButton
            onClick={() => EventSystem.publish(EventSystem.events.edit_product_categories, {
              categories: this.state.categories,
              products: this.state.products,
            })}
          >
            <CgListTree />
            <span>{Language.getName(Names.Categories)}</span>
          </HeaderButton>
          <HeaderButton onClick={() => EventSystem.publish(EventSystem.events.open_extras)}>
            <BsFilePlus />
            <span>{Language.getName(Names.Toppings)}</span>
          </HeaderButton>
          <HeaderButton
            onClick={() =>
              EventSystem.publish(EventSystem.events.edit_product, {
                product: undefined,
                categoryID: this.state.selectedCategory?.id,
                categories: this.state.categories,
                extras: this.state.extras,
                type: ProductType.PRODUCT,
              })
            }
          >
            <FaPlus />
            <span>{Language.getName(Names.AddButtonText)}</span>
          </HeaderButton>
        </HeaderButtonWrapper>
        <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
          <Droppable droppableId={"0"}>
            {(provided) => (
              <ListWrapper ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.products.map((product, i) => {
                  if (!product)
                    return <React.Fragment key={i} />;

                  if (this.state.search.length > 0) {
                    let s: string = this.state.search.toLowerCase();
                    if (!TranslatableString.get(product.name).toLowerCase().includes(s))
                      return <React.Fragment key={i} />;
                  } else {
                    if (!this.state.selectedCategory || product.categoryID !== this.state.selectedCategory.id)
                      return <React.Fragment key={i} />;
                  }
                  let category: Category = this.getCategoryById(product.categoryID);
                  // noinspection JSUnresolvedVariable
                  return (
                    <Draggable draggableId={i + ""} index={i} key={i}>
                      {(provided) => (
                        <ProductDiv {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <HiddenRectangle show={product.active === false} />
                          <DivImg onError={(e) => (e.target.style.display = "none")}
                                  src={Config.productImageUrlBase + product.image} alt={"image"} />
                          <Div width={"15%"}>
                            <b>{Language.getName(Names.Name)}:</b>
                            <br />
                            (#{product.id}) {TranslatableString.get(product.name)}
                          </Div>
                          <Div width={"15%"}>
                            <b>{Language.getName(Names.Price)}:</b>
                            <br />
                            {(!product.versions || product.versions.length <= 0) &&
                              <>
                                {product.price + " Ft"}
                              </>
                            }
                            {product.versions && product.versions.length > 0 && product.versions.map((v, j) => {
                              return (
                                <React.Fragment key={j}>
                                  {product.versions.length > 1 && (
                                    <>
                                      {TranslatableString.get(v.name) + ": "}
                                    </>
                                  )}
                                  {(product.price + v.price) + " Ft"}
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </Div>
                          <Div width={"15%"}>
                            <b>{Language.getName(Names.Category)}:</b>
                            <br />
                            {category ? TranslatableString.get(category.name) : "..."}
                          </Div>
                          <Div width={"20%"} maxlines={4}>
                            <b>{Language.getName(Names.Details)}:</b>
                            <br />
                            {TranslatableString.get(product.details)}
                          </Div>
                          <Controls width={"20%"}>
                            <div onClick={() => this.toggleActive(product)}>
                              {product.active === true && (
                                <>
                                  <FaEyeSlash />
                                  <span>{Language.getName(Names.Hide)}</span>
                                </>
                              )}
                              {product.active === false && (
                                <>
                                  <FaEye />
                                  <span>{Language.getName(Names.Unhide)}</span>
                                </>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                EventSystem.publish(EventSystem.events.edit_product, {
                                  product,
                                  categoryID: product.categoryID,
                                  categories: this.state.categories,
                                  extras: this.state.extras,
                                  copy: true,
                                  type: ProductType.PRODUCT,
                                });
                              }}
                            >
                              <FaCopy />
                              <span>{Language.getName(Names.Copy)}</span>
                            </div>
                            <div
                              onClick={() => {
                                EventSystem.publish(EventSystem.events.edit_product, {
                                  product,
                                  categoryID: product.categoryID,
                                  categories: this.state.categories,
                                  extras: this.state.extras,
                                  type: ProductType.PRODUCT,
                                });
                              }}
                            >
                              <FaPen />
                              <span>{Language.getName(Names.Modify)}</span>
                            </div>
                            <div onClick={() => this.handleRemoveProduct(product)}>
                              <FaTrashAlt />
                              <span>{Language.getName(Names.Remove)}</span>
                            </div>
                          </Controls>
                        </ProductDiv>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ListWrapper>
            )}
          </Droppable>
        </DragDropContext>
      </Wrapper>
    );
  }

  handleRemoveProduct(product) {
    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {
      },
      () => {
        for (let p of ContextSystem.products) {
          if (p.type !== ProductType.MENU)
            continue;

          for (let mp of p.products) {
            if (mp.id === product.id) {
              EventSystem.publish(EventSystem.events.show_confirmation_modal, {
                title: Language.getName(Names.ProductIsNotRemovable) + "!",
                text: (
                  <>
                    {Language.getName(Names.ProductInsideMenuTextPart1) + " " + TranslatableString.get(
                      mp.name) + " " + Language.getName(Names.ProductInsideMenuTextPart2)}
                  </>
                ),
                yesText: Language.getName(Names.Yes),
              });
              return;
            }
          }
        }
        ProductsAPI.remove(product.id, (res) => {
          if (res.error === 0) {
            toast(Language.getName(Names.Removed));
            this.loadProducts();
          }
        });
      },
    );
  }

  reloadSelectedCategory() {
    let selectedCategory = null;
    if (ContextSystem.categories) {
      if (this.state.selectedCategory == null) {
        for (let category of ContextSystem.categories) {
          if (category.parent === 0) {
            selectedCategory = category;
            break;
          }
        }
      } else {
        selectedCategory = ContextSystem.categories.find(c => c.id === this.state.selectedCategory.id);
      }
    }
    this.setState({ selectedCategory });
  }
}
