import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import { Label } from "./RawEditor";
import { Select } from "./InwardHandlingModal";
import { StorageAPI } from "../../utils/api/StorageAPI";
import { Storage, StorageTypes } from "../../model/Stock";
import type { Option } from "./OrderStateCommentEditor";

export default class StorageEditor extends Component {
  state: {
    showModal: boolean,
    edit: null | Storage,
    selectedType: Option,
    availableTypes: Option[],
    title: string,
    language: number,
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewStorage),
    language: ContextSystem.language,
    selectedType: undefined,
    availableTypes: [],
  };

  save() {
    let storage: Storage = { ...this.state.edit };
    if (!storage.name || storage.name.length < 3) {
      toast(Language.getName(Names.GivenNameTooShort));
      return;
    }

    storage.shopIDs = [];
    storage.shopIDs.push(ContextSystem.selectedShop.id);

    StorageAPI.save(storage, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  edit(storage: Storage) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyStorage),
      edit: {
        ...storage,
      },
    });
  }

  addNew() {
    this.setState({
      showModal: true,
      title: Language.getName(Names.AddNewStorage),
      edit: {
        id: -1,
        enabled: true,
        type: StorageTypes.COUNTER,
        name: "",
        shopIDs: [],
        addedDate: new Date(),
      },
    });
  }

  load() {
    // this.setState({
    //   raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id)
    // });
  }

  componentDidMount() {
    this.load();

    EventSystem.subscribe(EventSystem.events.edit_storage, (storage) => {
      let availableTypes = [];
      for (let storageTypeKey: number in StorageTypes) {
        let option: Option = {
          value: StorageTypes[storageTypeKey],
          label: Storage.getTypeName(StorageTypes[storageTypeKey]),
        };
        availableTypes.push(option);
      }
      let selectedType = availableTypes[0];

      this.setState({
        availableTypes,
        selectedType,
      });

      if (!storage)
        this.addNew();
      else
        this.edit(storage);
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  selectType(t: Option) {
    let edit: Storage = { ...this.state.edit };
    edit.type = t.value;

    this.setState({
      selectedType: t,
      edit,
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.edit &&
            <>
              <Label font_size={"10pt"}>{Language.getName(Names.Type)}</Label>
              <Select
                width={"100%"}
                placeholder={Language.getName(Names.SelectType)}
                value={this.state.selectedType}
                onChange={(e) => this.selectType(e)}
                options={this.state.availableTypes}
                noOptionsMessage={() => Language.getName(Names.NoCategoriesAvailable)}
              />
              <Label font_size={"10pt"}>{Language.getName(Names.Name)}</Label>
              <Input
                value={this.state.edit ? this.state.edit.name : ""}
                type="text"
                placeholder={Language.getName(Names.Name)}
                onChange={(e) =>
                  this.setState({
                    edit: { ...this.state.edit, name: e.target.value },
                  })
                }
                width={"100%"}
              />
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
