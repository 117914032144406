import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { AiOutlineUpload, BsFillPeopleFill, FaPen, FaPlus, FaTrashAlt, RiCoupon2Fill } from "react-icons/all";
import Language, { Names } from "../../utils/Language";
import { Profile } from "../../model/Profile";
import { Controls, Div, HeaderButton, HeaderButtonWrapper } from "./Products";
import { toast } from "react-toastify";
import { Coupon, CouponTypes } from "../../model/Coupon";
import { TranslatableString } from "../../model/Product";
import { CouponsAPI } from "../../utils/api/CouponsAPI";
import ErrorMessage from "../../utils/api/ErrorMessages";
import ConfirmationModal from "../modals/ConfirmationModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ListWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 24px;
  height: 100%;

  overflow-y: scroll;
`;

const CustomerDiv = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px gray;

  min-height: 50px;
  background-color: white;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default class Customers extends Component {
  state: {
    language: number,
    profiles: Profile[],
    coupons: Coupon[],
    selected: number,
  } = {
    language: ContextSystem.language,
    profiles: [],
    coupons: [],
    selected: 0,
  };

  eventIDs = [];

  load() {
    this.setState({
      profiles: ContextSystem.profiles,
      coupons: ContextSystem.coupons.filter(c => c.partnerIDs.includes(ContextSystem.selectedShop?.id)),
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  handlePlusClick() {
    if (this.state.selected === 0) {
      EventSystem.publish(EventSystem.events.add_new_customer, {});
    } else if (this.state.selected === 1) {
      EventSystem.publish(EventSystem.events.add_new_coupon);
    }
  }

  handleImportClick() {
    toast(Language.getName(Names.NotImplementedYet));
  }

  removeCoupon(c: Coupon) {
    ConfirmationModal.showModal(
      Language.getName(Names.Sure) + "!",
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {},
      ()=>{
        CouponsAPI.remove(c.id, res => {
          if (res.error !== ErrorMessage.OK)
            return;
          toast(Language.getName(Names.Removed));
          ContextSystem.removeCoupon(c.id);
        });
      }
    );
  }

  componentDidMount() {
    this.eventIDs = [];
    this.load();

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, profiles, coupons }) => {
      if (language !== undefined)
        this.setState({ language });
      if (profiles !== undefined || coupons !== undefined)
        this.load();
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <Wrapper>
        <HeaderButtonWrapper margin_top={"6px"}>
          <HeaderButton margin_right={"0"}
                        svg_font_size={"18pt"}
                        background_color={this.state.selected === 0 ? "#E6E6E6" : undefined}
                        onClick={() => this.setState({ selected: 0 })}
          >
            <BsFillPeopleFill />
            <span>{Language.getName(Names.Guests)}</span>
          </HeaderButton>
          <HeaderButton margin_left={"0"}
                        svg_font_size={"18pt"}
                        max_width={"80px"}
                        background_color={this.state.selected === 1 ? "#E6E6E6" : undefined}
                        onClick={() => this.setState({ selected: 1 })}
          >
            <RiCoupon2Fill />
            <span>{Language.getName(Names.PriceAdjustments)}</span>
          </HeaderButton>

          <HeaderButton svg_font_size={"18pt"} onClick={() => this.handlePlusClick()}>
            <FaPlus />
            <span>{Language.getName(Names.AddButtonText)}</span>
          </HeaderButton>
          <HeaderButton svg_font_size={"18pt"} onClick={() => this.handleImportClick()}>
            <AiOutlineUpload />
            <span>{Language.getName(Names.Import)}</span>
          </HeaderButton>
        </HeaderButtonWrapper>
        {this.state.selected === 0 &&
          <ListWrapper>
            {this.state.profiles.length <= 0 &&
              <>
                {Language.getName(Names.NoProfilesToShowAddRightTop)}
              </>
            }
            {this.state.profiles.map((profile, i) => {
              return (
                <CustomerDiv key={i}>
                  <Div width={"50px"}>
                    #{profile.id}
                  </Div>
                  <Div width={"20%"}>
                    <b>{Language.getName(Names.Name)}:</b>
                    <br />
                    {profile.firstName} {profile.lastName}
                  </Div>
                  <Div width={"35%"}>
                    <b>{Language.getName(Names.Email)}:</b>
                    <br />
                    {profile.email}
                  </Div>
                  <Div width={"25%"}>
                    <b>{Language.getName(Names.PhoneNumber)}:</b>
                    <br />
                    {profile.tel}
                  </Div>
                  <Controls>
                    {/*<div onClick={() => EventSystem.publish(EventSystem.events.edit_simpleTable, profile)}>*/}
                    {/*  <FaPen/>*/}
                    {/*  <span>{Language.getName(Names.Modify)}</span>*/}
                    {/*</div>*/}
                  </Controls>
                </CustomerDiv>
              );
            })}
          </ListWrapper>
        }
        {this.state.selected === 1 &&
          <ListWrapper>
            {this.state.coupons.length <= 0 &&
              <>
                {Language.getName(Names.NoCouponsToShowAddRightTop)}
              </>
            }
            {this.state.coupons.map((coupon, i) => {
              return (
                <CustomerDiv key={i}>
                  <Div width={"50px"}>
                    #{coupon.id}
                  </Div>
                  <Div width={"20%"}>
                    <b>{Language.getName(Names.Name)}:</b>
                    <br />
                    {TranslatableString.get(coupon.name)}
                  </Div>
                  <Div width={"35%"}>
                    {[CouponTypes.FIX, CouponTypes.PERCENTAGE].includes(coupon.type) &&
                      <>
                        <b>{Language.getName(Names.DiscountValue)}: </b>
                        {(coupon.couponValue)} {coupon.type === CouponTypes.FIX ? "Ft" : "%"}
                      </>
                    }
                    {[CouponTypes.SERVICE].includes(coupon.type) &&
                      <>
                        <b>{Language.getName(Names.ServiceChargeValue)}: </b>
                        {(-coupon.couponValue)} {"%"}
                      </>
                    }
                    <br />
                  </Div>
                  <Div width={"25%"}>
                    {[CouponTypes.FIX, CouponTypes.PERCENTAGE].includes(coupon.type) &&
                      <>
                        <b>{Language.getName(Names.AvailableQty)}:</b>
                        <br />
                        {coupon.qty === Coupon.UNLIMITED
                         ? Language.getName(Names.Unlimited)
                         : coupon.qty + " " + Language.getName(Names.pcs)}
                      </>
                    }
                  </Div>
                  <Controls>
                    <div onClick={() => EventSystem.publish(EventSystem.events.add_new_coupon, coupon)}>
                      <FaPen />
                      <span>{Language.getName(Names.Modify)}</span>
                    </div>
                    <div onClick={() => this.removeCoupon(coupon)}>
                      <FaTrashAlt />
                      <span>{Language.getName(Names.Remove)}</span>
                    </div>
                  </Controls>
                </CustomerDiv>
              );
            })}
          </ListWrapper>
        }
      </Wrapper>
    )
      ;
  }
}
