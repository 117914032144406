import React, { Component } from "react";
import Modal from "./Modal";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Select2 from "react-select";
import { ExtrasAPI } from "../../utils/api/ExtrasAPI";
import { AiFillCaretDown, AiFillCaretUp, FaCopy, FaPencilAlt, FaPlus, FaTimes, FaTrashAlt } from "react-icons/all";
import ContextSystem from "../../utils/ContextSystem";
import { Extra, ExtraGroup, Product, ProductExtra, TranslatableString, Version } from "../../model/Product";
import ConfirmationModal from "./ConfirmationModal";
import Language, { Names } from "../../utils/Language";
import { Button } from "../FormComponents";
import { languages, ReactFlagsSelect } from "./CouponEditor";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ProductEditor from "./ProductEditor";

export const SmallInput = styled.input`
  width: ${({ width }) => (width ? width : "20%")};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "auto")};
  color: ${({ color }) => (color ? color : "auto")};
  padding: 0;
  margin: 0;
  height: fit-content;
  border: none;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(132, 26, 30, 0.25);
    border: rgba(132, 26, 30, 0.25);
  }

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }

  ${({ font_size }) => font_size && css`
    font-size: ${font_size};
  `}
  ${({ border }) => border && css`
    border: ${border};
  `}
  ${({ height }) => height && css`
    height: ${height};
  `}

  ${({ hideSpin }) => hideSpin === true && css`
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `};
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;

  & > svg {
    font-size: 22pt;
    border-radius: 5px;
    padding: 4px;

    &:hover {
      background-color: #ebebeb;
      cursor: pointer;
    }
  }
`;

const ExtraGroupsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraGroupDiv = styled.div`
  width: 98%;
  border-radius: 7px;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  background-color: white;
`;

const ExtraGroupHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #dbdbdb;
  flex-wrap: wrap;

  & > span {
    border-radius: 6px;
    padding: 3px;
    background-color: rgba(150, 150, 150, 0.2);
    margin: 5px 15px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > input {
      background-color: transparent;
      margin: 0 5px;
    }
  }
`;

const EGNameWrapper = styled.div`
  padding: 4px 0 0 4px;
  width: 70%;
  font-size: 12pt;
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > span {
    margin-right: 11px;
  }
`;

const EGControls = styled.div`
  width: 30%;
  padding: 4px 4px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    padding: 4px;
    font-size: 16pt;
    color: #841a1e;

    &:hover, &:active {
      background-color: #ebebeb;
      cursor: pointer;
    }
  }
`;

const ExtrasList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
`;

const SelectedExtra = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding: 0 5px;
  background-color: white;

  & > div:nth-child(1) {
    width: 25%;
  }

  & > div:nth-child(2) {
    width: 20%;
  }

  & > div:nth-child(3) {
    width: 20%;
  }

  ${({ header }) => header && css`
    border: none;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 5px;
  `}
`;

const RemoveSelectedExtraButton = styled.div`
  text-align: right;
  width: fit-content;

  & > svg {
    padding: 4px;
    border-radius: 4px;
    font-size: 22pt;

    &:hover {
      background-color: #ebebeb;
      cursor: pointer;
    }
  }
`;

const Select = styled(Select2)`
  width: 90%;
`;

const VersionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 45px;
  margin-bottom: 10px;
`;

const VersionEditor = styled.div`
  display: none;

  padding: 10px;
  color: black;
  position: absolute;
  top: 50px;
  left: 10px;
  width: 300px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: white;
  box-shadow: 0 0 5px 1px #232323;
  border-radius: 8px;

  & > span {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > svg {
      padding: 3px;
      text-align: right;
      font-size: 20pt;

      &:hover, &:active {
        cursor: pointer;
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px;
  }

  ${({ show }) => !!show && css`
    display: flex;
  `};

  &:hover, &:active {
    cursor: default;
  }
`;

const VersionDiv = styled.div`
  width: ${({ versionsNumber }) => "calc((100% - 65px) / " + versionsNumber + ")"};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: white;
  color: #121212;
  border: 1px solid #ebebeb;

  ${({ selected }) => selected && css`
    background-color: #841a1e;
    color: white;
    font-weight: bold;
  `};

  &:hover, &:active {
    background-color: #971f23;
    color: white;
    cursor: pointer;
  }

  & > svg {
    color: white;
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: transparent;
    border-radius: 4px;
    padding: 7px;
    font-size: 22pt;
    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

    &:hover {
      color: #121212;
      background-color: white;
    }
  }
`;
const AddVersionButton = styled.div`
  width: 65px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 16pt;

  &:hover, &:active {
    background-color: #ebebeb;
    cursor: pointer;
  }
`;
const AddExtraGroupButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px 0;
  margin: 5px 0 0 0;

  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 16pt;

  &:hover, &:active {
    background-color: #ebebeb;
    cursor: pointer;
  }
`;

const CopyButton = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: rgb(208, 208, 208);
  color: rgb(40, 40, 40);
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: background-color 200ms ease-in-out;

  & > span {
    margin-right: 5px;
  }

  & > svg {
    //color: white;
    padding: 3px;
    font-size: 16pt;
  }

  &:hover {
    background-color: rgb(225, 225, 225);
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: rgb(180, 180, 180);
  }
`;

const CopyWrapperPos = styled.div`
  position: relative;
  overflow: visible;
`;

const CopyWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 12px 12px 12px;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px black;
  width: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 22;

  & > svg:nth-of-type(1) {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16pt;
    cursor: pointer;
  }
`;

type DroppableResult = { droppableId: any, index: any };

export default class ExtrasSelector extends Component {
  static defaultVersion: Version = {
    id: -1,
    enabled: true,
    productID: 1,
    name: "v",
    price: 0,
    partnerID: 1,
  };

  state: {
    showModal: boolean,
    extras: Extra[],
    title: string,
    product: Product,
    showNewExtras: { egid: number, show: boolean }[],
    selectedVersion: number,
    shownVersionIDEditor: number,
    versions: Version[],
    extraGroups: ExtraGroup[],
    products: Product[],
    productOptions: { value: any, label: string }[],
    copyOpened: boolean,
    language: number,
    extrasOptions: { value: number, label: string }[],
    selectedLanguage: number,
  } = {
    showModal: false,
    extras: [],
    title: Language.getName(Names.ExtraSelectorTitle),
    product: undefined,
    showNewExtras: [],
    selectedVersion: 1,
    shownVersionIDEditor: 0,
    versions: [],
    extraGroups: [],
    products: [],
    copyOpened: false,
    language: ContextSystem.language,
    extrasOptions: [],
    selectedLanguage: languages[0].id,
  };

  save() {
    for (let extraGroup of this.state.extraGroups) {
      extraGroup.minQty = parseInt(extraGroup.minQty);
      extraGroup.maxQty = parseInt(extraGroup.maxQty);
      extraGroup.freeQty = parseInt(extraGroup.freeQty);
      extraGroup.saleID = parseInt(extraGroup.saleID);
    }
    for (let version of this.state.versions) {
      version.price = parseFloat(version.price);
    }

    EventSystem.publish(EventSystem.events.extras_selected, {
      extraGroups: this.state.extraGroups,
      versions: this.state.versions,
    });
    this.setState({ showModal: false, showNewExtras: [] });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.select_extras, (ev) => {
      let extras: Extra[] = ev.extras;
      let extraGroups: ExtraGroup[] = ev.extraGroups.sort((eg1, eg2) => eg1.orderPlace - eg2.orderPlace);
      let versions: Version[] = ev.versions;
      let product: Product = ev.product;

      if (!extras)
        return;

      if (!extraGroups)
        extraGroups = [];
      if (!versions || versions.length <= 0) {
        versions = [{ ...ExtrasSelector.defaultVersion }];
        versions[0].name = TranslatableString.create2(Names.DefaultVersion);
      }

      let extrasOptions = this.getExtraOptions(extras);

      let showNewExtras: { egid: number, show: boolean }[] = [];
      for (let eg of extraGroups)
        showNewExtras.push({ egid: eg.id, show: false });

      let products: Product[] = [];
      ContextSystem.products.forEach(p => p.partnerID === ContextSystem.selectedShop.id && products.push(p));

      let productOptions: { value: number, label: string }[] = [];
      for (let p: Product of products) {
        if (p.id === product.id || (p.extraGroups.length <= 0 && p.versions.length <= 1))
          continue;

        productOptions.push({
          value: p.id,
          label: TranslatableString.get(p.name) + " (" + p.price + " Ft)",
        });
      }

      this.setState({
        versions,
        extraGroups,
        extras,
        showNewExtras,
        product,
        selectedVersion: versions[0].id,
        shownVersionIDEditor: 0,
        showModal: true,
        extrasOptions,
        products,
        productOptions,
      });
    });

    EventSystem.subscribe(EventSystem.events.extras_changed, () => {
      if (!this.state.showModal)
        return;
      ExtrasAPI.getExtras(res => {
        if (res.error !== 0)
          return;
        let extras = res.extras;
        let extrasOptions = this.getExtraOptions(extras);
        this.setState({ extras, extrasOptions });
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });
  }

  getExtraById(extraID): Extra {
    if (!this.state.extras)
      return null;
    for (let extra of this.state.extras) {
      if (extra.id === extraID)
        return extra;
    }
    return null;
  }

  changeMaxExtraQty(extraGroup, extra, maxQty) {
    if (isNaN(maxQty)) maxQty = "";
    else {
      if (maxQty <= 0) {
        toast(Language.getName(Names.MaxEGQtyBelowZero));
        return;
      }
    }
    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === extraGroup.id) {
        for (let ge of newEG.groupExtras) {
          if (ge.extraID === extra.id) {
            ge.maxQty = maxQty;
          }
        }
      }
      extraGroups.push(newEG);
    }
    this.setState({ extraGroups });
  }

  removeSelectedExtra(extraGroupID, extraID) {
    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === extraGroupID) {
        let groupExtras = [];
        for (let ge of newEG.groupExtras) {
          if (ge.extraID !== extraID) groupExtras.push(ge);
        }
        newEG.groupExtras = groupExtras;
      }
      extraGroups.push(newEG);
    }
    this.setState({ extraGroups });
  }

  // noinspection JSUnusedLocalSymbols
  async addExtra(extraGroupID, { value: extraID, label }): ProductExtra {
    if (extraID === -1) {
      EventSystem.publish(EventSystem.events.edit_extra);
      return;
    }
    let extra: Extra = this.getExtraById(extraID);
    if (!extra)
      return;

    let newProductExtra: ProductExtra = {
      id: -1,
      enabled: true,
      extraID: extra.id,
      maxQty: 1,
      partnerID: 2,
      orderPlace: -1,
      extraGroupID: 1,
      shownName: extra.name,
      productID: this.state.product.id,
      recipes: [],
      qty: 1,
    };

    let extraGroups: ExtraGroup[] = [];
    for (let eg: ExtraGroup of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === extraGroupID) {
        let maxOrderPlace = 0;
        let minID = -1;
        for (let ge of newEG.groupExtras) {
          if (ge.extraID === extraID) {
            toast(Language.getName(Names.ExtraIsAlreadyInList));
            return;
          }
          if (maxOrderPlace < ge.orderPlace)
            maxOrderPlace = ge.orderPlace;
          if (ge.id <= minID)
            minID = ge.id - 1;
        }
        newProductExtra.orderPlace = maxOrderPlace + 1;
        newProductExtra.id = minID;
        newEG.groupExtras.push(newProductExtra);
      }
      extraGroups.push(newEG);
    }
    return await new Promise((resolve) => this.setState({ extraGroups }, () => resolve(newProductExtra)));
  }

  changeEGName(egID: number, name: string, languageID: number) {
    let extraGroups = [];
    let language = languages.find(l => l.id === languageID);
    let languageCode: string = language.languageCode;

    for (let eg of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === egID)
        newEG.name = TranslatableString.modify(newEG.name, name, languageCode);
      extraGroups.push(newEG);
    }
    this.setState({ extraGroups });
  }

  changeEGMinQty(egID, minQty) {
    if (isNaN(minQty))
      minQty = 0;
    else {
      if (minQty < 0) {
        toast(Language.getName(Names.MinEGQtyBelowZero));
        return;
      }
    }

    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === egID)
        newEG.minQty = minQty;
      extraGroups.push(newEG);
    }
    this.setState({ extraGroups });
  }

  changeEGMaxQty(egID, maxQty) {
    if (isNaN(maxQty))
      maxQty = 0;
    else {
      if (maxQty < 0) {
        toast(Language.getName(Names.MaxEGQtyBelowZero));
        return;
      }
    }

    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === egID) newEG.maxQty = maxQty;
      extraGroups.push(newEG);
    }
    this.setState({ extraGroups });
  }

  changeEGFreeQty(egID, freeQty) {
    if (isNaN(freeQty))
      freeQty = 0;
    else {
      if (freeQty < 0) {
        toast(Language.getName(Names.FreeEGQtyBelowZero));
        return;
      }
    }

    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      let newEG = { ...eg };
      if (eg.id === egID) newEG.freeQty = freeQty;
      extraGroups.push(newEG);
    }
    this.setState({ extraGroups });
  }

  removeExtraGroup(egID) {
    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      if (eg.id !== egID) extraGroups.push({ ...eg });
    }
    this.setState({ extraGroups });
  }

  changeShownName(exGr: ExtraGroup, extra: Extra, name: string, languageID: number) {
    let language = languages.find(l => l.id === languageID);
    let languageCode: string = language.languageCode;

    let extraGroups = [];
    for (let eg of this.state.extraGroups) {
      if (eg.id === exGr.id) {
        let newEG = { ...eg };
        for (let ge of newEG.groupExtras) {
          if (ge.extraID === extra.id) {
            ge.shownName = TranslatableString.modify(ge.shownName, name, languageCode);
          }
        }
        extraGroups.push(newEG);
      } else {
        extraGroups.push({ ...eg });
      }
    }
    this.setState({ extraGroups });
  }

  async addVersion(stopAtMaxLimit: boolean = true): Version {
    if (stopAtMaxLimit && this.state.versions.length >= 5) {
      toast(Language.getName(Names.Max5ProductVersion));
      return;
    }
    let minV = 0;
    let versions = [];
    for (let version of this.state.versions) {
      versions.push({ ...version });
      if (version.id < minV)
        minV = version.id;
    }
    let newID = minV - 1;
    let newV = { ...ExtrasSelector.defaultVersion };
    newV.id = newID;
    newV.name = TranslatableString.create("");
    versions.push(newV);

    return await new Promise((resolve) => this.setState({ versions }, () => resolve(newV)));
  }

  selectVersion(versionID) {
    this.setState({ selectedVersion: versionID });
  }

  async addExtraGroup(versionID: number): ExtraGroup {
    let extraGroups = [];
    let minID = -1;
    let orderPlace = 1;
    for (let extraGroup of this.state.extraGroups) {
      extraGroups.push({ ...extraGroup });
      if (extraGroup.id < minID)
        minID = extraGroup.id;
      if (extraGroup.orderPlace >= orderPlace)
        orderPlace = extraGroup.orderPlace + 1;
    }
    let newID = minID > 0 ? -1 : minID - 1;

    let newExtraGroup: ExtraGroup = {
      id: newID,
      enabled: true,
      name: TranslatableString.create(""),
      groupExtras: [],
      versionID,
      productID: -1,
      partnerID: -1,
      minQty: 0,
      maxQty: 0,
      freeQty: 0,
      saleID: -1,
      minPrice: 0,
      orderPlace,
    };

    extraGroups.push(newExtraGroup);

    let showNewExtras = [];
    for (let showNewExtra of this.state.showNewExtras) {
      showNewExtras.push({ ...showNewExtra });
    }
    showNewExtras.push({ egid: newID, show: false });

    return await new Promise((resolve) => this.setState({ extraGroups, showNewExtras }, () => resolve(newExtraGroup)));
  }

  removeVersion(versionID: number) {
    if (this.state.versions.length <= 1) {
      toast(Language.getName(Names.YouNeedAtLeastOneProductVersion));
      return;
    }
    let versions = [];
    for (let version of this.state.versions) {
      let newV = { ...version };
      if (newV.id !== versionID) versions.push(newV);
    }

    let extraGroups = [];
    for (let extraGroup of this.state.extraGroups) {
      if (extraGroup.versionID !== versionID) extraGroups.push({ ...extraGroup });
    }
    this.setState({ extraGroups, versions, shownVersionIDEditor: 0 });
    if (this.state.selectedVersion === versionID) this.setState({ selectedVersion: versions[0].id });
  }

  selectVersionToEdit(versionID) {
    if (versionID !== 0) this.selectVersion(versionID);
    this.setState({ shownVersionIDEditor: versionID });
  }

  changeVersionName(versionID: number, name: string, languageID: string) {
    let language = languages.find(l => l.id === languageID);
    let languageCode: string = language.languageCode;

    let versions = [];
    for (let version of this.state.versions) {
      let newV = { ...version };
      if (newV.id === versionID)
        newV.name = TranslatableString.modify(newV.name, name, languageCode);
      versions.push(newV);
    }
    this.setState({ versions });
  }

  /**
   *
   * @param versionID
   * @param newPrice - price of product with this version, not the difference (difference = version.price)
   */
  changeVersionPrice(versionID: number, newPrice: number) {
    newPrice = parseFloat(ProductEditor.parsePrice(newPrice));
    if (isNaN(newPrice))
      newPrice = "";
    else {
      if (newPrice < 0) {
        toast(Language.getName(Names.ProductVersionPriceNonNegative));
        return;
      }
    }

    let versions = [];
    for (let version of this.state.versions) {
      let newV = { ...version };
      if (newV.id === versionID)
        newV.price = newPrice - this.state.product.price;
      versions.push(newV);
    }
    this.setState({ versions });
  }

  setShowNewExtras(extraGroupID) {
    let showNewExtras = [];
    for (let s of this.state.showNewExtras) {
      if (s.egid === extraGroupID) {
        let newS = { ...s };
        newS.show = true;
        showNewExtras.push(newS);
      } else {
        showNewExtras.push({ ...s });
      }
    }
    this.setState({ showNewExtras });
  }

  showNewExtras(extraGroupID: number) {
    for (let s of this.state.showNewExtras) {
      if (s.egid === extraGroupID) {
        return s.show;
      }
    }
    return false;
  }

  changeOrderPlaceOfSelectedExtra(egID: number, geID: number, diff: number) {
    let copyAllExtraGroups = [];
    for (let eg: ExtraGroup of this.state.extraGroups) {
      let copyEg: ExtraGroup = { ...eg };
      if (copyEg.id === egID) {
        let index: number = copyEg.groupExtras.findIndex(ge => ge.id === geID);
        if (index + diff >= 0 && index + diff < copyEg.groupExtras.length) {
          let copy = copyEg.groupExtras[index];
          copyEg.groupExtras.splice(index, 1);
          copyEg.groupExtras.splice(index + diff, 0, copy);
          let orderPlace = 0;
          for (let ge of copyEg.groupExtras)
            ge.orderPlace = orderPlace++;
        }
      }
      copyAllExtraGroups.push(copyEg);
    }

    this.setState({ extraGroups: copyAllExtraGroups });
  }

  onDragEnd(res: { destination: DroppableResult, source: DroppableResult }) {
    const { destination, source }: { destination: DroppableResult, source: DroppableResult } = res;
    if (!destination)
      return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    if (destination.droppableId !== source.droppableId)
      return;

    let copyAllExtraGroups = [];
    for (let eg: ExtraGroup of this.state.extraGroups) {
      copyAllExtraGroups.push({ ...eg });
    }

    let prodSource = copyAllExtraGroups[source.index];

    copyAllExtraGroups.splice(source.index, 1);
    copyAllExtraGroups.splice(destination.index, 0, prodSource);

    let k = 0;
    for (let eg of copyAllExtraGroups) {
      if (eg.versionID === this.state.selectedVersion)
        eg.orderPlace = k++;
    }

    copyAllExtraGroups = copyAllExtraGroups.sort((eg1, eg2) => eg1.orderPlace - eg2.orderPlace);

    this.setState({ extraGroups: copyAllExtraGroups });
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    return (
      <Modal
        show={this.state.showModal}
        size={"lg"}
        onEscapeKeyDown={() => this.setState({ showModal: false })}
        onClick={() => this.selectVersionToEdit(0)}
      >
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          {!this.state.copyOpened &&
            <CopyButton onClick={() => this.handleCopyClick()}>
              <span>{Language.getName(Names.CopyFromOtherProduct)}</span>
              <FaCopy />
            </CopyButton>
          }
          {this.state.copyOpened &&
            <CopyWrapperPos>
              <CopyWrapper>
                <FaTimes onClick={() => this.handleCopyCloseClick()} />
                <Select
                  placeholder={Language.getName(Names.SearchForProduct)}
                  onChange={(e) => this.selectProductToCopy(e)}
                  options={this.state.productOptions}
                  noOptionsMessage={() => {
                    Language.getName(Names.NoProductToShow);
                  }}
                />
              </CopyWrapper>
            </CopyWrapperPos>
          }
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id })}
          />
        </Modal.Header>
        {this.state.showModal &&
          <Modal.Body>
            <VersionsWrapper>
              {this.state.versions.map((v, i) => {
                return (
                  <VersionDiv
                    key={i}
                    selected={v.id === this.state.selectedVersion}
                    versionsNumber={this.state.versions.length}
                    onClick={() => this.selectVersion(v.id)}
                  >
                    {(v?.name?.translation?.find(
                      tr => tr.languageCode === languageCode)?.value ?? "")} ({this.state.product.price + v.price}Ft)
                    <FaPencilAlt onClick={(e) => {
                      e.stopPropagation();
                      this.selectVersionToEdit(v.id);
                    }}
                    />
                    <VersionEditor show={this.state.shownVersionIDEditor === v.id} onClick={(e) => e.stopPropagation()}>
                    <span>
                      <FaTrashAlt onClick={() => this.removeVersion(v.id)} />
                      <FaTimes onClick={() => this.selectVersionToEdit(0)} />
                    </span>
                      <div>
                        <span>{Language.getName(Names.VersionName)}:</span>
                        <SmallInput
                          value={v?.name?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
                          type="text"
                          height={"40px"}
                          placeholder={Language.getName(Names.VersionName) + "..."}
                          border={"1px solid #ebebeb"}
                          onChange={(ev) => this.changeVersionName(v.id, ev.target.value, this.state.selectedLanguage)}
                          width={"auto"}
                        />
                      </div>
                      <div>
                        <span>{Language.getName(Names.Price)}:</span>
                        <SmallInput
                          value={(v.price + this.state.product.price)}
                          type="text"
                          placeholder={Language.getName(Names.Price) + "..."}
                          height={"40px"}
                          border={"1px solid #ebebeb"}
                          onChange={(ev) => this.changeVersionPrice(v.id, ev.target.value)}
                          width={"auto"}
                        />
                      </div>
                    </VersionEditor>
                  </VersionDiv>
                );
              })}
              <AddVersionButton onClick={() => this.addVersion()}>
                <FaPlus />
              </AddVersionButton>
            </VersionsWrapper>
            <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
              <Droppable droppableId={"0"}>
                {provided => (
                  <ExtraGroupsList ref={provided.innerRef} {...provided.droppableProps}>
                    {this.state.extraGroups.map((eg, i) => {
                      if (eg.versionID !== this.state.selectedVersion)
                        return <React.Fragment key={i} />;

                      let groupExtras: ProductExtra[] = eg.groupExtras.sort(
                        (ge1, ge2) => ge1.orderPlace - ge2.orderPlace);

                      return (
                        <Draggable draggableId={i + "v"} index={i} key={i}>
                          {provided => (
                            <ExtraGroupDiv {...provided.draggableProps} {...provided.dragHandleProps}
                                           ref={provided.innerRef}>
                              <ExtraGroupHeader>
                                <EGNameWrapper>
                                  <span>{Language.getName(Names.ToppingGroupName)}: </span>
                                  <SmallInput
                                    value={eg?.name?.translation?.find(
                                      tr => tr.languageCode === languageCode)?.value ?? ""}
                                    type="text"
                                    placeholder={Language.getName(Names.EnterAGroupName)}
                                    border={"none"}
                                    onChange={(ev) => this.changeEGName(eg.id, ev.target.value,
                                      this.state.selectedLanguage,
                                    )}
                                    width={"auto"}
                                  />
                                </EGNameWrapper>
                                <EGControls>
                                  <FaTrashAlt onClick={() => this.removeExtraGroup(eg.id)} />
                                </EGControls>
                                <span>
                                {Language.getName(Names.Min)}:
                                <SmallInput
                                  value={eg.minQty}
                                  type="text"
                                  placeholder={Language.getName(Names.Min) + "..."}
                                  border={"none"}
                                  onChange={(ev) => this.changeEGMinQty(eg.id, parseInt(ev.target.value))}
                                  width={"30px"}
                                />
                                db
                                </span>
                                <span>
                                {Language.getName(Names.Max)}:
                                <SmallInput
                                  value={eg.maxQty}
                                  type="text"
                                  placeholder={Language.getName(Names.Max) + "..."}
                                  border={"none"}
                                  onChange={(ev) => this.changeEGMaxQty(eg.id, parseInt(ev.target.value))}
                                  width={"30px"}
                                />
                                db
                                </span>
                                <span>
                                {Language.getName(Names.BasePriceContains)}:
                                <SmallInput
                                  value={eg.freeQty}
                                  type="text"
                                  placeholder="3..."
                                  border={"none"}
                                  onChange={(ev) => this.changeEGFreeQty(eg.id, parseInt(ev.target.value))}
                                  width={"30px"}
                                />
                                  {Language.getName(Names.PCS)}
                                </span>
                              </ExtraGroupHeader>
                              <ExtrasList ref={provided.innerRef} {...provided.droppableProps}>
                                <SelectedExtra header={true}>
                                  <div>
                                    <b>{Language.getName(Names.ToppingName)}</b>
                                  </div>
                                  <div>
                                    <b>{Language.getName(Names.Price)}</b>
                                  </div>
                                  <div>
                                    <b>{Language.getName(Names.MaxQuantity)}</b>
                                  </div>
                                  <div>
                                    <b>{Language.getName(Names.ShowingName)}</b>
                                  </div>
                                </SelectedExtra>
                                {groupExtras.map((e, k) => {
                                  let extra: Extra = this.getExtraById(e.extraID);
                                  if (extra == null)
                                    return <React.Fragment key={k} />;

                                  return (
                                    <SelectedExtra key={k}>
                                      <div>{TranslatableString.get(extra.name)}</div>
                                      <div>{extra.price}Ft</div>
                                      <SmallInput
                                        value={e.maxQty}
                                        type="text"
                                        placeholder={Language.getName(Names.Quantity)}
                                        onChange={(ev) => this.changeMaxExtraQty(eg, extra, parseInt(ev.target.value))}
                                        width={"10%"}
                                      />
                                      <SmallInput
                                        value={e.shownName?.translation?.find(
                                          tr => tr.languageCode === languageCode)?.value ?? ""}
                                        type="text"
                                        placeholder={Language.getName(Names.ShowingName)}
                                        onChange={(ev) => this.changeShownName(eg, extra, ev.target.value,
                                          this.state.selectedLanguage,
                                        )}
                                        width={"19%"}
                                      />
                                      <RemoveSelectedExtraButton>
                                        <FaTrashAlt onClick={() => this.removeSelectedExtra(eg.id, extra.id)} />
                                        <AiFillCaretDown
                                          onClick={() => this.changeOrderPlaceOfSelectedExtra(eg.id, e.id, 1)} />
                                        <AiFillCaretUp
                                          onClick={() => this.changeOrderPlaceOfSelectedExtra(eg.id, e.id, -1)} />
                                      </RemoveSelectedExtraButton>
                                    </SelectedExtra>
                                  );
                                })}
                              </ExtrasList>
                              <SelectWrapper>
                                {!this.showNewExtras(eg.id) &&
                                  <FaPlus onClick={() => this.setShowNewExtras(eg.id)}
                                  />
                                }
                                {this.showNewExtras(eg.id) && <label>{Language.getName(Names.AddTopping)}</label>}
                                {this.showNewExtras(eg.id) && (
                                  <Select
                                    placeholder={Language.getName(Names.SearchToppings)}
                                    onChange={(e) => this.addExtra(eg.id, e)}
                                    options={this.state.extrasOptions}
                                    noOptionsMessage={() => Language.getName(Names.NoToppingsToShow)}
                                  />
                                )}
                              </SelectWrapper>
                            </ExtraGroupDiv>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ExtraGroupsList>
                )}
              </Droppable>
            </DragDropContext>
            <AddExtraGroupButton onClick={() => this.addExtraGroup(this.state.selectedVersion)}>
              <FaPlus />
            </AddExtraGroupButton>
          </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  getExtraOptions(extras: Extra[]) {
    let extrasOptions = [];
    extrasOptions.push({ value: -1, label: Language.getName(Names.AddNewTopping3Dots) });
    for (let extra: Extra of extras) {
      extrasOptions.push({ value: extra.id, label: TranslatableString.get(extra.name) });
    }
    return extrasOptions;
  }

  handleCopyClick() {
    this.setState({ copyOpened: true });
  }

  handleCopyCloseClick() {
    this.setState({ copyOpened: false });
  }

  selectProductToCopy(e: { value: number, label: string }) {
    let productID = e.value;
    let product: Product = this.state.products.find(p => p.id === productID);

    if (!product)
      return;

    let productExtraCount: number = 0;
    product.extraGroups.forEach(eg => productExtraCount += eg.groupExtras.length);
    let message = (
      <div>
        {Language.getName(Names.CopyConfirmTextPart1)}
        {TranslatableString.get(product.name)}
        {Language.getName(Names.CopyConfirmTextPart2)}
        <b>
          {product.versions.length}{Language.getName(Names.PCS) + " "}
          {Language.getName(Names.CopyConfirmTextPart3)}
        </b>
        {" " + Language.getName(Names.And) + " "}
        <b>
          {product.extraGroups.length + Language.getName(Names.PCS) + Language.getName(
            Names.CopyConfirmTextPart4) + "(" + productExtraCount + Language.getName(Names.PCS) + Language.getName(
            Names.CopyConfirmTextPart5) + ")"}
        </b>
        {Language.getName(Names.CopyConfirmTextPart6)}<br />
        <b>{Language.getName(Names.CopyConfirmTextPart7)}:</b> {Language.getName(Names.CopyConfirmTextPart8)}
      </div>
    );

    if (product.versions.length <= 1 && product.extraGroups.length <= 0) {
      toast(Language.getName(Names.NoExtrasOnSelectedProduct));
      return;
    }

    if (this.state.extraGroups.length <= 0 && this.state.versions.length <= 1) {
      // noinspection JSIgnoredPromiseFromCall
      this.copyFrom(product);
      this.handleCopyCloseClick();
      return;
    }

    ConfirmationModal.showModal(
      Language.getName(Names.ShallWeContinue),
      message,
      Language.getName(Names.Copy),
      Language.getName(Names.CancelButtonText),
      undefined,
      () => {
        // noinspection JSIgnoredPromiseFromCall
        this.copyFrom(product);
        this.handleCopyCloseClick();
      },
    );
  }

  async copyFrom(product: Product) {
    if (product.versions.length <= 1 && product.extraGroups.length <= 0)
      return;

    let removeVersions: Version[] = this.state.versions;

    for (let v of product.versions) {
      let newVersion: Version = await this.addVersion(false);
      if (!newVersion)
        continue;

      languages.forEach(l => {
        let nameValue: string = v.name.translation.find(tr => tr.languageCode === l.languageCode)?.value ?? "";
        if (!nameValue)
          nameValue = v.name.translation.find(tr => tr.value.length > 0)?.value ?? "";

        this.changeVersionName(newVersion.id, nameValue, l.id);
      });

      this.changeVersionPrice(newVersion.id, v.price + product.price);
      for (const eg of product.extraGroups) {
        if (eg.versionID !== v.id)
          continue;

        let newExtraGroup: ExtraGroup = await this.addExtraGroup(newVersion.id);

        this.changeEGFreeQty(newExtraGroup.id, eg.freeQty);
        this.changeEGMaxQty(newExtraGroup.id, eg.maxQty);
        this.changeEGMinQty(newExtraGroup.id, eg.minQty);

        languages.forEach(l => {
          let nameValue: string = eg.name.translation.find(tr => tr.languageCode === l.languageCode)?.value ?? "";
          if (!nameValue)
            nameValue = eg.name.translation.find(tr => tr.value.length > 0)?.value ?? "";

          this.changeEGName(newExtraGroup.id, nameValue, l.id);
        });

        for (const ge of eg.groupExtras) {
          let extra: Extra = this.getExtraById(ge.extraID);
          await this.addExtra(newExtraGroup.id, { value: ge.extraID, label: TranslatableString.get(ge.shownName) });
          languages.forEach(l => {
            let nameValue: string = ge.shownName.translation.find(
              tr => tr.languageCode === l.languageCode)?.value ?? "";
            if (!nameValue)
              nameValue = ge.shownName.translation.find(tr => tr.value.length > 0)?.value ?? "";

            this.changeShownName(newExtraGroup, extra, nameValue, l.id);
          });
          this.changeMaxExtraQty(newExtraGroup, extra, ge.maxQty);
        }
      }
    }

    removeVersions.forEach(v => this.removeVersion(v.id));
  }
}
