import APICaller, { APIMsgBase } from "../APICaller";
import { Storage } from "../../model/Stock";

function save(storage: Storage, cb: (res: APIMsgBase)=>{}) {
  let storage2: Storage = { ...storage };
  storage2.addedDate = storage2.addedDate.getTime();
  APICaller.ownFetch(false, "POST", "/storage", { ...storage2 }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(storageID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/storage", { id: storageID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type GetStoragesResponseType = APIMsgBase & { storages: Storage[] };

function getStorages(cb: (res: GetStoragesResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/storages", {}, (res) => {
    let result: GetStoragesResponseType = JSON.parse(res);
    if (result.error === 0)
      result.storages.forEach(r => r.addedDate = new Date(r.addedDate));
    if (cb)
      cb(result);
  });
}

export const StorageAPI = {
  getStorages,
  save,
  remove,
};
