import AbstractModel from "./AbstractModel";
import { TranslatableString } from "./Product";

export class Point {
  latitude: number;
  longitude: number;
}

export class Municipality extends AbstractModel {
  polyID: number;
  countyID: number;
  name: TranslatableString;
}

export class ZipCode extends AbstractModel {
  cityID: number;
  zipcode: string;
}

export class County extends AbstractModel {
  polyID: number;
  name: TranslatableString;
}

export class CountryLocale extends AbstractModel {
  code: string;
  currencyID: number;
  defaultLanguageID: number;
  mapFilters: string[];
}

export const CountryLocales: { [keys: string]: Country } = {
  HU: {
    id: 1,
    code: "hu",
    currencyID: 1,
    defaultLanguageID: 1,
    mapFilters: ["magyarorszag", "magyarország", "hungary", "hu"],
  },
  DK: {
    id: 2,
    code: "dk",
    currencyID: 3,
    defaultLanguageID: 2,
    mapFilters: ["denmark", "danmark", "dánia", "dkk"],
  },
  AE: {
    id: 3,
    code: "ae",
    currencyID: 4,
    defaultLanguageID: 1,
    mapFilters: ["united arab emirates", "uae", "ae", "emirates", "arab emirates"],
  },
};

export class City extends AbstractModel {
  name: TranslatableString;
  municipalityID: number;
  countyID: number;
  polygonIDs: number[];
  center: Point;

  //non-persistent
  isBudapest: boolean;
}

export class Address extends AbstractModel {
  comment: string;
  street: string;
  countyID: number;
  municipalityID: number;
  cityID: number;
  zipCodeID: number;
  coordinates: Point;
  googlePlaceID: string;
  profileID: number;
  type: AddressType;
}

export const AddressTypes: { [keys: string]: number } = {
  HOME: 1,
  WORK: 2,
  OTHER: 3,
  TEMP: 4,
};

export type AddressType = $Values<typeof AddressTypes>;

export class GeoMultiPolygon extends AbstractModel {
  allCoordinates: Point[];
  allHoles: Point[];

  polyPointLengths: number[];
  holePointLengths: number[];

  coordinates: Point[][];
  holes: Point[][];
  latitudeMin: number;
  latitudeMax: number;
  longitudeMin: number;
  longitudeMax: number;
}
