import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import styled from "styled-components";
import { Element, ElementTypes } from "../../model/BluePrint";
import Language, { Names } from "../../utils/Language";
import TablesLayout from "./TablesLayout";
import { Button, Select, Textarea } from "../FormComponents";
import { TableReservation } from "../../model/Order";
import CustomerSelector from "../CustomerSelector";
import { Profile } from "../../model/Profile";
import DatePicker, { DateObject } from "react-multi-date-picker";
import * as HoursCalc from "../../utils/HoursCalc";
import { addLeadingZero, arrayRotate, getShortDayName } from "../../utils/HoursCalc";
import { HourStatuses, HourTypes } from "../../model/Hour";
import { Shop } from "../../model/Shop";
import { CategoryDiv } from "../home/StandingComponent";
import { ReservationBookedType, TableReservationAPI } from "../../utils/api/TableReservationAPI";
import ErrorMessage from "../../utils/api/ErrorMessages";
import Orders from "../home/Orders";

const CoverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 450px;
`;

const CoverBtn = styled(CategoryDiv)`
  padding: 9px;
  width: fit-content;
  margin: 3px;
  min-width: 35px;
  min-height: 35px;
  font-size: 9pt;
  box-shadow: 0 0 2px 0 #444440;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 30vh;

  flex-grow: 2;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TopWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TablesWrapper = styled.div`
  width: 65%;
  height: 100%;
`;

const DetailsWrapper = styled.div`
  width: 35%;
  height: 100%;
`;

const ReservationsScroll = styled.div`
  width: 90%;
  display: block;
  height: 50vh;
  overflow-y: auto;
`;

const ReservationCard = styled.div`
  margin: 6px;
  padding: 9px 6px;
  background-color: #efefef;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  margin: 3px 0 6px 0;
  font-weight: bold;
  font-size: 11pt;
  width: ${({ width }) => width ?? "auto"};
`;

const TopPanelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TimingWrapper = styled.div`
  width: 150px;
  margin: 0 24px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const NoTables = styled.h3`
  font-weight: normal;
  font-family: Arial, sans-serif;
  font-size: 12pt;
  text-align: center;
  width: 100%;
  padding: 24px;
`;

export type DataSetRecord = {
  id: number,
  value: Date,
  label: string,
  minutes: Date[],
}

const PluginSelect = styled(Select)`
  height: 15px;
  background-color: transparent;
  outline: none;
  border: none;
  margin: 0 3px;
  padding: 2px 3px;

  & > option {
    font-size: 11pt;
    //background-color: #444440;
    //color: white;
  }

  color: var(--blundee_turkiz);
  font-size: 13pt;
  font-weight: bold;

  & > option:focus, & > option:active, & > option:checked {
    background-color: var(--blundee_input_bg_3) !important;
    background: var(--blundee_input_bg_3) linear-gradient(0deg, var(--blundee_input_bg_3) 0%, var(--blundee_input_bg_3) 100%) !important;
    transition: background-color 200ms ease-in-out !important;
  }

  width: 100%;

`;

const HourSelectWrapper = styled.div`
  margin-top: 20px;
  width: 100px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > span {
    width: 100%;
  }
`;

export default class NewReservationEditor extends Component {
  state: {
    language: number,
    showModal: boolean,
    tables: Element[],
    tableSelected: Element,
    tableReservations: TableReservation[],
    selectedProfile: Profile,
    selectedDay: Date,
    arrival: Date,
    hoursDataSet: DataSetRecord,
    shop: Shop,
    cover: number,
    stay: number,
    dateTime: Date,
    showCount: number,
    note: string,
  } = {
    language: ContextSystem.language,
    showModal: false,
    tables: [],
    tableSelected: undefined,
    tableReservations: [],
    selectedProfile: undefined,
    selectedDay: new Date(),
    arrival: new Date(),
    hoursDataSet: undefined,
    shop: ContextSystem.selectedShop,
    cover: 2,
    stay: 60,
    dateTime: new Date(),
    showCount: 1,
    note: "",
  };

  loadElements() {
    this.setState({
      shop: ContextSystem.selectedShop,
      tables: ContextSystem.elements.filter(
        e => e.enabled && e.partnerID === ContextSystem.selectedShop.id && e.type === ElementTypes.TABLE),
      tableReservations: ContextSystem.tableReservations.filter(
        r => r.enabled && r.partnerID === ContextSystem.selectedShop.id),
    });

    this.buildDataSet();
  }

  timeout;

  loadTime() {
    this.timeout = setTimeout(() => {
      this.setState({ dateTime: new Date() });
      this.loadTime();
      if (this.state.showModal) {
        let useNowDate: boolean = this.state.arrival === this.nowDateUsed;
        this.buildDataSet();
        if (useNowDate)
          this.setState({ arrival: this.nowDateUsed });
      }
    }, (60 * 1000) - (new Date().getSeconds() * 1000 + new Date().getMilliseconds()));
  }

  componentDidMount() {
    this.loadElements();
    this.loadTime();

    this.buildDataSet();

    EventSystem.subscribe(EventSystem.events.open_new_reservation_editor, ({ tableSelected }) => {
      this.setState({
        stay: 60,
        showModal: true,
        tableSelected,
        cover: 2,
        note: "",
        showCount: this.state.showCount + 1,
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ selectedShop, language, elements, tableReservations }) => {
        if (language !== undefined)
          this.setState({ language });
        if (selectedShop !== undefined || elements !== undefined || tableReservations !== undefined)
          this.loadElements();
      },
    );
  }

  selectTable(t: Element) {
    this.setState({
      tableSelected: t,
    });
  }

  save() {
    TableReservationAPI.addReservation(
      this.state.arrival, this.state.stay, this.state.cover, this.state.note, this.state.tableSelected?.id,
      this.state.selectedProfile?.id ?? -1,
      (res: ReservationBookedType) => {
        if (res.error !== ErrorMessage.OK)
          return;

        //everything else handled in the websocket push noti
        this.setState({
          showModal: false,
          tableSelected: undefined,
          selectedProfile: undefined,
          selectedDay: new Date(),
          arrival: new Date(),
          cover: 2,
          stay: 60,
          dateTime: new Date(),
          note: "",
        });
      },
    );
  }

  handleProfileSelected(id: number) {
    let selectedProfile: Profile = ContextSystem.profiles.find(p => p.id === id);
    this.setState({ selectedProfile });
  }

  handleArrivalChange(arrival: Date) {
    this.setState({ arrival });
  }

  nowDateUsed: Date = undefined;

  buildDataSet() {
    if (!this.state.shop)
      return;

    let hoursDataSet: DataSetRecord = [];

    let date: Date = this.state.selectedDay;

    let k = 0;
    let minutes: Date[] = [];
    let arrival = this.state.arrival;

    let nowDate: Date = new Date();
    nowDate.setSeconds(0, 0);
    this.nowDateUsed = nowDate;

    if (//is same day and is "now" is a valid choice
      nowDate.toLocaleDateString() === date.toLocaleDateString()
      && HoursCalc.getOpeningState(this.state.shop.hours[HourTypes.OPENING], nowDate).status === HourStatuses.OPEN
    ) {
      minutes.push(nowDate);
    }

    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), h, m, 0, 0);
        if (date < new Date()) {
          continue;
        }

        if (HoursCalc.getOpeningState(this.state.shop.hours[HourTypes.OPENING], date).status !== HourStatuses.OPEN) {
          continue;
        }

        if (date !== nowDate)
          minutes.push(new Date(date.getTime()));

        if (!arrival)
          arrival = new Date(date.getTime());

        if (arrival === this.state.arrival && arrival?.getHours() === h && arrival?.getMinutes() === m) {
          arrival = new Date(date.getTime());
        }
      }
    }

    if (minutes && minutes.length > 0) {
      hoursDataSet = {
        label:
          addLeadingZero(date.getMonth() + 1) + "." + addLeadingZero(date.getUTCDate()) + " "
          + "(" + getShortDayName(date.getDay() === 0 ? 7 : date.getDay()) + ")",
        value: new Date(date.getTime()),
        minutes,
        id: k++,
      };
    }

    if (arrival === this.state.arrival)
      arrival = minutes[0];

    this.setState({
      hoursDataSet,
      arrival,
    });
  }

  filterTableReservation(tr: TableReservation) {
    if (!tr || TableReservation.isClosed(tr))
      return false;

    if (!this.state.dateTime)
      return false;
    let stayMinutes: number = this.state.stay;
    if (!stayMinutes || stayMinutes <= 0)
      stayMinutes = 60;

    let startTime: Date = new Date(this.state.arrival);
    let endTime: Date = new Date(this.state.arrival).addMinutes(stayMinutes);

    let tStart = TableReservation.getStart(tr);
    let tEnd = TableReservation.getEnd(tr);

    return (tStart >= startTime && tStart <= endTime)
      || (tEnd > startTime && tEnd < endTime)
      || (tStart <= startTime && tEnd > endTime);

  }

  render() {
    // noinspection JSUnresolvedFunction
    return (
      <Modal size={"xl"} show={this.state.showModal}
             onEscapeKeyDown={() => this.setState({ order: undefined, showModal: false })}
      >
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.AddReservation)}</Modal.Title>
        </Modal.Header>
        <Modal.Body padding={"6px"}>
          <TopWrapper>
            <TopPanelWrapper>
              <TimingWrapper>
                <Label>{Language.getName(Names.Arrival)}</Label>
                <DatePicker
                  value={this.state.arrival}
                  onChange={(d: DateObject) => {
                    this.setState({ selectedDay: d?.toDate() ?? this.state.selectedDay }, () => {
                      this.buildDataSet();
                      let dateMin: Date = d?.toDate() ?? this.state.selectedDay;
                      dateMin.setHours(0, 0, 0, 0);
                      let dateMax: Date = d?.toDate() ?? this.state.selectedDay;
                      dateMax.setHours(23, 59, 59, 999);
                      ContextSystem.loadReservations(dateMin, dateMax);
                    });
                  }}
                  disableYearPicker={true}
                  minDate={new Date()}
                  maxDate={new Date().setDate(new Date().getDate() + 61)}
                  weekStartDayIndex={1}
                  format="MM.DD. HH:mm"
                  weekDays={arrayRotate(Language.getName(Names.DayNamesShort), true)}
                  months={(Language.getName(Names.MonthNames)).map(s => [s, s])}
                  inputClass={"input_range_asdasd"}
                  plugins={[
                    <HourSelectWrapper position={"right"}>
                      <span>{Language.getName(Names.Arrival)}</span>
                      <PluginSelect
                        onChange={e => this.handleArrivalChange(new Date(e.target.value))}
                        value={this.state.arrival}
                        id={"schdp-t-"}
                      >
                        {this.state.hoursDataSet?.minutes?.map((time, i) => {
                          return (
                            <option key={i} value={time}>
                              {addLeadingZero(time.getHours()) + ":" + addLeadingZero(time.getMinutes())}
                            </option>
                          );
                        })}
                      </PluginSelect>
                    </HourSelectWrapper>,
                  ]}
                />
              </TimingWrapper>
              <TimingWrapper>
                <Label>{Language.getName(Names.Period)}</Label>
                <Select
                  onChange={e => this.setState({ stay: parseInt(e.target.value) })}
                  value={this.state.stay}
                >
                  <option value={30}>{Language.getName(Names._30min)}</option>
                  <option value={60}>{Language.getName(Names._1h)}</option>
                  <option value={90}>{Language.getName(Names._1_5h)}</option>
                  <option value={120}>{Language.getName(Names._2h)}</option>
                  <option value={180}>{Language.getName(Names._3h)}</option>
                </Select>
              </TimingWrapper>
              <CoverWrapper>
                <Label width={"450px"}>{Language.getName(Names.Cover)}</Label>
                {Array(20).fill("").map((k, i) => {
                  return (
                    <CoverBtn key={i} onClick={() => this.setState({ cover: i + 1 })}
                              selected={this.state.cover === i + 1}>
                      {i + 1}
                    </CoverBtn>
                  );
                })}
              </CoverWrapper>
            </TopPanelWrapper>
            <Wrapper>
              <DetailsWrapper>
                <Label>{Language.getName(Names.ReservationsOnTable)}</Label>
                <ReservationsScroll>
                  {!this.state.tableSelected &&
                    <ReservationCard>
                      {Language.getName(Names.SelectTable)}
                    </ReservationCard>
                  }
                  {this.state.tableReservations.filter(tr => tr.tableID === this.state.tableSelected?.id
                    && (
                      TableReservation.getStart(tr).toLocaleDateString() === this.state.selectedDay.toLocaleDateString()
                      || TableReservation.getEnd(tr).toLocaleDateString() === this.state.selectedDay.toLocaleDateString()
                    ))
                    .sort((tr1, tr2) => Orders.sortByDate(TableReservation.getStart(tr1), TableReservation.getStart(tr2)))
                    .map((tr, i) => {
                      let profile = tr.profileID ? ContextSystem.profiles.find(p => p.id === tr.profileID) : undefined;
                      return (
                        <ReservationCard key={i}>
                          {profile &&
                            <>
                              {profile.firstName + " " + profile.lastName}<br />
                            </>
                          }
                          {TableReservation.getStart(tr).toShortTimeFormat()} - {TableReservation.getEnd(tr).toShortTimeFormat()}
                        </ReservationCard>
                      );
                    })}
                </ReservationsScroll>
              </DetailsWrapper>
              <TablesWrapper>
                <Label>{Language.getName(Names.Table)}</Label>
                <TablesLayout
                  onTableSelected={(table: Element) => this.selectTable(table)}
                  filterTable={() => true}
                  filterTableReservation={tr => this.filterTableReservation(tr)}
                  selectedTable={this.state.tableSelected}
                  emptyNote={<NoTables>{Language.getName(Names.NoTablesToSelectFrom)}</NoTables>}
                  useFixDate={this.state.arrival}
                  onZoneSelected={undefined}
                  parent={"reservationEditor"}
                />
              </TablesWrapper>
              <DetailsWrapper>
                <Label>{Language.getName(Names.Guest)}</Label>
                <CustomerSelector key={this.state.showCount}
                                  onProfileSelected={(id) => this.handleProfileSelected(id)} />
                <Label>{Language.getName(Names.Note)}</Label>
                <Textarea
                  value={this.state.note}
                  onChange={e => this.setState({ note: e.target.value })}
                />
              </DetailsWrapper>
            </Wrapper>
          </TopWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          <Button onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
