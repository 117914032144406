import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import { Raw, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import type { QtyType } from "../../model/Stock";
import { Qty, QtyTypes, Storage } from "../../model/Stock";
import { Label } from "./ContractsDetailsModal";
import styled, { css } from "styled-components";
import Select2 from "react-select";
import { RawAPI } from "../../utils/api/RawAPI";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/all";
import type { Option } from "./OrderStateCommentEditor";

export type OptionValue = { label: string, value: number };

export const Select = styled(Select2)`
  width: ${({ width }) => width ? width : "300px"};
`;

export const CellInput = styled(Input)`
  margin: 0;
  padding: 3px 6px;
  height: fit-content;
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const RawUnit = styled.span`
  margin-left: 4px;
  text-align: right;
`;

export const RawDiv = styled.div`
  width: 100%;
  display: flex;
`;
export const IngredientsDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TableCell = styled.div`
  width: ${({ width }) => width ?? "fit-content"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justify }) => justify ?? "center"};
  padding: 3px;
  text-align: center;

  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;

  ${({ grow }) => grow !== undefined && css`
    flex-grow: ${grow};
  `};

  ${({ shrink }) => shrink !== undefined && css`
    flex-shrink: ${shrink};
  `};

  ${({ c_cursor }) => c_cursor === true && css`
    cursor: pointer;
  `};

  ${({ bold }) => bold === true && css`
    font-weight: bold;
  `};

  ${({ color }) => color !== undefined && css`
    color: ${color};
  `};
`;

export const TableRow = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 20px;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  font-size: 10pt;
  ${({ header }) => header === true && css`
    background-color: #444440;
    color: white;
  `}
`;

export default class InwardHandlingModal extends Component {
  state: {
    showModal: boolean,
    title: string,
    language: number,
    raws: Raw[],
    qtyList: Qty[],
    storages: Storage[],
  } = {
    showModal: false,
    title: Language.getName(Names.InwardHandling),
    language: ContextSystem.language,
    raws: [],
    qtyList: [],
    storages: [],
  };

  save() {
    RawAPI.handleInward(this.state.qtyList, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.start_inward_handling, () => {
      this.setState({
        qtyList: [],
        showModal: true,
      });
    });

    this.loadContext();

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, raws, storages }) => {
      if (language !== undefined)
        this.setState({ language });
      if (raws !== undefined || storages !== undefined)
        this.loadContext();
    });
  }

  loadContext() {
    this.setState({
      raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      storages: ContextSystem.storages.filter(r => r.enabled && r.shopIDs.includes(ContextSystem.selectedShop?.id)),
    });
  }

  addNewQty(rawID: number) {
    if (!rawID)
      return;
    let raw: Raw = this.state.raws.find(r => r.id === rawID);
    if (!raw || raw.semiProduct === true)
      return;

    if (this.state.qtyList.find(q => q.modelID === rawID))
      return;

    let qtyList: Qty[] = this.state.qtyList.map(q => ({ ...q }));
    qtyList.push({
      id: -1,
      enabled: true,
      qty: 0,
      modelID: raw.id,
      price: 0,
      type: QtyTypes.RAW,
      reduction: [],
      profileID: raw.partnerID,
      addedDate: new Date(),
      supplyPrice: 0,
      storageID: -1,
    });
    this.setState({ qtyList });
  }

  changeQty(modelID: number, type: QtyType, qty: string, storageID: number = -1) {
    if (qty < 0)
      qty = 0;
    let qtyList: Qty[] = this.state.qtyList.map(qt => qt.modelID === modelID && qt.storageID === storageID ? {
      ...qt,
      qty: parseFloat(qty),
    } : { ...qt });

    if (qtyList.find(qt => qt.modelID === modelID && qt.storageID === storageID) === undefined)
      qtyList.push({
        id: -1,
        enabled: true,
        qty: parseFloat(qty),
        modelID: modelID,
        price: 0,
        type: QtyTypes.RAW,
        reduction: [],
        profileID: ContextSystem.selectedShop?.id,
        addedDate: new Date(),
        supplyPrice: 0,
        storageID: storageID,
      });

    this.setState({ qtyList });
  }

  changePrice(q: Qty, price: string) {
    if (price < 0)
      price = 0;
    let qtyList: Qty[] = this.state.qtyList.map(qt => qt.modelID === q.modelID ? {
      ...qt,
      price: parseFloat(price),
    } : { ...qt });
    this.setState({ qtyList });
  }

  removeQty(q: Qty) {
    let qtyList: Qty[] = this.state.qtyList.filter(qt => qt.modelID !== q.modelID);
    this.setState({ qtyList });
  }

  render() {
    let sum: number = 0;
    this.state.qtyList.forEach(q => sum += q.qty * q.price);

    let ingredientRows: Raw[] = [];
    for (let qty of this.state.qtyList) {
      if (ingredientRows.find(r => r.id === qty.modelID) === undefined) {
        ingredientRows.push({ ...this.state.raws.find(r => r.id === qty.modelID) });
      }
    }

    let options: Option[] = [];

    this.state.raws.forEach(r => {
      if (r.semiProduct !== true)
        options.push({ value: r.id, label: TranslatableString.get(r.name) });
    });

    return (
      <Modal size={"xl"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Label font_size={"10pt"}>{Language.getName(Names.PleaseEnterYourInwardHandling)}</Label>
          <Table>
            <TableRow header={true}>
              <TableCell width={"40px"}>#</TableCell>
              <TableCell grow={2} width={"20%"}>{Language.getName(Names.Name)}</TableCell>
              {this.state.storages.length > 0 &&
                <>
                  {this.state.storages.map((s, i) => (
                    <TableCell key={i} width={"100px"}>{Language.getName(Names.Quantity)} ({s.name})</TableCell>
                  ))}
                </>
              }
              {this.state.storages.length <= 0 &&
                <TableCell width={"100px"}>{Language.getName(Names.Quantity)}</TableCell>
              }
              <TableCell width={"90px"}>{Language.getName(Names.Price)}</TableCell>
              <TableCell width={"150px"}>{Language.getName(Names.Total)}</TableCell>
              <TableCell c_cursor={true} width={"30px"} />
            </TableRow>
            {ingredientRows.map((raw, i) => {
                let qtys: Qty[] = this.state.qtyList.filter(q => q.modelID === raw.id);
                let q: Qty = qtys[0];
                let sumTotal: number = 0;
                for (let qty of qtys) {
                  sumTotal += qty.price * qty.qty;
                }

                if (!raw)
                  return <React.Fragment key={i} />;

                return (
                  <TableRow key={i}>
                    <TableCell width={"40px"}>#{i + 1}</TableCell>
                    <TableCell grow={2} width={"20%"}>{TranslatableString.get(raw.name)}</TableCell>
                    {this.state.storages.length > 0 &&
                      <>
                        {this.state.storages.map((s, i) => {
                          let q: Qty = qtys.find(q => q.storageID === s.id);

                          return (
                            <TableCell key={i} width={"100px"}>
                              <CellInput
                                min={0}
                                pattern={"[0-9]+([\\.,][0-9]+)?"}
                                value={q?.qty ?? ""}
                                type="number"
                                step={0.01}
                                placeholder={Language.getName(Names.Quantity)}
                                onChange={(e) => this.changeQty(raw.id, QtyTypes.RAW, e.target.value, s.id)}
                                width={"100%"}
                              />
                              <RawUnit>
                                {TranslatableString.get(raw.unit)}
                              </RawUnit>
                            </TableCell>
                          );
                        })}
                      </>
                    }
                    {this.state.storages.length <= 0 &&
                      (() => {
                        let q: Qty = qtys.find(q => q.storageID === -1);

                        return (
                          <TableCell width={"100px"}>
                            <CellInput
                              min={0}
                              pattern={"[0-9]+([\\.,][0-9]+)?"}
                              value={q.qty ?? ""}
                              type="number"
                              step={0.01}
                              placeholder={Language.getName(Names.Quantity)}
                              onChange={(e) => this.changeQty(raw.id, QtyTypes.RAW, e.target.value, -1)}
                              width={"100%"}
                            />
                            <RawUnit>
                              {TranslatableString.get(raw.unit)}
                            </RawUnit>
                          </TableCell>
                        );
                      })()
                    }
                    <TableCell width={"90px"}>
                      <CellInput
                        min={0}
                        pattern={"[0-9]+([\\.,][0-9]+)?"}
                        type="number"
                        step={0.01}
                        value={q.price ?? ""}
                        placeholder={Language.getName(Names.Price)}
                        onChange={(e) => this.changePrice(q, e.target.value)}
                        width={"100%"}
                      />
                    </TableCell>
                    <TableCell width={"150px"}>{sumTotal.toLocaleString()} Ft</TableCell>
                    <TableCell width={"30px"}>
                      <FaTrash onClick={() => this.removeQty(q)} />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
            <TableRow>
              <TableCell width={"100%"}>
                <Select
                  options={options}
                  placeholder={Language.getName(Names.NewRaw)}
                  noOptionsMessage={() => {
                    Language.getName(Names.NoRawMaterialsToShow);
                  }}
                  onChange={(e) => this.addNewQty(e.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={"100%"} justify={"flex-end"}>
                <b style={{ "marginRight": "3px" }}>
                  {Language.getName(Names.Total)}:
                </b>
                {" " + sum.toLocaleString() + " Ft"}
              </TableCell>
            </TableRow>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
