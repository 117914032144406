import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import { Product, Raw, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import type { QtyType } from "../../model/Stock";
import { Qty, QtyChange, QtyChangeTypes, QtyTypes, Storage } from "../../model/Stock";
import { Label } from "./ContractsDetailsModal";
import { RawAPI } from "../../utils/api/RawAPI";
import { toast } from "react-toastify";
import { CellInput, RawUnit, Table, TableCell, TableRow } from "./InwardHandlingModal";
import { FaTrash } from "react-icons/all";

export default class StockTakingModal extends Component {
  state: {
    showModal: boolean,
    title: string,
    language: number,
    raws: ({ qtyType: QtyType } & Raw)[],
    qtyList: Qty[],
    stock: Qty[],
    storages: Storage[],
    products: ({ qtyType: QtyType } & Product)[],
  } = {
    showModal: false,
    title: Language.getName(Names.StockTaking),
    language: ContextSystem.language,
    raws: [],
    qtyList: [],
    stock: [],
    storages: [],
    products: [],
  };

  save() {
    let qtyChanges: QtyChange[] = [];
    this.state.qtyList.forEach(q => {
      let sum: number = Qty.countSum(this.state.stock, q.type, q.modelID, q.storageID);
      if (sum === q.qty)
        return;

      qtyChanges.push({
        id: -1,
        enabled: true,
        changeQty: q.qty,
        profileID: ContextSystem.selectedShop?.id,
        modelID: q.modelID,
        qtyID: -1,
        changeGroupID: -1,
        date: new Date(),
        type: q.type,
        changeType: QtyChangeTypes.INVENTORY_DIFFERENCE,
        _storageID: q.storageID,
      });
    });

    if (qtyChanges.length <= 0) {
      toast(Language.getName(Names.EmptyList));
      return;
    }

    RawAPI.stockTaking(qtyChanges, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  loadContext() {
    this.setState({
      raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      stock: ContextSystem.qtyList.filter(q => q.enabled && q.profileID === ContextSystem.selectedShop?.id),
      storages: ContextSystem.storages.filter(r => r.enabled && r.shopIDs.includes(ContextSystem.selectedShop?.id)),
      products: ContextSystem.products.filter(q => q.enabled && q.partnerID === ContextSystem.selectedShop?.id),
    });
  }

  componentDidMount() {
    this.loadContext();

    EventSystem.subscribe(EventSystem.events.start_stock_taking, () => {
      let qtyList: Qty[] = [];

      this.state.raws.forEach(r => {
        if (!r.enabled)
          return;
        qtyList.push({
          id: -1,
          enabled: true,
          qty: Qty.countSum(this.state.stock, QtyTypes.RAW, r.id, -1),
          modelID: r.id,
          price: 0,
          type: QtyTypes.RAW,
          reduction: [],
          profileID: r.partnerID,
          addedDate: new Date(),
          supplyPrice: 0,
          storageID: -1,
        });
        ContextSystem.storages.forEach(st => {
          qtyList.push({
            id: -1,
            enabled: true,
            qty: Qty.countSum(this.state.stock, QtyTypes.RAW, r.id, st.id),
            modelID: r.id,
            price: 0,
            type: QtyTypes.RAW,
            reduction: [],
            profileID: r.partnerID,
            addedDate: new Date(),
            supplyPrice: 0,
            storageID: st.id,
          });
        });
      });

      this.state.products.forEach(pr => {
        if (!pr.enabled || !pr.countStock)
          return;

        qtyList.push({
          id: -1,
          enabled: true,
          qty: Qty.countSum(this.state.stock, QtyTypes.PRODUCT, pr.id, -1),
          modelID: pr.id,
          price: 0,
          type: QtyTypes.PRODUCT,
          reduction: [],
          profileID: pr.partnerID,
          addedDate: new Date(),
          supplyPrice: 0,
          storageID: -1,
        });
        ContextSystem.storages.forEach(st => {
          qtyList.push({
            id: -1,
            enabled: true,
            qty: Qty.countSum(this.state.stock, QtyTypes.PRODUCT, pr.id, st.id),
            modelID: pr.id,
            price: 0,
            type: QtyTypes.PRODUCT,
            reduction: [],
            profileID: pr.partnerID,
            addedDate: new Date(),
            supplyPrice: 0,
            storageID: st.id,
          });
        });
      });

      this.setState({
        qtyList,
        showModal: true,
      });
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, raws, qtyList, products }) => {
      if (language !== undefined)
        this.setState({ language });
      if (raws !== undefined || products !== undefined || qtyList !== undefined)
        this.loadContext();
    });
  }

  removeQty(q: Qty) {
    if (!q)
      return;

    let qtyList: Qty[] = this.state.qtyList.filter(qt => qt.modelID !== q.modelID);
    this.setState({ qtyList });
  }

  changeQty(modelID: number, type: QtyType, qty: string, storageID: number = -1) {
    if (qty < 0)
      qty = 0;
    let qtyList: Qty[] = this.state.qtyList.map(qt => qt.modelID === modelID && qt.storageID === storageID ? {
      ...qt,
      qty: parseFloat(qty),
    } : { ...qt });

    if (qtyList.find(qt => qt.modelID === modelID && qt.storageID === storageID) === undefined)
      qtyList.push({
        id: -1,
        enabled: true,
        qty: parseFloat(qty),
        modelID: modelID,
        price: 0,
        type: QtyTypes.RAW,
        reduction: [],
        profileID: ContextSystem.selectedShop?.id,
        addedDate: new Date(),
        supplyPrice: 0,
        storageID: storageID,
      });

    this.setState({ qtyList });
  }

  render() {
    let stackables: ({ qtyType: QtyType } & (Raw | Product))[] = [];

    this.state.raws.forEach(r => {
      r.qtyType = QtyTypes.RAW;
      if (!r.enabled || this.state.qtyList.find(q => q.modelID === r.id) === undefined)
        return;

      stackables.push(r);
    });

    this.state.products.forEach(p => {
      p.qtyType = QtyTypes.PRODUCT;
      if (p.enabled && p.countStock && this.state.qtyList.find(q => q.modelID === p.id) !== undefined)
        stackables.push(p);
    });

    return (
      <Modal size={"xl"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Label font_size={"10pt"}>{Language.getName(Names.PleaseEnterYourInwardHandling)}</Label><br />
          <Table>
            <TableRow header={true}>
              <TableCell width={"40px"}>#</TableCell>
              <TableCell grow={2} width={"20%"}>{Language.getName(Names.Name)}</TableCell>
              <TableCell width={"100px"}>
                {Language.getName(Names.Stock)} ({Language.getName(Names.DefaultStorage)})
              </TableCell>
              <TableCell width={"100px"}>{Language.getName(Names.CountedStock)}</TableCell>
              {this.state.storages.length > 0 &&
                <>
                  {this.state.storages.map((s, i) => (
                    <React.Fragment key={i}>
                      <TableCell width={"100px"}>{Language.getName(Names.Stock)} ({s.name})</TableCell>
                      <TableCell width={"100px"}>{Language.getName(Names.CountedStock)} ({s.name})</TableCell>
                    </React.Fragment>
                  ))}
                </>
              }
              <TableCell c_cursor={true} width={"30px"} />
            </TableRow>
            {stackables.map((st, i) => {
                let qtys: Qty[] = this.state.qtyList.filter(q => q.modelID === st.id && q.type === st.qtyType);
                let qDefault: Qty = qtys.find(q => q.storageID === -1);
                let sumTotal: number = 0;
                for (let qty of qtys) {
                  sumTotal += qty.price * qty.qty;
                }

                if (!st)
                  return <React.Fragment key={i} />;

                let name = TranslatableString.get(st.name);
                let unit = st.qtyType === QtyTypes.RAW ? TranslatableString.get(st.unit) : Language.getName(Names.pcs);

                let originalSum = Qty.countSum(this.state.stock, st.qtyType, st.id, -1);
                let color = "auto";
                if (qDefault?.qty < originalSum)
                  color = "red";
                else if (qDefault?.qty > originalSum)
                  color = "green";

                return (
                  <TableRow key={i}>
                    <TableCell width={"40px"}>#{i + 1}</TableCell>
                    <TableCell grow={2} width={"20%"}>{name}</TableCell>
                    <TableCell width={"100px"} color={color} bold={color !== "auto"}>
                      {originalSum} {unit}
                    </TableCell>
                    <TableCell width={"100px"}>
                      <CellInput
                        min={0}
                        pattern={"[0-9]+([\\.,][0-9]+)?"}
                        value={qDefault?.qty ?? ""}
                        type="number"
                        step={0.01}
                        placeholder={Language.getName(Names.Quantity)}
                        onChange={(e) => this.changeQty(st.id, st.qtyType, e.target.value, -1)}
                        width={"100%"}
                      />
                      <RawUnit>
                        {unit}
                      </RawUnit>
                    </TableCell>
                    {this.state.storages.length > 0 &&
                      <>
                        {this.state.storages.map((s, i) => {
                          let q: Qty = qtys.find(q => q.storageID === s.id);
                          let originalSum = Qty.countSum(this.state.stock, st.qtyType, st.id, s.id);
                          let color = "auto";
                          if (q?.qty < originalSum)
                            color = "red";
                          else if (q?.qty > originalSum)
                            color = "green";

                          return (
                            <React.Fragment key={i}>
                              <TableCell width={"100px"} color={color} bold={color !== "auto"}>
                                {originalSum} {unit}
                              </TableCell>
                              <TableCell width={"100px"}>
                                <CellInput
                                  min={0}
                                  pattern={"[0-9]+([\\.,][0-9]+)?"}
                                  value={q?.qty ?? ""}
                                  type="number"
                                  step={0.01}
                                  placeholder={Language.getName(Names.Quantity)}
                                  onChange={(e) => this.changeQty(st.id, st.qtyType, e.target.value, s.id)}
                                  width={"100%"}
                                />
                                <RawUnit>
                                  {unit}
                                </RawUnit>
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                      </>
                    }
                    <TableCell width={"30px"}>
                      <FaTrash onClick={() => this.removeQty(qtys[0])} />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
