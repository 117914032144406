import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
  display: block;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  margin-bottom: 20pt;
  min-height: 25pt;
  border: solid 1px #ccc;
  border-radius: 3px;
  padding: 14px 0 14px 6px;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 14pt;
    padding: 12px 0 12px 4px;
  }
`;

export const Button = styled.button`
  display: ${({ inline }) => inline ? "inline-block" : "block"};
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "white")};
  color: ${({ color }) => (color ? color : "#3b6499")};
  border: 1px solid ${({ border_color }) => (border_color ? border_color : "#3b6499")};
  border-radius: 5px;
  padding: ${({ padding }) => (padding ?? "12px 24px")};
  outline: none;
  margin: ${({ margin }) => (margin ?? "0 auto")};

  font-size: 11pt;
  font-family: "Raleway Black", sans-serif;
  text-align: center;
  transition: height 0.2s ease-in-out, background-color 200ms ease-in-out, color 200ms ease-in-out;
  cursor: pointer;

  &:hover, &:active {
    outline: none;
    background-color: #4a7dbf;
    color: white;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

export const ButtonFilled = styled(Button)`
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "#3b6499")};
  color: ${({ color }) => (color ? color : "#ffffff")};
  
  &:hover, &:active {
    background-color: #4a7dbf;
    color: white;
  }
`;

export const Input = styled.input`
  width: ${({ width }) => (width ? width : "70%")};
  height: ${({ height }) => (height ? height : "70%")};
  padding: ${({ padding }) => (padding ? padding : "5px 10px")};
  margin: ${({ margin }) => margin ?? "10px 0"};
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }
`;

export const InputArea = styled.textarea`
  width: ${({ width }) => (width ? width : "70%")};
  padding: 5px 10px;
  margin: ${({ margin }) => margin ?? "10px 0"};
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  resize: vertical;

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }
`;
export const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 100px;
`;
