import CrossEventType from "./CrossEventType";
import EventSystem from "../EventSystem";
import { AuthAPI } from "../api/AuthAPI";
import ContextSystem from "../ContextSystem";
import { Order, TableReservation } from "../../model/Order";
import { Category, Extra, Product, ProductView, Raw, Recipe, VATValue } from "../../model/Product";
import { City, ZipCode } from "../../model/Address";
import { Shop } from "../../model/Shop";
import { Qty, SavedStorageMovement, Storage } from "../../model/Stock";
import { Element, Zone } from "../../model/BluePrint";
import { TableReservationAPI } from "../api/TableReservationAPI";
import { OrdersAPI } from "../api/OrdersAPI";
import { Coupon } from "../../model/Coupon";
import { Printer } from "../../model/Printer";
import type { RestaurantSetting } from "../api/ProfileAPI";
import { ShiftControl, ShiftControlExtraParameter } from "../../model/ShiftControl";
import { UpsellRule } from "../../model/UpsellRule";
import { CouponsAPI } from "../api/CouponsAPI";
import { UpsellRulesAPI } from "../api/UpsellRulesAPI";

export type CrossEventDataBase = { type: number };
type HandlerType = (msg: CrossEventDataBase, type: number) => void;

type PartnerMessage = CrossEventDataBase & { partner: Shop };
type LoggedOutEventData = CrossEventDataBase & { id: number };
type ProductChangedEventData = CrossEventDataBase & { product: Product | null, removeID: number };
type ExtraChangedEventData = CrossEventDataBase & { extra: Extra | null, removeID: number };
type ElementChangedEventData = CrossEventDataBase & { element: Element | null, removeID: number };
type ZoneChangedEventData = CrossEventDataBase & { zone: Zone | null, removeID: number };
type VATValuesChangedEventData = CrossEventDataBase & { vatValues: VATValue[] | null, shopID: number };
type StorageChangedEventData = CrossEventDataBase & { storage: Storage | null, removeID: number };
type SavedStorageMovementChangedEventData =
  CrossEventDataBase
  & { savedStorageMovement: SavedStorageMovement | null, removeID: number };
type CategoryChangedEventData =
  CrossEventDataBase
  & { category: Category, productViews: ProductView[], removeID: number };
type ShiftControlChangedEventData = CrossEventDataBase & { shiftControl: ShiftControl, removeID: number };
type StocksChangedEventData = CrossEventDataBase & { qtyList: Qty[] };
type RecipeChangedEventData = CrossEventDataBase & { recipe: Recipe, removeID: number };
type RawChangedEventData = CrossEventDataBase & { raw: Raw, removeID: number };
type ReservationChangedEventData = CrossEventDataBase & { reservation: TableReservation, removeID: number };
type OrdersChangedEventData = CrossEventDataBase
  & { orders: Order[], products: Product[], cities: City[], zipCodes: ZipCode[] };
type CouponChangedEventData = CrossEventDataBase & { coupon: Coupon, removeID: number };
type ShiftControlParameterChangedEventData = CrossEventDataBase & { scep: ShiftControlExtraParameter, removeID: number };
type UpsellRuleChangedEventData = CrossEventDataBase & { upsellRule: UpsellRule, removeID: number };
type PrintersChangedEventData = CrossEventDataBase & { printers: Printer[] };
type SettingChangedEventData = CrossEventDataBase & { settingsType: RestaurantSetting, enabled: boolean };

// noinspection JSUnusedLocalSymbols
const HandleOrderChanged: HandlerType = (msg: OrdersChangedEventData, type: number) => {
  msg.orders.forEach(o => OrdersAPI.fixDates(o));
  ContextSystem.mergeOrders(msg.orders, msg.products, msg.cities, msg.zipCodes);
};

// noinspection JSUnusedLocalSymbols
const HandleStockChanged: HandlerType = (msg: StocksChangedEventData, type: number) => {
  msg.qtyList.forEach(q => {
    q.addedDate = new Date(q.addedDate);
    q.reduction.forEach(qr => qr.date = new Date(qr.date));
  });
  ContextSystem.mergeQtyList(msg.qtyList);
};

// noinspection JSUnusedLocalSymbols
const HandleRecipeChanged: HandlerType = (msg: RecipeChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeRecipe(msg.removeID);
  if (msg.recipe) {
    msg.recipe.addedDate = new Date(msg.recipe.addedDate);
    ContextSystem.mergeRecipes([msg.recipe]);
  }

  if (msg.recipe && msg.removeID > 0) {
    ContextSystem.recipeChanged(msg.recipe, msg.removeID);
  }
};

// noinspection JSUnusedLocalSymbols
const HandleRawChanged: HandlerType = (msg: RawChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeRaw(msg.removeID);
  if (msg.raw)
    ContextSystem.mergeRaws([msg.raw]);

  if (msg.raw && msg.removeID)
    ContextSystem.rawChanged(msg.raw, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleCouponChanged: HandlerType = (msg: CouponChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeCoupon(msg.removeID);
  if (msg.coupon) {
    let coupons: Coupon[] = [msg.coupon];
    CouponsAPI.fixDates(coupons);
    ContextSystem.mergeCoupons(coupons);
  }
  // Nothing to update
  // if (msg.coupon && msg.removeID)
  //   ContextSystem.couponChanged(msg.coupon, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleShiftControlParameterChanged: HandlerType = (msg: ShiftControlParameterChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeShiftControlExtraParameter(msg.removeID);
  if (msg.scep) {
    let sceps: ShiftControlExtraParameter[] = [msg.scep];
    ContextSystem.mergeShiftControlParameters(sceps);
  }
  // Nothing to update
  // if (msg.coupon && msg.removeID)
  //   ContextSystem.couponChanged(msg.coupon, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleUpsellRuleChanged: HandlerType = (msg: UpsellRuleChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeUpsellRule(msg.removeID);
  if (msg.upsellRule) {
    let upsellRules: UpsellRule[] = [msg.upsellRule];
    UpsellRulesAPI.fixDates(upsellRules);
    ContextSystem.mergeUpsellRules(upsellRules);
  }

  // Nothing to update
  // if (msg.coupon && msg.removeID)
  //   ContextSystem.couponChanged(msg.coupon, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandlePrintersChanged: HandlerType = (msg: PrintersChangedEventData, type: number) => {
  if (msg.printers && msg.printers.length > 0)
    ContextSystem.replacePrinters(msg.printers);

  // Nothing to update
  // if (msg.coupon && msg.removeID)
  //   ContextSystem.printerChanged(msg.coupon, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleSettingChanged: HandlerType = (msg: SettingChangedEventData, type: number) => {
  ContextSystem.changeRestaurantSetting(msg.settingsType, msg.enabled);

  // Nothing to update
  // if (msg.coupon && msg.removeID)
  //   ContextSystem.printerChanged(msg.coupon, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleReservationChanged: HandlerType = (msg: ReservationChangedEventData, type: number) => {
  // if (msg.removeID > 0)
  //   ContextSystem.removeRaw(msg.removeID);
  if (msg.reservation) {
    TableReservationAPI.fixDates(msg.reservation);
    ContextSystem.mergeTableReservations([msg.reservation]);
  }

  // if (msg.reservation && msg.removeID)
  //   ContextSystem.rawChanged(msg.reservation.id, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleProductChanged: HandlerType = (msg: ProductChangedEventData, type: number) => {
  if (msg.product)
    ContextSystem.mergeProducts([msg.product]);
  if (msg.removeID > 0)
    ContextSystem.removeProduct(msg.removeID);

  if (msg.product && msg.removeID > 0) {
    ContextSystem.productChanged(msg.product, msg.removeID);
  }
};

// noinspection JSUnusedLocalSymbols
const HandleExtraChanged: HandlerType = (msg: ExtraChangedEventData, type: number) => {
  if (
    (msg.extra && msg.removeID !== msg.extra.id)
    && (msg.removeID > 0)
  ) {
    ContextSystem.removeExtras(msg.removeID);
  }
  if (msg.extra) {
    ContextSystem.mergeExtras([msg.extra]);
  }
};

// noinspection JSUnusedLocalSymbols
const HandleElementChanged: HandlerType = (msg: ElementChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeElement(msg.removeID);
  if (msg.element) {
    ContextSystem.mergeBlueprintElements([msg.element]);
  }

  if (msg.element && msg.removeID)
    ContextSystem.elementChanged(msg.element, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleZoneChanged: HandlerType = (msg: ZoneChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeZone(msg.removeID);
  if (msg.zone) {
    ContextSystem.mergeZones([msg.zone]);
  }

  if (msg.zone && msg.removeID)
    ContextSystem.zoneChanged(msg.zone, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleVATValuesChanged: HandlerType = (msg: VATValuesChangedEventData, type: number) => {
  msg.vatValues.forEach(vv => vv.date = vv.date > 0 ? new Date(vv.date) : undefined);
  ContextSystem.vatValuesChanged(msg.vatValues, msg.shopID);
};

// noinspection JSUnusedLocalSymbols
const HandleStorageChanged: HandlerType = (msg: StorageChangedEventData, type: number) => {
  if (msg.removeID > 0)
    ContextSystem.removeStorage(msg.removeID);
  if (msg.storage) {
    msg.storage.addedDate = new Date(msg.storage.addedDate);
    ContextSystem.mergeStorage([msg.storage]);
  }

  if (msg.storage && msg.removeID)
    ContextSystem.storageChanged(msg.storage, msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleSavedStorageMovementChanged: HandlerType = (msg: SavedStorageMovementChangedEventData, type: number) => {
  if (msg.savedStorageMovement) {
    msg.savedStorageMovement.addedDate = new Date(msg.savedStorageMovement.addedDate);
    ContextSystem.mergeSavedStorageMovements([msg.savedStorageMovement]);
  }
  if (msg.removeID > 0)
    ContextSystem.removeSavedStorageMovements(msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleShiftControlChanged: HandlerType = (msg: ShiftControlChangedEventData, type: number) => {
  if (msg.shiftControl) {
    msg.shiftControl.dateTime = new Date(msg.shiftControl.dateTime);
    msg.shiftControl.lastDateTime = msg.shiftControl.lastDateTime < 0 ? undefined : new Date(
      msg.shiftControl.lastDateTime);
    ContextSystem.mergeShiftControls([msg.shiftControl]);
  }
  if (msg.removeID > 0)
    ContextSystem.removeSavedStorageMovements(msg.removeID);
};

// noinspection JSUnusedLocalSymbols
const HandleCategoryChanged: HandlerType = (msg: CategoryChangedEventData, type: number) => {
  // ContextSystem.categories = [];
  // ContextSystem.mergeCategories([msg.category]);

  if (msg.category) {
    ContextSystem.mergeCategories([msg.category]);
    if (msg.productViews && msg.productViews.length > 0)
      ContextSystem.mergeProductViews(msg.productViews);
  }

  if (msg.removeID > 0) {
    ContextSystem.removeCategory(msg.removeID);
  }
};

// noinspection JSUnusedLocalSymbols
const HandleLoggedOut: HandlerType = (msg: LoggedOutEventData, type: number) => {
  if (ContextSystem.profile?.id !== msg.id && ContextSystem.adminProfile?.id !== msg.id)
    return;

  AuthAPI.logout();
};

const HandleShopOpenedClosed: HandlerType = (msg: PartnerMessage, type: number) => {
  EventSystem.publish(EventSystem.events.partnerOpened, {
    opened: type === CrossEventType.SHOP_OPENED,
    shop: msg.partner
  });
};

let crossEventHandlerTable: {
  [type: number]: HandlerType;
} = {};
crossEventHandlerTable[CrossEventType.ORDER_CHANGED] = HandleOrderChanged;
crossEventHandlerTable[CrossEventType.PRODUCT_CHANGED] = HandleProductChanged;
crossEventHandlerTable[CrossEventType.EXTRA_CHANGED] = HandleExtraChanged;
crossEventHandlerTable[CrossEventType.CATEGORY_CHANGED] = HandleCategoryChanged;
crossEventHandlerTable[CrossEventType.SHOP_CLOSED] = HandleShopOpenedClosed;
crossEventHandlerTable[CrossEventType.SHOP_OPENED] = HandleShopOpenedClosed;
crossEventHandlerTable[CrossEventType.LOGGED_OUT] = HandleLoggedOut;
crossEventHandlerTable[CrossEventType.RAW_CHANGED] = HandleRawChanged;
crossEventHandlerTable[CrossEventType.RECIPE_CHANGED] = HandleRecipeChanged;
crossEventHandlerTable[CrossEventType.STOCK_CHANGED] = HandleStockChanged;
crossEventHandlerTable[CrossEventType.RESERVATION_CHANGED] = HandleReservationChanged;
crossEventHandlerTable[CrossEventType.ELEMENT_CHANGED] = HandleElementChanged;
crossEventHandlerTable[CrossEventType.STORAGE_CHANGED] = HandleStorageChanged;
crossEventHandlerTable[CrossEventType.SAVED_STORAGE_MOVEMENT_CHANGED] = HandleSavedStorageMovementChanged;
crossEventHandlerTable[CrossEventType.SHIFT_CONTROL_CHANGED] = HandleShiftControlChanged;
crossEventHandlerTable[CrossEventType.ZONE_CHANGED] = HandleZoneChanged;
crossEventHandlerTable[CrossEventType.COUPON_CHANGED] = HandleCouponChanged;
crossEventHandlerTable[CrossEventType.UPSELL_RUNE_CHANGED] = HandleUpsellRuleChanged;
crossEventHandlerTable[CrossEventType.PRINTERS_CHANGED] = HandlePrintersChanged;
crossEventHandlerTable[CrossEventType.SETTING_CHANGED] = HandleSettingChanged;
crossEventHandlerTable[CrossEventType.VAT_VALUES_CHANGED] = HandleVATValuesChanged;
crossEventHandlerTable[CrossEventType.SHIFT_CONTROL_EXTRA_PARAMETER_CHANGED] = HandleShiftControlParameterChanged;

export default crossEventHandlerTable;
