import React, { Component } from "react";
import styled from "styled-components";
import { Section, SubSection } from "./DetailedSettings";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import Language, { Names } from "../../utils/Language";
import { Shop } from "../../model/Shop";
import { BiPencil, FaTrash } from "react-icons/all";
import { Controls } from "./Products";
import type { ShopProfileType } from "../../model/ShopProfile";
import { ShopProfile, ShopProfilePermissions, ShopProfileTypes } from "../../model/ShopProfile";
import { toast } from "react-toastify";
import { Button, Input } from "../FormComponents";
import EmailValidator, { isInAdminDomain } from "../../utils/EmailValidator";
import { ShopProfileAPI } from "../../utils/api/ShopProfileAPI";
import ConfirmationModal from "../modals/ConfirmationModal";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5%;
`;

const UserList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const UserDiv = styled.div`
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  margin: 6px 4px;

  font-size: 10pt;

  border-radius: 5px;

  transition: background-color 300ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const SpecialUser = styled.div`
  user-select: none;
  border-radius: 7px;
  border: 2px solid #444440;
  color: #444440;
  margin: 2px 3px;
  font-size: 9pt;
  font-weight: bold;
  padding: 3px 6px;
`;

const UserData = styled.div`
  width: ${({ width }) => width ? width : "150px"};
  margin: 0 3px;
`;

const UserPermission = styled.div`
  width: 350px;
`;

const InviteWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
`;

const SendInviteButton = styled(Button)`
  font-size: 10pt;
  margin-left: 6px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
`;

const InviteInput = styled(Input)`
  padding: 0 10px;
  margin: 0;
  height: 100%;
  width: 200px;
  font-size: 10pt;
  min-height: unset;

  &:focus {
    outline: none;
  }
`;

export default class Users extends Component {
  state: {
    selectedShop: Shop,
    language: number,
    editEmail: string,
  } = {
    selectedShop: ContextSystem.selectedShop,
    language: ContextSystem.language,
    editEmail: "",
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, selectedShop }) => {
        if (language !== undefined)
          this.setState({ language });
        if (selectedShop !== undefined)
          this.setState({ selectedShop });
      },
    );
    this.eventIDs.push(eid);
  }

  modifyUser(sp: ShopProfile) {
    if (!sp)
      return;

    if (sp.id === ContextSystem.selectedShop?.primaryContact?.id) {
      toast(Language.getName(Names.CantModifyPrimaryUser));
      return;
    }
    EventSystem.publish(EventSystem.events.edit_shop_profile_permissions, sp);
  }

  removeUser(sp: ShopProfile) {
    if (this.state.selectedShop.employees.filter(e => !isInAdminDomain(e.email)).length <= 1) {
      toast(Language.getName(Names.CantDeleteLastUser));
      return;
    }

    let profiles: ShopProfile[] = this.state.selectedShop.employees.filter(
      e => !isInAdminDomain(e.email) && ShopProfile.isPermitted(e, this.state.selectedShop.id,
        ShopProfileTypes.MANAGER,
      ));
    let manager: boolean = ShopProfile.isPermitted(sp, this.state.selectedShop.id, ShopProfileTypes.MANAGER);
    if (manager && (!profiles || profiles.length <= 1)) {
      toast(Language.getName(Names.CantDeleteLastManagerUser));
      return;
    }
    if (this.state.selectedShop.primaryContact.id === sp.id) {
      toast(Language.getName(Names.CantDeletePrimaryUser));
      return;
    }

    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {},
      () => {
        ShopProfileAPI.remove(sp.email, res => {
          if (res.error !== 0)
            return;

          ContextSystem.removeShopProfile(sp);
          toast(Language.getName(Names.Removed));
        });
      },
    );
  }

  sendInvite() {
    if (!this.state.editEmail || this.state.editEmail.length <= 0)
      return;
    if (!EmailValidator(this.state.editEmail)) {
      toast(Language.getName(Names.EmailAddressNotValid));
      return;
    }

    ShopProfileAPI.invite(this.state.editEmail, [ShopProfileTypes.STAND], res => {
      if (res.error !== 0)
        return;

      ContextSystem.addShopProfileInvite(res.newlyCreated, res.shopProfile);
    });
  }

  render() {
    return (
      <Wrapper>
        <Section>
          <div>
            <h2>{Language.getName(Names.Users)}</h2>
            <InviteWrapper>
              <InviteInput
                value={this.state.editEmail}
                type="text"
                placeholder={Language.getName(Names.InviteUser)}
                onChange={(e) => this.setState({ editEmail: e.target.value })}
              />
              <SendInviteButton onClick={() => this.sendInvite()}>
                {Language.getName(Names.SendButtonText)}
              </SendInviteButton>
            </InviteWrapper>
          </div>
          <SubSection>
            <UserList>
              {this.state.selectedShop?.employees?.map((sp, i) => {
                if (this.state.selectedShop?.primaryContact?.id !== sp?.id && isInAdminDomain(sp.email))
                  return <React.Fragment key={i} />;
                return (
                  <UserDiv key={i}>
                    <UserData width={"150px"}>
                      {sp.activated &&
                        <>{sp.lastName} {sp.firstName}</>
                      }
                    </UserData>
                    {!sp.activated &&
                      <SpecialUser>
                        {Language.getName(Names.InvitationPending)}...
                      </SpecialUser>
                    }
                    {sp.id === this.state.selectedShop.primaryContact?.id &&
                      <SpecialUser>
                        {Language.getName(Names.PrimaryContact)}
                      </SpecialUser>
                    }
                    <UserData width={"200px"}>
                      {sp.email}<br />
                      {sp.activated &&
                        <>{sp.phoneNumber}</>
                      }
                    </UserData>
                    <UserPermission>
                      <b>{Language.getName(Names.Permissions)}:</b><br />
                      {Object.keys(ShopProfileTypes).map((k, i) => {
                        let type: ShopProfileType = ShopProfileTypes[k];
                        let permitted: boolean = ShopProfile.isPermitted(sp, this.state.selectedShop.id, type);
                        if (permitted)
                          return (
                            <span key={i}>
                              {" • " + ShopProfilePermissions.getName(type)}
                            </span>
                          );
                        else
                          return <React.Fragment key={i} />;
                      })}
                    </UserPermission>
                    <Controls width={"fit-content"}>
                      <div onClick={() => this.modifyUser(sp)}>
                        <BiPencil />
                        <span>{Language.getName(Names.Modify)}</span>
                      </div>
                      <div onClick={() => this.removeUser(sp)}>
                        <FaTrash />
                        <span>{Language.getName(Names.Remove)}</span>
                      </div>
                    </Controls>
                  </UserDiv>
                );
              })}
            </UserList>
          </SubSection>
        </Section>
      </Wrapper>
    );
  }
}
