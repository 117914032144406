import MessageType from "./MessageType";
import HandlerTable from "./MessageHandler";
import Config from "../Config";
import ErrorMessages from "../api/ErrorMessages";
import ContextSystem from "../ContextSystem";

export default class WSConnection {
  static webSocketWrapper: WSConnection;
  webSocket: WebSocket;

  static getInstance(): WSConnection {
    if (!WSConnection.webSocketWrapper)
      WSConnection.webSocketWrapper = new WSConnection();
    return WSConnection.webSocketWrapper;
  }

  close() {
    if (this.webSocket) {
      this.webSocket.close();
    }
  }

  open(callbackAfterSuccessfulOpen?: () => void) {
    if (this.webSocket || !localStorage.getItem("usertoken") || localStorage.getItem("usertoken") === "") {
      if (this.webSocket) {
        this.close();
      }

      return;
    }

    this.webSocket = new WebSocket(Config.wsURL + "/" + localStorage.getItem("usertoken"));

    this.webSocket.onopen = (e) => {
      if (Config.DEBUG)
        console.log("WS: OPEN ", e);
      ContextSystem.setOnline(true);
      if (callbackAfterSuccessfulOpen)
        callbackAfterSuccessfulOpen();
    };

    this.webSocket.onclose = (e) => {
      if (Config.DEBUG)
        console.log("WS: CLOSE ", e);
      this.webSocket = undefined;
      ContextSystem.setOnline(false);
      if (ContextSystem.profile || ContextSystem.adminProfile) {
        setTimeout(() => this.open(), 1000);
      }
    };

    this.webSocket.onerror = (e) => {
      if (Config.DEBUG)
        console.log("WS: ERROR ", e);
      this.close();
    };

    this.webSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (Config.DEBUG)
        console.log("Received: ", data);
      if (data.error !== 0) {
        if (ErrorMessages[data.error]) {
          console.error(ErrorMessages[data.error]);
        } else {
          console.error(ErrorMessages.UNEXPECTED_ERROR);
        }

        HandlerTable[MessageType.ERROR](data);
        return;
      }

      if (data && data.type && data.type !== MessageType.OK && !!HandlerTable[data.type]) {
        HandlerTable[data.type](data);
      } else if (data && (!data.type || data.type === MessageType.OK)) {
        HandlerTable[MessageType.OK](data);
      }
    };
  }

  sendMessage(type: MessageType, body: any) {
    if (this.webSocket) {
      let data = JSON.stringify({
        type,
        ...body,
      });
      if (Config.DEBUG)
        console.log("WS: Sent: ", data);
      this.webSocket.send(data);
    }
  }
}
