import React, { Component, ReactNode } from "react";
import styled, { css } from "styled-components";
import { Contract, ContractTypes } from "../../model/Contract";
import ContextSystem from "../../utils/ContextSystem";
import Language, { Names } from "../../utils/Language";
import { Shop } from "../../model/Shop";
import { City, ZipCode } from "../../model/Address";
import { CommissionOffers } from "../../model/CommissionRecord";
import { TranslatableString } from "../../model/Product";
import Config, { ConfigKeys } from "../../utils/Config";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Arial, sans-serif;
  background-color: white;

  ol {
    counter-reset: item;
  }

  li {
    display: block;

    &:before {
      content: counters(item, ".") " ";
      counter-increment: item;
    }
  }

  p {
    width: 100%;
    line-height: 1.1;
  }
`;

const Title = styled.h2`
  font-size: 22pt;
  color: #841a1e;
  width: 100%;
  text-align: center;
  margin: 10px 0;
`;

const OffersTable = styled.div`
  border: 2px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Prices = styled.p`
  font-size: 9pt;
`;

const OffersTableRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  & > div {
    border: 1px solid black;
    font-size: 9pt;
  }

  & > div:nth-child(1) {
    width: 30%;
    text-align: left;
    padding-left: 5px;
  }

  & > div:nth-child(2) {
    width: 20%;
    text-align: center;
  }

  & > div:nth-child(3) {
    width: 50%;
    text-align: left;
    padding-left: 10px;
  }

  ${({ header }) => header === true && css`
    font-weight: bold;
  `};
`;

export default class ContractContent extends Component<{ contract: Contract }> {
  render() {
    if (this.props.contract?.type === ContractTypes.FIRST || this.props.contract?.type === ContractTypes.SECOND) {
      return renderSecondContractType(this.props.contract);
    } else if (this.props.contract?.type === ContractTypes.THIRD) {
      return renderThirdContractType(this.props.contract);
    } else {
      return renderSecondContractType(this.props.contract);
    }
  }
}

function renderSecondContractType(contract: Contract): ReactNode {
  let shop: Shop = ContextSystem.shops.find(s => s.id === contract.partnerID);
  let city: City = ContextSystem.getCityByID(shop.address.cityID);
  let zip: ZipCode = ContextSystem.getCityByID(shop.address.zipCodeID);

  let offerName: string = CommissionOffers.getName(contract.type, contract.offerID);

  return (
    <Wrapper>
      <Title>{Language.getName(Names.ContractContentTitle)}</Title>
      <p>
        amely létrejött egyrészről
      </p>
      <p>
        <b>Cégnév:</b> {Config.getString(ConfigKeys.partner_contract_company)}<br />
        <b>Székhely:</b> {Config.getString(ConfigKeys.partner_contract_address)}<br />
        <b>Cégjegyzékszám:</b> {Config.getString(ConfigKeys.partner_contract_official_id)}<br />
        <b>Adószám:</b> {Config.getString(ConfigKeys.partner_contract_vat)}<br />
        <b>Bankszámlaszám:</b> {Config.getString(ConfigKeys.partner_contract_bank_iban)}<br />
        <b>E-mail:</b> {Config.getString(ConfigKeys.partner_contract_email)}<br />
        <b>Képviselő:</b> {Config.getString(ConfigKeys.partner_contract_representative)}<br />
        <b>Kapcsolattartás:</b> (tel. , e-mail): {Config.getString(ConfigKeys.partner_contract_contact)}<br />
        mint <b>Szolgáltató</b> és másrészről
      </p>
      <p>
        <b>Cégnév:</b>{contract.companyName}<br />
        <b>Székhely:</b>{contract.hqCountry + ", "}{contract.hqZip + " "}{contract.hqCity + ", "}{contract.hqAddress}<br />
        <b>Étterem / étlapnév:</b>{ContextSystem.selectedShop.name}<br />
        <b>Étterem címe:</b>
        {(zip?.zipcode ?? "") + " " + (TranslatableString.get(city?.name) ?? "") + ", " + shop?.address?.street}<br />
        <b>Adószám:</b>{contract.companyVAT}<br />
        <b>Számlavezető bank:</b>{contract.bankName}<br />
        <b>Bankszámlaszám:</b>{contract.payoutIBAN}<br />
        <b>Telefonszám:</b>{shop.publicTel}<br />
        <b>E-mail:</b>{shop.publicEmail}<br />
        <b>Képviselő:</b>{contract.representativeName}<br />
        <b>Kapcsolattartó (tel., e-mail):</b>
        {contract.contactEmail + ", "}{contract.contactTel}<br />
        mint <b>Partner</b> (A Szolgáltató és a Partner a továbbiakban együttesen: <b>Felek</b>,
        külön-külön: <b>Fél</b>) között
        alulírott napon és helyen az alábbi feltételekkel (továbbiakban: <b>Megállapodás</b>).
      </p>
      <p>
        <b>A Szolgáltatás:</b>
      </p>
      <p>
        A Szolgáltató vállalja és biztosítja az Blundee elnevezésű elektronikus kereskedelmi szolgáltatása
        keretében,a Partner számára, egy saját Partner oldalt, amelyen a beállított termékeit megjeleníti a
        www.blundee.com weboldalon, mobil weboldalon, Blundee messenger rendelésfelületén és az Blundee mobil
        applikációban, valamint továbbítja beérkező megrendeléseket a Partner számára, illetve lehetőséget nyújt a
        közvetített rendelések ellenértékének online kifizetésének.
      </p>
      <ol>
        <li>
          SZOLGÁLTATÁSI DÍJAZÁS
          <ol>
            <li>
              A Partner a Szolgáltató jelen Megállapodás szerinti szolgáltatásai ellenében az (1.4) pontban
              meghatározott csomagok közül a kiválasztott csomag díját fizeti nettó értékben a Szolgáltató részére,
              ami
              a Partner oldalon Visszaigazolt rendelések bruttó forgalma alapján kerül kiszámításra, mely tartalmazza
              a
              szállítási, csomagolási és minden egyéb járulékos költséget.
            </li>
            <li>
              Az online bankkártyás fizetési vagy a SZÉP kártyás fizetési szolgáltatások díjai, a rendelés közvetítési
              szolgáltatás díján felül kerül felszámításra, a Felhasználó fizetési módjától függően, az (1.4) pontban
              részletezett díjszabás szerint.
            </li>
            <li>
              Közös marketing és promóciós vállalások
              <ol>
                <li>
                  Ha a Partner az 1.5. pontban található csomagok alapján Promóciós kötelezettséget vállal, akkor a
                  Megállapodás keltétől számítva a Szolgáltató feltételei alapján teljesíti a Szolgáltató promóciós
                  anyagainak jól látható helyen való elhelyezését, illetve amennyiben a Partner rendelkezik Online
                  éttermi adatlapokkal, (Pl: Google Cégem, Facebook éttermi oldal) kötelessége a Partnernek a
                  Szolgáltató partner specifikus hivatkozását az összes ilyen típusú felületen jól látható módon
                  elhelyezni, megjelölni, mint a Szolgáltató online ételrendelési platformja. A Promóciós
                  kötelezettségről a továbbiakban az ÁSZF 14. pontja rendelkezik. Szolgáltató kérésére hitelt
                  érdemlően igazolnia kell vállalások elégtételét. Amennyiben a Partner felszólítás után sem tudja ezt
                  igazolni, úgy automatikusan a “Standard” csomag szerint kerül elszámolásra az adott szolgáltatási
                  időszak. A Szolgáltatási időszak, minden tárgyhónap első napjával kezdődik és utolsó napjával ér
                  véget.
                </li>
                <li>
                  Ha a Partner az 1.5 pontban található csomagok alapján “A” csomag promóciós kötelezettségeit
                  vállalja, úgy egy Szolgáltatási időszakban legalább 8 darab megjelenést biztosít a Szolgáltató
                  irányelvei alapján online közösségi média platformokon, (Pl: Facebook, Instagram...) melyben a saját
                  vállalkozását illetve a Szolgáltató rendelési felületét promótálja. Az irányelveket a Szolgáltató a
                  Partner számára rendszeresen frissítve elérhetővé teszi az ÁSZF 14.5 pontjában található weboldalon.
                </li>
                <li>
                  Ha a Partner az 1.5 pontban található csomagok alapján az “Egyedi” csomag promóciós kötelezettségeit
                  vállalja, úgy a szerződés kiegészítő megállapodásában megfogalmazott kötelezettségek vonatkoznak rá
                  a szerződött időtartam alatt.
                </li>
              </ol>
            </li>
            <li>
              Felek közötti elszámolás részleteiről az ÁSZF 10. pontja rendelkezik.
            </li>
            <li>
              Elérhető csomagok és a kezdeti kiválasztott csomag:
              <OffersTable>
                <OffersTableRow header={true}>
                  <div>Csomag megnevezése</div>
                  <div>Díj mértéke (Nettó)</div>
                  <div>Közös marketing és promóciós vállalások</div>
                </OffersTableRow>
                <OffersTableRow>
                  <div>"A" Csomag</div>
                  <div>6%</div>
                  <div>Szerződés 1.3 pontjába foglaltak szerint</div>
                </OffersTableRow>
                <OffersTableRow>
                  <div>"Standard" Csomag</div>
                  <div>10%</div>
                  <div>Nincs kötelezettség, vállalás</div>
                </OffersTableRow>
              </OffersTable>
              <br />
              <Prices>
                Kiválasztott csomag és díjai ({offerName} Csomag):<br />
                <b>{offerName} Csomag
                  díja:</b> {contract.offerID === CommissionOffers.SECOND_TYPE_A_OFFER ? "6%" : "10%"}<br />
                <b>Online bankkártyás fizetés szolgáltatás díja</b>: 1,5%<br />
                <b>Online Apple Pay / Google Pay fizetési szolgáltatás díja</b>: 2%<br />
                <b>Online SZÉP kártyás fizetés szolgáltatás díja</b>: 3,6%<br />
              </Prices>
            </li>
          </ol>
        </li>
        <li>
          A PARTNERSÉGI MEGÁLLAPODÁS TÍPUSA
          <ol>
            <li>
              A jelen Megállapodás a Partner, mint megbízó és a Szolgáltató, mint közvetítő közötti tartós közvetítői
              megállapodásnak minősül. Mivel egyik fél sem fogyasztó, a jelen Megállapodás nem minősül fogyasztói
              megállapodásnak.
            </li>
          </ol>
        </li>
        <li>
          A PARTNERSÉGI MEGÁLLAPODÁS HATÁLYA
          <ol>
            <li>
              Az 1.5 pontban meghatározott “A” és “Standard” Csomagok esetén határozatlan időre szól a Megállapodás.
              Az “Egyedi” Csomag esetén a megállapodás időtartama a Kiegészítő megállapodásban meghatározott naptári
              hónap megjelölt napjáig tart.
            </li>
            <li>
              A Megállapodást bármelyik Fél, bármelyik napon indoklás nélkül felmondhatja. A Fél felmondási szándékát
              a másik Fél részére köteles írásban jelezni, amit a Felek 15 napon belül visszaigazolnak. A felmondási
              idő a felmondási szándék jelzésétől számítottan az adott naptári hónap utolsó napjáig tart.
            </li>
            <li>
              A Szolgáltatónak jogában áll a szerződést azonnali hatállyal felmondani, amennyiben A Partner a
              szerződésben leírtak szerinti kötelezettségeit súlyos mértékben megszegi. A Szerződés súlyos mértékű
              megszegését jelenti különösen, ha a Partner fizetési késedelembe esik, Szolgáltató oldalán feltüntetendő
              információkkal kapcsolatban valótlan tényt közöl, a Szolgáltató hírnevét csorbítja, az Általános
              Szerződési Feltételek szerinti tiltott direkt marketing tevékenységet folytat, fizetésképtelenségi
              eljárás hatálya alá kerül vagy az Általános Szerződési Feltételekben meghatározott egyéb súlyos
              szerződésszegési magatartást tanúsít.
            </li>
          </ol>
        </li>
        <li>
          AZ ÜZLETI TITOK
          <ol>
            <li>
              A Felek üzleti titoknak minősítenek minden olyan, információt,megoldás, tényt és adatot, amelyet a Felek
              a Szerződés teljesítésével összefüggésben egymásnak adnak át, vagy amely a Szerződés teljesítése során
              egyéb módon a másik Fél tudomására jut. Üzleti titoknak minősül különösen a szellemi termék, egyéb know-
              how, a szabályzatok, az informatikai és fizikai hozzáférés ellenőrzését biztosító adatok
              (felhasználónevek és jelszavak), üzleti vagy üzemi folyamatok és módszerek, tervek és specifikációk,
              egyéb szoftverek és adatbázisok, valamint más szellemi alkotások, ötletek és előkészített
              projektek.<br />
              A Szerződés szövege vagy annak bármely része üzleti titoknak tekintendő.
            </li>
            <li>
              A Felek kötelesek az üzleti titkot ennek megfelelően védeni és titokban tartani, az üzleti titokra az
              üzleti titokról szóló 2018. évi LIV. törvény foglalja magában.Feleket a Partnerség hatályon kívüli
              helyezésétől számított 5 évig terheli a titoktartási kötelezettség.
            </li>
          </ol>
        </li>
        <li>
          ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK
          <ol>
            <li>
              A Megállapodás elválaszthatatlan részét képezi az 1. számú mellékletként csatolt Általános Szerződési
              Feltételek, amely megtalálható a https://partner.enniakarok.hu/aszf oldalon. A Partner az Általános
              Szerződési Feltételeket elolvasta, értelmezte és kifejezetten minden pontját elfogadja, tudomásul veszi.
            </li>
          </ol>
        </li>
        <li>
          TELJES MEGÁLLAPODÁS
          <ol>
            <li>
              A Szerződés hatályon kívül helyez minden a Szerződés tárgyához nem kapcsolódó korábbi nyilatkozatot,
              ajánlatot, kötelezettségvállalást, megállapodást vagy egyezséget. A Szerződésbe bele nem foglalt korábbi
              nyilatkozatok, ajánlatok, kötelezettségvállalások, megállapodások vagy egyezségek a Szerződés
              értelmezésénél nem vehetők figyelembe.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        Felek a jelen Megállapodás elolvasása és értelmezése után, mint akaratukkal mindenben megegyezőt, jóváhagyólag
        aláírták. Jelen szerződés aláírása a Partner felől online történt meg.
      </p>
      <p>
        Kelt: {contract.hqCity}, {(contract?.date ?? new Date()).toHunFormatMinutes()}.
      </p>
    </Wrapper>
  );
}

function renderThirdContractType(contract: Contract): ReactNode {
  let shop: Shop = ContextSystem.shops.find(s => s.id === contract.partnerID);
  let city: City = ContextSystem.getCityByID(shop.address?.cityID);
  let zip: ZipCode = ContextSystem.getCityByID(shop.address?.zipCodeID);

  return (
    <Wrapper>
      <Title>{Language.getName(Names.ContractContentTitle)}</Title>
      <p>
        amely létrejött egyrészről
      </p>
      <p>
        <b>Cégnév:</b> {Config.getString(ConfigKeys.partner_contract_company)}<br />
        <b>Székhely:</b> {Config.getString(ConfigKeys.partner_contract_address)}<br />
        <b>Cégjegyzékszám:</b> {Config.getString(ConfigKeys.partner_contract_official_id)}<br />
        <b>Adószám:</b> {Config.getString(ConfigKeys.partner_contract_vat)}<br />
        <b>Bankszámlaszám:</b> {Config.getString(ConfigKeys.partner_contract_bank_iban)}<br />
        <b>E-mail:</b> {Config.getString(ConfigKeys.partner_contract_email)}<br />
        <b>Képviselő:</b> {Config.getString(ConfigKeys.partner_contract_representative)}<br />
        <b>Kapcsolattartás:</b> (tel. , e-mail): {Config.getString(ConfigKeys.partner_contract_contact)}<br />
        mint <b>Szolgáltató</b> és másrészről
      </p>
      <p>
        <b>Cégnév:</b>{contract.companyName}<br />
        <b>Székhely:</b>{contract.hqCountry + ", "}{contract.hqZip + " "}{contract.hqCity + ", "}{contract.hqAddress}<br />
        <b>Étterem / étlapnév:</b>{ContextSystem.selectedShop.name}<br />
        <b>Étterem címe:</b>
        {(zip?.zipcode ?? "") + " " + (TranslatableString.get(city?.name) ?? "") + ", " + shop?.address?.street}<br />
        <b>Adószám:</b>{contract.companyVAT}<br />
        <b>Számlavezető bank:</b>{contract.bankName}<br />
        <b>Bankszámlaszám:</b>{contract.payoutIBAN}<br />
        <b>Telefonszám:</b>{shop.publicTel}<br />
        <b>E-mail:</b>{shop.publicEmail}<br />
        <b>Képviselő:</b>{contract.representativeName}<br />
        <b>Kapcsolattartó (tel., e-mail):</b>
        {contract.contactEmail + ", "}{contract.contactTel}<br />
        mint <b>Partner</b> (A Szolgáltató és a Partner a továbbiakban együttesen: <b>Felek</b>,
        külön-külön: <b>Fél</b>) között
        alulírott napon és helyen az alábbi feltételekkel (továbbiakban: <b>Megállapodás</b>).
      </p>
      <p>
        <b>A Szolgáltatás:</b>
      </p>
      <p>
        A Szolgáltató vállalja és biztosítja az Blundee elnevezésű elektronikus kereskedelmi szolgáltatása
        keretében,a Partner számára, egy saját Partner oldalt, amelyen a beállított termékeit megjeleníti a
        www.blundee.com weboldalon, mobil weboldalon, Blundee messenger rendelésfelületén és az Blundee mobil
        applikációban, valamint továbbítja beérkező megrendeléseket a Partner számára, illetve lehetőséget nyújt a
        közvetített rendelések ellenértékének online kifizetésének.
      </p>
      <ol>
        <li>
          SZOLGÁLTATÁSI DÍJAZÁS
          <ol>
            <li>
              A Partner a Szolgáltató jelen Megállapodás szerinti szolgáltatásai ellenében az (1.5) pontban
              meghatározott csomagok közül a kiválasztott csomag díját fizeti nettó értékben a Szolgáltató részére, ami
              a Partner oldalon Visszaigazolt rendelések bruttó forgalma alapján kerül kiszámításra, mely tartalmazza a
              szállítási, csomagolási és minden egyéb járulékos költséget.
            </li>
            <li>
              Az online bankkártyás fizetési vagy a SZÉP kártyás fizetési szolgáltatások díjai, a rendelés közvetítési
              szolgáltatás díján felül kerül felszámításra, a Felhasználó fizetési módjától függően, az (1.5) pontban
              részletezett díjszabás szerint.
            </li>
            <li>
              Közös marketing, promóciós és adminisztrációs vállalások
              <ol>
                <li>
                  Ha a Partner az 1.5. pontban található csomagok alapján Promóciós kötelezettséget vállal, akkor a
                  Megállapodás keltétől számítva a Szolgáltató feltételei alapján teljesíti a Szolgáltató promóciós
                  anyagainak jól látható helyen való elhelyezését, illetve amennyiben a Partner rendelkezik Online
                  éttermi adatlapokkal, (Pl: Google Cégem, Facebook éttermi oldal… stb.) kötelessége a Partnernek a
                  Szolgáltató partner specifikus hivatkozását az összes ilyen típusú felületen jól látható módon
                  elhelyezni, megjelölni, mint a Szolgáltató online ételrendelési platformja. A Promóciós
                  kötelezettségről a továbbiakban az ÁSZF 14. pontja rendelkezik. Szolgáltató kérésére hitelt érdemlően
                  igazolnia kell vállalások elégtételét. A Szolgáltatási időszak, minden tárgyhónap első napjával
                  kezdődik és utolsó napjával ér véget.
                </li>
                <li>
                  A Partner az 1.5 pontban található csomagok alapján promóciós kötelezettségeit vállalja, úgy egy
                  Szolgáltatási időszakban legalább 4 darab megjelenést biztosít a Szolgáltató irányelvei alapján online
                  közösségi média platformokon, (Pl: Facebook, Instagram… stb.) melyben a saját vállalkozását illetve a
                  Szolgáltató rendelési felületét promótálja. Az irányelveket a Szolgáltató a Partner számára
                  rendszeresen frissítve elérhetővé teszi az ÁSZF 14.5 pontjában található weboldalon.
                </li>
                <li>
                  Ha a Partner az 1.5 pontban található csomagok alapján az “Egyedi” csomag promóciós kötelezettségeit
                  vállalja, úgy a szerződés kiegészítő megállapodásában megfogalmazott kötelezettségek vonatkoznak rá a
                  szerződött időtartam alatt.
                </li>
                <li>
                  A Partner vállalja, hogy minden, a csatlakozáshoz szükséges anyagot és információt a szerződéskötést
                  követő 3 munkanapon belül a Szolgáltató rendelkezésére bocsát.
                </li>
              </ol>
            </li>
            <li>
              Felek közötti elszámolás részleteiről az ÁSZF 10. pontja rendelkezik.
            </li>
            <li>
              Elérhető díjazási csomagok és a kezdeti kiválasztott csomag:
              <OffersTable>
                <OffersTableRow header={true}>
                  <div>Megnevezés</div>
                  <div>Díj mértéke (Nettó)</div>
                  <div>Közös marketing és promóciós vállalások</div>
                </OffersTableRow>
                <OffersTableRow>
                  <div>Regisztrációs díj</div>
                  <div>10.000Ft</div>
                  <div>Ablakmatrica és QR kódok jól látható helyre történő kihelyezése</div>
                </OffersTableRow>
                <OffersTableRow>
                  <div>Rendszerhasználati díj</div>
                  <div>1.969Ft / hónap</div>
                  <div />
                </OffersTableRow>
                <OffersTableRow>
                  <div>Online leadott rendeléssel történő <b>helyszíni</b> fogyasztás jutaléka</div>
                  <div>1.0%</div>
                  <div />
                </OffersTableRow>
                <OffersTableRow>
                  <div>Online <b>asztalfoglalás</b> esetén jutalék az adott asztal fogyasztásából</div>
                  <div>6.0%</div>
                  <div />
                </OffersTableRow>
                {contract.offerID === 0 &&
                  <OffersTableRow>
                    <div>Online <b>házhozszállításos</b> rendelés jutaléka</div>
                    <div>6.0%</div>
                    <div>Szerződés 1.3 pontjába foglaltak szerint</div>
                  </OffersTableRow>
                }
                {contract.offerID === 1 &&
                  <OffersTableRow>
                    <div>Online <b>házhozszállításos</b> rendelés jutaléka</div>
                    <div>10.0%</div>
                    <div>Nincs kötelezettség, vállalás</div>
                  </OffersTableRow>
                }
                {contract.offerID === 2 &&
                  <OffersTableRow>
                    <div>“Egyedi” Csomag</div>
                    <div>Kiegészítő megállapodásban meghatározva.</div>
                    <div>Speciális igényekhez, a kiegészítő megállapodás alapján</div>
                  </OffersTableRow>
                }
              </OffersTable>
              <br />
              <Prices>
                <b>Online bankkártyás fizetés szolgáltatás díja</b>: 1,5%<br />
                <b>Online Apple Pay / Google Pay fizetési szolgáltatás díja</b>: 2%<br />
                <b>Online SZÉP kártyás fizetés szolgáltatás díja</b>: 3,6%<br />
              </Prices>
            </li>
          </ol>
        </li>
        <li>
          A PARTNERSÉGI MEGÁLLAPODÁS TÍPUSA
          <ol>
            <li>
              A jelen Megállapodás a Partner, mint megbízó és a Szolgáltató, mint közvetítő közötti tartós közvetítői
              megállapodásnak minősül. Mivel egyik fél sem fogyasztó, a jelen Megállapodás nem minősül fogyasztói
              megállapodásnak.
            </li>
          </ol>
        </li>
        <li>
          A PARTNERSÉGI MEGÁLLAPODÁS HATÁLYA
          <ol>
            <li>
              Az 1.5 pontban meghatározott “A” Csomag esetén határozatlan időre szól a Megállapodás. Az “Egyedi” Csomag
              esetén a megállapodás időtartama a Kiegészítő megállapodásban meghatározott naptári hónap megjelölt
              napjáig tart.
            </li>
            <li>
              A Megállapodást bármelyik Fél, bármelyik napon indoklás nélkül felmondhatja. A Fél felmondási szándékát a
              másik Fél részére köteles írásban jelezni, amit a Felek 15 napon belül visszaigazolnak. A felmondási idő a
              felmondási szándék jelzésétől számítottan az adott naptári hónap utolsó napjáig tart.
            </li>
            <li>
              A Szolgáltatónak jogában áll a szerződést azonnali hatállyal felmondani, amennyiben A Partner a
              szerződésben leírtak szerinti kötelezettségeit súlyos mértékben megszegi. A Szerződés súlyos mértékű
              megszegését jelenti különösen, ha a Partner fizetési késedelembe esik, Szolgáltató oldalán feltüntetendő
              információkkal kapcsolatban valótlan tényt közöl, a Szolgáltató hírnevét csorbítja, az Általános
              Szerződési Feltételek szerinti tiltott direkt marketing tevékenységet folytat, fizetésképtelenségi eljárás
              hatálya alá kerül vagy az Általános Szerződési Feltételekben meghatározott egyéb súlyos szerződésszegési
              magatartást tanúsít.
            </li>
          </ol>
        </li>
        <li>
          AZ ÜZLETI TITOK
          <ol>
            <li>
              A Felek üzleti titoknak minősítenek minden olyan, információt,megoldás, tényt és adatot, amelyet a Felek a
              Szerződés teljesítésével összefüggésben egymásnak adnak át, vagy amely a Szerződés teljesítése során egyéb
              módon a másik Fél tudomására jut. Üzleti titoknak minősül különösen a szellemi termék, egyéb know- how, a
              szabályzatok, az informatikai és fizikai hozzáférés ellenőrzését biztosító adatok (felhasználónevek és
              jelszavak), üzleti vagy üzemi folyamatok és módszerek, tervek és specifikációk, egyéb szoftverek és
              adatbázisok, valamint más szellemi alkotások, ötletek és előkészített projektek.<br />
              A Szerződés szövege vagy annak bármely része üzleti titoknak tekintendő.
            </li>
            <li>
              A Felek kötelesek az üzleti titkot ennek megfelelően védeni és titokban tartani, az üzleti titokra az
              üzleti titokról szóló 2018. évi LIV. törvény foglalja magában.Feleket a Partnerség hatályon kívüli
              helyezésétől számított 5 évig terheli a titoktartási kötelezettség.
            </li>
          </ol>
        </li>
        <li>
          ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK
          <ol>
            <li>
              A Megállapodás elválaszthatatlan részét képezi az 1. számú mellékletként csatolt Általános Szerződési
              Feltételek, amely megtalálható a https://partner.blundee.com/aszf oldalon, valamint “Egyedi” díjazási
              csomag esetén, a 2. számú mellékletként az egyedi megállapodás részletezett feltételeit tartalmazó
              Kiegészítő megállapodás.
            </li>
            <li>
              A szerződés aláírásával a Partner elismeri, hogy az Általános Szerződési Feltételeket elolvasta,
              értelmezte és kifejezetten minden pontját elfogadja, tudomásul veszi.
            </li>
          </ol>
        </li>
        <li>
          TELJES MEGÁLLAPODÁS
          <ol>
            <li>
              A Szerződés hatályon kívül helyez minden a Szerződés tárgyához nem kapcsolódó korábbi nyilatkozatot,
              ajánlatot, kötelezettségvállalást, megállapodást vagy egyezséget. A Szerződésbe bele nem foglalt korábbi
              nyilatkozatok, ajánlatok, kötelezettségvállalások, megállapodások vagy egyezségek a Szerződés
              értelmezésénél nem vehetők figyelembe.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        Felek a jelen Megállapodás elolvasása és értelmezése után, mint akaratukkal mindenben megegyezőt, jóváhagyólag
        aláírták. Jelen szerződés aláírása a Partner felől online történt meg.
      </p>
      <p>
        Kelt: {contract.hqCity}, {(contract?.date ?? new Date()).toHunFormatMinutes()}.
      </p>
    </Wrapper>
  );
}
