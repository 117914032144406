import React, { Component } from "react";
import notification_sound from "../assets/notification.mp3";
import EventSystem from "../utils/EventSystem";
import { Order, OrderState } from "../model/Order";
import ConfirmationModal from "./modals/ConfirmationModal";
import ContextSystem from "../utils/ContextSystem";
import logo from "../assets/blundee_logos/app_icon.png";
import Language, { Names } from "../utils/Language";
import Layout from "../model/Layout";

export default class NotificationSound extends Component {
  lastPermission: NotificationPermission;
  loggedIn: boolean = false;
  sentNotification: string[] = [];

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.authentication_changed, () => {
      this.setupPermission();
      this.stopSound();
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ orders }) => {
      if (orders !== undefined) {
        for (let o of orders) {
          if (o.lastState?.status === OrderState.NEW) {
            this.restartAutoReplay();
            break;
          }
        }
      }
    });

    this.restartAutoReplay();
  }

  restartAutoReplay() {
    if (this.timeout)
      clearTimeout(this.timeout);
    this.autoReplaySound();
  }

  timeout;

  autoReplaySound() {
    this.timeout = setTimeout(() => {
      this.autoReplaySound();
      this.notifyNewOrders(ContextSystem.orders);
    }, 7500);
  }

  setupPermission() {
    this.lastPermission = Notification.permission;

    this.loggedIn = ContextSystem.loggedIn;

    if (!this.loggedIn)
      return;

    let callback = () => {
      Notification.requestPermission().then((result: NotificationPermission) => {
        this.lastPermission = Notification.permission;
        if (result !== "granted") {
          let msg2 = (
            <p>
              {Language.getName(Names.NoPermissionForPushNotification)}
            </p>
          );
          ConfirmationModal.showModal(Language.getName(Names.BrowserSettings), msg2, Language.getName(Names.Ok));
        }
      });
    };

    if (Notification.permission === "default") {
      let msg = (
        <p>
          {Language.getName(Names.AskPermissionForPushNotification)}
        </p>
      );
      ConfirmationModal.showModal(Language.getName(Names.IncomingOrders), msg, Language.getName(Names.Ok), "", callback,
        callback,
      );
    }
  }

  notifyNewOrders(orders: Order[]) {
    if (!orders || orders.length <= 0 || !ContextSystem.loggedIn)
      return;

    if (orders.filter(o => o.partnerID === ContextSystem.selectedShop?.id && o.lastState?.status === OrderState.NEW).length > 0)
      this.playNotificationSound();

    if (document.hasFocus())
      return;

    let newIDs: string[] = [];

    orders.forEach(o => {
      if (o.partnerID === ContextSystem.selectedShop?.id && o.lastState?.status === OrderState.NEW) {
        let a = this.sentNotification.find(v => v === o.number);
        if (a !== o.number)
          newIDs.push(o.number);
      }
    });
    if (newIDs.length <= 0)
      return;

    let title: string = Language.getName(Names.OrderReceived);
    let message: string = Language.getName(Names.OrderReceivedTextPart1) + newIDs.length + Language.getName(
      Names.OrderReceivedTextPart2);
    new Notification(title, { body: message, icon: logo });

    newIDs.forEach(id => this.sentNotification.push(id));
  }

  playNotificationSound() {
    if (ContextSystem.layout !== Layout.MANAGER)
      return;

    const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0];
    if (audioEl) {
      audioEl.currentTime = 0;
      // noinspection JSIgnoredPromiseFromCall
      audioEl.play();
    }
  }

  stopSound() {
    const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0];
    if (audioEl) {
      audioEl.currentTime = 0;
    }
  }

  render() {
    return (
      <audio className="audio-element">
        <source src={notification_sound} />
      </audio>
    );
  }
}
