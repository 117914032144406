import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import styled from "styled-components";
import Language, { Names } from "../../utils/Language";
import { Button } from "../FormComponents";
import CustomerSelector from "../CustomerSelector";
import { Profile } from "../../model/Profile";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 30vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default class NewCustomerEditor extends Component {
  state: {
    language: number,
    showModal: boolean,
    cb: ()=>{},
    closeOnSelected: boolean,
    showCount: number,
  } = {
    language: ContextSystem.language,
    showModal: false,
    cb: undefined,
    closeOnSelected: false,
    showCount: 1,
  };

  loadElements() {
    this.setState({
      language: ContextSystem.language,
    });
  }

  componentDidMount() {
    this.loadElements();

    EventSystem.subscribe(EventSystem.events.add_new_customer, ({ cb, closeOnSelected }) => {
      this.setState({
        showModal: true,
        showCount: this.state.showCount + 1,
        cb,
        closeOnSelected,
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.loadElements();
    });
  }

  handleProfileSelected(id: number) {
    let selectedProfile: Profile = ContextSystem.profiles.find(p => p.id === id);
    this.setState({ selectedProfile });
    if (this.state.cb)
      this.state.cb(selectedProfile);

    if (this.state.closeOnSelected) {
      this.setState({ showModal: false });
    }
  }

  render() {
    return (
      <Modal size={"lg"} show={this.state.showModal}
             onEscapeKeyDown={() => this.setState({ showModal: false })}
      >
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.AddNewCustomer)}</Modal.Title>
        </Modal.Header>
        <Modal.Body padding={"6px"}>
          <Wrapper>
            <CustomerSelector key={this.state.showCount} onProfileSelected={(id) => this.handleProfileSelected(id)} />
          </Wrapper>
        </Modal.Body>
        {!this.state.closeOnSelected &&
          <Modal.Footer>
            <Button onClick={() => this.setState({ showModal: false })}>
              {Language.getName(Names.CancelButtonText)}
            </Button>
          </Modal.Footer>
        }
      </Modal>
    );
  }
}
