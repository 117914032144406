import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import Modal from "./Modal";
import "react-image-crop/dist/ReactCrop.css";
import styled, { css } from "styled-components";
import ContextSystem from "../../utils/ContextSystem";
import { Contract, ContractTypes } from "../../model/Contract";
import { Label } from "./ContractsDetailsModal";
import { CitySelector, Select } from "../home/DetailedSettings";
import type { GetSearchZipcodeResType } from "../../utils/api/ShippingsAPI";
import { ShippingsAPI } from "../../utils/api/ShippingsAPI";
import IBAN from "iban";
import PhoneNumber from "libphonenumber-js";
import EmailValidator from "../../utils/EmailValidator";
import { toast } from "react-toastify";
import Steps from "rc-steps";
import { AiOutlineStop, BsDot } from "react-icons/all";
import ContractContent from "../contract/ContractContent";
import { ContractAPI } from "../../utils/api/ContractAPI";
import Language, { Names } from "../../utils/Language";
import { City, ZipCode } from "../../model/Address";
import { CommissionOffers } from "../../model/CommissionRecord";
import { Button, Input } from "../FormComponents";
import { TranslatableString } from "../../model/Product";
// import validateVAT from 'validate-vat';

const Body = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  & > newline {
    display: block;
    height: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  & > h3 {
    width: 100%;
    text-align: left;
  }
`;

export const InputWrapper = styled.div`
  width: ${({ width }) => width ?? "fit-content"};
  display: flex;
  flex-direction: ${({ flex_direction }) => flex_direction ?? "column"};
  align-items: flex-start;
  justify-content: flex-start;
  margin: ${({ margin }) => margin ?? "0 auto"};
  padding: 3px 0;
`;

const OffersWrapper = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
`;

const Offer = styled.div`
  width: 33%;
  min-width: 350px;
  max-width: 600px;
  height: fit-content;

  margin: 15px;
  padding: 10px;

  border-radius: 11px;
  box-shadow: 0 0 8px 1px #404040;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${({ selected }) => selected === true && css`
    box-shadow: 0 0 8px 1px #841a1e;
  `}
`;

const OfferTopTitle = styled.div`
  font-size: 16px;
  font-family: "Axiforma", "Arial", sans-serif;
  font-weight: 600;
  color: black;
`;

const OfferSubTitle = styled.div`
  font-size: 12px;
  font-family: "Axiforma", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.55);
  margin-top: 0;
  text-transform: uppercase;
`;

const OfferTitle = styled.div`
  font-size: 40pt;
  font-family: "Axiforma", "Arial", sans-serif;
  margin-bottom: 1px;
  color: #841a1e;

  & > span:nth-of-type(1) {
    text-decoration: line-through;
    margin-right: 13px;
  }

  & > span:nth-of-type(2) {
    font-size: 28pt;
    vertical-align: super;
  }
`;

const OfferDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const OfferFeature = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: "Axiforma", "Arial", sans-serif;

  padding: 2px 0 1px 0;

  & > svg, & > b {
    margin-right: 5px;
  }

  ${({ missing }) => missing === true && css`
    color: #b1b1b1;
  `};
`;

const OfferDetailHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px solid #d7d7d7;
  margin-bottom: 3px;
`;

const SelectOffer = styled(Button)`
  ${({ selected }) => selected && css`
    background-color: #841a1e;
    color: white;
    font-weight: bold;
    border: none;
  `};
`;

const InputGroup = styled.div`
  width: 98%;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  padding: 10px;
  margin: 5px auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h3 {
    margin-bottom: 10px;
  }
`;

const ContractContentWrapper = styled.div`
  width: 80%;
  margin: 15px auto;
`;

export default class AddContractModal extends Component {
  state: {
    showModal: boolean,
    contract: Contract,
    selectedZipCode: { label: string, value: any, cityName: string, zip: string },
    signState: number,
    language: number,
  } = {
    showModal: false,
    contract: undefined,
    selectedZipCode: null,
    signState: 0,
    language: ContextSystem.language,
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_add_contract_modal, () => {
      this.setState({
        showModal: true,
        contract: new Contract(-1, ContextSystem.selectedShop.id, "", "", "Magyarország", "", "", "", "", "", "", "",
          "", null, 0, null, ContractTypes.THIRD, -1,
        ),
        selectedZipCode: undefined,
        signState: 0,
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined) {
        this.setState({ language });
      }
    });
  }

  companyNameChanged(e) {
    let name = e.target.value;
    if (name === "Teszt Bt..") {
      ShippingsAPI.searchZipcode("1111", res => {
        if (res.error !== 0)
          return;

        let zip: ZipCode = res.results[0];
        let city: City = res.cities.find(c => c && c?.id === zip?.cityID);
        let selectedZipCode = {
          value: zip.id,
          label: zip.zipcode + " " + TranslatableString.get(city.name),
          cityName: TranslatableString.get(city.name),
          zip: zip.zipcode,
        };

        let contract: Contract = { ...this.state.contract };
        contract.companyName = name;
        contract.bankName = "MKB";
        contract.payoutIBAN = "HU93 1160 0006 0000 0000 1234 5676";
        contract.companyVAT = "HU12345678";
        contract.hqZip = "1111";
        contract.hqCity = "XI. Kerület";
        contract.contactTel = "+36 20 333 1112";
        contract.contactEmail = "pelda@belaexample.com";
        contract.representativeName = "Példa Béla";
        contract.hqAddress = "Példa utca 223.";
        // noinspection JSUndefinedPropertyAssignment
        contract.hqZipID = zip.id;
        this.setState({
          contract,
          selectedZipCode,
        });
      });
    } else {
      this.setState({ contract: { ...this.state.contract, companyName: name } });
    }
  }

  vatValid = false;

  render() {
    return (
      <Modal show={this.state.showModal} size={this.state.signState === 3 || this.state.signState === 0 ? "xl" : "lg"}
             onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.ConclusionOfContract)}</Modal.Title>
        </Modal.Header>
        <Body>
          <Steps progressDot size="small" current={this.state.signState}>
            <Steps.Step title="Csomag" />
            <Steps.Step title="Adatok" />
            <Steps.Step title="Ellenőrzés" />
            <Steps.Step title="Elektronikus aláírás" />
          </Steps>
          {this.state.signState === 0 &&
            <>
              <OffersWrapper>
                {this.renderOffer(CommissionOffers.THIRD_TYPE_A_OFFER)}
                {this.renderOffer(CommissionOffers.THIRD_TYPE_STANDARD_OFFER)}
              </OffersWrapper>
            </>
          }
          {this.state.signState === 1 &&
            <>
              <InputWrapper width={"48%"}>
                <Label>Cég neve:</Label>
                <Input
                  width={"100%"}
                  autoComplete="off"
                  autocomplete="off"
                  value={this.state.contract.companyName}
                  type="text"
                  placeholder="Teszt Bt."
                  onChange={(e) => this.companyNameChanged(e)}
                />
              </InputWrapper>
              <InputWrapper width={"48%"}>
                <Label
                  color={this.vatValid ? "green" : "initial"}
                >
                  Cég adószáma:
                </Label>
                <Input
                  width={"100%"}
                  autoComplete="off"
                  autocomplete="off"
                  value={this.state.contract.companyVAT}
                  type="text"
                  placeholder="HU12345678"
                  onChange={(e) => this.setState({ contract: { ...this.state.contract, companyVAT: e.target.value } })}
                />
              </InputWrapper>
              <InputGroup>
                <Label bold width={"100%"}>Cég hivatalos székhelye:</Label>
                <InputWrapper width={"98%"}>
                  <Label>Ország:</Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.hqCountry}
                    type="text"
                    placeholder="Magyarország"
                    onChange={(e) => this.setState({ contract: { ...this.state.contract, hqCountry: e.target.value } })}
                  />
                </InputWrapper>
                <Label>Irányítószám, város:</Label>
                <CitySelector width={"98%"}>
                  <Select
                    placeholder={"Irányítószám (pl.: 1131)"}
                    noOptionsMessage={() => "Kérem kezdjen el irányítószámokra keresni..."}
                    value={this.state.selectedZipCode}
                    onChange={(e) => {
                      this.setState({ selectedZipCode: e });
                    }}
                    loadOptions={this.promiseOptions}
                  />
                </CitySelector>
                <InputWrapper width={"98%"}>
                  <Label>Cím:</Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.hqAddress}
                    type="text"
                    placeholder="Példa utca 23"
                    onChange={(e) => this.setState({ contract: { ...this.state.contract, hqAddress: e.target.value } })}
                  />
                </InputWrapper>
              </InputGroup>
              <InputGroup>
                <Label bold width={"100%"}>Cég hivatalos bankszámla adatai:</Label>
                <InputWrapper width={"38%"}>
                  <Label>Bank neve:</Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.bankName}
                    type="text"
                    placeholder="MNB"
                    onChange={(e) => this.setState({ contract: { ...this.state.contract, bankName: e.target.value } })}
                  />
                </InputWrapper>
                <InputWrapper width={"58%"}>
                  <Label
                    color={IBAN.isValid(this.state.contract?.payoutIBAN ?? "") ? "green" : "initial"}
                  >
                    Bankszámlaszám (kiutalás ide történik):
                  </Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.payoutIBAN}
                    type="text"
                    placeholder="IBAN (HU93 1160 0006 0000 0000 1234 5676)"
                    onChange={(e) => this.setState({
                      contract: {
                        ...this.state.contract,
                        payoutIBAN: IBAN.printFormat(e.target.value, " "),
                      },
                    })}
                  />
                  <Label>
                    IBAN ellenőrzés és generálás: <a href={"https://www.iban.hu/calculate-iban"}
                                                     target={"_blank"}>www.iban.hu</a>
                  </Label>
                </InputWrapper>
              </InputGroup>
              <InputGroup>
                <Label bold width={"100%"}>Képviselő közvetlen elérhetőségei:</Label>
                <Label>
                  Az a személy, aki hivatalosan képviselheti a céget, és elfogadja jelen online
                  együttműködést.
                </Label>
                <InputWrapper width={"98%"}>
                  <Label>Teljes név:</Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.representativeName}
                    type="text"
                    placeholder="Példa Béla"
                    onChange={(e) => this.setState({
                      contract: {
                        ...this.state.contract,
                        representativeName: e.target.value,
                      },
                    })}
                  />
                </InputWrapper>
                <InputWrapper width={"48%"}>
                  <Label>E-mail cím:</Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.contactEmail}
                    type="text"
                    placeholder="email@cegem.hu"
                    onChange={(e) => this.setState(
                      { contract: { ...this.state.contract, contactEmail: e.target.value } })}
                  />
                </InputWrapper>
                <InputWrapper width={"48%"}>
                  <Label
                    color={PhoneNumber(this.state.contract?.contactTel ?? "", "HU")?.isValid() ? "green" : "initial"}
                  >
                    Telefonszám:
                  </Label>
                  <Input
                    width={"100%"}
                    autoComplete="off"
                    autocomplete="off"
                    value={this.state.contract.contactTel}
                    type="text"
                    placeholder="+36 20 123 4567"
                    onChange={(e) => this.setState({
                      contract: {
                        ...this.state.contract,
                        contactTel: PhoneNumber(e.target.value, "HU")?.formatInternational() ?? e.target.value,
                      },
                    })}
                  />
                </InputWrapper>
              </InputGroup>
            </>
          }
          {this.state.signState === 2 &&
            <InfoWrapper>
              <h3>Kérem ellenőrizze a szerződés adatait!</h3>
              <Label>
                <b>Kiválasztott csomag:</b>
                {CommissionOffers.getName(this.state.contract.type, this.state.contract.offerID)}
              </Label>
              <Label>
                <b>Cég:</b> {this.state.contract.companyName} ({this.state.contract.companyVAT})
              </Label>
              <Label>
                <b>Székhely:</b> {this.state.contract.hqCountry}, {this.state.selectedZipCode.label}, {this.state.contract.hqAddress}
              </Label>
              <Label>
                <b>Bankszámlaszám:</b> {this.state.contract.payoutIBAN} ({this.state.contract.bankName})
              </Label>
              <Label>
                <b>Képviselő:</b>
                {this.state.contract.representativeName},
                ({this.state.contract.contactEmail}, {this.state.contract.contactTel})
              </Label>
              <OffersWrapper>
                {this.renderOffer(this.state.contract.offerID, false)}
              </OffersWrapper>
            </InfoWrapper>
          }
          {this.state.signState === 3 &&
            <>
              <h3>Szerződés tartalma</h3>
              <ContractContentWrapper>
                <ContractContent contract={this.state.contract} />
              </ContractContentWrapper>
            </>
          }
        </Body>
        <Modal.Footer>
          {this.state.signState > 0 &&
            <Button variant="secondary" onClick={() => this.handlePrevClicked()}>
              Vissza
            </Button>
          }
          <Button variant="secondary" onClick={() => this.handleNextClicked()}>
            {this.state.signState === 0 && "Tovább"}
            {this.state.signState === 1 && "Tovább"}
            {this.state.signState === 2 && "Tovább"}
            {this.state.signState === 3 && "Aláír"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  promiseOptions = inputValue =>
    new Promise(resolve => {
      if (inputValue.length < 3)
        resolve([]);
      ShippingsAPI.searchZipcode(inputValue, (res: GetSearchZipcodeResType) => {
        let options = [];
        if (res.error === 0) {
          res.results.forEach(zip => {
            let city = res.cities.find((c: City) => c && c?.id === zip?.cityID);
            if (city) {
              options.push({
                value: zip.id,
                label: zip.zipcode + " " + TranslatableString.get(city.name),
                cityName: TranslatableString.get(city.name),
                zip: zip.zipcode,
              });
            }
          });
        }
        resolve(options);
      });
    });

  handleNextClicked() {
    if (this.state.signState === 0) {
      this.validatePackage();
    } else if (this.state.signState === 1) {
      this.validateData();
    } else if (this.state.signState === 2) {
      this.setState({ signState: 3 });
    } else if (this.state.signState === 3) {
      this.saveContract();
    }
  }

  validatePackage() {
    if (![0, 1, 2].includes(this.state.contract.offerID)) {
      toast("Kérem válasszon ki egy csomagot!");
      return;
    }
    this.setState({ signState: 1 });
  }

  validateData() {
    let validBankName = (this.state.contract?.bankName ?? "").length >= 3;
    let validIBAN = IBAN.isValid(this.state.contract?.payoutIBAN ?? "");
    let validPhone = PhoneNumber(this.state.contract?.contactTel ?? "", "HU")?.isValid() ?? false;
    let validEmail = EmailValidator(this.state.contract?.contactEmail ?? "");
    let validRepName = (this.state.contract.representativeName?.split(
      " ") ?? []).length > 1 && (this.state.contract?.representativeName ?? "").length > 4;
    // let validVAT = await checkVatValidity('HU', this.state.contract.companyVAT);
    let validVAT = this.state.contract.companyVAT.length >= 8;
    let validCompanyName = this.state.contract.companyName.length >= 5;
    let hqValid = this.state.selectedZipCode && this.state.contract.hqAddress.length >= 5 && this.state.contract.hqCountry.length >= 5;

    if (!validCompanyName) {
      toast("Cég neve túl rövid!");
      return;
    }
    if (!validVAT) {
      toast("Cég adószáma túl rövid!");
      return;
    }
    if (!hqValid) {
      toast("Cég székhelye hiányzik!");
      return;
    }
    if (!validBankName) {
      toast("Bank neve rövid!");
      return;
    }
    if (!validIBAN) {
      toast("Bankszámlaszám hibás!");
      return;
    }
    if (!validRepName) {
      toast("Képviselő neve túl rövid!");
      return;
    }
    if (!validPhone) {
      toast("Telefonszám hibás! Csak magyar telefonszámot fogadunk el.");
      return;
    }
    if (!validEmail) {
      toast("Email cím hibás!");
      return;
    }

    let contract = { ...this.state.contract };
    contract.hqCity = this.state.selectedZipCode.cityName;
    contract.hqZip = this.state.selectedZipCode.zip;
    contract.hqZipID = this.state.selectedZipCode.value;
    this.setState({ signState: 2, contract });
  }

  handlePrevClicked() {
    this.setState({ signState: Math.max(0, this.state.signState - 1) });
  }

  saveContract() {
    ContractAPI.add(this.state.contract, this.state.contract.offerID, res => {
      if (res.error !== 0)
        return;

      res.contract.date = new Date(res.contract.date);
      ContextSystem.addContract(res.contract);

      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: "Sikeres szerződés!",
        text: (
          <>
            A szerződést elmentettük, hamarosan ellenőrzi egy kollégánk!<br />
          </>
        ),
        yesText: "Rendben",
        noText: "",
        // cancelCb: () => {
        // },
        proceedCb: () => {
          this.setState({
            showModal: false,
            contract: undefined,
            selectedOffer: undefined,
            selectedZipCode: undefined,
            signState: 0,
            profile: undefined,
          });
        },
      });
    });
  }

  renderOffer(offerID, withButton: boolean = true) {
    return (
      <>
        {offerID === CommissionOffers.THIRD_TYPE_A_OFFER &&
          <Offer selected={this.state.contract?.offerID === CommissionOffers.THIRD_TYPE_A_OFFER}>
            <OfferTopTitle>Kedvezményes "A"</OfferTopTitle>
            <OfferTitle>6%</OfferTitle>
            <OfferSubTitle>jutalék</OfferSubTitle>
            <OfferDetails>
              <OfferFeature><b>0% jutalék</b> helyi rendelésrögzítés</OfferFeature>
              <OfferFeature><b>1% jutalék</b> helyi applikációs rendelés végösszegéből</OfferFeature>
              <OfferFeature><b>6% jutalék</b> asztalfoglalás végösszegéből</OfferFeature>
              <OfferFeature><b>6% jutalék</b> online rendelés végösszegéből</OfferFeature>
              <OfferFeature><b>Teljes rendszer</b> használata - havi <b>1.969Ft</b></OfferFeature>
              <OfferFeature><b>Szoftverfrissítések</b> folyamatosan</OfferFeature>
              <OfferFeature><b>Teljes kontroll</b> az online megjelenésben</OfferFeature>
              <OfferFeature missing><AiOutlineStop /><b>Dedikált</b> étterem hirdetés</OfferFeature>
              <OfferDetailHeader>Az Ön vállalásai:</OfferDetailHeader>
              <OfferFeature><BsDot />Blundee ablakmatrica felhelyezése</OfferFeature>
              <OfferFeature><BsDot />Heti min. 1db social média poszt a saját Blundee éttermi
                linkjével</OfferFeature>
              <OfferFeature><BsDot />A saját Blundee éttermi link kihelyezése online adatlapoknál</OfferFeature>
              <OfferFeature><BsDot />Személyre szabott molino kihelyezése az üzlet közelében (lehetőség szerint).
              </OfferFeature>

              {withButton &&
                <SelectOffer selected={this.state.contract?.offerID === CommissionOffers.THIRD_TYPE_A_OFFER}
                             onClick={() => this.setState({
                               contract: {
                                 ...this.state.contract,
                                 offerID: CommissionOffers.THIRD_TYPE_A_OFFER,
                               },
                             })}
                >
                  {this.state.contract?.offerID === CommissionOffers.THIRD_TYPE_A_OFFER && "Kiválasztva"}
                  {this.state.contract?.offerID !== CommissionOffers.THIRD_TYPE_A_OFFER && "Kiválasztom"}
                </SelectOffer>
              }
            </OfferDetails>
          </Offer>
        }
        {offerID === CommissionOffers.THIRD_TYPE_STANDARD_OFFER &&
          <Offer selected={this.state.contract?.offerID === CommissionOffers.THIRD_TYPE_STANDARD_OFFER}>
            <OfferTopTitle>Standard</OfferTopTitle>
            <OfferTitle>10%</OfferTitle>
            <OfferSubTitle>jutalék</OfferSubTitle>
            <OfferDetails>
              <OfferFeature><b>0% jutalék</b> helyi rendelésrögzítés</OfferFeature>
              <OfferFeature><b>1% jutalék</b> helyi applikációs rendelés végösszegéből</OfferFeature>
              <OfferFeature><b>6% jutalék</b> asztalfoglalás végösszegéből</OfferFeature>
              <OfferFeature><b>10% jutalék</b> rendelés végösszegéből</OfferFeature>
              <OfferFeature><b>Teljes rendszer</b> használata - havi <b>1.969Ft</b></OfferFeature>
              <OfferFeature><b>Szoftverfrissítések</b> folyamatosan</OfferFeature>
              <OfferFeature><b>Teljes kontroll</b> az online megjelenésben</OfferFeature>
              <OfferFeature><b>Dedikált</b> étterem hirdetés</OfferFeature>
              <OfferDetailHeader>Az Ön vállalásai:</OfferDetailHeader>
              <OfferFeature><BsDot />Blundee ablakmatrica felhelyezése</OfferFeature>
              <OfferFeature missing><BsDot /><AiOutlineStop />Heti min. 1db social média poszt a saját Blundee éttermi
                linkjével</OfferFeature>
              <OfferFeature missing><BsDot /><AiOutlineStop />A saját Blundee éttermi link kihelyezése online
                adatlapoknál</OfferFeature>
              <OfferFeature missing><BsDot /><AiOutlineStop />Személyre szabott molino kihelyezése az üzlet közelében
                (ha
                van rá
                mód).
              </OfferFeature>

              {withButton &&
                <SelectOffer selected={this.state.contract?.offerID === CommissionOffers.THIRD_TYPE_STANDARD_OFFER}
                             onClick={() => this.setState({
                               contract: {
                                 ...this.state.contract,
                                 offerID: CommissionOffers.THIRD_TYPE_STANDARD_OFFER,
                               },
                             })}
                >
                  {this.state.contract?.offerID === CommissionOffers.THIRD_TYPE_STANDARD_OFFER && "Kiválasztva"}
                  {this.state.contract?.offerID !== CommissionOffers.THIRD_TYPE_STANDARD_OFFER && "Kiválasztom"}
                </SelectOffer>
              }
            </OfferDetails>
          </Offer>
        }
      </>
    );
  }
}
