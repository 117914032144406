import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import { Raw, RawQty, Recipe, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button, Input } from "../FormComponents";
import { RecipeAPI } from "../../utils/api/RecipeAPI";
import { Label } from "./RawEditor";
import { CellInput, RawUnit, Select, Table, TableCell, TableRow } from "./InwardHandlingModal";
import { ButtonWrapper } from "../home/Settings";
import { FaTrash } from "react-icons/all";
import { languages, ReactFlagsSelect } from "./CouponEditor";
import type { Option } from "./OrderStateCommentEditor";
import { CheckBox } from "../home/Orders";

export default class RecipeEditor extends Component {
  state: {
    showModal: boolean,
    edit: null | Recipe,
    title: string,
    language: number,
    raws: Raw[],
    storages: Storage[],
    selectedLanguage: number,
    storageOptions: Option[],
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewRecipe),
    language: ContextSystem.language,
    raws: [],
    storages: [],
    selectedLanguage: languages[0].id,
    storageOptions: [],
  };

  save() {
    let recipe: Recipe = { ...this.state.edit };
    if (!recipe.name || !recipe.name.translation?.find(t => t.value.length > 1)) {
      toast(Language.getName(Names.GivenNameTooShort));
      return;
    }

    RecipeAPI.save(recipe, res => {
      if (res.error !== 0)
        return;

      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  edit(recipe: Recipe) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyRecipe),
      edit: { ...recipe },
    });
  }

  addNew() {
    this.setState({
      showModal: true,
      title: Language.getName(Names.AddNewRecipe),
      edit: {
        id: -1,
        enabled: true,
        name: TranslatableString.create(),
        partnerID: ContextSystem.selectedShop.id,
        addedDate: new Date(),
        raws: [],
      },
    });
  }

  load() {
    let storages: Storage[] = ContextSystem.storages.filter(
      r => r.enabled && r.shopIDs.includes(ContextSystem.selectedShop?.id));
    let storageOptions: Option[] = storages.map(s => ({ value: s.id, label: s.name }));
    storageOptions.unshift({ value: -1, label: Language.getName(Names.DefaultStorage) });

    this.setState({
      storageOptions,
      raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      storages,
    });
  }

  componentDidMount() {
    this.load();
    EventSystem.subscribe(EventSystem.events.edit_recipe, (recipe) => {
      if (!recipe)
        this.addNew();
      else
        this.edit(recipe);
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, raws, storages }) => {
      if (language !== undefined)
        this.setState({ language });
      if (raws !== undefined || storages !== undefined)
        this.load();
    });
  }

  removeRaw(raw: Raw) {
    if (!raw)
      return;

    let raws: RawQty[] = this.state.edit.raws.filter(rq => rq.raw.id !== raw.id);
    this.setState({
      edit: {
        ...this.state.edit,
        raws: raws,
      },
    });
  }

  changeQty(r: Raw, v: number) {
    if (!r || !v)
      return;

    let qty = Math.round(v * 100) / 100;

    let raws: RawQty[] = this.state.edit.raws.map(rq => {
      if (r.id === rq.raw.id)
        return { ...rq, qty };
      else
        return { ...rq };
    });

    this.setState({
      edit: {
        ...this.state.edit,
        raws: raws,
      },
    });
  }

  setAllowedOtherStorages(rq: RawQty, checked: boolean) {
    if (!rq || checked === undefined)
      return;
    let raws: RawQty[] = [];

    this.state.edit.raws.forEach(r => {
      if (r.raw.id === rq.raw.id)
        r.allowOtherStorage = checked;
      raws.push({ ...r });
    });

    let edit = { ...this.state.edit, raws };
    this.setState({ edit });
  }

  selectStorage(rq: RawQty, e: Option) {
    if (!rq || !e)
      return;

    let raws: RawQty[] = [];

    this.state.edit.raws.forEach(r => {
      if (r.raw.id === rq.raw.id)
        r.defaultStorageID = e.value;
      raws.push({ ...r });
    });

    let edit = { ...this.state.edit, raws };
    this.setState({ edit });
  }

  addNewRaw(rawID: number) {
    if (!rawID)
      return;

    let raw: Raw = this.state.raws.find(r => r.id === rawID);
    if (!raw)
      return;

    if (this.state.edit.raws.find(rq => rq.raw.id === rawID))
      return;

    let raws: RawQty[] = this.state.edit.raws.map(rq => ({ ...rq }));
    raws.push({
      raw: raw,
      qty: 0,
      defaultStorageID: -1,
      allowOtherStorage: true,
    });

    this.setState({
      edit: {
        ...this.state.edit,
        raws: raws,
      },
    });
  }

  setName(value: string) {
    let languageCode: string = languages.find(l => l.id === this.state.selectedLanguage).languageCode;
    let name: TranslatableString = this.state.edit.name;
    if (!name)
      name = TranslatableString.create("");
    name.translation = name.translation.filter(t => t.languageCode !== languageCode);
    name.translation.push({ languageCode: languageCode, value: value });
    this.setState({
      edit: {
        ...this.state.edit,
        name: name,
      },
    });
  }

  render() {
    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    let options: Option[] = [];

    this.state.raws.forEach(r => {
      options.push({ value: r.id, label: TranslatableString.get(r.name) });
    });

    return (
      <Modal size={"lg"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id })}
          />
        </Modal.Header>
        <Modal.Body>
          {this.state.edit &&
            <>
              <Label>{Language.getName(Names.Name)}</Label>
              <Input
                width={"100%"}
                autoComplete={"off"}
                autocomplete={"off"}
                value={this.state.edit?.name?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? ""}
                type={"text"}
                placeholder={Language.getName(Names.Name)}
                onChange={e => this.setName(e.target.value)}
              />
              <Label font_size={"10pt"}>{Language.getName(Names.RecipeFor10Servings)}</Label>
              <Table>
                <TableRow header={true}>
                  <TableCell width={"40px"}>#</TableCell>
                  <TableCell grow={5} width={"20%"}>{Language.getName(Names.Name)}</TableCell>
                  <TableCell grow={2} width={"100px"}>{Language.getName(Names.QuantityFor10)}</TableCell>
                  <TableCell grow={2} shrink={0} width={"150px"}>{Language.getName(Names.UsedStorage)}</TableCell>
                  <TableCell shrink={0} width={"100px"}>{Language.getName(Names.AllowAnotherStorage)}</TableCell>
                  <TableCell c_cursor={true} width={"30px"} />
                </TableRow>
                {this.state.edit?.raws?.length > 0 && this.state.edit.raws.map((rq: RawQty, i: number) => {
                  if (!rq)
                    return <React.Fragment key={i} />;

                  let raw: Raw = rq.raw;
                  let qty: number = rq.qty;

                  return (
                    <TableRow>
                      <TableCell width={"40px"}>#{i + 1}</TableCell>
                      <TableCell grow={5}
                                 width={"20%"}>{TranslatableString.get(raw.name)}</TableCell>
                      <TableCell grow={2} width={"100px"}>
                        <CellInput
                          pattern={"[0-9]+([\\.,][0-9]+)?"}
                          value={qty ?? ""}
                          type="number"
                          step={0.01}
                          placeholder={Language.getName(Names.Quantity)}
                          onChange={(e) => this.changeQty(raw, e.target.value)}
                          width={"100%"}
                        />
                        <RawUnit>
                          {TranslatableString.get(raw.unit)}
                        </RawUnit>
                      </TableCell>
                      <TableCell grow={2} width={"150px"} shrink={0}>
                        <Select
                          width={"90%"}
                          placeholder={Language.getName(Names.ChooseStorages)}
                          onChange={(e) => this.selectStorage(rq, e)}
                          options={this.state.storageOptions}
                          value={{
                            value: rq.defaultStorageID,
                            label: rq.defaultStorageID === -1
                                   ? Language.getName(Names.DefaultStorage)
                                   : this.state.storages.find(
                              (s: Storage) => s.id === rq.defaultStorageID)?.name ?? "--",
                          }}
                        />
                      </TableCell>
                      <TableCell width={"100px"} shrink={0}>
                        <CheckBox>
                          <input
                            type={"checkbox"}
                            checked={rq.allowOtherStorage}
                            onChange={e => this.setAllowedOtherStorages(rq, e.target.checked)}
                          />
                        </CheckBox>
                      </TableCell>
                      <TableCell width={"30px"}>
                        <FaTrash onClick={() => this.removeRaw(raw)} />
                      </TableCell>
                    </TableRow>
                  );
                })
                }
                <TableRow>
                  <TableCell width={"100%"}>
                    <Select
                      options={options}
                      placeholder={Language.getName(Names.NewRaw)}
                      noOptionsMessage={() => {
                        Language.getName(Names.NoRawMaterialsToShow);
                      }}
                      onChange={(e) => this.addNewRaw(e.value)}
                    />
                  </TableCell>
                </TableRow>
              </Table>
            </>
          }
          <ButtonWrapper>
            <Button onClick={() => EventSystem.publish(EventSystem.events.edit_raw_material)}>
              {Language.getName(Names.AddNewRaw)}
            </Button>
          </ButtonWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
