import "./Initializer";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-sortable-tree/style.css";
import "rc-steps/assets/index.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet/es/Helmet";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Header from "./components/header/Header";
import ScrollToTop from "./utils/ScrollToTop";
import Footer from "./components/Footer";
import AszfPage from "./pages/AszfPage";
import ActivatePage from "./pages/ActivatePage";
import ConfirmationModal from "./components/modals/ConfirmationModal";
import NotificationSound from "./components/NotificationSound";
import ContextSystem from "./utils/ContextSystem";
import EventSystem from "./utils/EventSystem";
import styled from "styled-components";
import ActivateShopProfilePage from "./pages/ActivateShopProfilePage";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Config from "./utils/Config";

const AppWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

class App extends React.Component {
  state: {
    cookie: boolean,
    language: number,
  } = {
    cookie: false,
    language: ContextSystem.language,
  };

  componentDidMount() {
    this.setState({
      cookie: window.localStorage.getItem("cookie") === "1",
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined)
        this.setState({ language });
    });

    // ReactGA.initialize('UA-106141597-1');
    // ReactGA.set({page: window.location.pathname}); // Update the user's current page
    // ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    // EventSystem.subscribe(EventSystem.events.urlChanged, () => {
    //Google analytics pagerefresh send
    // ReactGA.set({page: window.location.pathname}); // Update the user's current page
    // ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    //fb pixel pageview send
    //FBPixel.pageView();
    // });
  }

  // handleCookieBtn() {
  //   window.localStorage.setItem("cookie", "1");
  //   this.setState({
  //     cookie: true,
  //   });
  // }

  render() {
    return (
      <CacheBuster
        onCacheClear={() => {
          console.log("Cache cleared - " + Config.version);
        }}
        currentVersion={packageInfo.version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={Config.TEST} //If true, the library writes verbose logs to console.
        //loadingComponent={<Loading/>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <AppWrapper>
          {/*{this.state.cookie === false && (*/}
          {/*  <CookiesNotification>*/}
          {/*    <span>{Language.getName(Names.CookieText)}</span>*/}
          {/*    <div onClick={() => this.handleCookieBtn()} className={"button_cookies"}>*/}
          {/*      {Language.getName(Names.Close)}*/}
          {/*    </div>*/}
          {/*  </CookiesNotification>*/}
          {/*)}*/}
          <BrowserRouter>
            <ScrollToTop>
              <Header />
              <Helmet>
                <title>Blundee Partner</title>
                <meta name="description" content="Partner oldal" />
              </Helmet>
              <ToastContainer
                autoClose={3000}
                draggablePercent={40}
                position={toast.POSITION.TOP_CENTER}
                progressStyle={{
                  height: "5px",
                  background: "#f33242",
                }}
              />
              <ConfirmationModal />
              <NotificationSound />
              <Switch>
                <Route path={"/bejelentkezes"} component={() => <LoginPage redirect={true} />} exact />
                <Route path={"/aktivalas/:passphrase"} component={ActivatePage} exact />
                <Route path={"/profil/aktivalas/:passphrase"} component={ActivateShopProfilePage} exact />
                {/*<Route path={"/partnerlogin"} component={PartnerLoginPage} exact/>*/}
                <Route path={"/aszf"} component={AszfPage} exact />
                <Route path={"/"} component={HomePage} exact />
                <Route component={ErrorPage} />
              </Switch>
              <Footer />
            </ScrollToTop>
          </BrowserRouter>
        </AppWrapper>
      </CacheBuster>
    );
  }
}

export default App;
