import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import styled, { css } from "styled-components";
import Language, { Names } from "../../utils/Language";
import { Order, OrderPayment } from "../../model/Order";
import ErrorMessage from "../../utils/api/ErrorMessages";
import { AllPaymentMethod, PaymentMethods, PaymentMethodTypes } from "../../model/PaymentMethodSetting";
import { Button, Input } from "../FormComponents";
import { ShiftControl, ShiftControlCountedPayments, ShiftControlExtraParameter, ShiftControlExtraParameterSaved } from "../../model/ShiftControl";
import { AiOutlineExclamationCircle, BsCheck2All, BsDot, FaCheck, FaTimes, FiMoreHorizontal } from "react-icons/all";
import { ShiftControlsAPI } from "../../utils/api/ShiftControlsAPI";
import { toast } from "react-toastify";
import { ShopProfile } from "../../model/ShopProfile";
import ProductEditor from "./ProductEditor";
import Loader from "react-loader-spinner";
import ToggleButton from "react-toggle-button";
import { Label } from "./ContractsDetailsModal";
import { InputWrapper } from "./AddContractModal";
import { TranslatableString } from "../../model/Product";

const Warning = styled.div`
  margin: 0 6px;
  color: red;
  font-size: 12pt;
`;

const GoodStatus = styled.div`
  margin: 0 6px;
  color: #11be0b;
  font-size: 12pt;
`;

const WrapperDivider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const PaymentTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 24px;
  border-radius: 4px;
  margin: 6px 0 12px 0;
  box-shadow: 0 0 4px 0 #444440;
  position: relative;
`;

const PaymentRow = styled.div`
  width: 100%;
  margin: 6px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${({ header }) => header && css`
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #444440;
  `};
`;

const Name = styled.div`
  min-width: 230px;
  padding: 3px 6px;
  margin: 0 6px;
`;

const Calculated = styled.div`
  min-width: 120px;
  padding: 3px 6px;
  margin: 0 6px;
`;

const Counted = styled.div`
  padding: 3px 6px;
  margin: 3px 6px;
  text-align: right;
`;

const LastCOP = styled.div`
  width: 100%;
  margin: 0 0 6px 0;
  padding: 0 24px;
`;

const COPWrapper = styled.div`
  width: 75%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 12px;
  position: relative;

  ${({ loading }) => loading && css`
    &::after {
      content: ""; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f3f3f3;
      opacity: .7;
      border-radius: 7px;
    }
  `};
`;

const HistoryWrapper = styled.div`
  width: 35%;
  height: 100%;
  overflow-y: auto;
  padding: 24px 12px;
`;

const HistoryScroll = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const COP = styled.div`
  width: 100%;
  padding: 6px;
  margin: 6px 0;
  border-radius: 4px;
  border: 1px solid #b4b4b4;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const COPRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ margin }) => margin ?? "0"};
  font-size: ${({ font_size }) => font_size ?? "inherit"};
`;

const COPPayments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ margin }) => margin ?? "0"}
`;

const LoadMore = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const COPDate = styled.div`
  width: fit-content;
`;

const COPName = styled.div`
  margin-left: auto;
  justify-self: flex-end;
  text-align: right;
`;

export default class CloseOfPlayModal extends Component {
  state: {
    showModal: boolean,
    language: number,
    orderPayments: OrderPayment[],
    allOrders: Order[],
    orders: Order[],
    counted: ShiftControlCountedPayments[],
    lastCOPDate: Date,
    loaded: boolean,
    cops: ShiftControl[],
    maxShiftControlPages: number,
    mergeSzepCardsToCredit: boolean,
    shiftControlParameters: ShiftControlExtraParameter[],
    shiftControlParametersSaved: ShiftControlExtraParameterSaved[],
  } = {
    showModal: false,
    language: ContextSystem.language,
    orderPayments: [],
    allOrders: [],
    orders: [],
    counted: [],
    lastCOPDate: undefined,
    loaded: false,
    cops: [],
    maxShiftControlPages: 0,
    mergeSzepCardsToCredit: false,
    shiftControlParameters: ContextSystem.shiftControlParameters.filter(scep => scep.shopID === ContextSystem.selectedShop?.id),
    shiftControlParametersSaved: [],
  };

  loadContext() {
    let mergeSzepCardsToCredit: boolean = window.localStorage.getItem("mergeSzepCardsToCredit") === "true";

    this.setState({
      language: ContextSystem.language,
      cops: ContextSystem.shiftControls.filter(sc => sc.shopID === ContextSystem.selectedShop?.id),
      maxShiftControlPages: ContextSystem.maxShiftControlPages,
      allOrders: ContextSystem.orders.filter(o => o.partnerID === ContextSystem.selectedShop?.id),
      mergeSzepCardsToCredit,
      shiftControlParameters: ContextSystem.shiftControlParameters.filter(scep => scep.shopID === ContextSystem.selectedShop?.id),
    });
  }

  loading: boolean = false;
  loaded: boolean = false;

  loadCurrent() {
    if (this.loading || this.loaded)
      return;
    this.loading = true;
    ShiftControlsAPI.loadLastShiftControl(res => {
      this.loading = false;
      this.loaded = true;
      this.setState({ loaded: true });

      if (res.error !== ErrorMessage.OK)
        return;

      ContextSystem.mergeOrders(res.orders, res.products, res.cities, res.zipCodes);
      ContextSystem.mergeTableReservations(res.tableReservations);
      ContextSystem.mergeBlueprintElements(res.tables);

      this.setState({
        orderPayments: res.orderPayments,
        orders: res.orders,
        lastCOPDate: res.date,
        loaded: true,
      });
    });
  }

  componentDidMount() {
    this.loadContext();
    EventSystem.subscribe(EventSystem.events.open_close_of_pay_modal, () => {
      this.setState({ showModal: true, loaded: false });
      this.loading = false;
      this.loaded = false;
      this.loadCurrent();
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, shiftControls, orders, shiftControlParameters }) => {
      if (language !== undefined || shiftControls !== undefined || orders !== undefined || shiftControlParameters !== undefined)
        this.loadContext();
    });
  }

  saving: boolean = false;

  save(): void {
    if (this.saving)
      return;
    this.saving = true;

    let { orderPaymentsSum, ordersCount } = this.calculateNumbers();

    if (!this.state.counted || Object.keys(orderPaymentsSum) > Object.keys(ordersCount)) {
      toast(Language.getName(Names.PleaseFillOutTheCountedPart));
      this.saving = false;
      return;
    }

    ShiftControlsAPI.saveShiftControl(this.state.counted, this.state.shiftControlParametersSaved,res => {
      this.saving = false;
      if (res.error !== ErrorMessage.OK)
        return;

      this.setState({
        counted: [],
        lastCOPDate: new Date(),
        orderPayments: [],
        orders: [],
        showModal: false,
        shiftControlParametersSaved: []
      });
      toast(Language.getName(Names.Saved));
    });
  }

  changeExtraParameterValue(value: string, scepID: number) {
    let newSceps: ShiftControlExtraParameterSaved[] = [];
    let found: boolean = false;

    for (let sceps: ShiftControlExtraParameterSaved of this.state.shiftControlParametersSaved) {
      if (sceps.scepID === scepID) {
        sceps.value = value;
        found = true;
      }
      newSceps.push({ ...sceps });
    }

    if (!found)
      newSceps.push({
        id: -1,
        enabled: true,
        value,
        scepID: scepID,
        shiftControlID: -1,
        shopID: ContextSystem.selectedShop?.id ?? -1,
      });

    this.setState({ shiftControlParametersSaved: newSceps });
  }

  changeCounted(value: string, paymentMethod: number): void {
    let v: string = ProductEditor.parsePrice(value);
    let counted: ShiftControlCountedPayments[] = [];
    for (let shcp of this.state.counted) {
      if (shcp.paymentMethod !== paymentMethod)
        counted.push({ ...shcp });
    }
    counted.push({
      id: -1,
      enabled: true,
      paymentMethod: paymentMethod,
      counted: v,
      shiftControlID: -1,
    });
    this.setState({ counted });
  }

  cancel() {
    this.setState({ showModal: false });
  }

  toggleMergeSzepCardToCredit(value: boolean) {
    this.setState({ mergeSzepCardsToCredit: value });
    window.localStorage.setItem("mergeSzepCardsToCredit", value ? "true" : "false");
  }

  render() {
    let { orderPaymentsSum, orderPaymentsSum2, ordersCount, ordersSum, countedSum } = this.calculateNumbers();

    let lastCOPDate: string = Language.getName(Names.Never);
    if (this.state.lastCOPDate) {
      lastCOPDate = this.state.lastCOPDate.toLocaleTimeString();
      if (new Date().toDateString() !== this.state.lastCOPDate.toDateString()) //not today
        lastCOPDate = this.state.lastCOPDate.toLocaleDateString() + " " + lastCOPDate;
    }

    return (
      <Modal show={this.state.showModal} size={"full_scroll"}
             onEscapeKeyDown={() => this.cancel()}>
        <Modal.Body scroll={true} padding={0}>
          {this.state.showModal &&
            <WrapperDivider>
              <COPWrapper loading={this.state.loaded === false}>
                <LastCOP>
                  {Language.getName(Names.LastCOP) + ": " + lastCOPDate}<br />
                  {Language.getName(Names.Orders) + ": " + Object.keys(ordersCount).length + " " + Language.getName(
                    Names.pcs)}<br />
                  {Language.getName(Names.InventoryBased) + ": " + ordersSum.toLocaleString() + " Ft"}<br />
                  <InputWrapper flex_direction={"row"} width={"100%"}>
                    <Label>
                      {Language.getName(Names.MergeSzepCardsToCredit)}
                    </Label>
                    <ToggleButton
                      inactiveLabel={<FaTimes />}
                      activeLabel={<FaCheck />}
                      value={this.state.mergeSzepCardsToCredit}
                      onToggle={value => this.toggleMergeSzepCardToCredit(!value)}
                      style={{ marginLeft: "6px" }}
                    />
                  </InputWrapper>
                </LastCOP>
                <PaymentTable>
                  <PaymentRow header={true}>
                    <Name>{Language.getName(Names.PaymentMethod)}</Name>
                    <Calculated>{Language.getName(Names.CollectedSum)}</Calculated>
                    <Counted>{Language.getName(Names.CountedSum)}</Counted>
                  </PaymentRow>
                  {AllPaymentMethod.map((pp, i) => {
                    if (
                      pp.type === PaymentMethodTypes.ANY
                      || !pp.countries.includes(ContextSystem.selectedShop?.countryID)
                      || pp.type === PaymentMethodTypes.CASH_BIG_BILLS
                      || !ContextSystem.selectedShop?.paymentMethods?.find(
                        apm => apm.paymentID === pp.type && apm.active && apm.enabled)
                    )
                      return <React.Fragment key={i} />;

                    if (this.state.mergeSzepCardsToCredit && [
                      PaymentMethodTypes.LOCAL_SZEP_CARD_MKB, PaymentMethodTypes.LOCAL_SZEP_CARD_OTP, PaymentMethodTypes.LOCAL_SZEP_CARD_KH, PaymentMethodTypes.ONLINE_SZEP_OTP,
                      PaymentMethodTypes.ONLINE_SZEP_KH, PaymentMethodTypes.ONLINE_SZEP_MKB, PaymentMethodTypes.COD_SZEP_CARD_OTP, PaymentMethodTypes.COD_SZEP_CARD_KH,
                      PaymentMethodTypes.COD_SZEP_CARD_MKB,
                    ].includes(pp.type)) {
                      return <React.Fragment key={i} />;
                    }

                    let pm = pp.type;
                    let countedS: string = this.state.counted.find(c => c.paymentMethod === pm)?.counted;
                    let calculated: number = orderPaymentsSum[pm + ""] ?? 0;
                    return (
                      <PaymentRow key={i}>
                        <Name>{PaymentMethods.getName(pm)}: </Name>
                        <Calculated>{calculated.toLocaleString()} Ft</Calculated>
                        <Counted>
                          <Input
                            margin={"0"}
                            value={countedS}
                            type="text"
                            placeholder={"0"}
                            onChange={e => this.changeCounted(e.target.value, pm)}
                            width={"100px"}
                          />
                          {" Ft"}
                        </Counted>
                        {(!countedS || parseFloat(countedS) < calculated) &&
                          <Warning><AiOutlineExclamationCircle /></Warning>
                        }
                      </PaymentRow>
                    );
                  })}
                  <PaymentRow>
                    <Name><b>{Language.getName(Names.Total2)}: </b></Name>
                    <Calculated>{orderPaymentsSum2.toLocaleString()} Ft</Calculated>
                    <Calculated>{countedSum.toLocaleString()} Ft</Calculated>
                    {countedSum < orderPaymentsSum2 &&
                      <Warning><AiOutlineExclamationCircle /></Warning>
                    }
                  </PaymentRow>
                  {this.state.shiftControlParameters.map((scep, i) => {
                    let sceps: ShiftControlExtraParameterSaved = this.state.shiftControlParametersSaved.find(a => a.scepID === scep.id);
                    let text = sceps?.value ?? "";
                    return (
                      <PaymentRow key={i}>
                        <Name>{TranslatableString.get(scep.name)}: </Name>
                        <Calculated />
                        <Counted>
                          <Input
                            margin={"0"}
                            value={text}
                            type="text"
                            placeholder={""}
                            onChange={e => this.changeExtraParameterValue(e.target.value, scep.id)}
                            width={"100px"}
                          />
                        </Counted>
                      </PaymentRow>
                    );
                  })}
                </PaymentTable>
              </COPWrapper>
              <HistoryWrapper>
                <HistoryScroll>
                  <div>{Language.getName(Names.COPHistory)}</div>
                  {this.state.cops.map((sc: ShiftControl, i: number) => {
                    if (!sc)
                      return <React.Fragment key={i} />;

                    let shopProfile: ShopProfile = ContextSystem.selectedShop?.employees?.find(
                      e => e.id === sc?.requestShopProfileID);
                    let inventoryBasedCashflow: number = parseFloat(sc.inventoryBasedCashflow);
                    let orderPaymentsSum: { [keys: number]: number } = {};

                    sc.orderPaymentIDs.forEach(opid => {
                      for (let order: Order of this.state.allOrders) {
                        let orderPayment: OrderPayment = order.payments.find(op => op.id === opid.orderPaymentID);
                        if (!orderPayment)
                          continue;
                        if (!orderPaymentsSum[orderPayment.paymentType])
                          orderPaymentsSum[orderPayment.paymentType] = 0;
                        orderPaymentsSum[orderPayment.paymentType] += orderPayment.amount - orderPayment.creditedAmount;
                        return;
                      }
                    });

                    return (
                      <COP key={i}>
                        <COPRow margin={"0 0 6px 0"}>
                          <COPDate><b>{sc.dateTime?.toLocaleDateTime()}</b></COPDate>
                          <COPName>{shopProfile?.firstName} {shopProfile?.lastName}</COPName>
                        </COPRow>
                        <COPRow>
                          <COPDate>{Language.getName(
                            Names.InventoryBased)}: {inventoryBasedCashflow.toLocaleString()} Ft</COPDate>
                        </COPRow>
                        <COPPayments margin={"6px 0"}>
                          {Object.keys(orderPaymentsSum).map((pm, j) => {
                            let counted: number = sc?.countedPayments?.find(
                              cp => cp.paymentMethod === parseInt(pm))?.counted ?? 0;
                            let amount: number = orderPaymentsSum[parseInt(pm)] ?? 0;
                            return (
                              <React.Fragment key={j}>
                                <COPRow font_size={"10pt"}>
                                  <div>{PaymentMethods.getName(parseInt(pm))}</div>
                                </COPRow>
                                <COPRow font_size={"10pt"}>
                                  <BsDot />
                                  {counted?.toLocaleString()}
                                  {" Ft / "}
                                  {amount?.toLocaleString()}
                                  {" Ft"}
                                  {counted < amount &&
                                    <Warning><AiOutlineExclamationCircle /></Warning>
                                  }
                                  {counted >= amount &&
                                    <GoodStatus><BsCheck2All /></GoodStatus>
                                  }
                                </COPRow>
                              </React.Fragment>
                            );
                          })}
                        </COPPayments>
                        <COPPayments>
                          {sc.parameters.map((sceps, j) => {
                            let scep: ShiftControlExtraParameter = this.state.shiftControlParameters.find(a => a.id === sceps.scepID);
                            if (!scep)
                              return <React.Fragment key={j} />;

                            return (
                              <React.Fragment key={j}>
                                <COPRow font_size={"10pt"}>
                                  <div>{TranslatableString.get(scep.name)}</div>
                                </COPRow>
                                <COPRow font_size={"10pt"}>
                                  <BsDot />
                                  {sceps.value}
                                </COPRow>
                              </React.Fragment>
                            );
                          })}
                        </COPPayments>
                      </COP>
                    );
                  })}
                  {Math.ceil(
                      this.state.cops.length / ContextSystem.shiftControlPageSize) < this.state.maxShiftControlPages &&
                    <LoadMore onClick={() => ContextSystem.getNextShiftControls()}>
                      <FiMoreHorizontal />
                    </LoadMore>
                  }
                </HistoryScroll>
              </HistoryWrapper>
            </WrapperDivider>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.cancel()}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          {this.state.loaded === false &&
            <Loader visible={true} type="ThreeDots" color="rgb(80,80,80)"
                    height={20} width={100} />
          }
          <Button onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  calculateNumbers() {
    let orderPaymentsSum: { [keys: number]: number } = {};
    let orderPaymentsSum2: number = 0;
    let ordersCount: { [keys: number]: number } = {};
    let ordersSum: number = 0;
    let countedSum: number = 0;

    this.state.counted.forEach(c => countedSum += isNaN(parseFloat(c.counted)) ? 0 : parseFloat(c.counted));

    for (let o: Order of this.state.orders) {
      if (!this.state.lastCOPDate || o.date > this.state.lastCOPDate)
        ordersSum += o.orderTotalPrice;
    }

    for (let op: OrderPayment of this.state.orderPayments) {
      if (!ordersCount[op.orderID])
        ordersCount[op.orderID] = 1;
      else
        ordersCount[op.orderID] += 1;

      let paymentType: number = op.paymentType;
      if (paymentType === PaymentMethodTypes.CASH_BIG_BILLS)
        paymentType = PaymentMethodTypes.CASH;

      if (this.state.mergeSzepCardsToCredit && [PaymentMethodTypes.COD_SZEP_CARD_KH, PaymentMethodTypes.COD_SZEP_CARD_MKB, PaymentMethodTypes.COD_SZEP_CARD_OTP].includes(paymentType))
        paymentType = PaymentMethodTypes.COD_CREDIT_CARD;
      if (this.state.mergeSzepCardsToCredit && [PaymentMethodTypes.ONLINE_SZEP_KH, PaymentMethodTypes.ONLINE_SZEP_MKB, PaymentMethodTypes.ONLINE_SZEP_MKB].includes(paymentType))
        paymentType = PaymentMethodTypes.ONLINE_CREDIT_CARD;
      if (this.state.mergeSzepCardsToCredit && [PaymentMethodTypes.LOCAL_SZEP_CARD_KH, PaymentMethodTypes.LOCAL_SZEP_CARD_OTP, PaymentMethodTypes.LOCAL_SZEP_CARD_MKB].includes(paymentType))
        paymentType = PaymentMethodTypes.TAKEAWAY_CREDIT_CARD;

      if (!orderPaymentsSum[paymentType])
        orderPaymentsSum[paymentType] = 0;
      orderPaymentsSum[paymentType] += op.amount - op.creditedAmount;
      orderPaymentsSum2 += op.amount - op.creditedAmount;
    }
    return { orderPaymentsSum, orderPaymentsSum2, ordersCount, ordersSum, countedSum };
  }
}
