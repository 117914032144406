import React, { Component } from "react";
import styled, { css } from "styled-components";
import type { OrderProductSorted } from "../../model/Order";
import { Order, OrderPayment, OrderSource, OrderState, OrderUsedCouponID, TableReservation } from "../../model/Order";
import { Element } from "../../model/BluePrint";
import {
  AiFillShop,
  AiOutlineEdit,
  BsBagCheckFill,
  BsBagPlus,
  FaBan,
  FaCaretDown,
  FaCaretRight,
  FaCheck,
  FaHandHoldingHeart,
  FaPhone,
  FaPrint,
  FaShippingFast,
  FaUserCheck,
  FaUserTimes,
  GiCardPickup,
  GiReceiveMoney,
  IoTimerOutline,
  MdDirectionsBike,
  MdOutlineDeliveryDining,
  MdOutlineDoneAll
} from "react-icons/all";
import blundeeLogo from "../../assets/blundee_logos/app_icon.png";
import woltLogo from "../../assets/logos/wolt_logo.png";
import pandaLogo from "../../assets/logos/foodpanda_logo.png";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { print } from "../../utils/OrderPrinter";
import Orders, { ProductType } from "./Orders";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { Product, TranslatableString } from "../../model/Product";
import CartCalc from "../../utils/CartCalc";
import { ShippingMethods } from "../../model/ShippingPrice";
import { CouponModelTypes } from "../../model/Coupon";
import { ShopProfile, ShopProfileTypes } from "../../model/ShopProfile";
import EventSystem from "../../utils/EventSystem";
import { PaymentMethods } from "../../model/PaymentMethodSetting";

export const OrderWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 3px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 3px 2px gray;
`;
export const OrderDiv = styled.div`
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  background-color: white;
  padding: 6px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover, &:active {
    cursor: pointer;
  }

  & > span {
    overflow: hidden;
  }

  & > hr {
    display: block;
    width: 100%;
    height: 1px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 2px 0;
  }

  i, svg {
    font-size: 20pt;
  }

  ${({ status }) => status === OrderState.NEW && css`
    border: 2px solid cornflowerblue;
  `};

  ${({ status }) => status === OrderState.CONFIRMED && css`
    border: 2px solid orange;
  `};

  ${({ status }) => status === OrderState.READY && css`
    border: 2px solid green;
  `};

  ${({ status }) => status === OrderState.DECLINED && css`
    border: 2px solid #b40a51;
  `};

  ${({ status }) => status === OrderState.DELETED && css`
    border: 2px solid #d52b73;
  `};

  ${({ status }) => status === OrderState.FAILED_TRANS && css`
    border: 2px solid red;
  `};

  ${({ status }) => status === OrderState.DONE && css`
    border: 2px solid green;
  `};

  ${({ status }) => status === OrderState.SERVED && css`
    border: 2px solid #ff5000;
  `};

  ${({ selected }) => selected && css`
    border-radius: 5px 5px 0 0;
  `};
`;
export const OrderComment = styled.span`
  width: 40%;
  min-width: 330px;
`;
export const OrderID = styled.span`
  background-color: #efefef;
  min-width: 180px;
  width: calc(25% - 6px);
  padding: 0 8px;
  margin-right: 6px;
  border-radius: 4px;
  font-size: 17pt;
  color: #454545;

  & > span {
    padding-left: 15px;
    font-size: 10pt;
  }
`;
export const OrderName = styled.span`
  font-size: 10pt;
  margin-left: 3px;
`;
export const OrderDate = styled.span`
  font-size: 10pt;
  font-family: "Consolas", sans-serif;
  min-width: 130px;
  user-select: none;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & > div:nth-of-type(2) {
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #ffef42;
    color: #444440;
    font-size: 10pt;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg {
      margin-right: 6px;
      font-size: 13pt;
    }
  }
`;
export const OrderPhone = styled.span`
  width: 20%;
  font-size: 10pt;

  svg {
    font-size: 14pt;
  }
`;
export const OrderSourceLogo = styled.span`
  width: 22px;
  margin: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const OrderStatus = styled.span`
  min-width: 150px;
  width: 15%;
  text-align: right;
  font-weight: bold;
`;
export const TableNumberWrapper = styled.div`
  margin-right: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > svg {
    margin-right: 3px;
    font-size: 15pt;
  }
`;
export const TableNumber = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  margin: 4px 2px;
  box-shadow: 0 0 3px 1px black;

  cursor: pointer;
  transition: background-color ease-in-out 300ms;

  font-size: 9pt;

  &:hover {
    background-color: #efefef;
  }
`;
export const OrderShippingMethod = styled.span`
  min-width: 220px;
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 10pt;

  & > svg {
    margin-right: 3px;
    font-size: 15pt;
  }
`;
export const OrderPaymentMethod = styled.span`
  min-width: 115px;
  text-align: right;
  width: 20%;
`;
export const OrderAddress = styled.span`
  width: 60%;
  min-width: 200px;
`;
export const OrderActionsWrapper = styled.span`
  margin-left: auto;
  min-width: 330px;
  justify-self: flex-end;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 2px 5px;
  flex-wrap: wrap;

  &:hover, &:active {
    cursor: initial;
  }

  & > div {
    padding: 3px 5px;
    border-radius: 5px;
    min-width: 75px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover, &:active {
      cursor: pointer;
      background-color: whitesmoke;
    }

    & > i {
    }

    & > span {
      font-size: 10pt;
    }

    & > svg {
      font-size: 14pt;
    }
  }
`;
export const OrderDetails = styled.div`
  width: 100%;
  min-height: 50px;
  border-radius: 0 0 15px 15px;
  background-color: #f5f5f5;
  padding: 2px 10px;
  ${({ selected }) => !selected && css`
    display: none;
  `}
`;
export const ProductDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #aaaaaa;

  padding: 6px 5%;

  & > div:nth-child(1) {
    width: 55%;
  }

  & > div:nth-child(2) {
    width: 13%;
    margin-left: 5px;
  }

  & > div:nth-child(3) {
    width: 15%;
    margin-left: 5px;
  }

  & > div:nth-child(4) {
    width: 15%;
    margin-left: 5px;
  }
`;

export const OrderProductNoteWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 3px 3px 6px;
  font-weight: bold;
  font-style: italic;
  font-size: 9pt;
`;

export const Total = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #aaaaaa;
  margin: 6px 0;

  ${({ printing }) => printing === true && css`
    border: 1px solid #aaaaaa;
    font-family: "Yu Gothic", sans-serif;
  `}
`;
export const MenuHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px 10px 24px;
  margin-bottom: 8px;

  ${({ printing, borderbottom }) => printing === true && css`
    padding: 3px;
    width: calc(100% - 6px);
    ${borderbottom && css`
      border-bottom: 1px solid #aaaaaa;
      border-bottom-style: dashed;
    `}
    font-family: "Yu Gothic", sans-serif;
    flex-wrap: wrap;
  `};

  & > div:nth-of-type(1) {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 0 0;
    padding: 4px;

    &:hover {
      cursor: pointer;
    }

    & > div:nth-child(1) {
      width: 55%;
      font-weight: bold;
    }

    & > div:nth-child(2) {
      width: 13%;
    }

    & > div:nth-child(3) {
      width: 15%;
    }

    & > div:nth-child(4) {
      width: 15%;
    }
  }
`;
export const MenuBody = styled.div`
  width: 92%;
  padding-left: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > span {
    position: relative;
    right: 4%;
  }
`;
export const MenuProduct = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;

  & > span:first-child {
    width: 80%;
  }

  & > span:nth-child(2) {
    width: 20%;
    text-align: right;
    padding: 0 5px;
  }

  ${({ printing }) => printing === true && css`
    font-family: "Yu Gothic", sans-serif;
    border: none;

    & > span:first-child {
      width: 100%;
    }

    & > span:nth-child(2) {
      width: 100%;
    }
  `}
`;

export type Props = {
  order: Order,
  showTotal: boolean,
  showActions: boolean,
  onTableClick: (res: TableReservation, table: Element)=>{},
}

export type State = {
  showTotal: boolean,
  showActions: boolean,
  selected: boolean,
  order: Order,
  menuOpened: {},
  profile: ShopProfile,
}

export default class OrderComponent extends Component<Props> {
  state: State = {
    showTotal: true,
    showActions: true,
    selected: false,
    order: undefined,
    menuOpened: {},
    profile: ContextSystem.profile
  };

  loadProps() {
    if (this.state.order !== this.props.order)
      this.setState({ order: this.props.order });
    if (this.state.showTotal !== this.props.showTotal)
      this.setState({ showTotal: this.props.showTotal });
    if (this.state.showActions !== this.props.showActions)
      this.setState({ showActions: this.props.showActions });
    if (this.state.order !== this.props.order)
      this.setState({ order: this.props.order });
  }

  toggleMenuOpen(index) {
    let menuOpened = { ...this.state.menuOpened };
    menuOpened[index] = menuOpened[index] !== true;
    this.setState({ menuOpened });
  }

  isMenuOpened(index) {
    return this.state.menuOpened[index];
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot) {
    this.loadProps();
  }

  eventIDs: number[] = [];

  componentDidMount() {
    this.loadProps();

    this.eventIDs = [];
    let eid: number = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ profile }) => {
      if (profile !== undefined)
        this.setState({ profile: ContextSystem.profile });
    });
    this.eventIDs.push(eid);
  }

  componentWillUnmount() {
    this.eventIDs.forEach(eid => EventSystem.unsubscribe(eid));
  }

  handleClick() {
    this.setState({ selected: !this.state.selected });
  }

  handleTableClick(e: Event, tableReservation: TableReservation, table: Element) {
    e.stopPropagation();
    if (this.props.onTableClick)
      this.props.onTableClick(tableReservation, table);
  }

  render() {
    let order: Order = this.state.order;
    if (!order)
      return <></>;

    let usedCoupons: OrderUsedCouponID[] = [];
    order.usedCoupons.forEach(uc => usedCoupons.push(uc));
    for (let sub of order.subOrders)
      sub.usedCoupons.forEach(uc => {
        usedCoupons.push(uc);
      });

    let cart: Product[] = CartCalc.createCartFromOrder(order);
    let recreatedOrder: Order = CartCalc.createOrderFromState(cart, [], undefined, undefined, [], undefined,
      order.comment
    );

    if (!recreatedOrder)
      return <></>;

    let orderProducts: OrderProductSorted[] = Orders.sortProducts(recreatedOrder?.productList);

    let state: OrderState = order.lastState;
    let tableReservation: TableReservation = undefined;
    if (order.tableReservationID > 0 && order.shippingMethod === ShippingMethods.AT_PLACE)
      tableReservation = ContextSystem.tableReservations.find(tr => tr.id === order.tableReservationID);
    let table: Element = tableReservation === undefined ? undefined : ContextSystem.elements.find(
      e => e.id === tableReservation.tableID);

    return (
      <OrderWrapper>
        <OrderDiv status={state.status} selected={this.state.selected}
                  onClick={() => this.handleClick()}>
          <OrderID>
            <b>
              {order?.dailyNumber}
              <OrderName>
                {order?.profile?.firstName}
              </OrderName>
            </b>
            <span>#{order?.number}</span>
          </OrderID>
          {order?.profile?.tel &&
            <OrderPhone onClick={(e) => e.stopPropagation()}>
              <a href={"tel:" + order?.profile?.tel}>
                <FaPhone />
                {order.profile.tel}
              </a>
            </OrderPhone>
          }
          <OrderDate>
            <div>{new Date(order.date).toHunFormatMinutes()}</div>
            {order.scheduleDate &&
              <div>
                <IoTimerOutline />
                {new Date(order.scheduleDate).toHunFormatMinutes()}
              </div>
            }
          </OrderDate>
          <OrderSourceLogo>
            {(
                order.sourceID === OrderSource.ENNIAKAROK_POS ||
                order.sourceID === OrderSource.ENNIAKAROK_ONLINE ||
                order.sourceID === OrderSource.ENNIAKAROK_BLUNDEE
              )
              &&
              <img src={blundeeLogo} alt={"Blundee"} height={22} />
            }
            {order.sourceID === OrderSource.WOLT &&
              <img src={woltLogo} alt={"Wolt"} height={15} />
            }
            {order.sourceID === OrderSource.FOODPANDA &&
              <img src={pandaLogo} alt={"Foodpanda"} height={22} />
            }
          </OrderSourceLogo>
          <OrderStatus>{Orders.getStatusString(state.status)}</OrderStatus>

          <hr />

          {(order.shippingMethod === ShippingMethods.DELIVERY || order.shippingMethod === ShippingMethods.VENDOR_DELIVERY) && (order.address === undefined || order.address === null) &&
            <OrderAddress>{Language.getName(Names.AddressCantBeLoaded)}.</OrderAddress>
          }
          {(order.shippingMethod === ShippingMethods.DELIVERY || order.shippingMethod === ShippingMethods.VENDOR_DELIVERY) && order.address !== undefined && order.address !== null && (
            <OrderAddress>
              {order.address.street}, {TranslatableString.get(
              ContextSystem.getCityByID(order.address.cityID)?.name)}{" "}
              {ContextSystem.getZipCode(order.address.zipCodeID)?.zipcode}
            </OrderAddress>
          )}
          <OrderShippingMethod>
            {order.shippingMethod === ShippingMethods.AT_PLACE &&
              <TableNumberWrapper>
                <AiFillShop />
                {table &&
                  <TableNumber onClick={e => this.handleTableClick(e, tableReservation, table)}>
                    {table.name}
                  </TableNumber>
                }
              </TableNumberWrapper>
            }
            {order.shippingMethod === ShippingMethods.DELIVERY &&
              <MdOutlineDeliveryDining />
            }
            {order.shippingMethod === ShippingMethods.VENDOR_DELIVERY &&
              <MdDirectionsBike />
            }
            {order.shippingMethod === ShippingMethods.PICKUP &&
              <GiCardPickup />
            }
            {Orders.getShippingMethodString(order.shippingMethod)}
          </OrderShippingMethod>
          <OrderPaymentMethod>{PaymentMethods.getName(order.paymentMethod)}</OrderPaymentMethod>

          <hr />

          <OrderComment>
            {order?.comment && order?.comment.length > 0 &&
              <>
                <b>{Language.getName(Names.Comment)}:</b>
                <br />
                {order.comment}
              </>
            }
          </OrderComment>

          <OrderActionsWrapper onClick={(e) => e.stopPropagation()}>
            {state.finished &&
              <>
                {/*{(state.status === OrderState.DELETED || state.status === OrderState.DECLINED) &&*/}
                {/*<div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.NEW)}>*/}
                {/*  <FaUndoAlt/>*/}
                {/*  <span>{Language.getName(Names.Restore)}</span>*/}
                {/*</div>*/}
                {/*}*/}
              </>
            }
            {state.status === OrderState.SERVED && ContextSystem.selectedShop.cashflowReportAvailable && !Order.isPaidFully(
                order) &&
              <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.DONE)}>
                <MdOutlineDoneAll />
                <span>{Language.getName(Names.Done)}</span>
              </div>
            }
            {state.status === OrderState.NEW &&
              <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.DECLINED)}>
                <FaBan />
                <span>{Language.getName(Names.Decline)}</span>
              </div>
            }
            {ShopProfile.isPermitted(this.state.profile, order?.partnerID ?? -1, ShopProfileTypes.STORNO_ORDER) && order.sourceID === OrderSource.ENNIAKAROK_POS &&
              [OrderState.NEW, OrderState.CONFIRMED, OrderState.PREPARING, OrderState.READY].includes(state.status) &&
              <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.DELETED)}>
                <FaBan />
                <span>{Language.getName(Names.Storno)}</span>
              </div>
            }
            <div onClick={() => OrdersAPI.payOrder(order, undefined, true)}>
              <GiReceiveMoney />
              <span>{Language.getName(Names.Payment)}</span>
            </div>

            {state.status === OrderState.NEW && (
              <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.CONFIRMED)}>
                <FaCheck />
                <span>{Language.getName(Names.Confirm)}</span>
              </div>
            )}
            {state.status === OrderState.CONFIRMED && (order.shippingMethod === ShippingMethods.PICKUP || order.shippingMethod === ShippingMethods.AT_PLACE) &&
              <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.PREPARING)}>
                <BsBagPlus />
                <span>{Language.getName(Names.Preparing)}</span>
              </div>
            }
            {(state.status === OrderState.CONFIRMED || state.status === OrderState.PREPARING) && (
              <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.READY)}>
                {(order.shippingMethod === ShippingMethods.PICKUP || order.shippingMethod === ShippingMethods.AT_PLACE) &&
                  <>
                    <BsBagCheckFill />
                    <span>{Language.getName(Names.Done)}</span>
                  </>
                }
                {(order.shippingMethod === ShippingMethods.DELIVERY || order.shippingMethod === ShippingMethods.VENDOR_DELIVERY) &&
                  <>
                    <FaShippingFast />
                    <span>{Language.getName(Names.AtShipping)}</span>
                  </>
                }
              </div>
            )}
            {state.status === OrderState.READY && (
              <>
                {order.shippingMethod === ShippingMethods.AT_PLACE &&
                  <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.SERVED)}>
                    <FaHandHoldingHeart />
                    <span>{Language.getName(Names.Served)}</span>
                  </div>
                }
                <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.DONE)}>
                  <FaUserCheck />
                  {order.shippingMethod === ShippingMethods.PICKUP &&
                    <span>{Language.getName(Names.Accepted)}</span>}
                  {order.shippingMethod === ShippingMethods.DELIVERY &&
                    <span>{Language.getName(Names.Delivered)}</span>}
                  {order.shippingMethod === ShippingMethods.AT_PLACE &&
                    <span>{Language.getName(Names.Paid)}</span>}
                </div>
                <div onClick={() => OrdersAPI.modifyOrderState(order, OrderState.FAILED_TRANS)}>
                  <FaUserTimes />
                  <span>{Language.getName(Names.NotAccepted)}</span>
                </div>
              </>
            )}
            <div onClick={() => print([order], tableReservation, table)}>
              <FaPrint />
              <span>{Language.getName(Names.Print)}</span>
            </div>
            <div onClick={() => OrdersAPI.modifyOrder(order)}>
              <AiOutlineEdit />
              <span>{Language.getName(Names.Modify)}</span>
            </div>
          </OrderActionsWrapper>
        </OrderDiv>
        {this.state.selected &&
          <OrderDetails selected={this.state.selected}>
            {orderProducts.map((orderProduct, i) => {
              let versionName = "";
              let product = Orders.getProductByID(ContextSystem.products, orderProduct.productID);
              if (!product)
                return <React.Fragment key={i} />;
              let v = Orders.getVersionByID(product.versions, orderProduct.versionID);
              if (product.versions.length > 1 && v)
                versionName = "(" + TranslatableString.get(v.name) + ")";

              if (orderProduct.type && orderProduct.type === ProductType.PRODUCT) {
                return (
                  <ProductDiv key={i}>
                    <div>
                      {TranslatableString.get(orderProduct.name)} {versionName}
                      {orderProduct.extras && orderProduct.extras.length > 0 && (
                        <span>
                        <br />(
                          {orderProduct.extras.map((extra, k) => {
                            return (
                              <span key={k}>
                              {extra.qty > 1 ? extra.qty + Language.getName(Names.PCS) : ""} {TranslatableString.get(
                                extra.name)} {k < orderProduct.extras.length - 1 ? "," : ""}
                            </span>
                            );
                          })}
                          )
                      </span>
                      )}
                    </div>
                    <div>{orderProduct.qty}{Language.getName(Names.pcs)}</div>
                    <div>
                      {usedCoupons.find((ouid: OrderUsedCouponID) => ouid.modelType === CouponModelTypes.PRODUCT && ouid.modelID === orderProduct.productID && ouid.orderID === orderProduct.orderID)
                        ? (
                          <>
                            {Math.round(orderProduct.originalPrice + orderProduct.originalExtrasPrice).toLocaleString()} ->
                            {Math.round(orderProduct.price + orderProduct.extrasPrice).toLocaleString()}Ft
                          </>
                        )
                        : (
                          <>
                            {Math.round(orderProduct.price + orderProduct.extrasPrice).toLocaleString()}Ft
                          </>
                        )
                      }
                    </div>
                    <div>
                      {Math.round(orderProduct.qty * (orderProduct.price + CartCalc.getOrderProductExtrasPrice(orderProduct, product))).toLocaleString()}
                      Ft
                    </div>
                    {orderProduct.note &&
                      <OrderProductNoteWrapper>
                        {orderProduct.note}
                      </OrderProductNoteWrapper>
                    }
                  </ProductDiv>
                );
              } else if (orderProduct.type && orderProduct.type === ProductType.MENU) {
                return (
                  <Menu key={i}>
                    <MenuHead>
                      <div onClick={() => this.toggleMenuOpen(i)}>
                        <div>
                          {!this.isMenuOpened(i) &&
                            <FaCaretRight onClick={() => this.toggleMenuOpen(i)} />}
                          {this.isMenuOpened(i) &&
                            <FaCaretDown onClick={() => this.toggleMenuOpen(i)} />}
                          {TranslatableString.get(orderProduct.name)} {versionName}
                          {orderProduct.extras && orderProduct.extras.length > 0 && (
                            <span>
                            <br />(
                              {orderProduct.extras.map((extra, k) => {
                                return (
                                  <span key={k}>
                                  {extra.qty > 1
                                    ? extra.qty + Language.getName(Names.PCS)
                                    : ""} {TranslatableString.get(extra.name)} {k < orderProduct.extras.length - 1
                                    ? ","
                                    : ""}
                                </span>
                                );
                              })}
                              )
                          </span>
                          )}
                        </div>
                        <div>{orderProduct.qty}{Language.getName(Names.pcs)}</div>
                        {order.usedCoupons.find(ouid => ouid.modelID === orderProduct.productID && ouid.orderID === orderProduct.orderID) ? (
                          <>
                            {Math.round(orderProduct.originalPrice + orderProduct.originalExtrasPrice)
                              .toLocaleString()} ->
                            {Math.round(orderProduct.price + orderProduct.extrasPrice).toLocaleString()}Ft
                          </>
                        ) : (
                          <>
                            {Math.round(orderProduct.price + orderProduct.extrasPrice).toLocaleString()}Ft
                          </>
                        )}
                        <div>
                          {Math.round(orderProduct.qty * (orderProduct.price + orderProduct.extrasPrice))
                            .toLocaleString()}
                          Ft
                        </div>
                      </div>
                      {orderProduct.note &&
                        <OrderProductNoteWrapper>
                          {orderProduct.note}
                        </OrderProductNoteWrapper>
                      }
                    </MenuHead>
                    {this.isMenuOpened(i) && (
                      <MenuBody>
                        {orderProduct && orderProduct.products &&
                          orderProduct.products.map((subProduct, j) => {
                            let subVersionName = "";
                            let subP = Orders.getProductByID(ContextSystem.products, subProduct.productID);
                            if (!subP) {
                              return (
                                <MenuProduct key={j}>
                                <span>{Language.getName(Names.DeletedProductErrorText)}: {TranslatableString.get(
                                  subProduct.name)}(#{subProduct.productID})</span>
                                </MenuProduct>
                              );
                            }
                            let v = Orders.getVersionByID(subP.versions, subProduct.versionID);
                            if (subP.versions.length > 1 && v)
                              subVersionName = "(" + TranslatableString.get(v.name) + ")";
                            return (
                              <MenuProduct key={j}>
                            <span>
                              {TranslatableString.get(
                                subProduct.name)} {subVersionName}- {orderProduct.qty * subProduct.qty}
                              {Language.getName(Names.PCS)}
                              {subProduct.extras && subProduct.extras.length > 0 && (
                                <span>
                                  <br />(
                                  {subProduct.extras.map((extra, k) => {
                                    return (
                                      <span key={k}>
                                        {extra.qty > 1
                                          ? extra.qty + Language.getName(Names.PCS)
                                          : ""} {TranslatableString.get(extra.name)}{" "}
                                        {k < subProduct.extras.length - 1 ? "," : ""}
                                      </span>
                                    );
                                  })}
                                  )
                                </span>
                              )}
                            </span>
                              </MenuProduct>
                            );
                          })}
                      </MenuBody>
                    )}
                  </Menu>
                );
              }
            })}
            <Total>
              <div>
                {order.shippingMethod !== ShippingMethods.PICKUP &&
                  <span>{Language.getName(Names.Shipping)}: {Math.round(order.shippingPrice).toLocaleString()}Ft</span>
                }
                {OrderPayment.getPaidSum(order.payments) - order.orderTotalPrice > 0 &&
                  <span>{Language.getName(Names.Tip)}: {(OrderPayment.getPaidSum(order.payments) - order.orderTotalPrice).toLocaleString()}Ft</span>
                }
                <span>{Language.getName(Names.Total)}: {Math.round(Order.totalOrderPrice(order)).toLocaleString()}Ft</span>
              </div>
            </Total>
          </OrderDetails>
        }
      </OrderWrapper>
    );
  }
}
