import React, { Component } from "react";
import styled from "styled-components";
import { PageContainer } from "../components/PageContainer";
import { withRouter } from "react-router-dom";
import EventSystem from "../utils/EventSystem";
import ProductEditor from "../components/modals/ProductEditor";
import ImageUploader from "../components/modals/ImageUploader";
import ExtrasSelector from "../components/modals/ExtrasSelector";
import ExtraEditor from "../components/modals/ExtraEditor";
import OrderStateCommentEditor from "../components/modals/OrderStateCommentEditor";
import CategoriesEditor from "../components/modals/CategoriesEditor";
import ContextSystem from "../utils/ContextSystem";
import Config from "../utils/Config";
import ContractsDetailsModal from "../components/modals/ContractsDetailsModal";
import AddContractModal from "../components/modals/AddContractModal";
import ShowContractModal from "../components/modals/ShowContractModal";
import Layout from "../model/Layout";
import ManagerLayout, { DashBoardWrapper } from "../components/home/layout/ManagerLayout";
import DispatcherLayout from "../components/home/layout/DispatcherLayout";
import KitchenLayout from "../components/home/layout/KitchenLayout";
import WaiterLayout from "../components/home/layout/WaiterLayout";
import HelpPage from "../components/home/HelpPage";
import { Shop } from "../model/Shop";
import ExtrasModal from "../components/modals/ExtrasModal";
import SimpleTableEditor from "../components/modals/SimpleTableEditor";
import StandingLayout from "../components/home/layout/StandingLayout";
import ShopProfileEditor from "../components/modals/ShopProfileEditor";
import RawEditor from "../components/modals/RawEditor";
import InwardHandlingModal from "../components/modals/InwardHandlingModal";
import RecipeEditor from "../components/modals/RecipeEditor";
import RecipeQtyEditor from "../components/modals/RecipeQtyEditor";
import OrderCouponSelector from "../components/modals/OrderCouponSelector";
import OrderPaymentEditor from "../components/modals/orderpayment/OrderPaymentEditor";
import OrderEditor from "../components/modals/OrderEditor";
import ProductExtrasSelector from "../components/modals/ProductExtrasSelector";
import TableSelector from "../components/modals/TableSelector";
import NewReservationEditor from "../components/modals/NewReservationEditor";
import NewCustomerEditor from "../components/modals/NewCustomerEditor";
import OrdersModal from "../components/modals/OrdersModal";
import ZoneEditor from "../components/modals/ZoneEditor";
import StockTakingModal from "../components/modals/StockTakingModal";
import { ShopProfile } from "../model/ShopProfile";
import Language, { Names } from "../utils/Language";
import CouponEditor from "../components/modals/CouponEditor";
import PrinterSettingsEditor from "../components/modals/PrinterSettingsEditor";
import StorageEditor from "../components/modals/StorageEditor";
import ManufacturingModal from "../components/modals/ManufacturingModal";
import StackableTimelineModal from "../components/modals/StackableTimelineModal";
import MoveStorageModal from "../components/modals/MoveStorageModal";
import CloseOfPlayModal from "../components/modals/CloseOfPlayModal";
import UpsellRulesModal from "../components/modals/UpsellRulesModal";
import UpsellRuleEditor from "../components/modals/UpsellRuleEditor";
import ViewEditor from "../components/modals/ViewEditor";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

class HomePage extends Component {
  state: {
    loggedIn: boolean,
    shop: Shop,
    mobile: boolean,
    selectedMenu: number,
    selectedLayout: number,
    profile: ShopProfile
  } = {
    loggedIn: ContextSystem.loggedIn,
    shop: ContextSystem.selectedShop,
    mobile: window.innerWidth < 1000,
    selectedMenu: ContextSystem.selectedMenu,
    selectedLayout: ContextSystem.layout,
    profile: ContextSystem.profile,
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    window.addEventListener("resize", () => {
      this.setState({ mobile: window.innerWidth < Config.mobileMaxWidth });
    });

    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ loggedIn, profile, selectedMenu, layout, selectedShop }) => {
        if (loggedIn !== undefined) {
          this.setState({ profile, loggedIn });
          if (loggedIn === false) {
            this.props.history.push("/bejelentkezes");
          }
        }

        if (layout !== undefined)
          this.setState({ selectedLayout: layout });

        if (selectedMenu !== undefined)
          this.setState({ selectedMenu });

        if (profile !== undefined)
          this.setState({ profile });

        if (selectedShop !== undefined)
          this.setState({ shop: selectedShop });
      },
    );
    this.eventIDs.push(eid);
  }

  render() {
    let permission: boolean = false;
    if (this.state.profile && this.state.shop)
      permission = this.state.profile.permissions[this.state.shop.id].includes(this.state.selectedLayout);

    return (
      <PageContainer
        layout={this.state.selectedLayout}
      >
        <iframe
          id="ifmcontentstoprint"
          title="ifmcontentstoprint"
          style={{
            zIndex: "-10000",
            padding: "0px",
            margin: "0px",
            height: "0px",
            width: "0px",
            position: "absolute",
          }}
        />
        {this.state.loggedIn && (
          <Wrapper>
            <OrderEditor />
            <NewReservationEditor />
            <NewCustomerEditor />

            <AddContractModal />
            <ContractsDetailsModal />
            <ShowContractModal />
            <CategoriesEditor />
            <InwardHandlingModal />
            <ManufacturingModal />
            <StockTakingModal />
            <SimpleTableEditor />
            <ZoneEditor />
            <ShopProfileEditor />
            <ProductEditor />
            <ImageUploader />
            <CouponEditor />

            <ProductExtrasSelector />
            <ExtraEditor />
            <ViewEditor />
            <UpsellRuleEditor />
            <ExtrasModal />
            <UpsellRulesModal />
            <ExtrasSelector />
            <OrderStateCommentEditor />
            <RecipeQtyEditor />
            <MoveStorageModal />
            <RecipeEditor />
            <RawEditor />
            <StackableTimelineModal />
            <StorageEditor />
            <OrdersModal />
            <CloseOfPlayModal />
            <OrderCouponSelector />
            <OrderPaymentEditor />
            <PrinterSettingsEditor />
            <TableSelector />

            {this.state.selectedMenu === 7 &&
              <DashBoardWrapper>
                <HelpPage />
              </DashBoardWrapper>
            }

            {this.state.selectedMenu !== 7 &&
              <>
                {permission &&
                  <>
                    {this.state.selectedLayout === Layout.MANAGER && <ManagerLayout />}
                    {this.state.selectedLayout === Layout.KITCHEN && <KitchenLayout />}
                    {this.state.selectedLayout === Layout.DISPATCHER && <DispatcherLayout />}
                    {this.state.selectedLayout === Layout.WAITER && <WaiterLayout />}
                    {this.state.selectedLayout === Layout.STANDING && <StandingLayout />}
                  </>
                }
                {!permission &&
                  <>
                    {Language.getName(Names.PermissionDenied)}
                  </>
                }
              </>
            }
          </Wrapper>
        )}
      </PageContainer>
    );
  }
}

export default withRouter(HomePage);
