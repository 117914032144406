import AbstractModel from "./AbstractModel";

export class Printer extends AbstractModel {
  name: string;
  deviceName: string;
  width: number;
  height: number;
  localMachineID: number;
  partnerID: number;
  kitchen: boolean;
}

export class LocalMachine extends AbstractModel {
  name: string;
  UUID: string;
  shopID: number;
}

export class PrinterSetting extends AbstractModel {
  static INHERIT_PRINTER_ID: number = -2;

  modelID: number;
  printerID: number;
  shopID: number;
  modelType: PrinterSettingType;
}

export const PrinterSettingTypes = {
  PRODUCT: 1,
  VERSION: 2,
  EXTRA: 3,
};

export type PrinterSettingType = $Values<typeof PrinterSettingTypes>;
