import React, { Component } from "react";
import styled from "styled-components";
import { Section, SubSection } from "./DetailedSettings";
import { IntegrationsAPI } from "../../utils/api/IntegrationsAPI";
import { CassaPointIntegration } from "../../model/CassaPointIntegration";
import { PartnerNotification } from "../../model/PartnerNotification";
import PartnerNotificationDiv from "./integrations/PartnerNotificationDiv";
import Language, { Names } from "../../utils/Language";
import { ShopNTAKIntegration } from "../../model/NTAK";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5%;
`;

export default class Integrations extends Component {
  eventIDs: number[] = [];

  state: {
    cassapointIntegration: CassaPointIntegration[],
    partnerNotifications: PartnerNotification[],
    ntakIntegrations: ShopNTAKIntegration[]
  } = {
    cassapointIntegration: [],
    partnerNotifications: [],
    ntakIntegrations: [],
  };

  loadNotifications() {
    IntegrationsAPI.getPartnerNotifications(
      (res: { error: number, type: number, notifications: PartnerNotification[] | null }) => {
        if (res.error === 0) {
          if (res.notifications) {
            this.setState({ partnerNotifications: res.notifications });
          } else {
            this.setState({ partnerNotifications: [] });
          }
        }
      });
  }

  load() {
    this.loadNotifications();
    this.loadContext();
  }

  loadContext() {
    this.setState({
      ntakIntegrations: ContextSystem.ntakIntegrations.filter(n => n.partnerID === ContextSystem.selectedShop?.id),
    });
  }

  componentDidMount() {
    this.eventIDs = [];

    this.load();

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ ntakIntegrations }) => {
      if (ntakIntegrations !== undefined)
        this.loadContext();
    });
    this.eventIDs.push(eid);
  }

  componentWillUnmount() {
    this.eventIDs.forEach(id => EventSystem.unsubscribe(id));
  }

  render() {
    return (
      <Wrapper>
        <Section>
          <div>
            <h2>{Language.getName(Names.Notifications)}</h2>
          </div>
          <SubSection>
            <p>
              {Language.getName(Names.NotificationsDetails)}
            </p>
            {this.state.partnerNotifications.map((n, i) => {
              return (
                <PartnerNotificationDiv key={i} notification={n} reload={() => this.loadNotifications()} />
              );
            })}
            <PartnerNotificationDiv notification={null} reload={() => this.loadNotifications()} />
          </SubSection>
          <hr />
        </Section>
        <Section>
          <div>
            <h2>{Language.getName(Names.NTAK)}</h2>
          </div>
          <SubSection>
            <p>{Language.getName(Names.NTAKDetails)}</p>
            <p>{Language.getName(Names.NTAKDetails2)}</p>
          </SubSection>
          <SubSection>
            {(!this.state.ntakIntegrations || this.state.ntakIntegrations?.length <= 0) &&
              <p>{Language.getName(Names.NoNTAKIntegrations)}</p>
            }
            {this.state.ntakIntegrations?.length > 0 && this.state.ntakIntegrations.map((n, i) => {
              return (
                <React.Fragment key={i}>
                  <p>
                    <span>{Language.getName(Names.VATNumber)}: {n.companyVAT}</span><br />
                    <span>{Language.getName(Names.NTAKvendeglatoUzletRegSzam)}: {n.vendeglatoUzletRegSzam}</span><br />
                    {n.publicKeyFileName.endsWith(".cer") &&
                      <span
                        style={{ color: "green", fontWeight: "bold" }}>{Language.getName(
                        Names.NTAKIntegrationOK)}</span>
                    }
                    {!n.publicKeyFileName.endsWith(".cer") &&
                      <span style={{ color: "orange" }}>{Language.getName(Names.NTAKIntegrationWaiting)}</span>
                    }
                  </p>
                </React.Fragment>
              );
            })}
          </SubSection>
        </Section>
        <Section>
          <div>
            <h2>
              {Language.getName(Names.DeliveryHeroIntegrationInfo)}
            </h2>
          </div>
          <SubSection>
            <p>
              {Language.getName(Names.DeliveryHeroIntegrationDetails)}
            </p>
          </SubSection>
        </Section>
        <Section>
          <div>
            <h2>
              {Language.getName(Names.WoltIntegrationInfo)}
            </h2>
          </div>
          <SubSection>
            <p>
              {Language.getName(Names.WoltIntegrationDetails)}
            </p>
          </SubSection>
        </Section>
      </Wrapper>
    );
  }
}
