import React, { Component } from "react";
import styled, { css } from "styled-components";
import CartContent from "../../CartContent";
import type { Product } from "../../../model/Product";
import { OrderPayment, OrderProduct } from "../../../model/Order";
import { FaPlus } from "react-icons/all";
import PaymentSummaryComponent from "./PaymentSummaryComponent";
import CartCalc from "../../../utils/CartCalc";
import OrderPaymentEditor from "./OrderPaymentEditor";
import NumberPad from "./NumberPad";
import { PaymentMethodTypes } from "../../../model/PaymentMethodSetting";
import { Button } from "../../FormComponents";
import Language, { Names } from "../../../utils/Language";
import { toast } from "react-toastify";
import ContextSystem from "../../../utils/ContextSystem";
import EventSystem from "../../../utils/EventSystem";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px;
  height: 55vh;
`;

const NumberPadWrapper = styled.div`
  margin: 0 0 0 auto;
  padding-left: 12px;
`;

const BillWrapper = styled.div`
  min-width: ${({ width }) => width ?? "250px"};
  width: ${({ width }) => width ?? "250px"};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SplitCardsScrollable = styled.div`
  width: fit-content;
  height: 100%;
  max-width: 70%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const SplitCards = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
`;

const SplitCard = styled.div`
  width: 300px;
  height: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 6px 6px 6px 18px;
  padding: 6px;

  ${({ button }) => button === true && css`
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 18pt;
    transition: background-color 200ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }

    &:active {
      background-color: #e8e8e8;
    }
  `};

  ${({ narrow }) => narrow === true && css`
    width: 150px;
  `};

  ${({ no_border }) => no_border !== true && css`
    border-radius: 6px;
    box-shadow: 0 0 4px 1px #858585;
  `};

  ${({ selected }) => selected === true && css`
    border-radius: 6px;
    box-shadow: 0 0 4px 4px #3a63b6;
  `};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: auto 0 0 0;
  padding: 3px 0 0 0;
`;

const RemoveButton = styled(Button)`
  color: ${({ color }) => (color ? color : "#993b3b")};
  border: 1px solid ${({ border_color }) => (border_color ? border_color : "#993b3b")};

  &:hover, &:active {
    background-color: #bf4a4a;
  }
`;

const SelectButton = styled(Button)`
  ${({ selected }) => selected === true && css`
    background-color: #4a7dbf;
    color: white;
  `};

  &:hover, &:active {
    background-color: #5691de;
  }
`;

type Props = {
  newPayments: OrderPayment[],
  existingPayments: OrderPayment[],
  deletePayments: OrderPayment[],
  onNewPaymentsChanged: (newPayments: OrderPayment[])=>{},
  onSaveProcessed: ()=>{},
  onDeletePaymentsChanged: (deletePayments: OrderPayment[])=>{},
  cart: Product[],
  splitEqually: boolean,
};

type Split = {
  subCart: Product[],
  newOrderPayments: OrderPayment[],
  existingOrderPayments: OrderPayment[],
}

type State = {
  newPayments: OrderPayment[],
  existingPayments: OrderPayment[],
  deletePayments: OrderPayment[],
  cart: Product[],
  leftOverCart: Product[],
  splits: Split[],
  selected: number[],
  selectedPayingMethod: number,
  splitEqually: boolean,
};

export default class BillSplitByProductsComponent extends Component<Props> {
  state: State = {
    newPayments: [],
    existingPayments: [],
    deletePayments: [],
    cart: [],
    leftOverCart: [],
    splits: [{ existingOrderPayments: [], newOrderPayments: [], subCart: [] }],
    selected: [0],
    selectedPayingMethod: PaymentMethodTypes.CASH,
    splitEqually: false,
  };

  onProductClicked(p: Product) {
    if (this.state.selected.length <= 0) {
      toast(Language.getName(Names.PleaseSelectASplit));
      return;
    }
    let leftOverCart: Product[] = this.state.leftOverCart.filter(lp => lp.id !== p.id);

    p._splitBy = this.state.selected.length;
    let splits: Split[] = [];
    this.state.splits.forEach((s: Split, i) => {
      let copy: Split = { ...s };
      if (this.state.selected.includes(i)) {
        copy.subCart.push(p);
      }
      splits.push(copy);
    });

    this.setState({
      leftOverCart,
      splits,
    });
  }

  deletePaymentsChanged(deletePayments: OrderPayment[]) {
    this.setState({ deletePayments });
    if (this.props.onDeletePaymentsChanged)
      this.props.onDeletePaymentsChanged(deletePayments);
  }

  onProductClickedSub(p: Product) {
    let leftOverCart: Product[] = this.state.leftOverCart;
    leftOverCart.push(p);

    let splits: Split[] = [];
    let foundIn: number = 0;
    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(p.orderProduct);
    this.state.splits.forEach((s: Split) => {
      let copy: Split = { ...s };
      let subCart: Product[] = [];
      for (let sc: Product of copy.subCart) {
        let leadOP2: OrderProduct = CartCalc.getLeadOrderProduct(sc.orderProduct);
        if (CartCalc.isSameOrderElement(leadOP, leadOP2)) {
          foundIn++;
        } else {
          subCart.push(sc);
        }
      }
      copy.subCart = subCart;
      splits.push(copy);
    });

    this.setState({
      leftOverCart,
      splits,
    });
  }

  loadProps() {
    if (this.state.cart !== this.props.cart) {
      let cartCopy: Product[] = [];
      this.props.cart.forEach(value => cartCopy.push({ ...value }));
      this.setState({
        newPayments: [],
        cart: this.props.cart,
        leftOverCart: cartCopy,
        splits: [{ existingOrderPayments: [], newOrderPayments: [], subCart: [] }],
        selected: [0],
      });
      if (this.props.onNewPaymentsChanged)
        this.props.onNewPaymentsChanged([]);
    }
    if (this.state.newPayments !== this.props.newPayments)
      this.setState({ newPayments: this.props.newPayments });
    if (this.state.deletePayments !== this.props.deletePayments)
      this.setState({ deletePayments: this.props.deletePayments });
    if (this.state.existingPayments !== this.props.existingPayments)
      this.setState({ existingPayments: this.props.existingPayments });
    if (this.state.splitEqually !== this.props.splitEqually)
      this.setState({ splitEqually: this.props.splitEqually });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot) {
    this.loadProps();

    if (this.state.deletePayments !== prevState.deletePayments && this.props.onDeletePaymentsChanged) {
      this.props.onDeletePaymentsChanged(this.state.deletePayments);
    }

    if (this.state.splits !== prevState.splits && this.props.onNewPaymentsChanged) {
      let op: OrderPayment[] = [];
      this.state.splits.forEach((s, i) => s.newOrderPayments.forEach(nop => {
        let copy: OrderPayment = { ...nop };
        copy._split = i;
        copy.productPayments = [];
        s.subCart.forEach((p, i) => copy.productPayments.push({
          id: -1,
          enabled: true,
          orderID: -1,
          orderPaymentID: CartCalc.getLeadOrderProduct(p.orderProduct)?.orderID ?? -1 * i,
          orderProductID: CartCalc.getLeadOrderProduct(p.orderProduct)?.id ?? -1,
        }));
        op.push(copy);
      }));
      this.setState({ newPayments: op });
      this.props.onNewPaymentsChanged(op);
    }
  }

  eventIDs: number[] = [];

  componentDidMount() {
    this.eventIDs = [];
    let eid: number;
    eid = EventSystem.subscribe(EventSystem.events.order_payment_save_clicked, () => {
      if (this.props.onSaveProcessed)
        this.props.onSaveProcessed(this.state.newPayments);
    });
    this.eventIDs.push(eid);

    this.loadProps();
  }

  componentWillUnmount() {
    this.eventIDs.forEach(i => EventSystem.unsubscribe(i));
  }

  addSplit() {
    let splits: Split[] = [];
    this.state.splits.forEach(v => splits.push(v));
    splits.push({ existingOrderPayments: [], newOrderPayments: [], subCart: [] });

    let selected: number[] = this.state.selected;
    selected.push(splits.length - 1);

    this.setState({ splits, selected });
  }

  removeSplit(index: number, split: Split) {
    let splits: Split[] = [];
    let leftOverCart: Product[] = this.state.leftOverCart;
    this.state.splits.forEach((v, i) => {
      if (i !== index) { //not the same split, but we need to remove the shared products
        v.subCart = v.subCart.filter(sc => {
          let leadOP1: OrderProduct = CartCalc.getLeadOrderProduct(sc.orderProduct);
          return split.subCart.find(sc2 => CartCalc.isSameOrderElement(leadOP1, CartCalc.getLeadOrderProduct(sc2.orderProduct))) === undefined;
        });
        splits.push(v);
      }
    });

    //return the products to the leftovers list
    this.state.cart.forEach(c => {
      let leadOP1: OrderProduct = CartCalc.getLeadOrderProduct(c.orderProduct);
      let foundInSplitSubCart: boolean = split?.subCart.find(sc => CartCalc.isSameOrderElement(leadOP1, CartCalc.getLeadOrderProduct(sc.orderProduct))) !== undefined;
      if (foundInSplitSubCart) {
        leftOverCart.push({ ...c });
      }
    });

    let selected: number[] = this.state.selected;
    selected.remove(index);

    this.setState({ splits, leftOverCart, selected });
  }

  allOnOnePaymentOptionClicked(paymentType: number) {
    let splits: Split[] = [];
    let total: number = CartCalc.calcOrderProductsTotalPrice(this.state.cart, false);

    this.state.splits.forEach((split, i) => {
      let s: Split = { ...split };
      if (this.state.selected.includes(i)) {
        s.newOrderPayments = [];
        let amount: number = total / this.state.splits.length;
        if (!this.state.splitEqually)
          amount = CartCalc.calcOrderProductsTotalPrice(s.subCart, true);
        s.newOrderPayments.push({
            id: -1,
            enabled: true,
            paymentType,
            amount,
            creditedAmount: 0,
            dateTime: new Date(),
            orderContactID: -1,
            orderID: -1,
            payed: true,
            productPayments: [], //needs to be filled on "save", bcs the selected products on this split could change
            shopID: ContextSystem.selectedShop?.id ?? -1,
            txid: -1,
          },
        );
      }
      splits.push(s);
    });

    this.setState({ splits });
  }

  addPayment(amount: number, paymentType: number) {
    let splits: Split[] = [];

    this.state.splits.forEach((split, i) => {
      let s: Split = { ...split };
      if (this.state.selected.includes(i)) {
        s.newOrderPayments.push({
            id: -1,
            enabled: true,
            paymentType,
            amount,
            creditedAmount: 0,
            dateTime: new Date(),
            orderContactID: -1,
            orderID: -1,
            payed: true,
            productPayments: [], //needs to be filled on "save", bcs the selected products on this split could change
            shopID: ContextSystem.selectedShop?.id ?? -1,
            txid: -1,
          },
        );
      }
      splits.push(s);
    });

    this.setState({ splits });
  }

  newPaymentsUpdated(newPayments: OrderPayment[], s: Split, index: number) {
    let splits: Split[] = [];
    if (!newPayments)
      newPayments = [];

    this.state.splits.forEach((split, i) => {
      let s2: Split = { ...split };
      if (i === index)
        s2.newOrderPayments = newPayments;
      splits.push(s2);
    });

    this.setState({
      splits,
    });
  }

  selectToggle(index: number) {
    let s: number[] = this.state.selected;
    if (this.state.selected.includes(index)) {
      s = s.filter(ii => ii !== index);
      this.setState({ selected: s });
    } else {
      s.push(index);
      this.setState({ selected: s });
    }
  }

  render() {
    let mergeSzepCardsToCredit: boolean = window.localStorage.getItem("mergeSzepCardsToCredit") === "true";
    let availablePaymentMethods: { type: number, countries: number[], availableShippingMethods: number[] }[][] = OrderPaymentEditor.getAvailablePaymentMethods(mergeSzepCardsToCredit);
    let orderTotal: number = CartCalc.calcOrderProductsTotalPrice(this.state.cart, false);

    let existingPaymentsInSplits: Split[] = [];
    this.state.existingPayments.forEach(ep => {
      if (ep.amount <= ep.creditedAmount)
        return;

      let split: Split = existingPaymentsInSplits.find(eps => eps.existingOrderPayments.find(eop => eop.orderContactID === ep.orderContactID) !== undefined);
      if (!split) {
        existingPaymentsInSplits.push({
          existingOrderPayments: [ep],
          newOrderPayments: [],
          subCart: [],
        });
      } else {
        split.existingOrderPayments.push(ep);
      }
    });

    return (
      <Wrapper>
        {this.state.splitEqually !== true &&
          <BillWrapper>
            <CartContent
              products={this.state.leftOverCart}
              showShippingPrices={false}
              showModificationButtons={false}
              showTotal={false}
              increaseItem={undefined}
              removeItem={undefined}
              showPrices={true}
              showOriginalPrice={false}
              showSplitPrice={false}
              onProductClicked={(p: Product) => this.onProductClicked(p)}
              setNote={undefined}
              fontSize={undefined}
              showNote={undefined} />
          </BillWrapper>
        }

        <SplitCardsScrollable>
          <SplitCards>
            {existingPaymentsInSplits.map((s: Split, i: number) => {

              if (this.state.existingPayments.find(p => this.state.deletePayments.find(dp => dp.id === p.id) !== undefined) !== undefined) {
                return (
                  <React.Fragment key={i} />
                );
              }

              return (
                <SplitCard key={i}>
                  {this.state.splitEqually !== true &&
                    <BillWrapper width={"100%"}>
                      <CartContent
                        products={s.subCart}
                        showShippingPrices={false}
                        showModificationButtons={false}
                        showTotal={false}
                        increaseItem={undefined}
                        removeItem={undefined}
                        showPrices={true}
                        showOriginalPrice={false}
                        showSplitPrice={true}
                        onProductClicked={(p: Product) => this.onProductClickedSub(p)}
                        setNote={undefined}
                        showNote={undefined}
                        fontSize={undefined}
                      />
                    </BillWrapper>
                  }

                  <PaymentSummaryComponent
                    toPay={orderTotal}
                    total={orderTotal}
                    availablePaymentMethods={availablePaymentMethods}
                    existingPayments={s.existingOrderPayments}
                    newPayments={[]}
                    onNewPaymentsChanged={undefined}
                    deletePayments={this.state.deletePayments}
                    onDeletePaymentsChanged={deletePayments => this.deletePaymentsChanged(deletePayments)}
                    orders={[this.state.order]}
                    inSplit={true}
                  />
                </SplitCard>
              );
            })}
            {this.state.splits.map((s: Split, i) => {
              let selected: boolean = this.state?.selected?.includes(i);
              let total: number = orderTotal / this.state.splits.length;

              if (!this.state.splitEqually) {
                total = CartCalc.calcOrderProductsTotalPrice(s.subCart, true);
              }

              return (
                <SplitCard key={i} selected={selected}
                           onClick={() => this.selectToggle(i)}>
                  {this.state.splitEqually !== true &&
                    <BillWrapper width={"100%"}>
                      <CartContent
                        products={s.subCart}
                        showShippingPrices={false}
                        showModificationButtons={false}
                        showTotal={false}
                        increaseItem={undefined}
                        removeItem={undefined}
                        showPrices={true}
                        showOriginalPrice={false}
                        showSplitPrice={true}
                        onProductClicked={(p: Product) => this.onProductClickedSub(p)}
                        setNote={undefined}
                        showNote={undefined}
                        fontSize={undefined}
                      />
                    </BillWrapper>
                  }

                  <PaymentSummaryComponent
                    toPay={total}
                    total={total}
                    availablePaymentMethods={availablePaymentMethods}
                    existingPayments={s.existingOrderPayments}
                    newPayments={s.newOrderPayments}
                    onNewPaymentsChanged={newPayments => this.newPaymentsUpdated(newPayments, s, i)}
                    deletePayments={this.state.deletePayments}
                    onDeletePaymentsChanged={deletePayments => this.deletePaymentsChanged(deletePayments)}
                    orders={[this.state.order]}
                    inSplit={true}
                  />

                  <ButtonsWrapper>
                    <RemoveButton onClick={e => {
                      e.stopPropagation();
                      this.removeSplit(i, s);
                    }}>
                      {Language.getName(Names.Remove)}
                    </RemoveButton>
                    <SelectButton selected={selected} onClick={e => {
                      e.stopPropagation();
                      this.selectToggle(i);
                    }}>
                      {Language.getName(Names.Select)}
                    </SelectButton>
                  </ButtonsWrapper>
                </SplitCard>
              );
            })}
            <SplitCard button={true} narrow={true} onClick={() => this.addSplit()}>
              <FaPlus />
            </SplitCard>
          </SplitCards>
        </SplitCardsScrollable>

        <NumberPadWrapper>
          <NumberPad
            availablePaymentMethods={availablePaymentMethods}
            onPayingMethodDoubleClicked={type => this.allOnOnePaymentOptionClicked(type)}
            selectedPayingMethod={this.state.selectedPayingMethod}
            onPaymentMethodChanged={(selectedPayingMethod) => this.setState({ selectedPayingMethod })}
            onAddButtonClicked={(value, type) => this.addPayment(value, type)}
          />
        </NumberPadWrapper>
      </Wrapper>);
  }
}
