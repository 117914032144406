import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import { toast } from "react-toastify";
import Language, { Names } from "../../utils/Language";
import { Product, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import { languages } from "./CouponEditor";
import { ProductsList, Select, SelectedProduct } from "./ProductEditor";
import { UpsellRule, UpsellRuleModel, UpsellTypes } from "../../model/UpsellRule";
import { AllPaymentMethod, PaymentMethods, PaymentMethodTypes } from "../../model/PaymentMethodSetting";
import { ShippingMethods } from "../../model/ShippingPrice";
import { FaTrashAlt } from "react-icons/all";
import { CouponModelTypes } from "../../model/Coupon";
import Orders from "../home/Orders";
import { UpsellRulesAPI } from "../../utils/api/UpsellRulesAPI";
import type { Option } from "./OrderStateCommentEditor";

export default class UpsellRuleEditor extends Component {
  state: {
    showModal: boolean,
    edit: null | UpsellRule,
    title: string,
    language: number,
    selectedLanguage: number,
    products: Product[],
    productsOptions: Option[],
    typeOptions: Option[],
    selectedShippingTypeOptions: Option[],
    shippingTypeOptions: Option[],
    selectedPaymentTypeOptions: Option[],
    paymentTypeOptions: Option[],
  } = {
    showModal: false,
    edit: null,
    title: Language.getName(Names.NewUpsellRule),
    language: ContextSystem.language,
    selectedLanguage: languages[0].id,
    products: [],
    productsOptions: [],
    typeOptions: [],
    selectedShippingTypeOptions: [],
    shippingTypeOptions: [],
    selectedPaymentTypeOptions: [],
    paymentTypeOptions: [],
  };

  save() {
    let upsellRule: UpsellRule = { ...this.state.edit };

    upsellRule.date = new Date();
    upsellRule.shopIDs = [
      {
        enabled: true,
        id: -1,
        upsellRuleID: -1,
        modelID: ContextSystem.selectedShop.id,
        type: CouponModelTypes.PARTNER,
      },
    ];

    UpsellRulesAPI.save(upsellRule, () => {
      toast(Language.getName(Names.Saved));
      this.setState({ showModal: false });
    });
  }

  static remove(extraID) {
    UpsellRulesAPI.remove(extraID, () => {
      toast(Language.getName(Names.Removed));
    });
  }

  editProduct(upsellRule: UpsellRule) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyUpsellRule),
      edit: upsellRule,
    });
  }

  addNew() {
    this.setState({
      showModal: true,
      title: Language.getName(Names.NewUpsellRule),
      edit: {
        enabled: 1,
        id: -1,
        shopIDs: [
          {
            enabled: true,
            id: -1,
            upsellRuleID: -1,
            modelID: ContextSystem.selectedShop.id,
            type: CouponModelTypes.PARTNER,
          },
        ],
        type: UpsellTypes.POS_AT_END_OF_ORDER,
        productsTriggering: [],
        couponsTriggering: [],
        minCartValue: 0,
        maxCartValue: 0,
        date: new Date(),
        paymentMode: PaymentMethodTypes.ANY,
        shippingMode: ShippingMethods.ANY,
      },
    });
  }

  loadProductOptions() {
    let productsOptions = [];
    for (let p: Product of this.state.products) {
      productsOptions.push({
        value: p.id,
        label: TranslatableString.get(p.name) + " (" + p.price + " Ft)",
      });
    }
    this.setState({ productsOptions });
  }

  loadTypeOptions() {
    let typeOptions = [];
    for (let upsellTypesKey in UpsellTypes) {
      let value: number = UpsellTypes[upsellTypesKey];
      let name: string = Orders.getUpsellRuleTypeString(value);
      typeOptions.push({ value, label: name });
    }
    this.setState({ typeOptions });
  }

  loadShippingAndPaymentModeOptions() {
    let shippingTypeOptions: Option[] = [];
    for (let key of Object.keys(ShippingMethods)) {
      let sh: number = parseInt(ShippingMethods[key]);
      if ([ShippingMethods.ANY].includes(sh))
        continue;
      shippingTypeOptions.push({ value: sh, label: Orders.getShippingMethodString(sh) });
    }
    let paymentTypeOptions: Option[] = [];
    AllPaymentMethod.forEach(p => {
      if (!p.countries.includes(ContextSystem.selectedShop?.countryID))
        return;
      if (!ContextSystem.selectedShop.paymentMethods.find(pm => pm.paymentID === p.type && pm.active && pm.enabled))
        return;
      paymentTypeOptions.push({ value: p.type, label: PaymentMethods.getName(p.type) });
    });
    let selectedPaymentTypeOptions = paymentTypeOptions.map(pt => ({ ...pt }));
    let selectedShippingTypeOptions = shippingTypeOptions.map(st => ({ ...st }));
    this.setState({ paymentTypeOptions, shippingTypeOptions, selectedPaymentTypeOptions, selectedShippingTypeOptions });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.edit_upsell_rule, (upsellRule: UpsellRule) => {
      if (!upsellRule)
        this.addNew();
      else
        this.editProduct(upsellRule);
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, products }) => {
      if (language !== undefined || products !== undefined) {
        this.loadProductOptions();
        this.loadTypeOptions();
        this.loadShippingAndPaymentModeOptions();
        this.setState({
          language,
          products: ContextSystem.products.filter(p => p.enabled && p.partnerID === ContextSystem?.selectedShop?.id),
        });
      }
    });
    this.loadProductOptions();
    this.loadTypeOptions();
    this.loadShippingAndPaymentModeOptions();
  }

  selectType({ value: typeNumber }) {
    let edit: UpsellRule = { ...this.state.edit };
    edit.type = typeNumber;
    this.setState({ edit });
  }

  addTriggerProduct({ value: productID }) {
    let edit: UpsellRule = { ...this.state.edit };
    let productsTriggering: UpsellRuleModel[] = [];
    if (edit.productsTriggering)
      for (let p of edit.productsTriggering) {
        if (p.modelID === productID) {
          toast(Language.getName(Names.ProductIsAlreadyInMenu));
          return;
        }
        productsTriggering.push({ ...p });
      }
    for (let p of this.state.products) {
      if (p.id === productID) {
        productsTriggering.push({
          id: -1,
          enabled: true,
          modelID: p.id,
          upsellRuleID: edit.id,
          type: CouponModelTypes.PRODUCT_TRIGGER,
        });
        break;
      }
    }
    edit.productsTriggering = productsTriggering;
    this.setState({ edit });
  }

  addProduct({ value: productID }) {
    let edit: UpsellRule = { ...this.state.edit };
    let productIDs: UpsellRuleModel[] = [];
    if (edit.productIDs)
      for (let p of edit.productIDs) {
        if (p.modelID === productID) {
          toast(Language.getName(Names.ProductIsAlreadyInMenu));
          return;
        }
        productIDs.push({ ...p });
      }
    for (let p of this.state.products) {
      if (p.id === productID) {
        productIDs.push({
          id: -1,
          enabled: true,
          modelID: p.id,
          upsellRuleID: edit.id,
          type: CouponModelTypes.PRODUCT,
        });
        break;
      }
    }
    edit.productIDs = productIDs;
    this.setState({ edit });
  }

  removeSelectedProduct(productID: number) {
    let edit = { ...this.state.edit };
    let products = [];
    for (let p of edit.productIDs) {
      if (p.modelID !== productID)
        products.push({ ...p });
    }
    edit.productIDs = products;
    this.setState({ edit });
  }

  removeSelectedTriggerProduct(productID: number) {
    let edit = { ...this.state.edit };
    let productsTriggering = [];
    for (let p of edit.productsTriggering) {
      if (p.modelID !== productID)
        productsTriggering.push({ ...p });
    }
    edit.productsTriggering = productsTriggering;
    this.setState({ edit });
  }

  shippingMethodOptionsSelected(s: Option[]) {
    this.setState({ selectedShippingTypeOptions: s });
  }

  paymentMethodOptionsSelected(s: Option[]) {
    this.setState({ selectedPaymentTypeOptions: s });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{Language.getName(Names.Type)}</label>
          <Select
            placeholder={Language.getName(Names.Type) + "..."}
            onChange={(e) => this.selectType(e)}
            options={this.state.typeOptions}
            value={{ value: this.state?.edit?.type, label: Orders.getUpsellRuleTypeString(this.state?.edit?.type) }}
          />
          <label>{Language.getName(Names.ChooseWhichProductsShouldAppear)}</label>
          <ProductsList>
            <SelectedProduct header={true}>
              <div>
                <b>#</b>
              </div>
              <div>
                <b>{Language.getName(Names.ProductName)}</b>
              </div>
            </SelectedProduct>
            {this.state.edit && this.state.edit.productIDs && this.state.edit.productIDs.map((urm, i) => {
              let p: Product = this.state.products.find(product => product.id === urm.modelID);
              if (!p)
                return <React.Fragment key={i} />;
              return (
                <SelectedProduct key={i}>
                  <div>{p.id}</div>
                  <div>
                    {TranslatableString.get(p.name)} ({p.price} Ft)
                  </div>
                  <div>
                    <FaTrashAlt onClick={() => this.removeSelectedProduct(p.id)} />
                  </div>
                </SelectedProduct>
              );
            })}
          </ProductsList>
          <Select
            placeholder={Language.getName(Names.Search) + "..."}
            onChange={(e) => this.addProduct(e)}
            options={this.state.productsOptions}
            noOptionsMessage={() => Language.getName(Names.NoProductToShow)}
          />
          <label>{Language.getName(Names.ChooseWhichProductsShouldTrigger)}</label>
          <label>{Language.getName(Names.ChooseWhichProductsShouldTrigger2)}</label>
          <ProductsList>
            <SelectedProduct header={true}>
              <div>
                <b>#</b>
              </div>
              <div>
                <b>{Language.getName(Names.ProductName)}</b>
              </div>
            </SelectedProduct>
            {this.state.edit && this.state.edit.productsTriggering && this.state.edit.productsTriggering.map(
              (urm, i) => {
                let p: Product = this.state.products.find(product => product.id === urm.modelID);
                if (!p)
                  return <React.Fragment key={i} />;
                return (
                  <SelectedProduct key={i}>
                    <div>{p.id}</div>
                    <div>
                      {TranslatableString.get(p.name)} ({p.price} Ft)
                    </div>
                    <div>
                      <FaTrashAlt onClick={() => this.removeSelectedTriggerProduct(p.id)} />
                    </div>
                  </SelectedProduct>
                );
              })}
          </ProductsList>
          <Select
            placeholder={Language.getName(Names.Search) + "..."}
            onChange={(e) => this.addTriggerProduct(e)}
            options={this.state.productsOptions}
            noOptionsMessage={() => Language.getName(Names.NoProductToShow)}
          />

          {/*<Div width={"49%"}>{Language.getName(Names.ShippingMode)}: </Div>*/}
          {/*<Div width={"49%"}>{Language.getName(Names.PaymentMethods)}: </Div>*/}
          {/*<MultiSelect*/}
          {/*  margin={"0 6px"}*/}
          {/*  width={"calc(48% - 12px)"}*/}
          {/*  display={"inline-block"}*/}
          {/*  options={this.state.shippingTypeOptions}*/}
          {/*  value={this.state.selectedShippingTypeOptions}*/}
          {/*  onChange={(s) => this.shippingMethodOptionsSelected(s)}*/}
          {/*  labelledBy={"Select"}*/}
          {/*  disableSearch*/}
          {/*  styles={{*/}
          {/*    menu: provided => ({...provided, zIndex: 9999})*/}
          {/*  }}*/}
          {/*  overrideStrings={{*/}
          {/*    selectSomeItems: Language.getName(Names.Choose),*/}
          {/*    allItemsAreSelected: Language.getName(Names.AllSelected),*/}
          {/*    selectAll: Language.getName(Names.All),*/}
          {/*    search: Language.getName(Names.Search),*/}
          {/*    clearSearch: Language.getName(Names.Clear)*/}
          {/*  }}*/}
          {/*  hasSelectAll={true}*/}
          {/*/>*/}
          {/*<MultiSelect*/}
          {/*  margin={"0 6px"}*/}
          {/*  width={"calc(48% - 12px)"}*/}
          {/*  display={"inline-block"}*/}
          {/*  options={this.state.paymentTypeOptions}*/}
          {/*  value={this.state.selectedPaymentTypeOptions}*/}
          {/*  onChange={(s) => this.paymentMethodOptionsSelected(s)}*/}
          {/*  labelledBy={"Select"}*/}
          {/*  disableSearch*/}
          {/*  styles={{*/}
          {/*    menu: provided => ({...provided, zIndex: 9999})*/}
          {/*  }}*/}
          {/*  overrideStrings={{*/}
          {/*    selectSomeItems: Language.getName(Names.Choose),*/}
          {/*    allItemsAreSelected: Language.getName(Names.AllSelected),*/}
          {/*    selectAll: Language.getName(Names.All),*/}
          {/*    search: Language.getName(Names.Search),*/}
          {/*    clearSearch: Language.getName(Names.Clear)*/}
          {/*  }}*/}
          {/*  hasSelectAll={true}*/}
          {/*/>*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
