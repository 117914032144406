import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { ShippingPrice } from "../../model/ShippingPrice";
import { City, ZipCode } from "../../model/Address";

function getAll(cb: (res: { error: number, type: number, prices: ShippingPrice[], cities: City[] })=>{}) {
  APICaller.ownFetch(false, "POST", "/shippingprices", {}, (res) => {
    let result = JSON.parse(res);
    if (result.error === 0) if (cb) cb(result);
  });
}

function remove(shippingPriceID: number, cb: (res: { error: number, type: number })=>{}) {
  APICaller.ownFetch(false, "DELETE", "/shippingprice", { ID: shippingPriceID }, (res) => {
    let result = JSON.parse(res);
    if (result.error === 0) if (cb) cb(result);
  });
}

function searchCity(query: string, cb: (res: { error: number, type: number, cities: City[] })=>{}) {
  APICaller.ownFetch(false, "POST", "/search/city", { query }, (res) => {
    let result = JSON.parse(res);
    if (result.error === 0)
      if (cb)
        cb(result);
  });
}

export type GetSearchZipcodeResType = APIMsgBase & { results: ZipCode[], cities: City[] };

function searchZipcode(query, cb: (res: GetSearchZipcodeResType)=>{}) {
  APICaller.ownFetch(false, "POST", "/search/zipcode", { query }, (res) => {
    let result: GetSearchZipcodeResType = JSON.parse(res);
    if (result.error === 0)
      if (cb)
        cb(result);
  });
}

function addNewShippingPrice(
  cityID: number, price: number, price2: number = 0, price2Min: number = 0, minimumOrder: number = 0,
  cb: (res: { error: number, type: number })=>{},
) {
  APICaller.ownFetch(false, "POST", "/shippingprice", { cityID, price, price2, price2Min, minimumOrder }, (res) => {
    let result = JSON.parse(res);
    if (result.error === 0)
      if (cb)
        cb(result);
  });
}

export const ShippingsAPI = {
  getAll,
  remove,
  searchCity,
  searchZipcode,
  addNewShippingPrice,
};
