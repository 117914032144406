import React, { Component } from "react";

export default class DispatcherLayout extends Component {
  render() {
    return (
      <>
        Dispatcher
      </>
    );
  }
}