import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import { Raw, RawQty, Recipe, RecipeQty, TranslatableString } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import { Button } from "../FormComponents";
import { Label } from "./RawEditor";
import { CellInput, Select, Table, TableCell, TableRow } from "./InwardHandlingModal";
import { ButtonWrapper } from "../home/Settings";
import { FaTrash } from "react-icons/all";
import { Storage } from "../../model/Stock";

export default class RecipeQtyEditor extends Component {
  state: {
    showModal: boolean,
    edit: RecipeQty[],
    title: string,
    language: number,
    raws: Raw[],
    recipes: Recipe[],
    cb: ()=>{},
    storages: Storage[]
  } = {
    showModal: false,
    edit: [],
    title: Language.getName(Names.NewRecipe),
    language: ContextSystem.language,
    raws: [],
    recipes: [],
    cb: null,
    storages: [],
  };

  save() {
    if (this.state.cb)
      this.state.cb(this.state.edit);
    this.setState({ showModal: false });
  }

  edit(recipes: RecipeQty[], cb: ()=>{}) {
    this.setState({
      showModal: true,
      title: Language.getName(Names.ModifyRecipe),
      edit: recipes,
      cb,
    });
  }

  load() {
    this.setState({
      raws: ContextSystem.raws.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      recipes: ContextSystem.recipes.filter(r => r.enabled && r.partnerID === ContextSystem.selectedShop?.id),
      storages: ContextSystem.storages.filter(e => e.enabled && e.shopIDs.includes(ContextSystem.selectedShop.id)),
    });
  }

  componentDidMount() {
    this.load();
    EventSystem.subscribe(EventSystem.events.edit_recipe_qty, ({ recipes, cb }) => {
      if (!recipes)
        recipes = [];

      this.edit(recipes, cb);
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, raws, recipes }) => {
      if (language !== undefined)
        this.setState({ language });
      if (recipes !== undefined || raws !== undefined)
        this.load();
    });
  }

  changeQty(r: Recipe, v: number) {
    if (!r || !v)
      return;

    if (v < 0)
      v = 0;

    let qty = Math.round(v);

    let recipeQtyList: RecipeQty[] = this.state.edit.map(rq => {
      if (r.id === rq.recipe.id)
        return { recipe: rq.recipe, qty: qty };
      else
        return { ...rq };
    });

    this.setState({ edit: recipeQtyList });
  }

  removeRecipe(recipe: Recipe) {
    if (!recipe || recipe.id <= 0)
      return;

    let recipeQtyList: RawQty[] = this.state.edit.filter(rq => rq.recipe.id !== recipe.id);
    this.setState({ edit: recipeQtyList });
  }

  addNewRecipe(recipeID: number) {
    if (!recipeID)
      return;

    let recipe: Recipe = this.state.recipes.find(r => r.id === recipeID);
    if (!recipe)
      return;

    if (this.state.edit.find(rq => rq.recipe.id === recipeID))
      return;

    let recipeQtyList: RawQty[] = this.state.edit.map(rq => ({ ...rq }));
    recipeQtyList.push({
      recipe,
      qty: 1,
    });

    this.setState({ edit: recipeQtyList });
  }

  render() {
    return (
      <Modal size={"xl"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.edit &&
            <>
              <Label font_size={"10pt"}>{Language.getName(Names.ChooseRecipesForProduct)}</Label>
              <Table>
                <TableRow header={true}>
                  <TableCell width={"40px"}>#</TableCell>
                  <TableCell grow={4} width={"20%"}>{Language.getName(Names.Name)}</TableCell>
                  <TableCell grow={2} width={"100px"}>{Language.getName(Names.Quantity)}</TableCell>
                  <TableCell grow={6} width={"35%"}>{Language.getName(Names.QuantityFor10)}</TableCell>
                  <TableCell shrink={0} width={"30px"} />
                </TableRow>
                {this.state.edit?.length > 0 && this.state.edit.map((rq: RecipeQty, i: number) => {
                  if (!rq)
                    return <React.Fragment key={i} />;

                  let recipe: Recipe = rq.recipe;
                  let qty: number = rq.qty;

                  return (
                    <TableRow key={i}>
                      <TableCell width={"40px"}>#{i + 1}</TableCell>
                      <TableCell grow={4} width={"20%"}>{TranslatableString.get(recipe.name)}</TableCell>
                      <TableCell grow={2} width={"100px"}>
                        <CellInput
                          pattern={"[0-9]"}
                          value={qty ?? ""}
                          type="number"
                          step={1}
                          placeholder={Language.getName(Names.Quantity)}
                          onChange={(e) => this.changeQty(recipe, e.target.value)}
                          width={"100%"}
                        />
                      </TableCell>
                      <TableCell grow={6} width={"35%"}>
                        <ul>
                          {recipe.raws.map((rq, i) => {
                            let storageName: string = Language.getName(Names.DefaultStorage);
                            let st: Storage = undefined;
                            if (rq.defaultStorageID > 0) {
                              st = this.state.storages.find(st => st.id === rq.defaultStorageID);
                              if (st)
                                storageName = st.name;
                            }
                            let allowOtherString: string = rq.allowOtherStorage ? Language.getName(
                              Names.AnotherStorageUsable) : Language.getName(Names.OnlyThisStorage);
                            return (
                              <li key={i}>
                                {(qty * rq.qty).toLocaleString() + " " + TranslatableString.get(
                                    rq.raw.unit) + " " + TranslatableString.get(rq.raw.name)
                                  + " (" + storageName + " | " + allowOtherString + ")"
                                }
                              </li>
                            );
                          })}
                        </ul>
                      </TableCell>
                      <TableCell width={"30px"}>
                        <FaTrash onClick={() => this.removeRecipe(recipe)} />
                      </TableCell>
                    </TableRow>
                  );
                })
                }
                <TableRow>
                  <TableCell width={"100%"}>
                    <Select
                      options={this.state.recipes.map(r => ({ value: r.id, label: TranslatableString.get(r.name) }))}
                      placeholder={Language.getName(Names.NewRecipe)}
                      noOptionsMessage={() => {
                        Language.getName(Names.NoRecipesToShow);
                      }}
                      onChange={(e) => this.addNewRecipe(e.value)}
                    />
                  </TableCell>
                </TableRow>
              </Table>
            </>
          }
          <ButtonWrapper>
            <Button onClick={() => EventSystem.publish(EventSystem.events.edit_recipe)}>
              {Language.getName(Names.AddNewRecipe)}
            </Button>
          </ButtonWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
