import { isSameWeek, startOfWeek, endOfWeek, isSameMonth, isSameYear, isSameDay } from "date-fns";

export default function fill() {
  Date.prototype.toLocaleDateTime = function() {
    if (new Date().toDateString() === this.toDateString())
      return this.toLocaleTimeString();
    return this.toLocaleDateString() + " " + this.toLocaleTimeString();
  };

  Date.prototype.toHunFormatMinutes = function() {
    let mm = this.getMonth() + 1; // getMonth() is zero-based
    let dd = this.getDate();
    let hh = this.getHours();
    let MM = this.getMinutes();

    return (
      this.getUTCFullYear() +
      "." +
      (mm > 9 ? "" : "0") +
      mm +
      "." +
      (dd > 9 ? "" : "0") +
      dd +
      ". " +
      (hh > 9 ? "" : "0") +
      hh +
      ":" +
      (MM > 9 ? "" : "0") +
      MM
    );
  };

  Date.prototype.toShortTimeFormat = function() {
    let hh = this.getHours();
    let MM = this.getMinutes();

    return (
      (hh > 9 ? "" : "0") +
      hh +
      ":" +
      (MM > 9 ? "" : "0") +
      MM
    );
  };

  Date.prototype.addSeconds = function(seconds) {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
  };

  Date.prototype.addMinutes = function(minutes) {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
  };

  Date.prototype.addHours = function(hours) {
    this.setHours(this.getHours() + hours);
    return this;
  };

  Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + days);
    return this;
  };

  Date.prototype.addWeeks = function(weeks) {
    this.addDays(weeks * 7);
    return this;
  };

  Date.prototype.addMonths = function(months) {
    let dt = this.getDate();
    this.setMonth(this.getMonth() + months);
    let currDt = this.getDate();
    if (dt !== currDt) {
      this.addDays(-currDt);
    }
    return this;
  };

  Date.prototype.addYears = function(years) {
    let dt = this.getDate();
    this.setFullYear(this.getFullYear() + years);
    let currDt = this.getDate();
    if (dt !== currDt) {
      this.addDays(-currDt);
    }
    return this;
  };

  Date.prototype.isSameDay = function(other: Date) {
    return isSameDay(this, other);
  };


  Date.prototype.isSameWeek = function(other: Date) {
    return isSameWeek(this, other, { weekStartsOn: 1 });
  };

  Date.prototype.isSameMonth = function(other: Date) {
    return isSameMonth(this, other);
  };

  Date.prototype.isSameYear = function(other: Date) {
    return isSameYear(this, other);
  };

  Date.prototype.startOfWeek = function() {
    return startOfWeek(this, { weekStartsOn: 1 });
  };

  Date.prototype.endOfWeek = function() {
    return endOfWeek(this, { weekStartsOn: 1 });
  };

  Date.prototype.toWeekFormatForWaiterLayout = function() {
    let s = startOfWeek(this, { weekStartsOn: 1 });
    let e = endOfWeek(this, { weekStartsOn: 1 });

    let smm = s.getMonth() + 1; // getMonth() is zero-based
    let sdd = s.getDate() + 1;
    let emm = e.getMonth() + 1; // getMonth() is zero-based
    let edd = e.getDate() + 1;

    return (
      s.getUTCFullYear() +
      "." +
      (smm > 9 ? "" : "0") +
      smm +
      "." +
      (sdd > 9 ? "" : "0") +
      sdd +
      "." +
      " - " +
      (emm > 9 ? "" : "0") +
      emm +
      "." +
      (edd > 9 ? "" : "0") +
      edd +
      "."
    );

  };

}
