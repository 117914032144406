import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { CassaPointIntegration } from "../../model/CassaPointIntegration";
import { PartnerNotification } from "../../model/PartnerNotification";
import { ShopNTAKIntegration } from "../../model/NTAK";

function getCassaPoint(cb: ({ error: number, cassapoint: CassaPointIntegration[] | null })=>{}) {
  APICaller.ownFetch(false, "POST", "/cassapoint", {}, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function setCassaPoint(clientName, vatPercentage, cb) {
  APICaller.ownFetch(false, "PUT", "/cassapoint", { clientName, vatPercentage }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function deleteCassaPoint(cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/cassapoint", {}, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function getPartnerNotifications(cb: ({ error: number, notifications: PartnerNotification[] | null })=>{}) {
  APICaller.ownFetch(false, "POST", "/notifications", {}, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function addPartnerNotification(
  notification: PartnerNotification, cb: ({ error: number, partnerNotification: PartnerNotification | null })=>{}) {
  APICaller.ownFetch(false, "PUT", "/notification", { notification }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function deletePartnerNotification(
  id: number, cb: ({ error: number, partnerNotification: PartnerNotification | null })=>{}) {
  APICaller.ownFetch(false, "DELETE", "/notification", { id }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type NTAKIntegrationsResponseType = APIMsgBase & { ntakIntegrations: ShopNTAKIntegration };

function getNTAKIntegrations(cb: (res: NTAKIntegrationsResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/ntakIntegrations", {}, data => {
    let res: NTAKIntegrationsResponseType = JSON.parse(data);
    cb && cb(res);
  });
}

export const IntegrationsAPI = {
  getCassaPoint,
  setCassaPoint,
  deleteCassaPoint,
  getPartnerNotifications,
  addPartnerNotification,
  deletePartnerNotification,
  getNTAKIntegrations,
};
