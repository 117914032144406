import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { PaymentMethodSetting } from "../../model/PaymentMethodSetting";
import { VATValue } from "../../model/Product";

export type PMSChangeType = APIMsgBase & { paymentMethodSetting: PaymentMethodSetting };

function changePaymentMethodActiveness(paymentMethodID: number, active: boolean, cb: (res: PMSChangeType)=>{}) {
  let method = active ? "POST" : "DELETE";
  APICaller.ownFetch(false, method, "/change/payment/method", { paymentMethod: paymentMethodID }, data => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function changeTableOrderEnabled(enabled: boolean, cb: (res: APIMsgBase)=>{}) {
  let method = enabled ? "POST" : "DELETE";
  APICaller.ownFetch(false, method, "/change/tableOrder", { enabled }, data => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function changePickupEnabled(enabled: boolean, cb: (res: APIMsgBase)=>{}) {
  let method = enabled ? "POST" : "DELETE";
  APICaller.ownFetch(false, method, "/change/pickup", { enabled }, data => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function changeDeliveryEnabled(enabled: boolean, cb: (res: APIMsgBase)=>{}) {
  let method = enabled ? "POST" : "DELETE";
  APICaller.ownFetch(false, method, "/change/delivery", { enabled }, data => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function toggleRestaurantSetting(type: RestaurantSetting, enabled: boolean, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/change/restaurantSetting", { type, enabled }, data => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function changeShopVATValues(vatValues: VATValue[], cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/vatValues", { vatValues }, data => {
    let res: APIMsgBase = JSON.parse(data);
    cb && cb(res);
  });
}

export const RestaurantSettings = {
  PayLaterAvailable: 1,
  PosStandShowLocalOrders: 2,
  PosWaiterShowNonLocalOrders: 3,
  AutoPrintOrders: 4,
  KitchenReportAvailable: 5,
  ServerReportAvailable: 6,
  CashflowReportAvailable: 7,
  ShowCashflowReportAtOrder: 8,
  HideNotAvailableProducts: 10,
  ShowOrderTimerForGuests: 11,
  AutomaticallyAcceptOnlineOrders: 12
};

export type RestaurantSetting = $Values<typeof RestaurantSettings>;

export const ProfileAPI = {
  changePaymentMethodActiveness,
  changePickupEnabled,
  changeTableOrderEnabled,
  changeDeliveryEnabled,
  toggleRestaurantSetting,
  changeShopVATValues,
};
