import APICaller, { APIMsgBase } from "../APICaller";
import { Extra, Product } from "../../model/Product";

export type GetExtrasType = APIMsgBase & { extras: Extra[] };

function getExtras(cb: (res: GetExtrasType)=>{}) {
  APICaller.ownFetch(true, "POST", "/extras", {}, (res) => {
    let result: GetExtrasType = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function save(extra: Extra, cb: (res: APIMsgBase)=>{}) {
  let e: Product = { ...extra };
  e.recipes = e.recipes.map(r => ({ ...r, recipe: { ...r.recipe, addedDate: r.recipe.addedDate.getTime() } }));

  APICaller.ownFetch(false, "POST", "/extra", { ...e }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(extraID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/extra", { id: extraID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ExtrasAPI = {
  getExtras,
  save,
  remove,
};
