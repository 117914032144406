import APICaller, { APIMsgBase } from "../APICaller";
import type { ShopProfileType } from "../../model/ShopProfile";
import { ShopProfile } from "../../model/ShopProfile";

export type SaveShopProfileResponse = APIMsgBase & { shopProfile: ShopProfile };

function save(email: string, permissions: ShopProfileType[], cb: (res: SaveShopProfileResponse)=>{}) {
  APICaller.ownFetch(false, "POST", "/shop/profile", { email, permissions },
    (res) => {
      let result: SaveShopProfileResponse = JSON.parse(res);
      if (cb)
        cb(result);
    },
  );
}

function remove(email: string, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/shop/profile", { email }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type InviteResponse = APIMsgBase & { newlyCreated: boolean, shopProfile: ShopProfile };

function invite(email: string, permissions: ShopProfileType[], cb: (res: InviteResponse)=>{}) {
  APICaller.ownFetch(false, "PUT", "/shop/profile", { email, permissions }, (res) => {
    let result: InviteResponse = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function activate(
  email: string, password: string, token: string, firstName: string, lastName: string, phoneNumber: string,
  cb: (res: APIMsgBase)=>{},
) {
  APICaller.ownFetch(false, "PUT", "/shop/profile/activate", { email }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ShopProfileAPI = {
  save,
  remove,
  invite,
  activate,
};
