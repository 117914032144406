import React, { Component } from "react";
import styled from "styled-components";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";

const Error = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 120px;
  width: 100%;
  height: calc(100vh - 120px);

  font-size: 40pt;
  text-align: center;
  color: dimgrey;
  font-family: "Raleway", sans-serif;
`;

export default class ErrorPage extends Component {
  state: {
    language: number,
  } = {
    language: ContextSystem.language,
  };

  eventIDs: number[] = [];

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (this.state.language !== undefined)
        this.setState({ language });
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <Error>
        {Language.getName(Names.ErrorPage404Text)}
      </Error>
    );
  }
}
