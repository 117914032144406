import styled, { css } from "styled-components";
import { InputArea } from "./FormComponents";

export const OrderProductNoteEditorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 3px 0 0 0;
`;

export const OrderProductNoteEditor = styled(InputArea)`
  min-height: unset;
`;

export const ProductDiv = styled.div`
  user-select: none;
  
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: rgb(60, 60, 60);
  border-radius: 6px;
  font-size: 11pt;
  margin-top: 5px;
  color: rgb(220, 220, 220);
  flex-wrap: wrap;

  & > div:nth-of-type(1) {
    width: 40%;
    text-align: left;

    & > svg {
      width: 10px;
      margin-right: 5px;
    }
  }

  & > div:nth-of-type(2) {
    width: fit-content;
    margin-left: 5px;
    text-align: right;
  }

  & > div:nth-of-type(3) {
    max-width: 25%;
    width: 150px;
    margin-right: 5px;
    text-align: right;
  }

  & > div:nth-of-type(4) {
    max-width: 25%;
    width: 150px;
    margin-right: 5px;
    text-align: right;
  }

  & > span {
    margin-left: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    transition: background-color 100ms ease-in-out;

    &:hover, &:active {
      background-color: rgb(35, 35, 35);
      cursor: pointer;
    }
  }

  ${({ header }) => header === true && css`
    background-color: white;
    box-shadow: 0 2px 5px -3px #444440;
    border-radius: 0 0 5px 5px;
    color: #444440;
    position: sticky;
    top: 0;
    margin: 0 5px;
    width: calc(100% - 10px);
    z-index: 1;
  `};

  & > button {
    user-select: none;
    outline: none;
    border: none;

    margin-left: 5px;
    padding: 6px 8px;
    font-size: 9pt;
    background-color: rgb(120, 120, 120);
    color: rgb(230, 230, 230);
    border-radius: 4px;

    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: rgb(90, 90, 90);
    }

    &:active {
      color: rgb(220, 220, 220);
      background-color: rgb(70, 70, 70);
    }
  }
`;

export const OrderExtraDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  & > div:nth-of-type(1) {
    width: 40px;
  }

  & > div:nth-of-type(2) {
    width: calc(100% - 40px);
    ${({ first }) => first === false && css`
      border-top: 1px solid #adadad;
      padding-top: 3px;
      margin-top: 3px;
    `}
  }
`;

export const OrderExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 15px);
  margin: 10px 0 0 15px;

  padding: 7px 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const Menu = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  background-color: rgb(60, 60, 60);
  border-radius: 6px;
  font-size: 11pt;
  margin-top: 5px;
  color: rgb(220, 220, 220);
`;

export const MenuHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    width: 10px;
    margin-right: 5px;
  }

  & > div:nth-of-type(1) {
    width: calc(40% - 15px);
  }

  & > div:nth-of-type(2) {
    width: fit-content;
    margin-left: 5px;
    text-align: right;
  }

  & > div:nth-of-type(3) {
    width: 15%;
    margin-right: 5px;
    text-align: right;
  }

  & > div:nth-of-type(4) {
    width: 15%;
    margin-right: 5px;
    text-align: right;
  }

  & > span {
    margin-left: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    transition: background-color 100ms ease-in-out;

    &:hover, &:active {
      background-color: rgb(35, 35, 35);
      cursor: pointer;
    }
  }

  ${({ finish }) => finish === true && css`
    & > div:nth-of-type(1) {
      width: calc(40% - 23px);
    }
  `};

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    & > div:nth-of-type(1) {
      width: calc(100% - 15px);
      margin-bottom: 5px;
    }

    & > div:nth-of-type(2) {
      margin-left: 0;
    }

    & > div:nth-of-type(3) {
      width: 30%;
    }

    & > div:nth-of-type(4) {
      width: 30%;
    }
  }
`;

export const MenuBody = styled.div`
  width: 100%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms ease-in-out;
  margin-top: 5px;

  ${({ show }) => show === true && css`
    max-height: 100px;
  `};
`;

export const MenuProduct = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span:first-child {
    width: 80%;
  }

  & > span:nth-child(2) {
    width: 20%;
    text-align: right;
    padding: 0 5px;

    &:hover, &:active {
      background-color: rgb(35, 35, 35);
      cursor: pointer;
    }
  }
`;
