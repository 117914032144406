import type { APIMsgBase } from "../APICaller";
import APICaller from "../APICaller";
import { Category, Product } from "../../model/Product";
import { Qty } from "../../model/Stock";

export type GetProductsResponseType = APIMsgBase & { products: Product[], categories: Category[], qtyList: Qty[] };

function getProducts(cb: (res: GetProductsResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/products", {}, (res) => {
    let result: GetProductsResponseType = JSON.parse(res);
    if (result.error === 0) {
      result.qtyList.forEach(q => fixDatesQty(q));
      result.products.forEach(p => fixDates(p));
    }

    if (cb)
      cb(result);
  });
}

function fixDatesQty(q: Qty) {
  if (!q)
    return;
  q.addedDate = q.addedDate ? new Date(q.addedDate) : undefined;
  if (q.reduction)
    q.reduction.forEach(qr => qr.date = qr.date ? new Date(qr.date) : undefined);
}

function fixDates(p: Product) {
  if (!p || !p.recipes || p.recipes?.length <= 0)
    return;
  p.recipes.forEach(r => r.recipe.addedDate = r.recipe.addedDate ? new Date(r.recipe.addedDate) : undefined);
}

function remove(productID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/product", { id: productID }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function save(product: Product, cb: (res: APIMsgBase)=>{}) {
  let p: Product = { ...product };
  if (!p.recipes)
    p.recipes = [];

  //we got one issue, this is why I wrapped it into a new Date(..)
  p.recipes = p.recipes.map(
    r => ({ ...r, recipe: { ...r.recipe, addedDate: new Date(r.recipe.addedDate).getTime() } }));

  APICaller.ownFetch(false, "POST", "/product", { ...p }, (res) => {
    let result: APIMsgBase = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export type ChangeItemsOrderType = { id: number, orderPlace: number };

function changeOrderPlace(changedProducts: ChangeItemsOrderType[], cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/product/orderplace", { changedProducts }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function toggleProductActive(productID: number, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/product/active/toggle", { productID }, (data) => {
    let res: APIMsgBase = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export const ProductsAPI = {
  getProducts,
  save,
  remove,
  changeOrderPlace,
  toggleProductActive,
  fixDates,
  fixDatesQty,
};
