import type { UserTokenLocalStoreType } from "../APICaller";
import APICaller, { APIMsgBase } from "../APICaller";
import EmailValidator from "../EmailValidator";
import { toast } from "react-toastify";
import EventSystem from "../EventSystem";
import { Contract } from "../../model/Contract";
import { CommissionRecord } from "../../model/CommissionRecord";
import { ShopProfile } from "../../model/ShopProfile";
import { Shop } from "../../model/Shop";
import ContextSystem from "../ContextSystem";
import { AdminProfile } from "../../model/AdminProfile";

let authenticated: boolean | null = null;
let profile: ShopProfile | null = null;
let adminProfile: AdminProfile | null = null;
let checkingAuthentication: boolean = false;
let contracts: Contract[] = null;
let commissionRecords: CommissionRecord[] = null;
let shops: Shop[] = null;

export type PassResetType = APIMsgBase & {};

function requestPasswordReset(email: string, cb: (res: PassResetType)=>{}) {
  APICaller.ownFetch(false, "PUT", "/password", { email }, (res) => {
    let result: PassResetType = JSON.parse(res);
    if (result.error === 0) {
      //The server signs this user off, that's why we need to do it here as well.
      authenticated = false;
      EventSystem.publish(EventSystem.events.authentication_changed, { loggedIn: false, profile: null });
    }
    if (cb)
      cb(result);
  });
}

function login(email: string, password: string, cb: (authenticated: boolean)=>{}) {
  if (!EmailValidator(email)) {
    return false;
  }
  APICaller.ownFetch(false, "POST", "/login", { email, password }, data => defaultLoginHandler(data, cb));
}

export type LoginResult = {
  authenticated: boolean,
  commissionRecords: CommissionRecord[],
  contracts: Contract[],
  profile: ShopProfile | undefined,
  adminProfile: AdminProfile | undefined,
  shops: Shop[],
  token: string
};

function defaultLoginHandler(data: string, cb: (authenticated: boolean)=>{}) {
  let result: APIMsgBase & LoginResult = JSON.parse(data);
  if (result.error !== 0) {
    //toast("Hiba. Kérem próbáld újra később.");
  } else {
    if (result.authenticated) {
      window.localStorage.setItem("usertoken", result.token);
      addUserToken(result.token, result.profile);
      authenticated = true;
      profile = result.profile ?? null;
      adminProfile = result.adminProfile ?? null;
      contracts = result.contracts;
      if (contracts) {
        contracts.forEach(c => {
          c.date = new Date(c.date);
          c.terminateDate = new Date(c.terminateDate);
        });
      }
      commissionRecords = result.commissionRecords;
      if (commissionRecords) {
        commissionRecords.forEach(c => c.date = new Date(c.date));
      }

      shops = result.shops;
      ContextSystem.setShops(false, result.shops);
      EventSystem.publish(EventSystem.events.authentication_changed, { loggedIn: true, profile, adminProfile });
      if (cb)
        cb(true);
      return;
    } else {
      toast("Email cím vagy jelszó hibás.");
    }
  }
  authenticated = false;
  EventSystem.publish(EventSystem.events.authentication_changed, false);
  if (cb)
    cb(false);
}

function reset() {
  authenticated = null;
  profile = null;
  shops = [];
  commissionRecords = [];
  contracts = [];
}

function checkLogin(quite: boolean, cb: (res: LoginResult)=>{}) {
  if (authenticated != null) {
    if (!authenticated) {
      if (cb)
        cb({ authenticated, profile: null, shops: [], token: null, commissionRecords: [], contracts: [] });
      return;
    } else if (authenticated && profile != null) {
      if (cb)
        cb({ authenticated, profile, shops, token: null, commissionRecords, contracts });
      return;
    }
  }

  if (checkingAuthentication) {
    setTimeout(() => checkLogin(quite, cb), 100);
    return;
  }

  checkingAuthentication = true;

  APICaller.ownFetch(quite, "POST", "/login", {}, (res) => {
    let oldA = authenticated;
    let result: APIMsgBase & LoginResult = JSON.parse(res);

    if (result.error === 0) {
      profile = result.profile;
      contracts = result.contracts;
      contracts.forEach(c => {
        c.date = new Date(c.date);
        c.terminateDate = new Date(c.terminateDate);
      });
      commissionRecords = result.contracts;
      commissionRecords.forEach(c => c.date = new Date(c.date));
      authenticated = true;
      shops = result.shops;
    } else {
      profile = null;
      contracts = null;
      authenticated = false;
      shops = [];
      commissionRecords = [];
    }
    if (cb)
      cb(result);
    checkingAuthentication = false;

    if (oldA !== authenticated)
      EventSystem.publish(EventSystem.events.authentication_changed, { loggedIn: authenticated, profile });
  });
}

function logout() {
  // APICaller.ownFetch(true, "DELETE", "/login", {});
  window.localStorage.removeItem("usertoken");
  authenticated = false;
  profile = null;
  contracts = null;
  commissionRecords = null;
  ContextSystem.setShops(true, []);
  ContextSystem.setSelectedShop(null);
  EventSystem.publish(EventSystem.events.authentication_changed, { loggedIn: false, profile: null });
}

function changePassword(oldPass: string, newPass: string, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(
    false,
    "POST",
    "/password",
    {
      old: oldPass,
      new: newPass,
    },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

function addUserToken(userToken: string, shopProfile: ShopProfile) {
  let userTokens: UserTokenLocalStoreType[] = [];
  if (window.localStorage.getItem("usertokens") != null) {
    try {
      userTokens = JSON.parse(window.localStorage.getItem("usertokens"));
    } catch (e) {

    }
  }

  let u: UserTokenLocalStoreType = userTokens.find(u => u.usertoken === userToken);
  if (u)
    return;
  userTokens = userTokens.filter(us => us.shopProfile.id !== shopProfile.id);

  userTokens.push({ usertoken: userToken, pin: shopProfile.pin, shopProfile });
  window.localStorage.setItem("usertokens", JSON.stringify(userTokens));
}

function savePublicInfo(publicEmail: string, publicTel: string, cb: (res: APIMsgBase)=>{}) {
  APICaller.ownFetch(false, "POST", "/publicInfo", { publicEmail, publicTel }, (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

function savePreparedOrderInfo(
  preparedOrderMaxDays: number, showPreparedOrdersBeforeMinutes: number, preparedOrderOnlyOnlinePayment: boolean,
  cb: (res: APIMsgBase)=>{},
) {
  APICaller.ownFetch(false, "POST", "/preorderInfo", {
      preparedOrderMaxDays,
      showPreparedOrdersBeforeMinutes,
      preparedOrderOnlyOnlinePayment,
    }, (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    },
  );
}

export const AuthAPI = {
  login,
  checkLogin,
  logout,
  requestPasswordReset,
  changePassword,
  defaultLoginHandler,
  savePublicInfo,
  savePreparedOrderInfo,
  reset,
};
