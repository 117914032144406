import ContextSystem from "../utils/ContextSystem";

export const NotificationType = {
  EMAIL: 0,
  MESSENGER: 1,
  SMS: 2,
  PHONE_CALL: 3,
  WHATSAPP: 4,
  VIBER: 5,
  SIGNAL: 6,
};

NotificationType.getName = (type: number): string => {
  switch (type) {
    case NotificationType.EMAIL:
      return "Email";
    case NotificationType.MESSENGER:
      return "Facebook Messenger";
    case NotificationType.SMS:
      return "SMS üzenet";
    case NotificationType.PHONE_CALL:
      return "Telefonhívás";
    case NotificationType.WHATSAPP:
      return "WhatsApp üzenet";
    case NotificationType.VIBER:
      return "Viber üzenet";
    case NotificationType.SIGNAL:
      return "Signal üzenet";
    default:
      return "Hiba";
  }
};

export class PartnerNotification {
  id: number;
  enabled: boolean;
  partnerID: number;
  messengerASID: string;
  messengerApproveText: string;
  messengerApproved: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  notificationType: number; //NotificationType

  constructor() {
    this.id = -1;
    this.enabled = true;
    this.partnerID = ContextSystem.selectedShop.id;
    this.messengerASID = "";
    this.messengerApproveText = "";
    this.messengerApproved = "";
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.phoneNumber = "";
    this.notificationType = -1;
  }
}
