import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "babel-polyfill";
import datePolyfill from "./utils/DatePolyfill";
import arrayPolyfill from "./utils/ArrayPolyfill";
import { polyfill } from "es6-promise";
import ContextSystem from "./utils/ContextSystem";
// import XLSX from 'xlsx';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import EventSystem from "./utils/EventSystem";
import Config from "./utils/Config";
import { languages } from "./components/modals/CouponEditor";
import VersionAPI from "./utils/api/VersionAPI";

let initialized = false;
let googlePlacesAPILoaded = false;

function initialize() {
  Sentry.init({
    dsn: "https://f9d161cb78b94a4184312e7f482573fe@o902080.ingest.sentry.io/4504033221869568",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  datePolyfill();
  arrayPolyfill();
  //polyfill-ing other languages (e.g.: es6 can work on ie as well)
  polyfill();
  initialized = true;
  fontawesomeloader();
  reloadAtMidnight();
  loadGoogleApisScript();
  ContextSystem.init();
  initUSB();
}

function loadGoogleApisScript() {
  if (googlePlacesAPILoaded || (window.google && window.google.maps)) {
    let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("google-maps-api-js");
    for (let element: Element of elements) {
      document.head.removeChild(element);
    }
    window.google.maps = undefined;
  }

  let language: string = languages ? languages[ContextSystem.language]?.code ?? "HU" : "HU";
  googlePlacesAPILoaded = true;
  let src = "https://maps.googleapis.com/maps/api/js?key=" + Config.googleApiKey + "&libraries=places&language=" + language;
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.className = "google-maps-api-js";
  script.onload = () => EventSystem.publish(EventSystem.events.googlePlacesScriptLoaded);
  document.head.appendChild(script);
}

Array.prototype.remove = function() {
  let what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

let push = Array.prototype.push;
let splice = Array.prototype.splice;

Array.prototype.rotate = function(count: number) {
  let len = this.length >>> 0; // convert to uint
  count = count >> 0; // convert to int

  // convert count to value in range [0, len)
  count = ((count % len) + len) % len;

  // use splice.call() instead of this.splice() to make function generic
  push.apply(this, splice.call(this, 0, count));
  return this;
};

function fontawesomeloader() {
  const css = document.createElement("link");
  css.href = "https://use.fontawesome.com/releases/v5.1.0/css/all.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(css);
}

function reloadAtMidnight() {
  let now = new Date();
  let midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 5, 0, 0);

  let milliseconds = midnight - now;

  if (milliseconds < 0) {
    milliseconds += 86400000; // it's after 10am, try 10am tomorrow.
  }
  setTimeout(function() {
    window.location.reload(true);
    reloadAtMidnight();
  }, milliseconds);
}

async function checkVersion() {
  await VersionAPI.checkVersion((res) => {
    if (res.error !== 0)
      return;
    if (Config.version < res.appVersion.version && res.appVersion.hasToUpdate) {
      // noinspection JSDeprecatedSymbols
      // noinspection JSUnresolvedFunction
      window.location.reload(true);
      return;
    }

    Config.configurations = res.configurations;
  });
}

function initUSB() {
  // setTimeout(() => {
  // console.log('devices: ');
  // navigator.usb.getDevices().then(devices => {
  //   devices.forEach(device => {
  //     console.log("device:", JSON.stringify(device));
  //   });
  // })
  //   navigator.usb.requestDevice({})
  //     .then(device => {
  //       console.log(device.productName);      // "Arduino Micro"
  //       console.log(device.manufacturerName); // "Arduino LLC"
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }, 5000);
}

checkVersion().then(() => initialize());
