import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ProductEditor from "./ProductEditor";
import SortableTree, { changeNodeAtPath } from "react-sortable-tree";
import styled from "styled-components";
import { FaPlus, FaTimes, FaTrashAlt } from "react-icons/all";
import { toast } from "react-toastify";
import CategoriesAPI, { ChangeCategoriesType } from "../../utils/api/CategoriesAPI";
import ContextSystem from "../../utils/ContextSystem";
import { Category, CategoryViewTypes, Product, TranslatableString } from "../../model/Product";
import Language, { Names } from "../../utils/Language";
import { HeaderButton } from "../header/Header";
import { Button } from "../FormComponents";
import { languages, ReactFlagsSelect } from "./CouponEditor";
import ConfirmationModal from "./ConfirmationModal";

const RowButton = styled.button`
  height: 100%;
  padding: 5px 5px;
  border-radius: 4px;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: none;
  box-shadow: none;
  color: #242424;

  &:hover {
    background-color: #999999;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 65vh;
`;

type Node = { title: string, id: number, children: Node[] };

export default class CategoriesEditor extends Component {
  state: {
    showModal: boolean,
    categories: Category[],
    products: Product[],
    nodeClicked: Node | null,
    isInput: boolean,
    treeData: Node[],
    language: number,
    selectedLanguage: number,
  } = {
    showModal: false,
    categories: [],
    products: [],
    nodeClicked: null,
    isInput: false,
    treeData: [],
    language: ContextSystem.language,
    selectedLanguage: languages[0].id,
  };

  handleNodeClick = (node) => {
    this.setState({
      nodeClicked: node,
      // treeData: this.state.treeData,
      isInput: true,
    });
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.edit_product_categories, ({ categories, products }) => {
      this.load(categories, products);
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, products }) => {
      if (language !== undefined)
        this.setState({ language });
      if (products !== undefined)
        this.setState({ products });
    });
  }

  findInTree = (catID, tree) => {
    for (let childNode of tree) {
      if (childNode.id === catID)
        return childNode;
      else {
        if (childNode.children && childNode.children.length > 0) {
          let f = this.findInTree(catID, childNode.children);
          if (f !== null)
            return f;
        }
      }
    }
    return null;
  };

  load(categories: Category[], products: Product[]) {
    let categoriesCopy: Category[] = [];
    for (let c of categories) {
      if (!c.enabled || c.view === CategoryViewTypes.PRIVATE_VIEW || c.partnerID !== ContextSystem.selectedShop?.id)
        continue;
      categoriesCopy.push({ ...c });
    }

    let remainingCategories: Category[] = [];
    for (let c: Category of categoriesCopy) {
      remainingCategories.push(c);
    }

    remainingCategories = remainingCategories.sort((c1: Category, c2: Category) =>
      c2.parent - c1.parent === 0
      ? c1.orderNumber - c2.orderNumber
      : c1.parent - c2.parent,
    );

    let treeData = [];

    let language = languages.find(l => l.id === this.state.selectedLanguage);
    let languageCode: string = language.languageCode;

    while (remainingCategories.length > 0) {
      let removeLater = [];
      for (let c of remainingCategories) {
        let newNode = {
          title: c.name?.translation?.find(tr => tr.languageCode === languageCode)?.value ?? "",
          children: [],
          id: c.id,
          expanded: true,
        };
        if (c.parent === 0) {
          treeData.push(newNode);
          removeLater.push(c);
        } else {
          let parentNode = this.findInTree(c.parent, treeData);
          if (parentNode) {
            if (!parentNode.children)
              parentNode.children = [];
            parentNode.children.push(newNode);
            removeLater.push(c);
          }
        }
      }
      remainingCategories = remainingCategories.filter(value => !removeLater.includes(value));
    }

    this.treeDataChanged(treeData);

    this.setState({
      categories: categoriesCopy,
      treeData,
      showModal: true,
      products,
      isInput: false,
      nodeClicked: null,
    });
  }

  treeDataHistory: Node[][] = [];

  treeDataChanged(treeData: Node[]) {
    this.treeDataHistory.push(treeData);
  }

  treeDataUndo() {
    if (this.treeDataHistory.length <= 0)
      return;

    // noinspection JSUnusedAssignment
    let history = this.treeDataHistory.pop(); //twice!
    history = this.treeDataHistory[this.treeDataHistory.length - 1];
    this.setState({ treeData: history });
    this.load(ContextSystem.categories, ContextSystem.products);
  }

  render() {
    // let language = languages.find(l => l.id === this.state.selectedLanguage);
    // let languageCode: string = language.languageCode;

    return (
      <Modal size={"lg"} show={this.state.showModal} onEscapeKeyDown={() => this.setState({ showModal: false })}>
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.ProductCategories)}</Modal.Title>
          <ReactFlagsSelect
            selected={languages[this.state.selectedLanguage].code}
            countries={languages.map(l => l.code)}
            customLabels={(() => {
              let o = {};
              languages.forEach(l => o[l.code] = l.label);
              return o;
            })()}
            onSelect={code => this.setState({ selectedLanguage: languages.find(l => l.code === code).id }, () => {
              this.load(this.state.categories, this.state.products);
            })}
          />
        </Modal.Header>
        <Modal.Body>
          <Wrapper>
            <HeaderButton onClick={() => this.addNewCategory(null)}>
              <FaPlus /> {Language.getName(Names.AddNewCategory)}
            </HeaderButton>
            <SortableTree
              treeData={this.state.treeData}
              onChange={treeData => this.treeDataChanged(treeData)}
              maxDepth={2}
              getNodeKey={({ node }) => node.id}
              onMoveNode={e => this.nodeMoved(e)}
              generateNodeProps={({ node, path/*, treeIndex, lowerSiblingCounts, isSearchMatch, isSearchFocus*/ }) => {
                let buttons = [];
                if (this.state.nodeClicked === node && this.state.isInput) {
                  buttons.push(
                    <RowButton
                      onClick={e => {
                        e.stopPropagation();
                        this.cancelRename();
                      }}
                    >
                      <FaTimes />
                    </RowButton>,
                  );
                }
                if (path.length === 1) {
                  buttons.push(
                    <RowButton
                      onClick={() => this.addNewCategory(node, path)}
                    >
                      <FaPlus />
                    </RowButton>,
                  );
                }
                buttons.push(
                  <RowButton
                    onClick={() => this.deleteCategory(node.id)}
                  >
                    <FaTrashAlt />
                  </RowButton>,
                );
                return {
                  title:
                    this.state.isInput && node === this.state.nodeClicked ? (
                      <form onSubmit={e => {
                        e.preventDefault();
                        this.handleCategoryRename();
                      }}>
                        <input
                          style={{ fontSize: "1.1rem" }}
                          value={node.title}
                          onChange={(event) => {
                            node.title = event.target.value;
                            // noinspection JSUnusedGlobalSymbols
                            this.setState((state) => ({
                              treeData: changeNodeAtPath({
                                treeData: state.treeData,
                                path,
                                newNode: node,
                                getNodeKey: ({ node }) => node.id,
                              }),
                            }));
                          }}
                          onBlur={() => this.handleCategoryRename()}
                        />
                      </form>
                    ) : (
                      <span onClick={() => this.handleNodeClick(node)}>
                        {node.title}
                      </span>
                    ),
                  buttons,
                  // onClick: () => this.handleNodeClick(node),
                };
              }}
            />
          </Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
            {Language.getName(Names.Close)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  nodeMoved(e) {
    let { nextParentNode, node, treeData/*, nextPath, nextTreeIndex, path, prevPath, prevTreeIndex, treeIndex*/ } = e;
    /*
     nextParentNode: {title: "Főételek", children: Array(2), id: 2, expanded: true}
     nextPath: (2) [2, 4]
     nextTreeIndex: 1
     node: {title: "Húsos főétel", children: Array(0), id: 4}
     path: (2) [2, 4]
     prevPath: (2) [2, 4]
     prevTreeIndex: 2
     treeData: (3) [{…}, {…}, {…}]
     treeIndex: 1
     */

    let categories = [];
    for (let category of this.state.categories) {
      let cat = { ...category };
      if (cat.id === node.id) {
        cat.parent = nextParentNode ? nextParentNode.id : 0;
      }
      categories.push(cat);
    }

    //renumbering the "orderNumbers"
    let k = 1;
    for (let mainCategory: Node of treeData) {
      let c = ProductEditor.getCategoryById(mainCategory.id, categories);
      if (!c)
        continue;
      c.orderNumber = k;
      if (k >= 10) {
        toast(Language.getName(Names.MaxMainCategories));
        this.treeDataUndo();
        return;
      }
      k++;
      if (mainCategory.children && mainCategory.children.length > 0) {
        let n = 1;
        for (let child: Node of mainCategory.children) {
          c = ProductEditor.getCategoryById(child.id, categories);
          if (!c)
            continue;
          c.orderNumber = n;
          if (n >= 10) {
            toast(Language.getName(Names.MaxSubCategories));
            this.treeDataUndo();
            return;
          }

          if (child.children.length > 0) {
            toast(Language.getName(Names.Max2LevelCategories));
            this.treeDataUndo();
            return;
          }
          n++;
        }
      }
    }

    let changedCategories: ChangeCategoriesType[] = [];
    for (let category of categories) {
      changedCategories.push({
        orderNumber: category.orderNumber,
        id: category.id,
        parent: category.parent,
      });
    }

    CategoriesAPI.changeOrderNumber(changedCategories, res => {
      if (res.error === 0) {
        this.load(categories, this.state.products);
        toast(Language.getName(Names.OrderingSaved));
      } else {
        this.load(this.state.categories, this.state.products);
      }
    });
  }

  handleCategoryRename() {
    let newTitle = this.state.nodeClicked.title;
    let categoryID = this.state.nodeClicked.id;

    CategoriesAPI.modify(categoryID, newTitle, this.state.selectedLanguage, res => {
      if (res.error === 0) {
        let category: Category = ProductEditor.getCategoryById(categoryID, this.state.categories);
        ContextSystem.mergeCategories([category]);
        toast(Language.getName(Names.Modified));
        this.cancelRename();

        let language = languages.find(l => l.id === this.state.selectedLanguage);
        let languageCode: string = language.languageCode;

        let categories: Category[] = [];
        this.state.categories.forEach(c => {
          if (c.id === categoryID)
            c.name = TranslatableString.modify(c.name, newTitle, languageCode);
          categories.push(c);
        });

        this.load(categories, this.state.products);
      }
    });
  }

  cancelRename() {
    this.setState({ isInput: false });
  }

  deleteCategory(id) {
    if (this.state.categories.length <= 1) {
      toast(Language.getName(Names.YouNeedMinOneCategory));
      return;
    }
    if (this.getChildrenNumber(id) > 0) {
      toast(Language.getName(Names.OnlyEmptyCategoryCanBeRemoved));
      return;
    }
    if (this.getProductsInCategory(id) > 0) {
      toast(Language.getName(Names.OnlyEmptyCategoryCanBeRemoved));
      return;
    }

    ConfirmationModal.showModal(
      Language.getName(Names.Sure) + "!",
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {},
      ()=>{
        CategoriesAPI.remove(id, res => {
          if (res.error === 0) {
            let categories = [];
            for (let category of this.state.categories) {
              if (category.id !== id)
                categories.push(category);
            }

            this.load(categories, this.state.products);
            toast(Language.getName(Names.Removed));
          }
        });
      }
    );
  }

  getChildrenNumber(id) {
    let n = 0;
    for (let category of this.state.categories) {
      if (category.parent === id)
        n++;
    }
    return n;
  }

  getProductsInCategory(catID) {
    let n = 0;
    for (let p of this.state.products) {
      if (p.categoryID === catID)
        n++;
    }
    return n;
  }

  addNewCategory(parentNode: Node, path: Node[]) {
    let childrenNumber = parentNode ? parentNode.children ? parentNode.children.length : 0 : this.state.treeData.length;
    let parent = parentNode ? parentNode.id : 0;
    if (parent === 0 && childrenNumber > 10) {
      toast(Language.getName(Names.MaxMainCategories));
      return;
    } else if (parent !== 0 && childrenNumber > 10) {
      toast(Language.getName(Names.MaxSubCategories));
      return;
    }

    if (path && path.length >= 2) {
      toast(Language.getName(Names.Max2LevelCategories));
      return;
    }

    CategoriesAPI.add(parent, res => {
      if (res.error === 0) {
        let newCategory = res.category;
        let categories = [];
        categories.push(newCategory);
        for (let category of this.state.categories) {
          categories.push(category);
        }
        this.load(categories, this.state.products);
        toast(Language.getName(Names.Added));
      }
    });
  }
}
