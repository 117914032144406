import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { AiOutlineStop, BsClockHistory, BsListCheck, CgComponents, FaCompressArrowsAlt, FaPen, FaPlus, FaTrashAlt, GiWheat, ImBoxAdd, TbArrowsLeftRight, TbBuildingWarehouse } from "react-icons/all";
import Language, { Names } from "../../utils/Language";
import { Product, Raw, Recipe, Stackable, TranslatableString } from "../../model/Product";
import { Controls, Div, HeaderButton, HeaderButtonTopRightCircle, HeaderButtonWrapper } from "./Products";
import { RawAPI } from "../../utils/api/RawAPI";
import { toast } from "react-toastify";
import type { QtyType } from "../../model/Stock";
import { Qty, QtyTypes, SavedStorageMovement, Storage, StorageTypes } from "../../model/Stock";
import { RecipeAPI } from "../../utils/api/RecipeAPI";
import { StorageAPI } from "../../utils/api/StorageAPI";
import { MultiSelect as MultiSelectOrg } from "react-multi-select-component";
import type { Option } from "../modals/OrderStateCommentEditor";
import { Label } from "../modals/ContractsDetailsModal";
import { CheckBox } from "./Orders";
import { Input } from "../FormComponents";
import { ShopProfile, ShopProfileTypes } from "../../model/ShopProfile";
import { Shop } from "../../model/Shop";
import ConfirmationModal from "../modals/ConfirmationModal";

const QtyText = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > span {
    min-width: 100px;
    margin-right: 3px;
  }
`;

export const QtyWarning = styled.div`
  width: 6px;
  height: 6px;
  margin: 6px 3px 3px 3px;
  background-color: #ce390b;
  border-radius: 100px;
  display: inline-block;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ListWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 24px;
  height: 100%;

  overflow-y: scroll;
`;

const ListElement = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px gray;

  min-height: 50px;
  background-color: white;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FilterStorageWrapper = styled.div`
  margin-right: auto;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const MultiSelect = styled(MultiSelectOrg)`
  margin: ${({ margin }) => margin ?? "0 0 0 12px"};;
  width: ${({ width }) => width ?? "180px"};
  display: ${({ display }) => display ?? "auto"};
  height: 30px;
  font-size: 10pt;
  z-index: 2;
`;

export default class RawMaterials extends Component {
  state: {
    language: number,
    raws: ({ qtyType: QtyType } & Raw)[],
    products: ({ qtyType: QtyType } & (Product))[],
    qtyList: Qty[],
    recipes: Recipe[],
    storages: Storage[],
    savedStorageMovements: SavedStorageMovement[],
    selected: number,
    storageOptions: Option[],
    selectedStorageOptions: Option[],
    showOnlyEmpty: boolean,
    qtyTypeOptions: Option[],
    selectedQtyTypeOptions: Option[],
    searchText: string,
    shopProfile: ShopProfile,
    shop: Shop,
  } = {
    language: ContextSystem.language,
    raws: [],
    products: [],
    qtyList: [],
    recipes: [],
    storages: [],
    savedStorageMovements: [],
    selected: 0,
    storageOptions: [],
    selectedStorageOptions: [],
    showOnlyEmpty: false,
    qtyTypeOptions: [],
    selectedQtyTypeOptions: [],
    searchText: "",
    shopProfile: ContextSystem.profile,
    shop: ContextSystem.selectedShop,
  };

  eventIDs = [];

  load() {
    this.setState({
      raws: ContextSystem.raws.filter(e => e.partnerID === ContextSystem.selectedShop?.id && e.enabled),
      products: ContextSystem.products.filter(e => e.partnerID === ContextSystem.selectedShop?.id && e.enabled),
      recipes: ContextSystem.recipes.filter(e => e.partnerID === ContextSystem.selectedShop?.id && e.enabled),
      qtyList: ContextSystem.qtyList.filter(
        r => r.enabled && (r.storageID === -1 || ContextSystem.storages.find(s => s.id === r.storageID)?.shopIDs
          .includes(ContextSystem.selectedShop?.id))),
      storages: ContextSystem.storages.filter(e => e.enabled && e.shopIDs.includes(ContextSystem.selectedShop.id)),
      savedStorageMovements: ContextSystem.savedStorageMovements.filter(
        e => e.enabled && e.shopID === ContextSystem.selectedShop.id),
    });

    let storageOptions: Option[] = [];
    storageOptions.push({ value: -1, label: Language.getName(Names.DefaultStorage) });
    for (let storage: Storage of ContextSystem.storages) {
      if (storage.enabled)
        storageOptions.push({ value: storage.id, label: storage.name });
    }

    let qtyTypeOptions: Option[] = [];
    qtyTypeOptions.push({ value: 0, label: Language.getName(Names.Raws) });
    qtyTypeOptions.push({ value: 1, label: Language.getName(Names.Products) });
    qtyTypeOptions.push({ value: 2, label: Language.getName(Names.ProcessedProducts) });

    this.setState({
      storageOptions,
      selectedStorageOptions: storageOptions.map(i => ({ ...i })),
      qtyTypeOptions,
      selectedQtyTypeOptions: qtyTypeOptions.map(i => ({ ...i })),
    });
  }

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    this.load();
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, raws, qtyList, recipes, storages, products, savedStorageMovements, profile, selectedShop }) => {
        if (language !== undefined)
          this.setState({ language });
        if (profile !== undefined)
          this.setState({ shopProfile: profile });
        if (selectedShop !== undefined)
          this.setState({ shop: selectedShop });
        if (
          raws !== undefined || recipes !== undefined || qtyList !== undefined
          || storages !== undefined || products !== undefined
          || savedStorageMovements !== undefined
        )
          this.load();
      },
    );
    this.eventIDs.push(eid);
  }

  static getTimeDurationText(minutes: number) {
    let years: number = Math.floor(minutes / 525948.766);
    minutes -= years * 525948.766;
    let months: number = Math.floor(minutes / 43829.064);
    minutes -= months * 43829.064;
    let weeks: number = Math.floor(minutes / 10080);
    minutes -= weeks * 10080;
    let days: number = Math.floor(minutes / 1440);
    minutes -= days * 1440;
    let hours: number = Math.floor(minutes / 60);
    minutes -= hours * 60;

    let text: string;

    if (years > 0) {
      text = Language.getName(Names.Approx) + ". " + years + " " + (years === 1
                                                                    ? Language.getName(Names.YearUnit)
                                                                    : Language.getName(Names.YearsUnit));
      if (months > 0)
        text += "," + months + " " + (months === 1 ? Language.getName(Names.MonthUnit) : Language.getName(
          Names.MonthsUnit));
    } else if (months > 0) {
      text = Language.getName(Names.Approx) + ". " + months + " " + (months === 1
                                                                     ? Language.getName(Names.MonthUnit)
                                                                     : Language.getName(Names.MonthsUnit));
      if (weeks > 0)
        text += "," + weeks + " " + (weeks === 1 ? Language.getName(Names.WeekUnit) : Language.getName(
          Names.WeeksUnit));
    } else if (weeks > 0)
      text = Language.getName(Names.Approx) + ". " + weeks + " " + (weeks === 1
                                                                    ? Language.getName(Names.WeekUnit)
                                                                    : Language.getName(Names.WeeksUnit));
    else if (days > 0)
      text = Language.getName(Names.Approx) + ". " + days + " " + (days === 1
                                                                   ? Language.getName(Names.DayUnit)
                                                                   : Language.getName(Names.DaysUnit));
    else if (hours > 0)
      text = hours === 1 ? Language.getName(Names.HourUnit) : Language.getName(Names.HourUnit);
    else if (minutes > 0)
      text = minutes + " " + (minutes === 1 ? Language.getName(Names.MinuteUnit) : Language.getName(Names.MinuteUnit));

    return text;

  }

  getEditEventType(): number {
    if (this.state.selected === 0)
      return EventSystem.events.edit_raw_material;
    if (this.state.selected === 1)
      return EventSystem.events.edit_recipe;
    if (this.state.selected === 2)
      return EventSystem.events.edit_storage;
    return EventSystem.events.edit_raw_material;
  }

  storageOptionsSelected(s: { value: number, label: string }[]) {
    this.setState({ selectedStorageOptions: s });
  }

  qtyTypeOptionsSelected(s: { value: number, label: string }[]) {
    this.setState({ selectedQtyTypeOptions: s });
  }

  filter(s: Stackable): boolean {
    if (!s.enabled)
      return false;

    let includeRaws: boolean = this.state.selectedQtyTypeOptions.find(o => o.value === 0) !== undefined;
    let includeProducts: boolean = this.state.selectedQtyTypeOptions.find(o => o.value === 1) !== undefined;
    let includeProcessedProducts: boolean = this.state.selectedQtyTypeOptions.find(o => o.value === 2) !== undefined;

    if (s.qtyType === QtyTypes.RAW) {
      if (!s.semiProduct && !includeRaws)
        return false;
      if (s.semiProduct && !includeProcessedProducts)
        return false;
    } else if (s.qtyType === QtyTypes.PRODUCT) {
      if (!includeProducts || !s.countStock)
        return false;
    }

    let search: string = this.state.searchText.toLowerCase().trim();
    if (search.length > 0) {
      let strings: string[] = [];
      TranslatableString.getValues(s.name).forEach(v => strings.push(v));
      TranslatableString.getValues(s.unit).forEach(v => strings.push(v));
      TranslatableString.getValues(s.details).forEach(v => strings.push(v));
      for (let s of strings) {
        if (s.toLowerCase().trim().includes(search))
          return true;
      }
      return false;
    } else {
      return true;
    }
  }

  removeButtonClicked(st: Stackable){
    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {},
      ()=>{
        RawAPI.remove(st.id, (res) => {
          if (res.error !== 0)
            return;

          toast(Language.getName(Names.Removed));
          ContextSystem.removeRaw(st.id);
        })
      }
    );
  }

  render() {
    return (
      <Wrapper>
        <HeaderButtonWrapper margin_top={"6px"}>
          {this.state.selected === 0 && (() => {
            return (
              <FilterStorageWrapper>
                <MultiSelect
                  width={"150px"}
                  options={this.state.storageOptions}
                  value={this.state.selectedStorageOptions}
                  onChange={(s) => this.storageOptionsSelected(s)}
                  labelledBy={"Select"}
                  disableSearch
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                  }}
                  overrideStrings={{
                    selectSomeItems: Language.getName(Names.ChooseStorages),
                    allItemsAreSelected: Language.getName(Names.AllStorages),
                    selectAll: Language.getName(Names.SelectAll),
                    search: Language.getName(Names.Search),
                    clearSearch: Language.getName(Names.Clear),
                  }}
                  hasSelectAll={true}
                />
                <MultiSelect
                  width={"150px"}
                  options={this.state.qtyTypeOptions}
                  value={this.state.selectedQtyTypeOptions}
                  onChange={(s) => this.qtyTypeOptionsSelected(s)}
                  labelledBy={"Select"}
                  disableSearch
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                  }}
                  overrideStrings={{
                    selectSomeItems: Language.getName(Names.Choose),
                    allItemsAreSelected: Language.getName(Names.AllTypes),
                    selectAll: Language.getName(Names.SelectAll),
                    search: Language.getName(Names.Search),
                    clearSearch: Language.getName(Names.Clear),
                  }}
                  hasSelectAll={true}
                />
                <CheckBox>
                  <input
                    type={"checkbox"}
                    checked={this.state.showOnlyEmpty}
                    onChange={() => this.setState({
                      showOnlyEmpty: !this.state.showOnlyEmpty,
                    })}
                  />
                  <Label width={"80px"} onClick={() => this.setState({
                    showOnlyEmpty: !this.state.showOnlyEmpty,
                  })}>
                    {Language.getName(Names.ShowOnlyShortages)}
                  </Label>
                </CheckBox>
                <Input
                  value={this.state.searchText}
                  type="text"
                  placeholder={Language.getName(Names.Search)}
                  onChange={(e) => this.setState({ searchText: e.target.value })}
                  width={"150px"}
                />
              </FilterStorageWrapper>
            );
          })()}
          {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_MENU) &&
            <HeaderButton margin_right={"0"}
                          svg_font_size={"18pt"}
                          background_color={this.state.selected === 0 ? "#E6E6E6" : undefined}
                          onClick={() => this.setState({ selected: 0 })}
            >
              <GiWheat />
              <span>{Language.getName(Names.Items)}</span>
            </HeaderButton>
          }
          {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_STORAGES) &&
            <HeaderButton margin_right={"0"}
                          margin_left={"0"}
                          svg_font_size={"18pt"}
                          background_color={this.state.selected === 2 ? "#E6E6E6" : undefined}
                          onClick={() => this.setState({ selected: 2 })}
            >
              <TbBuildingWarehouse />
              <span>{Language.getName(Names.Storages)}</span>
            </HeaderButton>
          }
          {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MODIFY_MENU) &&
            <HeaderButton margin_left={"0"}
                          svg_font_size={"18pt"}
                          background_color={this.state.selected === 1 ? "#E6E6E6" : undefined}
                          onClick={() => this.setState({ selected: 1 })}
            >
              <CgComponents />
              <span>{Language.getName(Names.Recipes)}</span>
            </HeaderButton>
          }
          {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.STORAGE_INWARD_HANDLE) &&
            <>
              <HeaderButton svg_font_size={"18pt"}
                            onClick={() => EventSystem.publish(EventSystem.events.start_inward_handling)}
              >
                <ImBoxAdd />
                <span>{Language.getName(Names.InwardHandling)}</span>
              </HeaderButton>
              <HeaderButton svg_font_size={"18pt"}
                            onClick={() => EventSystem.publish(EventSystem.events.start_manufacturing)}
              >
                <FaCompressArrowsAlt />
                <span>{Language.getName(Names.Production)}</span>
              </HeaderButton>

            </>
          }
          {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.STOCK_TAKING) &&
            <HeaderButton svg_font_size={"18pt"}
                          onClick={() => EventSystem.publish(EventSystem.events.start_stock_taking)}
            >
              <BsListCheck />
              <span>{Language.getName(Names.StockTaking)}</span>
            </HeaderButton>
          }
          {(
              this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(ShopProfileTypes.MOVE_STORAGE)
              || this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                ShopProfileTypes.REQUEST_STORAGE_MOVEMENT)
            )
            && this.state.storages.length > 0 &&
            <HeaderButton svg_font_size={"18pt"}
                          onClick={() => EventSystem.publish(EventSystem.events.start_move_storage)}
            >
              <TbArrowsLeftRight />
              <span>{Language.getName(Names.Move)}</span>
              {this.state.savedStorageMovements.length > 0 &&
                <HeaderButtonTopRightCircle>
                  {this.state.savedStorageMovements.length}
                </HeaderButtonTopRightCircle>
              }
            </HeaderButton>
          }
          {(
              (this.state.selected === 0 && this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                ShopProfileTypes.MODIFY_MENU))
              || (this.state.selected === 1 && this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                ShopProfileTypes.MODIFY_MENU))
              || (this.state.selected === 2 && this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                ShopProfileTypes.MODIFY_STORAGES))
            ) &&
            <HeaderButton svg_font_size={"18pt"} onClick={() => EventSystem.publish(this.getEditEventType())}>
              <FaPlus />
              <span>{Language.getName(Names.AddButtonText)}</span>
            </HeaderButton>
          }
        </HeaderButtonWrapper>
        <ListWrapper>
          {this.state.selected === 0 &&
            <>
              {(() => {
                let stackables: Stackable[] = [];

                this.state.raws.forEach(r => {
                  r.qtyType = QtyTypes.RAW;
                  if (this.filter(r))
                    stackables.push(r);
                });

                this.state.products.forEach(p => {
                  p.qtyType = QtyTypes.PRODUCT;
                  if (this.filter(p))
                    stackables.push(p);
                });

                return (
                  <>
                    {stackables.length <= 0 &&
                      <>
                        {Language.getName(Names.NoRawMaterialsToShowAddRightTop)}
                      </>
                    }
                    {stackables.map((st, i) => {
                      let qtySum: number = 0;
                      let show: boolean = !this.state.showOnlyEmpty;
                      for (let so: Option of this.state.selectedStorageOptions) {
                        let q: number = Qty.countSum(this.state.qtyList, st.qtyType, st.id, so.value);
                        if (q < st.minQty)
                          show = true;
                        qtySum += q;
                      }
                      if (qtySum < st.minQty)
                        show = true;

                      if (!show)
                        return <React.Fragment key={i} />;

                      let name: string = TranslatableString.get(st.name);
                      let unit: string = st.qtyType === QtyTypes.RAW
                                         ? TranslatableString.get(st.unit)
                                         : Language.getName(Names.pcs);
                      let minValue: string = st.qtyType === QtyTypes.RAW && st.minQty > 0 ? st.minQty + " " + unit :
                                             <AiOutlineStop />;
                      let expirationTime: string = st.qtyType === QtyTypes.RAW && st.expirationMinutes > 0
                                                   ? RawMaterials.getTimeDurationText(st.expirationMinutes)
                                                   :
                                                   <AiOutlineStop />;

                      return (
                        <ListElement key={i}>
                          <Div width={"35px"}>
                            #{st.id}
                          </Div>
                          <Div width={"20%"}>
                            <b>{Language.getName(Names.Name)}</b>
                            <br />
                            {name}
                          </Div>
                          <Div width={"95px"}>
                            <b>{Language.getName(Names.Unit)}</b>
                            <br />
                            {unit}
                          </Div>
                          <Div width={"60px"}>
                            <b>{Language.getName(Names.Min)}</b>
                            <br />
                            {minValue}
                          </Div>
                          <Div width={"100px"}>
                            <b>{Language.getName(Names.ShelfLife)}</b><br />
                            {expirationTime}
                          </Div>
                          <Div width={"200px"}>
                            <b>{Language.getName(Names.Stock)}</b><br />
                            <QtyText>
                            <span>
                             {Language.getName(Names.Total2)}:
                            </span>
                              {st.qtyType === QtyTypes.RAW && st.minQty > qtySum &&
                                <QtyWarning />
                              }
                              {qtySum} {unit}
                            </QtyText>
                            {this.state.storages.length > 0 && this.state.selectedStorageOptions.length > 0 && (() => {
                              let subSum: number = Qty.countSum(this.state.qtyList, st.qtyType, st.id, -1);
                              return (
                                <>
                                  <hr />
                                  {this.state.selectedStorageOptions.find(
                                      o => parseInt(o.value) === -1) !== undefined &&
                                    <QtyText>
                                      <span>{Language.getName(Names.DefaultStorage)}:</span>
                                      {st.qtyType === QtyTypes.RAW && st.minQty > subSum &&
                                        <QtyWarning />
                                      }
                                      {subSum} {unit}
                                    </QtyText>
                                  }
                                </>
                              );
                            })()}
                            {this.state.storages.length > 0 &&
                              this.state.storages.map((s, ik) => {
                                let subSum: number = Qty.countSum(this.state.qtyList, st.qtyType, st.id, s.id);

                                if (this.state.selectedStorageOptions.find(
                                  o => parseInt(o.value) === s.id) === undefined)
                                  return <React.Fragment key={ik} />;

                                return (
                                  <QtyText key={ik}>
                                    <span>{s.name}:</span>
                                    {st.qtyType === QtyTypes.RAW && st.minQty > subSum &&
                                      <QtyWarning />
                                    }
                                    {subSum} {unit}
                                  </QtyText>
                                );
                              })
                            }
                          </Div>
                          <Controls>
                            <div onClick={() => EventSystem.publish(EventSystem.events.open_qty_history, st)}>
                              <BsClockHistory />
                              <span>{Language.getName(Names.History)}</span>
                            </div>
                            {this.state.shopProfile?.permissions[this.state.shop?.id]?.includes(
                                ShopProfileTypes.MODIFY_MENU) &&
                              <>
                                {st.qtyType === QtyTypes.RAW &&
                                  <div
                                    onClick={() => EventSystem.publish(EventSystem.events.edit_raw_material, st)}>
                                    <FaPen />
                                    <span>{Language.getName(Names.Modify)}</span>
                                  </div>
                                }
                                {st.qtyType === QtyTypes.RAW &&
                                  <div
                                    onClick={() => this.removeButtonClicked(st)}
                                  >
                                    <FaTrashAlt />
                                    <span>{Language.getName(Names.Remove)}</span>
                                  </div>
                                }
                              </>
                            }
                          </Controls>
                        </ListElement>
                      );
                    })}
                  </>
                );
              })()}
            </>
          }
          {this.state.selected === 1 &&
            <>
              {this.state.recipes.length <= 0 &&
                <>
                  {Language.getName(Names.NoRecipesToShowAddRightTop)}
                </>
              }
              {this.state.recipes.map((recipe, i) => {
                return (
                  <ListElement key={i}>
                    <Div width={"35px"}>
                      #{recipe.id}
                    </Div>
                    <Div width={"90px"}>
                      <b>{Language.getName(Names.Added)}</b>
                      <br />
                      {recipe.addedDate.toHunFormatMinutes()}
                    </Div>
                    <Div width={"25%"}>
                      <b>{Language.getName(Names.Name)}</b>
                      <br />
                      {TranslatableString.get(recipe.name)}
                    </Div>
                    <Div>
                      <b>{Language.getName(Names.QuantityFor10)}:</b>
                      <ul>
                        {recipe.raws.map((rq, i) => (
                          <li key={i}>
                            {rq && ((rq.qty).toLocaleString() + " " + TranslatableString.get(
                              rq.raw.unit) + " " + TranslatableString.get(rq.raw.name))}
                          </li>
                        ))}
                      </ul>
                    </Div>
                    <Controls>
                      <div onClick={() => EventSystem.publish(EventSystem.events.edit_recipe, recipe)}>
                        <FaPen />
                        <span>{Language.getName(Names.Modify)}</span>
                      </div>
                      <div
                        onClick={() =>
                          RecipeAPI.remove(recipe.id, (res) => {
                            if (res.error !== 0)
                              return;

                            toast(Language.getName(Names.Removed));
                            ContextSystem.removeRecipe(recipe.id);
                          })
                        }
                      >
                        <FaTrashAlt />
                        <span>{Language.getName(Names.Remove)}</span>
                      </div>
                    </Controls>
                  </ListElement>
                );
              })}
            </>
          }
          {this.state.selected === 2 &&
            <>
              {this.state.storages.length <= 0 &&
                <ListElement>
                  <Div width={"200px"}>
                    {Language.getName(Names.Default)}
                  </Div>
                </ListElement>
              }
              {this.state.storages.map((storage, i) => {
                return (
                  <ListElement key={i}>
                    <Div width={"35px"}>
                      #{storage.id}
                    </Div>
                    <Div width={"90px"}>
                      <b>{Language.getName(Names.Added)}</b>
                      <br />
                      {storage.addedDate.toHunFormatMinutes()}
                    </Div>
                    <Div width={"25%"}>
                      <b>{Language.getName(Names.Name)}</b>
                      <br />
                      {storage.name}
                    </Div>
                    <Div width={"25%"}>
                      <b>{Language.getName(Names.Type)}</b>
                      <br />
                      {storage.type === StorageTypes.COUNTER &&
                        <>{Language.getName(Names.Counter)}</>
                      }
                      {storage.type === StorageTypes.WAREHOUSE &&
                        <>{Language.getName(Names.Warehouse)}</>
                      }
                    </Div>
                    <Controls>
                      <div onClick={() => EventSystem.publish(EventSystem.events.edit_storage, storage)}>
                        <FaPen />
                        <span>{Language.getName(Names.Modify)}</span>
                      </div>
                      <div
                        onClick={() =>
                          StorageAPI.remove(storage.id, (res) => {
                            if (res.error !== 0)
                              return;

                            toast(Language.getName(Names.Removed));
                            ContextSystem.removeStorage(storage.id);
                          })
                        }
                      >
                        <FaTrashAlt />
                        <span>{Language.getName(Names.Remove)}</span>
                      </div>
                    </Controls>
                  </ListElement>
                );
              })}
            </>
          }
        </ListWrapper>
      </Wrapper>
    )
      ;
  }
}
