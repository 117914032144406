import React, { ChangeEvent, Component } from "react";
import styled from "styled-components";
import Language, { Names } from "../utils/Language";
import Select2 from "react-select/async";
import { Profile } from "../model/Profile";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import type { Option } from "./modals/OrderStateCommentEditor";
import { CustomerAPI } from "../utils/api/CustomerAPI";
import ErrorMessage from "../utils/api/ErrorMessages";
import { Button, Input } from "./FormComponents";
import PhoneNumber from "libphonenumber-js";
import { toast } from "react-toastify";
import EmailValidator from "../utils/EmailValidator";

const Select = styled(Select2)`
  width: 100%;
  margin-bottom: 25px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e3e3e3;
`;

type P = {
  onProfileSelected: (profileID: number)=>{},
}

type S = {
  language: number,
  profiles: Profile[],
  onProfileSelected: ()=>{},
  selectedProfile: Option,

  firstName: string,
  lastName: string,
  email: string,
  tel: string,
}

export default class CustomerSelector extends Component<P, S> {
  state: S = {
    language: ContextSystem.language,
    profiles: [],
    onProfileSelected: undefined,
    selectedProfile: undefined,

    firstName: "",
    lastName: "",
    email: "",
    tel: "",
  };

  loadContext() {
    this.setState({
      language: ContextSystem.language,
      profiles: ContextSystem.profiles,
    });
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>) {
    if (this.state.onProfileSelected !== this.props.onProfileSelected)
      this.setState({ onProfileSelected: this.props.onProfileSelected });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, profiles }) => {
      if (language !== undefined || profiles !== undefined)
        this.loadContext();
    });
  }

  promiseOptions = inputValue =>
    new Promise((resolve, reject) => {
      CustomerAPI.searchCustomer(inputValue, res => {
        if (res.error !== ErrorMessage.OK)
          reject("error - " + res.error);

        ContextSystem.maxCRMPages = res.maxCount;
        ContextSystem.mergeProfiles(res.profiles);
        resolve(res.profiles.map(p => ({ label: p.firstName + " " + p.lastName, value: p.id })));
      });
    });

  addNewCustomer() {
    if (!this.state.firstName || this.state.firstName.length < 2) {
      toast(Language.getName(Names.FirstNameNotValid));
      return;
    }
    if (!this.state.lastName || this.state.lastName.length < 2) {
      toast(Language.getName(Names.LastNameNotValid));
      return;
    }
    if (this.state.tel && !(PhoneNumber(this.state.tel)?.isValid() ?? false)) {
      toast(Language.getName(Names.PhoneNumberNotValid));
      return;
    }
    if (this.state.email.length > 0 && !EmailValidator(this.state.email)) {
      toast(Language.getName(Names.EmailAddressNotValid));
      return;
    }

    CustomerAPI.addCustomer(this.state.firstName, this.state.lastName, this.state.email, this.state.tel, res => {
      if (res.error !== ErrorMessage.OK)
        return;

      let profile: Profile = res.profiles[0];
      this.setState({ selectedProfile: { value: profile.id, label: profile.firstName + " " + profile.lastName } });

      ContextSystem.mergeProfiles(res.profiles);
      ContextSystem.mergeCities(res.cities, res.zipCodes);

      if (this.state.onProfileSelected)
        this.state.onProfileSelected(profile.id);
    });
  }

  deselect() {
    this.setState({
      selectedProfile: undefined,
      firstName: "",
      lastName: "",
      email: "",
      tel: "",
    });
  }

  render() {
    let profile: Profile = undefined;
    if (this.state.selectedProfile)
      profile = ContextSystem.profiles.find(p => p.id === this.state.selectedProfile.value);
    let disableInputs: boolean = profile !== undefined;

    return (
      <Wrapper>
        <Select
          placeholder={Language.getName(Names.SearchCustomer)}
          noOptionsMessage={() => Language.getName(Names.NoCustomersToShow)}
          value={this.state.selectedProfile}
          onChange={(e) => {
            this.setState({ selectedProfile: e });
            if (this.state.onProfileSelected)
              this.state.onProfileSelected(e.value);
          }}
          loadOptions={this.promiseOptions}
        />
        <Input
          autoComplete="off"
          autocomplete="off"
          disabled={disableInputs}
          value={profile?.tel ?? this.state.tel}
          type="text"
          placeholder={Language.getName(Names.PhoneNumber)}
          onChange={(e) => this.setState(
            { tel: PhoneNumber(e.target.value, "HU")?.formatInternational() ?? e.target.value })}
          width={"100%"}
          margin={"6px 0"}
        />
        <Input
          autoComplete="off"
          autocomplete="off"
          disabled={disableInputs}
          value={profile?.firstName ?? this.state.firstName}
          type="text"
          placeholder={Language.getName(Names.FirstName)}
          onChange={(e: ChangeEvent) => this.setState({ firstName: e.target.value })}
          width={"100%"}
          margin={"6px 0"}
        />
        <Input
          autoComplete="off"
          autocomplete="off"
          disabled={disableInputs}
          value={profile?.lastName ?? this.state.lastName}
          type="text"
          placeholder={Language.getName(Names.LastName)}
          onChange={(e: ChangeEvent) => this.setState({ lastName: e.target.value })}
          width={"100%"}
          margin={"6px 0"}
        />
        <Input
          autoComplete="off"
          autocomplete="off"
          disabled={disableInputs}
          value={profile?.email ?? this.state.email}
          type="text"
          placeholder={Language.getName(Names.Email)}
          onChange={(e: ChangeEvent) => this.setState({ email: e.target.value })}
          width={"100%"}
          margin={"6px 0"}
        />
        {!profile &&
          <Button
            color={"white"}
            bg_color={"#3b6499"}
            onClick={() => this.addNewCustomer()}
          >
            {Language.getName(Names.AddNewCustomer)}
          </Button>
        }
        {profile &&
          <Button
            color={"white"}
            bg_color={"#3b6499"}
            onClick={() => this.deselect()}
          >
            {Language.getName(Names.OtherGuest)}
          </Button>
        }
      </Wrapper>
    );
  }
}
