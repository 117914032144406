import React, { Component } from "react";
import styled from "styled-components";
import NumberPad from "./NumberPad";
import PaymentSummaryComponent from "./PaymentSummaryComponent";
import OrderPaymentEditor from "./OrderPaymentEditor";
import EventSystem from "../../../utils/EventSystem";
import type { OrderPayment } from "../../../model/Order";
import { PaymentMethodTypes } from "../../../model/PaymentMethodSetting";
import ContextSystem from "../../../utils/ContextSystem";
import { Order } from "../../../model/Order";

const PayAllOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3px;
`;

type Props = {
  existingPayments: OrderPayment[],
  newPayments: OrderPayment[],
  deletePayments: OrderPayment[],
  onNewPaymentsChanged: (newPayments: OrderPayment[])=>{},
  onDeletePaymentsChanged: (deletePayments: OrderPayment[])=>{},
  onSaveProcessed: ()=>{},
  total: number,
  orders: Order[],
};

export default class PayInOneComponent extends Component<Props> {
  state: {
    existingPayments: OrderPayment[],
    newPayments: OrderPayment[],
    deletePayments: OrderPayment[],
    selectedPayingMethod: number,
    total: number,
    orders: Order[],
  } = {
    existingPayments: [],
    newPayments: [],
    deletePayments: [],
    selectedPayingMethod: PaymentMethodTypes.CASH,
    total: 0,
    orders: []
  };

  loadProps() {
    if (this.state.newPayments !== this.props.newPayments)
      this.setState({ newPayments: this.props.newPayments });
    if (this.state.existingPayments !== this.props.existingPayments)
      this.setState({ existingPayments: this.props.existingPayments });
    if (this.state.total !== this.props.total)
      this.setState({ total: this.props.total });
    if (this.state.deletePayments !== this.props.deletePayments)
      this.setState({ deletePayments: this.props.deletePayments });
    if (this.state.orders !== this.props.orders)
      this.setState({ orders: this.props.orders });
  }

  saveButtonClicked() {
    if (this.props.onSaveProcessed)
      this.props.onSaveProcessed();
  }

  eventIDs: number[] = [];

  componentDidUpdate(prevProps: Readonly<Props>, prevState, snapshot) {
    this.loadProps();
  }

  componentWillUnmount() {
    this.eventIDs.forEach(i => EventSystem.unsubscribe(i));
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid: number;
    eid = EventSystem.subscribe(EventSystem.events.order_payment_save_clicked, () => {
      this.saveButtonClicked();
    });
    this.eventIDs.push(eid);

    this.loadProps();
  }

  allOnOnePaymentOptionClicked(paymentType: number) {
    let newPayments: OrderPayment[] = [];
    newPayments = PaymentSummaryComponent.changeNewPayment(newPayments, paymentType, this.state.total);
    this.setState({ newPayments });
    if (this.props.onNewPaymentsChanged)
      this.props.onNewPaymentsChanged(newPayments);
  }

  addPayment(amount: number, paymentType: number) {
    let newPayments: OrderPayment[] = this.state.newPayments;
    newPayments.push({
        id: -1,
        enabled: true,
        paymentType,
        amount,
        creditedAmount: 0,
        dateTime: new Date(),
        orderContactID: -1,
        orderID: -1,
        payed: true,
        productPayments: [],
        shopID: ContextSystem.selectedShop?.id ?? -1,
        txid: -1,
      },
    );
    this.setState({
      newPayments,
    });
    if (this.props.onNewPaymentsChanged)
      this.props.onNewPaymentsChanged(newPayments);
  }

  newPaymentsChanged(newPayments: OrderPayment[]) {
    this.setState({ newPayments });
    if (this.props.onNewPaymentsChanged)
      this.props.onNewPaymentsChanged(newPayments);
  }

  deletePaymentsChanged(deletePayments: OrderPayment[]) {
    this.setState({ deletePayments });
    if (this.props.onDeletePaymentsChanged)
      this.props.onDeletePaymentsChanged(deletePayments);
  }

  render() {
    let mergeSzepCardsToCredit: boolean = window.localStorage.getItem("mergeSzepCardsToCredit") === "true";
    let availablePaymentMethods: {
      type: number,
      countries: number[],
      availableShippingMethods: number[]
    }[][] = OrderPaymentEditor.getAvailablePaymentMethods(mergeSzepCardsToCredit);

    return (
      <PayAllOptionWrapper>
        <PaymentSummaryComponent
          availablePaymentMethods={availablePaymentMethods}
          newPayments={this.state.newPayments}
          deletePayments={this.state.deletePayments}
          existingPayments={this.state.existingPayments}
          onNewPaymentsChanged={newPayments => this.newPaymentsChanged(newPayments)}
          onDeletePaymentsChanged={deletePayments => this.deletePaymentsChanged(deletePayments)}
          toPay={this.state.total}
          total={this.state.total}
          orders={this.state.orders}
        />
        <NumberPad
          availablePaymentMethods={availablePaymentMethods}
          onPayingMethodDoubleClicked={type => this.allOnOnePaymentOptionClicked(type)}
          selectedPayingMethod={this.state.selectedPayingMethod}
          onPaymentMethodChanged={(selectedPayingMethod) => this.setState({ selectedPayingMethod })}
          onAddButtonClicked={(value, type) => this.addPayment(value, type)}
        />
      </PayAllOptionWrapper>
    );
  }
}
