import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { ExtrasAPI } from "../../utils/api/ExtrasAPI";
import { toast } from "react-toastify";
import { Product, Extra, TranslatableString } from "../../model/Product";
import { FaPen, FaPlus, FaTrashAlt } from "react-icons/all";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Input } from "../FormComponents";
import ConfirmationModal from "../modals/ConfirmationModal";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ListWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 24px;
  height: 100%;

  overflow-y: scroll;
`;

export const ProductDiv = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px gray;

  min-height: 50px;
  background-color: white;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Div = styled.div`
  width: ${({ width }) => (width ? width : "15%")};
`;

export const Controls = styled.div`
  width: ${({ width }) => (width ? width : "20%")};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > div {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10pt;

    & > svg {
      font-size: 22pt;
    }

    &:hover, &:active {
      cursor: pointer;
    }
  }
`;

export const AddButtonWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-right: 5%;
  padding-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ?? "flex-end"};

  & > div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10pt;

    & > svg {
      font-size: 22pt;
    }

    &:hover, &:active {
      cursor: pointer;
    }
  }
`;

export default class Extras extends Component {
  eventIDs: number[] = [];

  state: {
    extras: Extra[],
    language: number,
    search: string,
  } = {
    extras: ContextSystem.extras,
    language: ContextSystem.language,
    search: "",
  };

  load() {
    ExtrasAPI.getExtras((res) => {
      if (res.error === 0)
        this.setState({ extras: res.extras });
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  componentDidMount() {
    this.eventIDs = [];
    this.load();
    // noinspection DuplicatedCode
    let eid = EventSystem.subscribe(EventSystem.events.extras_changed, () => {
      this.load();
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, extras }) => {
      if (language !== undefined)
        this.setState({ language });
      if (extras !== undefined)
        this.load();
    });
    this.eventIDs.push(eid);
  }

  removeExtra(extraID: number): void {
    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.DoYouReallyWantToRemoveItem),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {
      },
      () => {
        let extraUsedByProducts: Product[] = ContextSystem.products.filter(
          p => p.enabled && p.partnerID === ContextSystem.selectedShop?.id && p.availableExtras.find(
            ae => ae.enabled && ae.id === extraID,
          ) !== undefined,
        );
        if (extraUsedByProducts?.length > 0) {
          let productsString: string = extraUsedByProducts.map(p => TranslatableString.get(p.name)).join("\r\n");
          toast(Language.getName(Names.CANT_DELETE_EXTRA_PRODUCT_USE_IT) + "\r\n" + productsString);
          return;
        }
        ExtrasAPI.remove(extraID, (res) => {
          if (res.error !== 0)
            return;
          toast(Language.getName(Names.Removed));
          this.load();
        });
      },
    );
  }

  render() {
    return (
      <Wrapper>
        <AddButtonWrapper justify={"space-between"}>
          <Input
            autoComplete="off"
            value={this.state.search}
            type="text"
            placeholder={Language.getName(Names.Search) + "..."}
            width={"90%"}
            margin={"3px 0"}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div onClick={() => EventSystem.publish(EventSystem.events.edit_extra)}>
            <FaPlus />
            <span>{Language.getName(Names.AddButtonText)}</span>
          </div>
        </AddButtonWrapper>
        <ListWrapper>
          {this.state.extras.map((extra, i) => {
            if (this.state.search !== "" && extra.name.translation.find(t => t.value.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase())) === undefined)
              return <React.Fragment key={i} />;

            return (
              <ProductDiv key={i}>
                <Div width={"20%"}>
                  <b>{Language.getName(Names.Name)}:</b>
                  <br />
                  {TranslatableString.get(extra.name)}
                </Div>
                <Div width={"20%"}>
                  <b>{Language.getName(Names.Price)}:</b>
                  <br />
                  {extra.price + " Ft"}
                </Div>
                <Controls>
                  <div onClick={() => EventSystem.publish(EventSystem.events.edit_extra, extra)}>
                    <FaPen />
                    <span>{Language.getName(Names.Modify)}</span>
                  </div>
                  <div onClick={() => this.removeExtra(extra.id)}>
                    <FaTrashAlt />
                    <span>{Language.getName(Names.Remove)}</span>
                  </div>
                </Controls>
              </ProductDiv>
            );
          })}
        </ListWrapper>
      </Wrapper>
    );
  }
}
