import React, { Component } from "react";
import { PageContainer } from "../components/PageContainer";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import EventSystem from "../utils/EventSystem";
import { ActivateAPI } from "../utils/api/ActivateAPI";
import { toast } from "react-toastify";
import ContextSystem from "../utils/ContextSystem";
import Language, { Names } from "../utils/Language";
import { ActivationPanel, Button, Wrapper } from "./ActivatePage";
import { Input } from "../components/FormComponents";
import PhoneNumber from "libphonenumber-js";

class ActivateShopProfilePage extends Component {
  eventIDs = [];

  state: {
    editFirstName: string,
    editLastName: string,
    editPhoneNumber: string,
    editPass: string,
    editPin: string,
    activationPassphrase: string,
    language: number
  } = {
    editPass: "",
    editFirstName: "",
    editLastName: "",
    editPhoneNumber: "",
    editPin: "",
    activationPassphrase: "",
    // passphraseValid: true,
    language: ContextSystem.language,
  };

  activateOnEnter(e) {
    if (e.keyCode === 13)
      this.activate();
  }

  isNumeric(str: string) {
    if (typeof str != "string")
      return false; // we only process strings!

    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
  }

  activate() {
    if (this.state.editPass.length < 5) {
      toast(Language.getName(Names.PasswordTooShort));
      return;
    }
    if (this.state.editFirstName.length < 3) {
      toast(Language.getName(Names.FirstNameShortDetails));
      return;
    }
    if (this.state.editLastName.length < 3) {
      toast(Language.getName(Names.LastNameShortDetails));
      return;
    }
    if (!(PhoneNumber(this.state.editPhoneNumber, "HU")?.isValid() ?? false)) {
      toast(Language.getName(Names.PhoneNumberNotValid));
      return;
    }
    if (!this.isNumeric(this.state.editPin)) {
      toast(Language.getName(Names.PinOnlyNumbers));
      return;
    }
    if (this.state.editPin.length < 4) {
      toast(Language.getName(Names.PinTooShort));
      return;
    }
    if (this.state.editPin.length > 8) {
      toast(Language.getName(Names.PinTooLong));
      return;
    }

    ActivateAPI.activateShopProfile(this.state.activationPassphrase, this.state.editPass,
      this.state.editFirstName, this.state.editLastName, this.state.editPin, this.state.editPhoneNumber,
      (res) => {
        if (res.error === 0)
          this.props.history.push("/bejelentkezes");
      },
    );
  }

  redirect(authenticated: boolean) {
    if (authenticated)
      this.props.history.push("/");
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    this.eventIDs = [];
  }

  componentDidMount() {
    let passphrase = this.props.match.params.passphrase;
    this.setState({ activationPassphrase: passphrase });

    // noinspection DuplicatedCode
    this.eventIDs = [];
    this.redirect(ContextSystem.loggedIn);

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ loggedIn, language }) => {
      if (loggedIn !== undefined) {
        this.redirect(loggedIn);
      }

      if (language !== undefined) {
        this.setState({ language });
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>{Language.getName(Names.EnniAkarokActivatePageTitle)}</title>
          <meta name="description" content={Language.getName(Names.EnniAkarokActivatePageDesc)} />
        </Helmet>
        <Wrapper>
          <ActivationPanel>
            <h1>{Language.getName(Names.ActivateTitle)}</h1>
            <form id={"activate"} method={"POST"} onSubmit={event => event.preventDefault()}>
              <p>{Language.getName(Names.ActivateDesc)}</p>
              <Input
                autoComplete="off"
                value={this.state.editLastName}
                type="text"
                placeholder={Language.getName(Names.LastName) + "..."}
                onChange={(e) => this.setState({ editLastName: e.target.value })}
              />
              <Input
                autoComplete="off"
                value={this.state.editFirstName}
                type="text"
                placeholder={Language.getName(Names.FirstName) + "..."}
                onChange={(e) => this.setState({ editFirstName: e.target.value })}
              />
              <Input
                autoComplete="off"
                value={this.state.editPhoneNumber}
                type="text"
                placeholder={Language.getName(Names.PhoneNumber) + "..."}
                onChange={(e) => this.setState(
                  { editPhoneNumber: PhoneNumber(e.target.value, "HU")?.formatInternational() ?? e.target.value })}
              />
              <Input
                autoComplete="new-password"
                value={this.state.editPass}
                onKeyUp={(e) => this.activateOnEnter(e)}
                type="password"
                placeholder={Language.getName(Names.ProfileNewPasswordPlaceholderText)}
                onChange={(e) => this.setState({ editPass: e.target.value })}
              />
              <Input
                autoComplete="off"
                value={this.state.editPin}
                type="text"
                placeholder={Language.getName(Names.PinNumber)}
                onChange={(e) => this.setState({ editPin: e.target.value })}
              />
              <Button onClick={() => this.activate()}>{Language.getName(Names.ActivateBtnText)}</Button>
            </form>
          </ActivationPanel>
        </Wrapper>
      </PageContainer>
    );
  }
}

export default withRouter(ActivateShopProfilePage);
