import { renderToString } from "react-dom/server";
import React from "react";
import styled, { css, ServerStyleSheet } from "styled-components";
import logo from "../assets/blundee_logos/blundee_text.png";
import QRCode from "qrcode.react";
import Orders, { ProductType } from "../components/home/Orders";
import ContextSystem from "./ContextSystem";
import type { OrderProductSorted } from "../model/Order";
import { Order, OrderProduct, OrderUsedCouponID, TableReservation } from "../model/Order";
import { Element } from "../model/BluePrint";
import Language, { Names } from "./Language";
import { Product, TranslatableString } from "../model/Product";
import { toast } from "react-toastify";
import { Menu, MenuBody, MenuHead, MenuProduct, OrderProductNoteWrapper } from "../components/home/OrderComponent";
import CartCalc from "./CartCalc";
import { Coupon, CouponTypes } from "../model/Coupon";
import { ShippingMethods } from "../model/ShippingPrice";
import { PaymentMethods } from "../model/PaymentMethodSetting";

const Wrapper = styled.div`
  width: calc(100% - 6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3px 30px 3px;
  border-bottom: 1px solid #111111;
  font-family: "Yu Gothic", sans-serif;
  line-height: 1rem;
  font-size: 10pt;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    width: 100%;
    max-width: 200px;
    margin: 5px 0;
  }

  margin-bottom: 5px;
`;

const ContantInfoWrapper = styled.div`
  width: 100%;
  background-color: #c6c6c6;

  & > span {
    display: block;
    width: 100%;
    text-align: left;
    padding: 4px 0;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;

  & > h1 {
    font-size: 50pt;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  & > span {
    display: block;
    width: 100%;
    text-align: left;
    padding: 4px 0;
  }
`;

const ProductsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 1rem;
`;

const Total = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin ?? "0"};

  background-color: #c6c6c6;

  ${({ font_size }) => !!font_size && css`
    font-size: ${font_size};
  `};

  & > span:nth-child(1) {
    width: 35%;
  }

  & > span:nth-child(2) {
    width: 34%;
  }

  & > span:nth-child(3) {
    padding-right: 3px;
    width: 30%;
    text-align: right;
  }
`;

const ProductDiv = styled.div`
  width: calc(100% - 6px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  //border: 1px solid #939393;
  border: 1px solid transparent;
  font-family: "Yu Gothic", sans-serif;
  margin: 2px 0;

  & > span:nth-child(1) {
    width: 55%;
  }

  & > span:nth-child(2) {
    width: 13%;
    text-align: center;
  }

  & > span:nth-child(3) {
    width: 15%;
    text-align: right;
    padding-right: 10px;
  }

  ${({ header }) => header === true && css`
    & > span {
      font-weight: bold;
    }

    & > span:nth-child(1) {
      width: 45%;
    }

    & > span:nth-child(2) {
      width: 23%;
      text-align: center;
    }

    & > span:nth-child(3) {
      width: 24%;
      text-align: right;
      padding-right: 10px;
    }

    padding: 17px 3px;
    background-color: #c6c6c6;
    margin: 15px 0 2px 0;
    border: 1px solid #c6c6c6;
  `}
`;

const Foot = styled.div`
  margin-top: 50px;
  width: 100%;
  text-align: left;

  & > div {
    font-size: 15pt;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  & > span {
    font-size: 9.5pt;
    font-family: "Monaco", sans-serif;
  }

  & > span:first-child {
    font-size: 11pt;
  }
`;

const OrderProductExtrasWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 4px 4px 4px 15px;
  border-radius: 5px;
  background-color: #d5d5d5;

  & > p {
    width: 100%;
    margin: 0 0 3px 0;
    font-size: 13px;
  }
`;

const OrderProductExtra = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div:nth-of-type(1) {
    width: 15%;
    padding-right: 3px;
  }

  & > div:nth-of-type(2) {
    width: 15%;
    padding: 0 3px;
  }

  & > div:nth-of-type(3) {
    width: 70%;
    padding-left: 3px;
    text-align: right;
  }
`;

function renderOrderProductExtras(orderProduct: OrderProduct) {
  let has: boolean = orderProduct.extras && orderProduct.extras.length > 0;
  if (!has) {
    return <React.Fragment />;
  }

  return (
    <OrderProductExtrasWrapper>
      <p>{Language.getName(Names.SelectedExtras)}</p>
      {orderProduct.extras.map((oe, i) => {
        return (
          <OrderProductExtra key={i}>
            <div>{oe.qty}db</div>
            <div>{TranslatableString.get(oe.name)}</div>
            <div>{oe.originalTotal}Ft</div>
          </OrderProductExtra>
        );
      })}
    </OrderProductExtrasWrapper>
  );
}

export function print(orders: Order[], tableReservation: TableReservation, table: Element) {
  if (!orders || orders.length <= 0) {
    toast(Language.getName(Names.OrderDetailsNotFound));
    return;
  }

  let products: Product[] = ContextSystem.products;
  let orderProducts: OrderProductSorted[] = [];

  for (let order of orders) {
    let cart: Product[] = CartCalc.createCartFromOrder(order);
    let recreatedOrder: Order = CartCalc.createOrderFromState(cart, {}, undefined, undefined, [], undefined,
      order.comment,
    );

    for (let ops of Orders.sortProducts(recreatedOrder.productList))
      orderProducts.push(ops);
  }

  let address = "";
  if (orders[0].address) {
    address =
      orders[0].address.street +
      ", " +
      TranslatableString.get(ContextSystem.getCityByID(orders[0].address.cityID)?.name) +
      " " +
      ContextSystem.getZipCode(orders[0].address.zipCodeID)?.zipcode;
  }

  let tableName = undefined;
  if (orders[0].tableReservationID > 0 && table !== undefined) {
    tableName = table.name;
  }

  let total: number = 0;
  let originalTotal: number = 0;
  for (let order of orders) {
    total += Order.totalOrderPrice(order);
    originalTotal += order.originalOrderTotalPrice;
  }

  let usedCoupons: Coupon[] = [];
  for (let order: Order of orders) {
    for (let ouci: OrderUsedCouponID of order.usedCoupons) {
      if (usedCoupons.find(c => c.id === ouci.usedCouponID) !== undefined)
        continue;
      usedCoupons.push(ContextSystem.coupons.find(c => c.id === ouci.usedCouponID));
    }
  }

  let orderElement = (
    <Wrapper>
      <Header>
        <img src={logo} alt={"LOGO"} />
        {orders[0].address &&
          <QRCode renderAs={"svg"} size={90} value={"https://maps.google.com/maps?q=" + address} />
        }
      </Header>
      <InfoWrapper>
        <h2>{orders[0].number > 0 ? orders[0].number : ""}</h2>
        <span>{Language.getName(Names.Order) + ": " + orders[0].date.toHunFormatMinutes()}</span>
        {orders[0].lastState && orders[0].lastState.dateTime &&
          <span>{Language.getName(Names.Arrived) + ": " + new Date(
            (orders[0].lastState?.dateTime)).toHunFormatMinutes()}</span>
        }
        <ContantInfoWrapper>
          {orders[0].profile &&
            <span>{orders[0].profile.firstName + " " + orders[0].profile.lastName}</span>
          }
          {orders[0].address && (orders[0].shippingMethod === ShippingMethods.VENDOR_DELIVERY || orders[0].shippingMethod === ShippingMethods.DELIVERY) &&
            <>
              <span>{address}</span>
              <span>
            {Language.getName(Names.Comment)}: {orders[0].address.comment}
          </span>
            </>
          }
          {tableName && orders[0].shippingMethod === ShippingMethods.AT_PLACE &&
            <span>{tableName}</span>
          }
          {orders[0].profile &&
            <span>{orders[0].profile.tel}</span>
          }
        </ContantInfoWrapper>
        <span>{PaymentMethods.getName(orders[0].paymentMethod)}</span>
        <span>{PaymentMethods.getName(orders[0].shippingMethod)}</span>
        {orders[0].comment && orders[0].comment.length > 0 && (
          <span>
            {Language.getName(Names.Comment)}
            <br />
            {orders[0].comment}
          </span>
        )}
      </InfoWrapper>
      <ProductDiv header={true}>
        <span>{Language.getName(Names.Product)}</span>
        <span>{Language.getName(Names.Quantity)}</span>
        <span>{Language.getName(Names.Price)}</span>
      </ProductDiv>
      <ProductsWrapper>
        {orderProducts.map((orderProduct, i) => {
          let versionName = "";
          let product = Orders.getProductByID(products, orderProduct.productID);
          if (!!product) {
            let v = Orders.getVersionByID(product.versions, orderProduct.versionID);
            if (product.versions.length > 1 && v)
              versionName = "(" + TranslatableString.get(v.name) + ")";
          }

          if (orderProduct.type === ProductType.PRODUCT) {
            return (
              <ProductDiv key={i}>
                <span>
                  {TranslatableString.get(orderProduct.name)} {versionName}
                </span>
                <span>{orderProduct.qty} {Language.getName(Names.pcs)}</span>
                <span>
                  {/*{(orderProduct.qty * (orderProduct.price + Orders.getOrderProductExtrasPrice(product, orderProduct))).toLocaleString()}*/}
                  {(orderProduct.qty * orderProduct.originalItemTotalPrice).toLocaleString()}
                  Ft
                </span>
                {orderProduct.note &&
                  <OrderProductNoteWrapper>
                    {orderProduct.note}
                  </OrderProductNoteWrapper>
                }
                {renderOrderProductExtras(orderProduct)}
              </ProductDiv>
            );
          } else if (orderProduct.type === ProductType.MENU) {
            return (
              <Menu printing={true} key={i}>
                <MenuHead printing={true} borderbottom={orderProduct.products.length > 0}>
                  <div>
                    <div>
                      {TranslatableString.get(orderProduct.name)} {versionName}
                    </div>
                    <div>{orderProduct.qty}{Language.getName(Names.pcs)}</div>
                    <div>
                      {/*{(orderProduct.qty * (orderProduct.price + Orders.getOrderProductExtrasPrice(product, orderProduct))).toLocaleString()}*/}
                      {(orderProduct.qty * orderProduct.originalItemTotalPrice).toLocaleString()}
                      Ft
                    </div>
                  </div>
                  {orderProduct.note &&
                    <OrderProductNoteWrapper>
                      {orderProduct.note}
                    </OrderProductNoteWrapper>
                  }
                  {renderOrderProductExtras(orderProduct)}
                </MenuHead>
                <MenuBody printing={true}>
                  <span>{Language.getName(Names.ContentOfMenu)}:</span>
                  {orderProduct && orderProduct.products.map((subProduct, j) => {
                    let subVersionName = "";
                    let subP = Orders.getProductByID(products, subProduct.productID);
                    if (!!subP) {
                      let v = Orders.getVersionByID(subP.versions, subProduct.versionID);
                      if (subP.versions.length > 1 && v)
                        subVersionName = "(" + TranslatableString.get(v.name) + ")";
                    }
                    return (
                      <MenuProduct printing={true} key={j}>
                        <span>
                          • {TranslatableString.get(subProduct.name)} {subVersionName}
                          <br />
                          {orderProduct.qty * subProduct.qty}{Language.getName(Names.pcs)}
                          {subProduct.extras && subProduct.extras.length > 0 && (
                            <span>
                              <br />(
                              {subProduct.extras.map((extra, k) => {
                                return (
                                  <span key={k}>
                                    {extra.qty > 1
                                     ? extra.qty + Language.getName(Names.pcs)
                                     : ""} {TranslatableString.get(extra.name)} {k < subProduct.extras.length - 1
                                                                                 ? ","
                                                                                 : ""}
                                  </span>
                                );
                              })}
                              )
                            </span>
                          )}
                        </span>
                      </MenuProduct>
                    );
                  })}
                </MenuBody>
              </Menu>
            );
          }
        })}
        {orders[0].shippingMethod === ShippingMethods.DELIVERY && (
          <ProductDiv>
            <span>{Language.getName(Names.ShippingPrice)}</span>
            <span />
            <span>{orders[0].shippingPrice}Ft</span>
          </ProductDiv>
        )}
        {usedCoupons.length > 0 &&
          <Total margin={"20px 0 0 0"}>
            <span />
            <span>{Language.getName(Names.Subtotal)}</span>
            <span>{originalTotal.toLocaleString()}Ft</span>
          </Total>
        }
        {usedCoupons.map((c, i) => {
          if (!c)
            return <React.Fragment key={i} />;

          return (
            <Total key={i}>
              <span />
              <span>{TranslatableString.get(c.name)}</span>
              <span>{-c.couponValue > 0 && "+"}{-c.couponValue.toLocaleString()}{c.type === CouponTypes.FIX
                                                                                 ? "Ft"
                                                                                 : "%"}</span>
            </Total>
          );
        })}
        <Total margin={usedCoupons.length > 0 ? "0" : "20px 0 0 0"} font_size={"14pt"}>
          <span />
          <span><b>{Language.getName(Names.Total)}</b></span>
          <span><b>{Math.round(total).toLocaleString()}Ft</b></span>
        </Total>
      </ProductsWrapper>
      <Foot>
        <span>{Language.getName(Names.OrderPrintFooter1)}</span>
        <br />
        <span>{Language.getName(Names.OrderPrintFooter2)}</span>
        <br />
        <span>{Language.getName(Names.OrderPrintFooter3)}</span>
        <br />
        <span>{new Date().toHunFormatMinutes()}</span>
      </Foot>
      <div>www.blundee.com</div>
    </Wrapper>
  );
  let sheet = new ServerStyleSheet();
  const html = renderToString(sheet.collectStyles(orderElement));
  const styleTags = sheet.getStyleTags();
  sheet.seal();
  // noinspection CheckTagEmptyBody
  let content = "<html lang=\"hu\"><head><title>" + document.title + "</title>";
  content += "" + styleTags;
  content += "</head><body>";
  content += html;
  content += "</body></html>";

  let pri = document.getElementById("ifmcontentstoprint").contentWindow;
  pri.document.open();
  pri.document.write(content);
  pri.document.close();

  setTimeout(() => {
    pri.focus();
    pri.print();
    pri.close();
  }, 500);
}
